<template>
  <div class="wrapper">
    <span class="md-title">Dosya Tipleri</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addFileTypeModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="fileTypes">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Kanal">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateFileType(item.id)">edit</i>
          <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteFileType(item.id)">delete</i>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addFileTypeModal" class="dialog">
      <md-content class="statuses">
        <h3>Yeni Dosya Tipi</h3>
        <md-field>
          <label>Dosya Tipi Adı *</label>
          <md-input v-model="fileType.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Dosya Tipi Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="addFileType">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateFileTypeModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Dosya Tipi Adı *</label>
          <md-input v-model="fileType.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Dosya Tipi Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateFileTypeDone">Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "FileTypes",
  data() {
    return {
      fileTypes: [],
      fileType: {
        name: ""
      },
      addFileTypeModal: false,
      updateFileTypeModal: false,
      isValid: {
        name: null,
      }
    };
  },
  created() {
    this.getFileTypes();
  },
  methods: {
    addFileType: function() {
      HTTP.post("/fileTypes/", this.fileType).then(result => {
        if (result.status == 201) {
          this.getFileTypes();
          this.addFileTypeModal = false;
        }
      });
    },
    getFileTypes: function() {
      HTTP.get("/fileTypes").then(result => {
        this.fileTypes = result.data;
      });
    },
    updateFileType: function(id) {
      this.fileType = this.fileTypes.filter(fileTypes => fileTypes.id == id)[0];
      this.updateFileTypeModal = true;
    },
    updateFileTypeDone: function() {
      HTTP.put("/fileTypes/" + this.fileType.id, this.fileType).then(result => {
        if (result.status == 204) {
          this.getFileTypes();
          this.updateFileTypeModal = false;
        }
      });
    },
    deleteFileType: function(id) {
        if(confirm("Emin misiniz?")){
            HTTP.delete("/fileTypes/" + id).then(result => {
              if (result.status == 204) {
                this.getFileTypes();
              }
            });
        }
    },
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
.sortButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 50px !important;
  top: 10px !important;
}
.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    cursor: move;
}
</style>