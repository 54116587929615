<template>
  <!-- <div v-if=false class="loader" :class="{ white: white }"></div> -->
  <div class="loading-container">
    <div class="loading-spinner"></div>
  </div>
</template>
<script>
export default {
  name: "loading",
  props: {
    white: {
      value: {
        type: Boolean,
        default: false
      }
    }
  }
};
</script>
<style>

.loading-container{
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 250px;
  position: absolute;
  display: flex;
  background: white;
  z-index: 20;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px); 
}

.loading-spinner, .loading-spinner::after {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.loading-spinner {
  border-top: 4px solid rgba(53, 137, 255, 0.2);
  border-right: 4px solid rgba(55, 195, 255, 0.2);
  border-bottom: 4px solid rgba(0, 81, 151, 0.2);
  border-left: 4px solid blue;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-name: loading-spinner-animation;
  animation-name: loading-spinner-animation;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes loading-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tw-loading-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/* */

.loader,
.loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.loader {
  margin-bottom: -3px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(128, 128, 128, 0.6);
  border-right: 3px solid rgba(128, 128, 128, 0.6);
  border-bottom: 3px solid rgba(128, 128, 128, 0.6);
  border-left: 3px solid #7c7c7c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.white {
  border-top: 3px solid rgba(255, 255, 255, 0.6);
  border-right: 3px solid rgba(255, 255, 255, 0.6);
  border-bottom: 3px solid rgba(255, 255, 255, 0.6);
  border-left: 3px solid #fff;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
