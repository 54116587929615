import { HTTP } from "@/main-source";
import moment from "moment";
export const patientProfileMixin = {
    data(){
        return{
            patient: [],
            patientsId: this.$route.params.id,
            patientStatuses: [],
            operations: [],
            firms: [],
            agencies: [],
            sources: [],
            users: [],
            statuses: [],
            languages: [],
            countries: [],
            patientSource: null,
            patSource: null,
            isPatient: false,
            patientCreatedAt: null,
            patientCreatedAt_old: null,
            statusHistory: []
        }
    },
    created() {
        HTTP.get("/users/").then((result) => {
            this.users = result.data;
        });
        HTTP.get("/operations/").then(result => {
            this.operations = result.data.filter(item => item.status == true);
        });
        HTTP.get("/patientstatus/").then(result => {
            this.patientStatuses = result.data;
        });
        HTTP.get("/status/").then(result => {
            this.statuses = result.data;
          });
        HTTP.get("/firms/").then(result => {
            this.firms = result.data;
        });
        HTTP.get("/agencies/").then(result => {
            this.agencies = result.data;
        });
        HTTP.get("/sources/").then(result => {
            this.sources = result.data;
        });
        HTTP.get("/countries/").then(result => {
            this.countries = result.data;
        });
        HTTP.get("/languages/").then(result => {
            this.languages = result.data;
        });
    
        this.refreshPatientData();
    },
    methods: {
        refreshPatientData: function() {
            HTTP.get("/patients/" + this.patientsId).then(result => {
                this.patient = result.data[0];
                this.isPatient = this.patient.type;
                //console.log("bu", this.patient);
        
                if (this.patient.treatments?.length) {
                  this.patientTreatments(this.patient.treatments);
                }
        
                
                this.patSource = this.sources.find(z => z.name == this.patient.source);
                if(this.patSource){
                    this.patientSource = {
                        id: this.patSource.id,
                        name: this.patSource.name
                    };
                }
                
        
                this.patientCreatedAt = new Date(moment(this.patient.createdAt).format("YYYY-MM-DD HH:mm"));
                this.patientCreatedAt_old = this.patient.createdAt;

                this.statusHistory = this.patient.statusHistory ? JSON.parse(this.patient.statusHistory) : [];
            });
          },
    }
}