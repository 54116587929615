<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <AppointmentCard
      :appointment="appointment"
      :appointmentDetails="appointmentDetails"
      @closeAppointmentCard="closeAppointmentCard"
      @refreshAppointments="refreshAppointments"
    ></AppointmentCard>


 <md-dialog :md-active.sync="showShareModal" class="dialog  " style="overflow: auto!important;">
      <md-content class="statuses p-5 ">
        <h3>Satış Fırsatı Oluştur</h3>
   
         <div class="md-layout md-gutter">
                    <!-- <md-button
            class="md-fab md-mini md-primary editButton"
            v-on:click="setEditable"
          >
            <md-icon>edit</md-icon>
          </md-button> -->
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        <md-field  >
                            <label>Ad Soyad</label>
                            <md-input v-model="patient.name"   readonly></md-input>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        <md-field  >
                            <label>Telefon</label>
                            <md-input v-model="patient.phone"  disable readonly></md-input>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        Durum
                        <md-field  >
                            <v-select v-model="patient.patientStatus" placeholder="Durum" :options="patientStatuses"
                                  label="name" class="md-menu">
                                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
                            </v-select>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        Tedavi
                        <md-field  >
                            <v-select v-model="patient.operation" placeholder="Tedavi" :options="operations"
                                label="name" class="md-menu">
                                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
                            </v-select>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        <!-- <md-field   >
                            <label>Ülke</label>
                            <md-input v-model="patient.countryCode"   ></md-input>
                        </md-field> -->
                        Ülke
                        <md-field  
                            >
                            <v-select v-model="patient.country" placeholder="Ülke" :options="countries" label="name_tr"
                                class="md-menu">
                                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
                            </v-select>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        <md-field>
                            <label>Tahmini Tutar ( {{ patient.currencySlug }} )</label>
                            <md-input v-model="patient.estimated_amount"   ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        Kaynak
                        <md-field  
                           >
                            <v-select v-model="patient.agencie" placeholder="Kaynak" :options="agencies" label="name"
                                class="md-menu smallSelect">
                                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
                            </v-select>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                  <!-- <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        İletişim
                        <md-field>
                            <md-input v-model="patient.source"  
                                v-if="patient.source && !patSource"></md-input>
                            <v-select :clearable="false" v-model="patientSource" placeholder="İletişim"
                                :options="sources" label="name" class="md-menu" 
                                v-if="!patient.source || patSource">
                                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
                            </v-select>
                        </md-field>
                    </div> -->
                    <div class="md-layout-item md-size-50 md-xsmall-size-100">
                        Dil
                        <md-field  >
                            <v-select v-model="patient.language" placeholder="Dil" :options="languages"
                                  label="name" class="md-menu">
                                <template slot="option" slot-scope="option">{{
                                    option.name
                                }}</template>
                            </v-select>
                            <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                        </md-field>
                    </div>
                </div>
     

       
           
         <!-- <md-checkbox v-model="sharedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        > -->
        <div class="md-layout text-right pb-5" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showShareModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="shareComplete"
            >Oluştur</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <div class="md-layout-item">
          <span class="md-title title">Satış Fırsatları</span>
    
            <md-button  v-on:click="showShareModal = true" class="md-fab md-mini md-primary addButton">
              <md-icon>add</md-icon>
            </md-button>
        
        </div>
        <md-table
          class="table appointments"
          v-model="patients"
         >
          <md-table-row
            slot="md-table-row"
            class="trow"
            slot-scope="{ item }"
          
            :class="isDuplicated(item.phone)"
          >
            <md-table-cell
              class="cell-sm"
              :class="{
                activePatient: editedPatient && editedPatient.id == item.id,
              }"
            >
              <md-button
                v-if="(hasAccess() || canSee(item)) && roleId != 5"
                class="md-icon-button md-raised md-dense"
                v-on:click="goPotantialDetail(item)"
                ><md-icon>account_circle</md-icon>
                <md-tooltip md-direction="top">Hastaya git</md-tooltip>
              </md-button>
              <div @click="searchDuplicates(item.phone)">
                <md-icon
                  class="duplicateOfReal"
                  v-if="isDuplicated(item.phone) == 'duplicatedOfRealPatient'"
                  >r_mobiledata</md-icon
                >
                <md-tooltip md-direction="top">Randevulu Hasta</md-tooltip>
              </div>
            </md-table-cell>

            <md-table-cell md-label="Hasta Tipi" v-if="searchAllPatients">
              {{ item.type == 1 ? "Gerçek" : "Aday Hasta" }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Telefon"
            >
              <span
                :title="
                  isConflicted(item.phone)
                    ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                    : ''
                "
              >
                <b v-if="isConflicted(item.phone)">✗</b> {{ item.phone }}
                <b v-if="isConflicted(item.phone)">✗</b>
              </span>
            </md-table-cell>
            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Ülke"
            >
              {{ item.country ? item.country.name_tr : item.countryCode }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Kaynak"
            >
              {{ item.agencieId && item.agencie ? item.agencie.name : "" }}
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Hasta Adı"
            >
              {{ item.name }}
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Tedavi"
            >
              {{ item.operation ? item.operation.name : "" }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Ülke"
            >
              {{ item.country ? item.country.name_tr : item.countryCode }}
            </md-table-cell>
            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Telefon"
            >
              <span
                :title="
                  isConflicted(item.phone)
                    ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                    : ''
                "
              >
                <b v-if="isConflicted(item.phone)">✗</b> {{ item.phone }}
                <b v-if="isConflicted(item.phone)">✗</b>
              </span>
            </md-table-cell>
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Durum"
            >
              {{
                item.status && item.patientStatus ? item.patientStatus.name : ""
              }}
            </md-table-cell>

            <md-table-cell
              v-if="roleId != 5"
              v-on:click.native="fastEditPatient(item)"
              md-label="Not"
            >
              <md-icon v-if="item.notes.length > 0">library_books</md-icon>
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Kaynak"
            >
              {{ item.agencieId && item.agencie ? item.agencie.name : "" }}
            </md-table-cell>
     <!-- <md-table-cell
                 v-else
                v-on:click.native="fastEditPatient(item)"
                md-label="Firma"
              >
                {{ item.innerFirmId && item.innerFirm ? item.innerFirm.name : "" }}
              </md-table-cell>
              <md-table-cell
               
                v-on:click.native="fastEditPatient(item)"
                md-label="Branş"
              >
                {{ item.branchId && item.branch ? item.branch.name : "" }}
              </md-table-cell> -->
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Kişi"
            >
              {{ item.user ? item.user.name : "" }}
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Kayıt Tarihi"
              >{{ dateString(item.createdAt) }}</md-table-cell
            >
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Son Güncelleme Tarihi"
              >{{ dateString(item.updatedAt) }}</md-table-cell
            >
            <md-table-cell class="pd-0">
              <div
                class="hover"
                v-if="(hasAccess() || canSee(item)) && roleId != 5"
              >
                <!-- <md-button
                   v-if="innerWidth >= 769"
                    class="md-icon-button md-raised md-dense"
                    v-on:click="goPotantialDetail(item)"
                    ><md-icon>account_circle</md-icon>
                    <md-tooltip md-direction="top">Hastaya git</md-tooltip>
                    </md-button> -->
                <!-- <md-button
                  class="md-icon-button md-raised md-dense"
                  v-on:click="directTo(item.id)"
                  ><md-icon>mobile_screen_share</md-icon>
                  <md-tooltip md-direction="top">Yönlendir</md-tooltip>
                </md-button> -->
                <!-- <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="sendEmail(item.id)"
                    ><md-icon>mail_outline</md-icon>
                    <md-tooltip md-direction="top">E-Mail At</md-tooltip>
                    </md-button> -->
                <md-button
                  class="md-icon-button md-raised md-dense"
                  v-if="isDuplicated(item.phone)"
                  v-on:click="searchDuplicates(item.phone)"
                  ><md-icon>groups</md-icon>
                  <md-tooltip md-direction="top"
                    >Tekrarlanan kayıtlar</md-tooltip
                  >
                </md-button>
                <md-button
                  class="md-icon-button md-raised md-dense md-accent"
                  v-if="roleId == 1 || canSee(item)"
                  v-on:click="deletePatient(item)"
                  ><md-icon>delete</md-icon>
                  <md-tooltip md-direction="top">Sil</md-tooltip>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <div v-for="appointment in appointments" v-bind:key="appointment.id">
          {{ appointment.name }}
        </div>
      </div>
    </div>
    <!-- fast edit patient model -->
  <md-dialog
      :md-active.sync="fastEditPatientModal"
      class="dialog allowOverflow"
    >
      <md-content class="statuses md-scrollbar">
        <h3>Hasta Bilgileri</h3>
        <div class="md-layout md-gutter" v-if="editedPatient">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Hasta Adı</label>
              <md-input v-model="editedPatient.name"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Tedavi</label>
            <md-field  >
              <v-select
                v-model="editedPatient.operation"
                placeholder="Tedavi"
                :options="operations2"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
             <span v-if="error" class="md-error">Bu alanı boş bırakmamalısınız!</span>
          </div>
          <!-- <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Firma</label>
            <md-field  >
              <v-select
                v-model="editedPatient.innerFirm"
                placeholder="Firma"
                  v-bind:disabled="editedPatient.innerFirm!=null"
                :options="firms"
                label="name"
                class="md-menu"
                readonly
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
             <span v-if="error" class="md-error">Bu alanı boş bırakmamalısınız!</span>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Branş</label>
            <md-field  >
              <v-select
                v-model="editedPatient.branch"
                placeholder="Branş"
                v-bind:disabled="editedPatient.branch!=null"
                :options="branches"
                label="name"
                class="md-menu"
                disable
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
             <span v-if="error" class="md-error">Bu alanı boş bırakmamalısınız!</span>
          </div> -->
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Ülke</label>
            <md-field>
              <v-select
                v-model="editedPatient.country"
                placeholder="Ülke"
                :options="countries"
                label="name_tr"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Telefon</label>
              <md-input   v-model="editedPatient.phone"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Kaynak</label>
            <md-field>
              <v-select
                v-model="editedPatient.agencie"
                placeholder="Kaynak"
                :options="agencies"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
             
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Dil</label>
            <md-field>
              <v-select
                v-model="editedPatient.language"
                placeholder="Dil"
                :options="languages"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
               <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>E-mail</label>
              <md-input v-model="editedPatient.email"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            İletişim
            <md-field>
              <md-textarea
                v-model="editedPatient.source"
                v-if="editedPatient.source && !patSource"
                md-autogrow
              ></md-textarea>
              <v-select
                :clearable="false"
                v-model="patientSource"
                placeholder="İletişim"
                :options="sources"
                label="name"
                class="md-menu"
                v-if="!editedPatient.source || patSource"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <label>Durum</label>
            <md-field>
              <v-select
                v-model="editedPatient.patientStatus"
                placeholder="Durum"
                :options="patientStatus"
                label="name"
                class="md-menu"
                @input="changeStatus"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div
            class="md-layout-item md-size-50 md-xsmall-size-100"
            v-show="statusChanged"
          >
            <md-field>
              <label>Durum Notlar</label>
              <md-input v-model="statusNote"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item wrapper md-size-100" v-if="statusHistory">
            <span class="md-title">Durum Hareketleri</span>
            <ul>
              <li  v-for="(item,index) in statusHistory" :key="index">
              <span v-if="item.oldStatus != null">
                <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.oldStatus)}}</b>'den <b>{{getStatusName(item.newStatus)}}</b>'ye değiştirildi.
                </span> 
                <span v-else> 
                  <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.newStatus)}}</b> olarak atandı.
                </span>
            <br>
              <span v-if="item.note != null"><b>Durum Notu:</b> {{item.note}}</span></li>
            </ul>
          </div>
        </div>
        <div class="md-layout md-gutter text-right" style="margin-top: 20px">
          <md-button
            class="md-raised button-margins md-default"
            v-on:click="cancelEditPatient"
            >İptal</md-button
          >
          <md-button
            class="md-raised button-margins md-accent"
            v-on:click="convertPatient"
          >
            <md-icon>info</md-icon>  <span v-if='editedPatient.sharingType==1||editedPatient.sharingType==2'>Randevu Oluştur </span> <span v-else> Hastaya Dönüştür </span>
          </md-button>
          <md-button
            class="md-raised button-margins md-primary"
            v-on:click="saveEditedPatient"
          >
            <md-icon>save</md-icon> Kaydet
          </md-button>
        </div>
        <div class="md-layout" v-if="editedPatient">
          <div class="md-layout-item md-size-100 notesContainer">
            <span class="md-title">Notlar</span>
            <notes :patientId="editedPatient.id"></notes>
          </div>
        </div>
      </md-content>
    </md-dialog>

    <convert-modal
      :showBecomePatientModal="showBecomePatientModal"
      :patient="editedPatient"
      @closeModal="closeConvertModal"
    ></convert-modal>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import AppointmentCard from "@/components/patients/appointmentCard";
import moment from "moment";
import ConvertModal from "@/components/patients/potantials/convertModal.vue";
import notes from "@/components/patients/notes";


import router from "@/router";

import "vue-loading-overlay/dist/vue-loading.css";
 
export default {
  name: "Appointments",
  data() {
    return {
      patientsId: this.$route.params.id,
      patient: null,
      patientStatus: null,
      appointments: {},
      appointment: {
        operatorNote:null,
        patient: {
          name: "",
          phone: "",
          patientStatus: "",
          email: "",
          sex: "",
          birthday: "",
          source: null,
          country: "",
        },
        statusId: "",
        location: {
          name: "",
        },
        operation: {
          name: "",
        },
      },
      appointmentDetails: false,
      created: false,
      isDietician: localStorage.getItem("roleId") == 9,
      isDoctor: localStorage.getItem("roleId") == 2,

  profile: [],
      patients: [],
      search_query: "",
      filters: [],
      selectedFilter: null,
      selectedPatientId: null,
      showDirectModal: false,
      directedPatientsToBeRecall: false,
      users: [],
      filterUsers: [],
      directToUser: null,
      currencies: {
        EUR: null,
        GBP: null,
        USD: null,
      },
      monthEarned: 0,
      monthExpect: 0,
      nextMonthExpect: 0,
      loseds: 0,
      customFilterModal: false,
      currenciesLoaded: false,
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
      leading: JSON.parse(localStorage.getItem("leading")),
      userCustomFilters: [],
      quickSelect: "all",
      startDate: new Date(),
      endDate: new Date(),
      showDeleteFilter: false,
      operations: [],
      operations2: [],
      firms: [],
      agencies: [],
      patientStatuses: [],
       countries: [],
      languages: [],
      sources: [],
      whens: [],
      patientOwners: [],




showShareModal: false,
 shareToUser: null,
  sharedPatientCode: [],
         shareBranches: [],
         branches: [],
         shareOperations: [],
         selectedOperation: [],
         selectedOperations: [],
         selectedBranch: [],
         sharingNote: [],
patientOwner:null,





   nf_country: null,
      nf_firms: null,
      nf_agencie: null,
      nf_patientStatus: null,
      nf_user: null,
      nf_when: null,
      nf_operation: null,
      nf_name: null,
      patientStatusFilterOptions: [],
      OperationFilterOptions: [],
      selectedUserFilter: null,
      selectedAgencieFilter: null,
      selectedCountryFilter: null,
      selectedLanguageFilter: null,
      selectedTreatmentFilter: null,
      selectedStatusFilter: null,
      selectedPatients: [],
      showAllDirectModal: false,
      searching: false,
      lastSearchField: "",
      paginationCurrentPage: 1,
      paginationPageCount: 1,
      paginationPageSize: 100,
      patientsCount: 0,
      duplicatedPhones: [],
      conflictedPhones: [],
      // possibleConflicedPhones: [],
      isGettingDuplicates: false,
          editedPatient: {
        sharingHistory:null,
        patientCode:null,
        innerFirmId: null,
        branchId: null,
        operation:null,
      },
      fastEditPatientModal: false,
      patientSource: null,
      patSource: null,
      patientCountries: [],
      filterShow: false,
      isLoading: false,
      exportFields: {
        "Hasta Adı": "name",
        Tedavi: {
          field: "operation",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Ülke: {
          field: "country",
          callback: (value) => {
            return value ? value.name_tr : "countryCode";
          },
        },
        Telefon: "phone",
        Kaynak: {
          filed: "agencie",
          callback: (value) => {
            return "agencieId" && value ? value.name : "";
          },
        },
        Kişi: {
          field: "user",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Durum: {
          field: "patientStatus",
          callback: (value) => {
            return "status" && value ? value.name : "";
          },
        },
        "Kayıt Tarihi": {
          field: "createdAt",
          callback: (value) => {
            return value ? this.dateString(value) : "";
          },
        },
        "Son Güncelleme Tarihi": {
          field: "updatedAt",
          callback: (value) => {
            return value ? this.dateString(value) : "";
          },
        },
      },
       statusChanged: false,
      statusNote: "",
      statusHistory: [],
      statusCurrent: null,
      historyChecked: false,
      currentStatusChecked: false,
      showBecomePatientModal: false,
      searchAllPatients: false,
      tabType: "all",
      recallCount: 0,







    };
  },
  components: {
    Navigation,
    Topbar,
    AppointmentCard,
       notes,
     ConvertModal,
  },
   async created() {
    HTTP.get("/patients/" + this.patientsId).then((result) => {
      this.patient = result.data[0];
    });
 



//  axios.get("https://intesasoft.com/api/currency/").then((res) => {
//       this.currencies.EUR = res.data.EUR;
//       this.currencies.USD = res.data.USD;
//       this.currencies.GBP = res.data.GBP;
//       this.currenciesLoaded = true;
//     });

    var agenciesLimitation = "";
    if (this.roleId != 1) agenciesLimitation = "?userId=" + this.userId;
    await HTTP.get("/agencies/" + agenciesLimitation).then((result) => {
      this.agencies = result.data;
    });
    var filtredUserAgencie = [];
    this.agencies.forEach(function(item){
      filtredUserAgencie.push(item.id)
    });
  
    this.getPool();
    HTTP.get("/users/").then((result) => {
      this.users = result.data;
      if(["1","6"].includes(this.roleId))
        this.filterUsers = this.users.filter((z) => true);
      else
        this.filterUsers = this.users.filter(
          (z) => this.leading.includes(z.id) || z.id == this.userId
        );
    });

    this.getCustomFilters();
    HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
      this.OperationFilterOptions = result.data.filter(
        (item) => item.status == true
      );
      this.OperationFilterOptions.reverse();
      this.OperationFilterOptions.push({ id: 0, name: "Yok" });
      this.OperationFilterOptions.reverse();
    });
    HTTP.get("/firms/").then((result) => {
      this.firms = result.data;
    });
    HTTP.get("/users/").then((result) => {
      this.patientOwners = result.data.filter(
        (user) => user.roleId != 2 && user.status == true
      );
    });
   HTTP.get("/patientstatus/").then((result) => {
                this.patientStatuses = result.data;
    });

    HTTP.get("/countries").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/languages").then((result) => {
      this.languages = result.data;
    });
     HTTP.get("/sources").then((result) => {
                this.sources = result.data;
                });
    HTTP.get("/whens/").then((result) => {
      this.whens = result.data;
    });

    this.getDuplicates();
    this.getOperations();
 
this.patientOwner = this.patient.user ? this.patient.user.name : "" + this.patient.secondUser ? "," + this.patient.secondUser.name : "";


  },
  methods: {
     getBranches:  function(){
      let userId = localStorage.getItem("userId");
      let branches = [];
      HTTP.get("/branches/").then((result) => {
      result.data.forEach(function(branch){
        if(branch.branchUsers.map(x => x.id).includes(parseInt(userId)))
          branches.push(branch);
      });

      this.branches = branches;

      this.selectedBranch = this.branches ? this.branches[0] : null;
    });
    },
    getOperations: function(){
      HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter(
        (operation) => operation.status == true  
      );
      this.operations2 = result.data.filter(
        (operation) => operation.status == true  
      );
      });
    },
    closeAppointmentCard() {
      this.appointmentDetails = false;
    },
    refreshAppointments() {
      this.getAppointmentsByPatients();
    },
 
    shareTo: function (id) {
      this.patientsId = id;
      this.showShareModal = true;
    },
   shareComplete: function () {
     this.showShareModal = !this.showShareModal;

     let data = {};

        data.sharedUserId = localStorage.getItem("userId");
        data.status = this.patient.patientStatus.id;
        // data.selectedOperation = this.patient.operation;
        data.agencieId = this.patient.agencie.id;
        data.selectedOperation = this.patient.operation.id;
        data.estimated_amount = this.estimated_amount;
 
      HTTP.patch("patients/createPotantialFromSharedPatient/" + this.patientsId, data).then(() => {
        this.sharedToUser = null;
         this.selectedPatientId = null;
        this.$swal("","Başarıyla Oluşturuldu", "success");
        this.$emit("updated");
      
             this.getPool();
        });
        
    },




    getAppointmentsByPatients: function () {
      HTTP.get("/appointments/bypatient/" + this.patientsId).then((result) => {
         this.appointments = result.data;
      });
    },
    showAppointmentDetails: function (id) {
      HTTP.get("/appointments/" + id).then((result) => {
         this.appointment = result.data[0];
        setTimeout(() => {
          this.appointmentDetails = true;
        }, 200);
      });
    },
    checkIfSelected: function (model, val) {
      return model && model.find((z) => z.id == val.id) ? "✓" : "";
    },
    numberIfSelected: function (model, val) {
      if (!this.historyChecked)
        return model && model.find((z) => z.id == val.id) ? "✓" : "";
      else {
        let idx = model ? model.findIndex((z) => z.id == val.id) : -1;
        return idx == -1 ? "" : idx + 1;
      }
    },
    filterToggle: function () {
      this.filterShow = !this.filterShow;
    },
    isDuplicated: function (phone) {
      if (phone == "") return false;
      let duplicatedRecord = this.duplicatedPhones.filter(
        (z) => z.phone == phone
      );
      if (duplicatedRecord.length > 0) {
        return duplicatedRecord[0].isRealPatient == 1
          ? "duplicatedOfRealPatient"
          : "duplicated";
      } else return false;
    },
    isConflicted: function (phone) {
      if (phone == "") return false;
      let conflictedRecord = this.conflictedPhones.filter(
        (z) => z.phone == phone
      );
      if (conflictedRecord.length > 0) return true;
      else return false;
    },
    searchDuplicates: function (phone) {
      this.isGettingDuplicates = true;
      this.searchAllPatients = true;
      this.search_query = phone.replace("+", "");
    },
    getDuplicates: function () {
      HTTP.get("/patients/getDuplicates").then((result) => {
        this.duplicatedPhones = result.data;
      });
      HTTP.get("/patients/getConflicts").then((result) => {
        this.conflictedPhones = result.data;
      });
    },
    hasAccess: function () {
      let roleId = localStorage.getItem("roleId");
      if (roleId == 1) {
        return true;
      }
      return false;
    },
    canSee: function (patient) {
      return (
        patient.userId == this.userId ||
        patient.secondUserId == this.userId ||
        this.leading.includes(patient.userId) ||
        this.leading.includes(patient.secondUserId)
      );
    },
    hiddenName: function (name) {
      if (!name) return "";
      var names = name.split(" ");
      if (names.length == 0) return "";
      else {
        var hiddenName = names[0] + " ";
        names.splice(1, 1).forEach(function (item) {
          hiddenName += item.substr(0, 1) + "*** ";
        });
        return hiddenName;
      }
    },
    getPool: function (resetPagination = true) {
      this.patients = [];

      this.paginationPageCount = 1;
      if (resetPagination) this.paginationCurrentPage = 1;
      let offset = this.paginationPageSize * (this.paginationCurrentPage - 1);
      let uri =
        "/patients/getPatientsForPatientInformation?limit=150" +
        "&offset=" +
        offset +
        "&patientCode=" +
        this.patient.id +
        "&includeUser=true&potantials=true";
 

      HTTP.get(uri).then((result) => {
        this.patients = result.data.rows;
        this.patientsCount = result.data.count;
        this.paginationPageCount = Math.ceil(
          result.data.count / this.paginationPageSize
        );
      });
    },
    async getPool2Export() {
      this.isLoading = true;
      let patients2export = [];
      let uri = "/patients/?includeUser=true&potantials=true";

      if (this.quickSelect != "all") {
        let startText =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1) +
          "-" +
          ("0" + this.startDate.getDate()).slice(-2);

        let endText =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1) +
          "-" +
          ("0" + this.endDate.getDate()).slice(-2);
        uri += "&startDate=" + startText + "&endDate=" + endText;
      }

      if (this.roleId != 1 && this.roleId != 5 && this.roleId != 6) {
        uri += "&user=" + this.userId + "," + this.leading.join();
      }

      if (this.selectedFilter) {
        uri =
          "/patients/?potantials=true&includeUser=true&" +
          this.selectedFilter.query;
      }

      if (this.selectedUserFilter) {
        var users = this.selectedUserFilter.map((z) => z.id);
        uri += "&user=" + users;
      }

      if (this.selectedAgencieFilter && this.selectedAgencieFilter.length > 0) {
        var agencies = this.selectedAgencieFilter.map((z) => z.id);
        uri += "&agencie=" + agencies;
      } else if (this.roleId == 5 || this.roleId == 6) {
        var agencies = this.agencies.map((z) => z.id);
        uri += "&agencie=0," + agencies;
      }

      if (this.selectedCountryFilter) {
        var countries = this.selectedCountryFilter.map((z) => z.code);
        uri += "&country=" + countries;
      }

      if (this.selectedLanguageFilter) {
        var languages = this.selectedLanguageFilter.map((z) => z.id);
        uri += "&language=" + languages;
      }

      if (this.selectedTreatmentFilter) {
        var operations = this.selectedTreatmentFilter.map((z) => z.id);
        uri += "&operation=" + operations;
      }

      if (this.selectedStatusFilter) {
        var patientstatuses = this.selectedStatusFilter.map((z) => z.id);
        uri += "&patientstatus=" + patientstatuses;
        if (this.historyChecked) uri += "&statushistory=true";
        if (this.currentStatusChecked) uri += "&currentchecked=true";
      }

      await HTTP.get(uri).then((result) => {
        patients2export = result.data;
      });
      this.isLoading = false;
      return patients2export;
    },
    searchPatient: async function (q) {
      let userId = this.userId;
      this.paginationPageCount = 1;
      let uri = "/patients/?limit=100&includeUser=true";
      uri += "&potantials=" + (this.searchAllPatients ? "all" : "true");
      if (!this.hasAccess() && !this.isGettingDuplicates) {
        uri += "&user=" + userId + "," + this.leading.join();
      }
      this.isGettingDuplicates = false;

      if (q != this.lastSearchField) return;
      if (q == "") {
        this.getPool();
      }
      this.searching = true;
      HTTP.get(uri + "&q=" + q).then((patients) => {
        this.patients = patients.data;
        this.searching = false;
        if (q != this.lastSearchField) this.searchPatient(this.lastSearchField);
      });
    },
    addFilter: function () {
      // console.log("ok");

      let uri = "";

      if (this.nf_country) {
        uri += "&country=" + this.nf_country;
      }

      if (this.nf_firms) {
        uri += "&firm=" + this.nf_firms.id;
      }

      if (this.nf_agencie) {
        uri += "&agencie=" + this.nf_agencie.id;
      }
      if (this.nf_patientStatus) {
        uri += "&patientstatus=" + this.nf_patientStatus.id;
      }

      if (this.nf_user) {
        uri += "&user=" + this.nf_user.id;
      }

      if (this.nf_when) {
        uri += "&when=" + this.nf_when.name;
      }

      if (this.nf_operation) {
        uri += "&operation=" + this.nf_operation.id;
      }
      uri = uri.substr(1);
      console.log(uri);

      this.customFilterModal = false;

      let data = {
        query: uri,
        name: this.nf_name,
      };

      HTTP.post("/customfilters/", data).then((result) => {
        console.log(result.data);
        this.getCustomFilters();
      });
    },
    getCustomFilters: function () {
      HTTP.get("/customfilters/").then((result) => {
        this.userCustomFilters = result.data;
        // this.users = result.data.filter(user => user.role.name != "Doctor");
      });
    },
    dateString: function (date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    sendEmail: function (id) {
      router.push({
        name: "potantial.patients.mails",
        params: { id: id },
      });
    },
    directTo: function (id) {
      this.selectedPatientId = id;
      this.showDirectModal = true;
    },
    directAllTo: function () {
      this.showAllDirectModal = true;
    },
    directCompleteAll: function () {
      for (let index = 0; index < this.selectedPatients.length; index++) {
        const pat = this.selectedPatients[index];

        let data = {
          userId: this.directToUser.id,
          directedPatientsToBeRecall: this.directedPatientsToBeRecall,
        };
        HTTP.patch("patients/" + pat.id, data).then(() => {
          console.log("selected " + pat.id);
          this.directToUser = null;
          // this.selectedPatientId = null;
          this.showAllDirectModal = false;
        });
      }
      this.selectedPatients = [];
      this.getPool();
      this.getDuplicates();
      // console.log("all is being directed");
    },
    directComplete: function () {
      let data = {
        userId: this.directToUser.id,
        directedPatientsToBeRecall: this.directedPatientsToBeRecall,
      };
      HTTP.patch("patients/" + this.selectedPatientId, data).then(() => {
        // console.log("selected " + this.selectedPatientId);
        this.directToUser = null;
        this.selectedPatientId = null;
        this.showDirectModal = false;
        this.getPool();
        this.getDuplicates();
      });
    },
    willBeCalled: function () {
      this.$swal({
        title: "Tekrar Aranacak",
        text: "Seçilen hastaları 'Tekrar Aranacak' olarak işaretlemek ister misiniz?",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Kaydet",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          for (let index = 0; index < this.selectedPatients.length; index++) {
            var pat = this.selectedPatients[index];

            pat.status = 30; // will be called again status id = 30

            HTTP.put("patients/" + pat.id, pat).then(() => {
              this.patients.find((z) => z.id == pat.id).status = 30;
              this.patients.find((z) => z.id == pat.id).patientStatus =
                this.patientStatus.find((z) => z.id == 30);
            });
          }
          this.selectedPatients = [];
          this.getRecallCount();
        }
      });
    },
    goPotantialDetail: function (item) {
      if (!this.hasAccess() && !this.canSee(item)) return;
      let savedFilters = {
        search_query: this.search_query,
        selectedUserFilter: this.selectedUserFilter,
        selectedAgencieFilter: this.selectedAgencieFilter,
        selectedCountryFilter: this.selectedCountryFilter,
        selectedLanguageFilter: this.selectedLanguageFilter,
        selectedTreatmentFilter: this.selectedTreatmentFilter,
        selectedStatusFilter: this.selectedStatusFilter,
        quickSelect: this.quickSelect,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      localStorage.setItem("savedFilters", JSON.stringify(savedFilters));

      router.push({
        name: "potantial.patients",
        params: {
          id: item.id,
        },
      });
    },
    openSales: function () {
      let total = 0;
      this.patients.forEach((patient) => {
        if (patient.offers && patient.offers.length > 0) {
          if (patient.offers[patient.offers.length - 1].status != 4) {
            switch (patient.offers[patient.offers.length - 1].currencySlug) {
              case "USD":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.USD
                );

                break;
              case "GBP":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.GBP
                );
                break;
              case "EUR":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.EUR
                );
                break;

              default:
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice
                );
                break;
            }
          }
        }
      });
      console.log("total", total);
      if (total) {
        return total.toFixed(2);
      } else {
        return 0;
      }
    },

    thisMonthTotal: function () {
      var total = 0;
      HTTP.get("/appointments/totals/monthearned").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.monthEarned = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    thisMonthExpect: function () {
      var total = 0;
      HTTP.get("/appointments/totals/monthexpect").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.monthExpect = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    nextMonthExpectFunc: function () {
      var total = 0;
      HTTP.get("/appointments/totals/nextmonthexpect").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.nextMonthExpect = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    getLoseds: function () {
      var total = 0;
      HTTP.get("/appointments/totals/loseds").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.offers[o.offers.length - 1].currencySlug) {
              case "USD":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.USD
                );
                break;
              case "GBP":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.GBP
                );
                break;
              case "EUR":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.EUR
                );
                break;
              default:
                total += parseFloat(o.offers[o.offers.length - 1].totalPrice);
                break;
            }
          });
          this.loseds = total.toFixed(2);
        }
      });
      // return total;
    },
    deleteCF: function (id) {
      console.log(id);
      HTTP.delete("/customfilters?ids=" + id).then(() => {
        this.getCustomFilters();
      });
    },
    // getQuick: function (val) {
    //   this.quickSelect = val;
    //   // this.getPool();
    //   var current = new Date(); // get current date

    //   switch (this.quickSelect) {
    //     case "today":
    //       this.startDate = current;
    //       this.endDate = current;
    //       break;
    //     case "week":
    //       var weekstart = current.getDate() - current.getDay() + 1;
    //       var weekend = weekstart + 6; // end day is the first day + 6
    //       var monday = new Date(current.setDate(weekstart));
    //       var sunday = new Date(current.setDate(weekend));
    //       this.startDate = monday;
    //       this.endDate = sunday;
    //       break;
    //     case "month":
    //       var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
    //       var lastDay = new Date(
    //         current.getFullYear(),
    //         current.getMonth() + 1,
    //         0
    //       );
    //       this.startDate = firstDay;
    //       this.endDate = lastDay;
    //       break;
    //     case "last month":
    //       var firstDay = new Date(
    //         current.getFullYear(),
    //         current.getMonth() - 1,
    //         1
    //       );
    //       var lastDay = new Date(current.getFullYear(), current.getMonth(), 0);
    //       this.startDate = firstDay;
    //       this.endDate = lastDay;
    //       break;
    //   }
    //   if (this.quickSelect != "special") {
    //     this.getPool();
    //   }
    // },
    changeDay: function (option) {
      this.quickSelect = "today";
      var activeDate = new Date(this.startDate);
      let val = option == "next" ? 1 : -1;
      var result = new Date(activeDate.getTime() + val * 86400000);

      this.startDate = result;
      this.endDate = result;

      this.getPool();
    },
    paginationChanged: function () {
      this.getPool(false);
    },
    patientSelected: function (items) {
      this.selectedPatients = items;
    },

    deletePatient: function (patient) {
      this.$swal({
        title: "Emin misiniz?",
        text:
          patient.name +
          " adlı ve " +
          patient.phone +
          " telefon nolu hastanın kaydı silinecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed)
          HTTP.delete("/patients?ids=" + patient.id).then(() => {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getDuplicates();
            this.selectedPatients = this.selectedPatients.filter(
              (p) => p.id !== patient.id
            );
            if (this.search_query != "")
              this.searchPatient(this.lastSearchField);
            else this.getPool();
          });
      });
    },
    deleteSelectedPatients: function () {
      if (this.selectedPatients.length > 0) {
        this.$swal({
          title: "Emin misiniz?",
          text: this.selectedPatients.length + " hasta kaydı silinecektir.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff5252",
          confirmButtonText: "Sil",
          cancelButtonText: "İptal",
        }).then((result) => {
          if (result.isConfirmed) {
            var patients = this.selectedPatients.map((p) => p.id);
            HTTP.delete("/patients?ids=" + patients).then(() => {
              this.$swal({
                icon: "success",
                text: "İşlem Başarıyla tamamlandı.",
                showConfirmButton: false,
                timer: 1500,
              });
              this.selectedPatients = [];
              this.getDuplicates();
              if (this.search_query != "")
                this.searchPatient(this.lastSearchField);
              else this.getPool();
            });
          }
        });
      }
    },
    fastEditPatient: function(item){
      if(this.roleId == 5) return;
      this.editedPatient = item;
      this.statusCurrent = this.editedPatient.status;
      this.patSource = this.sources.find((z) => z.name == item.source);
      if (this.patSource) {
        this.patientSource = {
          id: this.patSource.id,
          name: this.patSource.name,
        };
      }
      this.statusHistory = this.editedPatient.statusHistory
        ? JSON.parse(this.editedPatient.statusHistory)
        : [];
      this.fastEditPatientModal = true;
    },
    saveEditedPatient: function () {
      this.editedPatient.treatmentId = this.editedPatient.operation
        ? this.editedPatient.operation
        : null;
      this.editedPatient.status = this.editedPatient.patientStatus
        ? this.editedPatient.patientStatus.id
        : null;
      this.editedPatient.agencieId = this.editedPatient.agencie
        ? this.editedPatient.agencie.id
        : null;
      this.editedPatient.countryCode = this.editedPatient.country
        ? this.editedPatient.country.code
        : null;
      this.editedPatient.languageId = this.editedPatient.language
        ? this.editedPatient.language.id
        : null;

      if (this.patientSource) {
        if (!this.editedPatient.source) this.patSource = true;
        this.editedPatient.source = this.patientSource.name;
      }

      if (this.statusCurrent != this.editedPatient.status) {
        let statusHistory = this.editedPatient.statusHistory;
        if (statusHistory == "" || statusHistory == null) statusHistory = [];
        else statusHistory = JSON.parse(statusHistory);
        statusHistory.push({
          oldStatus: this.statusCurrent,
          newStatus: this.editedPatient.status,
          changedBy: this.userId,
          changedAt: new Date(),
          note: this.statusNote,
        });
        this.editedPatient.statusHistory = JSON.stringify(statusHistory);
      }

      HTTP.put("/patients/" + this.editedPatient.id, this.editedPatient).then(
        (result) => {
          if (result.status == 204) {
            var patientIndex = this.patients.findIndex(
              (item) => item.id == this.editedPatient.id
            );
            this.editedPatient.treatmentId = this.editedPatient.operation
              ? this.editedPatient.operation.id
              : null;
            this.patients[patientIndex] = this.editedPatient;
            // this.editedPatient = null;
            this.fastEditPatientModal = false;
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.statusChanged = false;
            this.statusNote = "";
            this.getRecallCount();
          }
        }
      );
    },
    cancelEditPatient: function () {
      // this.editedPatient = null;
      this.fastEditPatientModal = false;
      this.statusNote = "";
      this.statusChanged = false;
    },
    convertPatient: function () {
      this.cancelEditPatient();
      this.showBecomePatientModal = true;
    },
    clearFilters: function () {
      this.selectedUserFilter = null;
      this.selectedAgencieFilter = null;
      this.selectedCountryFilter = null;
      this.selectedLanguageFilter = null;
      this.selectedTreatmentFilter = null;
      this.selectedStatusFilter = null;
      this.historyChecked = false;
      this.currentStatusChecked = false;
    },
    changeStatus: function (selVal) {
      this.statusChanged = this.statusCurrent != selVal.id ? true : false;
    },
    closeConvertModal() {
      this.showBecomePatientModal = false;
    },
    searchQuery: function () {
      let cQuery = this.search_query.trim().split(" ").join("+");
      this.lastSearchField = cQuery;
      if (!this.searching) this.searchPatient(cQuery);
    },
    getRecallCount() {
      let uri =
        "/patients/?limit=1&offset=0&includeUser=true&potantials=true&&patientstatus=30";
      if (this.roleId != 1) {
        uri += "&user=" + this.userId + "," + this.leading.join();
      }
      HTTP.get(uri).then((result) => {
        this.recallCount = result.data.count;
        if (this.recallCount == 0) this.tabType = "all";
      });
    },
    getUserName(userId) {
      var user = this.users.find((z) => z.id == userId);
      return user ? user.name : "";
    },
    getStatusName(statusID) {
      var status = this.patientStatus.find((z) => z.id == statusID);
      return status ? status.name : "";
    },
    formatDate(date, showTime = false) {
      return showTime
        ? moment(date).format("DD-MM-YYYY HH:mm")
        : moment(date).format("DD-MM-YYYY");
    },










  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
.participants {
  display: block;
  span {
    display: inline-block;
  }
}
.addButton {
  float: right;
}
.table {
  width: 100%;
  margin-top: 0px;
}
.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}
.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}
.appointments {
  .md-table-row {
    cursor: pointer;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.pad {
  padding-right: 10px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;
    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}
</style>
