<template>
  
    <router-link
    v-if="validateSwitch ? validateControl : {}"
    :class="{ disabled: disabled }"
    style="text-decoration: none; color: inherit;"
    :to="{ name: 'patients_details', params: { id: id } }"
  >
    <div class="card-s">
      <div class="card-s-left">
        <img src="../../../public/images/avatar.jpg" alt="Avatar" />
      </div>
      <div class="card-s-right">
        <span style="font-size:15px;font-weight:400" class="title"
          ><md-icon class="icon">person</md-icon>{{ formatName ? widthBlock(formatName) : 'İsim Yok' }}</span
        >
        <span  class="title" 
          ><md-icon class="icon">language</md-icon>{{  country ?  widthBlock(country.name_tr) : 'Ülke Yok' }}</span
        >

        <span class="title" 
          ><md-icon class="icon">phone</md-icon>{{ phone ? (disabled ? formatPhone(phone) : phone) : 'Telefon Yok' }}</span
        >

        <span class="title"
          ><md-icon class="icon">person</md-icon>{{ userName ? widthBlock(userName.name) : 'Temsilci Atanmamış' }}
        </span>

      </div>
    </div>
    </router-link>

</template>

<script>

export default {
  name: "patientList",
  props: [
    "id",
    "name",
    "country",
    "phone",
    "imgUrl",
    "userId",
    "validateSwitch",
    "users",
    "disabled",
    "userName"
  ],
  data() {
    return {
      localUserId: localStorage.getItem("userId"),
      localRoleId: localStorage.getItem("roleId"),
      formatName: this.name,
      validateControl: "loading",
      color: "#077ab4",
    };
  },
  created() {

    
    let name = this.name;


   
    if (this.disabled) {
    //! İSİM MASKELEME
    let validate = name.match(` `);
    if (validate) {
        if (this.localRoleId == 1) {} else {
            this.formatName = name.substring(0, validate.index) + ` `;
            for (let i = validate.index; i < name.length; i++) {
                this.formatName += "*";
            }
        }
    }

    //NUMARA MASKELEME

    
}
            

  },
  methods: {
    widthBlock(value){
      if(value.length > 17){
        return value.substring(0,18)+"..."
      }else{
        return value
      }
    },
    formatPhone(value){
      let format = ""
      for(let i in value.substring(0,value.length-4)){
        format += "*"
      }
      format+= value.substring(value.length-4,value.length)
      return format
    }
  },
};
</script>

<style scoped>
.card-s {
  width: 300px;
  min-height: 150px;
  background: white;
  margin: 0px 0px 20px 30px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 3px solid white;
  overflow: hidden;
}
.card-s:hover {
  border: 3px solid;
  border-color: rgba(73, 194, 255, 0.2);
  cursor: pointer;
  box-shadow: 0 1.9rem 3.8rem rgba(56, 184, 249, 0.1),
    0 1.5rem 1.2rem rgba(17, 125, 213, 0.05);
  transform: translate(0, -0.25rem) scale(1.0075);
}

.card-s-left {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-s-right {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
}

.card-s-left img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.icon {
  transform: scale(0.6);
  color: black;
}

.title {
  font-family: sans-serif;
  color: black;
  margin-bottom: -10px;
  font-weight: 100;
  width:500px;
  overflow: hidden;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
