<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <div class="md-layout details">
      <div class="md-layout-item md-size-30 patients-wrap md-xsmall-size-100">
        <div class="md-layout-item profile wrapper">
          <file-upload
            ref="upload"
            class="file profile-img"
            v-model="image"
            accept="image/png, image/gif, image/jpeg"
            extensions="gif,jpg,jpeg,png"
          >
            <img
              :src="
                patient.profileImg
                  ? storagepath + patient.profileImg
                  : '/images/avatar.jpg'
              "
              alt="Avatar"
            />
            <img src="/images/add.jpg" class="add" alt />
          </file-upload>

          <div class="main">
            <span class="title">{{ patient.name }}</span>
            {{
              patient.country ? patient.country.name_tr : patient.countryCode
            }}
          </div>
          <div class="secondary">
            <div class="md-layout">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">phone</i>
              </div>
              <div class="md-layout-item">{{ patient.phone }}</div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">mail</i>
              </div>
              <div class="md-layout-item">{{ patient.email }}</div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">date_range</i>
              </div>
              <div class="md-layout-item">
                {{ patient.createdAt ? formatDate(patient.createdAt) : "-" }}
              </div>
            </div>

            <div class="md-layout story">
              <span class="md-title">Hikayesi</span>
              <div class="md-layout-item">{{ patient.story }}</div>
            </div>
            <div class="md-layout story" v-if="directionHistory">
              <span class="md-title">Yönlendirme Hareketleri</span>
              <ul class="md-layout-item">
                <li v-for="(item, index) in directionHistory" :key="index">
                  <span v-html="renderPatientHistory(item)"></span>
                </li>
              </ul>
            </div>

            <div class="md-layout story" v-if="sharingHistory">
              <span class="md-title">Paylaşım Hareketleri</span>
              <ul class="md-layout-item">
                <li v-for="(item, index) in sharingHistory" :key="index">
                  <span v-html="renderPatientSharingHistory(item)"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="md-layout-item hidden">
          <div class="wrapper">
            <span class="md-title">Ameliyat Geçmişi</span>

            <div v-if="surgeries.length == 0">Hiç ameliyat olmamış.</div>
            <div
              class="md-layout surgery"
              v-for="(surgery, index) in surgeries"
              :key="index"
            >
              <md-button
                class="md-fab md-mini md-danger editButton"
                v-on:click="deleteSurgery(surgery.id)"
              >
                <md-icon>delete</md-icon>
              </md-button>
              <span class="head">
                <b>{{ surgery.title }}</b>
                / {{ dateString(surgery.date) }}
              </span>
              {{ surgery.description }}
            </div>
            <br />

            <span class="md-title">Yeni Ameliyat Bilgisi</span>
            <md-field>
              <label>İşlem</label>
              <md-input v-model="surgery.title"></md-input>
            </md-field>

            <md-datepicker v-model="surgery.date" name="date" md-immediately>
              <label>Ne Zaman</label>
            </md-datepicker>
            <md-field>
              <label>Açıklama</label>
              <md-textarea v-model="surgery.description"></md-textarea>
            </md-field>
            <md-button
              class="md-raised md-primary editOkButton"
              v-on:click="addSurgery"
              >Ekle</md-button
            >
          </div>
        </div>
      </div>
      <!--  -->
      <div class="md-layout-item wrapper">
        <md-button
          class="md-fab md-mini md-primary editButton"
          v-on:click="setEditable"
          v-if="!isEditable && !isDietician"
        >
          <md-icon>edit</md-icon>
        </md-button>
        <md-button
          class="md-fab md-mini md-danger editButton"
          v-on:click="updatePatient"
          v-if="isEditable && !isDietician"
        >
          <md-icon>save</md-icon>
        </md-button>
        <span class="md-title">Bilgiler</span>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Ad Soyad</label>
              <md-input
                v-model="patient.name"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Email</label>
              <md-input
                v-model="patient.email"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <br />
            <MazPhoneNumberInput
              v-model="patient.phone"
              :translations="{
                countrySelectorLabel: 'Ülke',
                countrySelectorError: 'Hata',
                phoneNumberLabel: 'Telefon Numarası',
                example: 'Örnek :',
              }"
              :default-phone-number="patientPhone"
              default-country-code="TR"
              :preferred-countries="['TR', 'DE', 'GB', 'NL', 'BG', 'AU']"
              :showCodeOnList="true"
              :disabled="!isEditable"
            />
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <br />
            <MazPhoneNumberInput
              v-model="patient.phone2"
              :translations="{
                countrySelectorLabel: 'Ülke',
                countrySelectorError: 'Hata',
                phoneNumberLabel: '2. Telefon Numarası',
                example: 'Örnek :',
              }"
              :default-phone-number="patientPhone2"
              default-country-code="TR"
              :preferred-countries="['TR', 'DE', 'GB', 'NL', 'BG', 'AU']"
              :showCodeOnList="true"
              :disabled="!isEditable"
            />
          </div>

          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Cinsiyet</label>
              <md-select v-model="patient.sex" v-bind:disabled="!isEditable">
                <md-option value="false">Kadın</md-option>
                <md-option value="true">Erkek</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Doğum Tarihi</label>
              <md-input
                v-model="patient.birthday"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Hasta Sahibi
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.user"
                placeholder="Hasta Sahibi"
                :options="users"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
                v-if="roleId == 1"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Ülke
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.country"
                placeholder="Ülke"
                :options="countries"
                label="name_tr"
                class="md-menu"
                :disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Dil
            <md-field>
              <v-select
                v-model="patient.language"
                placeholder="Dil"
                :options="languages"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
          İkinci Dil
          <md-field>
            <v-select
              v-model="patient.secondLanguage"
              placeholder="Dil"
              :options="languages"
              label="name"
              class="md-menu"
              v-bind:disabled="!isEditable"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            İletişim
            <md-field>
              <md-select
                v-model="patient.sourceId"
                placeholder="İletişim Kanalı"
                v-bind:disabled="!isEditable"
              >
                <md-option
                  v-for="source in sources"
                  v-bind:value="source.id"
                  v-bind:key="source.id"
                  >{{ source.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <!-- Agencie -->
          <!-- Mevcut -->
          <div
            v-if="!isEditable"
            class="md-layout-item md-size-100 md-xsmall-size-100"
          >
            Mevcut Kaynak
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.agencie"
                placeholder="Kaynak"
                :options="agencies"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <!-- For Edit -->
          <!-- Parent -->
          <div
            v-if="isEditable"
            class="md-layout-item md-size-100 md-xsmall-size-100"
          >
            Ana Kaynak
            <md-field>
              <v-select
                :clearable="false"
                v-model="parentAgencie"
                placeholder="Kaynak"
                :options="childDontShow(agencies)"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <!-- Child -->
          <div
            v-if="isEditable && selectingAgenciesChild"
            class="md-layout-item md-size-100 md-xsmall-size-100"
          >
            Alt Kaynak
            <md-field>
              <v-select
                :clearable="true"
                v-model="childAgencie"
                placeholder="Alt Kaynak"
                :options="childAgencies"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <!-- Agencie End -->
          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <span>{{ patient.source ? patient.source : "İletişim" }}</span>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Şehir</label>
              <md-input
                v-model="patient.province"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Adress</label>
              <md-input
                v-model="patient.adress"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Hikayesi</label>
              <md-textarea
                v-model="patient.story"
                v-bind:disabled="!isEditable"
              ></md-textarea>
            </md-field>
          </div>
        </div>
        <!-- <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Firma
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.innerFirm"
                placeholder="Firma"
                :options="firms"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Ne Zaman?

            <md-field>
              <v-select
                v-model="patient.when_is_comes"
                placeholder="Ne Zaman?"
                :options="whens"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Tedavi
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.operation"
                placeholder="Tedavi"
                :options="operations"
                label="name"
                class="md-menu"
                :disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <label>Tahmini Ücret</label>
              <md-input
                v-model="patient.estimated_amount"
                v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Döviz
            <md-field>
              <v-select
                :clearable="false"
                v-model="patientCurrency"
                :options="currencies"
                placeholder="Döviz"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Olasılık
            <md-field>
              <v-select
                :clearable="false"
                v-model="patient.possibility"
                placeholder="Olasılık"
                :options="possibilities"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Durum
            <md-field>
              <v-select
                v-model="patient.patientStatus"
                placeholder="Durum"
                :options="patientStatuses"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
        </div> -->
      </div>
      <!--  -->
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100">
        <!-- Medical -->
        <!-- <div class="md-layout wrapper">
          <span class="md-layout-item md-size-100 md-title"
            >Medikal Bilgiler</span
          >
          <md-checkbox v-model="medicalinfo.allow_edit">Hastanın formu düzenlemesine izin verin.</md-checkbox>
          <md-tabs class="md-transparent" md-alignment="fixed">
            <md-tab id="tab-home" md-label="Medikal Bilgiler">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>Boy (cm)</label>
                  <md-input v-model="medicalinfo.height"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>Kilo(kg)</label>
                  <md-input v-model="medicalinfo.weight"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-100">
                <b>Sağlık Durumu</b>
              </div>
              <div class="md-layout-item md-size-100">
                <md-table class="table appointments medicalhistory">
                  <md-table-row>
                    <md-table-head class="md-size-100"></md-table-head>
                    <md-table-head>Evet</md-table-head>
                    <md-table-head>Hayır</md-table-head>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >Diyabet veya kan problemi</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.diabetes"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.diabetes"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >Tiroid problemi</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.thyroid"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.thyroid"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >Kalp problemi/ kondisyon</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.heart"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.heart"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >Akciğer problemi / performans</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.lungs"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.lungs"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Tansiyon problemi</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.pressure"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.pressure"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Böbrek problemi</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.kidney"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.kidney"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Karaciğer problemi</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.liver"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.liver"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Kan hastalıkları</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.blood"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.blood"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Kanser geçmişi</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.cancer"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.cancer"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>HIV veya AIDS</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.hiv"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.hiv"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >Ruh ve sinir sistemi depresyon</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.psyc"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.psyc"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Nörolojik problemler</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.no"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.no"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>Anestezi problemleri</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.an"
                        :value="true"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.an"
                        :value="false"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </md-tab>
            <md-tab id="tab-pages" md-label="Ameliyat - İlaç">
              <div class="md-layout">
           
                <div class="md-layout-item md-size-100">
                  Daha önce hiç ameliyat oldunuz mu? Veya herhangi bir sebepten
                  hastane de yatmak zorunda kaldınız mı?
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.formerly_surgery"
                      :value="true"
                      >Evet</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.formerly_surgery"
                      :value="false"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.formerly_surgery">
                      <md-textarea
                        v-model="medicalinfo.formerly_surgery_desc"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
 
                <div class="md-layout-item md-size-100">
                  hiç mide ameliyatı oldun mu?
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="no"
                      >Daha Önce Mide Ameliyatı Olmamış</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="sleeve"
                      >Mide Kol</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="bypass"
                      >Mide baypası</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="band"
                      >Mide Bandı</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="plication"
                      >Gastrik Plikasyon</md-radio
                    >
                  <md-radio
                      v-model="medicalinfo.gastric"
                      value="balloon"
                      >Mide Balonu</md-radio
                    >
                  <md-radio
                      v-model="medicalinfo.gastric"
                      value="other"
                      >Diğer</md-radio
                    >
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.gastric=='other'">
                      <md-input
                        v-model="medicalinfo.gastric_other"
                        placeholder="Lütfen açıklayınız"
                      ></md-input>
                    </md-field>
                  </div>
                </div>
                </div>
 
                <div class="md-layout-item md-size-100">
                  Herhangi bir hastalığın var mı?
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.diseases"
                      :value="true"
                      >Evet</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.diseases"
                      :value="false"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.diseases">
                      <md-textarea
                        v-model="medicalinfo.diseases_desc"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
 
                <div class="md-layout-item md-size-100">
                  Vücudunuzda herhangi bir implant veya metal bir cisim var mı?
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.implant" :value="true"
                      >Evet</md-radio
                    >
                    <md-radio v-model="medicalinfo.implant" :value="false"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.implant">
                      <md-textarea
                        v-model="medicalinfo.implant_desc"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
 
                <div class="md-layout-item md-size-100">
                  Yara yeri iyileşmesi ile ilgili bir probleminiz olduğunu
                  düşünüyor musunuz?
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.wound" :value="true"
                      >Evet</md-radio
                    >
                    <md-radio v-model="medicalinfo.wound" :value="false"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.wound">
                      <md-textarea
                        v-model="medicalinfo.wound_desc"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
 
                <div class="md-layout-item md-size-100">
                  Herhangi bir gıdaya veya ilaca alerjiniz var mı?
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.allergy" :value="true"
                      >Evet</md-radio
                    >
                    <md-radio v-model="medicalinfo.allergy" :value="false"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.allergy">
                      <md-textarea
                        v-model="medicalinfo.allergy_desc"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
     
                <div class="md-layout-item md-size-100">
                  <div class="md-layout-item">
                    <label
                      >Kullandığınız ilaçları günlük dozları ile birlikte lütfen
                      sıralayınız.</label
                    >
                    <md-field>
                      <md-textarea
                        v-model="medicalinfo.daily_medicines"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
    
                <div class="md-layout-item md-size-100">
                  <div class="md-layout-item">
                    <label
                      >Şu anda kullanmakta olduğunuz tüm vitamin veya gıda
                      takviyesi ürünlerini listeleyiniz.</label
                    >
                    <md-field>
                      <md-textarea
                        v-model="medicalinfo.vitamin_or_supplement"
                        placeholder="Lütfen açıklayınız"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
               </div>
            </md-tab>
            <md-tab id="tab-posts" md-label="Alerji - Tedavi - Ek Not">
               <div class="md-layout-item md-size-100">
                Hiç Nardil, Marplan veya Parnate gibi bir MAO inhibitörü ilaç
                kullandınız mı?
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.inhibitor" :value="true"
                    >Evet</md-radio
                  >
                  <md-radio v-model="medicalinfo.inhibitor" :value="false"
                    >Hayır</md-radio
                  >
                </div>
              </div>
        
              <div class="md-layout-item md-size-100">
                Kan sulandırı herhangi bir ilaç kullanıyor musunuz?
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.blood_thinner" :value="true"
                    >Evet</md-radio
                  >
                  <md-radio v-model="medicalinfo.blood_thinner" :value="false"
                    >Hayır</md-radio
                  >
                </div>
              </div>
    
              <div class="md-layout-item md-size-100">
                Alkol kullanıyor musunuz?
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.alcohol" :value="true"
                    >Evet</md-radio
                  >
                  <md-radio v-model="medicalinfo.alcohol" :value="false"
                    >Hayır</md-radio
                  >
                </div>
              </div>
   
              <div class="md-layout-item md-size-100">
                Sigara Kullanıyor musunuz?
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.cigarette" :value="true"
                    >Evet</md-radio
                  >
                  <md-radio v-model="medicalinfo.cigarette" :value="false"
                    >Hayır</md-radio
                  >
                </div>
              </div>
 
              <div class="md-layout-item md-size-100">
                Hangi tedavi yöntemimizi düşünüyor sunuz?
                <div class="md-layout-item">
                  <md-field>
                    <v-select
                      :clearable="false"
                      :searchable="false"
                      v-model="requested_treatment"
                      placeholder="Lütfen Düşündüğünüz Tedaviyi Seçiniz"
                      :options="requestedTreatments"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                  <md-field v-if="requested_treatment.name == 'Diğer..'">
                    <label>Lütfen düşünüğünüz tedaviyi belirtiniz</label>
                    <md-input
                      v-model="medicalinfo.requested_treatment"
                    ></md-input>
                  </md-field>
                </div>
              </div>
 
              <div class="md-layout-item md-size-100">
                Daha önce veya şu anda herhangi bir uyuşturucu/madde bağımlılığınız var mı?
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.addiction" :value="true"
                    >Evet</md-radio
                  >
                  <md-radio v-model="medicalinfo.addiction" :value="false"
                    >Hayır</md-radio
                  >
                </div>
                <div class="md-layout-item">
                  <md-field v-if="medicalinfo.addiction">
                    <md-textarea
                      v-model="medicalinfo.addiction_desc"
                      placeholder="Lütfen kullandığınız (veya kullandığınız) maddeleri yazınız ve miktarını belirtiniz"
                    ></md-textarea>
                  </md-field>
                </div>
              </div>
  
              <div class="md-layout-item md-size-100">
                Bizimle paylaşmanız gereken başka bir şey olduğunu düşünüyor
                musunuz?
                <div class="md-layout-item">
                  <md-field>
                    <label>Açıklama</label>
                    <md-textarea
                      v-model="medicalinfo.additional_note"
                    ></md-textarea>
                  </md-field>
                </div>
              </div>
     
            </md-tab>
          </md-tabs>

          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="updateMedicalInfo"
            >Kaydet</md-button
          >
        </div>  
    -->
        <div class="md-layout-item wrapper">
          <span class="md-layout-item md-size-100 md-title"
            >Medikal Bilgiler</span
          >
          <div class="md-layout-item md-size-33 md-xsmall-size-100">
            <md-field>
              <label>Boy</label>
              <md-input v-model="medical.height"></md-input>
              <!-- <span class="md-error">Bu alanı boş bırakmamalısınız!</span> -->
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-xsmall-size-100">
            <md-field>
              <label>Kilo</label>
              <md-input v-model="medical.weight"></md-input>
              <!-- <span class="md-error">Bu alanı boş bırakmamalısınız!</span> -->
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-xsmall-size-100">
            <md-field>
              <label>Yaş</label>
              <md-input v-model="medical.age"></md-input>
              <!-- <span class="md-error">Bu alanı boş bırakmamalısınız!</span> -->
            </md-field>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.bloodThinner"
              >Kan Sulandırıcı</md-checkbox
            >
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.cigarette">Sigara</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.ulcer">Ülser</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.alcohol">Alkol</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.reflux">Reflü</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.allergy">Alerji</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.gastritis">Gastrit</md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-xsmall-size-100">
            <md-checkbox v-model="medical.stomachSurgery"
              >Mide Ameliyatı Geçirmiş Mi ?</md-checkbox
            >
          </div>
          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="saveMedical"
            >Kaydet</md-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment";
var firstPhone = null;
export default {
  name: "Information",
  data() {
    return {
      patient: {},
      sources: [],
      patientsId: this.$route.params.id,
      isEditable: false,
      agencies: [],
      childAgencies: [],
      parentAgencie: null,
      childAgencie: null,
      selectingAgenciesChild: false,
      firms: [],
      roleId: localStorage.getItem("roleId"),
      isDietician: localStorage.getItem("roleId") == 9,
      operations: [],
      currency: [],
      countries: [],
      medicalCount: 0,
      firstPhone: null,
      medical: {
        height: "",
        weight: "",
        age: "",
        reflux: false,
        bloodThinner: false,
        cigarette: false,
        alcohol: false,
        allergy: false,
        gastritis: false,
        ulcer: false,
        stomachSurgery: false,
      },

      languages: [],
      users: [],
      currencies: [],
      image: [],
      possibilities: [
        {
          id: 0,
          name: "Düşük",
        },
        {
          id: 1,
          name: "Orta",
        },
        {
          id: 2,
          name: "Yüksek",
        },
      ],
      patientStatuses: [],
      patientType: null,
      patientPossibility: {},
      patientCurrency: null,
      radio: null,
      medicalinfo: {
        height: null,
        weight: null,
        an: null,
        no: null,
        psyc: null,
        hiv: null,
        cancer: null,
        blood: null,
        liver: null,
        kidney: null,
        pressure: null,
        lungs: null,
        heart: null,
      },
      firstMedicalInfo: true,
      surgery: {
        title: "",
        date: null,
        description: "",
        patientId: this.$route.params.id,
      },
      surgeries: [],
      storagepath: this.$storage,
      requested_treatment: {
        name: "Lütfen Düşündüğünüz Tedaviyi Seçiniz",
      },
      whens: [],
      requestedTreatments: [
        {
          name: "Tüp Mide",
        },
        {
          name: "Şeker Ameliyatı",
        },
        {
          name: "Mide Balonu",
        },
        {
          name: "Mide Botoksu",
        },
        {
          name: "Diğer..",
        },
      ],
      patientPhone: "",
      directionHistory: [],
      sharingHistory: [],
      patientPhone2: "",
    };
  },
  components: {
    Navigation,
    Topbar,
    MazPhoneNumberInput,
  },
  created() {
    HTTP.get("/patientstatus/").then((result) => {
      this.patientStatuses = result.data;
    });
    HTTP.get("/whens/").then((result) => {
      this.whens = result.data;
    });
    HTTP.get("/countries/").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/languages/").then((result) => {
      this.languages = result.data;
    });
    HTTP.get("/patients/" + this.patientsId).then((result) => {
      this.patient = result.data[0];

      this.patientType = {
        id: this.patient.type,
        name:
          this.patient.type == "0"
            ? "Havuz"
            : this.patient.type == "1"
            ? "Aktif"
            : this.patient.type == "2"
            ? "Pasif"
            : this.patient.type,
      };

      this.patientCurrency = {
        name: this.patient.currencySlug,
      };
      this.patientPossibility = {
        id: this.patient.possibility,
        name:
          this.patient.possibility == "0"
            ? "Düşük"
            : this.patient.possibility == "1"
            ? "Orta"
            : this.patient.possibility == "2"
            ? "Yüksek"
            : this.patient.possibility,
      };

      this.patientPhone = this.patient.phone;
      this.patientPhone2 = this.patient.phone2;
      firstPhone = this.patient.phone;

      this.directionHistory = this.patient.directionHistory
        ? JSON.parse(this.patient.directionHistory)
        : [];

      let sharingHistory = this.patient.sharingHistory
        ? JSON.parse(this.patient.sharingHistory)
        : [];

      for (let i in this.directionHistory) {
        if (this.directionHistory[i]?.isShared) {
          sharingHistory.push(this.directionHistory[i]);
        }
      }

      this.sharingHistory = [...sharingHistory];
    });

    HTTP.get("/sources/").then((result) => {
      this.sources = result.data;
    });

    HTTP.get("/firms/").then((result) => {
      this.firms = result.data;
    });
    HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
    });
    HTTP.get("/currency/").then((result) => {
      this.currencies = result.data.map((item) => {
        return { name: item.title, id: item.id, slug: item.slug };
      });
    });

    HTTP.get("/users/").then((result) => {
      this.users = result.data;
    });
    this.getMedicalInfo();
    this.getSurgeries();
    HTTP.get("/agencies/").then((result) => {
      this.agencies = result.data;

      const currAgencie = result.data?.find(
        (a) => a.id == this.patient?.agencie?.id
      );

      if (currAgencie?.parentId) {
        this.selectingAgenciesChild = true;
        let parentAgencieStart = result?.data?.filter(
          (a) => a.id === currAgencie?.parentId
        )?.[0];
        this.parentAgencie = parentAgencieStart;
        this.childAgencie = currAgencie;
        this.childAgencies = parentAgencieStart.childAgencies;
      } else if (currAgencie?.childAgencies?.length > 0) {
        this.selectingAgenciesChild = true;
        this.parentAgencie = currAgencie;
        this.childAgencies = currAgencie?.childAgencies;
      } else {
        this.parentAgencie = currAgencie;
      }

      setTimeout(() => {
        this.patient.agencieId = this.agencies.filter(
          (a) => a.id == this.patient.agencieId
        )[0].name;
      }, 200);
    });

    let params = {
      patientId: this.$route.params.id,
    };
    HTTP.get(
      "appointments/getNotSurgeryMedicalFormById?" +
        this.serializeURLQueryParams(params)
    ).then((result) => {
      if (result.data.length != 0) {
        this.medical = result.data[result.data.length - 1];
      }
      {
        this.medicalCount = 0;
      }
    });
  },
  methods: {
    saveMedical: function() {
      let params = {
        patientId: this.$route.params.id,
        height: this.medical.height,
        weight: this.medical.weight,
        age: this.medical.age,
        reflux: this.medical.reflux,
        bloodThinner: this.medical.bloodThinner,
        cigarette: this.medical.cigarette,
        alcohol: this.medical.alcohol,
        allergy: this.medical.allergy,
        gastritis: this.medical.gastritis,
        ulcer: this.medical.ulcer,
        stomachSurgery: this.medical.stomachSurgery,
        userId: localStorage.getItem("userId"),
      };
      if (this.medicalCount > 0) {
        HTTP.post("appointments/updateNotSurgeryMedicalForm", params)
          .then((res) => {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            this.message = "Bir şeyler ters gitti";
            console.log(e);
            console.log(e.message);
          });
      } else {
        HTTP.post("/appointments/addNotSurgeryMedicalForm/", params)
          .then(async (res) => {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            this.message = "Bir şeyler ters gitti";
            console.log(e);
            console.log(e.message);
          });
      }
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    getUserName(userId) {
      var user = this.users.find((z) => z.id == userId);
      return user ? user.name : "";
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    renderPatientHistory(item) {
      let text = "";
      if (item.directedBy == item.directedTo)
        text = `<b>${this.formatDate(
          item.directedAt
        )}</b> tarihinde <b>${this.getUserName(
          item.directedBy
        )}</b> tarafından havuza eklenmiştir.`;
      else {
        if (item.directedBy == 0) text = `Kaynaklardan gelen formlardan `;
        text +=
          `<b>${this.formatDate(
            item.directedAt
          )}</b> tarihinde <b>${this.getUserName(item.directedTo)}</b> ` +
          (item.directedBy == 0
            ? `kullanıcıya gelmiştir.`
            : `kullanıcıya yönlendirilmiştir.`);
      }

      return text;
    },
    renderPatientSharingHistory(item) {
      return `<b>${this.getUserName(
        item.directedBy
      )}</b> tarafından <b>${this.formatDate(
        item.directedAt
      )}</b> tarihinde <b> tarihinde <b>${this.getUserName(
        item.directedTo
      )}</b> adlı kullanıcıya paylaşılmıştır.`;
    },
    updatePatient: async function() {
      if (this.childAgencie) {
        this.patient.agencie = this.childAgencie;
        this.patient.agencieId = this.childAgencie.id;
      } else {
        this.patient.agencie = this.parentAgencie;
        this.patient.agencieId = this.parentAgencie.id;
      }

      if (this.patient.user) {
        this.patient.userId = this.patient.user.id;
      }
      if (this.patient.innerFirm) {
        this.patient.innerFirmId = this.patient.innerFirm.id;
      }
      if (this.patient.country) {
        this.patient.countryCode = this.patient.country.code;
      }
      if (this.patientCurrency) {
        this.patient.currencySlug = this.patientCurrency.slug;
      }

      if (this.patient.possibility) {
        this.patient.possibility = this.patient.possibility.id;
      }

      if (this.patient.patientStatus) {
        this.patient.status = this.patient.patientStatus.id;
      }
      if (this.patient.when_is_comes) {
        this.patient.when_is_comes = this.patient.when_is_comes.name;
      }

      if (this.patient.language) {
        this.patient.languageId = this.patient.language.id;
      }

      var val = this.patient.phone;
      let res = [];
      let res1 = await HTTP.get(
        `/patients/?q=${val.replace("+", "")}&limit=1&potantials=true`
      );
      let res2 = await HTTP.get(
        `/patients/?q=${val.replace("+", "")}&limit=1&potantials=false`
      );
      if (res1.data[0]) {
        res.push(res1.data[0]);
      }

      if (res2.data[0]) {
        res.push(res2.data[0]);
      }

      if (this.phoneUniq)
        this.oldPatient = JSON.parse(JSON.stringify(this.patient));

      this.phoneUniq = res.every((p) => p.phone != val);
      res.reverse();
      let patient = res[1] ? res[1] : res[0];
      patient = res[0] ? res[0] : undefined;

      if (!this.phoneUniq && this.patient.phone != firstPhone) {
        if (!this.phoneUniq) {
          this.$swal("", "Telefon Numarası Daha Önce Kayıtlı!", "error");
        }
      } else {
        HTTP.put("/patients/" + this.patient.id, this.patient).then(
          (result) => {
            if (result.status == 204) {
              this.isEditable = false;
              this.$swal("", "Başarıyla güncellendi", "success");
            }
          }
        );
      }
    },
    setEditable: function() {
      this.isEditable = true;
    },
    getMedicalInfo: function() {
      HTTP.get("/medicalinfos/" + this.$route.params.id).then((result) => {
        if (result.data[0]) {
          this.firstMedicalInfo = false;
          this.medicalinfo = result.data[0];
          if (this.medicalinfo.requested_treatment) {
            this.requested_treatment.name = this.medicalinfo.requested_treatment;
          }
        }
      });
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    updateMedicalInfo: function() {
      if (this.firstMedicalInfo) {
        this.medicalinfo.patientId = this.patient.id;
        HTTP.post("/medicalinfos/", this.medicalinfo)
          .then(() => {
            this.$swal("", "Eklendi", "success");
          })
          .catch(() => {
            this.$swal("Eklenirken hata oluştu");
          });
      } else {
        HTTP.put("/medicalinfos/" + this.patient.id, this.medicalinfo)
          .then(() => {
            this.$swal("", "Güncellendi", "success");
          })
          .catch(() => {
            this.$swal("Güncellenirken hata oluştu");
          });
      }
    },
    getSurgeries: function() {
      HTTP.get("/surgeries/" + this.patientsId).then((result) => {
        this.surgeries = result.data;
      });
    },
    addSurgery: function() {
      HTTP.post("/surgeries", this.surgery).then((result) => {
        if (result.status == 201) {
          this.getSurgeries();
        }
      });
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear();
      return startText;
    },
    deleteSurgery: function(id) {
      HTTP.delete("surgeries/?ids=" + id).then(() => {
        this.getSurgeries();
      });
    },
  },
  watch: {
    image: function() {
      let fd = new FormData();
      fd.append("image", this.image[0].file, this.image[0].name);

      HTTP.post("/patients/" + this.patientsId + "/profileimage", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then(() => {
        HTTP.get("/patients/" + this.patientsId).then((result) => {
          this.patient = result.data[0];
        });
      });
    },
    requested_treatment: function(val) {
      if (val.name != "Diğer..") {
        this.medicalinfo.requested_treatment = val.name;
      } else {
        this.medicalinfo.requested_treatment = null;
      }
    },
    parentAgencie: function(val) {
      if (val?.childAgencies?.length > 0) {
        this.selectingAgenciesChild = true;
        this.childAgencies = val.childAgencies;
        let included = this.childAgencies.find(
          (c) => c.id === this.childAgencie?.id
        );
        if (!included) {
          this.childAgencie = null;
        }
      } else {
        this.selectingAgenciesChild = false;
        this.childAgencie = null;
      }
    },
  },
};
</script>

<style lang="scss">
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.editOkButton {
  width: 100%;
  margin: 0 !important;
}
.vs--disabled * {
  background: transparent !important;
}
.medicalhistory {
  height: auto;
  max-height: auto;
}
.surgery {
  margin: 10px 0px;
  padding: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  .head {
    width: 100%;
    display: block;
  }
}
.profile-img {
  overflow: visible !important;
}
.profile {
  .add {
    position: absolute;
    left: calc(50% - 85px);
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .add {
      opacity: 1;
    }
  }
}
.file.profile-img {
  label {
    top: -100px !important;
    height: 200% !important;
  }
}
.md-tabs {
  width: 100%;
}
.maz-input.is-disabled .maz-input__label,
.maz-input.is-disabled .maz-input__input,
.maz-input.is-disabled .maz-input__toggle__arrow {
  cursor: auto;
  color: #000;
}
.maz-input.is-disabled.is-valid {
  border: 0;
}
</style>
