<template>
  <div>
    <h1>{{ title }}</h1>

    <md-dialog-confirm
      :md-active.sync="deleteModal"
      md-title="Kaynağı silmek istediğinize emin misiniz?"
      md-content="Bu işlem geri alınamaz."
      md-confirm-text="Sil"
      md-cancel-text="İptal Et"
      @md-confirm="deleteModalOnConfirm"
      style="z-index: 20 !important"
    />

    <md-dialog :md-active.sync="addAgencieModal" class="dialog">
      <div
        :class="
          viewSelectBox ? 'dialog-container-add-height' : 'dialog-container'
        "
      >
        <h3>Yeni Kaynak</h3>

        <md-field>
          <label>Kaynak Adı *</label>
          <md-input v-model="addAgencieValues.name"></md-input>
        </md-field>
        <md-field>
          <label class="list-title" style="padding-bottom: 5px;"
            >Üst Kaynak</label
          >
          <v-select
            v-model="addAgencieValues.parentAgencie"
            placeholder="İletişim"
            :options="childDontShow(agenciesList)"
            label="name"
            class="md-menu"
            @search:focus="clickSelectBox"
            @search:blur="closeSelectBox"
            style="margin-left:-8px;margin-top:10px"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="addAgencie"
          >Ekle</md-button
        >
      </div>
    </md-dialog>

    <md-dialog :md-active.sync="updateAgencieModal" class="dialog">
      <div
        :class="
          viewSelectBox ? 'dialog-container-add-height' : 'dialog-container'
        "
      >
        <h3>Kaynak Güncelle</h3>
        <md-field>
          <label>Kaynak Adı *</label>
          <md-input v-model="agencie.name"></md-input>
        </md-field>
        <div v-if="agencie.childAgencies.length == 0">
          <md-field>
            <label class="list-title" style="padding-bottom: 5px;"
              >Üst Kaynak</label
            >
            <v-select
              v-model="agencie.parentAgencie"
              placeholder="İletişim"
              :options="childDontShow(agenciesList)"
              label="name"
              class="md-menu"
              @search:focus="clickSelectBox"
              @search:blur="closeSelectBox"
              style="margin-left:-8px;margin-top:10px"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateAgencie"
          >Güncelle</md-button
        >
      </div>
    </md-dialog>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <div class="md-layout-item">
          <span class="md-title title">Kaynaklar</span>
          <div
            style="position:absolute; right:0; margin-right:30px;margin-top:-35px;z-index: 0;"
          >
            <md-button
              class="md-fab md-mini md-primary addButton"
              @click="addAgencieModal = true"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>
        </div>

        <div :key="index" v-for="(item, index) in agencies">
          <div v-if="item.parentId == null" class="item-card">
            <div class="item-card-top">
              <div class="item-card-child-card-left">
                <span class="item-card-child-name">{{ item.name }}</span>
              </div>
              <div class="item-card-top-right-buttons">
                <div class="switch-buttons">
                  <md-switch
                    @change="notificationChange(item)"
                    v-model="item.whatsappNotification"
                    class="md-primary"
                  >
                    Whatsapp</md-switch
                  >
                  <md-switch
                    @change="notificationChange(item)"
                    v-model="item.mailNotification"
                    class="md-primary"
                    >E-Mail
                  </md-switch>
                  <md-switch
                    @change="notificationChange(item)"
                    v-model="item.isAddedManuelData"
                    class="md-primary"
                    >Manuel Data Ekleyebilir
                  </md-switch>
                </div>
                <md-icon @click.native="showUpdateAgencie(item.id)"
                  >edit</md-icon
                >
                <md-icon
                  v-if="!item.childAgencies.length > 0"
                  @click.native="delAgencie(item.id)"
                  >delete</md-icon
                >
              </div>
            </div>
            <div class="item-card-bottom">
              <div
                class="item-child-card-frame"
                :key="index"
                v-for="(item, index) in item.childAgencies"
              >
                <div v-if="!item.isDeleted" class="item-card-child-card">
                  <div class="item-card-child-card-left">
                    <span class="item-card-child-name-top">{{
                      item.name
                    }}</span>
                  </div>
                  <div class="item-card-child-card-right">
                    <md-switch
                      @change="notificationChange(item)"
                      v-model="item.whatsappNotification"
                      class="md-primary"
                    >
                      Whatsapp</md-switch
                    >
                    <md-switch
                      @change="notificationChange(item)"
                      v-model="item.mailNotification"
                      class="md-primary"
                      >E-Mail
                    </md-switch>
                    <md-switch
                      @change="notificationChange(item)"
                      v-model="item.isAddedManuelData"
                      class="md-primary"
                      >Manuel Data Ekleyebilir
                    </md-switch>
                    <md-icon @click.native="showUpdateAgencie(item.id)"
                      >edit</md-icon
                    >
                    <md-icon @click.native="delAgencie(item.id)"
                      >delete</md-icon
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="index != agencies.length - 1 && item.parentId == null"
            class="line"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../main-source";
export default {
  name: "Marketing",
  data() {
    return {
      title: "Pazarlama",
      addAgencieModal: false,
      updateAgencieModal: false,
      agencie: {
        name: "",
        parentAgencie: null,
        childAgencies: [],
      },
      addAgencieValues: {
        name: "",
        parentAgencie: null,
        childAgencies: [],
      },
      agencies: [],
      deleteModal: false,
      activeAgencieId: "",
      viewSelectBox: false,
      addAgencieName: "",
      agenciesList: [],
    };
  },
  created: function() {
    this.getAgencies();
  },
  methods: {
    async getAgencies() {
      await HTTP.get("/agencies/").then((result) => {
        this.agencies = result.data;
        result.data.filter((item) => {
          !item.parentId && this.agenciesList.push(item);
        });
      });
    },
    async notificationChange(item) {
      const { id } = item;
      console.log(item);
      let data = {
        id: id,
        whatsappNotification: item.whatsappNotification,
        mailNotification: item.mailNotification,
        isAddedManuelData: item?.isAddedManuelData,
      };

      await HTTP.post("/agencies/updateNotification", data);
    },
    async addAgencie() {
      if (this.addAgencieValues.name.length > 0) {
        HTTP.post("/agencies/", this.addAgencieValues).then((result) => {
          if (result.status == 201) {
            this.getAgencies();
            this.addAgencieValues.name = "";
            this.addAgencieValues.parentAgencie = null;
            this.addAgencieModal = false;
          }
        });
      } else {
        alert("Lütfen gerekli bilgileri giriniz..");
      }
    },
    agenciesGetName(id) {
      for (let i in this.agencies) {
        if (this.agencies[i].id == id) {
          return this.agencies[i].name;
        }
      }
    },
    delAgencie: function(id) {
      this.deleteModal = true;
      this.activeAgencieId = id;
    },
    deleteModalOnConfirm: function() {
      const id = this.activeAgencieId;
      const agencieName = this.agenciesGetName(id);
      HTTP.delete("/agencies/?ids=" + id + "&name=" + agencieName).then(
        (result) => {
          if (result.status == 204) {
            this.getAgencies();
          }
        }
      );
    },
    showUpdateAgencie: function(id) {
      this.updateAgencieModal = true;
      this.agencie = this.agencies.filter((a) => a.id == id)[0];
      this.agencie.parentAgencie = this.agencies.filter(
        (a) => a.id == this.agencie.parentId
      )[0];
    },
    updateAgencie: function() {
      HTTP.put("/agencies/" + this.agencie.id, this.agencie).then((result) => {
        if (result.status == 204) {
          this.updateAgencieModal = false;
          this.getAgencies();
        }
      });
    },
    clickSelectBox: function() {
      this.viewSelectBox = true;
    },
    closeSelectBox: function() {
      this.viewSelectBox = false;
    },
    isDeletedParentDeleteControl: function(childAgencies) {
      let childAgenciesLength = childAgencies.length;

      if (childAgencies.length > 0) {
        let control = [];

        for (let i in childAgencies) {
          if (childAgencies[i].isDeleted) {
            control.push(childAgencies[i].isDeleted);
          }
        }

        if (childAgenciesLength == control.length) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
  },
  watch: {
    addAgencieModal: function(val) {
      (this.addAgencieName = ""), (this.addAgencieParent = "");
    },
    updateAgencieModal: function() {
      if (!this.updateAgencieModal) {
        this.agencie.parentAgencie = null;
      }
    },
  },
};
</script>

<style lang="scss">
.addButton {
  float: right;
}
.table-actions {
  width: 120px;
  i {
    float: left;
    margin-right: 5px;
  }
}
</style>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.list-title {
  margin-bottom: 0px;
  font-size: 12px;
  color: #757575;
}

.item-card {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 3px solid transparent;
  justify-content: space-between;
  transition: 0.5s;
}

.item-card-top {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.item-card-bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.item-card:hover {
  border: 3px solid;
  border-color: rgba(73, 194, 255, 0.2);
  cursor: pointer;
  box-shadow: 0 1.9rem 3.8rem rgba(56, 184, 249, 0.1),
    0 1.5rem 1.2rem rgba(17, 125, 213, 0.05);
  transform: translate(0, -0.25rem) scale(1.0075);
}

.item-card-main-name {
  font-weight: 500;
  font-size: 14.5px;
}

.item-card-child-name {
  font-weight: 300;
  font-size: 13.5px;
}

.item-card-child-name-top {
  font-weight: 300;
  font-size: 13.5px;
}

.item-card-child-card {
  display: flex;
  width: 50vw;
  height: 55px;
  background: white;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  transition: 0.5s;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
}

.item-card-child-card:hover {
  border: 1px solid rgb(56, 184, 249);
  box-shadow: 0px 0px 10px rgba(56, 184, 249, 0.2);
  transform: scale(1.05);
}

.item-card-child-card-left {
  display: flex;
  align-items: center;
}

.item-card-child-card-right {
  transform: scale(0.8);
  display: flex;
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgb(222, 222, 222);
}

.dialog-container {
  width: 100%;
  height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}

.dialog-container-add-height {
  width: 100%;
  height: 550px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}

.item-card-top-right-buttons {
  display: flex;
}
.switch-buttons {
  display: flex;
  transform: scale(0.9);
}

@media (max-width: 750px) {
  .item-card-child-card {
    width: 100%;
    height: 100px;
    margin-top: 5px;
    margin-bottom: 7px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .item-card-child-name-top {
    margin-top: 20px;
    margin-left: 5px;
  }

  .item-card-child-card-right {
    width: 100%;
    transform: scale(0.8);
    display: flex;
    margin-left: -20px;
  }

  .item-card-bottom {
    flex-direction: column;
  }

  .item-card-top {
    flex-direction: column;
    height: 80px;
  }

  .item-card-top-right-buttons {
    margin-left: -10px;
  }
}
</style>
