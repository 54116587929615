<template>
  <div>
    <Topbar/>
    <Nav/>
    <router-view></router-view>
  </div>
</template>

<script>
// import userList from "../../components/user/list";

import Topbar from '../../../components/user/topbar.vue'
import Nav from "../../../components/user/nav.vue";

export default {
  name: "Profile",
  components: {
    Topbar,
    Nav,
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors.scss";

.md-dialog-content {
  color: #f00;
}

.error {
  display: block !important;
  color: #f00;
  transform: translate3d(0, -8px, 0);
  height: -19px;
  position: inherit;
  font-size: 1rem;

  border-bottom: inset;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}



.wrap {
  margin: 0px -15px;
}
.item {
  min-height: 200px;
  margin: 15px;
  &:last-child {
    margin-right: 5px;
  }
}
.profile.wrapper {
  margin-top: 75px;
}
.wrapper {
  position: relative;
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}
.profile {
  text-align: center;
  img {
    width: 170px !important;
    height: 170px !important;
    border-radius: 50%;
    margin-top: -90px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }
  span {
    display: block;
  }
  .title {
    font-weight: bold;
  }
  .md-layout {
    text-align: left;
  }
  .icon {
    padding: 0px 10px;
    margin-right: 10px;
  }
  .main {
    margin: 15px 0px;
  }
  .secondary {
    margin: 30px 0px 15px 0px;
    .md-layout {
      margin-top: 10px;
      color: $dark;
    }
  }
}
.md-icon-button.delete {
  background: $red !important;
  .icon {
    color: $white !important;
  }
}
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.md-scrollbar {
  max-height: 80vh !important;
  overflow: auto;
}
.editOkButton {
  width: 100%;
  margin: 0 !important;
}
.vue-phone-number-input {
  width: 100%;
}
</style>

