<template>
  <div>
    <div :style="{ 'min-width': width + 'px' }" class="select-list-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "newInput",
  props: ["width"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.select-list-box {
  min-width: 180px;
  max-width: 200px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 1.5px solid #d9e2ec;
  transition: 0.5s;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-right: 5px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.select-list-box:hover {
  border: 1.5px solid #8cb3e0;
  box-shadow: 0px 0px 30px rgba(0, 111, 238, 0.3);
}

@media (max-width: 500px) {
  .select-list-box {
    min-width: 80vw;
  }
}
</style>
