<template>
 
    <div class="md-layout details">
      <BigLoading v-if="loading"/>
      <div v-if="!loading" class="md-layout-item wrapper md-size-100">
      
        <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable"
              v-if="isDisabled"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled">
              <md-icon>save</md-icon>
            </md-button>
            
        <div  v-if="roleId == 1">
          <span class="md-title">İletişim Kaynakları</span>
          <md-field>
            <label>Arayınız...</label>
            <md-input v-model="sourceSearchInput"></md-input>
            <md-icon>search</md-icon>
          </md-field>
          <br/>
          <md-checkbox
           v-model="selectAllSources"
           :disabled="isDisabled"
           > Tümünü Seç </md-checkbox>
          <md-checkbox
            v-for="source in searchFilter(sources,sourceSearchInput)"
            v-bind:key="source.id"
            v-bind:value="source.id"
            v-model="checkedSources"
            v-bind:disabled="isDisabled"
            >{{ source.name }}</md-checkbox
          >
        </div>

        </div>
      </div>

</template>

<script>
// import userList from "../../components/user/list";
import { HTTP } from "../../../main-source";
import BigLoading from "../../../components/bigLoading/index.vue";

export default {
    name: "Profile",
    data() {
        return {
            id: this.$route.params.id,
            selectAllSources: false,
            profile: [],
            roles: [],
            sources: [],
            users: [],
            checkedSources: [],
            isDisabled: true,
            roleId: localStorage.getItem("roleId"),
            storagepath: this.$storage,
            sourceSearchInput: "",
            loading: true
        };
    },
    created() {
        HTTP.get("/sources/").then(result => {
            this.sources = result.data;
        });
    
        HTTP.get("/users/" + this.id + "/getUserAllSources").then(result => {

            result.data[0].UserToSources.length == 0 ?  this.loading = false :  this.loading = false
            
            result.data[0].UserToSources.forEach(sources => {
                this.checkedSources.push(sources.id);
            
            });
        });

    },
    methods: {
        editable: function () {
            this.isDisabled = !this.isDisabled;
        },
        editInformation: function () {
            // 
            HTTP.post("/users/" + this.id + "/updateAllSources", {
                sources: this.checkedSources
            }).then(result => {
                if (result.status == 204) {
                    this.editable();
                }
            });
        },
        searchFilter: function (data, search) {
            let searchValue = search.toLowerCase();
            return data.filter(item => item.name.toLowerCase().match(searchValue) ? item : null);
        }
    },
    watch: {
        "selectAllSources": function () {
            if (this.selectAllSources) {
                this.checkedSources = [];
                this.sources.forEach(source => {
                    this.checkedSources.push(source.id);
                });
            }
            else {
                this.checkedSources = [];
            }
        },
    },
    components: { BigLoading }
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors.scss";


.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}


</style>

