<template>
  <div>
    <div class="sub-nav hidden-xs" v-if="isPatient">
      <router-link
        :to="{
          name: 'patients_details',
          params: { id: id },
        }"
      >Genel Bakış</router-link>
      <router-link
        :to="{
          name: 'patients.information',
          params: { id: id },
        }"
      >Kişisel Bilgiler</router-link>
      <router-link
        :to="{
          name: 'patients.appointments',
          params: { id: id },
        }"
      >Randevular</router-link>
      <router-link
        :to="{
          name: 'patients.activities',
          params: { id: id },
        }"
      >Aktiviteler</router-link>
      <router-link
        :to="{
          name: 'patients.patientpotantial',
          params: { id: id },
        }"
      >Satış Fırsatları</router-link>
      <router-link
        :to="{
          name: 'patients.files',
          params: { id: id },
        }"
      >Dosyalar</router-link>

   <router-link
        :to="{
          name: 'patients.epicrisis',
          params: { id: id },
        }"
      >Epikriz</router-link>

      <router-link
      v-if="roleId == 1"
        :to="{
          name: 'patients.confirmationForms',
          params: { id: id },
        }"
      >Onay Formları</router-link>
      
      <router-link
        v-if="!isDietician"
        :to="{
          name: 'patients.offers',
          params: { id: id },
        }"
      >Teklifler</router-link>
      <router-link
        :to="{
          name: 'patients.mails',
          params: { id: id },
        }"
      >E-Postalar</router-link>
    </div>
    <div class="sub-nav hidden-xs" v-if="!isPatient">
      <router-link
        :to="{
          name: 'potantial.patients',
          params: { id: id },
        }"
      >Genel Bakış</router-link>
      <router-link
        :to="{
          name: 'potantial.patients.activities',
          params: { id: id },
        }"
      >Aktiviteler</router-link>
      <router-link
        :to="{
          name: 'potantial.patients.information',
          params: { id: id },
        }"
      >Kişisel Bilgiler</router-link>
      <router-link
        :to="{
          name: 'potantial.patients.files',
          params: { id: id },
        }"
      >Dosyalar</router-link>

      <router-link
      v-if="!isDietician"
        :to="{
          name: 'potantial.patients.offers',
          params: { id: id },
        }"
      >Teklifler</router-link>
      <router-link
        :to="{
          name: 'potantial.patients.mails',
          params: { id: id },
        }"
      >E-Postalar</router-link>
    </div>
    <md-menu md-direction="bottom-start" v-if="innerWidth < 769">
      <md-button md-menu-trigger>
        <md-icon>menu</md-icon>
        {{ $route.meta.name }}
      </md-button>

      <md-menu-content>
        <!-- <md-menu-item>My Item 1</md-menu-item> -->
        <md-menu-item>
          <router-link
            :to="{
          name: 'potantial.patients',
          params: { id: id },
        }"
          >Genel Bakış</router-link>
        </md-menu-item>
        <md-menu-item v-if="!isPatient">
          <router-link
            :to="{
          name: 'potantial.patients.activities',
          params: { id: id },
        }"
          >Aktiviteler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
          name: 'potantial.patients.information',
          params: { id: id },
        }"
          >Kişisel Bilgiler</router-link>
        </md-menu-item>
        <md-menu-item v-if="isPatient">
          <router-link
            :to="{
          name: 'patients.appointments',
          params: { id: id },
        }"
          >Randevular</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
          name: 'potantial.patients.files',
          params: { id: id },
        }"
          >Dosyalar</router-link>
        </md-menu-item>

        <md-menu-item>
          <router-link
            :to="{
          name: 'potantial.patients.offers',
          params: { id: id },
        }"
          >Teklifler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
          name: 'potantial.patients.mails',
          params: { id: id },
        }"
          >E-Postalar</router-link>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";

export default {
  name: "Navigation",
  data() {
    return {
      roleId: localStorage.getItem("roleId"),
      isDietician: localStorage.getItem("roleId") == 9,
      deleteModal: false,
      isPatient: false
    };
  },
  props: ["id"],
  created() {
    if (this.id) {
      HTTP.get("patients/" + this.id).then(result => {
        this.isPatient = result.data[0].type;
        //console.log(this.isPatient);
      });
    }
  },
  methods: {},
  computed: {
    innerWidth: function() {
      return window.innerWidth;
    },
    getRoleId: function() {
      let role = "";
      const cookies = document.cookie.split(";");
      cookies.forEach(cookie => {
        if (cookie.split("=")[0].trim() == "role") {
          role = `${cookie.split("=")[1]}`;
        }
      });
      return role;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors.scss";
.sub-nav {
  a,
  button {
    background: $white;
    border-left: 1px solid lighten($dark, 80%);
    border-top: 1px solid lighten($dark, 80%);
    border-bottom: 1px solid lighten($dark, 80%);
    padding: 8px 30px;
    color: $dark !important;
    text-decoration: none !important;
    &:first-child {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }
    &:last-child {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-right: 1px solid lighten($dark, 80%);
    }
  }
  .router-link-exact-active,
  .active {
    color: $brandcolor !important;
    font-weight: bold;
  }
}
.setLocation.md-top-right {
  top: unset !important;
  margin-top: -30px !important;
  button.md-fab {
    background: #ccc !important;
  }
}
</style>
