/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import { HTTP } from "@/main-source";
import store from "@/store";
import nprogress from "nprogress";
import AppointmentsList from "@/views/Home.vue";
import ActivityList from "@/views/ActivityList.vue";
import Login from "@/views/Login.vue";
import Patients from "@/views/patients/Index.vue";
import MedicalHistory from "@/views/patients/MedicalHistory.vue";
import PatientAppointments from "@/views/patients/potantials/patients/Appointments";

import Payments from "@/views/patients/Payments.vue";
import Transfers from "@/views/patients/Transfers.vue";

// Potantial Patients
import PotantialPatient from "@/views/patients/potantials/patients/Index";
import PotantialPatientActivites from "@/views/patients/potantials/patients/Activities";
import PotantialInformation from "@/views/patients/potantials/patients/Information";
import PotantialOffers from "@/views/patients/potantials/patients/Offers";
import PotantialMails from "@/views/patients/potantials/patients/Mails";
import PotantialList from "@/views/patients/potantials/patients/List";
import PotantialFiles from "@/views/patients/potantials/patients/Files";
import PotantialQuestionnaire from "@/views/patients/potantials/patients/Questionnaire";
import PotantialConfirmationForms from "@/views/patients/potantials/patients/ConfirmationForms";

import UserProfile from "@/views/users/profile/index.vue";
import UserProfileGeneral from "@/views/users/profile/general.vue";
import UserProfileLocations from "@/views/users/profile/locations.vue";
import UserProfileTeam from "@/views/users/profile/withoutDoctors.vue";
import UserProfileBranchesDoctors from "@/views/users/profile/branchesDoctors.vue";
import UserProfileAgencies from "@/views/users/profile/agencies.vue";
import UserProfileClosedDays from "@/views/users/profile/closedDays.vue";
import UserProfileOld from "@/views/users/profile/oldProfile.vue";
import UserProfileAllSources from "@/views/users/profile/allSources.vue";

import AddPatient from "@/views/patients/Create.vue";
import CreatePatient from "@/views/createPatient/index.vue";
import Finance from "@/views/Finance.vue";
import Marketing from "@/views/Marketing.vue";
import Users from "@/views/users/Index";
import Profile from "@/views/users/Profile";
import CreateUser from "@/views/users/Create.vue";
import Reports from "@/views/Reports.vue";
import SalesReport from "@/views/reports/SalesReport.vue";
import ProgressPaymentReport from "@/views/reports/ProgressPaymentReport.vue";
import Home from "@/views/AppointmentsList.vue";
import CreateAppointment from "@/views/createAppointment.vue";
import UpdateAppointment from "@/views/updateAppointment.vue";
import Settings from "@/views/Settings.vue";
import PotantialEpicrisis from "@/views/patients/Epicrisis.vue";
import UpdateEpicrisis from "@/views/patients/updateEpicrisis.vue";
import PatientPotantial from "@/views/patients/PatientPotantial.vue";
import UpdateActivitie from "@/views/updateActivitie.vue";
import EpicrisisContents from "@/views/Epicrises.vue";
import UpdateEpicrisisContent from "@/views/patients/epicrisis/EditEpicrisisContent.vue";
import ConfirmationTemplates from "../views/confirmationDocuments/ConfirmationDocuments.vue";
import UpdateConfirmationTemplate from "../views/confirmationDocuments/UpdateConfirmationDocument.vue";
import PreviewConfirmationFile from "../views/PreviewConfirmationFile.vue";
import AppoinmentDetailView from "../views/AppointmentsDetailView.vue";
import Pool from "@/views/Pool.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patients",
    name: "patients",
    component: Patients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patients/add",
    name: "patient.create",
    component: CreatePatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patient/create",
    name: "patient.create",
    component: CreatePatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patients/:id/general",
    name: "patients_details",
    component: PotantialPatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/patients/:id/activities",
    name: "patients.activities",
    component: PotantialPatientActivites,
    meta: {
      requiresAuth: true,
      name: "Aktiviteler",
    },
  },
  {
    path: "/patients/:id/information",
    name: "patients.information",
    component: PotantialInformation,
    meta: {
      requiresAuth: true,
      name: "Kişisel Bilgiler",
    },
  },
  {
    path: "/patients/:id/medicalhistory",
    name: "patients.medical.history",
    component: MedicalHistory,
    meta: {
      requiresAuth: true,
      name: "Medikal Geçmiş",
    },
  },
  {
    path: "/patients/:id/appointments",
    name: "patients.appointments",
    component: PatientAppointments,
    meta: {
      requiresAuth: true,
      name: "Randevular",
    },
  },
  {
    path: "/patients/:id/offers",
    name: "patients.offers",
    component: PotantialOffers,
    meta: {
      requiresAuth: true,
      name: "Teklifler",
    },
  },
  {
    path: "/patients/:id/mails",
    name: "patients.mails",
    component: PotantialMails,
    meta: {
      requiresAuth: true,
      name: "E-Postalar",
    },
  },
  {
    path: "/patients/:id/payments",
    name: "patients.payments",
    component: Payments,
    meta: {
      requiresAuth: true,
      name: "Ödemeler",
    },
  },
  {
    path: "/patients/:id/epicrisis",
    name: "patients.epicrisis",
    component: PotantialEpicrisis,
    meta: {
      requiresAuth: true,
      name: "Epikriz",
    },
  },
  {
    path: "/patients/:id/confirmationForms",
    name: "patients.confirmationForms",
    component: PotantialConfirmationForms,
    meta: {
      requiresAuth: true,
      name: "ConfirmationForms",
    },
  },
  {
    path: "/patients/:patient/epicrirsis/:id",
    name: "patients.epicrisisUpdate",
    component: UpdateEpicrisis,
    meta: {
      requiresAuth: true,
      name: "UpdateEpicrirsis",
    },
  },

  {
    path: "/patients/:id/patientpotantial",
    name: "patients.patientpotantial",
    component: PatientPotantial,
    meta: {
      requiresAuth: true,
      name: "Fırsatlar",
    },
  },
  {
    path: "/patients/:id/files",
    name: "patients.files",
    component: PotantialFiles,
    meta: {
      requiresAuth: true,
      name: "Dosyalar",
    },
  },
  {
    path: "/patients/:id/transfers",
    name: "patients.transfers",
    component: Transfers,
    meta: {
      requiresAuth: true,
      name: "Transferler",
    },
  },
  {
    path: "/potantials/:savedFilters?",
    name: "potantials",
    component: PotantialList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/general",
    name: "potantial.patients",
    component: PotantialPatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/activities",
    name: "potantial.patients.activities",
    component: PotantialPatientActivites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/activities/update/:activityId",
    name: "updateActivity",
    component: UpdateActivitie,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/information",
    name: "potantial.patients.information",
    component: PotantialInformation,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/potantials/:id/offers",
    name: "potantial.patients.offers",
    component: PotantialOffers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/mails",
    name: "potantial.patients.mails",
    component: PotantialMails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/campaigns",
    name: "potantial.patients.campaigns",
    component: PotantialPatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potantials/:id/files",
    name: "potantial.patients.files",
    component: PotantialFiles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments/",
    name: "AppointmentsList",
    component: AppointmentsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/activities/",
    name: "ActivityList",
    component: ActivityList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments/update/:appointmentId",
    name: "updateAppointment",
    component: UpdateAppointment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments/create/:patientId?:datetime?",
    name: "createAppointment",
    component: CreateAppointment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finance",
    name: "finance",
    component: Finance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing",
    name: "marketing",
    component: Marketing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/create",
    name: "createUser",
    component: CreateUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/usersOld/:id",
    name: "users.profile.old",
    component: UserProfileOld,
  },
  {
    path: "/users/:id",
    name: "users.profile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/users/:id/general",
        name: "users.profile.general",
        component: UserProfileGeneral,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/locations",
        name: "users.profile.locations",
        component: UserProfileLocations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/team",
        name: "users.profile.team",
        component: UserProfileTeam,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/branchesDoctors",
        name: "users.profile.branchesDoctors",
        component: UserProfileBranchesDoctors,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/agencies",
        name: "users.profile.agencies",
        component: UserProfileAgencies,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/closedDays",
        name: "users.profile.closedDays",
        component: UserProfileClosedDays,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users/:id/allSources",
        name: "users.profile.allSources",
        component: UserProfileAllSources,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/salesReport",
    name: "reports.salesReport",
    component: SalesReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/progressPaymentReport",
    name: "reports.progressPaymentReport",
    component: ProgressPaymentReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/updateEpicrisisContent/:id",
    name: "updateEpicrisisContent",
    component: UpdateEpicrisisContent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/epicrisis",
    name: "EpicrisesContent",
    component: EpicrisisContents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/confirmationTemplates",
    name: "ConfirmationTemplates",
    component: ConfirmationTemplates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/updateConfirmationTemplate/:guid",
    name: "updateConfirmationTemplate",
    component: UpdateConfirmationTemplate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      system: true,
    },
  },
  {
    path: "/about",
    name: "about",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/potantials/ques/:token",
    name: "potantial.patients.questionnaire",
    component: PotantialQuestionnaire,
  },
  {
    path: "/file/confirmation/:guid",
    name: "confirmationFile",
    component: PreviewConfirmationFile,
  },
  {
    path: "/appointment/detail/:guid",
    name: "appoinmentDetailView",
    component: AppoinmentDetailView,
  },
  {
    path: "/pool",
    name: "pool",
    component: Pool,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    nprogress.start();
  }
  next();
});

router.afterEach(() => {
  nprogress.done();
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    HTTP.get("/users/checkauth")
      .then((result) => {
        if (to.matched.some((record) => record.meta.system)) {
          let role = localStorage.getItem("roleId");

          if (role != 1) {
            next({
              path: "/",
            });
          }
        }

        if (result.status !== 202) {
          store.state.user.loggedIn = false;
          next({
            path: "/login",
          });
        } else {
          store.state.user.loggedIn = true;
        }
      })
      .catch((e) => {
        // if (e.response.status === 401) {
        if (e) {
          next({
            path: "/login",
          });
        }
      });
  }
  //
  if (to.path == "/login" && store.state.user.loggedIn) {
    next({
      path: "/",
    });
  }
  let roleId = localStorage.getItem("roleId");
  let userId = localStorage.getItem("userId");

  if (to.path == '/reports/progressPaymentReport' && roleId != 1) {
    next({
      path: '/'
    })
  }

  // Limit all user except admin for access another user profile.
  
  if (
    ((to.name == "profile" && to.path != "/users/" + userId) ||
      to.path == "/users" ||
      to.path == "/users/create") &&
    roleId != 1
  ) {
    if (["2", "3", "4", "8", "9"].includes(roleId)) {
      next({
        path: "/",
      });
    } else if (["5", "6"].includes(roleId)) {
      next({
        path: "/potantials",
      });
    } else {
      next({
        path: "/appointments",
      });
    }
  }

  // limit user role 2 (Doktor)
  if (
    roleId == 2 &&
    (to.path == "marketing" ||
      to.path == "/reports/salesReport" ||
      to.path == "/potantial")
  ) {
    next({
      path: "/",
    });
  }

  // limit user role 3 (Satış Temsilcisi)
  if (roleId == 3 && to.path == "marketing") {
    next({
      path: "/",
    });
  }

  if (to.path == "/pool") {
    if (![1, 3, 4, 6].includes(parseInt(roleId))) {
      next({
        path: "/",
      });
    }
  }

  // limit user role 4 (Hastane Koordinatörü)
  if (roleId == 4 && to.path == "marketing") {
    next({
      path: "/",
    });
  }

  // limit user role 5 (Google Uzmanı) To only see the pool List (Havuz)
  if (
    roleId == 5 &&
    !(
      to.path == "/potantials" ||
      to.path == "/reports/salesReport" ||
      to.path == "/users/" + userId
    )
  ) {
    next({
      path: "/potantials",
    });
  }

  //Limit user role 6 (Dijital Pazarlama Uzmanı)
  if (
    roleId == 6 &&
    !(
      to.path == "/pool" ||
      to.path == "/appointments" ||
      to.path == "/appointment/detail/" + to.params.guid ||
      to.path == "/reports/salesReport" ||
      to.path == "/users/" + userId +"/general"
    )
  ) {
    next({
      path: "/pool",
    });
  }

  // Limit user role 7 (Transfer Sorumlusu) to see only appointments page
  if (
    roleId == 7 &&
    !(to.path == "/appointments" || to.path == "/users/" + userId)
  ) {
    next({
      path: "/appointments",
    });
  }

  // Limit user role 8 (Sosyal Medya Uzmanı)
  if (
    roleId == 8 &&
    (to.path == "/patients" ||
      to.path == "/appointments" ||
      to.path == "/potantials" ||
      to.path == "/marketing" ||
      to.path == "/reports/salesReport" ||
      to.name == "createAppointment" ||
      to.name == "patients.add")
  ) {
    next({
      path: "/",
    });
  }

  // Limit user role 9 (Diyetisyen)
  if (
    roleId == 9 &&
    (to.path == "/potantials" ||
      to.path == "/marketing" ||
      to.path == "/reports/salesReport" ||
      to.name == "createAppointment" ||
      to.name == "patients.add")
  ) {
    next({
      path: "/",
    });
  }

  if (to.path.match("/patients/") && !to.path.match("/add")) {
    const url = to.path;
    const splitUrl = url.split("/"); // URL'yi '/' karakterine göre böl
    const patientId = splitUrl[2]; // Patient ID'nin olduğu index

    const patientRouteControl = await HTTP.post("/patients/routeControl", {
      patientId,
    });

    if (!patientRouteControl?.data?.access) {
      next("/patients");
    }
  }

  if (to.path.match("/potantials/") && !to.path.match("/add")) {
    const url = to.path;
    const splitUrl = url.split("/"); // URL'yi '/' karakterine göre böl
    const patientId = splitUrl[2]; // Patient ID'nin olduğu index

    const patientRouteControl = await HTTP.post("/patients/routeControl", {
      patientId,
    });

    console.log(patientRouteControl.data);

    if (!patientRouteControl?.data?.access) {
      next("/patients");
    }
  }

  next();
});

export default router;
