<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <div class="md-layout wrapper details">
      <md-table class="table appointments" v-model="mails">
        <md-table-row>
          <md-table-head>Konu</md-table-head>
          <md-table-head>Kişi</md-table-head>
          <md-table-head>E-Posta</md-table-head>
          <md-table-head>Tarih</md-table-head>
          <md-table-head>Dosya</md-table-head>
          <md-table-head>Durum</md-table-head>
        </md-table-row>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Konu">{{ item.subject }}</md-table-cell>
          <md-table-cell md-label="Kişi">{{ item.user.name }}</md-table-cell>
          <md-table-cell md-label="E-Posta">{{ item.to }}</md-table-cell>
          <md-table-cell md-label="Tarih">{{ dateString(item.createdAt) }}</md-table-cell>
          <md-table-cell md-label="Dosya">
            <span v-if="item.files.length == 0">Ekli dosya eklenmemiş</span>
            <div v-if="item.files.length != 0">
              <li v-for="(item,index) in item.files" :key="index">
                <a :href="storagepath+item.path" target="blank">
                  <md-icon>open_in_new</md-icon>
                  {{ item.name }}
                </a>
              </li>
            </div>
          </md-table-cell>
          <md-table-cell md-label="Durum">Gönderildi</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div class="md-layout wrapper newemail">
      <span v-if="newMail.to == '' || newMail.to == null">
        Hastanın emaili bulunmuyor.
        <br />Lütfen kişisel bilgiler alanından email adresini güncelleyiniz.
      </span>
      <div class="md-layout" v-if="newMail.to != '' && newMail.to != null">
        <div class="md-layout-item md-size-100">
          <span class="title">Yeni E-Posta</span> / &nbsp;
          <small>{{ newMail.from }}</small>
          <md-field>
            <label>Kime</label>
            <md-input v-model="newMail.to" disabled></md-input>
          </md-field>
          <md-field>
            <label>CC</label>
            <md-input v-model="newMail.cc"></md-input>
          </md-field>
          <md-field>
            <label>Konu</label>
            <md-input v-model="newMail.subject"></md-input>
          </md-field>
          <VueEditor class="editor" v-model="newMail.message"></VueEditor>
        </div>

        <div class="md-layout-item md-size-50 mt-20">
          <div v-if="hasFile">
            <md-icon>attach_file</md-icon>
            {{ getName() }} adlı dosya eklendi
          </div>
          <ul>
            <li v-for="(file,index) in files" :key="index">{{file.name}}</li>
          </ul>
          <file-upload ref="upload" class="file" v-model="files" multiple v-if="!hasFile">
            <md-icon>attach_file</md-icon>Dosya Ekleyin
          </file-upload>
        </div>
        <div class="md-layout-item md-size-50 mt-20">
          <md-button class="md-raised md-primary editOkButton" v-on:click="sendMail">Gönder</md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import { HTTP } from "@/main-source";
import { VueEditor } from "vue2-editor";

export default {
  name: "mails",
  data: function() {
    return {
      patientsId: this.$route.params.id,
      patient: null,
      mails: [],
      newMail: {
        to: "",
        from: localStorage.getItem("email"),
        cc: "",
        subject: "",
        patientId: this.$route.params.id,
        files: [],
        message: null
      },
      hasFile: false,
      files: [],
      attachedFileId: null,
      storagepath: this.$storage
    };
  },
  created: function() {
    if (this.$route.query.name) {
      this.hasFile = true;
      this.attachedFileId = this.$route.query.oid;
    }
    this.getMails();

    HTTP.get("patients/" + this.patientsId).then(result => {
      this.newMail.to = result.data[0].email;
      this.patient = result.data[0];
    });
      
  },
  components: {
    Navigation,
    Topbar,
    VueEditor
  },
  methods: {
    getMails: function() {
      HTTP.get("mails/" + this.patientsId).then(result => {
        this.mails = result.data;
      });
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    sendMail: function() {
      let fd = new FormData();
      this.files.forEach(file => {
        fd.append("files", file.file, file.name);
      });

      fd.append("to", this.newMail.to);
      fd.append("from", this.newMail.from);
      fd.append("cc", this.newMail.cc);
      fd.append("patientId", this.newMail.patientId);
      fd.append("subject", this.newMail.subject);
      fd.append("message", this.newMail.message);
      fd.append("oid", this.attachedFileId);
      if (this.newMail.message) {
        HTTP.post("/mails", fd, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
          .then(() => {
            this.newMail = {
              to: this.newMail.to,
              from: localStorage.getItem("email"),
              cc: "",
              subject: "",
              patientId: this.$route.params.id,
              files: [],
              message: null
            };
            this.attachedFileId = null;
            this.oid = null;
            this.hasFile = false;
            this.files = [];
            this.$swal("", "Mailiniz Gönderildi", "success");
            this.getMails();
          })
          .catch(() => {
            this.$swal("", "Mailiniz gönderirken hata oluştu", "error");
          });
      }
    },
    getName: function() {
      return this.$route.query.name ? this.$route.query.name : "";
    }
  }
};
</script>
<style lang="scss">
.table li {
  list-style: none;
  font-size: 12px;
  margin-bottom: 5px;
  a {
    color: inherit !important;
    text-decoration: none !important;
  }
  a i {
    font-size: 15px !important;
  }
}
.editor {
  width: 100%;
}

.newemail {
  .title {
    font-weight: bold;
    font-size: 17px;
    margin: 0 5px 0px;
  }
}
.mt-20 {
  margin-top: 20px;
}
</style>