<template>
  <div>
    <Topbar :renew="setMakePatient" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <div class="md-layout details">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-60">
          <div class="wrapper">
            <span class="md-title">Teklifler</span>
            <md-table class="table appointments" v-model="offers">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Seçim">
                  <md-radio v-model="selectedOffer" :value="item.id"></md-radio>
                </md-table-cell>
                <md-table-cell md-label="Kişi">{{
                  item.user.name
                }}</md-table-cell>
                <md-table-cell md-label="Tarih">{{
                  dateString(item.createdAt)
                }}</md-table-cell>

                <md-table-cell md-label="Fiyat"
                  >{{ totalPriceString(item.totalPrice) }}
                  {{ item.totalPrice ? item.currencySlug : "" }}</md-table-cell
                >
                <md-table-cell md-label="Durum">{{
                  statusText(item.status)
                }}</md-table-cell>
                <md-table-cell md-label="Dosya">
                  <a
                    :href="storagepath + item.file.path"
                    target="_blank"
                    v-if="item.file"
                  >
                    <md-icon>open_in_new</md-icon>
                  </a>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-button
              class="md-raised md-primary text-right"
              v-on:click="sendOffer"
              v-if="offers.length > 0"
              >Seçili Teklifi Mail Olarak İlet</md-button
            >
            <md-button
              class="md-raised md-accent text-right"
              v-on:click="deleteOffer"
              v-if="offers.length > 0"
              >Seçili Teklifi Sil</md-button
            >
          </div>
        </div>
        <div class="md-layout-item">
          <div class="wrapper">
            <span class="md-title">Yeni Teklif</span>
            <div class="md-layout md-gutter">
               <div class="md-layout-item md-size-100">          
                <md-field>
                  <v-select
                    v-model="package_"
                    placeholder="Paket"
                    :clearable="true"
                    :options="packages_"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-60">
                <md-field>
                  <v-select
                    v-model="newOffer.schema"
                    placeholder="Şablon"
                    :clearable="false"
                    :options="schemas"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <v-select
                    :clearable="false"
                    v-model="newOffer.currencySlug"
                    placeholder="Döviz"
                    :options="currencies"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                </md-field>
              </div>
            </div>
            <!--  -->
            <div
              class="md-layout md-gutter"
              v-for="(treatment, index) in treatments"
              :key="index"
            >
            <div
              class="md-layout-item md-size-10 full-flex"
              @click="treatments.splice(index,1)"
            >
              <md-icon                  
                >clear</md-icon> 
            </div>
                           
              <div class="md-layout-item md-size-50">
                <md-field>
                  <v-select
                    v-model="treatment.id"
                    placeholder="Hizmet"
                    :options="operations"
                    label="name"
                    class="md-menu"
                  >
                  </v-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field>
                  <label>Adet</label>
                  <md-input
                    v-model="treatment.qt"
                    ref="name"
                    type="number"
                    min="0"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field>
                  <label>Fiyat</label>
                  <md-input v-model="treatment.price" ref="name"></md-input>
                </md-field>
              </div>
            </div>
            <!--  -->
            <div class="md-layout text-center acenter">
              <md-button class="md-default addOperation" @click="newOperation(-1)">
                <md-icon>add</md-icon>Yeni İşlem Ekle
              </md-button>
            </div>
            <div class="md-layout text-center acenter">
              <Loading v-if="isProcess"></Loading>
              <div v-if="isError">
                {{ isError }}
              </div>
            </div>
            <div class="md-layout acenter mt-20 fullbtn">
              <md-button
                class="md-raised md-default"
                onclick="modifyPdf(true)"
                @click="processing"
                :disabled="isProcess"
                >Önizleme
              </md-button>
              <md-button
                class="md-raised md-primary"
                v-on:click="addOffer"
                :disabled="isProcess"
                @click="processing"
                >Kaydet
              </md-button>
              <button onclick="modifyPdf()" ref="createPdf" class="hidden">
                blob oluştur
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import { HTTP } from "@/main-source";
import axios from "axios";
import router from "../../../../router";
import Loading from "@/components/Loading";

export default {
  name: "offers",
  data: function () {
    return {
      save:false,
      patient:null,
      packages_:[
      {
          value:'baloon6',
          name:'6 Aylık Mide Baloon Ameliyatı',        
          operations:[4,29,26,25,24,23,22],
        },
        {
          value:'baloon12',
          name:'12 Aylık Mide Baloon Ameliyatı',        
          operations:[31,29,26,25,24,23,22],
        },
            {
          value:'ellipse',
          name:'Gastrit Sleeve Ameliyatı',        
          operations:[30,29,26,25,24,23,22],
        },
        {
          value:'gastrit',
          name:'Gastrit Ameliyatı',        
          operations:[30,29,26,25,24,23,22],
        },
        {
          value:'bypass',
          name:'Bypass Ameliyatı',        
          operations:[2,29,26,25,24,23,22],
        },      
      ],
      package_:undefined,
      patientsId: this.$route.params.id,
      file: "",
      schemas: [
        {
          value:'EN',
          id: 0,
          name: "İngilizce",
        },
        {
          value:'BUL',
          id: 1,
          name: "Bulgarca",
        },
         {
          value:'GER',
          id: 2,
          name: "Almanca",
        },
         {
          value:'TR',
          id: 3,
          name: "Türkçe",
        },
      ],
      newOffer: {
        schema:  {
          value:'TR',
          id: 3,
          name: "Türkçe",
        },
        currencySlug: {
          "name": "Türk Lirası",
          "id": 1,
          "slug": "TL" }
      },
      currencies: [],
      operations: [],
      offers: [],
      statuses: [
        {
          id: 0,
          name: "Hazırlandı",
        },
        {
          id: 1,
          name: "Beklemede",
        },
        {
          id: 2,
          name: "Kabul Edilmedi",
        },
        {
          id: 3,
          name: "E-Mail ile Gönderildi",
        },
        {
          id: 4,
          name: "Onaylandı",
        },
      ],
      treatments: [{ operationId: null, price: null, qt: null }],
      selectedOffer: null,
      storagepath: this.$storage,
      setMakePatient: false,
      user: [],
      isProcess: false,
      isError: false,
    };
  },
  created: function () {
    localStorage.removeItem("treatments");
    this.getOffers();
    HTTP.get("/currency/").then((result) => {
      this.currencies = result.data.map((item) => {
        return { name: item.title, id: item.id, slug: item.slug };
      });
    });
    HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
    });
    HTTP.get("/users/profile/" + localStorage.getItem("userId")).then(
      (result) => {
        this.user = result.data[0];
        localStorage.setItem("user", JSON.stringify(this.user));
      }
    );
    HTTP.get("/patients/" + this.patientsId).then((result) => {
      let patient = result.data[0];
      this.patient = result.data[0];

      localStorage.setItem("patientId", this.patientsId);
      localStorage.setItem("patientName", patient.name);
      localStorage.setItem("patientEmail", patient.email ? patient.email : "");
      localStorage.setItem("patientPhone", patient.phone);
    });
  },
  components: {
    Navigation,
    Topbar,
    Loading,
  },
  beforeDestroy: function () {
    localStorage.removeItem("user");
    localStorage.removeItem("patientId");
    localStorage.removeItem("patientName");
    localStorage.removeItem("patientEmail");
    localStorage.removeItem("patientPhone");
  },
  methods: {
    processing: function () {
      this.isProcess = true;
      this.isError = false;
      localStorage.setItem("isPDFProcessing", true);

      let opList = [];
      this.treatments.forEach((treatment) => {
        opList.push({
          operationId: treatment.id ? treatment.id.id : null,
          price: treatment ? treatment.price : "",
          qt: treatment ? treatment.qt : "",
        });
      });

      setTimeout(() => {
        if (opList[0].operationId != null) {
          let result = true;
          let check = setInterval(() => {
            let result = localStorage.getItem("isPDFProcessing");
            if (result == "false") {
              clearInterval(check);
              this.isProcess = false;
            }
          }, 1000);
        } else {
          this.isProcess = false;
          this.isError = "Lütfen tüm alanları doldurduğunuzdan emin olun.";
          localStorage.setItem("isPDFProcessing", false);
        }
      }, 750);
    },
    getOffers: function () {
      HTTP.get("/offers/" + this.$route.params.id).then((result) => {
        this.offers = result.data;
      });
    },
    show: function () {
      // let blob = localStorage.getItem("pdfvalue");

      let fd = new FormData();
      // fd.append("files", file.file, file.name);
      fd.append("files", BLOB, "teklif");
      fd.append("description", "testteklif");
      fd.append("date", null);
      fd.append("notify_me", false);
      fd.append("patientId", 11661);
      fd.append("status", 0);

      HTTP.post("/notes", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then(() => {
        console.log("ok");
      });
    },
    patientTreatmentList: function (list) {
      let text = "";

      list.forEach((element) => {
        text +=
          " & " +
          this.operations.filter((r) => r.id == element.operationId)[0].name;
      });
      return text.substring(3);
    },
    dateString: function (date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    statusText: function (status) {
      let text = "";
      switch (status) {
        case 0:
          text = "Hazırlandı";
          break;
        case 1:
          text = "Beklemede";
          break;
        case 2:
          text = "Kabul Edilmedi";
          break;
        case 3:
          text = "E-Mail ile Gönderildi";
          break;
        case 4:
          text = "Onaylandı";
          break;
      }
      return text;
    },
    newOperation: function (id) {
      let op = this.operations;
      let treatment = op.find(o => o.id == id);
      if(typeof treatment =='object'){
        this.treatments.push({ operationId: treatment.id,price:0,qt:1 });
      } else{
         this.treatments.push({ operationId:null,price:0,qt:1 });
      }   
      this.fixPrices();
    },
    addOffer: function () {
      this.isError = false;
      let opList = [];
      this.treatments.forEach((treatment) => {
        opList.push({
          operationId: treatment.id ? treatment.id.id : null,
          price: treatment ? treatment.price : "",
          qt: treatment ? treatment.qt : "",
        });
      });
      // let check = setInterval(() => {
      //           let result = localStorage.getItem("isPDFProcessing");
      //           if (result == "false") {
      //             clearInterval(check);
      //             this.isProcess = false;
      //           }
      //         }, 1000);

      if (opList[0].operationId != null) {
        localStorage.setItem("treatments", JSON.stringify(this.treatments));
        this.$refs.createPdf.click();
        this.isProcess = true;
        let check = setInterval(() => {
          let result = localStorage.getItem("isPDFProcessing");
          if (result == "false") {
            clearInterval(check);
            this.isProcess = false;
            // console.log(this.treatments);
            let totalPrice = localStorage.getItem("offerTotalPrice");
            let fd = new FormData();
            fd.append("files", BLOB, "Teklif");
            // this.treatments.forEach(t => {
            //   fd.append("treatments", { id: t.id.id, price: t.price });
            // });
            fd.append("treatments", JSON.stringify(opList));
            fd.append(
              "currencySlug",
              this.newOffer.currencySlug ? this.newOffer.currencySlug.slug : ""
            );
            fd.append("patientId", this.patientsId);
            fd.append("totalPrice", totalPrice);

            HTTP.post("offers", fd, {
              headers: {
                "content-type": "multipart/form-data",
              },
            })
              .then((res) => {
                this.$swal("", "Teklif Oluşturuldu", "success");
                this.treatments = [{ operationId: null, price: null }];
                this.newOffer = [];
                this.getOffers();
                this.setMakePatient = true;
              })
              .catch((e) => {
                this.isError = e.response;
              });
          }
        }, 1000);
      } else {
        localStorage.setItem("isPDFProcessing", false);
        this.isProcess = false;
        this.isError = "Lütfen tüm alanları doldurduğunuzdan emin olun.";
      }
    },
    deleteOffer:async function(){
      await HTTP.delete(`/offers/${this.selectedOffer}`)
      this.getOffers()
    },
    sendOffer: function () {
      let offer = this.offers.filter(
        (offer) => offer.id == this.selectedOffer
      )[0];
      router.push({
        name: "potantial.patients.mails",
        params: {
          id: this.patientsId,
        },
        query: {
          oid: offer.file.id,
          name: this.patientTreatmentList(offer.treatments),
        },
      });
    },
    totalPriceString: function (total) {
      var totalString = new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      })
        .format(total)
        .substr(1);
      return totalString;
    },
    fixPrices(){
      let slug = this.newOffer.currencySlug.slug || 'EUR';
      localStorage.setItem('currency', slug);
      this.treatments.forEach((t)=>{
        let op = this.operations.find(o => o.id ==t.id.id) || null
        let price = 0
        if(op && typeof op.prices =='string'){
          let prices = JSON.parse(op.prices)
          if(prices[slug]){
            price  = prices[slug]          
          }           
        }    
        t.price = price             
      })
    },
  },
  watch: {
    treatments: {
      deep:true,
      handler:function(){
        this.fixPrices()
      }
      
    },
    package_:function(val){
      this.treatments=[]
      for(let i of val.operations){
        this.newOperation(i)
      }
    },
    "newOffer.currencySlug": function (val) {
      this.fixPrices();
    },
  },
  mounted() {},
};
/*eslint-disable*/
</script>
<style lang="scss">
.addOperation {
  background: transparent !important;
  margin: auto;
}
.acenter {
  justify-content: center;
  button {
    margin: 5px !important;
  }
}
.fullbtn {
  button {
    width: 46%;
  }
}
.hidden {
  display: none;
}
.md-field {
  input {
    max-width: 100%;
  }
}

.full-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
</style>