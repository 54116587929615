<template>
  <div>
    <md-dialog
      :md-active.sync="appointmentDetails"
      class="dialog"
      @md-clicked-outside="closeCard"
      :md-click-outside-to-close="false"
      >
        <div>
        <div class="md-layout-item md-xsmall-size-10">
          <md-button
            style="width: 35px; height: 35px; float: right;color:white;    right: -2rem;    top: -0.5rem;    position: absolute;"
            class="md-fab md-plain" @click="closeCard">X</md-button>
        </div>
      </div>
      <md-dialog-title>
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-90">Randevu Bilgileri</div>
        </div>
      </md-dialog-title>

      <md-dialog-actions class="d-block d-md-none mb-5">
        <div class="row" style="
            justify-content: right;
            flex-wrap: inherit;
            text-align: -webkit-right;
          ">
          <div class="col mb-3 p-0">
            <md-button
              class="md-secondary md-raised text-wrap"
              style="width: 6rem"
              @click="operatorNoteModal = true"
              v-if="isDoctor || isAdmin"
            >
              Operatör Notu</md-button
            >
          </div>
          <div class="col mb-3 p-0">
            <md-button
              class="md-secondary md-raised text-wrap"
              style="width: 6rem"
              @click="appointmentNoteModal = true"
              v-if="isAdmin || isCoordinator || hasAccessForUpdateNote()"
            >
              Randevu Notu</md-button
            >
          </div>

          <!-- <div class="col mb-3 p-0" v-if="![5, 6, 7].includes(roleId)">
            <md-button
              class="md-raised md-secondary text-wrap"
              style="width: 6rem"
              @click="
                
                medicalInfos = true;
              "
            >
              Medikal Formu
            </md-button>
          </div> -->
          <div class="col mb-3 p-0" v-if="isDoctor || isDietician || isCoordinator || isAdmin">
            <router-link :to="{
              name: 'patients.epicrisis',
              params: { id: appointment.patient.id },
            }" target="_blank">
              <md-button class="md-secondary md-raised" style="width: 6rem">
                Epikriz
              </md-button>
            </router-link>
          </div>
        </div>
      </md-dialog-actions>

      <md-content style="overflow-y: scroll; padding-bottom: 30px;" >
        <div  class="md-layout" v-if="appointment">
          <div class="md-layout-item little-table md-xsmall-size-100">
            <router-link style="text-decoration: underline" :to="{
              name: 'patients_details',
              params: { id: appointment.patient.id },
            }">
              <div class="md-layout">
                <div class="md-layout-item md-size-30 text-wrap">Ad Soyad</div>
                <div class="md-layout-item">
                  : {{ appointment.patient.name }}
                </div>
              </div>
            </router-link>
            <div class="md-layout" v-if="!isDoctor">
              <div class="md-layout-item md-size-30 text-wrap">Telefon</div>
              <div class="md-layout-item">
                : {{ appointment.patient.phone }}
              </div>
            </div>

            <!-- <div class="md-layout">
                            <div class="md-layout-item md-size-30">E-Mail</div>
                            <div class="md-layout-item">: {{ appointment.patient.email }}</div>
                        </div> -->

            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-wrap">Cinsiyet :</div>
              <div class="md-layout-item" v-if="appointment.patient.sex">
                : Erkek
              </div>
              <div class="md-layout-item" v-if="!appointment.patient.sex">
                : Kadın
              </div>
            </div>

            <!-- <div class="md-layout">
                            <div class="md-layout-item md-size-30">D. Tarihi :</div>
                            <div class="md-layout-item">: {{ appointment.patient.birthday }}</div>
                        </div> -->

            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-wrap">Ülke</div>
              <div class="md-layout-item">
                : {{ countryName(appointment.patient) }}
              </div>
            </div>

            <div class="md-layout" v-if="isAdmin">
              <div class="md-layout-item md-size-30 text-wrap">Kaynak</div>
              <div class="md-layout-item">
                : {{ agencieName(appointment.patient) }}
              </div>
            </div>

            <div class="md-layout" v-if="isAdmin">
              <div class="md-layout-item md-size-30 text-truncate">
                İlk Kayıt Tarihi
              </div>
              <div class="md-layout-item">
                : {{ formatDate(appointment.patient.createdAt) }}
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-truncate">
                Oluşturulma Tarihi
              </div>
              <div class="md-layout-item">
                : {{ formatDate(appointment.createdAt) }}
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-truncate">
                Son Güncelleme Tarihi
              </div>
              <div class="md-layout-item">
                : {{ formatDate(appointment.updateAt) }}
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-truncate">
                Durum
              </div>
              <div class="md-layout-item">
                : {{ appointment.status.name }}
                <span
                  v-if="
                    getLatestAppointmentNote(appointment.statusHistory) != ''
                  "
                  >({{
                    getLatestAppointmentNote(appointment.statusHistory)
                  }})</span
                >
              </div>
            </div>
          </div>
          <div class="md-layout-item little-table md-xsmall-size-100">
            <!-- <span class="md-title brand-color">İşlem Tipi, Tarihi & Katılımcılar</span> -->

            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-wrap">İşlem Tipi</div>
              <div class="md-layout-item">
                : {{ appointment.operation ? appointment.operation.name : "" }}
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-wrap">Konum</div>
              <div class="md-layout-item text-truncate">
                : {{ appointment.location ? appointment.location.name : "" }}
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30">Katılımcılar</div>
              <div class="md-layout-item participants">
                :
                <span v-for="participant in appointment.AppointmentsParticipants" v-bind:key="participant.id">{{
                  participant.name }}</span>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30">Temsilci</div>
              <div class="md-layout-item">: {{ appointment.user.name }}</div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30">Tarih</div>
              <div class="md-layout-item">
                : {{ formatDate(appointment.date) }}
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30">Saat</div>
              <div class="md-layout-item">: {{ appointment.time }}</div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30 text-truncate">
                İşlem Süresi
              </div>
              <div class="md-layout-item">: {{ appointment.duration }} dk.</div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-30">İndirim</div>
              <div class="md-layout-item">
                :{{ appointment.isDiscounted ? "Uygulandı" : "Uygulanmadı" }}
              </div>
            </div>

            <div class="md-layout" v-if="!isDoctor && !isDietician">
              <div class="md-layout-item md-size-30">Randevu Notu</div>
              <div class="md-layout-item">: {{ appointment.note }}</div>
            </div>

          </div>
        </div>
      </md-content>

      <md-dialog-actions>
        <div class="row" style="
            justify-content: center;
            flex-wrap: wrap;
            width: 105%;
          "
        >
          <div class="bottom-button-container" v-if="roleId == 1">
            <md-button
              style="width: 7rem"
              class="md-raised md-accent"
              @click="deleteAppointment(appointment.id)"
            >
              Sil</md-button
            >
          </div>

          <div
            class="bottom-button-container"
            v-if="
              (!isDoctor && appointment.statusId != 2 && !isDietician) ||
                isCoordinator ||
                isAdmin
            "
          >
            <md-button
              style="width: 7rem"
              class="md-secondary md-raised text-wrap"
              @click="eventStatusModal = true"
            >
              Durum Güncelle</md-button
            >
          </div>

          <div
              class="bottom-button-container"
              v-if="!isDoctor && !appointment.operation.isActivity"
              >
                <router-link
                v-if="!appointment.isActivity"
                  :to="{
                    name: 'updateAppointment',
                    params: {
                      appointmentId: appointment.id,
                    },
                  }"
                >
                <md-button
                  style="width: 7rem"
                  class="md-secondary md-raised"
                  @click="closeCard"
                >
                  Düzenle
                </md-button>
                </router-link>
                <router-link
                  v-else
                    :to="{
                      name: 'updateActivity',
                      params: {
                        activityId: appointment.id,
                      },
                    }"
                  >
                  <md-button
                    style="width: 7rem"
                    class="md-secondary md-raised"
                    @click="closeCard"
                  >
                    Düzenle
                  </md-button>
                </router-link>
            </div>

            <div
              class="bottom-button-container"
              v-if="!isDoctor && appointment.operation.isActivity"
            >
              <router-link
              :to="{
                name: 'updateActivity',
                params: {
                  activityId: appointment.id,
                },
              }"
            >
              <md-button
                style="width: 7rem"
                class="md-secondary md-raised"
                @click="closeCard"
              >
                Düzenle
              </md-button>
            </router-link>
          </div>

          <div
            class="bottom-button-container"
            v-if="userLeading.includes(appointment.userId) || roleId == 1"
          >
            <md-button
              style="width: 7rem"
              class="md-secondary md-raised text-wrap"
              @click="temsilciChangeModal = true"
            >
              Temsilci Güncelle</md-button
            >
          </div>

          <div class="bottom-button-container" v-if="isDoctor || isAdmin">
            <md-button
              class="md-secondary md-raised text-wrap"
              style="width: 7rem"
              @click="operatorNoteModal = true"
            >
              Operatör Notu</md-button
            >
          </div>

          <div
            class="bottom-button-container"
            v-if="isAdmin || hasAccessForUpdateNote()"
          >
            <md-button
              class="md-secondary md-raised text-wrap"
              style="width: 7rem"
              @click="appointmentNoteModal = true"
            >
              Randevu Notu</md-button
            >
          </div>

          <div
            class="bottom-button-container"
            v-if="![5, 6, 7].includes(roleId)"
          >
            <md-button
              class="md-raised md-secondary text-wrap"
              style="width: 7rem"
              @click="medicalInfos = true"
            >
              Medikal Formu
            </md-button>
          </div>

          <div
            class="bottom-button-container"
            v-if="isDoctor || isDietician || isCoordinator || isAdmin"
          >
            <router-link
              :to="{
                name: 'patients.epicrisis',
                params: { id: appointment.patient.id },
              }"
              target="_blank"
            >
              <md-button class="md-secondary md-raised" style="width: 7rem">
                Epikriz
              </md-button>
            </router-link>
          </div>

          <div
            class="bottom-button-container"
            v-if="appointment.fileContents.length > 0"
          >
            <router-link
              :to="{
                name: 'appoinmentDetailView',
                params: { guid: appointment.guid },
              }"
              target="_blank"
            >
              <md-button class="md-secondary md-raised" style="width: 7rem">
                Onay Formu
              </md-button>
            </router-link>
          </div>
        </div>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="eventStatusModal" class="dialog">
      <md-content class="statuses">
        <div class="md-layout-item md-xsmall-size-10">
          <md-button
            style="width: 35px; height: 35px; float: right;color:white;    right: 0.5rem;    top: 0.5rem;    position: absolute;"
            class="md-fab md-plain" @click="goBack()">X</md-button>
        </div>
        <h3>Randevu Durumu Düzenle</h3>
        <b>{{ appointment.patient.name }}</b>
        adlı hastanın {{ formatDate(appointment.date) }} tarihli <br />randevu
        durumu güncelleniyor.
        <md-field>
          <md-select v-model="appointment.statusId" placeholder="Durum">
            <md-option v-for="status in statuses" v-bind:value="status.id" v-bind:key="status.id">
              {{ status.name }}</md-option>
          </md-select>
        </md-field>
        <md-field>
          <md-input v-model="statusChangeNote" placeholder="Not" />
        </md-field>
        <p style="color: red;" v-if="statusChangeNoteRequired">
          * İptal ve Erteleme dumurumlarda not girilmesi zorunludur.
        </p>
        <div
          v-if="fileInputShow"
          class="md-layout-item md-size-100 md-xsmall-size-100 wrapper"
        >
          <div class="md-layout">
            <file-upload class="customfileinput file" ref="upload" v-model="uploadedFiles">
              <span v-if="uploadedFiles.length > 0">
                <span v-for="(file, index) in uploadedFiles" :key="index">
                  <md-icon>attach_file</md-icon>{{ file.name }}
                </span>
              </span>
              <span v-else>
                <md-icon>attach_file</md-icon>Dosya Ekleyin (max 4 mb)
              </span>
              <md-progress-spinner v-if="spinner" class="md-accent spinner" :md-diameter="20" :md-stroke="3"
                md-mode="indeterminate"></md-progress-spinner>
            </file-upload>
          </div>
        </div>
        <br />
        <br />
        <md-button class="md-raised md-primary updateStatus" v-on:click="setEventStatus">Güncelle</md-button>
      </md-content>
    </md-dialog>
    <md-dialog :md-active.sync="medicalInfos" :md-click-outside-to-close="true" class="dialog">
      <md-dialog-title>
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-90">Medikal Formlar</div>
          <div class="md-layout-item md-xsmall-size-10">
            <md-button style="width: 35px; height: 35px; float: right" class="md-fab md-plain"
              @click="medicalInfos = false">X</md-button>
          </div>
        </div>
      </md-dialog-title>
      <md-content>
        <div class="wrapper">
          <div class="">
            <span class="title">Dosyalar</span>
          </div>
          <div class="">
            <span v-if="files.length == 0">Medikal form mevcut degil</span>
          </div>
          <md-table class="table files" v-model="files">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Konu">
                <a :href="generateLink(item.path)" target="blank">
                  <md-icon>open_in_new</md-icon>
                  {{ item.subject ? item.subject : item.name }}
                </a>
              </md-table-cell>
              <md-table-cell md-label="Eklenme Tarihi">{{
                dateString(item.createdAt)
              }}</md-table-cell>

              <md-table-cell md-label="Ekleyen">{{
                item.userId ? item.user.name : ""
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="operatorNoteModal" :md-click-outside-to-close="true" class="dialog">
      <md-dialog-content>
        <md-content class="statuses">
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="width: 35px; height: 35px; float: right;color:white;    right: 0.5rem;    top: 0.5rem;    position: absolute;"
              class="md-fab md-plain"
              @click="goBack()"
              >X</md-button
            >
          </div>
          <md-dialog-title>Operatör Notu Ekle</md-dialog-title>

          <h3></h3>
          <b>{{ appointment.patient.name }}</b>
          adlı hastanın {{ formatDate(appointment.date) }} tarihli randevusu
          <br />
          <br />

          <div v-for="item in JSON.parse(this.appointment.operatorNote)" :key="item.id">
            <!-- <hr class="half" /> -->
            <span>
              <b> {{ item.user }} ({{ item.date }}) </b>
              <p style="white-space: pre-line">{{ item.note }}</p>
            </span>
            <hr class="" />
          </div>

          <md-field>
            <md-textarea v-model="operatorNoteInput"> </md-textarea>
          </md-field>
          <md-button class="md-raised md-primary" v-on:click="operatorNoteUpdate">Ekle</md-button>
        </md-content>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="appointmentNoteModal" :md-click-outside-to-close="true" class="dialog">
      <md-dialog-content>
        <md-content class="statuses">
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="width: 35px; height: 35px; float: right;color:white;    right: 0.5rem;    top: 0.5rem;    position: absolute;"
              class="md-fab md-plain"
              @click="goBack()"
              >X</md-button
            >
          </div>
          <md-dialog-title>Randevu Notu Düzenle</md-dialog-title>

          <h3></h3>
          <b>{{ appointment.patient.name }}</b>
          adlı hastanın {{ formatDate(appointment.date) }} tarihli randevusu
          <br />
          <br />

          <md-field>
            <md-textarea v-model="appointment.note"> </md-textarea>
          </md-field>
          <md-button class="md-raised md-primary" v-on:click="appointmentNoteUpdate">Düzenle</md-button>
        </md-content>
      </md-dialog-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="temsilciChangeModal"
      :md-click-outside-to-close="true"
      class="dialog"
    >
      <md-dialog-content>
        <md-content class="statuses">
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="width: 35px; height: 35px; float: right;color:white;    right: 0.5rem;    top: 0.5rem;    position: absolute;"
              class="md-fab md-plain"
              @click="goBack()"
              >X</md-button
            >
          </div>
          <md-dialog-title>Randevu Temsilcisini Düzenle</md-dialog-title>

          <md-field>
            <md-select v-model="appointment.userId" placeholder="Temsilci">
              <md-option
                v-for="user in appointmentChangeUsers"
                v-bind:value="user.id"
                v-bind:key="user.id"
                >{{ user.name }}</md-option
              >
            </md-select>
          </md-field>

          <md-field>
            <md-select v-model="appointment.userId" placeholder="Temsilci">
              <md-option v-for="user in appointmentChangeUsers" v-bind:value="user.id" v-bind:key="user.id">{{ user.name
              }}</md-option>
            </md-select>
          </md-field>

          <md-button class="md-raised md-primary" v-on:click="UpdateAppointmentUser">Düzenle</md-button>
        </md-content>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
// import router from "@/router";
import moment from "moment";

export default {
  name: "patient.appointmentCard",
  data: function() {
    return {
      eventStatusModal: false,
      statuses: [],
      countries: [],
      agencies: [],
      files: [],
      spinner: false,
      uploadedFiles: [],
      medicalInfos: false,
      roleId: localStorage.getItem("roleId"),
      isDietician: localStorage.getItem("roleId") == 9,
      isAdmin: localStorage.getItem("roleId") == 1,
      isCoordinator: localStorage.getItem("roleId") == 4,
      isDoctor: localStorage.getItem("roleId") == 2,
      userId: localStorage.getItem("userId"),
      storagepath: this.$storage,
      operatorNoteModal: false,
      appointmentNoteModal: false,
      temsilciChangeModal: false,
      operatorNoteInput: "",
      statusChangeNote: "",
      appointmentChangeUsers: [],
      allUsers: [],
      userLeading: [],
    };
  },
  props: ["appointment", "appointmentDetails"],
  created: function() {
    HTTP.get("/status/").then((result) => {
      this.statuses = result.data;
    });
    HTTP.get("/countries/").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/agencies/").then((result) => {
      this.agencies = result.data;
    });
    HTTP.get("/users/").then((result) => {
      let allUsers = result.data;
      this.allUsers = result.data;

      for (let i in allUsers) {
        if (allUsers[i].id == localStorage.getItem("userId")) {
          this.userLeading = JSON.parse(allUsers[i].leading);
          this.appointmentChangeUsers.push(allUsers[i]);
        }
      }

      if (localStorage.getItem("roleId") == 1) {
        for (let i in allUsers) {
          if ([3, 4].includes(allUsers[i].roleId)) {
            this.appointmentChangeUsers.push(allUsers[i]);
          }
        }
      } else {
        let viewerUser = allUsers.filter(
          (user) => user.id == localStorage.getItem("userId")
        )[0];
        let userLeading = JSON.parse(viewerUser?.leading);

        if (userLeading?.length > 0) {
          for (let i in allUsers) {
            if (userLeading.includes(allUsers[i].id)) {
              this.appointmentChangeUsers.push(allUsers[i]);
            }
          }
        }
      }
    });
  },
  computed: {
    statusChangeNoteRequired: function() {
      return (
        [3, 4].includes(this.appointment.statusId) &&
        this.statusChangeNote == ""
      );
    },
    fileInputShow: function() {
      if (
        (this.appointment.statusId == 1 || this.appointment.statusId == 6) &&
        !this.isAdmin &&
        (this.appointment.statusId == 1 || this.appointment.statusId == 6)
      ) {
        return true;
      } else {
        return false;
      }
    },

    appointmentOperatorNote: function() {
      if (!this.appointment || !this.appointment.operatorNote) return "";

      var note = JSON.parse(this.appointment.operatorNote);
      var text = "";
      note.forEach((item, index) => {
        text += "<b>" + item.user + ":</b> " + item.note + "<br/>";
      });

      return text;
    },
  },
  methods: {
    hasAccessForUpdateNote: function() {
      let now = new Date().getTime();

      let appointmentDate = new Date(this.appointment.date).getTime();
      if (appointmentDate >= now) {
        return true;
      }
      return false;
    },
    getLatestAppointmentNote: function(statusHistory) {
      if (!statusHistory) return "";
      let history = JSON.parse(statusHistory);
      let latestNote = history[history.length - 1];
      return latestNote.note;
    },
    goBack: function() {
      //this.appointmentDetails = true
      this.operatorNoteModal = false;
      this.appointmentNoteModal = false;
      this.medicalInfos = false;
      this.eventStatusModal = false;
      this.temsilciChangeModal = false;
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    generateLink(path) {
      return path.startsWith("http") ? path : this.storagepath + path;
    },
    getFiles: async function() {
      await HTTP.get("files/" + this.appointment.patientId).then((result) => {
        this.files = [];
        this.files = result.data.filter((z) => z.filetype.id == 8);
        //  this.statusFile =  result.data.filter((z) => z.filetype.id == 9);
      });
    },

    operatorNoteUpdate() {
      var operatorNote = JSON.parse(this.appointment.operatorNote) || [];

      var newNote = {
        note: this.operatorNoteInput,
        user: localStorage.getItem("name"),
        date: moment(Date()).format("DD-MM-YYYY H:m"),
      };

      operatorNote.push(newNote);

      HTTP.patch("/appointments/" + this.appointment.id + "/operatorNote", {
        operatorNote: JSON.stringify(operatorNote),
      }).then((result) => {
        if (result.status == 204) {
          this.$emit("refreshAppointments");
          this.operatorNoteModal = false;
          this.operatorNoteInput = "";
        }
      });
    },
    appointmentNoteUpdate() {
      HTTP.patch("/appointments/" + this.appointment.id + "/appointmentNote", {
        appointmentNote: this.appointment.note,
      }).then((result) => {
        if (result.status == 204) {
          this.$emit("refreshAppointments");
          this.appointmentNoteModal = false;
        }
      });
    },

    canDelete: function(appointment) {
      let userId = localStorage.getItem("userId");
      if (this.roleId == 1 || appointment.userId == userId) return true;
      else return false;
    },
    countryName: function(patient) {
      if (!patient.countryCode) return "";
      var country = this.countries.find((z) => z.code == patient.countryCode);
      return country ? country.name_tr : patient.countryCode;
    },
    agencieName: function(patient) {
      if (!patient.agencieId) return "";
      var agencie = this.agencies.find((z) => z.id == patient.agencieId);
      return agencie ? agencie.name : "";
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    closeCard: function() {
      this.$emit("closeAppointmentCard");
    },
    setEventStatus: function() {
      if (this.statusChangeNoteRequired) return;
      // this.fileCount();
      //
      if (this.fileInputShow) {
        if (this.uploadedFiles.length > 0) {
          this.spinner = true;
          let fd = new FormData();
          this.uploadedFiles.forEach((file) => {
            fd.append("files", file.file, file.name);
          });
          fd.append("subject", "Durum Değişikliği");
          fd.append("statusId", this.appointment.statusId);
          fd.append("filetypeId", 9);
          fd.append("patientId", this.appointment.patientId);

          HTTP.post("/files/", fd, {
            headers: {
              "content-type": "multipart/form-data",
            },
          }).then(() => {
            HTTP.patch("/appointments/" + this.appointment.id + "/status", {
              statusId: this.appointment.statusId,
              lastUpdateUserId: localStorage.getItem("userId"),
              statusNote: this.statusChangeNote,
            }).then((result) => {
              if (result.status == 204) {
                this.spinner = false;
                this.uploadedFiles = [];

                this.eventStatusModal = false;
                this.$emit("refreshAppointments");
              }
            });
          });
        } else {
          this.$swal(
            "",
            "· Durum Değişikliği İçin Lütfen Bilet / Fee Dekont Ekleyiniz",
            "warning"
          );
        }
      } else {
        HTTP.patch("/appointments/" + this.appointment.id + "/status", {
          statusId: this.appointment.statusId,
          lastUpdateUserId: localStorage.getItem("userId"),
          statusNote: this.statusChangeNote,
        }).then((result) => {
          if (result.status == 204) {
            this.eventStatusModal = false;
            this.$emit("refreshAppointments");
          }
        });
      }
      this.closeCard()
    },
    deleteAppointment: function(id) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlem geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/appointments/?ids=" + id).then((result) => {
            if (result.status == 204) {
              this.$swal({
                icon: "success",
                text: "İşlem Başarıyla tamamlandı.",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$emit("closeAppointmentCard");
              this.$emit("refreshAppointments");
            }
          });
        }
      });
    },
    UpdateAppointmentUser() {
      const appointmentId = this.appointment.id;
      const temsilciId = this.appointment.userId;

      this.appointment.participants = [];

      this.appointment.AppointmentsParticipants.forEach((participant) => {
        this.appointment?.participants.push(participant.id);
      });

      HTTP.patch("/appointments/" + appointmentId, this.appointment)
        .then((result) => {
          this.temsilciChangeModal = false;
          const userInfos = this.allUsers;

          for (let i in userInfos) {
            if (userInfos[i]?.id == temsilciId) {
              this.appointment.user.name = userInfos[i].name;
            }
          }

          this.$swal("", "Randevu Temsilcisi Güncellendi", "success");
        })
        .catch((error) => {
          console.log(error);
          this.temsilciChangeModal = false;
          this.$swal(
            "",
            "Güncelleme Sırasında Bir Hata Meydana Geldi",
            "warning"
          );
        });
    },
  },
  watch: {
    appointment: function() {
      this.statusChangeNote = this.getLatestAppointmentNote(
        this.appointment.statusHistory
      );
    },
    appointmentDetails: async function() {
      await this.getFiles();
    },
  },
};
</script>

<style scoped>
.dialog .md-dialog-container {
  max-width: 90vw;
}

.md-success {
  background-color: #4cd137 !important;
  color: white !important;
}

.spinner {
  margin-left: 1rem;
}

.customfileinput {
  cursor: pointer;
  border: 1px solid #636e72;
  padding: 1rem 3rem 1rem 1rem;
  width: 35rem;
  text-align-last: left;
}

.customfileinput label {
  cursor: pointer;
}

.md-title {
  flex: 0;
}

.md-dialog-actions a,
.md-dialog-actions button {
  margin: 0 !important;
}

.bottom-button-container {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
