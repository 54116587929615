<template>
  <div>
    <AppointmentCard :appointment="appointment" :appointmentDetails="appointmentDetails"
      @closeAppointmentCard="closeAppointmentCard" @refreshAppointments="refreshAppointments"></AppointmentCard>
    <div class="md-layout filterBtn">
      <button class="md-secondary md-raised" @click="filterToggle">
        <md-icon :class="{ filterRotateAnim: filterShow }">filter_list</md-icon>
        <span class="filter-btn-text" v-if="filterShow">Hide</span>
        <span class="filter-btn-text" v-else>Show</span>
      </button>
    </div>

    <p style="background: #d5f5e3; padding: 10px; font-weight: bold" v-if="roleId == 4 || roleId == 3">
      Kalan Ameliyatlı: {{ isSurgeryCount }} / Ameliyatsız:
      {{ isNotSurgeryCount }}
    </p>
    <div class="hidden-big mt-2" v-if="!filterShow">
      <button class="little-btn" @click="getQuick('today')" :class="{ active: quickSelect == 'today' }">
        Bugün
      </button>
      <button class="little-btn" @click="getQuick('week')" :class="{ active: quickSelect == 'week' }">
        Bu Hafta
      </button>
      <button class="little-btn" @click="getQuick('month')" :class="{ active: quickSelect == 'month' }">
        Bu Ay
      </button>
      <button class="little-btn" @click="getQuick('nextMonth')" :class="{ active: quickSelect == 'nextMonth' }">
        Gelecek Ay
      </button>
      <button class="little-btn md-xsmall-size-50 hidden-mini" @click="getQuick('last month')"
        :class="{ active: quickSelect == 'last month' }">
        Önceki Ay
      </button>
      <br />
      <br />
      <button class="little-btn" @click="changeDay('prev')">
        <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
      </button>
      <button class="little-btn" @click="changeDay('next')">
        Sonraki Gün
        <md-icon>keyboard_arrow_right</md-icon>
      </button>
      <button class="little-btn" @click="getQuick('special')" :class="{ active: quickSelect == 'special' }">
        Özel
      </button>
      <div class="md-layout-item md-size-20 md-xsmall-size-100">
        <md-datepicker v-model="startDate" md-immediately v-if="quickSelect == 'special'">
          <label>Başlangıç Tarihi</label>
        </md-datepicker>
      </div>

      <div class="md-layout-item md-size-20 md-xsmall-size-100">
        <md-datepicker v-model="endDate" md-immediately v-if="quickSelect == 'special'">
          <label>Bitiş Tarihi</label>
        </md-datepicker>
      </div>
    </div>

    <div
      class="md-layout filter"
      v-if="!selectingAgenciesChild"
      :class="{ show: filterShow == true }"
    >
      <div class="md-layout-item md-size-60 quick-action md-xsmall-size-100">
        <button class="little-btn" @click="getQuick('today')" :class="{ active: quickSelect == 'today' }">
          Bugün
        </button>
        <button class="little-btn" @click="getQuick('week')" :class="{ active: quickSelect == 'week' }">
          Bu Hafta
        </button>
        <button class="little-btn" @click="getQuick('month')" :class="{ active: quickSelect == 'month' }">
          Bu Ay
        </button>
        <button class="little-btn" @click="getQuick('nextMonth')" :class="{ active: quickSelect == 'nextMonth' }">
          Gelecek Ay
        </button>
        <button class="little-btn md-xsmall-size-50" @click="getQuick('last month')"
          :class="{ active: quickSelect == 'last month' }">
          Önceki Ay
        </button>
        <button class="little-btn" @click="getQuick('special')" :class="{ active: quickSelect == 'special' }">
          Özel
        </button>
        <button class="little-btn md-xsmall-hide" @click="changeDay('prev')">
          <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
        </button>
        <button class="little-btn md-xsmall-hide" @click="changeDay('next')">
          Sonraki Gün
          <md-icon>keyboard_arrow_right</md-icon>
        </button>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-100">
        <md-datepicker v-model="startDate" md-immediately v-if="quickSelect == 'special'">
          <label>Başlangıç Tarihi</label>
        </md-datepicker>
      </div>

      <div class="md-layout-item md-size-20 md-xsmall-size-100">
        <md-datepicker v-model="endDate" md-immediately v-if="quickSelect == 'special'">
          <label>Bitiş Tarihi</label>
        </md-datepicker>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100" v-if="this.branches.length > 1">
        <md-field class>
          <v-select v-model="selectedBranch" placeholder="Branşlar" :options="branches" label="name" :clearable="false"
            class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100">
        <md-field class>
          <v-select v-model="selectedLocation" placeholder="Konumlar" :options="locations" :close-on-select="false"
            label="name" class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedLocation, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100">
        <md-field class>
          <v-select v-model="selectedOperation" placeholder="İşlemler" :options="operations" :close-on-select="false"
            label="name" class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedOperation, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100">
        <md-field>
          <v-select v-model="selectedStatus" placeholder="Randevu Durumu" :options="statuses" :close-on-select="false"
            label="name" class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedStatus, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100">
        <md-field>
          <v-select v-model="selectedParticipant" placeholder="Doktorlar" :options="participants" :close-on-select="false"
            label="name" class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedParticipant, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100" v-if="hasAccess()">
        <md-field>
          <v-select v-model="selectedUser" placeholder="Kullanıcı" :options="users" :close-on-select="false" label="name"
            class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedUser, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <!-- Agencie -->
      <div
        class="md-layout-item row-filter md-xsmall-size-100"
        v-if="roleId == 1 || roleId == 3"
      >
        <md-field>
          <v-select
            v-model="selectedAgencie"
            placeholder="Kaynak"
            :options="childDontShow(agencies)"
            :close-on-select="false"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedAgencie, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100" v-if="roleId == 1">
        <md-field>
          <v-select v-model="selectedCountry" placeholder="Ülke" :options="countries" :close-on-select="false"
            label="name_tr" class="md-menu" multiple>
            <template slot="option" slot-scope="option">
              {{ option.name_tr }}
              <span class="optionSelected">{{
                checkIfSelected(selectedCountry, option, "code")
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100" v-if="roleId == 1">
        <md-field>
          <v-select v-model="selectedLanguage" placeholder="Dil" :options="languages" label="name" class="md-menu"
            :close-on-select="false" multiple>
            <template slot="option" slot-scope="option">{{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(selectedLanguage, option)
              }}</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item row-filter md-xsmall-size-100">
        <md-field>
          <v-select v-model="isShowActivities" placeholder="Aktivileri Getir" :options="showActivitiesOptions"
            label="name" class="md-menu" :close-on-select="true">
            <template slot="option" slot-scope="option">{{ option.name }}
              <span class="optionSelected" v-if="isShowActivities && isShowActivities.id === option.id">✓</span>
            </template>
          </v-select>
        </md-field>
      </div>

      <div class="md-layout-item md-xsmall-size-100">
        <md-button
          class="md-raised md-primary btn btn-full btn-mg"
          v-on:click="getAppointmentsByDate(), filterToggle()"
          >Randevuları Getir</md-button
        >
        <md-button
          class="md-dense md-secondary btn btn-full btn-mg"
          v-on:click="clearFilter"
          v-if="
            selectedStatus != [] ||
              selectedLocation != [] ||
              selectedParticipant != [] ||
              selectedUser != [] ||
              selectedOperation != [] ||
              selectedAgencie != [] ||
              selectedCountry != []
          "
          >Filtreleri Temizle</md-button
        >
      </div>
    </div>

    <div
      class="md-layout filter"
      v-if="selectingAgenciesChild"
      :class="{ show: filterShow == true }"
    >
      <div style="display:flex;flex-wrap: wrap;">
        <div class="md-layout-item md-size-60 quick-action md-xsmall-size-100">
          <button
            class="little-btn"
            @click="getQuick('today')"
            :class="{ active: quickSelect == 'today' }"
          >
            Bugün
          </button>
          <button
            class="little-btn"
            @click="getQuick('week')"
            :class="{ active: quickSelect == 'week' }"
          >
            Bu Hafta
          </button>
          <button
            class="little-btn"
            @click="getQuick('month')"
            :class="{ active: quickSelect == 'month' }"
          >
            Bu Ay
          </button>
          <button
            class="little-btn"
            @click="getQuick('nextMonth')"
            :class="{ active: quickSelect == 'nextMonth' }"
          >
            Gelecek Ay
          </button>
          <button
            class="little-btn md-xsmall-size-50"
            @click="getQuick('last month')"
            :class="{ active: quickSelect == 'last month' }"
          >
            Önceki Ay
          </button>
          <button
            class="little-btn"
            @click="getQuick('special')"
            :class="{ active: quickSelect == 'special' }"
          >
            Özel
          </button>
          <button class="little-btn md-xsmall-hide" @click="changeDay('prev')">
            <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
          </button>
          <button class="little-btn md-xsmall-hide" @click="changeDay('next')">
            Sonraki Gün
            <md-icon>keyboard_arrow_right</md-icon>
          </button>
        </div>
        <div class="md-layout-item md-size-20 md-xsmall-size-100">
          <md-datepicker
            v-model="startDate"
            md-immediately
            v-if="quickSelect == 'special'"
          >
            <label>Başlangıç Tarihi</label>
          </md-datepicker>
        </div>

        <div class="md-layout-item md-size-20 md-xsmall-size-100">
          <md-datepicker
            v-model="endDate"
            md-immediately
            v-if="quickSelect == 'special'"
          >
            <label>Bitiş Tarihi</label>
          </md-datepicker>
        </div>

        <div
          class="md-layout-item row-filter md-xsmall-size-100"
          v-if="this.branches.length > 1"
        >
          <md-field class>
            <v-select
              v-model="selectedBranch"
              placeholder="Branşlar"
              :options="branches"
              label="name"
              :clearable="false"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item row-filter md-xsmall-size-100">
          <md-field class>
            <v-select
              v-model="selectedLocation"
              placeholder="Konumlar"
              :options="locations"
              :close-on-select="false"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedLocation, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item row-filter md-xsmall-size-100">
          <md-field class>
            <v-select
              v-model="selectedOperation"
              placeholder="İşlemler"
              :options="operations"
              :close-on-select="false"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedOperation, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item row-filter md-xsmall-size-100">
          <md-field>
            <v-select
              v-model="selectedStatus"
              placeholder="Randevu Durumu"
              :options="statuses"
              :close-on-select="false"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedStatus, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item row-filter md-xsmall-size-100">
          <md-field>
            <v-select
              v-model="selectedParticipant"
              placeholder="Doktorlar"
              :options="participants"
              :close-on-select="false"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedParticipant, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div
          class="md-layout-item row-filter md-xsmall-size-100"
          v-if="hasAccess()"
        >
          <md-field>
            <v-select
              v-model="selectedUser"
              placeholder="Kullanıcı"
              :options="users"
              :close-on-select="false"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedUser, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div
          class="md-layout-item row-filter md-xsmall-size-100"
          v-if="roleId == 1"
        >
          <md-field>
            <v-select
              v-model="selectedCountry"
              placeholder="Ülke"
              :options="countries"
              :close-on-select="false"
              label="name_tr"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name_tr }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedCountry, option, "code")
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div
          class="md-layout-item row-filter md-xsmall-size-100"
          v-if="roleId == 1"
        >
          <md-field>
            <v-select
              v-model="selectedLanguage"
              placeholder="Dil"
              :options="languages"
              label="name"
              class="md-menu"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedLanguage, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item row-filter md-xsmall-size-100">
          <md-field>
            <v-select
              v-model="isShowActivities"
              placeholder="Aktivileri Getir"
              :options="showActivitiesOptions"
              label="name"
              class="md-menu"
              :close-on-select="true"
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span
                  class="optionSelected"
                  v-if="isShowActivities && isShowActivities.id === option.id"
                  >✓</span
                >
              </template>
            </v-select>
          </md-field>
        </div>

        <div class="md-layout-item md-xsmall-size-100">
          <md-button
            class="md-raised md-primary btn btn-full btn-mg"
            v-on:click="getAppointmentsByDate(), filterToggle()"
            >Randevuları Getir</md-button
          >
          <md-button
            class="md-dense md-secondary btn btn-full btn-mg"
            v-on:click="clearFilter"
            v-if="
              selectedStatus != [] ||
                selectedLocation != [] ||
                selectedParticipant != [] ||
                selectedUser != [] ||
                selectedOperation != [] ||
                selectedAgencie != [] ||
                selectedCountry != []
            "
            >Filtreleri Temizle</md-button
          >
        </div>
      </div>

      <!-- Divider -->
      <div style="width:100%;display:flex;align-items: center;">
        <div
          style="width:80%; height: 1px; background-color: gray;margin-bottom: 20px;margin-top: 15px;opacity:0.4;color : gray"
        ></div>
      </div>

      <!-- Agencie & Sub Agencie Section -->
      <div style="display:flex;flex-wrap: wrap;align-items: center;">
        <div
          class="md-layout-item row-filter md-xsmall-size-100"
          v-if="roleId == 1 || roleId == 3"
        >
          <md-field>
            <v-select
              v-model="selectedAgencie"
              placeholder="Kaynak"
              :options="childDontShow(agencies)"
              :close-on-select="false"
              label="name"
              class="md-menu"
              :disabled="isDisabledWatcher"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(selectedAgencie, option)
                }}</span>
              </template>
            </v-select>
          </md-field>
        </div>

        <!-- <NewInput>
          <v-select
            v-model="selectedAgencieFilter"
            placeholder="Kaynak"
            :options="childDontShow(agencies)"
            label="name"
            class="md-menu"
            multiple
            :disabled="isDisabledWatcher"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput> -->

        <div :key="index" v-for="(item, index) in selectedAgencie">
          <NewInput width="250" v-if="item.childAgencies.length > 0">
            <v-select
              v-model="selectedAgencieChildFilter[index]"
              :placeholder="item.name + ' Alt Kaynak'"
              :options="childIsDeletedControl(item.childAgencies)"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
              </template>
            </v-select>
          </NewInput>
        </div>
      </div>
    </div>

    <div v-if="!loading">
      <!-- first line -->
      <div class="md-layout wrapper md-size-100">
        <div
          class="md-layout md-size-20 md-xsmall-size-100"
          @click="showOpData('all')"
        >
          <div class="md-layout-item md-xsmall-size-50 hover-effect">
            <b>Toplam İşlemler:</b>
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            {{ this.appointments.length }}
          </div>
        </div>

        <div
          class="md-layout-item md-size-20 md-xsmall-size-100"
          v-for="(aoperation, index) in totalOperations"
          :key="index"
          @click="showOpData(operationName(aoperation))"
        >
          <div class="md-layout">
            <div class="md-layout-item md-xsmall-size-50 hover-effect">
              <b>{{ operationName(aoperation) }}:</b>
            </div>
            <div class="md-layout-item md-xsmall-size-50">
              {{ operationCount(aoperation) }}
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 10px; padding-left: 10px; font-size: 24px">
        {{
          this.opToShow.length === 0
            ? "Toplam İşlemler:"
            : this.opToShow.join(" - ") + ":"
        }}
      </div>
      <!-- second line -->
      <div class="md-layout wrapper md-size-100">
        <div class="md-layout md-size-20 md-xsmall-size-100">
          <div v-if="count(2) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status done"></span>
            Tamamlandı: {{ count(2) }}
          </div>
          <div v-if="count(1) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status waiting"></span>
            Beklemede: {{ count(1) }}
          </div>
          <div v-if="count(7) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status approve"></span>
            Onay Bekliyor: {{ count(7) }}
          </div>
          <div v-if="count(4) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status postponed"></span>
            Ertelendi: {{ count(4) }}
          </div>
          <div v-if="count(5) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status not-come"></span>
            Gelmedi: {{ count(5) }}
          </div>
          <div v-if="count(3) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status cancel"></span>
            İptal: {{ count(3) }}
          </div>
          <div v-if="count(6) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status optional"></span>
            Opsiyonlandı: {{ count(6) }}
          </div>
          <div v-if="count(8) > 0" class="md-layout-item md-xsmall-size-100">
            <span class="status depositPaid"></span>
            Depozit Alındı: {{ count(8) }}
          </div>
        </div>
      </div>
      <div class="md-layout details">
        <div class="md-layout-item wrapper md-size-100">
          <div class="md-layout-item">
            <span class="md-title title">Randevular</span>
            <br />
            <span class="md-subheading" v-if="quickSelect != 'today'"
              >{{ startDateText }} - {{ endDateText }}</span
            >
            <span class="md-subheading" v-if="quickSelect == 'today'">
              {{ startDateText }}
            </span>
            <div class="appointments-edit-frame">
              <router-link
                :to="{
                  name: 'createAppointment',
                }"
                v-if="!isDoctor && !isSocialMediaUser && !isDietician"
              >
                <md-button class="md-fab md-mini md-primary floatButton">
                  <md-icon>add</md-icon>
                </md-button>
              </router-link>
              <md-button
                class="md-fab md-mini md-green floatButton hidden-mini"
                v-print="'#appointmentsTable'"
                v-if="!isSocialMediaUser"
              >
                <md-icon>print</md-icon>
              </md-button>
              <md-button
                class="md-fab md-mini md-accent floatButton hidden-mini"
                v-if="!isSocialMediaUser"
                @click="downloadExcel"
              >                
                <md-icon>download</md-icon>
              </md-button>
            </div>
          </div>
          <md-table
            class="table appointments"
            v-model="appointments"
            id="appointmentsTable"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              @click="
                item.canView == true ? showAppointmentDetails(item.id) : ''
              "
            >
              <md-table-cell md-label="Durum">
                <div
                  class="status"
                  v-bind:class="[
                    item.status.id === 1 ? 'waiting' : '',
                    item.status.id === 2 ? 'done' : '',
                    item.status.id === 3 ? 'cancel' : '',
                    item.status.id === 4 ? 'postponed' : '',
                    item.status.id === 5 ? 'not-come' : '',
                    item.status.id === 6 ? 'optional' : '',
                    item.status.id === 7 ? 'approve' : '',
                    item.status.id === 8 ? 'depositPaid' : '',
                  ]"
                ></div>
                {{ item.status ? item.status.name : "" }}
                <span v-if="getLatestAppointmentNote(item.statusHistory) != ''"
                  ><br />({{
                    widthControl(getLatestAppointmentNote(item.statusHistory))
                  }})</span
                >
                <md-tooltip
                  v-if="getLatestAppointmentNote(item.statusHistory) != ''"
                  style="font-size: 13px"
                >
                  {{ getLatestAppointmentNote(item.statusHistory) }}
                </md-tooltip>
              </md-table-cell>
              <md-table-cell md-label="Hasta">
                {{ item.patient.name }}
              </md-table-cell>
              <md-table-cell md-label="İşlem">
                {{ item.operation.name }}
              </md-table-cell>
              <md-table-cell md-label="Personel">
                {{ item.user.name }}
              </md-table-cell>
              <md-table-cell md-label="Dil">
                {{ item.patient.language ? item.patient.language.name : "" }}
              </md-table-cell>
              <md-table-cell md-label="Konum">
                {{ item.location ? item.location.name : "" }}
              </md-table-cell>
              <md-table-cell md-label="Kaynak" v-if="roleId == 1">
                {{ item.patient.agencie ? item.patient.agencie.name : "" }}
              </md-table-cell>
              <md-table-cell md-label="Ülke" v-if="roleId == 1">
                {{ item.patient.country ? item.patient.country.name_tr : "" }}
              </md-table-cell>
              <md-table-cell md-label="Katılımcılar">
                <span
                  class="participants"
                  v-for="participants in item.AppointmentsParticipants"
                  v-bind:key="participants.id"
                  >{{ participants.name }}</span
                >
              </md-table-cell>
              <md-table-cell md-label="Tarih">
                {{ formatDate(item.date) }}
                - {{ item.time.substring(0, 5) }}
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div v-for="appointment in appointments" v-bind:key="appointment.id">
            {{ appointment.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <SkeletonTableLoading v-if="true" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import { HTTP } from "../main-source";
import AppointmentCard from "@/components/patients/appointmentCard";
import moment from "moment";
import print from "vue-print-nb";
import NewInput from "../components/newInput";
// import jsonExcel from "vue-json-excel";
import SkeletonTableLoading from "@/components/SkeletonTableLoading.vue";
import * as XLSX from "xlsx";

export default {
  name: "AppointmentsList",
  data() {
    return {
      appointments: [],
      appointment: {
        id: "",
        operatorNote: null,
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: "",
        },
        location: {
          name: "",
        },
        operation: {
          name: "",
        },
        status: {
          name: "",
        },
        user: {
          name: "",
        },
      },
      allUsers: [],
      branches: [],
      operations: [],
      locations: [],
      startDate: new Date(),
      endDate: new Date(),
      selectedStatus: [],
      isShowActivities: {
        id: true,
        name: "Evet",
      },
      showActivitiesOptions: [
        {
          id: true,
          name: "Evet",
        },
        {
          id: false,
          name: "Hayır",
        },
      ],
      statuses: [],
      appointmentDetails: false,
      appointmentStatusModal: false,
      created: false,
      deleteModal: false,
      selectedBranch: [],
      doctorsList: [],
      holidays: [],
      selectedLocation: [],
      selectedUser: [],
      selectedOperation: [],
      selectedParticipant: [],
      selectedLanguage: [],
      totalOperations: [],
      filterShow: false,
      isSpecialDate: false,
      quickSelect: "today",
      opToShow: [], //if empty => show every thing
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
      leading: JSON.parse(localStorage.getItem("leading")),
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      isDoctor: localStorage.getItem("roleId") == 2,
      isDietician: localStorage.getItem("roleId") == 9,
      isSocialMediaUser: localStorage.getItem("roleId") == 8,
      agencies: [],
      // Child Agencie
      filterAgencies: [],
      selectedAgencie: [],
      selectedAgencieChildFilter: [],
      isDisabledWatcher: false,
      selectingAgenciesChild: false,
      countries: [],
      languages: [],
      selectedCountry: [],
      dayLimit: {},
      isSurgeryCount: null,
      isNotSurgeryCount: null,
      exportFields: {
        Durum: {
          field: "status",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        "Hasta Adı": "patient.name",
        İşlem: {
          field: "operation",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Personel: {
          field: "user",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Konum: {
          field: "location",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Katılımcılar: {
          field: "AppointmentsParticipants",
          callback: (value) => {
            return value && value.length > 0 ? value.map((z) => z.name) : "";
          },
        },
        Tarih: {
          callback: (item) => {
            return item
              ? this.formatDate(item.date) + " - " + item.time.substring(0, 5)
              : "";
          },
        },
        Ülke: {
          field: "patient.country.name",
        },
        Kaynak: {
          field: "patient.agencie.name",
        },
        Telefon: {
          field: "patient.phone",
        },
      },
      days: {
        1: "Pazartesi",
        2: "Salı",
        3: "Çarşamba",
        4: "Perşembe",
        5: "Cuma",
        6: "Cumartesi",
        0: "Pazar",
      },
      twoDateBetweenDays: [],
      loading: true,
    };
  },
  components: {
    AppointmentCard,
    // jsonExcel,
    NewInput,
    SkeletonTableLoading,
  },
  directives: {
    print,
  },
  created: async function() {
    moment.locale("tr");
    // HTTP.get("/status/").then(result => {
    //   this.statuses = result.data;
    // });

    await this.getBranches();
    await this.getHolidays();
    await this.getOperations();
    await this.getLocations();

    await HTTP.get("/settings/default/daylimit").then((result) => {
      result.data.forEach((z) => (this.dayLimit[z.name] = z.transaction_limit));
    });
    await HTTP.get("/status/").then((result) => {
      //
      this.statuses = result.data;
      // this.statuses.push({ id: "0", name: "Tüm Durumlar" });
      // this.statuses.reverse();
    });

    this.getUsers();

    var agenciesLimitation = "";
    if (this.roleId != 1) agenciesLimitation = "?userId=" + this.userId;
    await HTTP.get("/agencies/" + agenciesLimitation).then((result) => {
      this.agencies = result.data;
    });

    await HTTP.get("/languages").then((result) => {
      this.languages = result.data;
    });

    await HTTP.get("/countries").then((result) => {
      this.countries = result.data;
    });

    this.getAppointmentsByDate();
    this.isSurgeryOrNotCounter();
    this.loading = false;
  },
  methods: {
    getLatestAppointmentNote: function(statusHistory) {
      if (!statusHistory) return "";
      let history = JSON.parse(statusHistory);
      let latestNote = history[history.length - 1];
      return latestNote.note;
    },
    async downloadExcel() {
      // Sample data (replace this with data from your backend)

      const allData = this.appointments

      console.log(allData);
      const data =
        allData?.length > 0
          ? this.transformDataForExcel(allData)
          : [{ Header: "Empty" }];

      // Convert the data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Save the workbook
      XLSX.writeFile(wb, this.excelFileName);
    },
    transformDataForExcel(data) {
      return data?.map((item) => ({
        "Durum": item?.status?.name,
        "Hasta Adı": item?.patient?.name,
        İşlem: item?.operation?.name,
        Personel: item?.user?.name,
        Konum: item?.location?.name,
        Katılımcılar: item?.AppointmentsParticipants?.length && this.showAppointmentsParticipantsInExcel(item?.AppointmentsParticipants),
        "Tarih": item ? this.formatDate(item.date) + " - " + item.time.substring(0, 5)
          : "",
        "Ülke": item?.patient?.country?.name,
        Kaynak: item?.patient?.agencie?.name,
        Telefon: item?.patient?.phone,
      }));
    },
    showAppointmentsParticipantsInExcel(parts){
      const res = parts?.map(z => z.name);
      return res?.toString()
    },
    thisMonth() {
      var month = moment(new Date()).format("MMMM");
      return month;
    },
    isSurgeryOrNotCounter: function() {
      let baseUri = "/appointments/";
      let uri = "?";

      var firstDay = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth(),
        1
      );
      var lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );

      let startText =
        firstDay.getFullYear() +
        "-" +
        (firstDay.getMonth() + 1) +
        "-" +
        ("0" + firstDay.getDate()).slice(-2);

      let endText =
        lastDay.getFullYear() +
        "-" +
        (lastDay.getMonth() + 1) +
        "-" +
        ("0" + lastDay.getDate()).slice(-2);
      uri += "&userId=" + this.userId;
      uri += "&startDate=" + startText + "&endDate=" + endText;

      HTTP.get(baseUri + uri).then((result) => {
        var appoinmentFilterByStatus = result.data.filter(
          (z) => z.statusId == 1 || z.statusId == 2 || z.statusId == 6
        );

        this.isSurgeryCount =
          this.dayLimit.userMonthlyLimit -
          appoinmentFilterByStatus.filter((z) => z.operation.isSurgery == true)
            .length;
        this.isNotSurgeryCount =
          this.dayLimit.userMonthlyNotSurgeryLimit -
          appoinmentFilterByStatus.filter(
            (z) => z.operation.isNotSurgery == true
          ).length;
      });
    },
    checkIfSelected: function(model, val, field = "id") {
      return model.find((z) => z[field] == val[field]) ? "✓" : "";
    },
    getBranches: function() {
      HTTP.get("/users/profile/" + this.userId).then((result) => {
        let user = result.data[0];
        this.branches = user.branches;

        if (this.branches.length > 0)
          this.selectedBranch.push(this.branches[0]);
      });
    },
    getUsers: function() {
      HTTP.get("/users/").then((result) => {
        this.allUsers = result.data.filter((user) => user.status == true);
        this.doctorsList = result.data.filter((user) => user.roleId == 2);
      });
    },
    getHolidays: function() {
      HTTP.get("/holidays").then((result) => {
        result.data.forEach((holiday) => {
          this.holidays.push({
            start: holiday.start + " " + holiday.startTime.substr(0, 5),
            end: holiday.end + " " + holiday.endTime.substr(0, 5),
            title: holiday.title,
            branchId: holiday.branchId,
            doctorId: holiday.doctorId,
          });
        });
      });
    },
    getOperations: function() {
      setTimeout(() => {
        HTTP.get("/operations/").then((result) => {
          this.operations = result?.data?.filter(
            (operation) =>
              operation?.status == true &&
              this.selectedBranch
                ?.map((z) => z.id)
                ?.includes(operation?.branchId)
          );
        });
      }, 1000);
    },
    closeAppointmentCard() {
      this.appointmentDetails = false;
    },
    refreshAppointments() {
      this.getAppointmentsByDate();
    },
    agencieName: function(patient) {
      if (!patient.agencieId) return "";
      var agencie = this.agencies.find((z) => z.id == patient.agencieId);
      return agencie ? agencie.name : "";
    },
    sortAppointmentsByDate(a, b) {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    showOpData(name) {
      if (name === "all") {
        this.opToShow = [];
      } else if (this.opToShow.includes(name)) {
        this.opToShow.splice(this.opToShow.indexOf(name), 1);
      } else {
        this.opToShow.push(name);
      }
    },
    hasAccess: function() {
      if (this.roleId == 1 || this.roleId == 4) {
        return true;
      }
      return false;
    },
    count: function(status) {
      if (!this.appointments) return 0;
      if (this.opToShow.length === 0) {
        return this.appointments.filter((item) => item.statusId == status)
          .length;
      } else {
        let counter = 0;
        for (let index = 0; index < this.appointments.length; index++) {
          const appointment = this.appointments[index];
          if (
            appointment.statusId == status &&
            this.opToShow.includes(appointment.operation.name)
          ) {
            counter++;
          }
        }
        return counter;
      }
    },
    getQuick: function(options) {
      var current = new Date(); // get current date
      this.quickSelect = options;

      switch (this.quickSelect) {
        case "today":
          this.startDate = current;
          this.endDate = current;
          break;
        case "week":
          var weekstart = current.getDate() - current.getDay() + 1;
          var weekend = weekstart + 6; // end day is the first day + 6
          var monday = new Date(current.setDate(weekstart));
          var sunday = new Date(current.setDate(weekend));
          if (monday > sunday) {
            sunday.setMonth(sunday.getMonth() + 1);
          }
          this.startDate = monday;
          this.endDate = sunday;
          break;
        case "month":
          var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
          var lastDay = new Date(
            current.getFullYear(),
            current.getMonth() + 1,
            0
          );
          this.startDate = firstDay;
          this.endDate = lastDay;
          break;
        case "nextMonth":
          var firstDay = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth() + 1,
            1
          );
          var lastDay = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth() + 2,
            0
          );
          this.startDate = firstDay;
          this.endDate = lastDay;
          break;
        case "last month":
          var firstDay = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth() - 1,
            1
          );
          var lastDay = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 0);
          this.startDate = firstDay;
          this.endDate = lastDay;
          break;
      }
      this.isSurgeryOrNotCounter();
      if (this.quickSelect != "special") {
        this.getAppointmentsByDate();
      }
    },
    showAppointmentDetails: function(id) {
      if (this.isSocialMediaUser) return;
      HTTP.get("/appointments/" + id).then((result) => {
        this.appointment = result.data[0];
        setTimeout(() => {
          this.appointmentDetails = true;
        }, 200);
      });
    },
    // Filter
    getAppointmentsByDate: async function() {
      this.loading = true;
      this.appointments = [];

      let baseUri = "/appointments/";
      let uri = "?";

      if (this.isShowActivities?.id === false) {
        uri += "type=onlyOperations";
      }

      if (this.selectedBranch.length) {
        uri += "&branchId=" + this.selectedBranch.map((z) => z.id);
      }

      if (this.selectedStatus.length) {
        var statuses = this.selectedStatus.map((z) => z.id);
        uri += "&statusId=" + statuses;
      }

      if (this.selectedLocation.length) {
        var locations = this.selectedLocation.map((z) => z.id);
        uri += "&locationId=" + locations;
      }

      if (this.selectedParticipant.length) {
        var participants = this.selectedParticipant.map((z) => z.id);
        uri += "&participantsId=" + participants;
      }

      if (this.hasAccess()) {
        if (this.selectedUser.length) {
          var users = this.selectedUser.map((z) => z.id);
          uri += "&userId=" + users;
        }
      } else if (
        !this.isDoctor &&
        !this.isSocialMediaUser &&
        !this.isDietician
      ) {
        uri += "&userId=" + this.userId + "," + this.leading.join();
      }

      if (this.selectedOperation.length) {
        var operations = this.selectedOperation.map((z) => z.id);
        uri += "&operationId=" + operations;
      }

      if (this.filterAgencies.length) {
        var agencies = this.filterAgencies.map((z) => z.id);
        uri += "&agencieId=" + agencies;
      }

      if (this.selectedCountry.length) {
        var countries = this.selectedCountry.map((z) => z.code);
        uri += "&countryCode=" + countries;
      }

      if (this.selectedLanguage.length) {
        var languages = this.selectedLanguage.map((z) => z.id);
        uri += "&language=" + languages;
      }

      if (this.startDate && this.endDate) {
        let startText =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1) +
          "-" +
          ("0" + this.startDate.getDate()).slice(-2);

        let endText =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1) +
          "-" +
          ("0" + this.endDate.getDate()).slice(-2);
        uri += "&startDate=" + startText + "&endDate=" + endText;
      }
      let userId = localStorage.getItem("userId");
      let data = [];
      await HTTP.get(baseUri + uri).then((result) => {
        result.data.forEach((a) => {
          a.canView = true;
          // var appointmentParticipants = a.AppointmentsParticipants.map(z => z.id);
          // if(appointmentParticipants.includes(parseInt(userId)))
          if (
            this.hasAccess() ||
            a.userId == userId ||
            this.leading.includes(a.userId) ||
            this.isDoctor ||
            this.isSocialMediaUser ||
            this.isDietician
          ) {
            data.push(a);
          } else {
            a.canView = false;
          }
        });
      });

      this.appointments = data.filter((z) => z); // don't show appointments waiting for approval here
      this.appointments.sort(this.sortAppointmentsByDate);

      this.loading = false;
    },
    operationName: function(operationId) {
      let operation = this.operations.find(
        (operation) => operation.id == operationId
      );
      return operation ? operation.name : "";
    },
    operationCount: function(operationId) {
      return this.appointments.filter(
        (appointment) => appointment.operationId == operationId
      ).length;
    },
    filterToggle: function() {
      this.filterShow = !this.filterShow;
    },
    clearFilter: function() {
      this.selectedStatus = [];
      this.selectedLocation = [];
      this.selectedParticipant = [];
      this.selectedUser = [];
      this.selectedOperation = [];
      this.selectingAgenciesChild = false;
      this.isDisabledWatcher = false;
      this.selectedAgencie = [];
      this.selectedCountry = [];
      this.selectedLanguage = [];
      this.getAppointmentsByDate();
    },
    changeDay: function(option) {
      this.quickSelect = "today";
      var activeDate = new Date(this.startDate);
      let val = option == "next" ? 1 : -1;
      var result = new Date(activeDate.getTime() + val * 86400000);

      this.startDate = result;
      this.endDate = result;

      this.getAppointmentsByDate();
    },
    changeAppointmentStatus: function() {
      //

      HTTP.patch("/appointments/" + this.appointment.id + "/status", {
        statusId: this.appointment.status.id
          ? this.appointment.status.id
          : this.appointment.status,
        lastUpdateUserId: localStorage.getItem("userId"),
      }).then((result) => {
        if (result.status == 204) {
          this.appointmentStatusModal = false;
          this.$swal("", "Randevu Durumu Başarıyla Güncellendi.", "success");
          this.getAppointmentsByDate();
        }
      });
    },
    getLocations: function() {
      setTimeout(() => {
        HTTP.get("/locations/").then((result) => {
          this.locations = result.data.filter(
            (location) =>
              location?.status == true &&
              this.selectedBranch.map((z) => z.id)?.includes(location.branchId)
          );
        });
      }, 1000);
    },
    widthControl: function(data) {
      if (data?.length > 20) {
        return data.substring(0, 20) + "...";
      } else {
        return data;
      }
    },
    selectedBranchesDoctorsList(data) {
      let selectedBranchIds = [];
      for (let i in this.selectedBranch) {
        selectedBranchIds.push(this.selectedBranch[i].id);
      }

      let control = false;

      if (data.branches.find((x) => selectedBranchIds.includes(x.id))) {
        control = true;
      }

      if (control) {
        return true;
      } else {
        return false;
      }
    },
    getDates(startDate, stopDate) {
      var dateArray = new Array();
      var currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(currentDate.getDay());
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    },
    twoDayBetweenDoctorDayControl(closedDays) {
      let closedDaysIds = [];

      for (let i in closedDays) {
        closedDaysIds.push(closedDays[i]);
      }

      if (this.twoDateBetweenDays.some((r) => closedDaysIds.indexOf(r) >= 0)) {
        return true;
      } else {
        return false;
      }
    },
    doctorsClosedDaysVisibleControl(day) {
      //var dateArray = this.getDates(new Date(this.startDate), new Date(this.endDate));

      if (this.twoDateBetweenDays.includes(day)) {
        return true;
      } else {
        return false;
      }
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    childIsDeletedControl(val) {
      return val.filter((item) => item.isDeleted == 0 && item);
    },
  },
  watch: {
    selectedBranch: function() {
      // this.selectedOperation = [];
      this.operations = [];
      this.getOperations();
      // this.selectedParticipant = [];
      // this.selectedLocation = [];
      this.locations = [];
      this.getLocations();
      // this.getAppointmentsByDate(true);
    },
    appointments: function() {
      let totalOperations = [];
      if (this.appointments.length > 0) {
        this.totalOperations = this.appointments.forEach((e) => {
          if (!totalOperations.includes(e.operationId)) {
            totalOperations.push(e.operationId);
          }
        });
        this.totalOperations = totalOperations;
      }
      //
    },
    selectedAgencie: function(val) {
      // get Ids
      let sendValue = this.selectedAgencieChildFilter;
      let flatSendValue = sendValue?.flat();

      // CHILD LAYOUT CONTROL - START
      let control = [];
      val?.filter(
        (item) => item?.childAgencies.length > 0 && control.push(true)
      );

      if (JSON.stringify(control).match(true)) {
        this.selectingAgenciesChild = true;
      } else {
        this.selectingAgenciesChild = false;
      }
      // CHILD LAYOUT CONTROL - END

      this.filterAgencies = [];
      if (flatSendValue?.length > 0 && val?.length > 0) {
        this.filterAgencies = flatSendValue;

        let ids = [];
        for (let i in sendValue) {
          for (let j in sendValue[i]) {
            ids.push(sendValue[i][j].id);
          }
        }

        let hasChildAgencies = [];
        let someths = val.filter((parent) => parent?.childAgencies?.length > 0);

        someths.map((pC) => {
          let childs = pC.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        const leftParentAgencies = val.filter(
          (p) => !hasChildAgencies.includes(p.id)
        );

        if (leftParentAgencies?.length > 0) {
          leftParentAgencies.map((l) => this.filterAgencies.push(l));
        }

        let childs = [];
        leftParentAgencies.map((v) =>
          childs.push(v.childAgencies?.map((z) => z))
        );
        let flattedChild = childs.flat();

        flattedChild.forEach((f) => this.filterAgencies.push(f));

        this.refreshAppointments();
      } else if (val != null && val?.length > 0) {
        let res = [];
        val?.map((v) => res.push(v));

        let childs = [];
        val.map((v) => childs.push(v?.childAgencies?.map((z) => z)));
        let flattedChild = childs?.flat();
        flattedChild.map((f) => res?.push(f));

        this.filterAgencies = res;
        this.refreshAppointments();
      } else {
        this.filterAgencies = this.agencies;
        this.refreshAppointments();
      }
    },
    selectedAgencieChildFilter: function(val) {
      if (this.selectingAgenciesChild) {
        let ids = [];
        for (let i in val) {
          for (let j in val[i]) {
            ids.push(val[i][j].id);
          }
        }

        if (ids?.length > 0) {
          this.isDisabledWatcher = true;
        } else {
          this.isDisabledWatcher = false;
        }

        let flatVal = val.flat();
        let flatSendValue = this.selectedAgencie?.flat();
        let sendValue = this.selectedAgencie;

        this.filterAgencies = [];

        if (sendValue?.length > 0 && flatVal?.length > 0) {
          let res = [];
          flatVal?.map((f) => res.push(f));
          // this.filterAgencies = flatVal;

          let hasChildAgencies = [];
          let someths = flatSendValue.filter(
            (parent) => parent?.childAgencies?.length > 0
          );

          someths.map((pC) => {
            let childs = pC.childAgencies.map((z) => z.id);

            childs.map((c) => {
              if (ids.includes(c)) hasChildAgencies.push(pC.id);
            });
          });

          const leftParentAgencies = flatSendValue.filter(
            (p) => !hasChildAgencies.includes(p.id)
          );

          if (leftParentAgencies?.length > 0) {
            leftParentAgencies.map((l) => res.push(l));
          }

          let childs = [];
          leftParentAgencies.map((v) =>
            childs.push(v?.childAgencies?.map((z) => z))
          );
          let flattedChild = childs?.flat();
          flattedChild.map((f) => res?.push(f));

          this.filterAgencies = res;

          this.refreshAppointments();
        } else if (sendValue?.length > 0) {
          let res = [];
          sendValue?.map((v) => res.push(v));

          let childs = [];
          sendValue.map((v) => childs.push(v?.childAgencies?.map((z) => z)));
          let flattedChild = childs?.flat();
          flattedChild.map((f) => res?.push(f));

          this.filterAgencies = res;

          this.refreshAppointments();
        } else {
          this.selectedAgencie = [];
          this.selectedAgencieChildFilter = [];
          this.filterAgencies = this.agencies;

          this.refreshAppointments();
        }
      }
    },
    startDate() {
      this.twoDateBetweenDays = this.getDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
    },
    endDate() {
      this.twoDateBetweenDays = this.getDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
    },
    startDate() {
      this.twoDateBetweenDays = this.getDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
    },
    endDate() {
      this.twoDateBetweenDays = this.getDates(
        new Date(this.startDate),
        new Date(this.endDate)
      );
    },
  },
  computed: {
    excelFileName: function() {
      let fileName = "Randevular " + this.startDateText;
      if (this.quickSelect != "today") fileName += " - " + this.endDateText;
      return fileName + ".xls";
    },
    startDateText: function() {
      let startText =
        ("0" + this.startDate.getDate()).slice(-2) +
        "-" +
        (this.startDate.getMonth() + 1) +
        "-" +
        this.startDate.getFullYear();

      var weekday = new Array(7);
      weekday[0] = "Pazar";
      weekday[1] = "Pazartesi";
      weekday[2] = "Salı";
      weekday[3] = "Çarşamba";
      weekday[4] = "Perşembe";
      weekday[5] = "Cuma";
      weekday[6] = "Cumartesi";

      var n = weekday[this.startDate.getDay()];
      return startText + " " + n;
    },
    endDateText: function() {
      let endText =
        ("0" + this.endDate.getDate()).slice(-2) +
        "-" +
        (this.endDate.getMonth() + 1) +
        "-" +
        this.endDate.getFullYear();
      return endText;
    },
    users: function() {
      return this.allUsers.filter((user) => user.role.name != "Doctor");
    },
    participants: function() {
      if (this.roleId == 2) {
        return this.allUsers.filter(
          (z) =>
            z.branches.find((z) => z.id == this.selectedBranch[0].id) &&
            z.roleId == 2
        );
      } else {
        return this.allUsers.filter(
          (user) =>
            user.role.id == 2 &&
            user.branches.filter((branch) =>
              this.selectedBranch.map((z) => z.id).includes(branch.id)
            ).length > 0 &&
            this.userDoctors.includes(user.id)
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

.filter-btn-text {
  font-weight: bold;
}

.filterRotateAnim {
  transform: rotate(180deg);
}

.participants {
  display: block;

  span {
    display: inline-block;
  }
}

.floatButton {
  float: right;
}

.table {
  width: 100%;
  margin-top: 0px;
}

.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}

.dialog .md-dialog-container {
  padding: 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  max-width: 80vw;

  .md-dialog-title {
    padding: 0px;
  }
}

.appointments {
  .md-table-row {
    cursor: pointer;
  }
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.dialog-actions {
  a {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.has-icon {
  padding-left: 10px !important;
}

.btn-full {
  width: 100%;
}

.filterBtn {
  display: none !important;
}

@media (max-width: 768px) {
  .hidden-mini {
    display: none !important;
  }

  .md-table-cell-container {
    padding: 3px !important;
  }

  .filter {
    height: 0px;
    overflow: hidden;
    transition: all 0.25s ease;
  }

  .show {
    height: auto;
    overflow: visible;
  }

  .filterBtn {
    display: flex !important;
    flex-direction: row-reverse;
  }

  .status-head {
    padding: 0 !important;
  }

  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;

    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}

.quick-action {
  display: block;
  margin-top: 20px;

  .little-btn {
    flex: 1;
    margin-top: 0.3rem;
    height: 30px;
    margin-top: 10px;
    font-weight: bold;
  }

  .active {
    color: $brandcolor !important;
  }
}

.row-filter {
  width: 16%;
  padding: 0px 10px 0px 0px;

  .vs__selected {
    font-size: 16px !important;
  }

  .vs__search {
    font-size: 16px !important;

    &::placeholder {
      color: #757575 !important;
      font-weight: 500;
    }
  }
}

.btn-mg {
  margin-top: 10px;
}

.filter {
  margin-bottom: 20px;
}

.hidden-big {
  .active {
    color: $brandcolor !important;
    font-weight: bold;
  }
}

.hover-effect {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.active {
  color: #00bba1;
  text-decoration: underline;
}

.md-button {
  margin: 0 !important;
}

.md-button.floatButton {
  margin-right: 10px !important;
}

.md-subheading {
  margin-left: 15px;
}

@media (min-width: 768px) {
  .hidden-big {
    display: none;
  }
}

.optionSelected {
  color: green;
  float: right;
  font-weight: bold;
}

// @media (max-width: 600px) {
//     thead {
//         display: none;
//     }
//     .md-table-row {
//         border-top: 1px solid grey;
//         border-bottom: 1px solid grey;
//         margin-bottom: 50px;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//     }

//     .md-table-row {
//         width: 100%;
//     }
// }

.md-table-cell-container {
  white-space: nowrap;
}

.little-btn {
  padding: 5px 4px;

  white-space: nowrap !important;
  padding: 5px 4px;
  margin-top: 10px;
}

.vs__deselect {
  width: 1rem;
  height: 1rem;
}

.vs__deselect > svg {
  margin: 0.2rem !important;
}

.vs__dropdown-menu li {
  width: 100%;
  white-space: break-spaces;
}

.appointments-edit-frame {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  margin-top: -60px;
}
</style>
