<template>
  <div>
    <span class="md-title md-layout-item md-size-100">Gün Kapatma</span>
    <div class="md-layout" v-if="message">{{ message }}</div>
    <div class="md-layout" v-if="!message">
      <div class="md-layout-item md-size-50 md-size-xsmall-100" v-if="isRoleValid(roleId)">
        <label>Branş</label>
        <md-field>
          <v-select v-model="selectedBranch" placeholder="Branşlar" :options="branches" label="name" :clearable="false"
            class="md-menu">
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
      </div>
      <div class="md-layout-item md-size-50 md-size-xsmall-100" v-if="isRoleValid(roleId)">
        <label>Doktor</label>
        <md-field>
          <v-select v-model="selectedDoctor" placeholder="Doktor" :options="doctors" label="name" :clearable="true"
            class="md-menu">
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
      </div>
      <div class="md-layout-item md-size-100">
        <md-field>
          <label>Başlık/Açıklama</label>
          <md-input v-model="holiday.title"></md-input>
        </md-field>
        <span class="md-error" v-if="notValid.includes('title')">Başlık/Açıklama girmelisiniz</span>
      </div>
      <span class="md-title md-layout-item md-size-100 title-2">Başlangıç Tarih & Saat</span>
      <div class="md-layout-item">
        <md-datepicker v-model="holiday.start" md-immediately>
          <label>Tarih</label>
        </md-datepicker>
        <span class="md-error" v-if="notValid.includes('start')">Başlangıç tarihi seçmelisiniz</span>
      </div>
      <div class="md-layout-item">
        <md-field>
          <vue-timepicker format="HH:mm" :minute-interval="5" placeholder="SS:dd" v-model="holiday.startTime"
            close-on-complete hide-disabled-hours></vue-timepicker>
        </md-field>
        <span class="md-error" v-if="notValid.includes('startTime')">Bitiş saati seçmelisiniz</span>
      </div>
      <span class="md-title md-layout-item md-size-100 title-2">Bitiş Tarih & Saat</span>
      <div class="md-layout-item">
        <md-datepicker v-model="holiday.end" md-immediately>
          <label>Tarih</label>
        </md-datepicker>
        <span class="md-error" v-if="notValid.includes('end')">Bitiş tarihi seçmelisiniz</span>
      </div>
      <div class="md-layout-item">
        <md-field>
          <vue-timepicker format="HH:mm" :minute-interval="5" placeholder="SS:dd" v-model="holiday.endTime"
            close-on-complete hide-disabled-hours></vue-timepicker>
        </md-field>
        <span class="md-error" v-if="notValid.includes('startTime')">Bitiş saati tarihi seçmelisiniz</span>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button class="md-raised md-primary calendar-nav" v-on:click="addHoliday">GÜN KAPAT</md-button>
        <md-button class="md-raised calendar-nav" @click="cancel">İPTAL</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

export default {
  name: "create",
  components: {
    VueTimepicker
  },
  data() {
    return {
      branches: [],
      doctors: [],
      selectedBranch: null,
      selectedDoctor: null,
      userId: localStorage.getItem("userId"),
      roleId: localStorage.getItem("roleId"),
      holiday: {
        start: null,
        end: null,
        startTime: "08:00",
        endTime: "23:55",
        title: ""
      },
      notValid: [],
      message: "",
    };
  },
  props: ["branch"],
  created: async function () {
    if (this.isRoleValid(this.roleId)) {
      await this.getBranches();
      this.selectedBranch = this.branch;
      this.getDoctors();
    } else {
      this.selectedDoctor = { id: this.userId };
    }
  },
  methods: {
    getBranches: async function () {

      return HTTP.get('/branches/').then(result => {
        this.branches = result.data;
      });
      // }else{
      //   return HTTP.get("/users/profile/" + this.userId).then(result => {
      //   let user = result.data[0];
      //   this.branches = user.branches;
      // });
      // }
    },
    getDoctors: function () {
      var branch = this.branches.find(z => z.id == this.selectedBranch.id);
      this.doctors = branch.branchUsers.filter(z => z.roleId == 2);

      this.doctors.unshift({ id: null, name: "Genel" });
    },
    cancel: function () {
      this.$emit("close", true);
    },
    checkForm: function () {
      if (!this.holiday.start) {
        this.notValid.push("start");
      } else {
        this.notValid = this.notValid.filter(item => item != "start");
      }
      if (!this.holiday.end) {
        this.notValid.push("end");
      } else {
        this.notValid = this.notValid.filter(item => item != "end");
      }
      if (!this.holiday.startTime) {
        this.notValid.push("startTime");
      } else {
        this.notValid = this.notValid.filter(item => item != "starTime");
      }
      if (!this.holiday.endTime) {
        this.notValid.push("endTime");
      } else {
        this.notValid = this.notValid.filter(item => item != "endTime");
      }
      if (!this.holiday.title) {
        this.notValid.push("title");
      } else {
        this.notValid = this.notValid.filter(item => item != "title");
      }
    },
    addHoliday: function () {
      this.checkForm();
      if (this.notValid.length == 0) {
        this.holiday.start = this.dateFormatter(this.holiday.start);
        this.holiday.end = this.dateFormatter(this.holiday.end);
        this.holiday.branchId = this.selectedBranch ? this.selectedBranch.id : null;
        this.holiday.doctorId = this.selectedDoctor ? this.selectedDoctor.id : null;
        HTTP.post("/holidays", this.holiday)
          .then(res => {
            if (res.status == 201) {
              this.message = "Gün/Günler Başarıyla Kapatıldı";
            }
            this.$emit("refresh", true);
          })
          .catch(e => {
            this.message =
              "Bir şeyler ters gitti, lütfen girdiğiniz verileri kontrol ederek tekrar deneyiniz veya sistem yöneticinize başvurunuz.";
            console.log(e);
            console.log(e.message);
          });
      }
    },
    dateFormatter: function (val) {
      console.log("val", val);
      return new Date(val).format("YYYY-MM-DD");
      let splitted = val.split("-");
      return splitted[2] + "-" + splitted[1] + "-" + splitted[0];
    },
    isRoleValid(roleId) {
      return [1, 4].includes(parseInt(roleId));
    }
  },
  watch: {
    selectedBranch: function () {
      this.getDoctors();
    }
  }
};
</script>

<style lang="scss" scoped>
.md-layout-item {
  padding: 0px 10px !important;
}

.title-2 {
  font-size: 17px !important;
  color: #1a1a1a !important;
}

.calendar-nav {
  margin-left: 15px !important;
}
</style>
