<template>
  <div>
    <md-table v-model="paginatedData" md-card>
      <md-table-toolbar>
        <h1 class="md-title">Kapalı Günler</h1>
      </md-table-toolbar>

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Başlık/Açıklama">{{ item.title }}</md-table-cell>
        <md-table-cell md-label="Başlangıç">{{ item.start }} : {{ item.startTime }}</md-table-cell>
        <md-table-cell md-label="Bitiş">{{ item.end }} : {{ item.endTime }}</md-table-cell>
        <md-table-cell md-label="Kapatan Kullanıcı">{{ getUserName(item.userId) }}</md-table-cell>
        <md-table-cell md-label="Doktor">{{ getUserName(item.doctorId) }}</md-table-cell>
        <md-table-cell md-label="Düzenle / Sil">
          <div class="">
            <md-button v-if="true" class="md-primary" v-on:click="updateHoliday(item.id)">
              <md-icon>edit</md-icon>
            </md-button>
          </div>
          <div class="">
            <md-button v-if="true" class="md-accent" v-on:click="deleteHoliday(item.id)">
              <md-icon>delete</md-icon>
            </md-button>
          </div>

        </md-table-cell>
      </md-table-row>
    </md-table>
    <MazPagination v-model="paginationCurrentPage" v-if="paginationPageCount > 1" :page-count="paginationPageCount"
      :dark="false" @page="updatePagination" />
  </div>
</template>

<script>
import moment from "moment";
import { MazPagination } from "maz-ui";
import { HTTP } from "../../main-source";
export default {

  data: () => ({
    paginationPageCount: null,
    paginationCurrentPage: 1,
    paginatedData: [],
    rowsPerPage: 5,
  }),
  components: {
    MazPagination,
  },
  props: ["holidays", "users"],
  created() {
    //console.log(new Date(moment().format("YYYY-MM-DD")).getTime() > new Date(this.holidays[0].end).getTime())
    let now = new Date(moment().format("YYYY-MM-DD")).getTime()
    //this.holidays = this.holidays.filter(e => new Date(e.end).getTime() > now )
    this.paginationPageCount = Math.round(this.holidays.length / this.rowsPerPage)
    this.updatePagination()
  },
  methods: {
    updateHoliday: function (holidayId) {
      this.$emit('updateHoliday', holidayId)
    },
    deleteHoliday: function (holidayId) {
      HTTP.delete("holidays/" + holidayId).then((result) => {
        this.holidays = this.holidays.filter(z => z.id != holidayId)
        //this.paginatedData = this.paginatedData.filter(z => z.id != holidayId)
        this.updatePagination()
      }).catch((err) => {

      });
    },
    getUserName(userId) {
      if (userId == null) {
        return ""
      } else {
        return this.users.filter(z => z.id == userId)[0].name
      }
    },
    updatePagination() {
      this.paginatedData = this.paginationCurrentPage != 1 ? this.holidays.slice(this.paginationCurrentPage * this.rowsPerPage, (this.paginationCurrentPage + 1) * this.rowsPerPage,) : this.holidays.slice(0, 5)
    }
  },
}
</script>

<style lang="scss" scoped>
.md-table+.md-table {
  margin-top: 16px
}

.avatar img {
  max-width: 30px;
}
</style>