<template>
  <div>

      <div class="md-layout details">
        <div ref="pdfContent">

        <Epic
          v-bind:epicrisis="epicrisis"
          :patient="patient"
          :template="template"
        >
        </Epic>
        </div>

        <div class="md-layout-item wrapper" >
          <span class="md-title">Bilgiler</span>
          <div class="md-layout md-gutter">
            <div v-if="epicrisis.title" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>Title</label>
                <md-input v-model="epicrisis.title.content"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.name" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.name.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.name" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Ad Soyad</label>
                <md-input v-model="patient.name"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.age" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.age.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.age" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Yaş</label>
                <md-input v-model="epicrisis.age.content"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.height" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.height.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.height" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Boy</label>
                <md-input type="number" v-model="epicrisis.height.content"></md-input>
              </md-field>
            </div>

            <div  v-if="epicrisis.weight" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.weight.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.weight" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Kilo</label>
                <md-input type="number" v-model="epicrisis.weight.content"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.bmi" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.bmi.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.bmi" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Bmi</label>
                <md-input type="number" v-model="epicrisis.bmi.content"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.complaint" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.complaint.label"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.complaint" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Complaint</label>
                <md-input v-model="epicrisis.complaint.content"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.admission" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.admission.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.admission" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Date of Admission</label>
                <md-input type="date" v-model="epicrisis.admission.content"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.discharge" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.discharge.label"></md-input>
              </md-field>
            </div>
            <div  v-if="epicrisis.discharge" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Date of Discharge</label>
                <md-input type="date" v-model="epicrisis.discharge.content"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.operationDate" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.operationDate.label"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.operationDate" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Operation Date</label>
                <md-input
                  type="date"
                  :value="epicrisis.operationDate.content"
                  v-model="epicrisis.operationDate.content"
                ></md-input>
              </md-field>
            </div>

            <div v-if="epicrisis.history" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.history.label"></md-input>
              </md-field>
              <md-field>
                <label>Clinical History</label>
                <md-textarea v-model="epicrisis.history.content"></md-textarea>
              </md-field>
            </div>

            <div v-if="epicrisis.examination" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.examination.label"></md-input>
              </md-field>
              <md-field>
                <label>Physical examination</label>
                <md-textarea v-model="epicrisis.examination.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.pulse" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.pulse.label"></md-input>
              </md-field>
              <md-field>
                <label>Pulse</label>
                <md-input type="number" v-model="epicrisis.pulse.content"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.spo2" class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.spo2.label"></md-input>
              </md-field>
              <md-field>
                <label>SpO2</label>
                <md-input type="number" v-model="epicrisis.spo2.content"></md-input>
              </md-field>
            </div>
            <div v-if="epicrisis.background" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.background.label"></md-input>
              </md-field>
              <md-field>
                <label>Backround</label>
                <md-textarea v-model="epicrisis.background.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.laboratory" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.laboratory.label"></md-input>
              </md-field>
              <md-field>
                <label>Laboratory tests</label>
                <md-textarea v-model="epicrisis.laboratory.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.abdominal" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.abdominal.label"></md-input>
              </md-field>
              <md-field>
                <label>Abdominal Ultrasonography</label>
                <md-textarea v-model="epicrisis.abdominal.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.course" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.course.label"></md-input>
              </md-field>
              <md-field>
                <label>Clinical Course</label>
                <md-textarea v-model="epicrisis.course.content"></md-textarea>
              </md-field>
            </div>
            <hr class="half-hr" />
            <div v-if="epicrisis.postop" class="md-layout-item md-size-100 md-xsmall-size-100 md-layout">
              <h4 class="md-layout-item md-size-100 md-xsmall-size-100">Postop Days</h4>
              <md-button
              class="md-raised md-primary  md-layout-item md-size-50 md-xsmall-size-100"
              @click="addPostopDay"
            >
              Gün Ekle
              <md-icon>add</md-icon>
            </md-button>
            <md-button
              class="md-raised md-danger  md-layout-item md-size-50 md-xsmall-size-100"
              @click="deletePostopDay"
            >
              Gün Sil
              <md-icon>delete</md-icon>
            </md-button>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100 md-layout" v-for="(postop,index) in epicrisis.postop" :key="index">
             <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.postop[index].label"></md-input>
              </md-field>
            </div>
              <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.postop[index].label }}</label>
                <md-textarea v-model="epicrisis.postop[index].content.desc"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.postop[index].content.crp.label"></md-input>
              </md-field>
              <md-field>
                <label>CRP</label>
                <md-input type="number" v-model="epicrisis.postop[index].content.crp.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.postop[index].content.hgb.label"></md-input>
              </md-field>
              <md-field>
                <label>HGB</label>
                <md-input type="number" v-model="epicrisis.postop[index].content.hgb.content"></md-input>
              </md-field>
            </div>
            </div>
            <hr class="half-hr" />
            <div v-if="epicrisis.surgeryNote" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.surgicalNote.label"></md-input>
              </md-field>
              <md-field>
                <label>Surgical Notes</label>
                <md-textarea v-model="epicrisis.surgicalNote.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.condDischarge" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.condDischarge.label"></md-input>
              </md-field>
              <md-field>
                <label>Condition on Discharge</label>
                <md-textarea v-model="epicrisis.condDischarge.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.diagnosis" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.diagnosis.label"></md-input>
              </md-field>
              <md-field>
                <label>Final Diagnosis</label>
                <md-textarea v-model="epicrisis.diagnosis.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.surgeryNote" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.surgeryNote.label"></md-input>
              </md-field>
              <md-field>
                <label>Surgery</label>
                <md-textarea v-model="epicrisis.surgeryNote.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.medicalPrescibed" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.medicalPrescibed.label"></md-input>
              </md-field>
              <md-field>
                <label>Medications Prescribed </label>
                <md-textarea v-model="epicrisis.medicalPrescibed.content"></md-textarea>
              </md-field>
            </div>
            <div v-if="epicrisis.recommendations" class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.recommendations.label"></md-input>
              </md-field>
              <md-field>
                <label>Recommendations </label>
                <md-textarea v-model="epicrisis.recommendations.content"></md-textarea>
              </md-field>
            </div>
          </div>
          <div class="btn-wrap m-3">
            <md-button
              class="md-raised md-primary btn-right"
              v-on:click="saveEpicrisis()"
            >
              Kaydet
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
          <div class="btn-wrap m-3">
            <md-button
              class="md-raised md-secondary btn-right"
              @click="exportToPDF"
            >
              PDF indir

              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import moment from "moment";
import Epic from "@/components/epicrisisComponent";

import html2pdf from "html2pdf.js";

export default {
  name: "Epikriz",

  data() {
    return {
      userId: localStorage.getItem("userId"),
      patient: {name : "Patient Name"},
      epicrisis: {},
      epicrisisId: this.$route.params.id,
      roleId: localStorage.getItem("roleId"),
      template:null
    };
  },
  components: {
    Epic,
  },
  created() {
    HTTP.get("epicrisisTemplate/byId/1").then(result => {
            this.template = result.data[0]
          });
    HTTP.get("/epicrisisContent/byId/" + this.$route.params.id).then(
      (result) => { 
        this.epicrisis = JSON.parse(result.data[0].content);
        console.log(result,"asd")
      }
    );
  },
  methods: {
    addPostopDay : function(){
      this.epicrisis.postop.push(
        {
            "label": "Post-operative day " + (this.epicrisis.postop.length + 1),
            "content": {
                "desc": "",
                "crp": {
                    "label": "CRP",
                    "content": 0
                },
                "hgb":{
                    "label": "HGB",
                    "content": 0
                }
            }
        },
      )
    },
    deletePostopDay : function(){
      this.epicrisis.postop = this.epicrisis.postop.slice(0,(this.epicrisis.postop.length-1))
      console.log(this.epicrisis.postop.slice(4,6))
    },
    saveEpicrisis: function () {
      let params = {
        id: this.$route.params.id,
        name: this.epicrisis.title.content,
        content: JSON.stringify(this.epicrisis),
        slug: this.epicrisis.title.content.replace(" " , "-"),
        lastUpdateUserId: this.userId,
      };
      
      HTTP.post("/epicrisisContent/update/", params)
        .then((res) => {
          this.$swal({
            icon: "success",
            text: "İşlem Başarıyla tamamlandı.",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          this.message = "Bir şeyler ters gitti";
          console.log(e);
          console.log(e.message);
        });
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },

    exportToPDF() {
      html2pdf(this.$refs.pdfContent, {
        margin: 0,
        filename: this.patient.name+" "+this.epicrisis.title+".pdf",
        pdfContentWidth: 800,
        image: { type: "png", quality: 1.98 },
        html2canvas: { dpi: 300, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },


    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    dateString: function (date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear();
      return startText;
    },
    deleteSurgery: function (id) {
      HTTP.delete("surgeries/?ids=" + id).then(() => {
        this.getSurgeries();
      });
    },
  },
  computed: {},

  watch: {
    "epicrisis.height.content": function () {
       let calculate =
        this.epicrisis.weight.content /
        (this.epicrisis.height.content * 0.01 * (this.epicrisis.height.content * 0.01));
      let bmi = calculate.toFixed(2);
      this.epicrisis.bmi.content = bmi;
    },
    "epicrisis.weight.content": function () {
      let calculate =
        this.epicrisis.weight.content /
        (this.epicrisis.height.content * 0.01 * (this.epicrisis.height.content * 0.01));
      let bmi = calculate.toFixed(2);
      this.epicrisis.bmi.content = bmi;
    },
  },
};
</script>

<style lang="scss" scope>
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.editOkButton {
  width: 100%;
  margin: 0 !important;
}
.vs--disabled * {
  background: transparent !important;
}
.medicalhistory {
  height: auto;
  max-height: auto;
}
.surgery {
  margin: 10px 0px;
  padding: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  .head {
    width: 100%;
    display: block;
  }
}
.profile-img {
  overflow: visible !important;
}
.profile {
  .add {
    position: absolute;
    left: calc(50% - 85px);
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .add {
      opacity: 1;
    }
  }
}
.file.profile-img {
  label {
    top: -100px !important;
    height: 200% !important;
  }
}
.md-tabs {
  width: 100%;
}
.maz-input.is-disabled .maz-input__label,
.maz-input.is-disabled .maz-input__input,
.maz-input.is-disabled .maz-input__toggle__arrow {
  cursor: auto;
  color: #000;
}
.maz-input.is-disabled.is-valid {
  border: 0;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  /*padding: 1px;*/
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 1px; /*AA*/
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
.header {
  background-color: #6cd8d2;
  font-family: Monstserrat-Italic;
  font-size: 35px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  max-height: 70px;
}
.card1 {
  border-style: solid;
  border-width: 2px;
  border-color: #6cd8d2;
}
</style>
