<template>
  <div>
    <div class="md-title">{{ title }}</div>
    <div class="md-layout">
      <div
        class="md-layout-item item profile-wrap md-size-30 md-xsmall-size-100"
        :class="{ 'hidden-xs': phoneUnique || !patient.phone }"
      >
        <div
          :style="{
            'border-radius': '20px',
            padding: '30px',
            border: patient.isShared
              ? '3px solid blue'
              : !phoneUnique
              ? '3px solid #FF4A4A'
              : '3px solid #82CD47',
            'box-shadow': '0px 0px 20px rgba(0,0,0,0.15)',
          }"
          class="md-layout-item profile wrapper"
        >
          <img src="../../../public/images/avatar.jpg" alt="Avatar" />
          <div class="main">
            <span class="title">{{ patient.name }}</span>
            {{ countryName(patient) }}
          </div>
          <div class="secondary">
            <div class="md-layout" v-if="patient.phone">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">phone</i>
              </div>
              <div class="md-layout-item">{{ patient.phone }}</div>
            </div>
            <div
              class="md-layout"
              v-if="!phoneUnique && patient.phone && patient.phone.length != 0"
            >
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">date_range</i>
              </div>
              <div
                v-if="duplicatedPatients[0].directionHistory"
                class="md-layout-item"
              >
                <div>
                  {{ patientFirstCreateDate(duplicatedPatients) }}
                  <small>(İlk Kayıt Tarihi)</small>
                </div>
              </div>
              <div v-else class="md-layout-item">
                {{ patient.createdAt ? formatDate(patient.createdAt) : "-" }}
                <small>(İlk Kayıt Tarihi)</small>
              </div>
            </div>
            <div class="md-layout" v-if="patient.email">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">mail</i>
              </div>
              <div class="md-layout-item">{{ patient.email }}</div>
            </div>
            <div
              class="md-layout"
              v-if="!phoneUnique && patient.phone && patient.phone.length != 0"
            >
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">account_circle</i>
              </div>
              <div v-if="duplicatedPatients" class="md-layout-item">
                {{ getUserName(duplicatedPatients[0].userId) }}
                <small>(İlk Kayıt Sahibi)</small>
              </div>
              <div v-else class="md-layout-item">
                {{ patient.userId ? patient.userId.name : "-" }}
                <small>(İlk Kayıt Sahibi)</small>
              </div>
            </div>

            <div class="md-layout story" v-if="duplicatedPatients.length > 0">
              <span class="md-title">Hasta Geçmişi</span>
              <ul class="md-layout-item">
                <li v-for="(pat, index) in duplicatedPatients" :key="index">
                  <ul class="md-layout-item" v-if="pat.directionHistory">
                    <div
                      v-for="(item, index2) in JSON.parse(pat.directionHistory)"
                      :key="index2"
                    >
                      <span class="md-title"
                        ><span
                          :class="item.isShared ? 'blue-text' : 'red-text'"
                          >{{ pat.type == 1 ? "Gerçek" : "Aday" }}</span
                        >
                        <b> {{ getUserName(pat.userId) }}</b> -
                        <b>{{ formatDate(pat.createdAt) }}</b></span
                      >
                      <li v-html="renderPatientHistory(item)"></li>
                    </div>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="md-layout story" v-if="patient.historySharing">
              <span class="md-title">Paylaşım Hareketleri</span>
              <ul class="md-layout-item">
                <li
                  v-for="(item, index) in patient.historySharing"
                  :key="index"
                >
                  <span v-if="item.sharedBy != 0"
                    ><b>{{ getUserName(item.sharedBy) }}</b> tarafından </span
                  ><b>{{ formatDate(item.sharedAt) }}</b> tarihinde
                  <b>{{ getUserName(item.sharedTo) }}</b> adlı kullanıcıya
                  paylaşılmıştır.
                </li>
              </ul>
            </div>
          </div>
          <div
            class="md-layout"
            v-if="
              !phoneUnique &&
                patient.phone &&
                patient.phone.length != 0 &&
                ((patient.userId && patient.userId.id == userId) || roleId == 1)
            "
          >
            <md-button
              class="md-layout-item md-raised md-primary"
              :to="{ name: 'potantial.patients', params: { id: patient.id } }"
              >Hastaya git</md-button
            >
          </div>
        </div>
      </div>
      <!-- ilk sutun -->
      <div
        class="md-layout-item item profile-wrap md-xsmall-size-100 patients-details"
      >
        <div class="wrapper md-layout">
          <div class="md-layout-item md-size-40 md-xsmall-size-100">
            <span class="md-title">Yeni Hasta Adayı Oluştur</span>
          </div>

          <div
            class="md-layout-item md-size-60 text-right head-buttons hidden-xs"
          >
            <md-button
              v-on:click="addPatient(true)"
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              >Kaydet ve Yeni</md-button
            >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              v-on:click="addPatient"
              >Kaydet</md-button
            >
            <md-button class="md-raised md-default" v-on:click="cancel"
              >İptal</md-button
            >
          </div>
        </div>
        <div class="wrapper">
          <span class="md-title">Bilgiler</span>
          <div class="md-layout md-gutter">
            <!-- Left Area -->
            <div class="md-layout-item md-size-100">
              <md-switch v-model="fast" v-if="false">Hızlı Kayıt</md-switch>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-100">
                  <MazPhoneNumberInput
                    v-model="patient.phone"
                    :translations="{
                      countrySelectorLabel: 'Ülke',
                      countrySelectorError: 'Hata',
                      phoneNumberLabel: 'Telefon Numarası',
                      example: 'Örnek :',
                    }"
                    type="tel"
                    default-country-code="TR"
                    :preferred-countries="['TR', 'DE', 'GB', 'NL', 'BG', 'AU']"
                    :showCodeOnList="true"
                    @update="telInput"
                    clearable
                    startFocus="true"
                  />
                  <span class="md-error">
                    {{
                      !$v.patient.phone.required && triedToSubmit
                        ? "Bu alanı boş bırakmamalısınız!"
                        : ""
                    }}
                    <span style="color:orange !important;">{{
                      !phoneUnique && patient.phone
                        ? "Telefon daha önce alınmış."
                        : ""
                    }}</span>
                  </span>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field
                    :class="{
                      'md-invalid':
                        !$v.patient.userId.required && triedToSubmit,
                    }"
                  >
                    <label v-if="roleId != 1">
                      <small>{{ userName }} (Hasta Sahibi)</small>
                    </label>
                    <v-select
                      v-model="patient.userId"
                      placeholder="Hasta Sahibi"
                      :options="patientOwners"
                      label="name"
                      class="md-menu"
                      v-if="roleId == 1"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error" v-if="!$v.patient.userId.required"
                      >Bu alanı boş bırakmamalısınız!</span
                    >
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field
                    :class="{
                      'md-invalid':
                        !$v.patient.innerFirmId.required && triedToSubmit,
                    }"
                  >
                    <label v-if="roleId != 1">
                      <small>Dr. HE</small>
                    </label>
                    <v-select
                      v-model="patient.innerFirmId"
                      placeholder="Firma"
                      :options="firms"
                      label="name"
                      class="md-menu"
                      v-if="roleId == 1"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-90">
                  <md-field>
                    <label>Ad Soyad</label>
                    <md-input v-model="patient.name" ref="name"></md-input>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
                <div class="noNameBtn">
                  <md-button
                    class="md-icon-button md-dense md-primary"
                    @click="patient.name = 'İsimsiz'"
                  >
                    <md-icon>no_accounts</md-icon>
                    <md-tooltip md-direction="top">İsimsiz</md-tooltip>
                  </md-button>
                </div>
              </div>
              <!-- Agencie -->
              <div class="md-layout md-gutter">
                <!-- Parent Agency -->
                <div
                  @click="getAgencies"
                  class="md-layout-item md-size-50 md-xsmall-size-100"
                >
                  <md-field
                    :class="{
                      'md-invalid':
                        !$v.patient.agencieId.required && triedToSubmit,
                    }"
                  >
                    <v-select
                      v-model="patient.agencieId"
                      :placeholder="placeholders.agencie"
                      :options="agencies"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
                <!-- Child Agency -->
                <div
                  v-if="selectingAgenciesChild"
                  class="md-layout-item md-size-50 md-xsmall-size-100"
                >
                  <md-field
                    :class="{
                      'md-invalid':
                        !$v.patient.childAgencieId.required && triedToSubmit,
                    }"
                  >
                    <v-select
                      v-model="patient.childAgencieId"
                      placeholder="Child Agencie"
                      :options="childAgencies"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
              </div>
              <!-- Agencie End -->
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field
                    :class="{
                      'md-invalid':
                        !$v.patient.languageId.required && triedToSubmit,
                    }"
                  >
                    <v-select
                      v-model="patient.languageId"
                      placeholder="Dil"
                      :options="languages"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field>
                    <v-select
                      v-model="patient.type"
                      placeholder="Durum"
                      :options="patientStatus"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                </div>
              </div>
              <!-- <div class="md-layout-item md-size-100 md-xsmall-size-100">
                <md-field>
                  <v-select
                    v-model="patient.treatmentId"
                    placeholder="Tedavi"
                    :options="operations"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div> -->
              <!-- Bottom -->
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field>
                    <v-select
                      v-model="patient.treatmentId"
                      placeholder="Tedavi"
                      :options="operations"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field>
                    <v-select
                      v-model="patient.source"
                      placeholder="İletişim"
                      :options="sources"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                    <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                  </md-field>
                </div>
              </div>

              <md-field v-if="!fast">
                <label>Cinsiyet</label>
                <md-select v-model="patient.sex">
                  <md-option value="false">Kadın</md-option>
                  <md-option value="true">Erkek</md-option>
                </md-select>
              </md-field>

              <!-- <md-field   v-if="!fast"  >
                <v-select
                  v-model="patient.country"
                  placeholder="Ülke"
                  :options="$store.state.countrys"
                  label="Ülke"
                  class="md-menu"
                ></v-select>
              </md-field> -->

              <md-datepicker
                v-if="!fast"
                v-model="patient.birthday"
                md-immediately
              >
                <label>Doğum Tarihi</label>
              </md-datepicker>
              <md-field
                v-if="!fast"
                :class="{ 'md-invalid': !$v.patient.email.email }"
              >
                <label>Email</label>
                <md-input v-model="patient.email"></md-input>
                <span class="md-error">E-mail formatı yanlış.</span>
              </md-field>
              <md-field v-if="!fast">
                <label>Şehir</label>
                <md-input v-model="patient.province"></md-input>
              </md-field>

              <md-field v-if="!fast">
                <label>Adres</label>
                <md-input v-model="patient.adress"></md-input>
              </md-field>
            </div>
            <!-- Left Area End -->

            <!-- Right Area-->
            <div class="md-layout-item md-size-50">
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field v-if="!fast">
                    <v-select
                      v-model="patient.currencySlug"
                      placeholder="Döviz"
                      :options="currencies"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field v-if="!fast">
                    <label>Tahmini Ücret</label>
                    <md-input v-model="patient.estimated_amount"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field v-if="!fast">
                    <v-select
                      v-model="patient.when_is_comes"
                      placeholder="Ne Zaman?"
                      :options="whens"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-field v-if="!fast">
                    <v-select
                      v-model="patient.possibility"
                      placeholder="Olasılık"
                      :options="possibilities"
                      label="name"
                      class="md-menu"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                </div>
              </div>
            </div>
            <!-- Right Area End -->
          </div>

          <md-field v-if="!fast">
            <label>Hikayesi</label>
            <md-textarea v-model="patient.story"></md-textarea>
          </md-field>
          <md-checkbox v-if="!fast" v-model="patient.subscribe_sms"
            >SMS Onay</md-checkbox
          >
          <md-checkbox v-if="!fast" v-model="patient.subscribe_email"
            >Email Onay</md-checkbox
          >
          <div class="md-layout-item text-right head-buttons">
            <md-button
              v-on:click="addPatient(true)"
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              >Kaydet ve Yeni</md-button
            >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              v-on:click="addPatient"
              >Kaydet</md-button
            >
            <md-button class="md-raised md-default" v-on:click="cancel"
              >İptal</md-button
            >
          </div>
        </div>
      </div>
      <!-- ikinci sutun -->
    </div>
  </div>
</template>

<script>
// import userList from "../../components/user/list";
import router from "../../router";
import { HTTP } from "../../main-source";
import { MazPhoneNumberInput } from "maz-ui";
// import { setTimeout } from "timers";
import { required, email } from "vuelidate/lib/validators";
import moment from "moment";
import { isArray } from "util";

export default {
  name: "CreatePatient",
  data() {
    return {
      phoneUnique: true,
      fast: true,
      triedToSubmit: false,
      loading: false,
      title: "Hasta Kayıt",
      roleId: localStorage.getItem("roleId"),
      userName: localStorage.getItem("name"),
      userId: localStorage.getItem("userId"),
      patient: {
        name: "",
        email: "",
        phone: "",
        countryCode: "",
        province: "",
        adress: "",
        story: "",
        sex: null,
        directionHistory: null,
        historyDirecting: null,
        historySharing: null,
        birthday: null,
        marital_status: null,
        source: { id: 1 },
        agencieId: null,
        childAgencieId: null,
        languageId: null,
        innerFirmId: { id: 3 }, // set DR. HE Firma as Default
        userId:
          localStorage.getItem("roleId") != 1
            ? localStorage.getItem("userId")
            : null,
        possibility: null,
        subscribe_sms: false,
        subscribe_email: false,
        currencySlug: "",
        type: { id: 1 },
      },
      selectingAgenciesChild: false,
      roles: [],
      roleName: "",
      type: "",
      locations: [],
      sources: [],
      currencies: [],
      operations: [],
      languages: [],
      phoneHolder: "",
      users: null,
      agencies: [],
      childAgencies: [],
      firms: [],
      countries: [],
      patientOwners: [],
      patientStatus: [],
      possibilities: [
        {
          id: 0,
          name: "Düşük",
        },
        {
          id: 1,
          name: "Orta",
        },
        {
          id: 2,
          name: "Yüksek",
        },
      ],
      whens: [],
      duplicatedPatients: [],
      innerFirms: [],
      placeholders: {
        agencie: "Kaynak",
      },
    };
  },
  validations: {
    patient: {
      name: {},
      email: {
        email,
      },
      phone: {
        required,
      },
      countryCode: {},
      province: {},
      adress: {},
      story: {},
      sex: {},
      birthday: {},
      marital_status: {},
      source: {
        required,
      },
      innerFirmId: {
        required,
      },
      agencieId: {
        required,
      },
      childAgencieId: {
        required,
      },
      language: {
        required,
      },
      languageId: {
        required,
      },
      userId: {
        required,
      },
      treatmentId: {
        required,
      },
      possibility: {},
      subscribe_sms: {},
      subscribe_email: {},
      currencySlug: {},
      type: {},
      oldPatient: null,
    },
    phoneHolder: {
      required,
    },
  },
  created() {
    if (this.$route.query.phone) {
      this.patient.phone = this.$route.query.phone;
    }

    HTTP.get("/patientstatus/").then((result) => {
      this.patientStatus = result.data;
    });

    HTTP.get("/firms/").then((result) => {
      this.firms = result.data;
      for (let i in result.data) {
        if (result.data[i].id == 3) {
          this.patient.innerFirmId = result.data[i];
        }
      }
    });

    HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
    });

    HTTP.get("/languages/").then((result) => {
      this.languages = result.data;
    });

    HTTP.get("/currency/").then((result) => {
      this.currencies = result.data.map((item) => {
        return { name: item.title, id: item.id, slug: item.slug };
      });
    });

    HTTP.get("/sources/").then((result) => {
      this.sources = result.data;
      for (let i in result.data) {
        if (result.data[i].id == 1) {
          this.patient.source = result.data[i];
        }
      }
    });

    HTTP.get("/patientstatus/").then((result) => {
      this.patientStatus = result.data;
      for (let i in result.data) {
        if (result.data[i].id == 1) {
          this.patient.type = result.data[i];
        }
      }
    });

    HTTP.get("/whens/").then((result) => {
      this.whens = result.data;
    });

    HTTP.get("/countries/").then((result) => {
      this.countries = result.data;
    });

    HTTP.get("/users/").then((result) => {
      this.patientOwners = result.data.filter(
        (user) => user.roleId != 2 && user.status == true
      );
      this.users = result.data;
      this.patient.userId = result.data?.find(
        (x) => x.id == localStorage.getItem("userId")
      );
    });

    this.getAgencies();
  },
  methods: {
    countryName: function(patient) {
      if (!patient.countryCode) return "";
      var country = this.countries?.find((z) => z.code == patient.countryCode);
      return country ? country.name_tr : patient.countryCode;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    getUserName(userId) {
      var user = this.users?.find((z) => z.id == userId);
      return user ? user.name : "";
    },
    renderPatientHistory(item) {
      if (item?.isShared) {
        return `<b>${this.getUserName(
          item.directedBy
        )}</b> tarafından <b>${this.formatDate(
          item.directedAt
        )}</b> tarihinde <b> tarihinde <b>${this.getUserName(
          item.directedTo
        )}</b> adlı kullanıcıya paylaşılmıştır.`;
      } else {
        let text = "";
        if (item.directedBy == item.directedTo)
          text = `<b>${this.formatDate(
            item.directedAt
          )}</b> tarihinde <b>${this.getUserName(
            item.directedBy
          )}</b> tarafından havuza eklenmiştir.`;
        else {
          if (item.directedBy == 0) text = `Kaynaklardan gelen formlardan `;
          text +=
            `<b>${this.formatDate(
              item.directedAt
            )}</b> tarihinde <b>${this.getUserName(item.directedTo)}</b> ` +
            (item.directedBy == 0
              ? `kullanıcıya gelmiştir.`
              : `kullanıcıya yönlendirilmiştir.`);
        }

        return text;
      }
    },
    checkDuplicatedPatient: async function(val) {
      if (val.length > 7) {
        const duplicateDatasRequest = await HTTP.post(
          "/duplicateDatas/patients",
          {
            fieldName: "phone",
            value: val?.replace("+", ""),
          }
        );

        const { data } = duplicateDatasRequest.data;

        this.duplicatedPatients = data
          ? data?.filter((p) => p.phone == val)
          : [];

        if (this.duplicatedPatients.length == 0) {
          if (!this.phoneUnique)
            this.patient = JSON.parse(JSON.stringify(this.oldPatient));

          this.phoneUnique = true;
          return;
        }
        this.duplicatedPatients.sort((a, b) => b.createdAt - a.createdAt);

        if (this.phoneUnique)
          this.oldPatient = JSON.parse(JSON.stringify(this.patient));

        this.phoneUnique = false;

        let patient = this.duplicatedPatients?.find((z) => z.type == 1);
        if (!patient)
          // if no real patient found
          patient = this.duplicatedPatients[
            this.duplicatedPatients?.length - 1
          ];

        this.patient.id = patient.id;
        this.patient.isShared = patient.isShared;
        this.patient.name =
          patient.userId == this.userId || this.roleId == 1
            ? patient.name
            : this.hiddenName(patient.name);
        this.patient.email = patient.email;
        this.patient.countryCode = patient.countryCode;
        this.patient.province = patient.province;
        this.patient.adress = patient.adress;
        this.patient.story = patient.story;
        this.patient.sex = patient.sex;
        this.patient.birthday = patient.birthday;
        this.patient.marital_status = patient.marital_status;
        this.patient.possibility = patient.possibility;
        this.patient.subscribe_sms = patient.subscribe_sms;
        this.patient.subscribe_email = patient.subscribe_email;
        this.patient.currencySlug = patient.currencySlug;
        this.patient.innerFirmId = this.firms?.find(
          (o) => o.id == patient.innerFirmId
        );
        this.patient.agencieId = this.agencies?.find(
          (o) => o.id == patient.agencieId
        );

        this.patient.languageId = this.languages?.find(
          (o) => o.id == patient.languageId
        );

        this.patient.language = this.languages?.find(
          (o) => o.id == patient.languageId
        );

        this.patient.treatmentId = this.operations?.find(
          (o) => o.id == patient.treatmentId
        );

        this.patient.source = patient.sourceModel;
        this.patient.createdAt = patient.createdAt;
        this.patient.directionHistory = patient.directionHistory;
        this.patient.sharingHistory = patient.sharingHistory;
        this.patient.historyDirecting = JSON.parse(
          this.patient.directionHistory
        );
        this.patient.historySharing = JSON.parse(this.patient.sharingHistory);

        if (this.patient.directionHistory) {
          if (
            JSON.parse(this.patient.directionHistory)?.find((z) => z.id != 0)
              .directedBy
          ) {
            this.patient.userId = this.patientOwners?.find(
              (o) =>
                o.id ==
                JSON.parse(this.patient.directionHistory)?.find(
                  (z) => z.id != 0
                ).directedBy
            );
          } else {
            this.patient.userId = this.patientOwners?.find(
              (o) => o.id == patient.userId
            );
          }
        }
      }
    },
    /* eslint-disable */
    addPatient: async function(continueAdd) {
      // var val = this.phoneHolder;
      // this.checkDuplicatedPatient(val);

      this.triedToSubmit = true;
      if (
        !this.patient.source ||
        !this.patient.agencieId ||
        this.$v.invalid ||
        !this.phoneHolder
      ) {
        this.$swal("", "Lütfen gerekli alanları doldurunuz!", "error");
        return false;
      } else if (this.$v.invalid || !this.phoneHolder) {
        this.$swal("", "Lütfen gerekli alanları doldurunuz!", "error");
        return false;
      } else if (!this.phoneUnique) {
        if ([1].includes(parseInt(this.roleId))) {
          this.confirmModal = true;
          const confirmDialog = await this.$swal({
            title: "Eklemek istediğinize emin misiniz?",
            text: "Numara zaten kayıtlı!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff5252",
            confirmButtonText: "Ekle",
            cancelButtonText: "İptal",
          });

          if (confirmDialog.isConfirmed) {
            this.addedExistingPatient(continueAdd);
          }
        } else {
          this.$swal("", "Daha önce kullanıcı kayıtlı!", "error");
        }
        return false;
      }

      if (
        (this.patient.agencieId != null || this.patient.childAgencieId != null) &&
        (this.patient.language != null || this.patient.languageId != null)
      ) {
        if (this.patient.childAgencieId != null) {
          this.patient.agencieId = this.patient.childAgencieId.id;
        }else {
          this.patient.agencieId = this.patient.agencieId.id;
        }
        this.patient.phone = this.phoneHolder;

        /*if (this.patient.email == "") {
          this.isValid.email = false;
        }
        */

        if (this.patient.source) {
          this.patient.source = this.patient.source.id;
        }
        if (this.patient.innerFirmId) {
          this.patient.innerFirmId = this.patient.innerFirmId.id;
        }
        // if (this.patient.treatmentId) {
        //   this.patient.treatmentId = this.patient.treatmentId.id;
        // }
        if (this.patient.possibility) {
          this.patient.possibility = this.patient.possibility.id;
        }

        if (this.patient.type) {
          this.patient.status = this.patient.type.id;
        }
        if (this.patient.languageId) {
          this.patient.languageId = this.patient.languageId.id;
        }

        if (this.patient.userId) {
          this.patient.userId = this.patient.userId?.id;
        }

        // if (this.patient.status) {
        //   this.patient.status = this.patient.status.id;
        // }

        if (this.patient.currencySlug) {
          this.patient.currencySlug = this.patient.currencySlug.slug;
        }

        if (this.patient.when_is_comes) {
          this.patient.when_is_comes = this.patient.when_is_comes.name;
        }

        this.loading = true;
        const addPatientRequest = await HTTP.post("/patients/", this.patient);
        this.loading = false;

        if (addPatientRequest.data.isSuccess) {
          if (continueAdd == true) {
            location.reload();
          } else {
            router.push({ name: "pool" });
          }

          this.$swal({
            icon: "success",
            text: "Yeni Hasta Adayı Oluşturuldu!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal({
            icon: "error",
            text: "Yeni Hasta Adayı Eklenemedi!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    telInput: function(tel) {
      this.phoneHolder = tel.e164;
      this.patient.countryCode = tel.countryCode;
    },
    cancel: function() {
      router.push("/patients");
    },
    hiddenName: function(name) {
      if (!name) return "";
      var names = name.split(" ");
      if (names.length == 0) return "";
      else {
        var hiddenName = names[0] + " ";
        names.splice(1, 1).forEach(function(item) {
          hiddenName += item.substr(0, 1) + "*** ";
        });
        return hiddenName;
      }
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    getAgencies() {
      if (this.agencies.length == 0) {
        this.placeholders.agencie = "Yükleniyor";
      }

      var agenciesLimitation = "";
      if (this.roleId != 1) agenciesLimitation = "?userId=" + this.userId;
      HTTP.get("/agencies/" + agenciesLimitation).then((result) => {
        this.agencies = this.childDontShow(result.data);
        this.placeholders.agencie = "Ana Kaynak";
      });
    },
    patientFirstCreateDate(val) {
      const date = JSON.parse(val[0]?.directionHistory)[0]?.directedAt;
      if (val.length > 0) {
        if (val[0]?.directionHistory) return this.formatDate(date);
      }
      return val;
    },
    async addedExistingPatient(continueAdd) {
      if (
        this.patient.agencieId != null &&
        (this.patient.language != null || this.patient.languageId != null)
      ) {
        this.patient.agencieId = this.patient.agencieId.id;
        this.patient.phone = this.phoneHolder;

        /*if (this.patient.email == "") {
          this.isValid.email = false;
        }
        */

        if (this.patient.source) {
          this.patient.source = this.patient.source.id;
        }
        if (this.patient.innerFirmId) {
          this.patient.innerFirmId = this.patient.innerFirmId.id;
        }
        // if (this.patient.treatmentId) {
        //   this.patient.treatmentId = this.patient.treatmentId.id;
        // }
        if (this.patient.possibility) {
          this.patient.possibility = this.patient.possibility.id;
        }

        if (this.patient.type) {
          this.patient.status = this.patient.type.id;
        }
        if (this.patient.languageId) {
          this.patient.languageId = this.patient.languageId?.id;
        }

        // if (this.patient.status) {
        //   this.patient.status = this.patient.status.id;
        // }

        if (this.patient.userId) {
          this.patient.userId = this.patient.userId.id;
        }

        if (this.patient.currencySlug) {
          this.patient.currencySlug = this.patient.currencySlug.slug;
        }

        if (this.patient.when_is_comes) {
          this.patient.when_is_comes = this.patient.when_is_comes.name;
        }

        this.loading = true;
        const addPatientRequest = await HTTP.post("/patients/", {
          ...this.patient,
          isShared: true,
        });
        this.loading = false;

        if (addPatientRequest.data.isSuccess) {
          if (continueAdd == true) {
            location.reload();
          } else {
            router.push({ name: "pool" });
          }

          this.$swal({
            icon: "success",
            text: "Yeni Hasta Adayı Oluşturuldu!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal({
            icon: "error",
            text: "Yeni Hasta Adayı Eklenemedi!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
  components: {
    MazPhoneNumberInput,
  },
  watch: {
    "patient.userId": function(val) {},
    phoneHolder: async function(val) {
      this.checkDuplicatedPatient(val);
    },
    "patient.agencieId": function(val) {
      if (val?.childAgencies?.length > 0) {
        this.selectingAgenciesChild = true;

        this.childAgencies = val.childAgencies;
        this.patient.childAgencieId = val.childAgencies[0];
      } else {
        this.selectingAgenciesChild = false;
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

.wrap {
  margin: 0px -15px;
}

.item {
  min-height: 200px;
  margin: 15px;

  &:last-child {
    margin-right: 5px;
  }
}

.profile-wrap {
  margin-top: 75px;
}

.wrapper {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.profile {
  text-align: center;

  img {
    width: 170px !important;
    height: 170px !important;
    border-radius: 50%;
    margin-top: -90px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }

  span {
    display: block;
  }

  .title {
    font-weight: bold;
  }

  .md-layout {
    text-align: left;
  }

  .icon {
    padding: 0px 10px;
    margin-right: 10px;
  }

  .main {
    margin: 15px 0px;
  }

  .secondary {
    margin: 30px 0px 15px 0px;

    .md-layout {
      margin-top: 10px;
      color: $dark;
    }
  }
}

.full-btn {
  width: 100% !important;
  margin: 0 !important;
}

.country-selector__input,
.input-tel__input {
  border: none !important;
  padding-bottom: 5px;
}

.vue-phone-number-input {
  margin-bottom: 7px;
}

.v-select {
  width: 100%;

  .vs__dropdown-toggle {
    border: 0;
  }
}

.head-buttons {
  .md-button {
    margin-left: 15px !important;
  }
}

@media (max-width: 767.98px) {
  .profile-wrap {
    &:nth-child(2) {
      margin-top: 0px;
    }
  }
}

.no-pd {
  .vs__selected {
    font-size: 16px !important;
    padding-left: 0 !important;
  }

  .vs__search {
    padding-left: 0 !important;
    font-size: 16px !important;

    &::placeholder {
      color: #757575 !important;
      font-weight: 500;
    }
  }
}

.country-selector__input {
  box-shadow: none !important;
}

.vue-recycle-scroller__item-wrapper {
  margin-top: 10px;
}

.maz-phone-number-input .country-selector {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.5rem;
  flex: 0 0 7.5rem;
  width: 7.5rem;
  min-width: 7.5rem;
  max-width: 7.5rem;
}

.noNameBtn {
  .md-button.md-primary {
    margin-top: 1rem !important;

    .md-icon {
      color: #fff !important;
    }
  }
}

.red-text {
  color: red;
  display: inline-block !important;
}

.blue-text {
  color: blue;
  display: inline-block !important;
}
</style>
