<template>
    <!-- Generator: Adobe Illustrator 27.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <div style="display:flex;margin-right: 10px;">
        <div>

            <svg :fill="color" :style="{ width: size }" viewBox="0 0 25 25">
                <path class="cls-1"
                    d="m11.57,10.7c.07,0,.14,0,.21,0,.09,0,.12-.01.13-.12.03-.47.38-.78.86-.78,2.42,0,4.84,0,7.26,0,.51,0,.86.35.86.86,0,.34,0,.69,0,1.03,0,.16-.03.22-.21.21-2.29,0-4.58,0-6.87,0-.42,0-.41,0-.42.41,0,.18.06.19.21.19,2.27,0,4.54,0,6.82,0,.15,0,.36-.07.45.03.08.08.02.28.03.43,0,.44,0,.43.45.44.12,0,.16-.02.16-.15,0-.87,0-1.73,0-2.6,0-.8-.58-1.39-1.37-1.44-.09,0-.13-.02-.13-.12,0-.22,0-.44,0-.66,0-.12.04-.13.14-.13.51,0,.76-.26.76-.78,0-.95,0-1.91,0-2.86,0-.72-.43-1.15-1.15-1.15-1.67,0-3.35,0-5.02,0-.42,0-.41,0-.42.42,0,.15.05.18.19.18,1.76,0,3.52,0,5.28,0,.36,0,.53.17.53.52,0,.95,0,1.91,0,2.86,0,.17-.05.21-.21.21-1.23,0-2.46,0-3.69,0s-2.46,0-3.69,0c-.17,0-.21-.05-.21-.21,0-.89.02-1.77,0-2.66-.01-.58.14-.76.74-.73.49.02.49,0,.46-.49,0-.08-.02-.11-.1-.11-.19,0-.37,0-.56,0-.7,0-1.13.43-1.13,1.13,0,.95,0,1.9,0,2.85,0,.56.22.8.78.81.08,0,.12.01.12.11,0,.22,0,.45,0,.67,0,.11-.04.12-.13.12-.7.04-1.23.51-1.35,1.2q-.05.29.25.29Zm1.98-2.4c.95,0,1.9,0,2.85,0s1.87,0,2.81,0c.16,0,.2.05.19.2-.01.18-.02.36,0,.54.01.15-.04.17-.18.17-1.69,0-3.37,0-5.06,0-.21,0-.41,0-.62,0-.13,0-.15-.04-.15-.16,0-.2.01-.4,0-.6,0-.13.04-.15.16-.15Z" />
                <path class="cls-1"
                    d="m10.7,17.89c0-1.99-1.62-3.6-3.59-3.59-2.02,0-3.61,1.62-3.6,3.64.01,1.96,1.64,3.55,3.62,3.55,1.96,0,3.58-1.63,3.57-3.6Zm-1.47-2.11c1.04,1.05,1.06,2.59.52,3.47-1.33-1.33-2.67-2.67-4.01-4.01.91-.55,2.45-.5,3.48.54Zm.08,4.14c-.29.31-.29.31-.59.02-.71-.71-1.43-1.43-2.14-2.14-.05-.05-.09-.12-.15-.19-.13.13-.22.25-.33.34-.1.09-.09.14,0,.23.75.74,1.49,1.49,2.24,2.23.04.04.1.08.15.12-.95.56-2.44.51-3.47-.46-.94-.89-1.21-2.46-.57-3.54.28.28.57.56.84.84.11.12.18.13.3,0,.27-.29.27-.28,0-.56-.24-.24-.47-.48-.72-.71-.09-.08-.07-.13,0-.21.31-.35.3-.35.63-.03,1.27,1.27,2.53,2.53,3.8,3.8.1.1.11.16,0,.26Z" />
                <path class="cls-1"
                    d="m21.07,13.99c-.16,0-.19.04-.19.19,0,1.57,0,3.14,0,4.72,0,.15-.03.19-.19.19-2.1,0-4.19,0-6.29,0-.42,0-.41,0-.42.41,0,.15.03.2.19.19,2.17,0,4.34,0,6.51,0,.2,0,.21.06.17.22-.12.58-.59.98-1.18.98-2.2,0-4.39,0-6.59,0-.21,0-.27.05-.3.24-.06.36-.07.36.3.36,1.1,0,2.2,0,3.29,0,1.06,0,2.12,0,3.18,0,1.15,0,1.91-.76,1.91-1.91,0-1.72,0-3.44,0-5.17,0-.41,0-.4-.41-.42Z" />
                <path class="cls-1"
                    d="m13.4,16.4c0-.72,0-1.45,0-2.17,0-.3-.04-.6-.14-.88-.61-1.88-2.66-2.49-4.2-1.75-.8.38-1.32,1.02-1.56,1.88-.02.06-.07.14,0,.17.17.05.34.1.51.09.1,0,.06-.18.12-.27.02-.04.03-.08.05-.12.42-1,1.45-1.58,2.55-1.42,1.09.15,1.89.91,2.04,1.99.08.6.01,1.21.03,1.81,0,.11-.07.08-.12.08-.41,0-.81,0-1.22,0-.47,0-.47,0-.46.48,0,.1.03.12.13.12.41,0,.81,0,1.22,0,.47,0,.47,0,.46.48,0,.1-.03.12-.13.12-.31,0-.61,0-.92,0-.47,0-.46,0-.46.46,0,.12.03.14.15.14.41,0,.82,0,1.24,0,.05,0,.12-.03.12.07-.02.47.05.95-.06,1.42-.29,1.22-1.44,1.99-2.7,1.79-.15-.02-.33-.12-.43-.06-.1.06-.1.26-.16.4-.04.1-.03.15.08.17.15.03.3.06.46.09,1.81.27,3.4-1.1,3.4-2.93,0-.71,0-1.42,0-2.13Z" />
                <path class="cls-1"
                    d="m19.6,16.69c.29,0,.39-.1.39-.39,0-.82,0-1.65,0-2.47,0-.35-.09-.43-.43-.43-1.72,0-3.43,0-5.15,0-.41,0-.41,0-.42.41,0,.15.03.19.19.19,1.68,0,3.36,0,5.04,0,.14,0,.18.03.17.17,0,.58-.01,1.16,0,1.74,0,.15-.03.19-.19.19-1.59,0-3.18,0-4.78,0-.43,0-.43,0-.44.43,0,.14.03.17.17.17.91,0,1.82,0,2.73,0s1.8,0,2.7,0Z" />
                <path class="cls-1"
                    d="m7.63,8.9c.62.24,1.04.67,1.28,1.28.05.13.15.21.3.21.14,0,.22-.09.29-.21.13-.24.26-.48.39-.72.04-.08.1-.14.18-.18.25-.13.49-.27.74-.4.12-.06.19-.15.2-.29,0-.14-.09-.24-.21-.29-.62-.24-1.05-.65-1.29-1.28-.05-.14-.15-.23-.31-.23-.15,0-.24.1-.29.23-.24.61-.65,1.04-1.27,1.27-.14.05-.23.15-.23.31,0,.15.1.24.23.29Zm1.57-1.16c.16.42.44.7.89.84-.22.15-.44.2-.57.35-.13.15-.2.33-.33.55-.15-.44-.42-.73-.84-.88.41-.17.7-.44.85-.87Z" />
                <path class="cls-1"
                    d="m4.41,6.87c.41.16.68.43.83.84,0,0,0,.01,0,.02.15.26.44.28.56,0,.18-.42.46-.71.88-.87.02,0,.04-.03.06-.04.08-.06.12-.14.13-.23,0-.16-.1-.26-.23-.31-.38-.15-.65-.41-.8-.8-.05-.14-.15-.25-.32-.24-.16,0-.25.11-.3.24-.15.38-.41.65-.8.8,0,0-.01,0-.02,0-.13.06-.22.15-.22.3,0,.15.09.25.22.3Zm1.13-.73c.09.22.21.35.44.43-.21.09-.36.21-.43.46-.09-.23-.21-.37-.46-.45.25-.09.37-.22.45-.44Z" />
                <path class="cls-1"
                    d="m16.56,18.19c.99,0,1.98,0,2.98,0,.46,0,.45,0,.45-.45,0-.12-.03-.15-.15-.15-.54,0-1.09,0-1.63,0h-1.39c-.43,0-.43,0-.44.43,0,.14.03.17.17.17Z" />
                <path class="cls-1"
                    d="m13.57,7.11c.11,0,.14-.03.13-.13,0-.36,0-.71,0-1.07,0-.25,0-.5,0-.75,0-.45,0-.45-.45-.45-.12,0-.15.03-.15.15,0,.6,0,1.2,0,1.8,0,.46,0,.46.47.45Z" />
                <path class="cls-1"
                    d="m14.43,4.7c-.12,0-.14.04-.14.15,0,.35,0,.7,0,1.05v.75c0,.46,0,.46.46.46.12,0,.14-.04.14-.15,0-.6,0-1.2,0-1.8,0-.46,0-.46-.46-.46Z" />
                <path class="cls-1"
                    d="m15.62,4.7c-.11,0-.13.03-.13.13,0,.35,0,.7,0,1.05v.77c0,.45,0,.45.45.45.12,0,.15-.03.15-.15,0-.6,0-1.2,0-1.8,0-.46,0-.46-.47-.45Z" />
                <path class="cls-1"
                    d="m16.82,4.7c-.11,0-.14.03-.13.13,0,.36,0,.71,0,1.07v.75c0,.46,0,.45.45.45.12,0,.15-.03.15-.15,0-.6,0-1.2,0-1.8,0-.46,0-.46-.47-.45Z" />
                <path class="cls-1"
                    d="m18.49,5.16c0-.45,0-.45-.45-.45-.12,0-.15.03-.15.15,0,.6,0,1.2,0,1.8,0,.46,0,.46.47.45.11,0,.14-.03.13-.13,0-.36,0-.71,0-1.07v-.75Z" />
                <path class="cls-1"
                    d="m19.22,4.7c-.11,0-.14.03-.13.13,0,.35,0,.7,0,1.05,0,.26,0,.51,0,.77,0,.46,0,.45.45.45.12,0,.15-.03.15-.15,0-.6,0-1.2,0-1.8,0-.46,0-.46-.47-.45Z" />
                <path class="cls-1"
                    d="m15.68,17.59c-.26,0-.51,0-.77,0h-.47c-.46,0-.46,0-.45.47,0,.11.03.13.13.13.4,0,.8,0,1.2,0,.49,0,.49,0,.47-.49,0-.09-.03-.12-.12-.11Z" />
            </svg>
        </div>
        <div
            style="margin-top:6px;display:flex;flex-direction:column;margin-left: 5px;justify-content: center;align-items: flex-start;">
            <label style="font-size:12px;font-weight: 600;"> {{ title }} </label>
            <label style="font-size:11px;margin-top:-15px"> {{ value }} </label>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: ["size", "title", "value", "color"],
};
</script>
