<template>
  <div class="main-container">
    <div style="position:fixed;z-index: 0;height:100vh;top:0">
      <img style="width:100vw;height:100vh;object-fit:cover" src="/images/login-bg.jpg" />
    </div>
    <div class="btn-wrap m-3">
      <md-button @click="exportToPDF" class="md-raised md-secondary btn-right">
        PDF indir
        <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
      </md-button>
    </div>
    <div style="z-index:1; overflow: hidden;border-radius: 15px;" ref="pdfContent">
      <ConfirmationTemplate
        class="confirmation-template"
        :confirmation="content"
        :template="template"
        :avatar="
          user.profileImg ? storagepath + user.profileImg : '/images/avatar.jpg'
        "
      />
    </div>
  </div>
</template>

<script>
import ConfirmationTemplate from "../components/confirmationTemplate/confirmationTemplate.vue";
import { HTTP } from "../main-source";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      content: {},
      template: {},
      user: {},
      storagepath: this.$storage,
    };
  },
  components: {
    ConfirmationTemplate,
  },
  async created() {
    await this.getConfirmationFile();
  },
  methods: {
    getConfirmationFile() {
      HTTP.get("/fileContents/" + this.$route.params.guid).then((response) => {
        console.log(response)
        let content = JSON.parse(response.data.content);
        this.user = response.data.creatorUser;
        content.confirmationId.content = content.confirmationId.content.replace(
          "confirmationId",
          this.$route.params.id
        );
        this.content = content;
        this.template = response.data.template;
      });
    },
    exportToPDF() {
      html2pdf(this.$refs.pdfContent, {
        margin: 0,
        filename: this.content.confirmationId.content + ".pdf",
        pdfContentWidth: 800,
        image: { type: "png", quality: 0.5 },
        html2canvas: { dpi: 300, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-template {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

@media (max-width: 767.98px) {
  .confirmation-template {
    transform: scale(0.4);
    margin-top: -660px;
  }
}
</style>
