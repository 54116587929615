var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPatient)?_c('div',{staticClass:"sub-nav hidden-xs"},[_c('router-link',{attrs:{"to":{
        name: 'patients_details',
        params: { id: _vm.id },
      }}},[_vm._v("Genel Bakış")]),_c('router-link',{attrs:{"to":{
        name: 'patients.information',
        params: { id: _vm.id },
      }}},[_vm._v("Kişisel Bilgiler")]),_c('router-link',{attrs:{"to":{
        name: 'patients.appointments',
        params: { id: _vm.id },
      }}},[_vm._v("Randevular")]),_c('router-link',{attrs:{"to":{
        name: 'patients.activities',
        params: { id: _vm.id },
      }}},[_vm._v("Aktiviteler")]),_c('router-link',{attrs:{"to":{
        name: 'patients.patientpotantial',
        params: { id: _vm.id },
      }}},[_vm._v("Satış Fırsatları")]),_c('router-link',{attrs:{"to":{
        name: 'patients.files',
        params: { id: _vm.id },
      }}},[_vm._v("Dosyalar")]),_c('router-link',{attrs:{"to":{
        name: 'patients.epicrisis',
        params: { id: _vm.id },
      }}},[_vm._v("Epikriz")]),(_vm.roleId == 1)?_c('router-link',{attrs:{"to":{
        name: 'patients.confirmationForms',
        params: { id: _vm.id },
      }}},[_vm._v("Onay Formları")]):_vm._e(),(!_vm.isDietician)?_c('router-link',{attrs:{"to":{
        name: 'patients.offers',
        params: { id: _vm.id },
      }}},[_vm._v("Teklifler")]):_vm._e(),_c('router-link',{attrs:{"to":{
        name: 'patients.mails',
        params: { id: _vm.id },
      }}},[_vm._v("E-Postalar")])],1):_vm._e(),(!_vm.isPatient)?_c('div',{staticClass:"sub-nav hidden-xs"},[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients',
        params: { id: _vm.id },
      }}},[_vm._v("Genel Bakış")]),_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.activities',
        params: { id: _vm.id },
      }}},[_vm._v("Aktiviteler")]),_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.information',
        params: { id: _vm.id },
      }}},[_vm._v("Kişisel Bilgiler")]),_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.files',
        params: { id: _vm.id },
      }}},[_vm._v("Dosyalar")]),(!_vm.isDietician)?_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.offers',
        params: { id: _vm.id },
      }}},[_vm._v("Teklifler")]):_vm._e(),_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.mails',
        params: { id: _vm.id },
      }}},[_vm._v("E-Postalar")])],1):_vm._e(),(_vm.innerWidth < 769)?_c('md-menu',{attrs:{"md-direction":"bottom-start"}},[_c('md-button',{attrs:{"md-menu-trigger":""}},[_c('md-icon',[_vm._v("menu")]),_vm._v(" "+_vm._s(_vm.$route.meta.name)+" ")],1),_c('md-menu-content',[_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients',
        params: { id: _vm.id },
      }}},[_vm._v("Genel Bakış")])],1),(!_vm.isPatient)?_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.activities',
        params: { id: _vm.id },
      }}},[_vm._v("Aktiviteler")])],1):_vm._e(),_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.information',
        params: { id: _vm.id },
      }}},[_vm._v("Kişisel Bilgiler")])],1),(_vm.isPatient)?_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'patients.appointments',
        params: { id: _vm.id },
      }}},[_vm._v("Randevular")])],1):_vm._e(),_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.files',
        params: { id: _vm.id },
      }}},[_vm._v("Dosyalar")])],1),_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.offers',
        params: { id: _vm.id },
      }}},[_vm._v("Teklifler")])],1),_c('md-menu-item',[_c('router-link',{attrs:{"to":{
        name: 'potantial.patients.mails',
        params: { id: _vm.id },
      }}},[_vm._v("E-Postalar")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }