<template>
  <!-- Generator: Adobe Illustrator 27.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <div style="display:flex;margin-right: 10px;">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Icons"
        viewBox="0 0 75 75"
        :fill="color"
        :style="{ width: size }"
      >
        <path
          d="M36.975,41.077c-9.019-.294-11.182-8.016-11.645-10.509a3.915,3.915,0,0,1-2.193-1.393,4.407,4.407,0,0,1-.821-3,4.138,4.138,0,0,1,.976-3.217,3.026,3.026,0,0,1,.729-.55,15.905,15.905,0,0,1-2.37-9.034c1.044-7.46,7.056-8.186,8.939-8.228a8.815,8.815,0,0,1,3.169-2.337,11.168,11.168,0,0,1,7.179-.4c4.662,1.217,7.771,3.551,9.246,6.938,2.1,4.814.258,10.355-.8,12.843a3.063,3.063,0,0,1,1.092.627,4.011,4.011,0,0,1,1.116,3.361,4.4,4.4,0,0,1-.82,3,3.921,3.921,0,0,1-2.194,1.393C48.115,33.061,45.957,40.783,36.975,41.077ZM25.692,24.007a1.353,1.353,0,0,0-.966.344,2.383,2.383,0,0,0-.409,1.789,1.027,1.027,0,0,1-.007.2,2.469,2.469,0,0,0,.419,1.625,2.283,2.283,0,0,0,1.6.758,1,1,0,0,1,.879.908c.033.372.92,9.157,9.769,9.447,8.8-.29,9.691-9.075,9.724-9.449a1,1,0,0,1,.879-.906,2.281,2.281,0,0,0,1.6-.758,2.474,2.474,0,0,0,.419-1.633,1.047,1.047,0,0,1-.007-.194,2.383,2.383,0,0,0-.451-1.836,1.517,1.517,0,0,0-1.086-.284A1,1,0,0,1,47,22.559c.037-.07,3.7-7.022,1.346-12.425-1.219-2.792-3.88-4.741-7.911-5.793a9.159,9.159,0,0,0-5.873.3,6.725,6.725,0,0,0-2.694,2.108.991.991,0,0,1-.887.4c-.259-.021-6.382-.417-7.342,6.428h0s-.277,5.445,2.877,8.745a1,1,0,0,1,.19,1.1.947.947,0,0,1-.947.591Z"
        />
        <path
          d="M47.881,24.031a1,1,0,0,1-1-.958c-.012-.259-.322-6.3-4.832-6.881a15.226,15.226,0,0,1-11.613-3.364c-1.275.959-4.213,3.874-3.648,10.091A1,1,0,0,1,24.8,23.1c-.829-9.121,5.028-12.287,5.277-12.417a1,1,0,0,1,1.154.164A13.355,13.355,0,0,0,41.857,14.2a1.118,1.118,0,0,1,.277-.01c5.084.529,6.637,5.991,6.747,8.8a1,1,0,0,1-.958,1.038Z"
        />
        <path
          d="M65.089,72H8.911a1,1,0,0,1-1-1V59.852a13.308,13.308,0,0,1,13.3-13.281h1.92a1,1,0,1,1,0,2h-1.92a11.306,11.306,0,0,0-11.3,11.281V70H64.089V59.852A11.294,11.294,0,0,0,52.808,48.571H50.75a1,1,0,0,1,0-2h2.058A13.3,13.3,0,0,1,66.089,59.852V71A1,1,0,0,1,65.089,72Z"
        />
        <path
          d="M40.342,72a1,1,0,0,1-.989-1.149L43.4,44.068a1,1,0,0,1,1.277-.808l6.984,2.105a1,1,0,0,1,.608,1.4l-2.776,5.57,1.61,1.807a1,1,0,0,1,.1,1.191L41.192,71.526A1,1,0,0,1,40.342,72Zm4.864-26.492L42.061,66.321l7.053-11.4-1.563-1.755a1,1,0,0,1-.149-1.111l2.548-5.114Z"
        />
        <path
          d="M33.543,72a1,1,0,0,1-.851-.474L22.678,55.336a1,1,0,0,1,.1-1.191l1.61-1.807-2.776-5.57a1,1,0,0,1,.608-1.4l6.984-2.105a1,1,0,0,1,1.276.808l4.049,26.783A1,1,0,0,1,33.543,72ZM24.771,54.918l7.053,11.4L28.679,45.508l-4.744,1.43,2.547,5.114a1,1,0,0,1-.148,1.111Z"
        />
        <path
          d="M37.017,53.355a54.215,54.215,0,0,1-6.469-.392,1,1,0,1,1,.238-1.985,50.417,50.417,0,0,0,12.307,0,1,1,0,0,1,.25,1.984A50.5,50.5,0,0,1,37.017,53.355Z"
        />
        <path
          d="M44.391,45.217a1,1,0,0,1-.8-.405c-1.486-2.009-1.755-5.991-1.78-6.438a1,1,0,0,1,.94-1.056,1.011,1.011,0,0,1,1.056.94c.061,1.052.431,4.067,1.391,5.365a1,1,0,0,1-.8,1.594Z"
        />
        <path
          d="M29.494,45.217a1,1,0,0,1-.8-1.594c.962-1.3,1.331-4.314,1.392-5.365a1,1,0,1,1,2,.116c-.025.447-.3,4.429-1.78,6.438A1,1,0,0,1,29.494,45.217Z"
        />
        <path
          d="M57.155,57.984a1,1,0,0,1-1-.971c-.187-6.512-2.663-8.561-2.687-8.581a1.007,1.007,0,0,1-.174-1.4.994.994,0,0,1,1.385-.192c.132.1,3.257,2.527,3.476,10.115a1,1,0,0,1-.97,1.028Z"
        />
        <path
          d="M57.16,63.888a3.953,3.953,0,1,1,3.952-3.953A3.957,3.957,0,0,1,57.16,63.888Zm0-5.906a1.953,1.953,0,1,0,1.952,1.953A1.955,1.955,0,0,0,57.16,57.982Z"
        />
        <path
          d="M16.082,57.984a1,1,0,0,1-1-.929c-.545-7.675,3.684-10.128,3.864-10.229a1,1,0,0,1,.983,1.742c-.152.09-3.3,2.033-2.853,8.346a1,1,0,0,1-.927,1.068Z"
        />
        <path
          d="M14.059,65.991a1,1,0,0,1-.8-.394A6.251,6.251,0,0,1,12.2,63.449a6.012,6.012,0,0,1,3.929-7.492,5.6,5.6,0,0,1,4.3.532,6.418,6.418,0,0,1,3.12,6.612,1,1,0,0,1-1.973-.328,4.422,4.422,0,0,0-2.126-4.54,3.619,3.619,0,0,0-2.778-.353,4.014,4.014,0,0,0-2.548,5.036,4.267,4.267,0,0,0,.726,1.47,1,1,0,0,1-.795,1.605Z"
        />
        <path
          d="M22.576,63.939c-.039,0-.079,0-.119-.006L21.33,63.8a1,1,0,0,1,.236-1.987l1.127.134a1,1,0,0,1-.117,1.993Z"
        />
        <path
          d="M14.059,66a1,1,0,0,1-.348-1.938l1.2-.443a1,1,0,0,1,.7,1.875l-1.2.444A1,1,0,0,1,14.059,66Z"
        />
      </svg>
    </div>
    <div
      style="margin-top:6px;display:flex;flex-direction:column;margin-left: 5px;justify-content: center;align-items: flex-start;"
    >
      <label style="font-size:12px;font-weight: 600;"> {{ title }} </label>
      <label style="font-size:11px;margin-top:-15px"> {{ value }} </label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["size", "title", "value", "color"],
};
</script>
