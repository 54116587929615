<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <span style="margin-bottom: 15px;" class="md-title title">Onay Formları</span>



        <md-table v-if="confirmationForms.length > 0" class="table appointments" v-model="confirmationForms">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Görüntüle">
              <md-button @click="directPDFViewer(item.fileContent.guid)"
                class="md-icon-button md-raised md-dense"><md-icon>description</md-icon>
                <!-- <md-tooltip md-direction="top">Görüntüle</md-tooltip> -->
              </md-button>
            </md-table-cell>
            <md-table-cell md-label="Randevu Tarihi Saati">
              {{ item.fileContent.content.treatmentDateTime.content }}
            </md-table-cell>
            <md-table-cell md-label="Lokasyon">
              {{ item.fileContent.content.operationLocation.content }}
            </md-table-cell>
            <md-table-cell md-label="Operasyon">
              {{ item.fileContent.content.treatmentPackage.content }}
            </md-table-cell>
            <md-table-cell md-label="Oluşturulma Tarihi">
              {{ formatDate(item.createdAt) }}
            </md-table-cell>
            <md-table-cell md-label="Oluşturan Kişi">
              {{ item.fileContent.creatorUser.name }}
            </md-table-cell>
            <md-table-cell md-label="Son Güncelleyen Kişi">
              {{ item.fileContent.lastModifierUser.name ? item.fileContent.lastModifierUser.name : "" }}
            </md-table-cell>
            <!-- <md-table-cell md-label="Düzenle">
              <md-button @click="editableClick(item.id)"
                class="md-icon-button md-raised md-dense"><md-icon>edit</md-icon>
              </md-button>
            </md-table-cell> -->
          </md-table-row>
        </md-table>

        <div v-else style="margin-left :15px; " >
          <h6 style="font-weight: 100;" >Hastanın Onay Formu Bulunamadı.</h6>
        </div>
      </div>
    </div>

    <md-dialog :md-active.sync="editFormModal" class="dialog">
      <div style="overflow-y: scroll;">
        <div class="inner">

          <ConfirmationTemplate class="confirmation-template"
            :confirmation="editableConfirmationForms.fileContent.content"
            :template="editableConfirmationForms.fileContent.template" :input="true" v-if="editableConfirmationForms" />

        </div>

      </div>

      <div style="display:flex;justify-content:center;">
        <md-button class="md-raised md-primary btn-right" @click="updateFile">
          Güncelle
          <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
        </md-button>
      </div>

    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import moment from "moment";
import ConfirmationTemplate from "../../../../components/confirmationTemplate/confirmationTemplate.vue";

export default {
  name: "Appointments",
  data() {
    return {
      patientsId: this.$route.params.id,
      patient: {},
      confirmationForms: [],
      editFormModal: false,
      editableConfirmationForms: {},
      storagepath: this.$storage,
      allUsers: [],
    };
  },
  components: {
    Navigation,
    Topbar,
    ConfirmationTemplate
  },
  async created() {
    HTTP.get("/users/").then((result) => {
      this.allUsers = result.data
    });

    HTTP.get("/patients/" + this.$route.params.id).then((result) => {
      this.patient = result.data[0];

    });

    this.getConfirmationForm();


    // :avatar="  profile.profileImg
    //                 ? storagepath + profile.profileImg
    //                 : '/images/avatar.jpg'"
  },
  methods: {
    getConfirmationForm: function () {
      HTTP.get(
        "/patientFiles/patient/" + this.$route.params.id + "?type=10"
      ).then((response) => {
        for (let i in response.data) {
          response.data[i].fileContent.content = JSON.parse(response.data[i].fileContent.content)
          response.data[i].fileContent.content.confirmationId.content = response.data[i].fileContent.content.confirmationId.content.replace('confirmationId', response.data[i].fileContent.id)
          this.confirmationForms.push(response.data[i])
        }
        this.editableConfirmationForms = this.confirmationForms[0]

      });
    },
    formatDate(date) {
      return (
        moment(date).format("DD-MM-YYYY") +
        " - " +
        new Date(date).getHours() +
        ":" +
        new Date(date).getMinutes()
      );
    },
    directPDFViewer(guid) {
      let routeData = this.$router.resolve(
        {
          name: 'confirmationFile',
          params: { guid: guid },
        });
      window.open(routeData.href, '_blank');
    },
    editableClick(id) {
      this.editFormModal = true
      this.editableConfirmationForms = this.confirmationForms.find(x => x.id == id)

    },
    updateFile() {
      HTTP.put("/patientFiles/" + this.editableConfirmationForms.id, this.editableConfirmationForms).then((response) => {
        if (response.status == 200) {
          let findIndex = this.confirmationForms.findIndex(x => x.id == this.editableConfirmationForms.id)
          console.log(findIndex)
          this.confirmationForms[findIndex] = this.editableConfirmationForms
          this.$swal("", "Onay formu başarılı bir şekilde güncellendi.", "success");
          this.editFormModal = false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.participants {
  display: block;

  span {
    display: inline-block;
  }
}

.addButton {
  float: right;
}

.table {
  width: 100%;
  margin-top: 0px;
}

.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}

.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;

  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}

.appointments {
  .md-table-row {
    cursor: pointer;
  }
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.pad {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;

    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}

.edit-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 99;
}

.inner {
  position: relative;
  height: auto;
}
</style>
