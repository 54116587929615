<template>
    <div>
      <div class="md-layout">
        <h1>{{ profile.name }}</h1>
        <md-speed-dial
          class="md-top-right"
          md-direction="bottom"
          md-event="click"
          v-if="roleId == 1"
        >
          <md-speed-dial-target class="md-primary">
            <md-icon class="md-morph-initial">settings</md-icon>
            <md-icon class="md-morph-final">settings</md-icon>
          </md-speed-dial-target>
  
          <md-speed-dial-content>
            <md-button
              class="md-icon-button delete"
              v-on:click="deleteModal = true"
            >
              <md-icon class="icon">delete</md-icon>
            </md-button>
          </md-speed-dial-content>
        </md-speed-dial>
      </div>
  
      <md-dialog-confirm
        :md-active.sync="deleteModal"
        md-title="Kullanıcıyı silmek istediğinize emin misiniz?"
        md-content="Bu işlem geri alınamaz."
        md-confirm-text="Sil"
        md-cancel-text="İptal Et"
        @md-confirm="onConfirm"
      />
  
      <div class="md-layout wrap">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-100 item profile-wrap" style="overflow-x: scroll">
          <div class="md-layout-item profile wrapper">
            <file-upload
              ref="upload"
              class="file profile-img"
              v-model="image"
              accept="image/png, image/gif, image/jpeg"
              extensions="gif,jpg,jpeg,png"
            >
              <img
                :src="
                  profile.profileImg
                    ? storagepath + profile.profileImg
                    : '/images/avatar.jpg'
                "
                alt="Avatar"
              />
              <img src="/images/add.jpg" class="add" alt />
            </file-upload>
  
            <div class="main">
              <span class="title">{{ profile.name }}</span>
              {{ profile.role ? profile.role.name : profile.roleId }}
            </div>
            <div class="secondary">
              <div class="md-layout">
                <div class="md-layout-item md-size-10 icon">
                  <i class="md-icon">phone</i>
                </div>
                <div class="md-layout-item">{{ phoneHolder }}</div>
              </div>
  
              <div class="md-layout">
                <div class="md-layout-item md-size-10 icon">
                  <i class="md-icon">mail</i>
                </div>
                <div class="md-layout-item">{{ profile.email }}</div>
              </div>
            </div>
            <div class="md-layout">
              <md-switch v-model="profile.status" class="md-primary">{{
                profile.status ? "Aktif Kullanıcı" : "Pasif Kullanıcı"
              }}</md-switch>
            </div>
            <div class="md-layout">
              <md-switch v-model="profile.getAppointmentsNotification" class="md-primary">{{
                profile.getAppointmentsNotification ? "Hafta içi randevuların bildirimi alacak" : "Hafta içi randevuların bildirimi almaz"
              }}</md-switch>
            </div>
            <div class="md-layout">
              <md-switch v-model="profile.getWhatsappNotifications" class="md-primary">{{
                profile.getWhatsappNotifications ? "Whatsapp bildirimleri açık" : "Whatsapp bildirimleri kapalı"
              }}</md-switch>
            </div>
            <div class="md-layout" v-if="roleId == 1 && profile.roleId == 2">
              <md-switch v-model="profile.canSeeComments" class="md-primary">{{
                profile.canSeeComments ? "Randevu Yorumları Görebilir" : "Randevu Yorumları Göremez"
              }}</md-switch>
            </div>
          </div>
  
          <div class="wrapper" v-if="roleId == 1">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('roles')"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <span class="md-title">Kullanıcı Yetkileri</span>
            <md-field>
              <md-select
                v-model="profile.roleId"
                v-bind:disabled="isDisabled.roles"
              >
                <md-option
                  v-for="role in roles"
                  v-bind:value="role.id"
                  v-bind:key="role.id"
                  >{{ role.name }}</md-option
                >
              </md-select>
            </md-field>
  
            <md-button
              class="md-raised md-primary editOkButton"
              v-on:click="editRoles"
              v-if="!isDisabled.roles"
              >Değiştir</md-button
            >
          </div>
  
          <div class="wrapper">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('password')"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <span class="md-title">Şifre Değiştir</span>
            <md-field>
              <label>Yeni Şifre</label>
              <md-input
                v-model="password"
                type="password"
                v-bind:disabled="isDisabled.password"
              ></md-input>
            </md-field>
            <md-field>
              <label>Yeni Şifre Onay</label>
              <md-input
                v-model="password1"
                type="password"
                v-bind:disabled="isDisabled.password"
              ></md-input>
            </md-field>
            <md-button
              class="md-raised md-primary editOkButton"
              v-on:click="editPassword"
              v-if="!isDisabled.password"
              >Değiştir</md-button
            >
          </div>
          
          <div class="wrapper" v-if="roleId == 1">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('information')"
              v-if="isDisabled.information"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled.information">
              <md-icon>save</md-icon>
            </md-button>
            <span class="md-title">Branşlar</span>
            <br/>
            <md-checkbox
              v-for="branch in branches"
              v-bind:key="branch.id"
              v-bind:value="branch.id"
              v-model="checkedBranches"
              v-bind:disabled="isDisabled.information"
              >{{ branch.name }}</md-checkbox
            >
            <div v-if="profile.roleId != 2">
              <span class="md-title">Doktorlar</span>
              <md-field>
              <label>Arayınız...</label>
              <md-input v-model="doctorsOfSelectedBranchesSearchInput"></md-input>
              <md-icon>search</md-icon>
            </md-field>
              <br/>
              <md-checkbox
                v-for="doctor in searchFilter(doctorsOfSelectedBranches,doctorsOfSelectedBranchesSearchInput)"
                v-bind:key="doctor.id"
                v-bind:value="doctor.id"
                v-model="userDoctors"
                v-bind:disabled="isDisabled.information"
                >{{ doctor.name }}</md-checkbox
              >
            </div>
            <div v-else>
              <span class="md-title">İşlem Limitleri</span>
              <br/>
              <md-table class="table appointments md-scrollbar" v-model="doctorLimits">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="">{{ item.name }}</md-table-cell>
                  <md-table-cell v-for="day in weekdays" :md-label="day.name" :key="day.id">
                    <md-field style="width: 35px;">
                      <md-input type="number" v-model="operationLimits[item.id]['day' + day.id]" v-bind:disabled="isDisabled.information"></md-input>
                    </md-field>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
  
          <!-- Leading users -->
          <div class="wrapper" v-if="roleId == 1 && profile.roleId != 2">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('information')"
              v-if="isDisabled.information"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled.information">
              <md-icon>save</md-icon>
            </md-button>
            <span class="md-title">Lider ettiği kullanıcılar</span>
            <md-field>
              <label>Arayınız...</label>
              <md-input v-model="usersWithoutDoctorsSearchInput"></md-input>
              <md-icon>search</md-icon>
            </md-field>
            <br/>
            <md-checkbox
             v-model="selectAllUsers"
             v-bind:disabled="isDisabled.information"
             > Tümünü Seç </md-checkbox>
            <md-checkbox
              v-for="user in searchFilter(usersWithoutDoctors,usersWithoutDoctorsSearchInput)"
              v-bind:key="user.id"
              v-bind:value="user.id"
              v-model="checkedUsers"
              v-bind:disabled="isDisabled.information"
              >{{ user.name }}</md-checkbox
            >
          </div>
          <div class="wrapper" v-if="(roleId == 1 || roleId == 2) && profile.roleId == 2">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('information')"
              v-if="isDisabled.information"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled.information">
              <md-icon>save</md-icon>
            </md-button>
            <span class="md-title">Kapalı Günler</span>
            <br/>
            <md-checkbox
              v-for="day in weekdays"
              v-bind:key="day.id"
              v-bind:value="day.id"
              v-model="closedDays"
              v-bind:disabled="isDisabled.information"
              >{{ day.name }}</md-checkbox
            >
          </div>
        </div>
        <!-- İlk sutun -->
  
        <div class="md-layout-item item md-xsmall-size-100 md-small-size-100">
          <div class="wrapper">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable('information')"
              v-if="isDisabled.information"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled.information">
              <md-icon>save</md-icon>
            </md-button>
            <span class="md-title">Bilgiler</span>
            <md-field>
              <label>Ad Soyad</label>
              <md-input
                v-model="profile.name"
                v-bind:disabled="isDisabled.information"
              ></md-input>
            </md-field>
            <md-field class="tel-input">
              <vue-phone-number-input
                v-model="profile.phone"
                :show-code-on-list="true"
                :translations="{
                  countrySelectorLabel: 'Ülke',
                  countrySelectorError: 'Hata',
                  phoneNumberLabel: 'Telefon Numarası',
                  example: 'Örnek :'
                }"
                @update="telInput"
                :error="!phoneValidate"
                clearable
                v-bind:disabled="isDisabled.information"
              ></vue-phone-number-input>
            </md-field>
            <div v-if="!phoneValidate" class="error">
              <p>Bu numara başka bir kullanıcıya tanımlanmıştır !</p>
            </div>
            <md-field>
              <label>E-Mail</label>
              <md-input
                v-model="profile.email"
                v-bind:disabled="isDisabled.information"
              ></md-input>
            </md-field>
            <md-field>
              <label>Cinsiyet</label>
              <md-select v-model="profile.sex">
                <md-option value="false">Kadın</md-option>
                <md-option value="true">Erkek</md-option>
              </md-select>
            </md-field>
  
            <md-datepicker
              v-model="profile.birthday"
              v-bind:disabled="isDisabled.information"
              md-immediately
            >
              <label>Doğum Tarihi</label>
            </md-datepicker>
  
            <div v-if="roleId == 1">
            <span class="md-title">Konumlar</span>
            <md-field>
              <label>Arayınız...</label>
              <md-input v-model="locationSearchInput"></md-input>
              <md-icon>search</md-icon>
            </md-field>
            <br/>
            <md-checkbox
             v-model="selectAllLocation"
             v-bind:disabled="isDisabled.information"
             > Tümünü Seç </md-checkbox>
            <md-checkbox
              v-for="location in searchFilter(locations,locationSearchInput)"
              v-bind:key="location.id"
              v-bind:value="location.id"
              v-model="checkedLocations"
              v-bind:disabled="isDisabled.information"
              >{{ location.name }}</md-checkbox
            >
            </div>
            <br/>
            <br/>
            <div  v-if="roleId == 1">
            <span class="md-title">Kaynaklar</span>
            <md-field>
              <label>Arayınız...</label>
              <md-input v-model="agenciesSearchInput"></md-input>
              <md-icon>search</md-icon>
            </md-field>
            <br/>
            <md-checkbox
             v-model="selectAllAgencies"
             v-bind:disabled="isDisabled.information"
             > Tümünü Seç </md-checkbox>
            <md-checkbox
              v-for="agencie in searchFilter(agencies,agenciesSearchInput)"
              v-bind:key="agencie.id"
              v-bind:value="agencie.id"
              v-model="checkedAgencies"
              v-bind:disabled="isDisabled.information"
              >{{ agencie.name }}</md-checkbox
            >
            </div>
          </div>
        </div>
        <!-- İkinci sutun -->
        <div class="md-layout-item item md-xsmall-size-100 md-small-size-100" v-if="true">
          <div class="wrapper">
            <span class="md-title">Randevular</span>
            <br />
            <span>Son 20 Randevu</span>
            <md-content class="md-scrollbar scroll">
              <appointmentList
                v-for="appointment in appointments"
                v-bind:key="appointment.id"
                v-bind:patientName="appointment.patient.name"
                v-bind:locationName="appointment.location.name"
                v-bind:date="appointment.date"
                v-bind:time="appointment.time"
              ></appointmentList>
            </md-content>
          </div>
        </div>
      </div>
      <md-dialog-alert
        :md-active.sync="validateDialog"
        md-content="Bu numara başka bir kullanıcıya tanımlanmıştır !"
        md-cancel-text="Kapat"
      />
    </div>
  </template>
  
  <script>
  // import userList from "../../components/user/list";
  import router from "../../../router";
  import { HTTP } from "../../../main-source";
  import appointmentList from "../../../components/user/appointmentList";
  import VuePhoneNumberInput from "vue-phone-number-input";
  var phone = null;
  export default {
    name: "Profile",
    data() {
      return {
        selectAllLocation: false,
        selectAllUsers: false,
        selectAllAgencies: false,
        title: "Kullanıcılar",
        profile: [],
        password: "placeholder",
        password1: "placeholder",
        roles: [],
        deleteModal: false,
        locations: [],
        checkedLocations: [],
        agencies: [],
        branches: [],
        users: [],
        checkedAgencies: [],
        checkedBranches: [],
        checkedUsers: [],
        userDoctors: [],
        isDisabled: {
          roles: true,
          password: true,
          information: true
        },
        appointments: [],
        phoneHolder: "",
        roleId: localStorage.getItem("roleId"),
        image: [],
        storagepath: this.$storage,
        weekdays: [
          {id: 1, name: "Pazartesi"},
          {id: 2, name: "Salı"},
          {id: 3, name: "Çarşamba"},
          {id: 4, name: "Perşembe"},
          {id: 5, name: "Cuma"},
          {id: 6, name: "Cumartesi"},
          {id: 0, name: "Pazar"},
        ],
        closedDays: [],
        phoneList: [],
        phoneValidate: true,
        validateDialog: false,
        doctorLimits : [{id : "surgery", name: "Ameliyat Limiti"}, {id : "notSurgery", name: "Ameliyatsız Limiti"}],
        operationLimits : [],
        locationSearchInput : "",
        agenciesSearchInput : "",
        usersWithoutDoctorsSearchInput : "",
        doctorsOfSelectedBranchesSearchInput : ""
      };
    },
    components: {
      appointmentList,
      "vue-phone-number-input": VuePhoneNumberInput
    },
    computed: {
      usersWithoutDoctors : function(){
        return this.users.filter(z => z.roleId != 2);
      },
      doctorsOfSelectedBranches : function(){
        return this.users.filter(z => 
        z.roleId == 2 && 
        this.checkedBranches.filter(checkedBranch => z.branches.map(branch => branch.id).includes(checkedBranch)).length > 0
        );
      }
    },
    created() {
      const id = this.$route.params.id;
      HTTP.get("/users/profile/" + id).then(result => {
        this.profile = result.data[0];
        phone = result.data[0].phone;
        this.profile.UserToLocations.forEach(location => {
          this.checkedLocations.push(location.id);
        });
        this.profile.agenciesOfUser.forEach(agencie => {
          this.checkedAgencies.push(agencie.id);
        });
        this.profile.branches.forEach(branch => {
          this.checkedBranches.push(branch.id);
        });
        // 
        this.profile.birthday = new Date(result.data[0].birthday);
        this.closedDays = this.profile.closedDays ? JSON.parse(this.profile.closedDays) : [];
        this.checkedUsers = this.profile.leading ? JSON.parse(this.profile.leading) : [];
        this.userDoctors = this.profile.userDoctors ? JSON.parse(this.profile.userDoctors) : [];
        this.operationLimits = {
          surgery : this.profile.doctorLimits && this.profile.doctorLimits.find(z => z.type == "surgery") ? this.profile.doctorLimits.find(z => z.type == "surgery") : { "day0" : 0, "day1" : 0, "day2" : 0, "day3" : 0, "day4" : 0, "day5" : 0, "day6" : 0},
          notSurgery : this.profile.doctorLimits && this.profile.doctorLimits.find(z => z.type == "notSurgery") ? this.profile.doctorLimits.find(z => z.type == "notSurgery") : { "day0" : 0, "day1" : 0, "day2" : 0, "day3" : 0, "day4" : 0, "day5" : 0, "day6" : 0},
        };
      });
      // HTTP.get("/users/" + id + "/appointments").then(result => {
      //   this.appointments = result.data;
      // });
      HTTP.get("/roles/").then(result => {
        this.roles = result.data;
      });
      HTTP.get("/locations/").then(result => {
        this.locations = result.data;
      });
      HTTP.get("/agencies/").then(result => {
        this.agencies = result.data;
      });
      HTTP.get("/branches/").then(result => {
        this.branches = result.data;
      });
      HTTP.get("/users/").then(result => {
        this.users = result.data;
        this.phoneList = this.users.map((item) => item.phone);
      });
    },
    methods: {
      onConfirm: function() {
        HTTP.delete("/users/?ids=" + this.profile.id).then(result => {
          if (result.status == 204) {
            router.push({ name: "users" });
          }
        });
      },
      telInput: function(tel) {
        this.phoneHolder = tel.formattedNumber;
        if (
          this.phoneList.includes(this.phoneHolder) &&
          this.phoneHolder != phone
        ) {
          this.phoneValidate = false;
          this.validateDialog = true;
        } else {
          this.phoneValidate = true;
          this.validateDialog = false;
        }
      },
      editable: function(options) {
        switch (options) {
          case "roles":
            this.isDisabled.roles = !this.isDisabled.roles;
            break;
          case "password":
            this.password = "";
            this.password1 = "";
            this.isDisabled.password = !this.isDisabled.password;
            break;
          case "information":
            this.isDisabled.information = !this.isDisabled.information;
            break;
          default:
            break;
        }
      },
      editRoles: function() {
        HTTP.patch("/users/" + this.profile.id + "/role", {
          roleId: this.profile.roleId
        }).then(result => {
          if (result.status == 204) {
            this.editable("roles");
          }
        });
      },
      editInformation: function() {
        // 
        if (this.phoneValidate == true) {
        this.profile.locations = this.checkedLocations;
        this.profile.agencies = this.checkedAgencies;
        this.profile.branches = this.checkedBranches;
        this.profile.phone = this.phoneHolder;
        this.profile.closedDays = JSON.stringify(this.closedDays);
        this.profile.leading = JSON.stringify(this.checkedUsers);
        this.userDoctors = this.userDoctors.filter(z => this.doctorsOfSelectedBranches.map(d => d.id).includes(z)); // removing old saved doctors whose branch has been removed
        this.profile.userDoctors = JSON.stringify(this.userDoctors);
        this.profile.operationLimits = JSON.stringify(this.operationLimits);
        HTTP.patch(
          "/users/" + this.profile.id + "/information",
          this.profile
        ).then(result => {
          if (result.status == 204) {
            this.editable("information");
          }
        });
         
        } else {
          this.validateDialog = true;
        }
      },
      editPassword: function() {
        let isValid = false;
        if (this.password == this.password1) {
          isValid = true;
        }
        if (isValid) {
          HTTP.patch("/users/" + this.profile.id + "/password", {
            password: this.password,
            password1: this.password1
          }).then(result => {
            if (result.status == 204) {
              this.editable("password");
            }
          });
        }
      },
      searchFilter: function(data,search){
        let searchValue = search.toLowerCase()
        return data.filter(item=>
        item.name.toLowerCase().match(searchValue) ? item : null
        )
      }
    },
    watch: {
      "selectAllLocation": function(){
        if(this.selectAllLocation){
          this.checkedLocations = []
          this.locations.forEach( location => {
            this.checkedLocations.push(location.id)
          })
        }else {
          this.checkedLocations = []
        }
      },
      "selectAllUsers": function(){
        if(this.selectAllUsers){
          this.checkedUsers = []
          this.users.forEach( user => {
            this.checkedUsers.push(user.id)
          })
        }else {
          this.checkedUsers = []
        }
      },
      "selectAllAgencies": function(){
        if(this.selectAllAgencies){
          this.checkedAgencies = []
          this.agencies.forEach( agencie => {
            this.checkedAgencies.push(agencie.id)
          })
        }else {
          this.checkedAgencies = []
        }
      },
      "profile.status": function() {
        HTTP.patch("/users/" + this.profile.id + "/status", {
          status: this.profile.status
        });
        },
      "profile.getAppointmentsNotification": function() {
        HTTP.patch("/users/" + this.profile.id + "/getAppointmentsNotification", {
          getAppointmentsNotification: this.profile.getAppointmentsNotification
        });
        },
      "profile.getWhatsappNotifications": function() {
        HTTP.patch("/users/" + this.profile.id + "/getWhatsappNotifications", {
          getWhatsappNotifications: this.profile.getWhatsappNotifications
        });
        },
      "profile.canSeeComments": function() {
        HTTP.patch("/users/" + this.profile.id + "/comments", {
          canSeeComments: this.profile.canSeeComments
        });
      },
      image: function() {
        let fd = new FormData();
        fd.append("image", this.image[0].file, this.image[0].name);
        
        HTTP.post("/users/" + this.profile.id + "/profileimage", fd, {
          headers: {
            "content-type": "multipart/form-data"
          }
        }).then(() => {
          HTTP.get("/users/profile/" + this.profile.id).then(result => {
            this.profile = result.data[0];
          });
        });
      }
    }
  };
  </script>
  
  <style lang="scss">
@import "../../../assets/scss/colors.scss";
  .md-dialog-content {
    color: #f00;
  }
  .error {
    display: block !important;
    color: #f00;
    transform: translate3d(0, -8px, 0);
    height: -19px;
    position: inherit;
    font-size: 1rem;
    border-bottom: inset;
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .wrap {
    margin: 0px -15px;
  }
  .item {
    min-height: 200px;
    margin: 15px;
    &:last-child {
      margin-right: 5px;
    }
  }
  .profile.wrapper {
    margin-top: 75px;
  }
  .wrapper {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
  .profile {
    text-align: center;
    img {
      width: 170px !important;
      height: 170px !important;
      border-radius: 50%;
      margin-top: -90px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      margin-bottom: 20px;
    }
    span {
      display: block;
    }
    .title {
      font-weight: bold;
    }
    .md-layout {
      text-align: left;
    }
    .icon {
      padding: 0px 10px;
      margin-right: 10px;
    }
    .main {
      margin: 15px 0px;
    }
    .secondary {
      margin: 30px 0px 15px 0px;
      .md-layout {
        margin-top: 10px;
        color: $dark;
      }
    }
  }
  .md-icon-button.delete {
    background: $red !important;
    .icon {
      color: $white !important;
    }
  }
  .editButton {
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }
  .md-scrollbar {
    max-height: 80vh !important;
    overflow: auto;
  }
  .editOkButton {
    width: 100%;
    margin: 0 !important;
  }
  .vue-phone-number-input {
    width: 100%;
  }
  </style>