<template>
  <div class="wrapper md-layout">
    <div class="md-layout-item md-size-40 topbar-profile md-layout">
      <div>
        <img
          :src="
            user.profileImg
              ? storagepath + user.profileImg
              : '/images/avatar.jpg'
          "
          alt="Avatar"
        />
      </div>
      <div>
        <span class="name">{{ user.name ? user.name : "" }}</span>
        <div style="display: flex">
          <span class="subract">{{ user.phone }} - {{ user.role.name }} </span>
        </div>
      </div>
    </div>
    <div class="md-layout-item md-size-60 text-right head-buttons">
      <md-speed-dial
        class="md-top-right"
        md-direction="bottom"
        md-event="click"
        v-if="roleId == 1"
      >
        <md-speed-dial-target class="md-primary">
          <md-icon class="md-morph-initial">settings</md-icon>
          <md-icon class="md-morph-final">settings</md-icon>
        </md-speed-dial-target>

        <md-speed-dial-content>
          <md-button class="md-icon-button delete" v-on:click="deleteUser">
            <md-icon class="icon">delete</md-icon>
          </md-button>
        </md-speed-dial-content>
      </md-speed-dial>

      <md-button
        class="md-raised md-default button-margins"
        v-if="prev"
        v-on:click="prevRecord"
      >
        <md-icon>keyboard_arrow_left</md-icon>Önceki
      </md-button>
      <md-button
        class="md-raised md-default button-margins"
        v-if="next"
        v-on:click="nextRecord"
      >
        Sonraki
        <md-icon>keyboard_arrow_right</md-icon>
      </md-button>
    </div>
  </div>
</template>
  
  
  <script>
import { HTTP } from "@/main-source";
import router from "@/router";

export default {
  name: "user.profile.topbar",
  data: function () {
    return {
      next: null,
      prev: null,
      storagepath: this.$storage,
      user: {
        role: {
          name: null,
        },
      },
      roleId: localStorage.getItem("roleId"),
    };
  },
  created: function () {
    HTTP.get("/users/" + this.$route.params.id + "/getUsersInfosTopBar/").then(
      (result) => {
        this.user = result.data[0];
      }
    );
  },
  methods: {
    setNextAndPrev: function () {
      let uri = "/patients/potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "/patients/";
      }

      // if (this.roleId != 1 && this.roleId != 4) {
      //         uri += "&user=" + localStorage.getItem("userId");
      //       }
      var userLimits = this.roleId != 1 ? "?userId=" + this.userId : "";

      HTTP.get(uri + this.$route.params.id + "/nextprev" + userLimits)
        .then((result) => {
          // this.patient = result.data[0];
          this.isPatient = result.data[0].type;
          this.patientAgencieName = this.patient.agencie
            ? this.patient.agencie.name
            : "";

          result.data[1] ? (this.next = result.data[1].id) : (this.next = null);
          result.data[2] ? (this.prev = result.data[2].id) : (this.prev = null);

          this.patientTreatments();
        })
        .catch(() => {
          HTTP.get("/patients/" + this.$route.params.id).then((result) => {
            // this.patient = result.data[0];
            this.isPatient = result.data[0].type;
          });
        });
    },
    prevRecord: function () {
      let uri = "potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "patients/";
      }
      window.location = this.$appDomain + uri + this.prev + "/general";
    },
    nextRecord: function () {
      let uri = "potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "patients/";
      }
      window.location = this.$appDomain + uri + this.next + "/general";
    },
    deleteUser: function () {
      this.$swal({
        title: "Emin misiniz?",
        text: "Seçilen kullanıcının tüm bilgileri, randevuları işlemleri ve dosyaları SİLENECEKTİR.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/users/?ids=" + this.user.id).then((result) => {
            if (result.status == 204) {
              router.push({ name: "users" });
            }
          });
        }
      });
    },
  },
};
</script>
  
  <style lang="scss"  >
.topbar-profile {
  img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 10px;
  }
  span {
    display: block;
    margin-top: 5px;
  }
  .name {
    font-size: 24px;
    font-weight: bold;
  }
}
.talert {
  margin-bottom: 10px !important;
  button {
    margin-left: 15px !important;
  }
}
.md-layout.link {
  margin-bottom: 10px;
  a,
  i {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.smallSelect ul {
  max-height: 250px;
}
.scrollbarY {
  overflow-y: auto;
  overflow-x: hidden;
}
button.button-margins {
  margin: 3px !important;
}
.goBackButton {
  background-color: #666;
  color: #fff;
  font-weight: bold;
  margin-top: -15px;
  margin-left: -15px;
  margin-bottom: 15px;
  padding: 10px;
  width: 110px;

  .md-icon {
    color: #fff !important;
  }
}
.goBackButtonLink {
  &:hover,
  &:active {
    text-decoration: none !important;
  }
}
.wordWrap {
  word-wrap: break-word;
}
</style>