<template>
  <div>
    <h1>{{ title }}</h1>
    <Loading v-if="loading" />
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step
        id="first"
        md-label="Randevu Oluştur 1/4"
        md-description="Kişisel Bilgiler & Hasta Seçimi"
        :md-done.sync="first"
      >
        <div class="md-layout md-gutter step-inner">
          <div class="md-layout-item md-size-100 hidden-big">
            <md-button
              class="md-raised md-primary btn-right"
              @click="setDone('first', 'second')"
            >
              Devam
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item box">
            <span class="md-title">Hasta Ara</span>
            <md-field>
              <label>Hasta Adı</label>
              <md-input v-model="search_query"></md-input>
              <md-icon>search</md-icon>
            </md-field>

            <span class="md-title">Lütfen Hasta Seçin</span>
            <span class="md-error" v-if="this.isValid.patient === false"
              >Lütfen Hasta Seçiniz</span
            >
            <div class="md-layout patient-list">
              <patientlist
                class="md-layout-item patient-wrap"
                v-for="patient in patients"
                :name="patient.name"
                :country="
                  patient.country
                    ? patient.country.name_tr
                    : patient.countryCode
                "
                :phone="patient.phone"
                :imgUrl="patient.imgUrl"
                :key="patient.id"
                :id="patient.id"
                v-bind:class="{
                  selectedpatient: selectedPatient.id == patient.id,
                }"
                v-on:click.native="selectPatient(patient.id)"
              ></patientlist>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="inner-box newPatient" v-if="false">
              <span class="md-title">Yeni Hasta</span>

              <!-- component olacak -->
              <md-field v-bind:class="{ 'md-invalid': isValid.name == false }">
                <label>Ad Soyad</label>
                <md-input v-model="newPatient.name"></md-input>
                <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
              </md-field>
              <md-field>
                <label>Email</label>
                <md-input v-model="newPatient.email"></md-input>
              </md-field>
              <md-field>
                <label>Telefon</label>
                <md-input v-model="newPatient.phone"></md-input>
              </md-field>
              <md-field>
                <label>Cinsiyet</label>
                <md-select v-model="newPatient.sex">
                  <md-option value="false">Kadın</md-option>
                  <md-option value="true">Erkek</md-option>
                </md-select>
              </md-field>

              <md-datepicker v-model="newPatient.birthday" md-immediately>
                <label>Doğum Tarihi</label>
              </md-datepicker>

              <md-field>
                <v-select
                  v-model="newPatient.agencieId"
                  placeholder="Acente"
                  :options="agencies"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>

              <md-field>
                <v-select
                  v-model="newPatient.source"
                  placeholder="İletişim Kanalı"
                  :options="sources"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>

              <md-button
                class="md-raised md-primary full-btn"
                v-on:click="addPatient()"
                >Hasta Ekle</md-button
              >
              <!-- component olacak -->
            </div>
            <md-button
              class="md-raised md-primary btn-right"
              @click="setDone('first', 'second')"
            >
              Devam
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>

      <md-step
        id="second"
        md-label="Randevu Oluştur 2/4"
        md-description="İşlem Tipi, Tarih & Katılımcılar"
        :md-error="secondStepError"
        :md-done.sync="second"
      >
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <div class="inner-box">
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <span class="md-title" style="display: block"
                    >Tarih & Saat</span
                  >
                  <md-datepicker
                    :md-disabled-dates="isDateDisabled"
                    v-model="appointmentDate"
                    md-immediately
                  >
                    <label>Randevu Tarihi</label>
                  </md-datepicker>
                  <span class="md-error" v-if="this.isValid.date === false"
                    >Randevu tarihi seçmelisiniz</span
                  >
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <span class="md-title">Randevu Saati</span>
                  <md-field>
                    <vue-timepicker
                      format="HH:mm"
                      :hour-range="[
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20,
                      ]"
                      :minute-interval="5"
                      placeholder="SS:dd"
                      v-model="time"
                      close-on-complete
                      hide-disabled-hours
                    ></vue-timepicker>
                  </md-field>
                  <span class="md-error" v-if="this.isValid.time === false"
                    >Randevi saati seçmelisiniz</span
                  >
                </div>
              </div>
              <div class="md-layout" v-if="!depositPaid">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <md-checkbox
                    v-model="temporaryAppointment"
                    :disabled="temporaryConversion"
                    >Opsiyonlanma</md-checkbox
                  >
                </div>
                <!-- <div class="md-layout-item md-size-50 md-xsmall-size-100" v-if="temporaryAppointment">
                  <md-field>
                    <label for="temporaryPeriod">opsiyonlanma Süresi</label>
                  <md-select v-model="temporaryPeriod" name="temporaryPeriod" id="temporaryPeriod">
                    <md-option value="24">24 Saat</md-option>
                    <md-option value="48">48 Saat</md-option>
                    <md-option value="72">72 Saat</md-option>
                    <md-option value="168" v-if="show1weekOption">1 Hafta</md-option>
                  </md-select>
                </md-field>
                </div> -->
              </div>
              <div
                class="md-layout"
                v-if="
                  appointmentCreationLimitControlResponse.isSuccess == false
                "
              >
                <md-checkbox v-model="reqApprove"
                  >Yönetici onayı iste</md-checkbox
                >
              </div>
              <div v-if="this.appointment.date" class="selectedDayOperations">
                <h5>Seçili Güne Ait Operasyonlar</h5>

                <div
                  v-for="(dayOperation, index) in dayOperations"
                  :key="index"
                >
                  <span class="number">{{
                    selectedDayAppointments.filter(
                      (e) => e.operationId == dayOperation
                    ).length
                  }}</span>
                  <div>{{ selectedDayOperationsValues(dayOperation) }}</div>
                </div>
              </div>

              <!-- <span class="md-error" v-if="!canContinue && daysLimit">
                Bu güne daha fazla Ameliyat giremezsiniz, lütfen başka bir gün
                seçiniz.
                <br />
              </span>

              <span class="md-error" v-if="!canContinue && operationDailyLimit">
                Seçtiğiniz işlem günlük üst limite ulaşmış.
              </span>
              <span
                class="md-error"
                v-if="!canContinue && userMonthlyLimitExceeded"
              >
                Kullanıcı aylık ameliyat sayısı üst limite ulaşmış.
              </span>

              <span class="md-error" v-if="!canContinue && pastDate">
                Geçmiş tarih seçtiniz. Lütfen Başka bir gün seçiniz.
              </span>

              <span class="md-error" v-if="isDayClosed">
                Seçili gün kapalı olarak işaretlenmiş. Lütfen başka bir gün
                seçiniz.
              </span>

              <span class="md-error" v-if="!canContinue && weeklyLimitExceeded">
                Haftalık ameliyat sayısı üst limite ulaşmış.
              </span>
              <span class="md-error" v-if="!canContinue && daysNotSurgeryLimit">
                Bu güne daha fazla Ameliyatsız İşlem giremezsiniz, lütfen başka
                bir gün seçiniz.
                <br />
              </span>
              <span class="md-error" v-if="doctorNotSurgeryDailyLimit">
                Seçtiğiniz doktorun günlük ameliyatsız işlem limiti dolmuştur.
              </span>
              <span class="md-error" v-if="doctorSurgeryDailyLimit">
                Seçtiğiniz doktorun günlük ameliyatlı işlem limiti dolmuştur.
              </span>
              <span
                class="md-error"
                v-if="!canContinue && weeklyNotSurgeryLimitExceeded"
              >
                Haftalık ameliyatsız sayısı üst limite ulaşmış.
              </span>

              <span
                class="md-error"
                v-if="!canContinue && userMonthlyNotSurgeryLimitExceeded"
              >
                Kullanıcı aylık ameliyatsız işlem üst limite ulaşmış.
              </span> -->

              <span
                :class="
                  appointmentCreationLimitControlResponse.isSuccess
                    ? 'md-success'
                    : 'md-error'
                "
              >
                {{ appointmentCreationLimitControlResponse.message }}
              </span>

              <md-content class="timeSelect">
                <md-table class="time-table">
                  <md-table-row
                    v-for="(dayAppointment, index) in selectedDayAppointments"
                    v-bind:key="index"
                  >
                    <md-table-cell md-label="Saat" class="selectTime">{{
                      dayAppointment.time.substr(0, 5)
                    }}</md-table-cell>
                    <md-table-cell md-label="Operasyon">{{
                      dayAppointment.operation.name
                    }}</md-table-cell>
                  </md-table-row>
                </md-table>
              </md-content>
            </div>
            <div v-if="screenWidth >= 769" class="btn-wrap">
              <md-button
                class="md-raised md-secondary"
                v-on:click="turnBack('first')"
                >Geri Dön</md-button
              >
              <md-button
                class="md-raised md-primary btn-right md-disabled"
                v-if="
                  appointmentCreationLimitControlResponse.isSuccess ||
                    reqApprove
                "
                v-on:click="setDone('second', 'third')"
              >
                Devam
                <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="inner-box">
              <span class="md-title">İşlem Tipi</span>

              <md-field>
                <v-select
                  :clearable="false"
                  v-model="selectedUser"
                  placeholder="Temsilciler"
                  :options="users"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>

              <md-field>
                <v-select
                  :clearable="false"
                  v-model="selectedBranch"
                  placeholder="Branş"
                  :options="branches"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>
              <md-field>
                <v-select
                  :clearable="false"
                  v-model="selectedOperation"
                  placeholder="İşlem"
                  :options="operations"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>

              <span class="md-error" v-if="this.isValid.operation === false"
                >İşlem seçmelisiniz</span
              >

              <md-field>
                <label>Konum</label>
                <md-select
                  v-model="appointment.locationId"
                  name="locations"
                  id="locations"
                  placeholder="Konum"
                >
                  <md-option
                    :style="{ width: screenWidth >= 769 ? '' : '250px' }"
                    v-for="location in locations"
                    v-bind:value="location.id"
                    v-bind:key="location.id"
                    >{{ location.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error" v-if="this.isValid.alocation === false"
                >Konum seçmelisiniz</span
              >

              <md-field>
                <label>Katılımcılar</label>
                <md-select
                  v-model="appointment.doctor"
                  name="participants"
                  id="participants"
                  placeholder="Katılımcılar"
                >
                  <md-option
                    v-for="participant in participants"
                    v-bind:value="participant.id"
                    v-bind:key="participant.id"
                    >{{ participant.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error" v-if="this.isValid.participants === false"
                >Katılımcı seçmelisiniz</span
              >
              <md-field>
                <label>İşlem Süresi</label>
                <md-input
                  v-model="appointment.duration"
                  placeholder="İşlem Süresi"
                ></md-input>
              </md-field>
              <span class="md-error" v-if="this.isValid.duration === false"
                >İşlem Süresi Girmelisiniz</span
              >
            </div>
            <div class="inner-box">
              <span class="md-title">Hastaneye Randevu Notu</span>
              <md-field>
                <md-textarea
                  v-model="appointment.note"
                  placeholder="Randevu notu"
                ></md-textarea>
              </md-field>
              <div class="md-layout">
                <md-checkbox
                  class="md-layout-item md-size-30"
                  v-model="notifyMe"
                  >Hatırlat</md-checkbox
                >
                <md-checkbox
                  class="md-layout-item md-size-30"
                  v-model="byEmail"
                  v-if="notifyMe"
                  >E-Mail</md-checkbox
                >
                <md-checkbox
                  class="md-layout-item md-size-30"
                  v-model="byWhatsapp"
                  v-if="notifyMe"
                  >WhatsApp</md-checkbox
                >

                <md-datepicker
                  class="md-layout-item md-size-50"
                  v-model="notificationDate"
                  name="date"
                  v-if="notifyMe"
                  md-immediately
                >
                  <label>Ne Zaman</label>
                </md-datepicker>
                <md-field class="md-layout-item md-size-50" v-if="notifyMe">
                  <vue-timepicker
                    drop-direction="up"
                    format="HH:mm"
                    :minute-interval="5"
                    placeholder="SS:dd"
                    v-model="notificationTime"
                    close-on-complete
                    hide-clear-button
                  ></vue-timepicker>
                </md-field>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            "
            v-if="screenWidth < 769"
            class="btn-wrap"
          >
            <md-button
              class="md-raised md-secondary"
              v-on:click="turnBack('first')"
              >Geri Dön</md-button
            >
            <md-button
              class="md-raised md-primary btn-right"
              v-on:click="setDone('second', 'third')"
              v-if="buttonVisibleControl()"
            >
              Devam
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>

      <md-step
        id="third"
        md-label="Onay Formu 3/4"
        md-description="Onay Formu"
        :md-done.sync="third"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center"
          class="md-layout-item wrapper"
        >
          <md-button
            id="form-container-continue-button"
            class="md-raised md-primary btn-right"
            @click="setDone('third', 'four')"
          >
            Devam
            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
          <div style="margin-top: 30px">
            <div ref="pdfContent">
              <ConfirmationTemplate
                @validation="confirmationTemplateValidationMethod"
                v-if="selectedConfirmationContent.content"
                :confirmation="selectedConfirmationContent.content"
                :template="selectedConfirmationTemplate"
                :input="true"
                create
              >
              </ConfirmationTemplate>
            </div>
          </div>

          <md-button
            style="margin-top: 30px !important"
            id="form-container-continue-button"
            class="md-raised md-primary btn-right"
            @click="setDone('third', 'four')"
          >
            Devam
            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
        </div>
      </md-step>

      <md-step
        id="four"
        md-label="Randevu Oluştur 4/4"
        md-description="Önizleme & Onay"
        class="last-step"
        :md-done.sync="four"
      >
        <div class="md-layout md-gutter">
          <div class="md-layout-item inner-box">
            <div class="md-layout">
              <div
                class="md-layout-item md-size-35 little-table md-xsmall-size-100"
              >
                <span class="md-title brand-color">Hasta Bilgileri</span>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Ad Soyad
                  </div>
                  <div class="md-layout-item">: {{ appointment.name }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Telefon
                  </div>
                  <div class="md-layout-item">: {{ appointment.phone }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Kaynak
                  </div>
                  <div class="md-layout-item">
                    : {{ agencieName(appointment) }}
                  </div>
                </div>

                <!-- <div class="md-layout">
                  <div class="md-layout-item md-size-15 md-xsmall-size-40">E-Mail</div>
                  <div class="md-layout-item">: {{ appointment.email }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15 md-xsmall-size-40">Cinsiyet</div>
                  <div class="md-layout-item">
                    : {{ appointment.sex ? "Erkek" : "Kadın" }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15 md-xsmall-size-40">D. Tarihi</div>
                  <div class="md-layout-item">: {{ appointment.birthday }}</div>
                </div> -->

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Ülke
                  </div>
                  <div class="md-layout-item">
                    :
                    {{ appointment.country ? appointment.country.name_tr : "" }}
                  </div>
                </div>
              </div>

              <div class="md-layout-item little-table md-xsmall-size-100">
                <span class="md-title brand-color"
                  >İşlem Tipi, Tarihi & Katılımcılar</span
                >

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    İşlem
                  </div>
                  <div class="md-layout-item">
                    : {{ selectedOperationsName }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Konum
                  </div>
                  <div class="md-layout-item">: {{ LocationModify }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Temsilci
                  </div>
                  <div class="md-layout-item">: {{ selectedUser.name }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-35 md-xsmall-size-40">
                    <b>Katılmcılar</b>
                  </div>
                  <div class="md-layout-item">: {{ participantsModify }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Tarih
                  </div>
                  <div class="md-layout-item">
                    : {{ formatDate(appointment.date) }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Saat
                  </div>
                  <div class="md-layout-item">: {{ appointment.time }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    İşlem Süresi
                  </div>
                  <div class="md-layout-item">
                    : {{ appointment.duration }} dk
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Hastaneye Randevu Notu
                  </div>
                  <div class="md-layout-item">: {{ appointment.note }}</div>
                </div>
              </div>

              <div class="md-layout-item little-table md-xsmall-size-100">
                <span class="md-title brand-color">Onay Formu</span>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Inc Package
                  </div>
                  <div class="md-layout-item">
                    :
                    {{ selectedConfirmationContent.content.incPrice.content }}
                    {{
                      selectedConfirmationContent.content.priceCurrency.content
                    }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Deposit Price
                  </div>
                  <div class="md-layout-item">
                    :
                    {{
                      selectedConfirmationContent.content.depositPrice.content
                    }}
                    {{
                      selectedConfirmationContent.content.priceCurrency.content
                    }}
                  </div>
                </div>

                <div
                  v-if="
                    selectedConfirmationContent.content.visibleAreas
                      .transferInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Vip Transfer
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.vipTransfer.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Transfer Managing Name Surname
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content
                          .transferManagingNameSurname.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Airport to Hotel/Hospital
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content
                          .airportToHotelHospital.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Hospital to Hotel/Address
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content
                          .hospitalToHotelAddress.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Transfer to Hotel/Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content
                          .transferFromToHotelToAirport.content
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedConfirmationContent.content.visibleAreas.hotelInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Hotel Name
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.hotelName.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Accommodation Day
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.accommodationDay
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Room Type Adults / Children
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content
                          .roomTypeAdultsChildren.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Check-in Date
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.checkInDate.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Check-out Date
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        formatDate(
                          selectedConfirmationContent.content.checkOutDate
                            .content
                        )
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedConfirmationContent.content.visibleAreas.flightInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Arrival : Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.arrivalAirport
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Date and time
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.dateAndTime.content
                      }}
                      {{ selectedConfirmationContent.content.dateAndTime.time }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Return : Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.returnAirport
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Return Date and time
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.returnDateAndTime
                          .content
                      }}
                      {{
                        selectedConfirmationContent.content.returnDateAndTime
                          .time
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Flight Code
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        selectedConfirmationContent.content.flightCode.content
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedConfirmationContent.content.notes.content.length > 0
                  "
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Notes
                  </div>
                  <div class="md-layout-item">
                    : {{ selectedConfirmationContent.content.notes.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Confirmation Form -->
          <div
            class="md-layout-item inner-box md-size-100 confirmation"
            v-if="convertPatient && false"
          >
            <!-- Row -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-100">
                <span class="md-title">Confirmation Form</span>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.treatment_package.required &&
                      $v.appointment.confirmation.treatment_package.$error,
                  }"
                >
                  <label>Treatment Package</label>
                  <md-input
                    v-model="appointment.confirmation.treatment_package"
                    @blur="
                      $v.appointment.confirmation.treatment_package.$touch()
                    "
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.inc_price.required &&
                      $v.appointment.confirmation.inc_price.$error,
                  }"
                >
                  <label>Inc Price</label>
                  <md-input
                    v-model="appointment.confirmation.inc_price"
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
            </div>
            <!-- Row -->

            <!-- Row -->
            <div class="md-layout md-gutter">
              <!-- <div class="md-layout-item md-size-100">
                <span class="md-title">General</span>
              </div>-->
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.arrival_airport.required &&
                      $v.appointment.confirmation.arrival_airport.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.arrival_airport"
                    placeholder="Arrival Airport"
                    :options="airports"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-datepicker
                  v-model="appointment.confirmation.arrival_date"
                  md-immediately
                >
                  <label>Arrival Date</label>
                </md-datepicker>

                <span
                  class="md-error"
                  v-if="
                    !$v.appointment.confirmation.arrival_date.required &&
                      $v.appointment.confirmation.arrival_date.$error
                  "
                  >Bu alanı boş bırakmamalısınız!</span
                >
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.arrival_time.required &&
                      $v.appointment.confirmation.arrival_time.$error,
                  }"
                >
                  <label>Arrival Time</label>
                  <vue-timepicker
                    format="HH:mm"
                    :minute-interval="5"
                    placeholder="SS:dd"
                    v-model="appointment.confirmation.arrival_time"
                    close-on-complete
                    hide-disabled-hours
                  ></vue-timepicker>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
            </div>
            <!-- Row -->
            <!-- Row -->
            <div class="md-layout md-gutter">
              <!-- <div class="md-layout-item md-size-100">
                <span class="md-title">General</span>
              </div>-->
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.return_airport.required &&
                      $v.appointment.confirmation.return_airport.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.return_airport"
                    placeholder="Return Airport"
                    :options="airports"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-datepicker
                  v-model="appointment.confirmation.return_date"
                  md-immediately
                >
                  <label>Return Date</label>
                </md-datepicker>
                <span
                  class="md-error"
                  v-if="
                    !$v.appointment.confirmation.return_date.required &&
                      $v.appointment.confirmation.return_date.$error
                  "
                  >Bu alanı boş bırakmamalısınız!</span
                >
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.return_time.required &&
                      $v.appointment.confirmation.return_time.$error,
                  }"
                >
                  <label>Return Time</label>
                  <vue-timepicker
                    format="HH:mm"
                    :minute-interval="5"
                    placeholder="SS:dd"
                    v-model="appointment.confirmation.return_time"
                    close-on-complete
                    hide-disabled-hours
                  ></vue-timepicker>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
            </div>
            <!-- Row -->
            <!-- Row -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-100">
                <span class="md-title">Transfer Informations</span>
              </div>
              <div class="md-layout-item md-size-100">
                <!-- <md-field > -->
                <!-- <label>Vip Transfer</label> -->
                <md-checkbox v-model="appointment.confirmation.isVip"
                  >VIP Transfer</md-checkbox
                >
                <!-- </md-field> -->
              </div>
              <div class="md-layout-item md-size-40">
                <md-field v-if="appointment.confirmation.isVip">
                  <label>Transfer Managing Name Surname</label>
                  <md-input
                    v-model="
                      appointment.confirmation.transfer_managing_fullname
                    "
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field v-if="appointment.confirmation.isVip">
                  <label>Airport to Hotel/ Hospital</label>
                  <md-input
                    v-model="appointment.confirmation.airport_to_hotel_hospital"
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field v-if="appointment.confirmation.isVip">
                  <label>Hospital to Hotel/ Address</label>
                  <md-input
                    v-model="appointment.confirmation.hospital_to_hotel_address"
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field v-if="appointment.confirmation.isVip">
                  <label>Transfer From Hotel to Airport</label>
                  <md-input
                    v-model="appointment.confirmation.hotel_to_airport"
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
            </div>
            <!-- Row -->

            <!-- Row -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-100">
                <span class="md-title">Accommodation</span>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.hotel_name.required &&
                      $v.appointment.confirmation.hotel_name.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.hotel_name"
                    placeholder="Hotel Name"
                    :options="hotels"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.hotel_days.required &&
                      $v.appointment.confirmation.hotel_days.$error,
                  }"
                >
                  <label>Accommodation Day</label>
                  <md-input
                    v-model="appointment.confirmation.hotel_days"
                    type="number"
                  ></md-input>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.room_type.required &&
                      $v.appointment.confirmation.room_type.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.room_type"
                    placeholder="Room Type"
                    :options="roomTypes"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-datepicker
                  v-model="appointment.confirmation.check_in"
                  md-immediately
                >
                  <label>Check-in Date</label>
                </md-datepicker>
                <span
                  class="md-error"
                  v-if="
                    !$v.appointment.confirmation.check_in.required &&
                      $v.appointment.confirmation.check_in.$error
                  "
                  >Bu alanı boş bırakmamalısınız!</span
                >
              </div>
              <div class="md-layout-item md-size-50">
                <md-datepicker
                  v-model="appointment.confirmation.check_out"
                  md-immediately
                >
                  <label>Check-out Date</label>
                </md-datepicker>
                <span
                  class="md-error"
                  v-if="
                    !$v.appointment.confirmation.check_out.required &&
                      $v.appointment.confirmation.check_out.$error
                  "
                  >Bu alanı boş bırakmamalısınız!</span
                >
              </div>
            </div>
            <!-- Row -->

            <!-- Row -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-100">
                <span class="md-title">Doctors</span>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.staff_doctor.required &&
                      $v.appointment.confirmation.staff_doctor.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.staff_doctor"
                    placeholder="Staff Doctor"
                    :options="participants"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Lütfen seçim yapınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.assistant_doctor.required &&
                      $v.appointment.confirmation.assistant_doctor.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.assistant_doctor"
                    placeholder="Assistant Doctor"
                    :options="participants"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Lütfen seçim yapınız!</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field
                  :class="{
                    'md-invalid':
                      !$v.appointment.confirmation.Nutritionist.required &&
                      $v.appointment.confirmation.Nutritionist.$error,
                  }"
                >
                  <v-select
                    v-model="appointment.confirmation.Nutritionist"
                    placeholder="Nutritionist"
                    :options="options"
                    label="name"
                    class="md-menu"
                  >
                    <template slot="option" slot-scope="option">{{
                      option.name
                    }}</template>
                  </v-select>
                  <span class="md-error">Lütfen seçim yapınız!</span>
                </md-field>
              </div>
            </div>
            <!-- Row -->
          </div>
          <!-- Confirmation Form -->
          <div class="md-layout-item md-size-100">
            <Loading v-if="isPDFProcessingC"></Loading>
            <md-button
              class="md-raised md-secondary"
              v-on:click="turnBack('third')"
              :disabled="isPDFProcessingC"
              >Geri Dön</md-button
            >&nbsp;&nbsp;
            <md-button
              class="md-raised md-secondary"
              @click="preview"
              :disabled="isPDFProcessingC"
              v-if="false"
              >Onay Formunu Önizle</md-button
            >&nbsp;&nbsp;
            <md-button
              class="md-raised md-primary btn-right"
              :style="{
                'pointer-events': buttonTwoClickControl != 0 ? 'none' : 'all',
              }"
              v-on:click="createAppointment"
              :disabled="isPDFProcessingC && buttonTwoClickControl != 0"
            >
              Randevu Oluştur
              <md-icon>add</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>
    </md-steppers>
  </div>
</template>

<script>
/*eslint-disable*/
// import axios from "axios";
import patientlist from "../components/patients/listwithoutlink";
import router from "../router";
import { HTTP } from "../main-source";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { required, minLength, between } from "vuelidate/lib/validators";
import Loading from "@/components/Loading";
import moment from "moment";

import ConfirmationTemplate from "../components/confirmationTemplate/confirmationTemplate.vue";
import html2pdf from "html2pdf.js";

export default {
  name: "createAppointment",
  data() {
    return {
      title: "Randevu Oluştur",
      active: "first", //first yaz bitince
      first: false,
      second: false,
      third: false,
      four: false,
      secondStepError: null,
      operations: [],
      allOperations: [],
      doctors: [],
      branches: [],
      userId: localStorage.getItem("userId"),
      roleId: localStorage.getItem("roleId"),
      selectedBranch: null,
      selectedOperationsName: "",
      patients: [],
      newPatient: {
        name: "",
      },
      roles: [],
      roleName: "",
      type: "",
      locations: [],
      sources: [],
      dayOperations: [],
      selectedOperation: null,
      isPDFProcessingC: false,
      appointmentDate: null,
      appointment: {
        operationId: "",
        userId: localStorage.getItem("userId"),
        duration: 0,
        locationId: null,
        confirmation: {
          isVip: false,
          inc_price: "",
          treatment_package: null,
          arrival_airport: null,
          arrival_date: null,
          arrival_time: null,
          return_airport: null,
          return_date: null,
          return_time: null,
          hotel_name: null,
          hotel_days: null,
          room_type: null,
          check_in: null,
          check_out: null,
          treatment_date: null,
          treatment_time: null,
          operation_location: null,
          staff_doctor: null,
          staff_location: null,
          assistant_doctor: null,
          Nutritionist: null,
        },
        statusId: 1,
        notify_me: true,
        participants: [],
        doctor: "",
        isDiscounted: false,
      },
      process_type: [],
      participants: [],
      process: [],
      selectedPatient: {},
      search_query: "",
      selectedTime: {},
      queryId: this.$route.query.id,
      comingDatetime: this.$route.query.datetime,
      isValid: {
        name: null,
        location: null,
        patient: null,
        operation: null,
        alocation: null,
        duration: null,
        participants: null,
        date: null,
        time: null,
        isDiscounted: null,
      },
      selectedDayAppointments: [],
      selectedDayOperations: [],
      time: "",
      options: [],
      agencies: [],
      convertPatient: this.$route.query.convert ? true : false,
      airports: [],
      hotels: [],
      dayProcess: 0,
      dayLimit: {},
      customLimits: [],
      holidays: [],
      isDayClosed: false,
      weeklyLimitExceeded: false,
      userMonthlyLimitExceeded: false,
      canContinue: false,
      daysLimit: false,
      operationDailyLimit: false,
      pastDate: false,
      weekendDays: [0, 6],
      reqApprove: false,
      temporaryConversion: this.$route.query.temporaryConversion == "true",
      temporaryAppointment: this.$route.query.temporaryConversion == "true",
      depositPaid: this.$route.query.depositPaid == "true",
      temporaryPeriod: 24,
      show1weekOption: false,
      byEmail: false,
      byWhatsapp: false,
      notifyMe: false,
      notificationDate: null,
      notificationTime: "00:00",
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      doctorNotSurgeryDailyLimit: false,
      doctorSurgeryDailyLimit: false,
      daysNotSurgeryLimit: false,
      userMonthlyNotSurgeryLimitExceeded: false,
      weeklyNotSurgeryLimitExceeded: false,
      buttonTwoClickControl: 0,
      users: [],
      transferManagingUsers: [],
      selectedUser: "",
      confirmationContentJSON: {},
      selectedConfirmationTemplate: {},
      confirmationTemplates: [],
      selectedConfirmationContent: { content: {} },
      allConfirmationContents: [],
      selectedHotel: {},
      selectedArrivalAirport: {},
      selectedReturnAirport: {},
      flightDate: "",
      flightTime: "",
      flightReturnDate: "",
      flightReturnTime: "",
      allCurrencies: "",
      selectedCurrencies: "",
      depozitPrice: "",
      incPackagePrice: "",
      hotelAccommodationDay: 1,
      hotelCheckInDate: "",
      selectedRoomTypes: {},
      roomTypes: [],
      storagepath: this.$storage,
      profile: [],
      loading: true,
      confirmationTemplateValidation: false,
      screenWidth: 0,
      appointmentCreationLimitControlResponse: {
        isSuccess: false,
        message: "Gerekli bilgiler girildiğinde limit kontrolü başlayacaktır.",
      },
    };
  },
  validations: {
    appointment: {
      confirmation: {
        treatment_package: { required },
        inc_price: { required },
        arrival_airport: { required },
        arrival_date: { required },
        arrival_time: { required },
        return_airport: { required },
        return_date: { required },
        return_time: { required },
        hotel_name: { required },
        hotel_days: { required },
        room_type: { required },
        check_in: { required },
        check_out: { required },
        staff_doctor: { required },
        assistant_doctor: { required },
        Nutritionist: { required },
      },
    },
  },
  components: {
    patientlist,
    "vue-timepicker": VueTimepicker,
    Loading,
    ConfirmationTemplate,
  },
  async created() {
    let userId = localStorage.getItem("userId");

    if (!this.queryId) {
      let uri = "/patients?limit=8";

      if (!this.hasAccess()) {
        uri += "&user=" + userId;
      }
      HTTP.get(uri).then((result) => {
        this.patients = result.data;
      });
    } else {
      HTTP.get("/patients/" + this.queryId).then((result) => {
        this.patients.push(result.data[0]);
        this.getConfirmationContentByLanguageId(result.data[0]?.languageId);
        this.selectPatient(this.queryId);
        this.active = "second";
      });
    }

    await this.getConfirmationTemplates();
    await this.getConfirmationContents();
    await this.getCurrencies();
    await this.getBranches();

    await HTTP.get("/sources/").then((result) => {
      this.sources = result.data;
    });

    await HTTP.get("/settings/default/daylimit").then((result) => {
      result.data.forEach((z) => (this.dayLimit[z.name] = z.transaction_limit));
    });

    await HTTP.get("/agencies/").then((result) => {
      this.agencies = result.data;
    });
    await HTTP.get("/roomtypes/").then((result) => {
      this.roomTypes = result.data;
      this.selectedRoomTypes = result.data[0];
    });
    await HTTP.get("/hotels/").then((result) => {
      this.hotels = result.data;
      this.selectedHotel = result.data[0];
    });
    await HTTP.get("/airports/").then((result) => {
      this.airports = result.data;
      this.selectedArrivalAirport = result.data[0];
      this.selectedReturnAirport = result.data[0];
    });

    await HTTP.get("/settings/customLimits/day-limit").then((result) => {
      this.customLimits = result.data;
    });

    await this.getLocations();

    await this.getOperations();

    // axios
    //   .get(
    //     "https://www.googleapis.com/calendar/v3/calendars/tr.turkish%23holiday%40group.v.calendar.google.com/events?key=AIzaSyDNEVNiP7XjXpSDVmflzit_ersKVgrZ3ls"
    //   )
    //   .then((result) => {
    //     confirmedHolidays = result.data.items.filter(
    //       (holiday) => holiday.status == "confirmed"
    //     );
    //     confirmedHolidays.forEach((holiday) => {
    //       this.holidays.push({
    //         start: holiday.start.date + " 08:00",
    //         end: holiday.end.date + " 00:00",
    //         title: holiday.summary,
    //       });
    //     });
    //   })
    //   .catch((e) => {
    //   });

    await HTTP.get("holidays").then((result) => {
      result.data.forEach((holiday) => {
        this.holidays.push({
          start: holiday.start + " " + holiday.startTime.substr(0, 5),
          end: holiday.end + " " + holiday.endTime.substr(0, 5),
          title: holiday.title,
        });
      });
    });

    await HTTP.get("/users/").then((result) => {
      let allUsers = result.data;

      this.doctors = result.data.filter((user) => user.role?.id == 2);

      this.transferManagingUsers = result.data.filter(
        (user) => user.role.id == 7
      );

      if (localStorage.getItem("roleId") == 1) {
        this.participants = this.doctors.filter((z) =>
          z.branches.map((z) => z.id).includes(this.selectedBranch?.id)
        );
      } else {
        this.participants = this.doctors.filter(
          (z) =>
            z.branches.map((z) => z.id).includes(this.selectedBranch?.id) &&
            this.userDoctors?.includes(z.id)
        );
      }
      this.options = result.data;
    });

    const getUsers = await HTTP.post("/permissions/userDatas/getUsers");
    this.users = getUsers.data;
    this.selectedUser = getUsers.data[0];

    await HTTP.get(
      "/users/" + localStorage.getItem("userId") + "/getGeneralInfos"
    ).then((result) => {
      this.profile = result.data;
      this.profile.phone = result.data.phone;
      this.profile.birthday = new Date(result.data.birthday);
      this.profile.appointmentNotification = JSON.parse(
        this.profile.appointmentNotification
      );
    });

    this.loading = false;
    this.selectedConfirmationContent.content.confirmationId.content =
      "confirmationId-" +
      this.appointment.patientId +
      "-" +
      this.appointment.phone.replace("+", "");
    this.selectedConfirmationContent.content.date.content = this.formatDate(
      new Date()
    );
    this.selectedConfirmationContent.content.nameSurname.content = this.appointment.name;
    this.selectedConfirmationContent.content.country.content = this.appointment.country.name;
    this.selectedConfirmationContent.content.phone.content = this.appointment.phone;
    this.selectedConfirmationContent.content.email.content = this.appointment.email;
    // this.selectedConfirmationContent.content.operationLocation.content = this.appointment.locationId;
    this.selectedConfirmationContent.content.staffDoctor.content = this.participantsModify;
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("datetime");
      if (myParam) {
        let date = new Date(myParam);
        this.appointment.date = date;
        this.appointmentDate = date;
        this.time = date.getHours() + ":" + date.getMinutes();
        this.appointmentDateChanged();
        this.checkHoliday();
        this.checkContinue();
      } else {
        this.time = "08:00";
      }
    }, 1000);
  },
  methods: {
    durationFromToday: function() {
      let duration = moment(this.appointment.date).diff(moment(), "days");
      // show 1 week expiration duration option only if appointment date is after 30 days
      this.show1weekOption = duration > 29;
      if (!this.show1weekOption && this.temporaryPeriod > 72)
        this.temporaryPeriod = 72;
    },
    getBranches: async function() {
      const branches = await HTTP.get("/permissions/userDatas/getBranches");
      this.branches = branches.data;
      this.selectedBranch = branches.data ? branches?.data[0] : null;
    },
    getOperations: function() {
      HTTP.get("/operations/").then((result) => {
        this.allOperations = result.data.filter(
          (operation) =>
            operation.status == true &&
            operation.branchId == this.selectedBranch.id
        );
        this.operations = result.data.filter(
          (operation) =>
            operation.status == true &&
            operation.isActivity == false &&
            operation.branchId == this.selectedBranch.id
        );
      });
    },
    getLocations: async function() {
      const locations = await HTTP.post("/permissions/userDatas/getLocations", {
        branchId: this.selectedBranch.id,
      });

      this.locations = locations?.data;
    },
    agencieName: function(patient) {
      if (!patient.agencieId) return "";
      var agencie = this.agencies.find((z) => z.id == patient?.agencieId);
      return agencie ? agencie?.name : "";
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    appointmentDateChanged: function() {
      var uri = "/appointments/";

      if (!this.appointment.date) {
        this.selectedDayAppointments = [];
        this.dayOperations = [];
        return;
      }
      const start = this.appointment.date;
      const end = this.appointment.date;

      let startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);

      let endText =
        end.getFullYear() +
        "-" +
        (end.getMonth() + 1) +
        "-" +
        ("0" + end.getDate()).slice(-2);

      uri += "?startDate=" + startText + "&endDate=" + endText;
      uri += "&branchId=" + this.selectedBranch.id;
      HTTP.get(uri).then(async (appointments) => {
        this.selectedDayAppointments = appointments.data.reverse();
        this.selectedDayAppointments = this.selectedDayAppointments.filter(
          (appointment) =>
            appointment.statusId != 3 && appointment.statusId != 4
        );

        let dayOperations = [];
        this.dayOperations = [];
        this.selectedDayAppointments.forEach((e) => {
          if (!dayOperations.includes(e.operationId)) {
            dayOperations.push(e.operationId);
          }
        });
        this.dayOperations = dayOperations;
        this.reqApprove = false;
        this.checkHoliday();
        this.checkContinue();
        this.checkLimit();
      });
    },
    isDateDisabled: function(date) {
      return moment(date).isBefore(moment().format("YYYY-MM-DD"));
    },
    hasAccess: function() {
      if (this.roleId == 1 || this.roleId == 4) {
        return true;
      }
      return false;
    },
    checkContinue: function() {
      //check if past date is added
      if (
        this.appointment.date &&
        moment(this.appointment.date).diff(moment(), "days") < 0
      ) {
        this.canContinue = false;
        this.pastDate = true;
        return;
      } else {
        this.pastDate = false;
      }

      if (this.roleId == 1) {
        // admin can make appointments even if they passed the daily limit
        this.canContinue = true;
        return;
      }

      // Check operations daily limit (weekdays and weekend)
      // getDay() result: Saturday=6, Sunday=0

      this.canContinue = true;
    },
    checkHoliday: function() {
      this.canContinue = true;
      this.isDayClosed = false;

      let params = {
        date: moment(this.appointment.date).format("YYYY-MM-DD"),
        time: this.appointment.time,
        branchId: this.selectedBranch ? this.selectedBranch.id : null,
        operationId: this.selectedOperation ? this.selectedOperation.id : null,
        doctorId: this.appointment.participants,
        duration: this.appointment.duration,
        isSurgery: this.selectedOperation
          ? this.selectedOperation.isSurgery
          : false,
        isNotSurgery: this.selectedOperation
          ? this.selectedOperation.isNotSurgery
          : false,
      };

      if (params.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkHolidayForCreate?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (result.data.closedDay) {
          this.canContinue = false;
          this.isDayClosed = true;
        }
      });
      if (!this.appointment.date) return;
      let date = moment(this.appointment.date);
      let closedDays = [];
      if (this.appointment.participants.length > 0) {
        this.appointment.participants.forEach((doctorId) => {
          var doctor = this.participants.find((z) => z.id == doctorId);
          if (doctor.closedDays) {
            closedDays = closedDays.concat(JSON.parse(doctor.closedDays));
          }
        });
      }
      if (closedDays.includes(date.day())) {
        this.isDayClosed = true;
        this.canContinue = false;
        return;
      }
    },
    checkLimit: function() {
      this.canContinue = true;
      this.operationDailyLimit = false;
      this.daysNotSurgeryLimit = false;
      this.daysLimit = false;
      this.weeklyLimitExceeded = false;
      this.userMonthlyLimitExceeded = false;
      this.userMonthlyNotSurgeryLimitExceeded = false;
      this.weeklyNotSurgeryLimitExceeded = false;
      if (!this.appointment.date) return;
      let date = moment(this.appointment.date);
      let closedDays = [0];
      let params = {
        date: moment(this.appointment.date).format("YYYY-MM-DD"),
        time: this.appointment.time,
        branchId: this.selectedBranch ? this.selectedBranch.id : null,
        operationId: this.selectedOperation ? this.selectedOperation.id : null,
        doctorId: this.appointment.participants,
        duration: this.appointment.duration,
        isSurgery: this.selectedOperation
          ? this.selectedOperation.isSurgery
          : false,
        isNotSurgery: this.selectedOperation
          ? this.selectedOperation.isNotSurgery
          : false,
      };
      // check selected operation daily limit
      if (this.selectedOperation) {
        var dateSelectedOperationCount = this.selectedDayAppointments.filter(
          (z) =>
            z.operationId == this.selectedOperation.id &&
            !["3", "4", "5"].includes(z.statusId)
        );
        if (
          this.selectedOperation.dailyLimit > 0 &&
          dateSelectedOperationCount.length >= this.selectedOperation.dailyLimit
        ) {
          this.canContinue = false;
          this.operationDailyLimit = true;
          return;
        }
      }
      // check doctors daily limits
      if (this.selectedOperation && this.appointment.participants.length > 0) {
        this.doctorNotSurgeryDailyLimit = false;
        this.doctorSurgeryDailyLimit = false;
        this.appointment.participants.forEach((userId) => {
          let doctor = this.doctors.find((z) => z.id == userId);

          var isSurgeryItems = [];
          var isNotSurgeryItems = [];
          this.selectedDayAppointments.filter((z) => {
            if (![3, 7, 5, 4]?.includes(z?.statusId)) {
              if (z.operation.isSurgery == true) {
                z.AppointmentsParticipants.filter((a) => {
                  isSurgeryItems.push(a.id);
                });
              }
              if (z.operation.isNotSurgery == true) {
                z.AppointmentsParticipants.filter((a) => {
                  isNotSurgeryItems.push(a.id);
                });
              }
            }
          });

          var newItem = {
            id: doctor.id,
            isNotSurgery: isNotSurgeryItems.filter((c) => c == userId).length,
            isSurgery: isSurgeryItems.filter((c) => c == userId).length,
          };

          if (doctor.doctorLimits.length > 0) {
            let key = this.selectedOperation.isSurgery
              ? "isSurgery"
              : this.selectedOperation.isNotSurgery
              ? "isNotSurgery"
              : null;
            let key2 = this.selectedOperation.isSurgery
              ? "surgery"
              : this.selectedOperation.isNotSurgery
              ? "notSurgery"
              : null;
            if (key) {
              if (
                newItem[key] >=
                doctor.doctorLimits.find((z) => z.type == key2)[
                  "day" + new Date(this.appointment.date).getDay()
                ]
              ) {
                if (key == "isSurgery") {
                  this.canContinue = false;
                  this.doctorSurgeryDailyLimit = true;
                  return;
                } else if (key == "isNotSurgery") {
                  this.canContinue = false;
                  this.doctorNotSurgeryDailyLimit = true;
                  return;
                }
              }
            }
          }
        });
      }
      if (this.selectedOperation) {
        var dateCustomLimit = this.customLimits.filter(
          (cl) =>
            new Date(cl.date).format("YYYY-MM-DD") ==
              new Date(this.appointment.date).format("YYYY-MM-DD") &&
            ((this.selectedOperation.isSurgery &&
              cl.operationType == "surgery") ||
              (this.selectedOperation.isNotSurgery &&
                cl.operationType == "nonSurgery"))
        );
        if (dateCustomLimit.length > 0) {
          var dayLimit = dateCustomLimit[0].limit;
        } else {
          var weekendDays = [0, 6];
          var dayLimit = weekendDays.includes(
            new Date(this.appointment.date).getDay()
          )
            ? this.selectedOperation.isSurgery
              ? this.dayLimit.weekend
              : this.dayLimit.weekendNotSurgery
            : this.selectedOperation.isSurgery
            ? this.dayLimit.weekdays
            : this.dayLimit.weekdayNotSurgery;
        }

        if (this.selectedOperation.isNotSurgery == true) {
          let dayOperationsCount = this.selectedDayAppointments.filter(
            (z) => z.operation.isNotSurgery == true
          ).length;

          if (dayOperationsCount >= dayLimit) {
            this.canContinue = false;
            this.daysNotSurgeryLimit = true;

            return;
          }
        } else if (this.selectedOperation.isSurgery == true) {
          let dayOperationsCount = this.selectedDayAppointments.filter(
            (z) => z.operation.isSurgery == true
          ).length;

          if (dayOperationsCount >= dayLimit) {
            this.canContinue = false;
            this.daysLimit = true;
            return;
          }
        }
      }

      if (params.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkLimitForCreate?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (this.roleId != 1 && this.selectedOperation) {
          if (this.selectedOperation.isSurgery) {
            if (
              result.data.userMonthlyOperations >=
              this.dayLimit.userMonthlyLimit
            ) {
              this.userMonthlyLimitExceeded = true;
              this.canContinue = false;
            }
          } else if (this.selectedOperation.isNotSurgery) {
            if (
              result.data.userMonthlyOperations >=
              this.dayLimit.userMonthlyNotSurgeryLimit
            ) {
              this.userMonthlyNotSurgeryLimitExceeded = true;
              this.canContinue = false;
            }
          }
          if (
            this.selectedBranch &&
            this.selectedBranch.weeklyLimit > 0 &&
            result.data.weeklyOperations >= this.selectedBranch.weeklyLimit
          ) {
            if (this.selectedOperation.isSurgery) {
              this.weeklyLimitExceeded = true;
              this.canContinue = false;
            } else if (this.selectedOperation.isNotSurgery) {
              this.weeklyNotSurgeryLimitExceeded = true;
              this.canContinue = false;
            }
          }
        }
      });
    },
    is_timeString(str) {
      var regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
      if (regexp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    turnBack: function(index) {
      this.active = index;
    },
    setDone: async function(id, index) {
      // this[id] = true;
      let errorMessage = "Lütfen gerekli alanları doldurunuz. <br />";

      switch (index) {
        case "second":
          if (this.appointment.patientId == null) {
            this.isValid.patient = false;
          } else {
            this.active = index;
            this.goToTop();
          }

          break;
        case "third":
          let control = [];

          if (!this.appointment.operationId) {
            this.isValid.operation = false;
            control.push("Lütfen operasyon seçiniz");
          }

          if (!this.appointment.locationId) {
            this.isValid.alocation = false;
            control.push("Lütfen konum seçiniz");
          }
          if (this.appointment.participants.length == 0) {
            this.isValid.participants = false;
            control.push("Lütfen katılımcı seçiniz");
          }

          if (
            this.appointment.duration == 0 ||
            this.appointment.duration == null
          ) {
            this.isValid.duration = false;
            control.push("Lütfen işlem süresi giriniz");
          }
          if (this.appointment.date == undefined) {
            this.isValid.date = false;
            control.push("Lütfen randevu tarihi seçiniz");
          }

          if (
            this.appointment.time == undefined ||
            this.is_timeString(this.appointment.time) == false
          ) {
            this.isValid.time = false;
            control.push("Lütfen randevu saati seçiniz");
          }

          if (control?.length > 0) {
            for (let i in control) {
              errorMessage += "* " + control[i] + "<br/>";
            }

            this.$swal("Uyarı", errorMessage, "warning");
          } else if (!this.appointment.operationId) {
            this.isValid.operation = false;
          } else if (!this.appointment.locationId) {
            this.isValid.alocation = false;
          } else if (this.appointment.participants.length == 0) {
            this.isValid.participants = false;
          } else if (
            this.appointment.duration == 0 ||
            this.appointment.duration == null
          ) {
            this.isValid.duration = false;
          } else if (this.appointment.date == undefined) {
            this.isValid.date = false;
          } else if (
            this.appointment.time == undefined ||
            this.is_timeString(this.appointment.time) == false
          ) {
            this.isValid.time = false;
          } else {
            const isDiscountedDialog = await this.$swal({
              title: "İndirim Tercihi?",
              text: "Bu randevuda indirim kullanıldı mı ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#ff5252",
              confirmButtonText: "Evet",
              cancelButtonText: "Hayır",
            });

            if (isDiscountedDialog?.isConfirmed) {
              this.appointment.isDiscounted = true;
            } else {
              this.appointment.isDiscounted = false;
            }

            this.goToTop();
            this.active = index;
          }

          break;
        case "four":
          if (this.confirmationTemplateValidation) {
            for (let i in this.confirmationTemplateValidation) {
              errorMessage +=
                "* " +
                this.confirmationTemplateValidation[i]?.message +
                "<br/>";
            }

            this.$swal("Uyarı", errorMessage, "warning");
          } else {
            this.goToTop();
            this.active = index;
          }
          break;
      }
    },
    setTime: function(item, index) {
      this.selectedTime.item = item;
      this.selectedTime.index = index;
    },
    addPatient: function() {
      if (this.newPatient.agencieId != null) {
        this.newPatient.agencieId = this.newPatient.agencieId.id;
      }
      if (this.newPatient.source != null) {
        this.newPatient.source = this.newPatient.source.name;
      }

      if (this.newPatient.name == "") {
        this.isValid.name = false;
      } else {
        HTTP.post("/patients/", this.newPatient).then((result) => {
          // router.push({ name: "patients" });
          if (result.status == 201) {
            HTTP.get("/patients/?limit=8").then((patients) => {
              this.patients = patients.data;
            });
            let id = result.data.id;
            setTimeout(() => {
              this.selectPatient(id);
            }, 700);
            this.newPatient = {};
          }
        });
      }
    },
    selectPatient: function(patientId) {
      if (this.patients.length > 0) {
        this.selectedPatient = this.patients.filter(
          (patient) => patient.id == patientId
        )[0];
      } else {
        this.selectedPatient = this.patients;
      }

      this.appointment.patientId = this.selectedPatient.id;
      this.appointment.name = this.selectedPatient.name;
      this.appointment.email = this.selectedPatient.email;
      this.appointment.phone = this.selectedPatient.phone;
      this.appointment.sex = this.selectedPatient.sex;
      this.appointment.birthday = this.selectedPatient.birthday;
      this.appointment.country = this.selectedPatient.country;
      this.appointment.source = this.selectedPatient.source;
      this.appointment.agencieId = this.selectedPatient.agencieId;
      this.appointment.userId = this.selectedPatient.userId
        ? this.selectedPatient.userId
        : localStorage.getItem("userId");

      if (this.userId == this.selectedPatient.secondUserId)
        this.appointment.userId = this.selectedPatient.secondUserId;
    },
    searchPatient: function(q) {
      let userId = localStorage.getItem("userId");
      let uri = "/patients/?limit=8";
      if (!this.hasAccess()) {
        uri += "&user=" + userId;
      }
      HTTP.get(uri + "&q=" + q).then((patients) => {
        this.patients = patients.data;
      });
    },
    createAppointment: function() {
      /*eslint-disable*/

      if (this.buttonTwoClickControl == 0) this.buttonTwoClickControl++;

      if (this.convertPatient && false) {
        // disable comfirmation form TEMPORARLY
        this.$v.appointment.confirmation.$touch();

        if (!this.$v.appointment.confirmation.$error) {
          localStorage.setItem("isPDFProcessingC", true);
          this.appointment.confirmation.patientName = this.appointment.name;
          this.appointment.confirmation.patientPhone = this.appointment.phone;
          this.appointment.confirmation.patientEmail = this.appointment.email;
          this.appointment.confirmation.patientCountry = this.appointment.country;
          this.appointment.confirmation.date = this.dateModify;
          this.appointment.confirmation.time = this.appointment.time;
          this.appointment.confirmation.operation_location = this.LocationModify;

          localStorage.setItem(
            "confirmation",
            JSON.stringify(this.appointment.confirmation)
          );

          confirmationForm(false, true);
          this.isPDFProcessingC = true;
          var checkPDF = setInterval(() => {
            if (localStorage.getItem("isPDFProcessingC") == "false") {
              this.isPDFProcessingC = false;
              let fd = new FormData();
              // this.appointment.confirmation = JSON.parse(
              //   localStorage.getItem("confirmation")
              // );
              fd.append("files", CONFIRMATION_FORM, "Onay Formu");
              fd.append(
                "treatment_package",
                this.appointment.confirmation.treatment_package
                  ? this.appointment.confirmation.treatment_package
                  : ""
              );
              fd.append(
                "inc_price",
                this.appointment.confirmation.inc_price
                  ? this.appointment.confirmation.inc_price
                  : ""
              );
              fd.append(
                "arrival_airport",
                this.appointment.confirmation.arrival_airport
                  ? this.appointment.confirmation.arrival_airport.name
                  : ""
              );
              fd.append(
                "arrival_date",
                this.appointment.confirmation.arrival_date
                  ? this.appointment.confirmation.arrival_date
                  : null
              );
              fd.append(
                "arrival_time",
                this.appointment.confirmation.arrival_time
                  ? this.appointment.confirmation.arrival_time.HH +
                      ":" +
                      this.appointment.confirmation.arrival_time.mm
                  : ""
              );
              fd.append(
                "return_airport",
                this.appointment.confirmation.return_airport
                  ? this.appointment.confirmation.return_airport.name
                  : null
              );
              fd.append(
                "return_date",
                this.appointment.confirmation.return_date
                  ? this.appointment.confirmation.return_date
                  : null
              );
              fd.append(
                "return_time",
                this.appointment.confirmation.return_time
                  ? this.appointment.confirmation.return_time.HH +
                      ":" +
                      this.appointment.confirmation.return_time.mm
                  : ""
              );
              fd.append(
                "isVip",
                this.appointment.confirmation.isVip
                  ? this.appointment.confirmation.isVip
                  : false
              );
              fd.append(
                "transfer_managing_fullname",
                this.appointment.confirmation.transfer_managing_fullname
                  ? this.appointment.confirmation.transfer_managing_fullname
                  : null
              );
              fd.append(
                "airport_to_hotel_hospital",
                this.appointment.confirmation.airport_to_hotel_hospital
                  ? this.appointment.confirmation.airport_to_hotel_hospital
                  : null
              );
              fd.append(
                "hospital_to_hotel_address",
                this.appointment.confirmation.hospital_to_hotel_address
                  ? this.appointment.confirmation.hospital_to_hotel_address
                  : null
              );
              fd.append(
                "hotel_to_airport",
                this.appointment.confirmation.hotel_to_airport
                  ? this.appointment.confirmation.hotel_to_airport
                  : null
              );
              fd.append(
                "hotel_name",
                this.appointment.confirmation.hotel_name
                  ? this.appointment.confirmation.hotel_name.name
                  : null
              );
              fd.append(
                "hotel_days",
                this.appointment.confirmation.hotel_days
                  ? this.appointment.confirmation.hotel_days
                  : null
              );
              fd.append(
                "room_type",
                this.appointment.confirmation.room_type
                  ? this.appointment.confirmation.room_type.name
                  : ""
              );
              fd.append(
                "check_in",
                this.appointment.confirmation.check_in
                  ? this.appointment.confirmation.check_in
                  : null
              );
              fd.append(
                "check_out",
                this.appointment.confirmation.check_out
                  ? this.appointment.confirmation.check_out
                  : null
              );
              fd.append(
                "treatment_date",
                this.appointment.confirmation.date
                  ? this.appointment.confirmation.date
                  : null
              );
              fd.append(
                "treatment_time",
                this.appointment.confirmation.time
                  ? this.appointment.confirmation.time
                  : null
              );
              fd.append(
                "operation_location",
                this.appointment.confirmation.operation_location
                  ? this.appointment.confirmation.operation_location
                  : null
              );
              fd.append(
                "staff_doctor",
                this.appointment.confirmation.staff_doctor
                  ? this.appointment.confirmation.staff_doctor.name
                  : null
              );
              fd.append(
                "assistant_doctor",
                this.appointment.confirmation.assistant_doctor
                  ? this.appointment.confirmation.assistant_doctor.name
                  : null
              );
              fd.append(
                "Nutritionist",
                this.appointment.confirmation.Nutritionist
                  ? this.appointment.confirmation.Nutritionist.name
                  : null
              );
              fd.append("patientId", this.$route.query.id);
              HTTP.post("appointments/confirmation", fd, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              })
                .then((res) => {
                  this.setAppointment();
                })
                .catch((e) => {
                  //
                });
              clearInterval(checkPDF);
            }
          }, 500);
        }
      } else {
        this.setAppointment();
      }

      /*eslint-disable*/
    },
    setAppointment: async function() {
      this.loading = true;

      let start = new Date(this.appointment.date);
      let startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);
      this.appointment.date = startText;
      this.appointment.convertPatient = this.convertPatient;
      this.appointment.userId = this.selectedUser.id;
      if (this.temporaryAppointment) {
        this.appointment.statusId = 6;
      } else if (this.depositPaid) {
        this.appointment.statusId = 8;
      }

      if (this.reqApprove) {
        this.appointment.statusId = 7;
      }

      const { data: appointment } = await HTTP.post(
        "/appointments/",
        this.appointment
      );

      const fileResponse = await HTTP.post("/patientFiles", {
        patientId: this.appointment.patientId,
        fileContent: {
          name:
            appointment?.patientId +
            "-" +
            this.appointment.name +
            "-" +
            appointment.date +
            "-" +
            appointment.time,
          slug: this.createSlugName(
            appointment?.patientId +
              "-" +
              this.appointment.name +
              "-" +
              appointment.date +
              "-" +
              appointment.time
          ),
          content: JSON.stringify(this.selectedConfirmationContent.content),
          fileTypeId: 10,
          languageId: this.selectedConfirmationContent.languageId,
          templateId: this.selectedConfirmationTemplate.id,
          appointmentId: appointment.id,
          confirmationContentId: this.selectedConfirmationContent.id,
        },
      });

      if (fileResponse.status == 201) {
        const appointmentGuid = appointment.guid;

        router.push({
          name: "AppointmentsList",
          query: { date: this.appointment.date },
        });

        const route = this.$router.resolve({
          name: "appoinmentDetailView",
          params: { guid: appointmentGuid },
        });

        window.open(route.href, "_blank");
      }
    },
    preview: function() {
      this.$v.appointment.confirmation.$touch();

      if (!this.$v.appointment.confirmation.$error) {
        localStorage.setItem("isPDFProcessingC", true);
        this.isPDFProcessingC = true;
        this.appointment.confirmation.patientName = this.appointment.name;
        this.appointment.confirmation.patientPhone = this.appointment.phone;
        this.appointment.confirmation.patientEmail = this.appointment.email;
        this.appointment.confirmation.patientCountry = this.appointment.country;
        this.appointment.confirmation.date = this.dateModify;
        this.appointment.confirmation.time = this.appointment.time;
        this.appointment.confirmation.operation_location = this.LocationModify;

        localStorage.setItem(
          "confirmation",
          JSON.stringify(this.appointment.confirmation)
        );
        /*eslint-disable*/
        confirmationForm(true, false);
        /*eslint-disable*/

        var checkPDF = setInterval(() => {
          if (localStorage.getItem("isPDFProcessingC") == "false") {
            this.isPDFProcessingC = false;
            clearInterval(checkPDF);
          }
        }, 500);
      }
    },
    buttonVisibleControl() {
      if (
        this.userMonthlyLimitExceeded ||
        this.operationDailyLimit ||
        this.daysLimit ||
        this.isDayClosed ||
        this.doctorSurgeryDailyLimit ||
        this.doctorNotSurgeryDailyLimit
      ) {
        console.log("DEBUG", this.reqApprove);
        if (this.reqApprove) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getConfirmationTemplates() {
      HTTP.get("/confirmationTemplates/").then((result) => {
        this.confirmationTemplates = result.data;
        this.selectedConfirmationTemplate = result.data[0];
      });
    },
    getConfirmationContents() {
      HTTP.get("/confirmationContents/").then((result) => {
        let contents = result.data;

        for (let i in contents) {
          contents[i].content = JSON.parse(contents[i].content);
        }

        this.allConfirmationContents = contents;
        this.selectedConfirmationContent = this.allConfirmationContents[0];
      });
    },
    getLocationName(id) {
      return this.locations.find((x) => x.id == id).name;
    },
    getParticipantsName(id) {
      return this.participants.find((x) => x.id == id).name;
    },
    getCurrencies() {
      HTTP.get("currency").then((currencies) => {
        this.allCurrencies = currencies.data;
        this.selectedCurrencies = currencies.data[0];
      });
    },
    exportToPDF() {
      html2pdf(this.$refs.pdfContent, {
        margin: 0,
        filename: this.appointment.name + ".pdf",
        pdfContentWidth: 800,
        image: { type: "png", quality: 0.5 },
        html2canvas: { dpi: 300, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    createSlugName(text) {
      return text
        .toLowerCase()
        .replaceAll("ı", "i")
        .replaceAll("ş", "s")
        .replaceAll("ü", "u")
        .replaceAll("ğ", "g")
        .replaceAll("ö", "o");
    },
    selectedDayOperationsValues(dayOperation) {
      let data = this.allOperations?.filter((op) => op?.id == dayOperation)[0]
        ?.name;
      if (data) {
        return data;
      } else {
        return "";
      }
    },
    getConfirmationContentByLanguageId(id) {
      HTTP.get("/confirmationContents/language/" + id).then((res) => {
        let content = res?.data;
        if (res?.data) {
          content.content = JSON.parse(content?.content);
          this.selectedConfirmationContent = content;
        }
      });
    },
    confirmationTemplateValidationMethod(val) {
      this.confirmationTemplateValidation = val;
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async appointmentCreationLimitControl() {
      const appointmentDate = this?.appointmentDate;
      const appointmentTime = this?.time;
      const selectedUserId = this?.selectedUser?.id;
      const selectedOperationId = this?.selectedOperation?.id;
      const selectedDoctorId = this?.appointment?.participants[0];

      this.appointmentCreationLimitControlResponse = {
        isSuccess: null,
        message: "Limit kontrolü yapılıyor...",
      };

      if (
        appointmentDate &&
        appointmentTime &&
        selectedUserId &&
        selectedOperationId &&
        selectedDoctorId
      ) {
        const response = await HTTP.post(
          "/limit/appointmentCreationLimitControl",
          {
            date: appointmentDate,
            time: appointmentTime,
            userId: selectedUserId,
            operationId: selectedOperationId,
            doctorId: selectedDoctorId,
          }
        );

        this.appointmentCreationLimitControlResponse = response.data;
      } else {
        this.appointmentCreationLimitControlResponse = {
          isSuccess: null,
          message:
            "Gerekli bilgiler girildiğinde limit kontrolü başlayacaktır.",
        };
      }
    },
  },
  computed: {
    dateModify: function() {
      if (this.appointment.date) {
        let date = this.appointment.date;
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDay();
        let dateString = year + "-" + month + "-" + day;
        // this.appointment.date.toLocaleDateString();
        return dateString;
      }
      return null;
    },
    participantsModify: function() {
      let participantsValue = [];
      let result = "";
      if (this.appointment.participants) {
        participantsValue = this.participants.filter((participant) =>
          this.appointment.participants.includes(participant.id)
        );
        participantsValue.forEach((participant) => {
          result += participant.name + " - ";
        });
        return result.substr(0, result.length - 3);
      }
      return null;
    },
    LocationModify: function() {
      let location = "";
      if (this.appointment.locationId) {
        location = this.locations.filter(
          (location) => location.id == this.appointment.locationId
        )[0];
      }
      return location.name;
    },
  },
  watch: {
    search_query: function(query) {
      setTimeout(() => {
        this.searchPatient(query);
      }, 300);
    },
    selectedBranch: function() {
      this.getOperations();
      this.appointment.doctor = {};
      this.selectedOperation = null;
      this.participants = this.doctors.filter(
        (z) =>
          z.branches.map((z) => z.id).includes(this.selectedBranch.id) &&
          this.userDoctors.includes(z.id)
      );
      this.appointment.participants = [];
      this.appointment.locationId = null;
      this.appointmentDateChanged();
      this.getLocations();
    },
    "appointment.duration": function() {
      this.checkHoliday();
      this.checkContinue();
      this.checkLimit();
    },
    "newPatient.name": function(value) {
      if (value.length > 0) {
        this.isValid.name = true;
      } else {
        this.isValid.name = false;
      }
    },
    "appointment.isDiscounted": async function(value) {
      if (!value || !this.selectedBranch.id || !this.selectedOperation.id) {
        this.isValid.isDiscounted = false;
        return false;
      }
      this.loading = true;
      const { data: hasLimit } = await HTTP.get(
        `/branches/${this.selectedBranch.id}/has-limit?operationId=${
          this.selectedOperation.id
        }&month=${moment(this.appointment.date).format("M")}&type=${
          this.selectedOperation.isSurgery ? "withSurgery" : "nonSurgery"
        }`
      );
      this.loading = false;
      if (!hasLimit) {
        this.$swal({
          title: "İndirimli İşlem Limit Hakkınız Bulunmamaktadır!",
          text: "Yönetici Onayı İster misiniz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff5252",
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then((result) => {
          if (result.isConfirmed) {
            this.isValid.isDiscounted = true;
            this.appointment.isDiscounted = true;
            this.reqApprove = true;
          } else {
            this.isValid.isDiscounted = false;
            this.appointment.isDiscounted = false;
            this.reqApprove = false;
          }
        });
      }
    },
    // active: function () {
    //   if (this.active == "third") {
    //     this.appointment.confirmation.treatment_package = this.selectedOperationsName;
    //     HTTP.get("offers/id/" + this.$route.query.offerId).then((res) => {
    //       this.appointment.confirmation.inc_price =
    //         res.data[0].totalPrice + " " + res.data[0].currencySlug;
    //     });
    //   }
    // },
    selectedDayAppointments: function() {
      var operations = this.operations
        .filter((z) => z.isSurgery)
        .map((z) => z.id); // only these operations are being considered as "Ameliyat" to be count in the calander counter
      this.dayProcess = this.selectedDayAppointments.filter(
        (op) =>
          operations.includes(op.operationId) &&
          op.statusId != 4 &&
          op.statusId != 3 &&
          op.statusId != 7
      ).length;
    },
    reqApprove: function() {
      this.appointment.statusId = this.reqApprove ? 7 : 1;
      if (this.reqApprove) this.temporaryConversion = false;
    },
    byWhatsapp: function() {
      this.appointment.notifyByWhatsapp = this.byWhatsapp;
    },
    byEmail: function() {
      this.appointment.notifyByEmail = this.byEmail;
    },
    notificationDate: function() {
      let date = moment(
        moment(this.notificationDate).format("YYYY-MM-DD") +
          " " +
          this.notificationTime +
          ":00"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.appointment.notificationDate = date;
      //
      //

      //
    },
    notificationTime: function() {
      let date = moment(
        moment(this.notificationDate).format("YYYY-MM-DD") +
          " " +
          this.notificationTime +
          ":00"
      ).format("YYYY-MM-DD HH:mm:ss");
      this.appointment.notificationDate = date;
      //
      //
      //
    },
    notifyMe: function() {
      // this.notificationDate = new Date().getTime().toISOString()
      this.appointment.notify_Me = this.notifyMe;
      this.byEmail = this.notifyMe ? this.byEmail : false;
      this.byWhatsapp = this.notifyMe ? this.byWhatsapp : false;
      this.appointment.notifyByWhatsapp = this.byWhatsapp;
      this.appointment.notifyByEmail = this.byEmail;
      this.notificationDate = this.appointment.date;
      this.appointment.notificationDate = this.notificationDate;
    },
    selectedUser: function() {
      this.selectedConfirmationContent.content.userNameSurname.content = this.selectedUser?.role?.name
      this.selectedConfirmationContent.content.userNameSurname.label = this.selectedUser.name;
      this.selectedConfirmationContent.content.userNameSurname.phone = this.selectedUser.phone;
      this.selectedConfirmationContent.content.userNameSurname.image = this
        .selectedUser.profileImg
        ? this.storagepath + this.selectedUser.profileImg
        : "/images/avatar.jpg";

      this.appointmentCreationLimitControl();
    },
    selectedOperationsName: function() {
      this.selectedConfirmationContent.content.treatmentPackage.content = this.selectedOperationsName;
    },
    appointmentDate: function(val) {
      if (this.appointmentDate instanceof Date || !this.appointmentDate) {
        this.appointment.date = this.appointmentDate;
        console.log(new Date(this.appointmentDate).getTime());

        if (isNaN(this.appointmentDate))
          alert("invalid format2: " + this.appointmentDate);
      } else {
        var appDate = this.appointmentDate;
        var app = this.appointmentDate?.split("-");
        appDate = app[1] + "-" + app[0] + "-" + app[2];

        if (isNaN(new Date(appDate)))
          alert("invalid format: " + this.appointmentDate);

        this.appointment.date = new Date(appDate);
      }
      this.durationFromToday();
      this.appointmentDateChanged();

      this.selectedConfirmationContent.content.treatmentDateTime.content =
        this.formatDate(this.appointmentDate) + " - " + this.time;

      this.appointmentCreationLimitControl();
    },
    time: function(val) {
      this.appointment.time = this.time;
      console.log(val);
      this.checkHoliday();
      this.checkContinue();
      this.checkLimit();

      this.selectedConfirmationContent.content.treatmentDateTime.content =
        this.formatDate(this.appointmentDate) + " - " + this.time;

      this.appointmentCreationLimitControl();
    },
    selectedOperation: function() {
      // let selectedOperation = this.operations.filter(
      //   operation => operation.id == this.appointment.operationId
      // )[0];
      this.appointment.operationId = this.selectedOperation.id;
      this.appointment.duration = this.selectedOperation.duration;
      this.selectedOperationsName = this.selectedOperation.name;

      this.reqApprove = false;
      this.checkHoliday();
      this.checkContinue();
      this.checkLimit();
      this.appointmentCreationLimitControl();
    },
    "appointment.locationId": function() {
      // this.selectedConfirmationContent.content.operationLocation.content = this.getLocationName(
      //   this.appointment.locationId
      // );
    },
    "appointment.doctor": function(val) {
      this.appointment.participants = [val];
      this.loading = true;
      HTTP.get("/confirmationTemplates/doctor/" + val).then((res) => {
        this.selectedConfirmationTemplate = res?.data;
        this.loading = false;
      });
    },
    "appointment.participants": function(val) {
      this.checkHoliday();
      this.checkContinue();
      this.checkLimit();
      this.selectedConfirmationContent.content.staffDoctor.content = this.participantsModify;
      this.appointmentCreationLimitControl();
    },
    "selectedConfirmationContent": function() {
      this.selectedConfirmationContent.content.userNameSurname.content = this.selectedUser.role.name;
      this.selectedConfirmationContent.content.userNameSurname.label = this.selectedUser.name;
      this.selectedConfirmationContent.content.userNameSurname.phone = this.selectedUser.phone;
      this.selectedConfirmationContent.content.treatmentPackage.content = this.selectedOperationsName;
      this.selectedConfirmationContent.content.confirmationId.content =
        "confirmationId-" +
        this.appointment.patientId +
        "-" +
        this.appointment.phone.replace("+", "");
      this.selectedConfirmationContent.content.nameSurname.content = this.appointment.name;
      this.selectedConfirmationContent.content.country.content = this.appointment.country.name;
      this.selectedConfirmationContent.content.phone.content = this.appointment.phone;
      this.selectedConfirmationContent.content.email.content = this.appointment.email;
      this.selectedConfirmationContent.content.treatmentDateTime.content =
        this.formatDate(this.appointmentDate) + " - " + this.time;
      // this.selectedConfirmationContent.content.operationLocation.content = this.getLocationName(
      //   this.appointment.locationId
      // );

      //-----------------
      this.selectedConfirmationContent.content.staffDoctor.content = this.participantsModify;
      //-----------------
    },
    reqApprove(value) {
      if (value) {
        this.temporaryAppointment = false;
        this.reqApprove = true;
      }
    },
    temporaryAppointment(value) {
      if (value) {
        this.reqApprove = false;
        this.temporaryAppointment = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

.md-steppers {
  .md-steppers-navigation {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    background: $white;
  }
}

.md-stepper {
  // margin-top: 30px;
  padding: 0 !important;
}

.md-steppers.md-theme-default {
  background: none !important;
}

.box {
  background: $white;
  padding: 15px !important;
  margin: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.inner-box {
  background: $white;
  padding: 30px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.md-stepper-content {
  margin: 20px 0px 15px 0px;
}

.patient-wrap {
  min-width: calc(50% - 9.5px);
  max-width: calc(50% - 9.5px);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background: darken($white, 5%);
  }

  &:nth-child(even) {
    margin-right: 0px;
  }
}

.step-inner {
  margin-top: 0px;
}

.btn-wrap {
  float: right;
}

.btn-right {
  float: right;
  margin-right: 0 !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 5px !important;

  .btn-icon {
    margin-top: -3px !important;
    padding: 0 !important;
  }
}

.time-table {
  // display: inline-block;
}

.timeSelect {
  max-height: 410px;

  .md-scrollbar {
    max-height: 410px !important;
  }

  overflow: hidden !important;
}

.last-step {
  .md-size-100 {
    text-align: right;
  }
}

.little-table {
  .md-layout {
    margin: 10px 0px 10px 0px;
  }

  .md-size-15,
  .md-size-30 {
    font-weight: bold;
  }
}

.selectedpatient {
  border: 1px solid $brandcolor;
  position: relative;

  &:after {
    content: "\2713";
    color: $brandcolor;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.patient-list {
  margin-top: 10px;
}

.newPatient {
  opacity: 0.4;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
}

.activeTime {
  background: darken($white, 5%) !important;
  // border: 1px solid #f00;
}

.selectTime {
  width: 10px;
  margin: 0;
  padding: 0;

  .md-radio {
    margin: 0;
  }
}

.selectedDayOperations {
  margin-bottom: 15px;

  div {
    margin-bottom: 10px;
  }

  .number {
    width: 20px;
    height: 20px;
    text-align: center;
    background: $brandcolor;
    border-radius: 50%;
    margin-right: 5px;
    color: $white;
    display: inline-block;
  }

  h5 {
    margin: 0;
    margin-bottom: 10px;
  }
}

.time-picker {
  width: 100% !important;

  input {
    width: 100% !important;
    border: none !important;
    z-index: 99;
  }

  .dropdown {
    z-index: 9;
  }
}

.confirmation {
  text-align: left !important;

  .md-layout-item {
    text-align: left !important;
  }
}

@media (max-width: 767.98px) {
  .md-layout-item.box {
    padding: 15px !important;
  }

  .md-card-content.desc {
    padding: 0;
  }

  #form-container-continue-button {
    margin-bottom: -200px;
  }
}

@media (min-width: 768px) {
  .hidden-big {
    display: none;
  }
}

.confirmation-form-category-title {
  font-size: 19px;
  font-weight: 300;
  font-family: sans-serif;
  margin-top: 40px;
}
</style>
