<template>
  <div>
    <h1>{{ title }}</h1>
    <Loading v-if="loading" />
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step
        id="first"
        md-label="Randevu Düzenle 1/4"
        md-description="Kişisel Bilgiler & Hasta Seçimi"
        :md-done.sync="first"
      >
        <div class="md-layout md-gutter step-inner">
          <div class="md-layout-item box">
            <span class="md-title">Seçili Hasta</span>
            <div class="md-layout patient-list">
              <patientlist
                class="md-layout-item patient-wrap"
                v-for="patient in patients"
                :name="patient.name"
                :country="patient.country"
                :phone="patient.phone"
                :imgUrl="patient.imgUrl"
                :key="patient.id"
                :id="patient.id"
                v-bind:class="{
                  selectedpatient: selectedPatient.id == patient.id,
                }"
                v-on:click.native="selectPatient(patient.id)"
              ></patientlist>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="inner-box newPatient"></div>
            <md-button
              class="md-raised md-primary btn-right"
              @click="setDone('first', 'second')"
            >
              Devam
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>

      <md-step
        id="second"
        md-label="Randevu Düzenle 2/4"
        md-description="İşlem Tipi, Tarih & Katılımcılar"
        :md-error="secondStepError"
        :md-done.sync="second"
      >
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <div class="inner-box">
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-100">
                  <span v-if="isCompleted" class="md-error"
                    >Randevu
                    {{ formatDate(this.resultHolder.updatedAt, true) }}
                    tarihinde tamamlandıgı için güncellemeye kapanmıştır.</span
                  >
                </div>
                <div class="md-layout-item md-size-50">
                  <span class="md-title">Tarih & Saat</span>
                  <md-datepicker
                    @md-opened="canContinue = false"
                    @md-closed="canContinue = false"
                    v-if="!isCompleted"
                    v-model="appointment.date"
                    md-immediately
                    :md-disabled-dates="isDateDisabled"
                  >
                    <label>Randevu Tarihi</label>
                  </md-datepicker>
                  <md-field v-if="isCompleted">
                    <span>{{ formatDate(this.appointment.date, false) }}</span>
                  </md-field>
                  <span class="md-error" v-if="this.isValid.date === false"
                    >Randevi tarihi seçmelisiniz</span
                  >
                </div>
                <div class="md-layout-item md-size-50">
                  <span class="md-title">Randevu Saati</span>
                  <md-field>
                    <vue-timepicker
                      format="HH:mm"
                      :hour-range="[
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20,
                      ]"
                      :minute-interval="5"
                      placeholder="SS:dd"
                      v-model="appointment.time"
                      close-on-complete
                      hide-disabled-hours
                      v-bind:disabled="isCompleted"
                    ></vue-timepicker>
                  </md-field>
                  <span class="md-error" v-if="this.isValid.time === false"
                    >Randevi saati seçmelisiniz</span
                  >
                </div>

                <div
                  class="md-layout"
                  v-if="
                    !canContinue ||
                      reqApprove ||
                      (isValid.isDiscounted && reqApprove)
                  "
                >
                  <md-checkbox v-model="reqApprove"
                    >Yönetici onayı iste</md-checkbox
                  >
                </div>

                <!-- SelectedDay Events -->
                <div v-if="this.appointment.date" class="selectedDayOperations">
                  <h5>Seçili Güne Ait Operasyonlar</h5>

                  <div
                    v-for="(dayOperation, index) in dayOperations"
                    :key="index"
                  >
                    <span class="number">{{
                      selectedDayAppointments.filter(
                        (e) => e.operationId == dayOperation
                      ).length
                    }}</span>
                    {{
                      operations.filter((op) => op.id == dayOperation)[0].name
                    }}
                  </div>
                </div>
                <!-- <span class="md-error" v-if="!canContinue && daysLimit">
                  Bu güne daha fazla Ameliyat giremezsiniz, lütfen başka bir gün
                  seçiniz.
                  <br />
                </span>

                <span
                  class="md-error"
                  v-if="!canContinue && operationDailyLimit"
                >
                  Seçtiğiniz işlem günlük üst limite ulaşmış.
                </span>
                <span
                  class="md-error"
                  v-if="!canContinue && userMonthlyLimitExceeded"
                >
                  Kullanıcı aylık ameliyat sayısı üst limite ulaşmış.
                </span>

                <span class="md-error" v-if="!canContinue && pastDate">
                  Geçmiş tarih seçtiniz. Lütfen Başka bir gün seçiniz.
                </span>

                <span class="md-error" v-if="!canContinue && isDayClosed">
                  Seçili gün kapalı olarak işaretlenmiş. Lütfen başka bir gün
                  seçiniz.
                </span>

                <span
                  class="md-error"
                  v-if="!canContinue && weeklyLimitExceeded"
                >
                  Haftalık ameliyat sayısı üst limite ulaşmış.
                </span>
                <span
                  class="md-error"
                  v-if="!canContinue && daysNotSurgeryLimit"
                >
                  Bu güne daha fazla Ameliyatsız İşlem giremezsiniz, lütfen
                  başka bir gün seçiniz.
                  <br />
                </span>
                <span
                  class="md-error"
                  v-if="!canContinue && doctorNotSurgeryDailyLimit"
                >
                  Seçtiğiniz doktorun günlük ameliyatsız işlem limiti dolmuştur.
                </span>
                <span
                  class="md-error"
                  v-if="!canContinue && doctorSurgeryDailyLimit"
                >
                  Seçtiğiniz doktorun günlük ameliyatlı işlem limiti dolmuştur.
                </span>
                <span
                  class="md-error"
                  v-if="!canContinue && weeklyNotSurgeryLimitExceeded"
                >
                  Haftalık ameliyatsız sayısı üst limite ulaşmış.
                </span>

                <span
                  class="md-error"
                  v-if="!canContinue && userMonthlyNotSurgeryLimitExceeded"
                >
                  Kullanıcı aylık ameliyatsız işlem üst limite ulaşmış.
                </span> -->

                <span
                  :class="
                    appointmentCreationLimitControlResponse.isSuccess
                      ? 'md-success'
                      : 'md-error'
                  "
                >
                  {{ appointmentCreationLimitControlResponse.message }}
                </span>

                <md-content class="timeSelect">
                  <md-table class="time-table">
                    <md-table-row
                      v-for="(dayAppointment, index) in selectedDayAppointments"
                      v-bind:key="index"
                    >
                      <md-table-cell md-label="Saat" class="selectTime">{{
                        dayAppointment.time.substr(0, 5)
                      }}</md-table-cell>
                      <md-table-cell md-label="Operasyon">{{
                        dayAppointment.operation.name
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </md-content>
              </div>

              <!-- SelectedDay Events End -->

              <div v-if="screenWidth > 769" class="btn-wrap">
                <md-button
                  class="md-raised md-secondary"
                  v-on:click="turnBack('first')"
                  >Geri Dön</md-button
                >
                <!-- !canContinue && doctorSurgeryDailyLimit -->
                <md-button
                  class="md-raised md-primary btn-right"
                  v-on:click="setDone('second', 'third')"
                  v-if="
                    appointmentCreationLimitControlResponse.isSuccess ||
                      reqApprove
                  "
                  v-bind:disabled="isCompleted"
                >
                  Devam
                  <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
                </md-button>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="inner-box">
              <span class="md-title">İşlem Tipi</span>

              <md-field v-if="temsilciVisibleControl()">
                <label>Temsilci</label>
                <md-select v-model="selectedUser.id" placeholder="Temsilci">
                  <md-option
                    v-for="(user, index) in users"
                    v-bind:value="user.id"
                    v-bind:key="index"
                    >{{ user.name }}</md-option
                  >
                </md-select>
              </md-field>

              <md-field>
                <v-select
                  v-model="selectedBranch"
                  :clearable="false"
                  placeholder="Branş"
                  :options="branches"
                  label="name"
                  class="md-menu"
                  disabled="true"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>
              <md-field>
                <label>İşlem</label>
                <!-- <v-select
                  :clearable="false"
                  v-model="appointment.operationId"
                  placeholder="İşlem"
                  :options="operations"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select> -->
                <md-select
                  v-model="appointment.operationId"
                  placeholder="İşlem"
                  v-bind:disabled="isCompleted"
                >
                  <md-option
                    v-for="(operation, index) in filterByBranch(operations)"
                    v-bind:value="operation.id"
                    v-bind:key="index"
                    >{{ operation.name }}</md-option
                  >
                </md-select>
              </md-field>

              <md-field>
                <label>Konum</label>
                <md-select
                  v-model="appointment.locationId"
                  placeholder="Konum"
                  v-bind:disabled="isCompleted"
                >
                  <md-option
                    v-for="location in locations"
                    v-bind:value="location.id"
                    v-bind:key="location.id"
                    >{{ location.name }}</md-option
                  >
                </md-select>
              </md-field>

              <md-field>
                <label>Katılımcılar</label>
                <md-select
                  v-model="selectedDoctor"
                  name="participants"
                  id="participants"
                  placeholder="Katılımcılar"
                  v-bind:disabled="isCompleted"
                >
                  <md-option
                    v-for="participant in participants"
                    v-bind:value="participant.id"
                    v-bind:key="participant.id"
                    >{{ participant.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error" v-if="this.isValid.participants === false"
                >Katılımcı seçmelisiniz</span
              >
              <md-field>
                <label>İşlem Süresi</label>
                <md-input
                  v-model="appointment.duration"
                  placeholder="İşlem Süresi"
                  v-bind:disabled="isCompleted"
                ></md-input>
              </md-field>
              <span class="md-error" v-if="this.isValid.duration === false"
                >İşlem Süresi Girmelisiniz</span
              >
              <md-checkbox
                v-model="appointment.isDiscounted"
                :disabled="
                  !selectedBranch || !selectedOperation || !appointment.date
                "
                >İndirim Uygulandi</md-checkbox
              >
            </div>
            <div class="inner-box">
              <span class="md-title">Hastaneye Randevu Notu</span>
              <md-field>
                <md-textarea
                  v-model="appointment.note"
                  placeholder="Randevu notu"
                  v-bind:disabled="isSoon"
                ></md-textarea>
              </md-field>

              <div class="md-layout">
                <md-checkbox v-model="notifyMe" v-bind:disabled="isCompleted"
                  >Hatırlat</md-checkbox
                >
                <md-checkbox
                  v-model="byEmail"
                  v-if="notifyMe"
                  v-bind:disabled="isCompleted"
                  >E-Mail</md-checkbox
                >
                <md-checkbox
                  v-model="byWhatsapp"
                  v-if="notifyMe"
                  v-bind:disabled="isCompleted"
                  >WhatsApp</md-checkbox
                >
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            "
            v-if="screenWidth < 769"
            class="btn-wrap"
          >
            <md-button
              class="md-raised md-secondary"
              v-on:click="turnBack('first')"
              >Geri Dön</md-button
            >
            <md-button
              class="md-raised md-primary btn-right"
              v-on:click="setDone('second', 'third')"
              v-if="buttonVisibleControl()"
              v-bind:disabled="isCompleted"
            >
              Devam
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>

      <md-step
        id="third"
        md-label="Onay Formu 3/4"
        md-description="Onay Formu"
        :md-done.sync="third"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center"
          class="md-layout-item wrapper"
        >
          <md-button
            id="form-container-continue-button"
            class="md-raised md-primary btn-right"
            @click="setDone('third', 'four')"
          >
            Devam
            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
          <div style="margin-top: 30px" ref="pdfContent">
            <ConfirmationTemplate
              @validation="confirmationTemplateValidationMethod"
              :confirmation="appointment.confirmationForm.content"
              :template="selectedConfirmationTemplate"
              :input="true"
            >
            </ConfirmationTemplate>
          </div>
          <md-button
            style="margin-top: 30px !important"
            id="form-container-continue-button"
            class="md-raised md-primary btn-right"
            @click="setDone('third', 'four')"
          >
            Devam
            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
        </div>
      </md-step>

      <md-step
        id="four"
        md-label="Randevu Düzenle 4/4"
        md-description="Önizleme & Onay"
        class="last-step"
        :md-done.sync="four"
      >
        <div class="md-layout md-gutter">
          <div class="md-layout-item inner-box">
            <div class="md-layout">
              <div
                class="md-layout-item md-size-35 little-table md-xsmall-size-100"
              >
                <span class="md-title brand-color">Hasta Bilgileri</span>

                <div class="md-layout">
                  <div class="md-layout-item md-size-">Ad Soyad</div>
                  <div class="md-layout-item">: {{ appointment.name }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-">Telefon</div>
                  <div class="md-layout-item">: {{ appointment.phone }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-">Kaynak</div>
                  <div class="md-layout-item">
                    : {{ agencieName(appointment) }}
                  </div>
                </div>

                <!-- <div class="md-layout">
                  <div class="md-layout-item md-size-15">E-Mail</div>
                  <div class="md-layout-item">: {{ appointment.email }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">Cinsiyet :</div>
                  <div class="md-layout-item">: {{ appointment.sex ? 'Erkek' : 'Kadın' }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">D. Tarihi :</div>
                  <div class="md-layout-item">: {{ appointment.birthday }}</div>
                  </div> -->

                <div class="md-layout">
                  <div class="md-layout-item md-size-">Ülke</div>
                  <div class="md-layout-item">
                    :
                    {{ appointment.country ? appointment.country.name_tr : "" }}
                  </div>
                </div>
              </div>
              <div class="md-layout-item little-table md-xsmall-size-100">
                <span class="md-title brand-color"
                  >İşlem Tipi, Tarihi & Katılımcılar</span
                >

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">İşlem</div>
                  <div class="md-layout-item">
                    : {{ selectedOperationsName }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">Konum</div>
                  <div class="md-layout-item">: {{ selectedLocationName }}</div>
                </div>

                <div class="md-layout">
                  <div
                    v-if="screenWidth <= 769"
                    class="md-layout-item md-size-20"
                  >
                    <b>Temsilci</b>
                  </div>
                  <div
                    v-if="screenWidth > 769"
                    class="md-layout-item md-size-15"
                  >
                    <b>Temsilci</b>
                  </div>
                  <div class="md-layout-item">: {{ selectedUser.name }}</div>
                </div>

                <div class="md-layout">
                  <div
                    v-if="screenWidth <= 769"
                    class="md-layout-item md-size-25"
                  >
                    <b>Katılımcılar</b>
                  </div>
                  <div
                    v-if="screenWidth > 769"
                    class="md-layout-item md-size-15"
                  >
                    <b>Katılımcılar</b>
                  </div>
                  <div class="md-layout-item">
                    : {{ allUsers.find((x) => x.id == selectedDoctor).name }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">Tarih</div>
                  <div class="md-layout-item">: {{ selectedDateString }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">Saat</div>
                  <div class="md-layout-item">: {{ appointment.time }}</div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">İşlem Süresi</div>
                  <div class="md-layout-item">
                    : {{ appointment.duration }} dk
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-15">
                    Hastaneye Randevu Notu
                  </div>
                  <div class="md-layout-item">: {{ appointment.note }}</div>
                </div>
              </div>

              <div class="md-layout-item little-table md-xsmall-size-100">
                <span class="md-title brand-color">Onay Formu</span>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Inc Package
                  </div>
                  <div class="md-layout-item">
                    :
                    {{ appointment.confirmationForm.content.incPrice.content }}
                    {{
                      appointment.confirmationForm.content.priceCurrency.content
                    }}
                  </div>
                </div>

                <div class="md-layout">
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Deposit Price
                  </div>
                  <div class="md-layout-item">
                    :
                    {{
                      appointment.confirmationForm.content.depositPrice.content
                    }}
                    {{
                      appointment.confirmationForm.content.priceCurrency.content
                    }}
                  </div>
                </div>

                <div
                  v-if="
                    appointment.confirmationForm.content.visibleAreas
                      .transferInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Vip Transfer
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.vipTransfer.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Transfer Managing Name Surname
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content
                          .transferManagingNameSurname.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Airport to Hotel/Hospital
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content
                          .airportToHotelHospital.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Hospital to Hotel/Address
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content
                          .hospitalToHotelAddress.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Transfer to Hotel/Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content
                          .transferFromToHotelToAirport.content
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    appointment.confirmationForm.content.visibleAreas.hotelInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Hotel Name
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.hotelName.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Accommodation Day
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.accommodationDay
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Room Type Adults / Children
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content
                          .roomTypeAdultsChildren.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Check-in Date
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.checkInDate.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Check-out Date
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        formatDate(
                          appointment.confirmationForm.content.checkOutDate
                            .content
                        )
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    appointment.confirmationForm.content.visibleAreas
                      .flightInfos
                  "
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Arrival : Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.arrivalAirport
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Date and time
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.dateAndTime.content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Return : Airport
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.returnAirport
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Return Date and time
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.returnDateAndTime
                          .content
                      }}
                    </div>
                  </div>

                  <div class="md-layout">
                    <div class="md-layout-item md-size-30 md-xsmall-size-40">
                      Flight Code
                    </div>
                    <div class="md-layout-item">
                      :
                      {{
                        appointment.confirmationForm.content.flightAirline
                          ? appointment.confirmationForm.content.flightAirline
                              .content
                          : ""
                      }}
                      {{
                        appointment.confirmationForm.content.flightCode.content
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="appointment.confirmationForm.content.notes"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-30 md-xsmall-size-40">
                    Notes
                  </div>
                  <div class="md-layout-item">
                    : {{ appointment.confirmationForm.content.notes.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-raised md-secondary"
              v-on:click="turnBack('third')"
              >Geri Dön</md-button
            >
            <md-button
              class="md-raised md-primary btn-right"
              v-on:click="updateAppointment"
            >
              Randevu Düzenle
              <md-icon>edit</md-icon>
            </md-button>
          </div>
        </div>
      </md-step>
    </md-steppers>
  </div>
</template>

<script>
// import axios from "axios";
import patientlist from "../components/patients/listwithoutlink";
import router from "../router";
import { HTTP } from "../main-source";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import moment from "moment";
import Loading from "@/components/Loading";
import ConfirmationTemplate from "../components/confirmationTemplate/confirmationTemplate.vue";

let dateNotChanged;
let selectedOperationIsSurgery;

export default {
  name: "updateAppointment",
  data() {
    return {
      title: "Randevu Düzenle",
      active: "first",
      first: false,
      second: false,
      third: false,
      four: false,
      secondStepError: null,
      operations: [],
      doctors: [],
      branches: [],
      isCompleted: null,
      isSoon: null,
      pastDate: false,
      userId: localStorage.getItem("userId"),
      roleId: localStorage.getItem("roleId"),
      selectedBranch: null,
      selectedOperationsName: "",
      selectedOperation: null,
      patients: [],
      newPatient: {},
      roles: [],
      roleName: "",
      type: "",
      locations: [],
      sources: ["WhatsApp", "Web Sitesi", "Facebook", "Instagram", "Referans"],
      appointment: {
        id: "",
        operationId: "",
        duration: 0,
        locationId: "",
        userName: localStorage.getItem("name"),
        date: "",
        time: "",
        confirmationForm: {
          content: {},
          template: {},
        },
        isDiscounted: false,
      },
      process_type: [],
      participants: [],
      agencies: [],
      process: [],
      selectedPatient: {},
      search_query: "",
      selectedTime: {},
      appointmentId: this.$route.params.appointmentId,
      resultHolder: [],
      selectedLocationName: "",
      selectedDateString: "",
      time: "",
      isValid: {
        name: null,
        location: null,
        patient: null,
        duration: null,
        operation: null,
        alocation: null,
        participants: null,
        date: null,
        time: null,
      },
      dayOperations: [],
      selectedDayAppointments: [],
      isDayClosed: false,
      dayProcess: 0,
      dayLimit: {},
      customLimits: [],
      holidays: [],
      notifyMe: true,
      byEmail: false,
      reqApprove: false,
      byWhatsapp: false,
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      daysLimit: false,
      doctorNotSurgeryDailyLimit: false,
      doctorSurgeryDailyLimit: false,
      operationDailyLimit: false,
      weeklyLimitExceeded: false,
      userMonthlyLimitExceeded: false,
      daysNotSurgeryLimit: false,
      userMonthlyNotSurgeryLimitExceeded: false,
      weeklyNotSurgeryLimitExceeded: false,
      canContinue: false,
      allUsers: [],
      users: [],
      selectedUser: {
        id: "",
        name: "",
      },
      selectedDoctor: null,
      isAdmin: localStorage.getItem("roleId") == 1,
      isCoordinator: localStorage.getItem("roleId") == 4,
      userLeading: [],
      selectedConfirmationContent: null,
      allConfirmationContents: [],
      selectedConfirmationTemplate: [],
      allConfirmationTemplates: [],
      loading: true,
      storagepath: this.$storage,
      confirmationTemplateValidation: false,
      screenWidth: 0,
      appointmentCreationLimitControlResponse: {
        isSuccess: false,
        message: "Gerekli bilgiler girildiğinde limit kontrolü başlayacaktır.",
      },
    };
  },
  components: {
    patientlist,
    "vue-timepicker": VueTimepicker,
    Loading,
    ConfirmationTemplate,
  },
  async created() {
    await this.getOperations();
    await this.getBranches();

    await HTTP.get("/roles/").then((result) => {
      this.roles = result.data;
    });

    await HTTP.get("/agencies/").then((result) => {
      this.agencies = result.data;
    });

    await HTTP.get("/settings/customLimits/day-limit").then((result) => {
      this.customLimits = result.data;
    });

    HTTP.get("/settings/default/daylimit").then((result) => {
      result.data.forEach(
        (z) => (this.dayLimit[z?.name] = z.transaction_limit)
      );
    });

    await HTTP.get("holidays").then((result) => {
      result.data.forEach((holiday) => {
        this.holidays.push({
          start: holiday.start + " " + holiday.startTime.substr(0, 5),
          end: holiday.end + " " + holiday.endTime.substr(0, 5),
          title: holiday.title,
        });
      });
    });

    await this.getLocations();

    await HTTP.get("/appointments/" + this.appointmentId).then(
      async (result) => {
        this.selectedDoctor = result.data[0]?.AppointmentsParticipants[0]?.id;

        this.resultHolder = result.data[0];
        let currentDate = new Date();
        this.resultHolder.status.id == 2 && this.roleId != 1
          ? (this.isCompleted = true)
          : (this.isCompleted = false);

        this.appointment.id = result.data[0].id;
        this.appointment.userId = result.data[0].userId;
        this.appointment.operationId = result.data[0].operationId;
        this.appointment.guid = result.data[0]?.guid;
        this.appointment.patient = result.data[0]?.patient;
        this.appointment.user = result.data[0]?.user;
        this.appointment.isDiscounted = result.data[0]?.isDiscounted;
        this.roleId != 1 &&
        this.roleId != 4 &&
        (this.resultHolder?.date <= currentDate.format("YYYY-MM-DD") ||
          this.resultHolder.status.id == 2)
          ? (this.isSoon = true)
          : (this.isSoon = false);

        this.appointment.locationId = result.data[0].locationId;
        this.selectedOperation = result.data[0].operation;
        this.selectedBranch = this.branches.find(
          (z) => z.id == result.data[0].operation.branchId
        );
        this.selectedOperationsName = result.data[0].operation?.name;

        this.getDoctorConfirmationTemplate(
          result.data[0].AppointmentsParticipants[0]?.id
        );

        this.appointment.duration = result.data[0]?.duration;
        this.appointment.note = result.data[0].note;
        this.appointment.note = result.data[0].note;
        this.appointment.date = new Date(result.data[0]?.date);
        this.appointment.time = result.data[0].time.substr(0, 5);

        this.notifyMe = result.data[0].notify_me;
        this.appointment.notify_me = this.notifyMe;

        HTTP.get("/operations/").then((resultOperations) => {
          var selectedOperation = resultOperations.data.filter(
            (operation) => operation.id == this.appointment.operationId
          );

          selectedOperationIsSurgery = selectedOperation[0].isSurgery;
          this.selectedOperation = selectedOperation[0];
        });

        HTTP.get("/users/").then((resultUser) => {
          this.allUsers = resultUser.data;

          this.doctors = resultUser.data.filter((user) => user.role?.id == 2);

          if (localStorage.getItem("roleId") == 1) {
            this.participants = this.doctors.filter((z) =>
              z.branches.map((z) => z.id).includes(this.selectedBranch.id)
            );
          } else {
            this.participants = this.doctors.filter(
              (z) =>
                z.branches.map((z) => z.id).includes(this.selectedBranch.id) &&
                this.userDoctors.includes(z.id)
            );
          }

          this.options = resultUser.data;
        });

        const getUsers = await HTTP.post("/permissions/userDatas/getUsers");
        this.users = getUsers.data;
        this.selectedUser = getUsers.data.find(
          (item) => item?.id == this.appointment.userId
        );

        HTTP.get(
          "/notifications/notify/" + this.appointment.id + "/appointments"
        ).then((resultNotify) => {
          this.byEmail = resultNotify.data.notifyByEmail;
          this.byWhatsapp = resultNotify.data.notifyByWhatsapp;
          this.appointment.notifyByEmail = this.byEmail;
          this.appointment.notifyByWhatsapp = this.byWhatsapp;
        });

        HTTP.get("/patients/" + this.resultHolder.patientId).then(
          (resultPatients) => {
            this.patients = resultPatients.data;
            this.selectPatient(resultPatients.data[0].id);
            this.active = "second";
          }
        );

        var uri = "/appointments/";

        const start = this.appointment?.date;
        const end = this.appointment?.date;

        let startText =
          start.getFullYear() +
          "-" +
          (start.getMonth() + 1) +
          "-" +
          ("0" + start.getDate()).slice(-2);

        let endText =
          end.getFullYear() +
          "-" +
          (end.getMonth() + 1) +
          "-" +
          ("0" + end.getDate()).slice(-2);

        uri += "?startDate=" + startText + "&endDate=" + endText;

        HTTP.get(uri).then((appointments) => {
          this.selectedDayAppointments = appointments.data.reverse();
          this.selectedDayAppointments = this.selectedDayAppointments.filter(
            (appointment) =>
              appointment.statusId != 3 && appointment.statusId != 4
          );

          let dayOperations = [];
          this.dayOperations = [];
          this.selectedDayAppointments.forEach((e) => {
            if (!dayOperations.includes(e.operationId)) {
              dayOperations.push(e.operationId);
            }
          });
          this.dayOperations = dayOperations;
          dateNotChanged = appointments.data.find(
            (z) => z.id == this.appointmentId
          );
        });
      }
    );

    await this.allDataLoaded();
    this.appointmentCreationLimitControl();
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    formatDate(date, showTime = false) {
      return showTime
        ? moment(date).format("DD-MM-YYYY HH:mm")
        : moment(date).format("DD-MM-YYYY");
    },
    getBranches: function() {
      let userId = localStorage.getItem("userId");
      let branches = [];
      return HTTP.get("/branches/").then((result) => {
        result.data.forEach(function(branch) {
          if (branch.branchUsers.map((x) => x.id).includes(parseInt(userId)))
            branches.push(branch);
        });

        this.branches = branches;

        // this.selectedBranch = this.appointment.operation
        //   ? this.branches.find(
        //       (z) => z.id == this.appointment.operation.branchId
        //     )
        //   : this.branches
        //   ? this.branches[0]
        //   : null;
      });
    },
    getOperations: function() {
      HTTP.get("/operations/").then((result) => {
        this.operations = result.data.filter(
          (operation) => operation.status == true
          // && operation.branchId == this.selectedBranch.id
        );
      });
    },
    getLocations: function() {
      HTTP.get("/locations/").then((result) => {
        this.locations = result.data.filter(
          (location) =>
            location.status == true &&
            location.branchId == this.selectedBranch.id
        );
        let location = {};
        if (this.appointment.locationId) {
          location = this.locations.filter(
            (location) => location.id == this.appointment.locationId
          )[0];
          this.selectedLocationName = location?.name;
        }
      });
    },
    agencieName: function(patient) {
      if (!patient.agencieId) return "";
      var agencie = this.agencies.find((z) => z.id == patient.agencieId);
      return agencie ? agencie?.name : "";
    },
    isDateDisabled: function(date) {
      return moment(date).isBefore(moment().format("YYYY-MM-DD"));
    },
    turnBack: function(index) {
      this.active = index;
    },
    is_timeString(str) {
      var regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
      if (regexp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    setDone: function(id, index) {
      let errorMessage = "Lütfen gerekli alanları doldurunuz. <br />";

      switch (index) {
        case "second":
          if (this?.appointment?.patientId == null) {
            this.isValid.patient = false;
          } else {
            this.goToTop();
            this.active = index;
          }
          break;

        case "third":
          let control = [];

          if (!this.appointment?.operationId) {
            this.isValid.operation = false;
            control.push("Lütfen operasyon seçiniz");
          }

          if (!this.appointment?.locationId) {
            this.isValid.alocation = false;
            control.push("Lütfen konum seçiniz");
          }

          if (this.appointment.participants.length == 0) {
            this.isValid.participants = false;
            control.push("Lütfen katılımcı seçiniz");
          }

          if (
            this.appointment.duration == 0 ||
            this.appointment.duration == null
          ) {
            this.isValid.duration = false;
            control.push("Lütfen işlem süresi giriniz");
          }

          if (this.appointment.date == null) {
            this.isValid.date = false;
            control.push("Lütfen randevu tarihi seçiniz");
          }

          if (
            this.appointment.time == undefined ||
            this.is_timeString(this.appointment.time) == false
          ) {
            this.isValid.time = false;
            control.push("Lütfen randevu saati seçiniz");
          }

          if (control?.length > 0) {
            for (let i in control) {
              errorMessage += "* " + control[i] + "<br/>";
            }

            this.$swal("Uyarı", errorMessage, "warning");
          } else if (!this.appointment.operationId) {
            this.isValid.operation = false;
          } else if (!this.appointment.locationId) {
            this.isValid.alocation = false;
          } else if (this.appointment.participants.length == 0) {
            this.isValid.participants = false;
          } else if (
            this.appointment.duration == 0 ||
            this.appointment.duration == null
          ) {
            this.isValid.duration = false;
          } else if (this.appointment.date == undefined) {
            this.isValid.date = false;
          } else if (
            this.appointment.time == undefined ||
            this.is_timeString(this.appointment.time) == false
          ) {
            this.isValid.time = false;
          } else {
            this.goToTop();
            this.active = index;
          }

          break;

        case "four":
          if (this.confirmationTemplateValidation) {
            for (let i in this.confirmationTemplateValidation) {
              errorMessage +=
                "* " +
                this.confirmationTemplateValidation[i]?.message +
                "<br/>";
            }

            this.$swal("Uyarı", errorMessage, "warning");
          } else {
            this.goToTop();
            this.active = index;
          }
          break;
      }
    },
    setTime: function(item, index) {
      this.selectedTime.item = item;
      this.selectedTime.index = index;
    },
    addPatient: function() {
      HTTP.post("/patients/", this.newPatient).then((result) => {
        // router.push({ name: "patients" });
        if (result.status == 201) {
          HTTP.get("/patients/?limit=8").then((patients) => {
            this.patients = patients.data;
          });
          let id = result.data.id;
          //
          setTimeout(() => {
            this.selectPatient(id);
          }, 700);
        }
      });
    },
    selectPatient: function(patientId) {
      this.selectedPatient = this.patients.filter(
        (patient) => patient.id == patientId
      )[0];
      //
      this.appointment.patientId = this.selectedPatient.id;
      this.appointment.name = this.selectedPatient?.name;
      this.appointment.email = this.selectedPatient.email;
      this.appointment.phone = this.selectedPatient.phone;
      this.appointment.sex = this.selectedPatient.sex;
      this.appointment.birthday = this.selectedPatient.birthday;
      this.appointment.country = this.selectedPatient.country;
      this.appointment.agencieId = this.selectedPatient.agencieId;
    },
    updateAppointment: function() {
      let start = new Date(this.appointment?.date);
      let startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);
      this.appointment.date = startText;

      //
      // HTTP.put("/appointments/changeAppointmentOwner/"+this.appointment.id,{userId : this.selectedUser.id})
      // .then((result) =>
      // {

      // })

      this.appointment.userId = this.selectedUser.id;

      HTTP.patch("/appointments/" + this.appointment.id, this.appointment).then(
        (result) => {
          if (result.status == 201) {
            const data = {
              patientId: this.appointment?.patientId,
              fileContent: {
                name:
                  result.data?.patientId +
                  "-" +
                  this.appointment.name +
                  "-" +
                  result.data.date +
                  "-" +
                  result.data.time,
                slug: this.createSlugName(
                  result.data?.patientId +
                    "-" +
                    this.appointment.name +
                    "-" +
                    result.data.date +
                    "-" +
                    result.data.time
                ),
                content: JSON.stringify(
                  this.appointment.confirmationForm?.content
                ),
                fileTypeId: 10,
                languageId: this.selectedConfirmationContent?.languageId,
                templateId: this.selectedConfirmationTemplate?.id,
                appointmentId: this.appointment?.id,
                confirmationContentId: this.selectedConfirmationContent?.id,
              },
            };

            HTTP.post("/patientFiles", data)
              .then((response) => {
                if (response.status == 201) {
                  const appointmentGuid = result?.data?.guid;

                  if (this.screenWidth > 769) {
                    router.push({
                      name: "AppointmentsList",
                      query: { date: this.appointment?.date },
                    });

                    const route = this.$router.resolve({
                      name: "appoinmentDetailView",
                      params: { guid: this.appointment.guid },
                    });

                    window.open(route.href, "_blank");
                  } else {
                    router.push({
                      name: "appoinmentDetailView",
                      params: { guid: appointmentGuid },
                    });
                  }
                }
              })
              .catch((err) => {});
          }
        }
      );
    },
    checkContinue: function() {
      this.doctorNotSurgeryDailyLimit = false;
      this.doctorSurgeryDailyLimit = false;
      // this.daysNotSurgeryLimit = false;
      // this.daysLimit = false;

      //check if past date is added
      if (
        this.appointment?.date &&
        moment(this.appointment?.date).diff(moment(), "days") < 0
      ) {
        this.canContinue = false;
        this.pastDate = true;
        return;
      } else {
        this.pastDate = false;
      }

      if (this.roleId == 1) {
        // admin can make appointments even if they passed the daily limit
        this.canContinue = true;
        return;
      }

      // Check operations daily limit (weekdays and weekend)
      // getDay() result: Saturday=6, Sunday=0

      // check doctors daily limits
      if (this.selectedOperation && this.appointment.participants?.length > 0) {
        this.doctorNotSurgeryDailyLimit = false;
        this.doctorSurgeryDailyLimit = false;
        this.appointment.participants.forEach((userId) => {
          let doctor = this.doctors.find((z) => z.id == userId);

          var isSurgeryItems = [];
          var isNotSurgeryItems = [];
          this.selectedDayAppointments.filter((z) => {
            if (z.operation.isSurgery == true) {
              z.AppointmentsParticipants.filter((a) => {
                isSurgeryItems.push(a.id);
              });
            }
            if (z.operation.isNotSurgery == true) {
              z.AppointmentsParticipants.filter((a) => {
                isNotSurgeryItems.push(a.id);
              });
            }
          });

          var newItem = {
            id: doctor.id,
            isNotSurgery: isNotSurgeryItems.filter((c) => c == userId)?.length,
            isSurgery: isSurgeryItems.filter((c) => c == userId)?.length,
          };

          if (doctor.doctorLimits?.length > 0) {
            let key = this.selectedOperation.isSurgery
              ? "isSurgery"
              : this.selectedOperation.isNotSurgery
              ? "isNotSurgery"
              : null;
            let key2 = this.selectedOperation.isSurgery
              ? "surgery"
              : this.selectedOperation.isNotSurgery
              ? "notSurgery"
              : null;
            if (key) {
              if (
                newItem[key] >=
                doctor.doctorLimits.find((z) => z.type == key2)[
                  "day" + new Date(this.appointment?.date).getDay()
                ]
              ) {
                if (key == "isSurgery") {
                  this.canContinue = false;
                  this.doctorSurgeryDailyLimit = true;
                  return;
                } else if (key == "isNotSurgery") {
                  this.canContinue = false;
                  this.doctorNotSurgeryDailyLimit = true;
                  return;
                }
              }
            }
          }
        });
      }
    },
    checkDoctorDailyLimit: function() {
      if (this.selectedOperation && this.appointment.participants?.length > 0) {
        this.doctorNotSurgeryDailyLimit = false;
        this.doctorSurgeryDailyLimit = false;
        this.appointment.participants.forEach((userId) => {
          let doctor = this.doctors.find((z) => z.id == userId);

          var isSurgeryItems = [];
          var isNotSurgeryItems = [];
          this.selectedDayAppointments.filter((z) => {
            if (z.operation.isSurgery == true) {
              z.AppointmentsParticipants.filter((a) => {
                isSurgeryItems.push(a.id);
              });
            }
            if (z.operation.isNotSurgery == true) {
              z.AppointmentsParticipants.filter((a) => {
                isNotSurgeryItems.push(a.id);
              });
            }
          });

          var newItem = {
            id: doctor.id,
            isNotSurgery: isNotSurgeryItems.filter((c) => c == userId)?.length,
            isSurgery: isSurgeryItems.filter((c) => c == userId)?.length,
          };

          if (doctor.doctorLimits?.length > 0) {
            let key = this.selectedOperation.isSurgery
              ? "isSurgery"
              : this.selectedOperation.isNotSurgery
              ? "isNotSurgery"
              : null;
            let key2 = this.selectedOperation.isSurgery
              ? "surgery"
              : this.selectedOperation.isNotSurgery
              ? "notSurgery"
              : null;
            if (key) {
              if (
                newItem[key] >=
                doctor.doctorLimits.find((z) => z.type == key2)[
                  "day" + new Date(this.appointment?.date).getDay()
                ]
              ) {
                if (key == "isSurgery") {
                  this.doctorSurgeryDailyLimit = true;
                  return;
                } else if (key == "isNotSurgery") {
                  this.doctorNotSurgeryDailyLimit = true;
                  return;
                }
              }
            }
          }
        });
      }
    },
    checkHoliday: function() {
      this.isDayClosed = false;

      let params = {
        date: moment(this.appointment?.date).format("YYYY-MM-DD"),
        time: this.appointment.time,
        branchId: this.selectedBranch ? this.selectedBranch.id : null,
        operationId: this.selectedOperation ? this.selectedOperation.id : null,
        doctorId: this.appointment.participants,
        duration: this.appointment?.duration,
        isSurgery: this.selectedOperation
          ? this.selectedOperation.isSurgery
          : false,
        isNotSurgery: this.selectedOperation
          ? this.selectedOperation.isNotSurgery
          : false,
      };

      if (params?.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkHoliday?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (result.data.closedDay) {
          this.canContinue = false;
          this.isDayClosed = true;
        }
      });
      if (!this.appointment?.date) return;
      let date = moment(this.appointment?.date);
      let closedDays = [];
      if (this.appointment.participants?.length > 0) {
        this.appointment.participants.forEach((doctorId) => {
          var doctor = this.participants.find((z) => z.id == doctorId);
          if (doctor.closedDays) {
            closedDays = closedDays.concat(JSON.parse(doctor.closedDays));
          }
        });
      }
      if (closedDays.includes(date.day())) {
        this.isDayClosed = true;
        this.canContinue = false;
        return;
      }
    },
    checkMonthlyLimit: function() {
      this.userMonthlyLimitExceeded = false;
      this.userMonthlyNotSurgeryLimitExceeded = false;
      if (!this.appointment?.date) return;
      let date = moment(this.appointment?.date);
      let closedDays = [0];
      let params = {
        date: moment(this.appointment?.date).format("YYYY-MM-DD"),
        time: this.appointment.time,
        branchId: this.selectedBranch ? this.selectedBranch.id : null,
        operationId: this.selectedOperation ? this.selectedOperation.id : null,
        doctorId: this.appointment.participants,
        duration: this.appointment?.duration,
        isSurgery: this.selectedOperation
          ? this.selectedOperation.isSurgery
          : false,
        isNotSurgery: this.selectedOperation
          ? this.selectedOperation.isNotSurgery
          : false,
      };

      if (params?.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkMonthlyLimit?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (this.roleId != 1 && this.selectedOperation) {
          if (this.selectedOperation.isSurgery) {
            if (
              result.data.userMonthlyOperations >=
              this.dayLimit.userMonthlyLimit
            ) {
              this.userMonthlyLimitExceeded = true;
              this.canContinue = false;
            }
          } else if (this.selectedOperation.isNotSurgery) {
            if (
              result.data.userMonthlyOperations >=
              this.dayLimit.userMonthlyNotSurgeryLimit
            ) {
              this.userMonthlyNotSurgeryLimitExceeded = true;
              this.canContinue = false;
            }
          }
        }
      });
    },
    checkWeeklyLimit: function() {
      if (!this.appointment?.date) return;

      let params = {
        date: moment(this.appointment?.date).format("YYYY-MM-DD"),
        time: this.appointment.time,
        branchId: this.selectedBranch ? this.selectedBranch.id : null,
        operationId: this.selectedOperation ? this.selectedOperation.id : null,
        doctorId: this.appointment.participants,
        duration: this.appointment?.duration,
        isSurgery: this.selectedOperation
          ? this.selectedOperation.isSurgery
          : false,
        isNotSurgery: this.selectedOperation
          ? this.selectedOperation.isNotSurgery
          : false,
      };

      this.canContinue = false;

      if (params?.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkWeeklyLimit?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (this.roleId != 1 && this.selectedOperation) {
          this.weeklyLimitExceeded = false;

          this.weeklyNotSurgeryLimitExceeded = false;

          if (
            this.selectedBranch &&
            this.selectedBranch.weeklyLimit > 0 &&
            result.data.weeklyOperations >= this.selectedBranch.weeklyLimit
          ) {
            if (this.selectedOperation.isSurgery) {
              this.weeklyLimitExceeded = true;
              this.canContinue = false;
              return;
            } else if (this.selectedOperation.isNotSurgery) {
              this.weeklyNotSurgeryLimitExceeded = true;
              this.canContinue = false;
              return;
            }
          }
        }
      });
    },
    checkOperationDailyLimit: function() {
      if (this.selectedOperation) {
        this.operationDailyLimit = false;
        var dateSelectedOperationCount = this.selectedDayAppointments.filter(
          (z) =>
            z.operationId == this.selectedOperation.id &&
            !["3", "4", "5"].includes(z.statusId)
        );
        if (
          this.selectedOperation.dailyLimit > 0 &&
          dateSelectedOperationCount?.length >=
            this.selectedOperation.dailyLimit
        ) {
          this.operationDailyLimit = true;
          return;
        }
        this.canContinueShow();
      }
    },
    checkDailyLimit: function() {
      // check selected operation daily limit

      if (!this.appointment?.date) return;

      if (this.selectedOperation) {
        this.canContinue = false;
        this.daysNotSurgeryLimit = false;
        this.daysLimit = false;
        var weekendDays = [0, 6];

        var dateCustomLimit = this.customLimits.filter(
          (cl) =>
            new Date(cl?.date).format("YYYY-MM-DD") ==
              new Date(this.appointment?.date).format("YYYY-MM-DD") &&
            ((this.selectedOperation.isSurgery &&
              cl.operationType == "surgery") ||
              (this.selectedOperation.isNotSurgery &&
                cl.operationType == "nonSurgery"))
        );
        if (dateCustomLimit?.length > 0) {
          var dayLimit = dateCustomLimit[0].limit;
        } else {
          var dayLimit = weekendDays.includes(
            new Date(this.appointment?.date).getDay()
          )
            ? this.selectedOperation.isSurgery
              ? this.dayLimit.weekend
              : this.dayLimit.weekendNotSurgery
            : this.selectedOperation.isSurgery
            ? this.dayLimit.weekdays
            : this.dayLimit.weekdayNotSurgery;
        }

        if (this.selectedOperation.isNotSurgery == true) {
          let dayOperationsCount = this.selectedDayAppointments.filter(
            (z) => z.operation.isNotSurgery == true
          )?.length;

          if (dayOperationsCount >= dayLimit) {
            this.canContinue = false;
            this.daysNotSurgeryLimit = true;
          }
        } else if (this.selectedOperation.isSurgery == true) {
          let dayOperationsCount = this.selectedDayAppointments.filter(
            (z) => z.operation.isSurgery == true
          )?.length;

          if (dayOperationsCount >= dayLimit) {
            this.canContinue = false;
            this.daysLimit = true;
          }
        }
      }
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    appointmentDateChanged: function() {
      this.canContinue = false;
      this.selectedDateString = this.appointment?.date.toLocaleDateString();
      var uri = "/appointments/";
      const start = this.appointment?.date;
      const end = this.appointment?.date;

      let startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);

      let endText =
        end.getFullYear() +
        "-" +
        (end.getMonth() + 1) +
        "-" +
        ("0" + end.getDate()).slice(-2);

      uri += "?startDate=" + startText + "&endDate=" + endText;
      uri += "&branchId=" + this.selectedBranch.id;

      HTTP.get(uri).then((appointments) => {
        this.selectedDayAppointments = appointments.data.reverse();
        this.selectedDayAppointments = this.selectedDayAppointments.filter(
          (appointment) =>
            appointment.statusId != 3 && appointment.statusId != 4
        );

        let dayOperations = [];
        this.dayOperations = [];
        this.selectedDayAppointments.forEach((e) => {
          if (!dayOperations.includes(e.operationId)) {
            dayOperations.push(e.operationId);
          }
        });
        this.dayOperations = dayOperations;
      });
    },
    canContinueShow: function() {
      // this.canContinue = false
      if (
        this.daysLimit == false &&
        this.daysNotSurgeryLimit == false &&
        this.operationDailyLimit == false &&
        this.doctorSurgeryDailyLimit == false &&
        this.doctorNotSurgeryDailyLimit == false &&
        this.isDayClosed == false &&
        this.userMonthlyLimitExceeded == false &&
        this.userMonthlyNotSurgeryLimitExceeded == false &&
        this.weeklyLimitExceeded == false &&
        this.weeklyNotSurgeryLimitExceeded == false
      ) {
        this.canContinue = true;
      } else {
        this.canContinue = false;
      }
    },
    temsilciVisibleControl() {
      if (this.roleId == 4) {
        return this.userLeading
          .concat(this.userId)
          .includes(this.appointment.userId);
      } else {
        return true;
      }
    },
    getAppointmentConfirmationForm(guid) {
      HTTP.get("/appointments/confirmationForm/" + guid).then(
        async (response) => {
          this.loading = false;

          let lastAppointmentConfirmationForm =
            response.data?.fileContents[
              response.data?.fileContents?.length - 1
            ];

          if (lastAppointmentConfirmationForm?.content) {
            let content = lastAppointmentConfirmationForm?.content;

            this.user = lastAppointmentConfirmationForm?.creatorUser;
            content.confirmationId.content =
              lastAppointmentConfirmationForm?.guid;
            this.appointment.confirmationForm.content = content;

            // if (this.appointment?.confirmationForm?.content?.notes?.content) {
            // } else {
            //   this.appointment.confirmationForm.content.notes = this.selectedConfirmationContent?.content?.notes;
            // }

            this.appointment.confirmationForm.content.userNameSurname.content = this.roles.find(
              (x) => x.id == this.appointment?.user?.roleId
            )?.name;
            this.appointment.confirmationForm.content.userNameSurname.label = this.appointment?.user?.name;
            this.appointment.confirmationForm.content.userNameSurname.phone = this.appointment?.user?.phone;
            this.appointment.confirmationForm.content.userNameSurname.image = this
              .appointment?.user?.profileImg
              ? this.storagepath + this.appointment?.user?.profileImg
              : "/images/avatar.jpg";

            this.appointment.confirmationForm.template =
              lastAppointmentConfirmationForm?.template;
            this.appointment.confirmationForm.flightCode =
              content?.flightCode?.content;
            this.appointment.confirmationForm.templateId =
              lastAppointmentConfirmationForm?.templateId;
            this.appointment.confirmationForm.name =
              lastAppointmentConfirmationForm?.name;
            this.appointment.confirmationForm.slug =
              lastAppointmentConfirmationForm?.slug;
            this.updateConfirmationContentLabel();
          } else {
            this.getConfirmationContents(this.appointment?.patient?.languageId);
          }
        }
      );
    },
    getConfirmationContents(id) {
      HTTP.get("/confirmationContents/language/" + id).then((result) => {
        let content = result.data;
        content.content = JSON.parse(content?.content);

        this.appointment.confirmationForm.content = result.data?.content;
        this.selectedConfirmationContent = content;
        this.appointment.confirmationForm.content.confirmationId.content = "";
        this.appointment.confirmationForm.content.date.content = this.formatDate(
          new Date()
        );

        //new fields
        this.appointment.confirmationForm.content.userNameSurname.content = this.roles.find(
          (x) => x.id == this.appointment?.user?.roleId
        )?.name;
        this.appointment.confirmationForm.content.userNameSurname.label = this.appointment?.user?.name;
        this.appointment.confirmationForm.content.userNameSurname.phone = this.appointment?.user?.phone;
        this.appointment.confirmationForm.content.userNameSurname.image = this
          .appointment?.user?.profileImg
          ? this.storagepath + this.appointment?.user?.profileImg
          : "/images/avatar.jpg";

        this.appointment.confirmationForm.content.nameSurname.content = this.appointment?.name;
        this.appointment.confirmationForm.content.country.content = this.appointment.country?.name_tr;
        this.appointment.confirmationForm.content.phone.content = this.appointment.phone;
        this.appointment.confirmationForm.content.email.content = this.appointment.email;
        this.appointment.confirmationForm.content.treatmentPackage.content = this.operations.find(
          (x) => x?.id == this.appointment.operationId
        )?.name;

        this.appointment.confirmationForm.content.staffDoctor.content = this.allUsers.find(
          (x) => x.id == this.appointment.participants[0]
        )?.name;

        let location = this.locations.filter(
          (location) => location.id == this.appointment.locationId
        )[0];

        // this.appointment.confirmationForm.content.operationLocation.content =
        //   location?.name;
        this.user = this.users.find(
          (x) => x.id == localStorage.getItem("userId")
        );
        this.appointment.confirmationForm.name =
          this.appointment.patient.id +
          "-" +
          this.patient?.name +
          "-" +
          this.appointment?.date +
          "-" +
          this.appointment.time;
        this.appointment.confirmationForm.slug = this.createSlugName(
          this.appointment.patient.id +
            "-" +
            this.patient?.name +
            "-" +
            this.appointment?.date +
            "-" +
            this.appointment.time
        );
      });
    },
    async getConfirmationContentByLanguageId(id) {
      return await HTTP.get("/confirmationContents/language/" + id);
    },
    async updateConfirmationContentLabel() {
      const fieldNames = [
        "headerTitle",
        "confirmationId",
        "date",
        "title",
        "description",
        "postSurgeryGarment",
        "helplineWithExpertTeam",
        "consultation",
        "preOperativeTest",
        "allMedications",
        "treatmentPackage",
        "incPrice",
        "incPriceCurrency",
        "depositPrice",
        "depositPriceCurrency",
        "priceCurrency",
        "personalInformations",
        "nameSurname",
        "country",
        "phone",
        "email",
        "treatmentInformations",
        "treatmentDateTime",
        "operationLocation",
        "staffDoctor",
        "transferInformations",
        "vipTransfer",
        "transferManagingNameSurname",
        "airportToHotelHospital",
        "hospitalToHotelAddress",
        "transferFromToHotelToAirport",
        "accommodation",
        "hotelName",
        "accommodationDay",
        "roomTypeAdultsChildren",
        "checkInDate",
        "checkOutDate",
        "flightDetails",
        "arrivalAirport",
        "dateAndTime",
        "returnAirport",
        "returnDateAndTime",
        "flightCode",
      ];

      const confirmationContentByLanguageId = await this.getConfirmationContentByLanguageId(
        this.patients[0]?.languageId
      );
      const content = JSON.parse(
        confirmationContentByLanguageId?.data?.content
      );

      if (
        !this.appointment.confirmationForm.content.flightDetails.arrivalLabel
      ) {
        this.appointment.confirmationForm.content.flightDetails.arrivalLabel =
          content.flightDetails.arrivalLabel;
        this.appointment.confirmationForm.content.flightDetails.returnLabel =
          content.flightDetails.returnLabel;
      }

      if (
        !this.appointment.confirmationForm.content.visibleAreas
          .flightArrivalInfos
      ) {
        this.$set(
          this.appointment.confirmationForm.content.visibleAreas,
          "flightArrivalInfos",
          content.visibleAreas.flightArrivalInfos
        );
      }

      if (
        !this.appointment.confirmationForm.content.visibleAreas
          .flightReturnInfos
      ) {
        this.$set(
          this.appointment.confirmationForm.content.visibleAreas,
          "flightReturnInfos",
          content.visibleAreas.flightReturnInfos
        );
      }

      for (let i in fieldNames) {
        this.appointment.confirmationForm.content[fieldNames[i]].label =
          val.content[fieldNames[i]].label;
      }
      //-----------------
    },
    createSlugName(text) {
      return text
        .toLowerCase()
        .replaceAll("ı", "i")
        .replaceAll("ş", "s")
        .replaceAll("ü", "u")
        .replaceAll("ğ", "g")
        .replaceAll("ö", "o");
    },
    getDoctorConfirmationTemplate(id) {
      HTTP.get("/confirmationTemplates/doctor/" + id).then((res) => {
        this.selectedConfirmationTemplate = res?.data;
        this.loading = false;
      });
    },
    async allDataLoaded() {
      await this.getAppointmentConfirmationForm(this.appointment.guid);
    },
    confirmationTemplateValidationMethod(val) {
      this.confirmationTemplateValidation = val;
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    filterByBranch(data) {
      return data.filter((item) => item?.branchId == this.selectedBranch?.id);
    },
    buttonVisibleControl() {
      if (
        this.userMonthlyLimitExceeded ||
        this.operationDailyLimit ||
        this.daysLimit ||
        this.isDayClosed ||
        this.doctorSurgeryDailyLimit ||
        this.doctorNotSurgeryDailyLimit
      ) {
        console.log("DEBUG", this.reqApprove);
        if (this.reqApprove) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    async appointmentCreationLimitControl() {
      const appointmentDate = this?.appointment.date;
      const appointmentTime = this?.appointment.time;
      const selectedUserId = this?.selectedUser?.id;
      const selectedOperationId = this?.appointment?.operationId;
      const selectedDoctorId = this?.selectedDoctor;

      console.log(appointmentDate);
      console.log(appointmentTime);
      console.log(selectedUserId);
      console.log(selectedOperationId);
      console.log(selectedDoctorId);

      this.appointmentCreationLimitControlResponse = {
        isSuccess: false,
        message: "Limit kontrolü yapılıyor...",
      };

      if (
        appointmentDate &&
        appointmentTime &&
        selectedUserId &&
        selectedOperationId &&
        selectedDoctorId
      ) {
        const response = await HTTP.post(
          "/limit/appointmentCreationLimitControl",
          {
            date: appointmentDate,
            time: appointmentTime,
            userId: selectedUserId,
            operationId: selectedOperationId,
            doctorId: selectedDoctorId,
          }
        );

        this.appointmentCreationLimitControlResponse = response.data;
      } else {
        this.appointmentCreationLimitControlResponse = {
          isSuccess: false,
          message:
            "Gerekli bilgiler girildiğinde limit kontrolü başlayacaktır.",
        };
      }
    },
  },
  computed: {
    dateModify: function() {
      if (this.appointment?.date) {
        // let date = this.appointment.date;
        // let year = date.getFullYear();
        // let month = date.getMonth();
        // let day = date.getDay();
        // let dateString = year + "-" + month + "-" + day;
        return this.appointment?.date.toLocaleDateString();
      }
      return null;
    },
    participantsModify: function() {
      let participantsValue = [];
      let result = "";
      if (this.appointment.participants) {
        participantsValue = this.participants.filter((participant) =>
          this.appointment.participants.includes(participant.id)
        );
        participantsValue.forEach((participant) => {
          result += participant?.name + " - ";
        });
        //

        return result.substr(0, result?.length - 3);
      }
      return null;
    },
  },
  watch: {
    // selectedBranch: function () {
    //   this.getOperations();
    //   this.selectedOperation = null;
    //   this.participants = this.doctors.filter(
    //     (z) =>
    //       z.branches.map((z) => z.id).includes(this.selectedBranch.id) &&
    //       this.userDoctors.includes(z.id)
    //   );
    //   this.appointment.participants = [];
    //   this.appointment.locationId = null;
    //   this.appointmentDateChanged();
    //   this.getLocations();
    // },

    "appointment.date": async function() {
      // this.canContinueShow();
      // this.daysNotSurgeryLimit = false;
      // this.daysLimit = false;
      // this.weeklyLimitExceeded = false;
      // this.weeklyNotSurgeryLimitExceeded = false;
      this.appointment.confirmationForm.content.treatmentDateTime.content =
        this.formatDate(this.appointment?.date) + " - " + this.appointment.time;
      this.appointmentDateChanged();
      this.canContinue = false;

      if (
        this.formatDate(this.appointment?.date) !=
        this.formatDate(dateNotChanged?.date)
      ) {
        await this.checkWeeklyLimit();
        await this.checkDailyLimit();
        await this.checkOperationDailyLimit();
        await this.checkHoliday();
        await this.checkDoctorDailyLimit();
        await this.checkMonthlyLimit();

        this.canContinue = false;
        // this.canContinueShow();
      }
      this.canContinueShow();
      this.appointmentCreationLimitControl();
    },
    "appointment.operationId": function() {
      this.appointmentCreationLimitControl();
      // this.canContinue = false;
      this.daysNotSurgeryLimit = false;
      this.daysLimit = false;
      // this.weeklyLimitExceeded = false;
      // this.weeklyNotSurgeryLimitExceeded = false;

      let findOperation = this.operations.filter(
        (operation) => operation.id == this.appointment.operationId
      )[0];

      this.selectedOperation = findOperation;
      this.appointment.duration = this.selectedOperation?.duration;
      this.selectedOperationsName = this.selectedOperation?.name;
      if (
        this.formatDate(this.appointment?.date) !=
        this.formatDate(dateNotChanged?.date)
      ) {
        this.checkDailyLimit();
        this.checkOperationDailyLimit();

        this.checkWeeklyLimit();
        this.checkMonthlyLimit();
        this.checkDoctorDailyLimit();

        return;
      }

      if (
        this.formatDate(this.appointment?.date) ==
        this.formatDate(dateNotChanged?.date)
      ) {
        if (
          (dateNotChanged.operation.isSurgery == true &&
            this.selectedOperation.isSurgery == false) ||
          (dateNotChanged.operation.isNotSurgery == true &&
            this.selectedOperation.isNotSurgery == false)
        ) {
          this.checkDailyLimit();
          this.checkDoctorDailyLimit();
          // this.canContinueShow();
        }
      }
      this.canContinueShow();
    },
    "appointment.duration": function() {
      this.checkHoliday();
      this.canContinueShow();
    },
    "appointment.locationId": function() {
      let location = "";
      if (this.appointment.locationId) {
        location = this.locations.filter(
          (location) => location.id == this.appointment.locationId
        )[0];
      }
      this.selectedLocationName = location?.name;
    },
    "appointment.time": function() {
      this.checkHoliday();
      this.canContinueShow();
    },
    "appointment.participants": function() {
      this.checkDoctorDailyLimit();
      this.checkHoliday();
      this.checkDailyLimit();
      this.canContinueShow();

      let names = "";

      for (let i in this.appointment.participants) {
        names += this.allUsers.find(
          (x) => x.id == this.appointment.participants[i]
        )?.name;
        if (this.appointment.participants?.length - 1 > i) {
          names += " , ";
        }
      }

      this.appointmentCreationLimitControl();
    },
    "appointment.isDiscounted": async function(value) {
      if (!value || !this.selectedBranch.id || !this.selectedOperation.id) {
        this.isValid.isDiscounted = false;
        return false;
      }
      this.loading = true;
      const { data: hasLimit } = await HTTP.get(
        `/branches/${this.selectedBranch.id}/has-limit?operationId=${
          this.selectedOperation.id
        }&month=${moment(this.appointment.date).format("M")}&type=${
          this.selectedOperation.isSurgery ? "withSurgery" : "nonSurgery"
        }&currentAppointmentId=${this.appointment.id}`
      );
      this.loading = false;
      if (!hasLimit) {
        this.$swal({
          title: "İndirimli İşlem Limit Hakkınız Bulunmamaktadır!",
          text: "Yönetici Onayı İster misiniz?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff5252",
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then((result) => {
          if (result.isConfirmed) {
            this.isValid.isDiscounted = true;
            this.appointment.isDiscounted = true;
            this.reqApprove = true;
          } else {
            this.isValid.isDiscounted = false;
            this.appointment.isDiscounted = false;
            this.reqApprove = false;
          }
        });
      }
    },
    "selectedUser.id": function(val) {
      const selectedUser = this.allUsers.find((x) => x.id == val);

      this.appointment.confirmationForm.content.userNameSurname.content =
        selectedUser.role?.name;
      this.appointment.confirmationForm.content.userNameSurname.label =
        selectedUser?.name;
      this.appointment.confirmationForm.content.userNameSurname.phone =
        selectedUser?.phone;
      this.appointment.confirmationForm.content.userNameSurname.image = selectedUser?.profileImg
        ? this.storagepath + selectedUser.profileImg
        : "/images/avatar.jpg";
    },
    reqApprove: function() {
      this.appointment.statusId = this.reqApprove ? 7 : 1;
      if (this.reqApprove) this.temporaryConversion = false;
    },
    selectedDayAppointments: function() {
      var operations = this.operations
        .filter((z) => z.isSurgery)
        .map((z) => z.id); // only these operations are being considered as "Ameliyat" to be count in the calander counter
      this.dayProcess = this.selectedDayAppointments.filter(
        (op) =>
          operations.includes(op.operationId) &&
          op.statusId != 4 &&
          op.statusId != 3 &&
          op.statusId != 7
      )?.length;
    },
    byWhatsapp: function() {
      this.appointment.notifyByWhatsapp = this.byWhatsapp;
    },
    byEmail: function() {
      this.appointment.notifyByEmail = this.byEmail;
    },
    notifyMe: function() {
      this.appointment.notify_me = this.notifyMe;
      this.byEmail = this.notifyMe ? this.byEmail : false;
      this.byWhatsapp = this.notifyMe ? this.byWhatsapp : false;
      this.appointment.notifyByWhatsapp = this.byWhatsapp;
      this.appointment.notifyByEmail = this.byEmail;
    },
    selectedConfirmationContent: function(val) {
      const fieldNames = [
        "headerTitle",
        "confirmationId",
        "date",
        "title",
        "description",
        "postSurgeryGarment",
        "helplineWithExpertTeam",
        "consultation",
        "preOperativeTest",
        "allMedications",
        "treatmentPackage",
        "incPrice",
        "incPriceCurrency",
        "depositPrice",
        "depositPriceCurrency",
        "priceCurrency",
        "personalInformations",
        "nameSurname",
        "country",
        "phone",
        "email",
        "treatmentInformations",
        "treatmentDateTime",
        "operationLocation",
        "staffDoctor",
        "transferInformations",
        "vipTransfer",
        "transferManagingNameSurname",
        "airportToHotelHospital",
        "hospitalToHotelAddress",
        "transferFromToHotelToAirport",
        "accommodation",
        "hotelName",
        "accommodationDay",
        "roomTypeAdultsChildren",
        "checkInDate",
        "checkOutDate",
        "flightDetails",
        "arrivalAirport",
        "dateAndTime",
        "returnAirport",
        "returnDateAndTime",
        "flightCode",
      ];

      for (let i in fieldNames) {
        this.appointment.confirmationForm.content[fieldNames[i]].label =
          val.content[fieldNames[i]].label;
      }
      //-----------------
    },
    "appointment.confirmationForm.template"(val) {
      this.appointment.confirmationForm.templateId = val?.id;
    },
    selectedOperationsName(val) {
      this.appointment.confirmationForm.content.treatmentPackage.content = val;
    },
    selectedLocationName(val) {
      // this.appointment.confirmationForm.content.operationLocation.content = val;
    },
    selectedDoctor(val) {
      this.appointment.participants = [val];
      this.getDoctorConfirmationTemplate(val);
      this.appointment.confirmationForm.content.staffDoctor.content = this.allUsers.find(
        (x) => x.id == val
      )?.name;

      this.checkDoctorDailyLimit();
      this.checkHoliday();
      this.checkDailyLimit();
      this.canContinueShow();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

.md-steppers {
  .md-steppers-navigation {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    background: $white;
  }
}

.md-stepper {
  // margin-top: 30px;
  padding: 0 !important;
}

.md-steppers.md-theme-default {
  background: none !important;
}

.box {
  background: $white;
  padding: 30px !important;
  margin: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.inner-box {
  background: $white;
  padding: 30px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.md-stepper-content {
  margin: 20px 0px 15px 0px;
}

.patient-wrap {
  min-width: calc(50% - 9.5px);
  max-width: calc(50% - 9.5px);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background: darken($white, 5%);
  }

  &:nth-child(even) {
    margin-right: 0px;
  }
}

.step-inner {
  margin-top: 0px;
}

.btn-wrap {
  float: right;
}

.btn-right {
  float: right;
  margin-right: 0 !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 5px !important;

  .btn-icon {
    margin-top: -3px !important;
    padding: 0 !important;
  }
}

.timeSelect {
  max-height: 410px;

  .md-scrollbar {
    max-height: 410px !important;
  }

  overflow: hidden !important;
}

.last-step {
  .md-size-100 {
    text-align: right;
  }
}

.little-table {
  .md-layout {
    margin: 10px 0px 10px 0px;
  }

  .md-size-15,
  .md-size-30 {
    font-weight: bold;
  }
}

.selectedpatient {
  border: 1px solid $brandcolor;
  position: relative;

  &:after {
    content: "\2713";
    color: $brandcolor;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.patient-list {
  margin-top: 10px;
}

.newPatient {
  opacity: 0.4;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
}

.activeTime {
  background: darken($white, 5%) !important;
  // border: 1px solid #f00;
}

.selectTime {
  width: 10px;
  margin: 0;
  padding: 0;

  .md-radio {
    margin: 0;
  }
}
</style>
