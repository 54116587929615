<template>
  <div>
    <div class="logo">
      <router-link :to="{ name: 'home' }">
        <img src="../../public/images/logo.png" alt="Logo" />
      </router-link>
    </div>
    <md-list class="nav">
      <md-list-item to="/" v-if="isAllowedDashboard">
        <md-icon>dashboard</md-icon>
        <span class="md-list-item-text">Anasayfa</span>
      </md-list-item>
      <md-list-item to="/appointments" v-if="isAllowedHospitalAppointments">
        <md-icon>event</md-icon>
        <span class="md-list-item-text">Hastane Randevuları</span>
      </md-list-item>
      <md-list-item to="/activities" v-if="isAllowedClinicAppointments">
        <md-icon>event</md-icon>
        <span class="md-list-item-text">Klinik Randevuları</span>
      </md-list-item>
      <md-list-item to="/patients" v-if="isAllowedPatients">
        <md-icon>person</md-icon>
        <span class="md-list-item-text">Hastalar</span>
      </md-list-item>

      <md-list-item to="/patient/create" v-if="isAllowedQuickPatientAdd">
        <md-icon>whatshot</md-icon>
        <span class="md-list-item-text">Hızlı Hasta Ekleme</span>
      </md-list-item>

      <!-- <md-list-item to="/potantials" v-if="isAllowedPool">
        <md-icon>person</md-icon>
        <span class="md-list-item-text">Havuz</span>
      </md-list-item> -->

      <md-list-item to="/pool" v-if="isAllowedOptimizePool">
        <md-icon>move_to_inbox</md-icon>
        <span class="md-list-item-text">Havuz</span>
      </md-list-item>

      <md-list-item md-expand v-if="isAllowedReports">
        <md-icon>bar_chart</md-icon>
        <span class="md-list-item-text">Raporlar</span>

        <md-list slot="md-expand" class="expandedList">
          <md-list-item to="/reports/salesReport">
            <md-icon>table_view</md-icon>
            <span class="md-list-item-text">Satış Raporu</span>
          </md-list-item>
          <md-list-item to="/reports/progressPaymentReport" v-if="isAllowedAdmin" >
            <md-icon>table_view</md-icon>
            <span class="md-list-item-text">Hakediş Raporu</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <md-list-item to="/marketing" v-if="isAllowedMarketing">
        <md-icon>attach_money</md-icon>
        <span class="md-list-item-text">Pazarlama</span>
      </md-list-item>

      <md-list-item to="/users" v-if="isAllowedUsers">
        <md-icon>person_outline</md-icon>
        <span class="md-list-item-text">Kullanıcılar</span>
      </md-list-item>

      <md-list-item md-expand v-if="isAllowedSettings">
        <md-icon>settings</md-icon>
        <span class="md-list-item-text">Ayarlar</span>
        <md-list slot="md-expand" class="expandedList">
          <md-list-item to="/settings">
            <md-icon>settings</md-icon>
            <span class="md-list-item-text">Genel Ayarlar</span>
          </md-list-item>
          <md-list-item to="/epicrisis">
            <md-icon>description</md-icon>
            <span class="md-list-item-text">Epikrizler</span>
          </md-list-item>
          <md-list-item to="/confirmationTemplates">
            <md-icon>description</md-icon>
            <span class="md-list-item-text">Onay Şablonları</span>
          </md-list-item>
        </md-list>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      leading: [],
      roleId: 0,
    };
  },
  created() {
    this.leading = JSON.parse(localStorage.getItem("leading")) || [];
    this.roleId = parseInt(localStorage.getItem("roleId")) || 0;
  },
  computed: {
    isAllowedDashboard() {
      return ![5, 6, 7].includes(this.roleId);
    },
    isAllowedHospitalAppointments() {
      return ![5, 8].includes(this.roleId);
    },
    isAllowedClinicAppointments() {
      return ![5,6, 8].includes(this.roleId);
    },
    isAllowedPatients() {
      return ![5, 6, 7, 8].includes(this.roleId);
    },
    isAllowedPool() {
      return ![2, 7, 8, 9].includes(this.roleId);
    },
    isAllowedQuickPatientAdd() {
      return [1, 3, 4].includes(this.roleId);
    },
    isAllowedOptimizePool() {
      return [1, 3, 4, 6].includes(this.roleId);
    },
    isAllowedReports() {
      return (
        [1, 3, 4, 5, 6].includes(this.roleId) ||
        (this.leading.length > 0 && this.roleId === 4)
      );
    },
    isAllowedMarketing() {
      return this.roleId === 1;
    },
    isAllowedAdmin() {
      return this.roleId === 1;
    },
    isAllowedUsers() {
      return this.roleId === 1;
    },
    isAllowedSettings() {
      return [1, 9, 4].includes(this.roleId);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors";

.nav {
  background: none !important;

  .md-list-item-expand {
    border: none;
  }

  .expandedList {
    background: transparent !important;
    padding-left: 40px !important;
  }
}

.md-list-item-content {
  justify-content: left !important;
  padding: 0px 16px !important;

  .md-icon {
    &:first-child:not(.markAsReadButton) {
      margin-right: 16px !important;
    }
  }
}

.md-list-item {
  background: none !important;
  margin-bottom: 10px;

  a {
    font-size: 14px !important;
    color: $dark !important;
  }

  .md-icon {
    color: $dark !important;
  }
}

.logo {
  padding: 15px;
  text-align: center;
}

.router-link-exact-active {
  background: #fff !important;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

  .md-icon {
    color: $brandcolor !important;
  }
}

@media (max-width: 767.98px) {
  .logo {
    height: 0px;
    opacity: 0.2;
  }
}
</style>
