<template>

    <div class="row">
            <Container
        width="50"
        height="20"/>
        <Container
        style="position:absolute;margin-left: 105px;"
        width="150"
        height="20"/>
    </div>

</template>
<script>
import Container from './container.vue';
export default {
    components: { Container }
}
</script>
<style scoped>
.row{
    width: 200px;
    display: flex;
}

</style>