<template>
  <div>
    <div class="sub-nav hidden-xs">
      <router-link
        :to="{
        name:'patients.activities',
        params:{'id':id}
    }"
      >Aktiviteler</router-link>
      <router-link
        :to="{
        name:'patients.information',
        params:{'id':id}
    }"
      >Kişisel Bilgiler</router-link>
      <router-link
        :to="{
        name:'patients.medical.history',
        params:{'id':id}
    }"
      >Medikal Geçmiş</router-link>
      <router-link
        :to="{
        name:'patients.appointments',
        params:{'id':id}
    }"
      >Randevular</router-link>
      <router-link :to="{
        name:'patients.offers',
        params:{'id':id}
    }">Teklifler</router-link>
      <router-link :to="{
        name:'patients.payments',
        params:{'id':id}
    }">Ödemeler</router-link>
      <router-link :to="{
        name:'patients.files',
        params:{'id':id}
    }">Dosyalar</router-link>
      <router-link
        :to="{
        name:'patients.transfers',
        params:{'id':id}
    }"
      >Transfer & Konaklama</router-link>
    </div>
    <md-menu md-direction="bottom-start" v-if="innerWidth < 769">
      <md-button md-menu-trigger>
        <md-icon>menu</md-icon>
        {{ $route.meta.name }}
      </md-button>

      <md-menu-content>
        <!-- <md-menu-item>My Item 1</md-menu-item> -->
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.activities',
        params:{'id':id}
    }"
          >Aktiviteler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.information',
        params:{'id':id}
    }"
          >Kişisel Bilgiler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.medical.history',
        params:{'id':id}
    }"
          >Medikal Geçmiş</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.appointments',
        params:{'id':id}
    }"
          >Randevular</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.offers',
        params:{'id':id}
    }"
          >Teklifler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.payments',
        params:{'id':id}
    }"
          >Ödemeler</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.files',
        params:{'id':id}
    }"
          >Dosyalar</router-link>
        </md-menu-item>
        <md-menu-item>
          <router-link
            :to="{
        name:'patients.transfers',
        params:{'id':id}
    }"
          >Transfer & Konaklama</router-link>
        </md-menu-item>
      </md-menu-content>
    </md-menu>

    <md-speed-dial
      class="md-top-right setLocation"
      md-direction="bottom"
      md-event="click"
      v-if="getRoleId == 1"
    >
      <md-speed-dial-target class="md-primary">
        <md-icon class="md-morph-initial">settings</md-icon>
        <md-icon class="md-morph-final">settings</md-icon>
      </md-speed-dial-target>

      <md-speed-dial-content>
        <md-button class="md-icon-button delete" v-on:click="deleteModal = true">
          <md-icon class="icon">delete</md-icon>
        </md-button>
      </md-speed-dial-content>
    </md-speed-dial>

    <md-dialog-confirm
      :md-active.sync="deleteModal"
      md-title="Hasta kaydını silmek istediğinize emin misiniz?"
      md-content="Bu işlem geri alınamaz."
      md-confirm-text="Sil"
      md-cancel-text="İptal Et"
      @md-confirm="onConfirm"
    />
  </div>
</template>
<script>
import router from "../../router";
import { HTTP } from "../../main-source";

export default {
  name: "Navigation",
  data() {
    return {
      deleteModal: false
    };
  },
  props: ["id"],
  methods: {
    onConfirm: function() {
      HTTP.delete("/patients/?ids=" + this.id).then(result => {
        if (result.status == 204) {
          router.push({ name: "patients" });
        }
      });
    }
  },
  computed: {
    innerWidth: function() {
      return window.innerWidth;
    },
    getRoleId: function() {
      let role = localStorage.getItem("roleId");

      return role;
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/colors.scss";
.sub-nav {
  a,
  button {
    background: $white;
    border-left: 1px solid lighten($dark, 80%);
    border-top: 1px solid lighten($dark, 80%);
    border-bottom: 1px solid lighten($dark, 80%);
    padding: 8px 30px;
    color: $dark !important;
    text-decoration: none !important;
    &:first-child {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }
    &:last-child {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-right: 1px solid lighten($dark, 80%);
    }
  }
  .router-link-exact-active,
  .active {
    color: $brandcolor !important;
    font-weight: bold;
  }
}
.setLocation.md-top-right {
  top: unset !important;
  margin-top: -30px !important;
  button.md-fab {
    background: #ccc !important;
  }
}
</style>
