<template>
  <div>
    <Loading v-if="loading" />

    <md-dialog-prompt
      :md-active.sync="createNewModal"
      v-model="createConfirmationValues.name"
      md-title="Oluşturulacak Onay Şablonunu Adini Girin"
      md-input-maxlength="30"
      md-input-placeholder="Onay Şablonu Adini Giriniz"
      md-confirm-text="Oluştur"
      @md-confirm="createConfirmationContentAPI"
    />

    <md-dialog
      id="addTemplate"
      :md-active.sync="addTemplate"
      class="dialog"
      @md-clicked-outside="clickModalOutside"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>Yeni Onay Template Oluşturun</md-dialog-title>
      <md-content
        class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
      >
        <div class="md-layout-item md-size-30 md-xsmall-size-100">
          <label><b>Template Adı</b></label>
          <NewInput :width="'100%'">
            <md-input
              style="border: 0px solid; height: 30px"
              v-model="newTemplate.name"
            ></md-input>
          </NewInput>
        </div>
        <div class="md-layout-item md-size-30 md-xsmall-size-100">
          <label><b>Renk Kodu</b></label>
          <div>
            <NewInput :width="'40px'">
              <div style="display: flex">
                <input
                  v-model="newTemplate.color"
                  style="width: 50px"
                  type="color"
                />
                <md-input
                  style="
                    border: 0px solid;
                    width: 100%;
                    height: 30px;
                    padding-left: 10px;
                  "
                  v-model="newTemplate.color"
                  disabled
                ></md-input>
              </div>
            </NewInput>
          </div>
        </div>
        <div class="md-layout-item md-size-30 md-xsmall-size-100">
          <label><b>Doktor Seçiniz</b></label>
          <NewInput :width="'30%'">
            <md-select
              style="border: 0px solid white !important"
              v-model="newTemplate.userId"
              placeholder="Doktor Seçiniz"
            >
              <md-option
                v-for="doctor in allDoctors"
                v-bind:value="doctor.id"
                v-bind:key="doctor.id"
              >
                {{ doctor.name }}</md-option
              >
            </md-select>
          </NewInput>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100">
          <label><b>Footer</b></label>
          <md-field>
            <md-textarea v-model="newTemplate.footer"></md-textarea>
          </md-field>
        </div>
        <div class="md-layout md-size-50 md-xsmall-size-100">
          <!-- <a
            target="_blank"
            v-if="updateTemplate"
            :href="'images/epicrisis/' + newTemplate.imgPath"
            >Mevcut Dosyayı Görmek İçin Tıklanınız</a
          > -->

          <div style="display: flex">
            <FileUploadComp
              :value="logoFileUploadValue"
              subject="Onay Formu"
              :filetypeId="10"
              placeholder="Logo
            Yükleyiniz..."
              @onChangeValue="logoFileUpload"
              accept="image/png, image/gif, image/jpeg"
            />

            <FileUploadComp
              :value="watermarkFileUploadValue"
              subject="Onay Formu"
              :filetypeId="10"
              placeholder="Watermark Yükleyiniz..."
              @onChangeValue="watermarkFileUpload"
              accept="image/png, image/gif, image/jpeg"
            />
          </div>
          <!-- <file-upload
            class="customfileinput"
            input-id="IMG"
            :accept="'.png'"
            v-model="templateImg"
          >
            <md-icon>add</md-icon>IMG Yuklemek icin tiklayiniz (255 x 52)
          </file-upload> -->
        </div>
        <div class="md-layout md-size-50 md-xsmall-size-100">
          <!-- <a
            target="_blank"
            v-if="updateTemplate"
            :href="'images/epicrisis/' + newTemplate.watermarkPath"
            >Mevcut Dosyayı Görmek İçin Tıklanınız</a
          >
          <file-upload
            class="customfileinput"
            input-id="watermark"
            :accept="'.png'"
            v-model="templateWatermark"
          >
            <md-icon>add</md-icon>Watermark Yüklemek icin tiklayiniz (596 x 800)
          </file-upload> -->
        </div>
        <md-button
          v-if="!updateTemplate"
          class="md-raised md-primary"
          @click="createTemplate"
          >Oluştur</md-button
        >
        <md-button
          v-if="updateTemplate"
          class="md-raised md-primary"
          @click="editTemplate"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>

    <md-dialog
      id="addTemplate"
      :md-active.sync="showTemplates"
      class="dialog"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>Onay Template</md-dialog-title>
      <md-content
        style="width: 800px"
        class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
      >
        <md-table
          style="width: 100%"
          v-model="confirmationTemplates"
          md-sort="name"
          md-sort-order="asc"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Default">
              <md-radio
                @change="setDefaultTemplate(item)"
                :value="!item.default"
              ></md-radio>
            </md-table-cell>
            <md-table-cell md-label="Doktor">{{
              item.user.name
            }}</md-table-cell>
            <md-table-cell md-label="Dosya Adı">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="Footer">{{ item.footer }}</md-table-cell>
            <md-table-cell md-label="Renk">{{ item.color }}</md-table-cell>
            <md-table-cell
              style="width: 100px"
              md-label="Son Güncelleme Tarihi"
              >{{ item.updatedAt.slice(0, 10) }}</md-table-cell
            >
            <!-- <md-table-cell md-label="Son Güncelleyen" md-sort-by="lastUpdateUser">{{
              item.lastUpdateUser.name
            }}</md-table-cell> -->

            <md-table-cell style="width: 50px" md-label="Düzenle / Sil">
              <div class="">
                <md-button @click="openEditTemplate(item)" class="md-succes">
                  <md-icon>edit</md-icon>
                </md-button>
              </div>
              <div class="">
                <md-button
                  v-if="true"
                  class="md-accent"
                  v-on:click="deleteTemplate(item.guid)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-content>
    </md-dialog>

    <div class="md-layout details">
      <div class="md-layout-item md-size-100 md-xsmall-size-100 wrapper">
        <div class="md-layout">
          <span class="md-title title">Yeni Onay Şablonu Ekle</span>
        </div>
        <div
          class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
          v-if="allConfirmationContents"
        >
          <md-field class="md-layout-item md-xsmall-size-100 md-size-10">
            <v-select
              v-model="selectedLanguage"
              placeholder="Diller"
              :options="languages"
              :value="id"
              label="name"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-field
            class="md-layout-item calFilter md-xsmall-size-100 md-size-85"
          >
            <v-select
              v-model="selectedContent"
              placeholder="Dosyalar"
              :options="allConfirmationContents"
              :value="id"
              label="name"
              class="md-menu"
              :disabled="!selectedLanguage"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-button
            @click="createConfirmationContent"
            class="
                                                      "
            ><md-icon>note_add</md-icon> Yeni Oluştur</md-button
          >
          <md-button
            @click="showAddEpicrisisTemplate"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>note_add</md-icon> Yeni Template Oluştur</md-button
          >
          <md-button
            @click="showTemplates = true"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>note_add</md-icon>Template Listesi</md-button
          >
        </div>
      </div>
      <div class="md-layout-item wrapper md-size-100">
        <!-- sams -->
        <md-table
          v-model="allConfirmationContents"
          md-sort="name"
          md-sort-order="asc"
        >
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">Onay Şablonları</h1>
            </div>

            <md-field md-clearable class="md-toolbar-section-end">
              <md-input
                placeholder="Search by name..."
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Default">
              <md-radio
                @change="setDefaultContent(item)"
                :value="!item.default"
              ></md-radio>
            </md-table-cell>
            <md-table-cell md-label="Dosya Adı" md-sort-by="name">{{
              item.name
            }}</md-table-cell>
            <md-table-cell md-label="Dil" md-sort-by="lastUpdateUser">{{
              item.language.name
            }}</md-table-cell>
            <md-table-cell
              md-label="Son Güncelleme Tarihi"
              md-sort-by="updateAt"
              >{{ item.updatedAt }}</md-table-cell
            >
            <md-table-cell md-label="Son Güncelleyen" md-sort-by="updateAt">{{
              item.lastUpdateUser.name
            }}</md-table-cell>
            <md-table-cell md-label="Düzenle / Sil" md-sort-by="edit">
              <div class="">
                <router-link
                  :to="{
                    name: 'updateConfirmationTemplate',
                    params: {
                      guid: item.guid,
                    },
                  }"
                >
                  <md-button class="md-succes">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
              </div>
              <div class="">
                <md-button
                  v-if="true"
                  class="md-accent"
                  v-on:click="deleteConfirmationForm(item.guid)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-dialog-confirm
          :md-active.sync="deleteModal"
          md-title="Dosyayı silmek istediğinize emin misiniz?"
          md-content="Bu işlem geri alınamaz."
          md-confirm-text="Sil"
          md-cancel-text="İptal Et"
          @md-confirm="deleteConfirmationContentAPI()"
        />
      </div>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};
import { HTTP } from "@/main-source";
import moment from "moment";
import NewInput from "../../components/newInput";
import Loading from "../../components/Loading.vue";
import FileUploadComp from "../../components/fileUpload.vue";

export default {
  name: "Epicrisis",
  data() {
    return {
      newTemplate: {
        id: null,
        name: "",
        color: "#",
        footer: "",
        imgPath: null,
        watermarkPath: null,
        lastUpdateUserId: localStorage.getItem("userId"),
        isEpicrisis: false,
      },
      createConfirmationValues: {
        name: "",
      },
      confirmationContentJSON: {
        headerTitle: { label: "Treatment Confirmation Document", content: "" },
        confirmationId: { label: "confirmation", content: "11111-22222-33333" },
        date: { label: "date", content: "11111-22222-33333" },
        title: { label: "Your Operation Is Booked Successfully", content: "" },
        description: {
          label: "",
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        },
        postSurgeryGarment: {
          label: "VIP Transfer",
          content: "Included",
        },
        helplineWithExpertTeam: {
          label: "Helpline with expert team",
          content: "Included",
        },
        consultationWithDoctors: {
          label: "Consultation with Doctors",
          content: "Included",
        },
        consultation: { label: "Hotel Accommodation", content: "Included" },
        preOperativeTest: { label: "Pre-operative test", content: "Included" },
        allMedications: { label: "All Medications", content: "Included" },
        userNameSurname: {
          label: "Anastasiya Keskin",
          content: "PATIENT CONSULTANT",
          phone: "+90 507 396 00 80",
          image: "",
        },
        treatmentPackage: { label: "Treatment Package", content: "" },
        incPrice: { label: "Inc Package", content: "" },
        incPriceCurrency: { label: "", content: "TL" },
        balancedPrice: { label: "Balanced Price", content: "" },
        depositPrice: { label: "Deposit Price", content: "" },
        depositPriceCurrency: { label: "", content: "TL" },
        priceCurrency: { label: "", content: "TL" },
        personalInformations: { label: "PERSONAL INFORMATIONS", content: "" },
        nameSurname: { label: "Name Surname", content: "" },
        country: { label: "Country", content: "" },
        phone: { label: "Phone", content: "" },
        email: { label: "Email", content: "" },
        treatmentInformations: { label: "TREATMENT INFORMATIONS", content: "" },
        treatmentDateTime: { label: "Treatment Date & Time", content: "" },
        operationLocation: { label: "Operation Location", content: "" },
        staffDoctor: { label: "Surgeon Name", content: "" },
        transferInformations: { label: "TRANSFER INFORMATIONS", content: "" },
        vipTransfer: { label: "Vip Transfer", content: "" },
        transferManagingNameSurname: {
          label: "Transfer Managing Name Surname",
          content: "",
          userId: "",
        },
        airportToHotelHospital: {
          label: "Airport to Hotel/Hospital",
          content: "",
        },
        hospitalToHotelAddress: {
          label: "Hospital to Hotel/Address",
          content: "",
        },
        transferFromToHotelToAirport: {
          label: "Transfer to Hotel/Airport",
          content: "",
        },
        accommodation: { label: "ACCOMMODATION", content: "" },
        hotelName: { label: "Hotel Name", content: "", hotelId: "" },
        accommodationDay: { label: "Accommodation Day", content: "" },
        roomTypeAdultsChildren: {
          label: "Room Type Adults/ Children",
          content: "",
          roomTypeId: "",
        },
        checkInDate: { label: "Check-in Date", content: "", time: "" },
        checkOutDate: { label: "Check-out Date", content: "", time: "" },
        flightDetails: {
          label: "FLIGHT DETAILS",
          arrivalLabel: "Gidiş Bilgileri",
          returnLabel: "Dönüş Bilgileri",
          content: "",
        },
        arrivalAirport: {
          label: "Arrival: Airport",
          content: "",
          airportId: "",
        },
        dateAndTime: { label: "Date and time", content: "", time: "" },
        returnAirport: { label: "Return: Airport", content: "", airportId: "" },
        returnDateAndTime: {
          label: "Return Date and time",
          content: "",
          time: "",
        },
        flightCode: { label: "Flight Code", content: "" },
        flightAirline: { label: "Flight Airline", content: "", id: "" },
        notes: { label: "NOTES", content: "" },
        visibleAreas: {
          transferInfos: true,
          hotelInfos: true,
          flightInfos: true,
          notesInfos: true,
          flightArrivalInfos: true,
          flightReturnInfos: true,
        },
      },
      createNewModal: false,
      allDoctors: [],
      selectedDoctor: "",
      allConfirmationContents: [],
      languages: [],
      selectedLanguage: null,
      imgUpdated: false,
      watermarkUpdated: false,
      confirmationTemplates: [],
      templateImg: [],
      templateWatermark: [],
      addTemplate: false,
      showTemplates: false,
      epicrisisName: "",
      deleteModal: false,
      search: null,
      searched: [],
      lastUpdateUserName: "",
      selectedContent: null,
      selectedEventGuid: null,
      created: false,
      isDietician: localStorage.getItem("roleId") == 9,
      isDoctor: localStorage.getItem("roleId") == 2,
      userId: localStorage.getItem("userId"),
      loading: true,
      apiLink:
        process.env.NODE_ENV == "production"
          ? process.env.VUE_APP_API_URL + "images/epicrisis/storage"
          : process.env.VUE_APP_API_URL,
      logoFileUploadValue: {
        isSuccess: null,
        file: {},
      },
      watermarkFileUploadValue: {
        isSuccess: null,
        file: {},
      },
    };
  },
  created() {
    this.getLanguages();
    this.getConfirmationTemplates();
    this.getConfirmationContents();
    this.getDoctors();
    this.getCurrencies();
    this.loading = false;
  },
  methods: {
    getLanguages() {
      HTTP.get("/languages/").then((result) => {
        this.languages = result.data;
      });
    },
    getConfirmationTemplates() {
      HTTP.get("/confirmationTemplates/").then((result) => {
        this.confirmationTemplates = result?.data;
      });
    },
    getConfirmationContents() {
      HTTP.get("/confirmationContents/").then((result) => {
        this.allConfirmationContents = result.data;
        this.loading = false;
      });
    },
    getDoctors() {
      HTTP.get("/users/role/2").then((result) => {
        this.allDoctors = result.data;
      });
    },
    getCurrencies() {
      HTTP.get("currency").then((currencies) => {
        this.confirmationContentJSON.incPriceCurrency.content =
          currencies.data[0]?.slug;
        this.confirmationContentJSON.depositPriceCurrency.content =
          currencies.data[0]?.slug;
      });
    },
    createConfirmationContent() {
      if (this.selectedLanguage == null) {
        this.$swal({
          icon: "warning",
          text: "Lütfen Onay Şablonu Dilini Girdiginizden Emin Olun",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        this.createNewModal = true;
      }
    },
    createConfirmationContentAPI() {
      HTTP.post("/confirmationContents", {
        name: this.createConfirmationValues.name,
        content: JSON.stringify(this.confirmationContentJSON),
        languageId: this.selectedLanguage.id,
      })
        .then((response) => {
          if (response.status == 200) {
            //this.allConfirmationContents.push(response.data)
            this.getConfirmationContents();
          }
        })
        .catch((err) => {});
    },
    deleteConfirmationContentAPI() {
      HTTP.delete("/confirmationContents/" + this.selectedEventGuid).then(
        (response) => {
          if (response.status == 200) {
            this.allConfirmationContents = this.allConfirmationContents.filter(
              (x) => x.guid != this.selectedEventGuid
            );
          }
        }
      );
    },
    showAddEpicrisisTemplate() {
      this.newTemplate = {};
      this.addTemplate = true;
      this.updateTemplate = false;
    },
    openEditTemplate(item) {
      this.newTemplate.guid = item.guid;
      this.newTemplate.name = item.name;
      this.newTemplate.color = item.color;
      this.newTemplate.footer = item.footer;
      this.newTemplate.imgPath = item.img;
      this.newTemplate.watermarkPath = item.watermark;
      this.newTemplate.lastUpdateUserId = this.userId;
      this.newTemplate.userId = item.userId;
      this.newTemplate.id = item.id;
      this.updateTemplate = true;
      this.addTemplate = true;
      this.showTemplates = false;
      this.imgUpdated = false;
      this.watermarkUpdated = false;

      this.logoFileUploadValue = {
        isSuccess: item.img ? true : null,
        file: {
          name: "Logo",
          base64: item.img,
        },
      };

      this.watermarkFileUploadValue = {
        isSuccess: item.watermark ? true : null,
        file: {
          name: "Watermark",
          base64: item.watermark,
        },
      };
    },
    clickModalOutside() {
      this.addTemplate = false;
      this.clearFilesValues();
    },
    async editTemplate() {
      const validation = await this.formValidationControl();

      if (validation) {
        let errorMessage = "Lütfen gerekli alanları doldurunuz. <br />";

        for (let i in validation) {
          errorMessage += "* " + validation[i]?.message + "<br/>";
        }

        this.$swal("Uyarı", errorMessage, "warning");
      } else {
        this.editTemplateRequest();
      }
    },
    async editTemplateRequest() {
      await HTTP.put("/confirmationTemplates/" + this.newTemplate?.guid, {
        name: this.newTemplate?.name,
        color: this.newTemplate?.color,
        footer: this.newTemplate?.footer,
        img: this.newTemplate?.imgPath,
        watermark: this.newTemplate?.watermarkPath,
        userId: this.newTemplate?.userId,
      })
        .then((result) => {
          this.$swal({
            icon: result?.data?.isSuccess ? "success" : "warning",
            text: result?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.updateTemplate = false;
          this.imgUpdated = false;
          this.watermarkUpdated = false;
          this.addTemplate = false;
          this.getConfirmationTemplates();
        })
        .catch((err) => {});
    },
    deleteTemplate(guid) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlem geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/confirmationTemplates/" + guid)
            .then((result) => {
              this.$swal({
                icon: result?.data?.isSuccess ? "success" : "warning",
                text: result?.data?.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getConfirmationTemplates();
            })
            .catch((err) => {});
        }
      });
    },
    async formValidationControl() {
      let control = [];

      const requiredFields = [
        {
          fieldName: "name",
          message: "Lütfen template adını giriniz.",
        },
        {
          fieldName: "footer",
          message: "Lütfen footer alanınızı giriniz.",
        },
        {
          fieldName: "color",
          message: "Lütfen renk seçiniz.",
        },
        {
          fieldName: "imgPath",
          message: "Lütfen logo yükleyiniz.",
        },
        {
          fieldName: "watermarkPath",
          message: "Lütfen watermark yükleyiniz.",
        },
        {
          fieldName: "userId",
          message: "Lütfen doktor seçiniz.",
        },
      ];

      for (let i in requiredFields) {
        if (this.newTemplate[requiredFields[i]?.fieldName]) {
          this.newTemplate[requiredFields[i]?.fieldName].toString()?.length > 0
            ? null
            : control.push(requiredFields[i]);
        } else {
          control.push(requiredFields[i]);
        }
      }

      return control?.length > 0 ? control : false;
    },
    async createTemplate() {
      const validation = await this.formValidationControl();

      if (validation) {
        let errorMessage = "Lütfen gerekli alanları doldurunuz. <br />";

        for (let i in validation) {
          errorMessage += "* " + validation[i]?.message + "<br/>";
        }

        this.$swal("Uyarı", errorMessage, "warning");
      } else {
        this.createTemplateRequest();
      }
    },
    async createTemplateRequest() {
      HTTP.post("/confirmationTemplates/", {
        name: this.newTemplate?.name,
        color: this.newTemplate?.color,
        footer: this.newTemplate?.footer,
        img: this.newTemplate?.imgPath,
        watermark: this.newTemplate?.watermarkPath,
        userId: this.newTemplate?.userId,
      })
        .then((result) => {
          this.$swal({
            icon: result?.data?.isSuccess ? "success" : "warning",
            text: result?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });

          this.addTemplate = false;
          this.getConfirmationTemplates();
          this.clearFilesValues();
        })
        .catch((err) => {
          this.$swal({
            icon: err.response?.data?.isSuccess ? "success" : "warning",
            text: err.response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deleteConfirmationForm: function(guid) {
      this.selectedEventGuid = guid;
      this.deleteModal = true;
    },
    searchOnTable() {
      this.searched = searchByName(this.allConfirmationContents, this.search);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    setDefaultContent(item) {
      this.loading = true;

      HTTP.put("/confirmationContents/default/" + item?.guid).then((result) => {
        this.$swal({
          icon: result?.data?.isSuccess ? "success" : "warning",
          text: result?.data?.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getConfirmationContents();
      });
    },
    setDefaultTemplate(item) {
      HTTP.put("/confirmationTemplates/default/" + item?.guid).then(
        (result) => {
          this.$swal({
            icon: result?.data?.isSuccess ? "success" : "warning",
            text: result?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getConfirmationTemplates();
        }
      );
    },
    clearFilesValues() {
      this.logoFileUploadValue = {
        isSuccess: null,
        file: {
          name: "Logo",
          base64: "",
        },
      };

      this.watermarkFileUploadValue = {
        isSuccess: null,
        file: {
          name: "Logo",
          base64: "",
        },
      };
    },
    logoFileUpload(val) {
      if (val.isSuccess) {
        this.newTemplate.imgPath = val.file.base64;
        this.logoFileUploadValue = {
          isSuccess: true,
          file: {
            name: "Watermark",
            base64: val.file.base64,
          },
        };
      }
    },
    watermarkFileUpload(val) {
      if (val.isSuccess) {
        this.newTemplate.watermarkPath = val.file.base64;
        this.watermarkFileUploadValue = {
          isSuccess: true,
          file: {
            name: "Watermark",
            base64: val.file.base64,
          },
        };
      }
    },
  },
  computed: {},
  components: {
    NewInput,
    Loading,
    FileUploadComp,
  },
  watch: {
    templateImg() {
      this.imgUpdated = true;
    },
    templateWatermark() {
      this.watermarkUpdated = true;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.md-select-menu {
  max-width: fit-content !important;
  width: fit-content !important;
}

.add-field {
  margin-left: 15px !important;
}

.add-button {
  margin-left: 15px !important;
}

.participants {
  display: block;

  span {
    display: inline-block;
  }
}

.table {
  width: 100%;
  margin-top: 0px;
}

.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}

.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;

  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}

.appointments {
  .md-table-row {
    cursor: pointer;
  }
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.pad {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;

    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}

.add-button {
  margin-left: 1rem;
}

.calFilter {
  min-width: 160px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
</style>
