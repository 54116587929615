<template>
  <div class="wrap md-layout-item md-size-25 md-xsmall-size-100">
    <router-link :to="{name:'users.profile.general',params:{id:id}}">
      <md-card>
        <md-avatar class="avatar">
          <img :src="imgUrl ? imgUrl : '/images/avatar.jpg'" alt="Avatar" />
        </md-avatar>
        <md-card-content>
          <span class="title">{{ name }}</span>
          {{ role.name }}
        </md-card-content>
      </md-card>
    </router-link>
  </div>
</template>

<script scoped>
export default {
  name: "userList",
  data() {
    return {};
  },
  props: ["id", "name", "role", "imgUrl"]
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 198px;
  margin: 15px 0px 45px 0px !important;
  text-align: center;
  transition: all 0.3s ease !important;
  &:hover {
    transform: scale(1.03);
  }
  .avatar {
    width: 72px;
    height: 72px;
    margin-top: -40px !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .title {
    display: block;
    font-weight: bold;
  }
  .md-card-content {
    padding: 10px;
  }
  .md-card-content:last-of-type {
    padding-bottom: 10px;
  }
  a {
    color: inherit;
    text-decoration: none !important;
  }
}
.md-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04) !important;
}
@media (max-width: 767.98px) {
  .title {
    margin: 0;
  }
}
</style>