<template>
  <div class="list">
    <span class="bold">{{ patientName }}</span>
    <span>{{ locationName }}</span>
    <span>{{ date }} - {{ time }}</span>
  </div>
</template>
<script>
export default {
  name: "appointmentList",
  data() {
    return {};
  },
  props: ["patientName", "locationName", "date", "time"]
};
</script>

<style lang="scss" scoped>
.list {
  padding: 15px;
  background: #f7f7f7;
  margin-top: 10px;
  span {
    display: block;
  }
  .bold {
    font-weight: bold;
  }
}
</style>