<template>
  <div>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <div class="md-layout details">Transferler</div>
  </div>
</template>

<script>
import { HTTP } from "../../main-source";
import Navigation from "../../components/patients/nav";
export default {
  name: "Transfers",
  data() {
    return {
      patients: [],
      patientsId: this.$route.params.id
    };
  },
  components: {
    Navigation
  },
  created() {
    HTTP.get("/patients/" + this.patientsId).then(result => {
      this.patients = result.data[0];
    });
  }
};
</script>

<style lang="scss">
</style>
