<template>
  <div>
    <div class="md-layout">
      <h1 style="margin-left:20px">{{ title }}</h1>
    </div>
    <div class="md-layout">
      <div class="container">
        <div class="md-layout-item">
          <md-field>
            <label>Hasta Adı / Telefon</label>
            <md-input v-model="search_query"></md-input>
            <md-icon>search</md-icon>
          </md-field>
        </div>
        <md-switch
          v-if="roleId != 1 && roleId != 2 && roleId != 4 && roleId != 9"
          style="margin-left:20px"
          class="md-primary"
          v-model="validateSwitch"
          >Sadece hastalarımı göster</md-switch
        >
      </div>
      <div class="md-layout-item">
        <md-speed-dial
          class="md-top-right"
          md-direction="bottom"
          md-event="click"
          v-if="!isDoctor"
        >
          <router-link
            :to="{
              name: 'patient.create',
            }"
          >
            <md-speed-dial-target class="md-primary">
              <md-button class="md-icon-button">
                <md-icon>add</md-icon>
              </md-button>
            </md-speed-dial-target>
          </router-link>
        </md-speed-dial>
      </div>
    </div>

    <!-- Filter Section -->
    <div class="md-layout" style="margin-left:20px">
      <!-- Filter Start -->
      <div v-if="!selectingAgenciesChild" class="filters-container">
        <NewInput v-if="validateSwitch">
          <v-select
            @click.native="clickFilterInput('users')"
            v-model="filters.users"
            placeholder="Temsilciler"
            :options="options.users"
            label="name"
            :close-on-select="false"
            multiple
          >
            <template slot="option" slot-scope="option"
              >{{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(filters.users, option)
              }}</span>
            </template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            @click.native="clickFilterInput('countries')"
            v-model="filters.countries"
            placeholder="Ülke"
            :options="options.countries"
            label="name_tr"
            class="md-menu"
            :close-on-select="false"
            multiple
          >
            <template slot="option" slot-scope="option"
              >{{ option.name_tr }}
              <span class="optionSelected">{{
                checkIfSelectedCountry(filters.countries, option)
              }}</span>
            </template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            @click.native="clickFilterInput('languages')"
            v-model="filters.languages"
            placeholder="Dil"
            :options="options.languages"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option"
              >{{ option.name }}
              <span class="optionSelected">{{
                checkIfSelected(filters.languages, option)
              }}</span></template
            >
          </v-select>
        </NewInput>

        <!-- Agencies -->
        <NewInput>
          <v-select
            @click.native="clickFilterInput('agencies')"
            v-model="filters.parentAgencie"
            placeholder="Ana Kaynak"
            :options="childDontShow(options.agencies)"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option"
              >{{ option.name
              }}<span class="optionSelected">{{
                checkIfSelected(filters.parentAgencie, option)
              }}</span></template
            >
          </v-select>
        </NewInput>
        <!-- Agencies End -->

        <md-button
          :disabled="
            filters.users ||
            filters.parentAgencie ||
            filters.countries ||
            filters.languages ||
            filters.agencieChild
              ? false
              : true
          "
          style="border: 2px solid rgb(213, 213, 213); border-radius: 5px;"
          @click="clearFilters"
        >
          Temizle
        </md-button>
      </div>
      <!-- Filter End -->

      <!-- Filters With Child Agencies Start -->
      <div v-if="selectingAgenciesChild">
        <!-- Top Navbar -->
        <div class="filters-container">
          <NewInput v-if="validateSwitch">
            <v-select
              @click.native="clickFilterInput('users')"
              v-model="filters.users"
              placeholder="Temsilciler"
              :options="options.users"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.users, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('countries')"
              v-model="filters.countries"
              placeholder="Ülke"
              :options="options.countries"
              label="name_tr"
              class="md-menu"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name_tr }}
                <span class="optionSelected">{{
                  checkIfSelectedCountry(filters.countries, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('languages')"
              v-model="filters.languages"
              placeholder="Dil"
              :options="options.languages"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.languages, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <md-button
            :disabled="
              filters.users ||
              filters.countries ||
              filters.languages ||
              filters.parentAgencie ||
              filters.agencieChild
                ? false
                : true
            "
            style="border: 2px solid rgb(213, 213, 213); border-radius: 5px;"
            @click="clearFilters"
          >
            Temizle
          </md-button>
        </div>
        <!-- Divider -->
        <div style="width:100%;display:flex;align-items: center;">
          <div
            style="width:100%; height: 1px; background-color: gray;margin-bottom: 20px;margin-top: 15px;opacity:0.4;color : gray"
          ></div>
        </div>

        <!-- Agencie & Sub Agencie Section -->
        <div style="display:flex;flex-wrap: wrap;">
          <!-- Agencie Field -->
          <NewInput>
            <v-select
              @click.native="clickFilterInput('agencies')"
              v-model="filters.parentAgencie"
              placeholder="Ana Kaynak"
              :options="childDontShow(options.agencies)"
              label="name"
              class="md-menu"
              :disabled="isDisabledWatcher"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name
                }}<span class="optionSelected">{{
                  checkIfSelected(filters.parentAgencie, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <div :key="index" v-for="(item, index) in filters.parentAgencie">
            <NewInput width="250" v-if="item.childAgencies.length > 0">
              <v-select
                v-model="filters.agencieChild[index]"
                :placeholder="item.name + ' Alt Kaynak'"
                :options="childIsDeletedControl(item.childAgencies)"
                label="name"
                class="md-menu"
                multiple
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                </template>
              </v-select>
            </NewInput>
          </div>
        </div>
      </div>
    </div>

    <Loading v-if="loading" />

    <div class="md-layout patientlist">
      <patientlist
        v-for="patient in patients"
        :name="patient.name"
        :phone="patient.phone"
        :imgUrl="patient.imgUrl"
        :country="patient.country"
        :key="patient.id"
        :id="patient.id"
        :userId="patient.userId"
        :disabled="patient.disabled"
        :userName="patient.user"
        :validateSwitch="validateSwitch"
        :patient="patient"
      ></patientlist>
    </div>
    <div class="pagination-container">
      <paginate
        :page-count="paginationNumbers"
        :click-handler="clickCallback"
        prev-text="Geri"
        prev-class="page-item"
        next-class="page-item"
        prev-link-class="page-item-link"
        next-link-class="page-item-link"
        active-class="selected-page-item"
        next-text="İleri"
        container-class="pagination"
        page-class="page-item"
        page-link-class="page-item-link"
        v-model="activePage"
      />
    </div>
  </div>
</template>

<script>
import patientlist from "../../components/patients/list";
import { HTTP } from "../../main-source";
import Loading from "../../components/Loading.vue";
import NewInput from "@/components/newInput";

export default {
  name: "Patients",
  data() {
    return {
      title: "Hastalar",
      isDisabledWatcher: false,
      loading: true,
      patients: [],
      search_query: "",
      userId: localStorage.getItem("userId"),
      roleId: localStorage.getItem("roleId"),
      leading: JSON.parse(localStorage.getItem("leading")),
      isDoctor: localStorage.getItem("roleId") == 2,
      isHospitalCoordinator: localStorage.getItem("roleId") == 4,
      isDietitian: localStorage.getItem("roleId") == 9,
      searching: false,
      lastSearchField: "",
      validateSwitch: true,
      accessRoleIDs:
        localStorage.getItem("roleId") == 1 ||
        localStorage.getItem("roleId") == 2 ||
        localStorage.getItem("roleId") == 4 ||
        localStorage.getItem("roleId") == 9,
      paginationNumbers: 10,
      activePage: 1,
      activeAPI: "",
      selectingAgenciesChild: false,
      options: {
        users: [],
        countries: [],
        agencies: [],
        childAgencies: [],
        languages: [],
      },
      filters: {
        users: null,
        countries: null,
        agencies: [],
        languages: null,
        parentAgencie: null,
        agencieChild: [],
      },
    };
  },
  components: {
    patientlist,
    Loading,
    NewInput,
  },
  async created() {
    await this.getRepresentatives();
    await this.getAgencies();
    await this.getLanguages();
    await this.getCountries();
    if (this.accessRoleIDs) {
      this.getPatientsAll();
      this.activeAPI = "getPatientsAll";
    } else {
      this.getPatients();
      this.activeAPI = "getPatients";
    }
  },
  methods: {
    getPatients: async function() {
      this.loading = true;
      //let uri = "/patients/?limit=20";
      let uri = "/patients/basicGet";
      uri += `?myPatient=${this.validateSwitch}`;
      uri += `&page=${this.activePage}`;

      await HTTP.get(uri).then((result) => {
        this.patients = result?.data?.rows;
        this.paginationNumbers = result?.data?.count + 1;
        this.activeAPI = "getPatients";
        this.loading = false;
      });
    },
    searchPatient: async function(q) {
      this.loading = true;
      let uri = "/patients/basicGetSearch";
      uri += `?myPatient=${this.validateSwitch}`;
      uri += `&page=${this.activePage}`;

      this.search_query !== "" ? (uri += `&q=${this.search_query}`) : "";

      this.filters?.users?.map((u) => {
        uri += `&agents=${u?.id}`;
      });
      this.filters?.languages?.map((l) => {
        uri += `&languages=${l?.id}`;
      });
      this.filters?.countries?.map((c) => {
        uri += `&countries=${c?.code}`;
      });
      this.filters?.agencies?.map((a) => {
        uri += `&agencies=${a?.id}`;
      });

      await HTTP.get(uri).then((response) => {
        this.patients = response?.data?.rows;
        this.paginationNumbers = response?.data?.count + 1;
        this.activeAPI = "searchPatient";
        this.loading = false;
      });
    },
    searchPatientAll: async function(q, page) {
      this.loading = true;
      let uri = "/patients/basicGetSearchAllView";
      uri += `?page=${this.activePage}`;

      this.search_query !== "" ? (uri += `&q=${this.search_query}`) : "";

      this.filters?.users?.map((u) => {
        uri += `&agents=${u?.id}`;
      });
      this.filters?.languages?.map((l) => {
        uri += `&languages=${l?.id}`;
      });
      this.filters?.countries?.map((c) => {
        uri += `&countries=${c?.code}`;
      });
      this.filters?.agencies?.map((a) => {
        uri += `&agencies=${a?.id}`;
      });

      await HTTP.get(uri).then((response) => {
        this.patients = response?.data?.rows;
        this.paginationNumbers = response?.data?.count + 1;
        this.activeAPI = "searchPatientAll";
        this.loading = false;
      });
    },
    getPatientsAll: async function() {
      this.loading = true;

      let uri = "/patients/basicGetAllView";
      uri += `?page=${this.activePage}`;
      await HTTP.get(uri).then((result) => {
        this.patients = result?.data?.rows;
        this.paginationNumbers = result?.data?.count + 1;
        this.activeAPI = "getPatientsAll";
        this.loading = false;
      });
    },
    async getRepresentatives() {
      await HTTP.get(
        "/patients/getRepresentativesByAuthUser?myPatient=" + this.accessRoleIDs
      ).then((result) => {
        this.options.users = result.data;
      });
    },
    async getCountries() {
      const countries = await HTTP.get("/countries");
      this.options.countries = [
        { id: null, code: null, name: "Boş", name_tr: "Boş" },
        ...countries.data,
      ];
    },
    async getAgencies() {
      const agencies = await HTTP.post("/permissions/userDatas/getAgencies");
      this.options.agencies = [{ id: null, name: "Boş" }, ...agencies.data];
    },
    async getLanguages() {
      const languages = await HTTP.get("/languages");
      this.options.languages = [
        { id: null, code: null, name: "Boş" },
        ...languages.data,
      ];
    },
    clearFilters() {
      this.isDisabledWatcher = false;
      this.filters = {
        users: null,
        countries: null,
        agencies: null,
        languages: null,
        parentAgencie: null,
        agencieChild: [],
      };

      this.selectingAgenciesChild = false;

      if (this.accessRoleIDs) {
        if (this.search_query) {
          this.searchPatientAll(this.search_query);
        } else {
          this.getPatientsAll();
        }
      } else {
        if (this.search_query) {
          this.searchPatient(this.search_query);
        } else {
          this.getPatients();
        }
      }
    },
    clickCallback(pageNum) {},
    checkIfSelected(model, val) {
      return model && model.find((z) => z.id == val.id) ? "✓" : "";
    },
    checkIfSelectedCountry(model, val) {
      return model && model.find((z) => z.code == val.code) ? "✓" : "";
    },
    clickFilterInput(item) {
      const inputOptionsToCall = {
        users: {
          value: this.options.users,
          optionsMethodToCall: this.getUsers,
        },
        operations: {
          value: this.options.operations,
          optionsMethodToCall: this.getOperations,
        },
        countries: {
          value: this.options.countries,
          optionsMethodToCall: this.getCountries,
        },
        agencies: {
          value: this.options.agencies,
          optionsMethodToCall: this.getAgencies,
        },
        patientStatus: {
          value: this.options.patientStatus,
          optionsMethodToCall: this.getPatientStatus,
        },
        languages: {
          value: this.options.languages,
          optionsMethodToCall: this.getLanguages,
        },
      };

      if (inputOptionsToCall[item]?.value.length === 0) {
        const methodToCall = inputOptionsToCall[item]?.optionsMethodToCall;
        if (typeof methodToCall === "function") {
          methodToCall.call(this);
        }
      }
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item?.isDeleted == false && item
      );
    },
    childIsDeletedControl(val) {
      return val.filter((item) => item.isDeleted == 0 && item);
    },
  },
  watch: {
    search_query: function(query) {
      if (this.accessRoleIDs) {
        this.searchPatientAll(query);
      } else {
        this.searchPatient(query);
      }
    },
    validateSwitch() {
      if (this.accessRoleIDs) {
        if (this.search_query.length > 0) {
          this.searchPatientAll(this.search_query);
        } else {
          this.getPatientsAll();
        }
      } else {
        if (this.search_query.length > 0) {
          this.searchPatient(this.search_query);
        } else {
          this.getPatients();
        }
      }
    },
    activePage() {
      this[this.activeAPI](this.search_query);
    },
    activeAPI() {
      this.activePage = 1;
    },
    "filters.users"() {
      if (this.accessRoleIDs) {
        this.searchPatientAll();
      } else {
        this.searchPatient();
      }
    },
    "filters.countries"() {
      if (this.accessRoleIDs) {
        this.searchPatientAll();
      } else {
        this.searchPatient();
      }
    },
    "filters.parentAgencie"(val) {
      let flatVal = val?.flat();
      let sendValue = this.filters.agencieChild;
      let flatSendValue = this.filters.agencieChild?.flat();

      // CHILD LAYOUT CONTROL - START
      let control = [];
      val?.map((item) => item?.childAgencies?.length > 0 && control.push(true));

      if (JSON.stringify(control).match(true)) {
        this.selectingAgenciesChild = true;
      } else {
        this.selectingAgenciesChild = false;
      }
      // CHILD LAYOUT CONTROL - END

      this.filters.agencies = [];
      if (flatSendValue?.length > 0 && val?.length > 0) {
        this.filters.agencies = flatSendValue;

        let ids = [];
        for (let i in sendValue) {
          for (let j in sendValue[i]) {
            ids.push(sendValue[i][j].id);
          }
        }

        let hasChildAgencies = [];
        let someths = val.filter((parent) => parent?.childAgencies?.length > 0);

        someths.map((pC) => {
          let childs = pC.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        const leftParentAgencies = val.filter(
          (p) => !hasChildAgencies.includes(p.id)
        );

        if (leftParentAgencies?.length > 0) {
          leftParentAgencies.map((l) => this.filters.agencies.push(l));
        }

        let childs = [];
        leftParentAgencies.map((v) =>
          childs.push(v.childAgencies?.map((z) => z))
        );
        let flattedChild = childs.flat();

        flattedChild.forEach((f) => this.filters.agencies.push(f));

        if (this.accessRoleIDs) {
          this.searchPatientAll();
        } else {
          this.searchPatient();
        }
      } else if (val != null && val?.length > 0) {
        let res = [];
        val?.map((v) => res.push(v));

        let childs = [];
        val.map((v) => childs.push(v?.childAgencies?.map((z) => z)));
        let flattedChild = childs?.flat();
        flattedChild.map((f) => res?.push(f));

        this.filters.agencies = res;

        if (this.accessRoleIDs) {
          this.searchPatientAll();
        } else {
          this.searchPatient();
        }
      } else {
        this.filters.parentAgencie = null;
        this.filters.agencieChild = [];
        this.filters.agencies = this.options.agencies;

        if (this.accessRoleIDs) {
          this.searchPatientAll();
        } else {
          this.searchPatient();
        }
      }
    },
    "filters.agencieChild"(val) {
      // Get Selected Child Agencies' ids

      if (this.selectingAgenciesChild) {
        let ids = [];
        for (let i in val) {
          for (let j in val[i]) {
            ids.push(val[i][j].id);
          }
        }

        if (ids?.length > 0) {
          this.isDisabledWatcher = true;
        } else {
          this.isDisabledWatcher = false;
        }

        let flatVal = val.flat();
        let flatSendValue = this.filters.parentAgencie?.flat();
        let sendValue = this.filters.parentAgencie;

        this.filters.agencies = [];

        if (sendValue?.length > 0 && flatVal?.length > 0) {
          let res = [];
          flatVal?.map((f) => res.push(f));
          // this.filters.agencies = flatVal;

          let hasChildAgencies = [];
          let someths = flatSendValue.filter(
            (parent) => parent?.childAgencies?.length > 0
          );

          someths.map((pC) => {
            let childs = pC.childAgencies.map((z) => z.id);

            childs.map((c) => {
              if (ids.includes(c)) hasChildAgencies.push(pC.id);
            });
          });

          const leftParentAgencies = flatSendValue.filter(
            (p) => !hasChildAgencies.includes(p.id)
          );

          if (leftParentAgencies?.length > 0) {
            leftParentAgencies.map((l) => res.push(l));
          }

          let childs = [];
          leftParentAgencies.map((v) => childs.push(v?.childAgencies?.map((z) => z)));
          let flattedChild = childs?.flat();
          flattedChild.map((f) => res?.push(f));

          this.filters.agencies = res;

          if (this.accessRoleIDs) {
            this.searchPatientAll();
          } else {
            this.searchPatient();
          }
        } else if (sendValue?.length > 0) {
          let res = [];
          sendValue?.map((v) => res.push(v));

          let childs = [];
          sendValue.map((v) => childs.push(v?.childAgencies?.map((z) => z)));
          let flattedChild = childs?.flat();
          flattedChild.map((f) => res?.push(f));

          this.filters.agencies = res;

          if (this.accessRoleIDs) {
            this.searchPatientAll();
          } else {
            this.searchPatient();
          }
        } else {
          this.filters.parentAgencie = null;
          this.filters.agencieChild = [];
          this.filters.agencies = this.options.agencies;

          if (this.accessRoleIDs) {
            this.searchPatientAll();
          } else {
            this.searchPatient();
          }
        }
      }
    },
    "filters.languages"() {
      if (this.accessRoleIDs) {
        this.searchPatientAll();
      } else {
        this.searchPatient();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";
.patientlist {
  margin-top: 7px !important;
  margin: -15px;
  .md-layout-item {
    min-width: 31.1%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    background: $white;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.03);
    }
  }
}

.filters-container {
  width: 100%;
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.container {
  width: 80%;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.pagination-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item {
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid #eae7e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item:hover {
  cursor: pointer;
  background: rgb(219, 219, 219);
}

.selected-page-item {
  width: 40px;
  height: 40px;
  background: #aed3f5;
  border: 1px solid #eae7e9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.selected-page-item:hover {
  background: #aed3f5;
}

.page-item-link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .patientlist {
    padding: 15px;
  }
  .container {
    flex-direction: column;
    width: "100%";
    margin-left: 8vw;
  }
}
</style>
