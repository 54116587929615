<template>
  <div class="wrapper">
    <span class="md-title">Aylık İndirimli İşlem Limitleri</span>
    <md-button
      class="md-fab md-mini md-primary editButton"
      v-on:click="addCustomLimitModal = true"
    >
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="customLimits">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Branş">{{
          _branchName(item.branchId)
        }}</md-table-cell>
        <md-table-cell md-label="Tip">{{
          _operationTypeName(item.operationType)
        }}</md-table-cell>
        <md-table-cell md-label="Ay">{{
          _formatDate(item.date)
        }}</md-table-cell>
        <md-table-cell md-label="Limit">{{ item.limit }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i
            class="md-icon md-icon-font md-theme-default"
            v-on:click="updateCustomLimit(item.id)"
            >edit</i
          >
          <i
            class="md-icon md-icon-font md-theme-default"
            v-on:click="deleteCustomLimit(item.id)"
            >delete</i
          >
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addCustomLimitModal" class="dialog">
      <md-content class="statuses">
        <h3>Ekle</h3>

        <md-field>
          <label for="operationType">Operasyon</label>
          <md-select v-model="customLimit.branchId">
            <md-option
              v-for="item in branches"
              v-bind:value="item.id"
              v-bind:key="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>

        <md-field>
          <label for="operationType">Operasyon Tipi</label>
          <md-select v-model="customLimit.operationType">
            <md-option value="surgery">Ameliyat</md-option>
            <md-option value="nonSurgery">Ameliyatsız</md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Limit</label>
          <md-input v-model="customLimit.limit" type="number"></md-input>
        </md-field>

        <md-field>
          <label for="selected_date">Ay</label>
          <md-select v-model="customLimit.date">
            <md-option v-for="item in months" v-bind:value="item" v-bind:key="item">{{
              item
            }}</md-option>
          </md-select>
        </md-field>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="addCustomLimit"
          >Ekle</md-button
        >
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateCustomLimitModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label for="operationType">Operasyon</label>
          <md-select v-model="customLimit.branchId">
            <md-option
              v-for="item in branches"
              v-bind:value="item.id"
              v-bind:key="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>

        <md-field>
          <label for="operationType">Operasyon Tipi</label>
          <md-select v-model="customLimit.operationType">
            <md-option value="surgery">Ameliyat</md-option>
            <md-option value="nonSurgery">Ameliyatsız</md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Limit</label>
          <md-input v-model="customLimit.limit" type="number"></md-input>
        </md-field>

        <md-field>
          <label for="selected_date">Ay</label>
          <md-select v-model="customLimit.date">
            <md-option v-for="item in months" v-bind:value="item" v-bind:key="item">{{
              item
            }}</md-option>
          </md-select>
        </md-field>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateCustomLimitDone"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import moment from "moment";

export default {
  name: "CustomDiscountLimits",
  data() {
    return {
      operationTypes: {
        surgery: "Ameliyat",
        nonSurgery: "Ameliyatsız",
      },
      customLimits: [],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
      customLimit: {
        date: null,
        limit: 0,
        operationType: "surgery",
        branchId: null,
      },
      branches: [],
      addCustomLimitModal: false,
      updateCustomLimitModal: false,
    };
  },
  created() {
    this.getCustomLimits();
    this.getBranches();
  },
  methods: {
    _formatDate: function (value) {
      if (value) return moment(String(value)).lang("tr").format("MMMM");
    },

    _operationTypeName: function (value) {
      if (value) return this.operationTypes[value];
    },

    _branchName: function (value) {
      const foundBranch = this.branches.find((branch) => branch.id == value);
      return foundBranch?.name || "";
    },
    addCustomLimit: function () {
      this.customLimit.date = `${moment().format("YYYY")}-${this.customLimit.date}-01`;
      HTTP.post("/settings/customLimits/discount-limit", this.customLimit).then(
        (result) => {
          if (result.status == 201) {
            this.getCustomLimits();
            this.addCustomLimitModal = false;
          }
        }
      );
    },
    getCustomLimits: function () {
      HTTP.get("/settings/customLimits/discount-limit").then((result) => {
        this.customLimits = result.data;
      });
    },
    getBranches: async function () {
      const { data: dbBranches } = await HTTP.get("/branches");
      this.branches = dbBranches;
    },
    updateCustomLimit: function (id) {
      this.customLimit = this.customLimits.filter(
        (customLimits) => customLimits.id == id
      )[0];
      this.customLimit.date = moment(this.customLimit.date).format("MM");
      this.updateCustomLimitModal = true;
    },
    updateCustomLimitDone: function () {
      this.customLimit.date = `${moment().format("YYYY")}-${this.customLimit.date}-01`;
      HTTP.put(
        "/settings/customLimits/discount-limit/" + this.customLimit.id,
        this.customLimit
      ).then((result) => {
        if (result.status == 204) {
          this.getCustomLimits();
          this.updateCustomLimitModal = false;
        }
      });
    },
    deleteCustomLimit: function (id) {
      if (confirm("Emin misiniz?")) {
        HTTP.delete("/settings/customLimits/discount-limit/" + id).then(
          (result) => {
            if (result.status == 204) {
              this.getCustomLimits();
            }
          }
        );
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
.sortButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 50px !important;
  top: 10px !important;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: move;
}
</style>