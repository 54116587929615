<template>
  <div>
    <div class="md-layout details">
      <div ref="pdfContent">
        <ConfirmationTemplate
          notesLabelShow
          :confirmation="confirmation"
          :patient="patient"
          :template="template"
          avatar="http://localhost:8081/img/avatar.4523b58a.jpg"
        >
        </ConfirmationTemplate>
      </div>

      <div class="md-layout-item wrapper">
        <span class="md-title">Bilgiler</span>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Onay Şablonu Adı</label>
              <md-input maxlength="38" v-model="fileContent.name"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <h5>Değer Tanımları</h5>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Price Currency</label>
              <md-select
                class="text-input"
                v-model="confirmation.priceCurrency.content"
                placeholder="Para Birimi"
              >
                <md-option
                  v-for="currency in allCurrencies"
                  v-bind:value="currency.slug"
                  v-bind:key="currency.slug"
                >
                  {{ currency.slug }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Transfer Managing Name Surname</label>
              <md-input
                v-model="confirmation.transferManagingNameSurname.content"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <h5>İsim Tanımları</h5>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Header Title</label>
              <md-input
                maxlength="38"
                v-model="confirmation.headerTitle.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Confirmation Id</label>
              <md-input v-model="confirmation.confirmationId.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Confirmation Date</label>
              <md-input v-model="confirmation.date.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Title</label>
              <md-input
                maxlength="75"
                v-model="confirmation.title.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Açıklama</label>
              <md-textarea
                maxlength="305"
                v-model="confirmation.description.content"
              ></md-textarea>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Included Text</label>
              <md-input v-model="confirmation.consultation.content"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Treatment Package Title</label>
              <md-input
                v-model="confirmation.treatmentPackage.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Inc Package Title</label>
              <md-input v-model="confirmation.incPrice.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Deposit Price Title</label>
              <md-input v-model="confirmation.depositPrice.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Balanced Price Title</label>
              <md-input v-model="confirmation.balancedPrice.label"></md-input>
            </md-field>
          </div>


          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>PERSONAL INFORMATIONS</label>
              <md-input
                maxlength="38"
                v-model="confirmation.personalInformations.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.nameSurname.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.country.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.phone.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.email.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.consultationWithDoctors.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.preOperativeTest.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.allMedications.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.helplineWithExpertTeam.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.treatmentPackage.label"
              ></md-input>
            </md-field>
          </div>


          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>TREATMENT INFORMATIONS</label>
              <md-input
                maxlength="38"
                v-model="confirmation.treatmentInformations.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.treatmentDateTime.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.operationLocation.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.staffDoctor.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>TRANSFER INFORMATIONS</label>
              <md-input
                maxlength="38"
                v-model="confirmation.transferInformations.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.vipTransfer.label"></md-input>
              <md-checkbox
                value="✓"
                v-model="confirmation.vipTransfer.content"
              ></md-checkbox>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.transferManagingNameSurname.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.airportToHotelHospital.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.hospitalToHotelAddress.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.transferFromToHotelToAirport.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>ACCOMMODATION</label>
              <md-input
                maxlength="38"
                v-model="confirmation.accommodation.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.hotelName.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.accommodationDay.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.roomTypeAdultsChildren.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.checkInDate.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.checkOutDate.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>FLIGHT DETAILS</label>
              <md-input
                maxlength="38"
                v-model="confirmation.flightDetails.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Arrival Information Title</label>
              <md-input
                maxlength="38"
                v-model="confirmation.flightDetails.arrivalLabel"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <label>Return Information Title</label>
              <md-input
                maxlength="38"
                v-model="confirmation.flightDetails.returnLabel"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.arrivalAirport.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.dateAndTime.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.returnAirport.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input
                v-model="confirmation.returnDateAndTime.label"
              ></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.flightCode.label"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <md-field>
              <md-input v-model="confirmation.notes.label"></md-input>
            </md-field>
          </div>
        </div>
        <div class="btn-wrap m-3">
          <md-button
            class="md-raised md-primary btn-right"
            v-on:click="saveConfirmationContent()"
          >
            Kaydet
            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
        </div>
        <div class="btn-wrap m-3">
          <md-button
            class="md-raised md-secondary btn-right"
            @click="exportToPDF"
          >
            PDF indir

            <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../main-source";
import moment from "moment";
import ConfirmationTemplate from "../../components/confirmationTemplate/confirmationTemplate.vue";

import html2pdf from "html2pdf.js";

export default {
  name: "Onay",

  data() {
    return {
      userId: localStorage.getItem("userId"),
      patient: { name: "Patient Name" },
      epicrisis: {},
      epicrisisId: this.$route.params.id,
      roleId: localStorage.getItem("roleId"),
      template: {
        id: 1,
        name: "Hasan Erdem",
        img: "../../assets/white-drhe-logo.png",
        color: "#6CD8D2",
        footer:
          "info@drhasanerdem.com,DR.HE OBESITY CLINIC,www.drhasanerdem.com",
        watermark: "../../assets/confirmation-template/watermark.png",
        email: "info@drhasanerdem.com",
        clinicName: "DR.HE OBESITY CLINIC",
        website: "www.drhasanerdem.com",
        lastUpdateUserId: 902,
        createdAt: "2022-06-17T11:50:00.000Z",
        updatedAt: "2022-06-17T11:50:00.000Z",
      },
      confirmation: {},
      fileContent: {},
      allCurrencies: [],
    };
  },
  components: {
    ConfirmationTemplate,
  },
  async created() {
    await HTTP.get("/confirmationContents/" + this.$route.params.guid).then(
      (result) => {
        this.confirmation = JSON.parse(result.data.content);
        this.fileContent = result.data;
      }
    );
    await this.getCurrencies();
  },
  methods: {
    saveConfirmationContent: function() {
      let params = {
        name: this.fileContent.name,
        content: JSON.stringify(this.confirmation),
        languageId: this.fileContent.languageId,
      };

      HTTP.put("/confirmationContents/" + this.fileContent.guid, params)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e) => {
          this.message = "Bir şeyler ters gitti";
        });
    },
    exportToPDF() {
      html2pdf(this.$refs.pdfContent, {
        margin: 0,
        filename: this.patient.name + " " + this.epicrisis.title + ".pdf",
        pdfContentWidth: 800,
        image: { type: "png", quality: 0.5 },
        html2canvas: { dpi: 300, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    getCurrencies() {
      HTTP.get("currency").then((currencies) => {
        this.allCurrencies = currencies.data;
        if (!this.confirmation.incPriceCurrency.content) {
          this.confirmation.incPriceCurrency.content = currencies.data[0]?.slug;
          this.confirmation.depositPriceCurrency.content =
            currencies.data[0]?.slug;
        }
      });
    },
  },
  watch: {
    "confirmation.consultation.content"(val) {
      this.confirmation.helplineWithExpertTeam.content = val;
      this.confirmation.postSurgeryGarment.content = val;
      this.confirmation.preOperativeTest.content = val;
      this.confirmation.allMedications.content = val;
      this.confirmation.consultationWithDoctors.content = val;
    },
  },
};
</script>

<style lang="scss" scope>
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}

.editOkButton {
  width: 100%;
  margin: 0 !important;
}

.vs--disabled * {
  background: transparent !important;
}

.medicalhistory {
  height: auto;
  max-height: auto;
}

.surgery {
  margin: 10px 0px;
  padding: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  position: relative;

  .head {
    width: 100%;
    display: block;
  }
}

.profile-img {
  overflow: visible !important;
}

.profile {
  .add {
    position: absolute;
    left: calc(50% - 85px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    .add {
      opacity: 1;
    }
  }
}

.file.profile-img {
  label {
    top: -100px !important;
    height: 200% !important;
  }
}

.md-tabs {
  width: 100%;
}

.maz-input.is-disabled .maz-input__label,
.maz-input.is-disabled .maz-input__input,
.maz-input.is-disabled .maz-input__toggle__arrow {
  cursor: auto;
  color: #000;
}

.maz-input.is-disabled.is-valid {
  border: 0;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  /*padding: 1px;*/
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 1px;
  /*AA*/
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.header {
  background-color: #6cd8d2;
  font-family: Monstserrat-Italic;
  font-size: 35px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  max-height: 70px;
}

.card1 {
  border-style: solid;
  border-width: 2px;
  border-color: #6cd8d2;
}
</style>
