<template>
  <div class="wrapper">
    <span class="md-title">Kullanıcı Rolleri</span>


    <md-table class="table appointments" v-model="sources">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Durum Adı">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateSource(item.id)">edit</i>
        </md-table-cell>
      </md-table-row>
    </md-table>


    <md-dialog :md-active.sync="updateSourceModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Randevu Durum Adı *</label>
          <md-input v-model="source.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen randevu durum adı giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateSourceDone">Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Sources",
  data() {
    return {
      sources: [],
      source: {
        name: ""
      },
      updateSourceModal: false,
      isValid: {
        name: null,
        duration: null
      }
    };
  },
  created() {
    this.getSources();
  },
  methods: {
    getSources: function () {
      HTTP.get("/roles").then(result => {
        this.sources = result.data;
      });
    },
    updateSource: function (id) {
      this.source = this.sources.filter(sources => sources.id == id)[0];
      this.updateSourceModal = true;
    },
    updateSourceDone: function () {
      HTTP.put("/roles/" + this.source.id, this.source).then(result => {

        if (result.status == 200) {
          this.getSources();
          this.updateSourceModal = false;
        }
        this.$swal("", result?.data?.message, result?.data?.isSuccess ? "success" : "error");

      })
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.active {
  background: green;
}

.passive {
  background: red;
}
</style>