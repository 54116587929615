<template>
  <div class="notes">
    <div>
      <span v-if="notes.length == 0">Herhangi bir not eklenmemiş.</span>
      <div class="md-layout note" v-for="(note, index) in notes" :key="note.id">
        <span class="head text-right">
          <div class="notify">
            <md-icon v-if="note.byEmail || note.byWhatsapp"
              >notifications_active</md-icon
            >
            {{
              note.byEmail || note.byWhatsapp
                ? dateString(note.date) + "-" + note.time
                : ""
            }}
            {{ note.byWhatsapp ? "Whatsapp" : "" }}
            {{ note.byEmail ? "Email" : "" }}
          </div>
          <small>
            {{ formatDate(note.createdAt) }}
            <md-button
              class="md-icon-button md-accent"
              @click="updateLastNote(note)"
              v-if="notes.length == index + 1"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <!-- <md-icon v-on:click.native="deleteNote(note.id)">delete</md-icon> -->
          </small>
        </span>
        <div v-if="note.statusId != null">
          <p>
            <span v-if="note.userId"
              ><b>{{ note.user ? note.user.name.toUpperCase() : "" }}</b>
              tarafindan </span
            >durum
            <b>{{ note.patientStatus ? note.patientStatus.name : "" }}</b>
            olarak atandi
          </p>
          <p v-if="note.description != ''">
            <b>Durum Notu:</b>{{ note.description }}
          </p>
        </div>
        <div v-else>
          {{ note.description }}
        </div>
        <br />
        <br />
        <br />
        <div class="files" v-if="note.files.length != 0">
          <div v-for="(file, index) in note.files" :key="index">
            <a :href="storagepath + file.path" target="_blank">
              <md-icon>attach_file</md-icon>
              {{ file.name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="md-layout">
      <div
        class="md-layout-item row md-size-100 md-xsmall-size-100"
        style="margin-top:5px;margin-bottom: 10px;padding-bottom: 10px;"
      >
        <label class="md-layout-item md-size-100"><b>Durum</b></label>
        <md-field
          class="md-menu md-layout-item row md-size-50 md-xsmall-size-100"
        >
          <v-select
            placeholder="Hastanın Durumunu Seçiniz"
            v-model="newNote.status"
            :options="patientStatuses"
            label="name"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
      </div>
      <div style="text-align: center;">
        <p v-if="statusError" style="color: red;font-size: 1rem;">
          Lütfen Hastanın Durumunu Seçiniz !!!
        </p>
      </div>
      <md-field>
        <label>Durum Notu Giriniz ...</label>
        <md-textarea v-model="newNote.description"></md-textarea>
      </md-field>
    </div>

    <div style="display:flex ; justify-content: flex-end;">
      <input
        id="datetimepicker"
        v-model="newNote.datetime"
        v-if="newNote.notify_me"
        type="datetime-local"
        :min="newNote.dateMin"
        placeholder="Lütfen Tarih Giriniz"
      />
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-40 md-xsmall-size-100">
        <ul>
          <li v-for="(file, index) in filesNote" :key="index">
            {{ file.name }}
          </li>
        </ul>
        <file-upload
          ref="upload"
          class="file"
          v-model="filesNote"
          multiple
          input-id="filesNote"
        >
          <md-icon>attach_file</md-icon>Dosya Ekleyin
        </file-upload>
      </div>

      <div class="md-layout-item text-right md-xsmall-size-100">
        <div class="md-layout" style="justify-content: right;">
          <div
            class="md-layout-item md-size-50 md-xsmall-size-100"
            style="padding-right: 2.4rem;"
          >
            <md-checkbox v-model="newNote.notify_me">Hatırlat</md-checkbox>
          </div>

          <div
            class="md-layout-item row md-size-50 md-xsmall-size-100"
            style="justify-content: right;margin-right: 0px;"
          >
            <md-button
              :disabled="updateButtonDisabledControl()"
              :class="
                updateButtonDisabledControl()
                  ? 'md-raised md-disabled'
                  : 'md-raised md-primary'
              "
              v-on:click="createNote"
              :aria-disabled="clickable"
              >DURUMU GÜNCELLE</md-button
            >
          </div>
        </div>
      </div>
    </div>
    <md-dialog
      :md-active.sync="updateNotePopup"
      :md-click-outside-to-close="true"
      class="dialog"
    >
      <md-dialog-content>
        <md-content class="statuses">
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="width: 35px; height: 35px; float: right;color:white;    right: 0.5rem;    top: 0.5rem;    position: absolute;"
              class="md-fab md-plain"
              @click="updateNotePopup = false"
              >X</md-button
            >
          </div>
          <md-dialog-title>Durum Notunu Güncelle</md-dialog-title>
          <md-field>
            <md-textarea v-model="updatedNote.description"> </md-textarea>
          </md-field>
          <md-button class="md-raised md-primary" v-on:click="onConfirm()"
            >Güncelle</md-button
          >
        </md-content>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { notificationsMixin } from "@/mixins/notificationsMixin";
import moment from "moment";

export default {
  name: "notes",
  mixins: [notificationsMixin],
  data: function() {
    return {
      updatedNote: {
        description: "",
        date: new Date(),
        notify_me: false,
        patientId: this.patientId,
        status: null,
        byEmail: false,
        byWhatsapp: false,
      },
      updateNotePopup: false,
      clickable: true,
      noteStatus: false,
      selectedStatus: null,
      userId: localStorage.getItem("userId"),
      statusError: false,
      notes: [],
      filesNote: [],
      notificationTime: "10:00",
      notificationDate: null,
      newNote: {
        description: "",
        date: new Date(),
        datetime: "",
        dateMin: "",
        notify_me: false,
        patientId: this.patientId,
        status: null,
        byEmail: false,
        byWhatsapp: false,
      },
      storagepath: this.$storage,
    };
  },
  props: ["patientId", "patientStatuses", "clickControl"],
  created: function() {
    this.getNotes();
    this.getCurrentDate();
    if (this.patientStatuses == null) {
      this.getPatientStatuses();
    }

    // this.fillNotificationsList();
  },
  methods: {
    updateLastNote(note) {
      this.updatedNote = note;
      this.updateNotePopup = true;
    },
    onConfirm() {
      HTTP.put("/notes/" + this.updatedNote.id, this.updatedNote)
        .then((result) => {
          if (result.status == 204) {
            this.$swal("", "Not başarılı bir şekilde güncellendi.", "success");
            this.updateNotePopup = false;
          }
        })
        .catch((e) => {
          this.$swal(
            "",
            "Güncelleme sırasında bir hata oluştu lütfen daha sonra tekrar deneyiniz.",
            "error"
          );
          this.updateNotePopup = false;
        });
    },
    isDateDisabled: function(date) {
      return moment(date).isBefore(moment().format("YYYY-MM-DD"));
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD - HH:mm:ss");
    },
    createNote: function() {
      this.clickable = false;
      this.statusError = this.newNote.status ? false : true;
      if (this.statusError) {
        this.noteStatus = false;
        this.clickable = true;
        return;
      }
      this.newNote.date = this.notificationDate
        ? moment(
            moment(this.notificationDate).format("YYYY-MM-DD") +
              " " +
              this.notificationTime +
              ":00"
          ).format("YYYY-MM-DD HH:mm:ss")
        : "1970-01-01 00:00:00";
      let fd = new FormData();
      this.filesNote.forEach((file) => {
        fd.append("files", file.file, file.name);
      });
      fd.append("description", this.newNote.description);
      fd.append("date", this.newNote.date);
      fd.append("time", this.notificationTime);
      fd.append("datetime", this.newNote.datetime);
      fd.append("notify_me", this.newNote.notify_me);
      fd.append("patientId", this.newNote.patientId);
      fd.append(
        "statusId",
        this.newNote.status ? this.newNote.status.id : null
      );
      fd.append("byEmail", this.newNote.byEmail);
      fd.append("byWhatsapp", this.newNote.byWhatsapp);
      fd.append("userId", this.userId);

      // this.newNote.status = this.newNote.status.id;
      HTTP.post("/notes", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((response) => {
        this.$emit("newPatientStatus", this.newNote.status);
        this.$emit("newPatientNotes", response.data);

        this.$swal({
          icon: response.data.isSuccess ? "success" : "error",
          text: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });

        this.newNote = {
          description: "",
          date: new Date(),
          notify_me: false,
          patientId: this.patientId,
          status: null,
          byEmail: false,
          byWhatsapp: false,
        };
        this.statusError = false;
        this.filesNote = [];
        this.getNotes();
        this.fillNotificationsList();
        //
        this.noteStatus = true;
        this.clickable = true;
      });
    },
    getNotes: function() {
      HTTP.get("/notes/" + this.patientId).then((results) => {
        this.notes = results.data;
      });
    },
    getPatientStatuses: function() {
      HTTP.get("/patientstatus/").then((result) => {
        this.patientStatuses = result.data.filter((z) => true);
        this.patientStatuses.reverse();
        this.patientStatuses.push({ id: 0, name: "Yok" });
        this.patientStatuses.reverse();
      });
    },
    dateString: function(date) {
      if (date) {
        let start = new Date(date);
        let startText =
          ("0" + start.getDate()).slice(-2) +
          "-" +
          (start.getMonth() + 1) +
          "-" +
          start.getFullYear();
        return startText;
      }
    },
    deleteNote: function(id) {
      HTTP.delete("/notes?ids=" + id).then(() => {
        this.getNotes();
        this.removeFromNotificationsList("notes", id);
      });
    },
    updateButtonDisabledControl() {
      if (this.newNote.notify_me) {
        return this.newNote.datetime ? false : true;
      }
      return false;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = this.addLeadingZero(date.getMonth() + 1);
      const day = this.addLeadingZero(date.getDate());
      const hours = this.addLeadingZero(date.getHours());
      const minutes = this.addLeadingZero(date.getMinutes());

      this.newNote.dateMin = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.newNote.datetime = `${year}-${month}-${day}T12:00`;
    },
    addLeadingZero(number) {
      return number < 10 ? `0${number}` : number;
    },
  },
  watch: {
    clickControl(value) {
      if (value) {
        this.createNote();
      }
      // if(value){
      //   this.createNote()
      // }
    },
    notificationDate(val) {
    },
    "newNote.datetime"(val) {
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";
.notes {
  .head {
    width: 100%;
    display: block;
    font-weight: bold;
    small {
      i {
        font-size: 16px !important;
        transition: all 0.2s ease;
        cursor: pointer;
        margin-top: -5px;
        &:hover {
          color: #f00;
        }
      }
    }
  }

  .note {
    margin: 10px 0px;
    padding: 10px;
    border-bottom: 1px solid $today_border;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    position: relative;
  }
  .md-checkbox {
    margin: 10px 10px 0px;
  }
}
.text-right {
  text-align: right;
}
.notify {
  font-size: 12px;
  float: left;
  i {
    font-size: 15px !important;
  }
}
.files {
  display: block;
  width: 100%;
  margin-top: 10px;
  a {
    text-decoration: none !important;
  }
  i {
    font-size: 16px !important;
  }
}
.file * {
  cursor: pointer !important;
}

#datetimepicker {
  border: 1px solid rgb(185, 185, 185);
  border-radius: 5px;
  padding: 5px;
}
</style>
