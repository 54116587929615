<template>
  <div>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Finance",
  data() {
    return {
      title: "Finans"
    };
  }
};
</script>
