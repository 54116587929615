import moment from 'moment';
import { HTTP } from '@/main-source';
import store from '../store';

export const notificationsMixin = {
    methods: {
        removeFromNotificationsList: function(oTable, oId){
            if(oTable == ''){
                let valIdx = store.state.notificationsList.findIndex(z => z.Id == oId);
                store.state.notificationsList.splice(valIdx, 1);
            }
            else{
                let valIdx = store.state.notificationsList.findIndex(z => z.objectId == oId && z.objectTable == oTable);
                store.state.notificationsList.splice(valIdx, 1);
            }
        },
        fillNotificationsList: function(){
            // let roleId = localStorage.getItem("roleId");
            //let today = moment();
            // if(roleId == 1)
            //     HTTP.get('/notifications/all?date=' + today.format("YYYY-MM-DD")).then((result) => {
            //     store.state.notificationsList = result.data;                
            // });
            // else{
                /*
                HTTP.get('/notifications/user/' + localStorage.getItem("userId") + '?date=' + today.format("YYYY-MM-DD")).then((result) => {
                    store.state.notificationsList = result.data;
                });
                */
            // }
        },

    },
}