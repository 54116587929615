<template>
    <div class="card">
       <Container
       style="margin-left: 20px;
       margin-top: 30px;"
        width="300"
        height="30"/>
        <div class="row">
            <List/>
            <List/>
            <List/>
        
        </div>
        <div class="row">
            <List/>
            <List/>
            <List/>
        </div>
    </div>
</template>
<script>
import Container from './container.vue';
import List from './list.vue';
export default {
    components: { Container, List }
}
</script>
<style scoped>
.card{
    width: 100%;
    height: 250px;
    background: white;
    border: 0px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.07);
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    
}
.row{
    display: flex;
    margin-top: 20px;
    margin-left: -25px;
}


.content-2{
    margin-left: 0px;
    background: red;
}
</style>