<template>
  <div>
    <Navigation v-if="screenWidth < 769" class="navigation" :id="patientsId"></Navigation>
    <Topbar @updated="refreshPatientData" :patId="patientsId" :patient="patient" :saveStatus="isEditable" :parent="this"></Topbar>
    <Navigation v-if="screenWidth > 769" class="navigation" :id="patientsId"></Navigation>
    <AppointmentCard :appointment="appointment" :appointmentDetails="appointmentDetails" @closeAppointmentCard="closeAppointmentCard" @refreshAppointments="refreshAppointments"></AppointmentCard>
    <div class="md-layout details md-gutter">
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <!-- <div class="md-layout-item wrapper">
          <md-button
          class="md-fab md-mini md-primary editButton"
          v-on:click="setEditable"
          v-if="!isEditable && !isDietician"
        >
          <md-icon>edit</md-icon>
        </md-button>
          <md-button
          class="md-fab md-mini md-danger editButton"
          v-on:click="saveInformation"
          v-if="isEditable && !isDietician"
        >
          <md-icon>save</md-icon>
        </md-button>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Ad Soyad</label>
                <md-input v-model="patient.name" v-bind:disabled="!isEditable"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Durum
              <md-field>
              <v-select
                v-model="patient.patientStatus"
                placeholder="Durum"
                :options="patientStatuses"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
                @input="changeStatus"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              </md-field>
            </div>
            <div
            class="md-layout-item md-size-50 md-xsmall-size-100"
            v-if="statusChanged && isEditable"
          >
            <md-field>
              <label>Durum Notlar</label>
              <md-input
              v-model="statusNote"
              v-bind:disabled="!isEditable"
              ></md-input>
            </md-field>
          </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Tedavi
              <md-field>
              <v-select
                :clearable="false"
                v-model="patient.operation"
                placeholder="Tedavi"
                :options="operations"
                label="name"
                class="md-menu"
                :disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Ülke
              <md-field>
                <v-select
                  :clearable="false"
                  v-model="patient.country"
                  placeholder="Ülke"
                  :options="countries"
                  label="name_tr"
                  class="md-menu"
                  :disabled="!isEditable"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name_tr
                  }}</template>
                </v-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Firma
              <md-field>
              <v-select
                :clearable="false"
                v-model="patientFirm"
                placeholder="Firma"
                :options="firms"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>Tahmini Tutar ( {{ patient.currencySlug }} )</label>
                <md-input
                  v-model="patient.estimated_amount"
                  v-bind:disabled="!isEditable"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
                İletişim
              <md-field>
                <md-textarea 
                v-model="patient.source" 
                v-bind:disabled="!isEditable" 
                v-if="patient.source && !patSource"
                md-autogrow
                ></md-textarea>
                <v-select
                :clearable="false"
                v-model="patientSource"
                placeholder="İletişim"
                :options="sources"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
                v-if="!patient.source || patSource"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Kaynak
              <md-field>
              <v-select
                :clearable="false"
                v-model="patient.agencie"
                placeholder="Kaynak"
                :options="agencies"
                label="name"
                class="md-menu"
                v-bind:disabled="!isEditable"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
                İlk Kayıt Tarihi
              <md-field v-if="!isEditable || roleId != 1">
                <span>{{formatDate(this.patient.createdAt, true)}}</span>
              </md-field>
                <md-datepicker :clearable="false" v-model="patientCreatedAt" v-if="isEditable && roleId == 1"></md-datepicker>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              Dil
              <md-field>
                <v-select
                  v-model="patient.language"
                  placeholder="Dil"
                  :options="languages"
                  label="name"
                  class="md-menu"
                  v-bind:disabled="!isEditable"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
            </md-field>
            </div>
          </div>
        </div> -->

        <div class="md-layout-item wrapper" v-if="isPatient">
          <span class="md-title">Randevular</span>
          <md-table class="table appointments" v-model="appointments">
            <md-table-row>
              <md-table-head>Form</md-table-head>
              <md-table-head>Durum</md-table-head>
              <md-table-head>İşlem</md-table-head>
              <md-table-head>Tarih</md-table-head>
              <md-table-head>Konum</md-table-head>
              <md-table-head>Katılımcılar</md-table-head>
            </md-table-row>
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              v-on:click="showAppointmentDetails(item.id)"
            >
            <md-table-cell md-label="Form">

              <md-button  v-if="item.fileContents.length > 0" @click="navigateAppoinmentDetailView(item.guid)"
                class="md-icon-button md-raised md-dense"><md-icon>description</md-icon>
              </md-button>

            </md-table-cell>
              <md-table-cell md-label="Durum">
                <div
                  class="status"
                  v-bind:class="[
                    item.status.id === 1 ? 'waiting' : '',
                    item.status.id === 2 ? 'done' : '',
                    item.status.id === 3 ? 'cancel' : '',
                    item.status.id === 4 ? 'postponed' : '',
                    item.status.id === 5 ? 'not-come-bg' : '',
                    item.status.id === 6 ? 'optional' : '',
                    item.status.id === 7 ? 'approve' : '',
                    item.status.id === 8 ? 'depositPaid' : '',
                  ]"
                ></div>
                {{ item.status.name }}
                <span v-if="getLatestAppointmentNote(item.statusHistory) != ''"><br/>({{ getLatestAppointmentNote(item.statusHistory) }})</span>
              </md-table-cell>
              <md-table-cell md-label="İşlem">{{
                item.operation.name
              }}</md-table-cell>
              <md-table-cell md-label="Tarih">
                {{ formatDate(item.date) }}
                - {{ item.time ? item.time.substring(0, 5) : "" }}
              </md-table-cell>
              <md-table-cell md-label="Konum">
                {{
              item.location.name
              }}
              </md-table-cell>
              <md-table-cell md-label="Katılımcılar">
                <span
                  class="participants"
                  v-for="participants in item.AppointmentsParticipants"
                  v-bind:key="participants.id"
                  >{{ participants.name }}</span
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="text-right">
            <router-link
            v-if="!isDoctor"
            :to="{
            name:'createAppointment',
            query:{
              id: patientsId
            }
          }"
          >
            <md-button 
            v-if="!isDietician"
            class="md-raised md-primary">
              <md-icon>add</md-icon>Yeni Ekle
            </md-button>
          </router-link>
          </div>
        </div>
        <div class="md-layout-item wrapper">
          <span class="md-title">Aktiviteler</span>
          <md-table class="table appointments" v-model="activities">
            <md-table-row>
              <md-table-head>Durum</md-table-head>
              <md-table-head>İşlem</md-table-head>
              <md-table-head>Not</md-table-head>
              <md-table-head>Tarih</md-table-head>
              <md-table-head>Konum</md-table-head>
            </md-table-row>
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              v-on:click="eventDetails(item.id)"
            >
              <md-table-cell md-label="İşlem">{{
                item.operation.name
              }}</md-table-cell>
              <md-table-cell md-label="Not">{{ item.note }}</md-table-cell>
              <md-table-cell md-label="Tarih">
                {{ formatDate(item.date) }}
                - {{ item.time ? item.time.substring(0, 5) : "" }}
              </md-table-cell>
              <md-table-cell md-label="Katılımcılar">
                <span
                  class="participants"
                  v-for="participants in item.AppointmentsParticipants"
                  v-bind:key="participants.id"
                  >{{ participants.name }}</span
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div
          class="text-right">
            <md-button @click="newActivities" class="md-raised md-primary">
              <md-icon>add</md-icon>Yeni Ekle
            </md-button>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <div class="md-layout-item wrapper">
          <span class="md-title">Notlar</span>
          <notes :patientId="patientsId"></notes>
        </div>
        <div class="md-layout-item wrapper" v-if="liveStatusHistory">
          <span class="md-title">Durum Hareketleri</span>
          <ul >
            <li  v-for="(item,index) in liveStatusHistory" :key="index">
              <span v-if="item.oldStatus != null">
                <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.oldStatus)}}</b>'den <b>{{getStatusName(item.newStatus)}}</b>'ye değiştirildi.
                </span> 
                <span v-else> 
                  <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.newStatus)}}</b> olarak atandı.
                </span>
            <br>
              <span v-if="item.note != null"><b>Durum Notu:</b> {{item.note}}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import router from "@/router";
import AppointmentCard from "@/components/patients/appointmentCard";
import { patientProfileMixin } from "@/mixins/patientProfileMixin";
import notes from "@/components/patients/notes";
import moment from 'moment';

export default {
  name: "PotantialPatient",
  mixins: [patientProfileMixin],
  data() {
    return {
      appointments: {},
      activities: {},
      patientTreatmentList: "",
      isEditable: false,
      userId: localStorage.getItem("userId"),
      isDoctor: localStorage.getItem("roleId") == 2,
      isDietician: localStorage.getItem("roleId") == 9,
      roleId: localStorage.getItem("roleId"),
      oldStatus: null,
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: ""
        },
        statusId: "",
        location: {
          name: ""
        },
        operation: {
          name: ""
        }
      },
      appointmentDetails: false,
      statusChanged: false,
      screenWidth: 0,
    };
  },
  components: {
    Navigation,
    Topbar,
    notes,
    AppointmentCard
  },
  created() {
    this.getAppointmentsByPatients();
  },
  watch: {
    patientCreatedAt: function(createdAt){
      
      let patCreatedAt = null;
      if(!this.patientCreatedAt || isNaN(new Date(this.patientCreatedAt)))
        patCreatedAt = new Date(moment(this.patientCreatedAt_old).format("YYYY-MM-DD HH:mm"));
      patCreatedAt = new Date(moment(this.patientCreatedAt).format("YYYY-MM-DD") + " " + moment(this.patient.createdAt).format("HH:mm:ss"));

      if(!isNaN(new Date(patCreatedAt)) && patCreatedAt)
        this.patient.createdAt = patCreatedAt;
    }
  },
  computed:{
    liveStatusHistory: function(){
      return JSON.parse(this.patient.statusHistory)
    }
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    getLatestAppointmentNote: function(statusHistory){
      if(!statusHistory)
        return "";
      let history = JSON.parse(statusHistory);
      let latestNote = history[history.length - 1];
      return latestNote.note;
    },
    formatDate(date, showTime = false){
      return showTime ? moment(date).format("DD-MM-YYYY HH:mm") : moment(date).format("DD-MM-YYYY");
    },
    closeAppointmentCard(){
      this.appointmentDetails = false;
    },
    refreshAppointments(){
      this.getAppointmentsByPatients();
    },
    newActivities: function() {
      router.push({ name: "potantial.patients.activities" });
    },
    patientTreatments: function(list) {
      HTTP.get("/operations").then(result => {
        list.forEach(element => {
          this.patientTreatmentList +=
            " & " +
            result.data.filter(r => r.id == element.operationId)[0].name;
        });
        this.patientTreatmentList = this.patientTreatmentList.substring(3);
      });
    },
    setEditable: function() {
      this.isEditable = true;
    },
    saveInformation: function(){
      if (this.patient.patientStatus) {
        this.patient.status = this.patient.patientStatus.id;
      }
      if (this.patient.operation) {
        this.patient.treatmentId = this.patient.operation;
      }

      if (this.patient.country) {
        this.patient.countryCode = this.patient.country.code;
      }
      if (this.patient.innerFirm) {
        this.patient.innerFirmId = this.patient.innerFirm.id;
      }
    
      if (this.patient.agencie) {
        this.patient.agencieId = this.patient.agencie.id;
      }
      
      if (this.patientSource) {
        if(!this.patient.source) this.patSource = true;
        this.patient.source = this.patientSource.name;
      }

      if(this.patient.language){
        this.patient.languageId = this.patient.language.id;
      }
      if(typeof this.patient.createdAt.getMonth !== 'function')
        this.patient.createdAt = this.patientCreatedAt_old;
      if (this.oldStatus != this.patient.status) { 
        let statusHistory = this.patient.statusHistory; 
        if (statusHistory == "" || statusHistory == null) statusHistory = [];
        else statusHistory = JSON.parse(statusHistory);
        statusHistory.push({
          oldStatus: this.oldStatus,
          newStatus: this.patient.status,
          changedBy: this.userId,
          changedAt: new Date(),
          note: this.statusNote,
        });
        //
        this.patient.statusHistory = JSON.stringify(statusHistory);
      }

      HTTP.put("/patients/" + this.patient.id, this.patient).then(result => {
        if (result.status == 204) {
          this.isEditable = false;
          this.$swal("", "Başarıyla güncellendi", "success");
        }
      });
      this.statusChanged = false;
    },
    getAppointmentsByPatients: function() {
      HTTP.get(`appointments/bypatient/${this.patientsId}?type=all`).then(result => {
        if(result.data.length > 0){
          this.appointments = result.data.filter(z => z.isActivity == false);
          this.activities = result.data.filter(z => z.isActivity == true);
        }
      });
    },
    showAppointmentDetails: function(id) {
      HTTP.get("/appointments/" + id).then(result => {
        // 
        this.appointment = result.data[0];
        
        setTimeout(() => {
          this.appointmentDetails = true;
        }, 200);
      });
    },
    getUserName(userId){
      var user = this.users.find(z => z.id == userId);
      return user ? user.name : "";
    },
    changeStatus: function (selVal) {
      this.statusChanged = this.oldStatus != selVal.id ? true : false;
    },
    getStatusName(statusID){
      this.oldStatus = statusID;
      var status = this.patientStatuses.find(z => z.id == statusID);
      return status ? status.name : "";
    },
    navigateAppoinmentDetailView(guid) {
      const route = this.$router.resolve({
        name: "appoinmentDetailView",
        params: { guid, },
      });

      window.open(route.href, "_blank");
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;

      if (!this.input) {
        this.deviceControl = "desktop"
      } else {
        if (this.screenWidth >= 769) {
          this.deviceControl = "desktop"
        } else {
          this.deviceControl = "mobile"
        }
      }

    },
  }
};

</script>

<style lang="scss">
.patientlist {
  margin-top: 7px !important;
  margin: -15px;
  .md-layout-item {
    min-width: 31.1%;
    max-width: 31.1%;
    margin-right: 15px !important;
    margin-left: 15px !important;
    margin-bottom: 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
}
.navigation {
  margin-top: 20px;
}
.patients-wrap {
  margin-top: 75px;
  margin-right: 30px;
}
.details {
  margin-top: 30px;
}
.table {
  margin-top: 20px;
}
.story {
  padding: 10px;
  span {
    display: block;
    font-size: 14px;
  }
  .md-layout-item {
    min-width: 100%;
    max-width: 100%;
  }
}
.text-right {
  text-align: right;
}
</style>
