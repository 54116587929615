import axios from 'axios';
// baseURL: 'https://api.drheclinic.com/',
// http://klinikapi.intesasoft.com/
// baseURL: 'http://64.225.70.173/',
var apiLink = process.env.VUE_APP_API_URL;
export const HTTP = axios.create({
  baseURL: apiLink,
  // baseURL: 'http://64.225.70.173/',
});
HTTP.defaults.timeout = 40000;
var token = '';
token = localStorage.getItem('token');
if (token) {
  HTTP.defaults.headers.common.Authorization = 'Bearer ' + token;
}
