import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMaterial from 'vue-material';
import nprogress from 'nprogress';
import vSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';
import VueUploadComponent from 'vue-upload-component';
import Vuelidate from 'vuelidate';
import VCalendar from 'v-calendar';
import Paginate from 'vuejs-paginate'


import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'nprogress/nprogress.css';
import './assets/scss/index.scss';
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

nprogress.configure({
  showSpinner: false,
});

Vue.use(VueMaterial);
Vue.use(nprogress);
Vue.component('v-select', vSelect);
Vue.component('file-upload', VueUploadComponent);
Vue.component('paginate', Paginate)

Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

// Vue.prototype.$storage = 'http://64.225.70.173/';
// Vue.prototype.$appDomain = 'http://klinikv2.intesasoft.com/';
var apiLink = process.env.VUE_APP_API_URL;
var siteLink = process.env.VUE_APP_APP_URL;
Vue.prototype.$appDomain = siteLink;
Vue.prototype.$storage = apiLink;

// Vue.material.locale.dateFormat = "yyyy-MM-dd";
Vue.material.locale.dateFormat = 'dd-MM-yyyy';
Vue.material.locale.firstDayOfAWeek = 1;
Vue.material.locale.ok = 'tamam';
Vue.material.locale.days = [
  'Pazar',
  'Pazartesi',
  'Salı',
  'Çarşamba',
  'Perşembe',
  'Cuma',
  'Cumartesi',
];
Vue.material.locale.shortDays = [
  'Paz',
  'Pzt',
  'Salı',
  'Çarş',
  'Perş',
  'Cuma',
  'Cts',
];
Vue.material.locale.shorterDays = ['PZ', 'PS', 'SL', 'ÇŞ', 'PŞ', 'CM', 'CT'];
Vue.material.locale.months = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
Vue.material.locale.shortMonths = [
  'OCK',
  'ŞUB',
  'MART',
  'NİS',
  'MAY',
  'HAZ',
  'TEM',
  'AĞUS',
  'EYL',
  'EKİM',
  'KASIM',
  'ARA',
];
Vue.material.locale.shorterMonths = [
  'OCK',
  'ŞUB',
  'MART',
  'NİS',
  'MAY',
  'HAZ',
  'TEM',
  'AĞUS',
  'EYL',
  'EKİM',
  'KASIM',
  'ARA',
];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
