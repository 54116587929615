<template>
  <div>
    <md-dialog :md-active.sync="showBecomePatientModal" class="dialog allowOverflow" @md-clicked-outside="closeModal"
      :md-click-outside-to-close="false">
      <md-dialog-title>Hastaya Dönüştür</md-dialog-title>
      <md-content class="scrollbarY" v-if="patient">
        <div class="md-layout md-gutter">

          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field :class="{
              'md-invalid': $v.patient.name.$invalid && showInvalidErrors,
            }">
              <span class="list-title">Ad Soyad</span>
              <md-input v-model="patient.name" :disabled="!isEditable"></md-input>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field :class="{
              'md-invalid':
                $v.patient.treatmentId.$invalid && showInvalidErrors,
            }">
              <span class="list-title">Tedavi</span>
              <v-select v-model="patient.operation" placeholder="Tedavi" :options="operations" label="name"
                class="md-menu">
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field v-if="!isEditable">
              <md-input v-model="patient.countryCode" disabled></md-input>
            </md-field>
            <md-field v-if="isEditable" :class="{
              'md-invalid':
                $v.patient.countryCode.$invalid && showInvalidErrors,
            }">
              <span class="list-title">Ülke</span>
              <v-select v-model="patient.country" placeholder="Ülke" :options="countries" label="name_tr" class="md-menu">
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>


          </div>

          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Tahmini Tutar ( {{ patient.currencySlug }} )</span>
              <md-input v-model="patient.estimated_amount" :disabled="!isEditable"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field v-if="isEditable" :class="{
              'md-invalid':
                $v.patient.agencieId.$invalid && showInvalidErrors,
            }">
              <span class="list-title">Kaynak</span>
              <v-select v-model="patient.agencie" placeholder="Kaynak" :options="agencies" label="name"
                class="md-menu smallSelect">
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field :class="{
                  'md-invalid':
                    $v.patient.languageId.$invalid && showInvalidErrors,
                }">
              <span class="list-title">Dil</span>
              <v-select v-model="patient.language" placeholder="Dil" :options="languages" v-if="isEditable" label="name"
                class="md-menu">
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>
          </div>
        </div>

        <hr class="half-hr" />

        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <div v-for="file in patient.files" :key="file.id">
              <div class="md-layout link" v-if="file.filetypeId == 4">
                <a :href="storagepath + file.path" target="_blank">
                  <md-icon>open_in_new</md-icon>{{ file.name }}
                  <small class="ml-2">({{ formatDate(file.createdAt) }})</small>
                </a>
                <a href="javascript:void(0)" style="margin-left: 8px" :data-file-id="file.id" @click="deleteFile($event)">
                  <md-icon style="color: red">delete</md-icon>
                </a>
              </div>
            </div>
            <file-upload class="customfileinput" input-id="patienedFile" :accept="'.png,.jpg,.jpeg,.pdf'"
              v-model="patienedFile">
              <md-icon>add</md-icon>Bilet/Fee Dekont Yükle (max 4 mb)
              <md-progress-spinner v-if="spinnerFee" class="md-accent spinner " :md-diameter="20" :md-stroke="3"
                md-mode="indeterminate"></md-progress-spinner>
            </file-upload>
          </div>
        </div>
        <hr class="half-hr" />

        <div class="md-layout" v-if="!patient.operation || !patient.operation.isNotSurgery == 1">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <div v-for="file in patient.files" :key="file.id">
              <div class="md-layout link" v-if="file.filetypeId == 8">
                <a :href="storagepath + file.path" target="_blank">
                  <md-icon>open_in_new</md-icon>{{ file.name }}
                  <small class="ml-2">({{ formatDate(file.createdAt) }})</small>
                </a>
                <a href="javascript:void(0)" style="margin-left: 8px" :data-file-id="file.id" @click="deleteFile($event)">
                  <md-icon style="color: red">delete</md-icon>
                </a>
              </div>
            </div>
            <file-upload class="customfileinput" input-id="medicalFormFile" :accept="'.pdf'" v-model="medicalFormFile">
              <md-icon>add</md-icon>Medikal Form Yükle (max 4 mb)
              <md-progress-spinner v-if="spinnerMedical" class="md-accent spinner " :md-diameter="20" :md-stroke="3"
                md-mode="indeterminate"></md-progress-spinner>
            </file-upload>
          </div>
        </div>

        <div class="md-layout" v-if="patient.operation && patient.operation.isNotSurgery == 1">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-33 md-xsmall-size-100">
              <md-field :class="{
                'md-invalid': $v.patient.name.$invalid && showInvalidErrors,
              }">
                <label>Boy</label>
                <md-input v-model="patient.height" :disabled="!isEditable"></md-input>
                <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-xsmall-size-100">
              <md-field :class="{
                'md-invalid': $v.patient.name.$invalid && showInvalidErrors,
              }">
                <label>Kilo</label>
                <md-input v-model="patient.weight" :disabled="!isEditable"></md-input>
                <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-xsmall-size-100">
              <md-field :class="{
                'md-invalid': $v.patient.name.$invalid && showInvalidErrors,
              }">
                <label>Yaş</label>
                <md-input v-model="patient.age" :disabled="!isEditable"></md-input>
                <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
              </md-field>
            </div>

            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.bloodThinner">Kan Sulandırıcı
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.cigarette">Sigara</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.ulcer">Ülser</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.alcohol">Alkol</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.reflux">Reflü</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.allergy">Alerji</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.gastritis">Gastrit</md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-checkbox v-model="patient.stomachSurgery">Mide Ameliyatı Geçirmiş Mi ?</md-checkbox>
            </div>
          </div>
        </div>

        <hr class="half-hr" />

        <div class="md-layout">
          <div class="md-layout-item md-size-15 md-xsmall-size-100">
            <md-checkbox v-model="temporaryConversion">opsiyonlanma</md-checkbox>
          </div>
          <!--   <div class="md-layout-item md-size-15 md-xsmall-size-100">
                      <md-checkbox v-model="depositPaid">Depozit Alındı</md-checkbox>
                  </div> -->
        </div>

        <div class="md-layout text-right">
          <md-button class="md-raised md-default button-margins" v-on:click="closeModal">
            İptal</md-button>

          <md-button class="md-raised md-primary button-margins" v-if="!patient.patientCode"
            v-on:click="makePatient">Hastaya Dönüştür
          </md-button>
          <md-button class="md-raised md-primary button-margins" v-if="patient.patientCode"
            v-on:click="makePatient">Randevu Oluştur
          </md-button>
        </div>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { HTTP } from "@/main-source";
import moment from "moment";
import router from "@/router";

export default {
  name: "convertModal",
  data: function () {
    return {
      spinnerFee: false,
      spinnerMedical: false,
      storagepath: this.$storage,
      isEditable: true,
      // patientStatuses: [],
      operations: [],
      agencies: [],
      countries: [],
      languages: [],
      sources: [],
      files: [],
      patienedFile: [],
      patienedFileCount: 0,
      patienedFileOk: false,
      medicalFormFile: [],
      medicalFormFileCount: 0,
      showInvalidErrors: false,
      temporaryConversion: false,
      depositPaid: false,
      patSource: null,
      patientSource: null,
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
    };
  },
  validations: {
    patient: {
      name: {
        required,
      },
      status: {
        required,
      },
      treatmentId: {
        required,
      },
      countryCode: {
        required,
      },
      estimated_amount: {},
      agencieId: {
        required,
      },
      source: {},
      languageId: {
        required,
      },
    },
  },
  props: ["showBecomePatientModal", "patient"],
  created: function () {
    // HTTP.get("/patientstatus/").then((result) => {
    //     this.patientStatuses = result.data;
    //     });
    HTTP.get("/operations").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
    });
    HTTP.get("/agencies").then((result) => {
      this.agencies = result.data;
    });
    HTTP.get("/sources").then((result) => {
      this.sources = result.data;
    });
    HTTP.get("/countries").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/languages").then((result) => {
      this.languages = result.data;
    });
    this.fileCount();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    shortUpdate: async function () {
      if (this.patient.innerFirm) {
        this.patient.innerFirmId = this.patient.innerFirm.id;
      }
      if (this.patient.agencie) {
        this.patient.agencieId = this.patient.agencie.id;
      }
      if (this.patient.country) {
        this.patient.countryCode = this.patient.country.code;
      }
      if (this.patientSource) {
        this.patient.source = this.patientSource.name;
      }
      // if (this.patient.patientStatus) {
      //   this.patient.status = this.patient.patientStatus.id;
      // }
      if (this.patient.operation) {
        this.patient.treatmentId = this.patient.operation;
      }
      if (this.patient.language) {
        this.patient.languageId = this.patient.language.id;
      }

      if (!this.patient.status) {
        this.$swal("", "Hasta Durumu Girilmedi!", "error");
        this.showInvalidErrors = true;
        return false;
      }

      if (this.$v.$invalid) {
        this.$swal("", "Hasta Bilgileri Eksiksiz Doldurunuz!", "error");
        this.showInvalidErrors = true;
        return false;
      }

      return HTTP.put("/patients/" + this.patient.id, this.patient).then(
        (result) => {
          if (result.status == 204) {
            this.isEditable = false;
            return true;
            // this.$swal("", "Başarıyla güncellendi", "success");
          }
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    makePatient: async function () {
      let params = {
        patientId:
          this.patient.patientCode != null
            ? this.patient.patientCode
            : this.patient.id,
        height: this.patient.height,
        weight: this.patient.weight,
        age: this.patient.age,
        reflux: this.patient.reflux,
        bloodThinner: this.patient.bloodThinner,
        cigarette: this.patient.cigarette,
        alcohol: this.patient.alcohol,
        allergy: this.patient.allergy,
        gastritis: this.patient.gastritis,
        ulcer: this.patient.ulcer,
        stomachSurgery: this.patient.stomachSurgery,
        userId: localStorage.getItem("userId"),
      };

      this.fileCount();
      if (this.patient.operation.isSurgery == 1) {
        if (this.patienedFileOk) {
          // HTTP.put("offers/" + this.approvedOffer.id).then(() => {
          let updated = await this.shortUpdate();
          if (!updated) return;
          router.push({
            name: "createAppointment",
            query: {
              id:
                this.patient.patientCode != null
                  ? this.patient.patientCode
                  : this.patient.id,
              convert: true,
              temporaryConversion: this.temporaryConversion,
              depositPaid: this.depositPaid,
              // offerId: this.approvedOffer.id,
            },
          });
          // let statusHistory = this.patient.statusHistory;
          // if (statusHistory == "" || statusHistory == null){statusHistory = [];}
          // else{statusHistory = JSON.parse(statusHistory);}
          // //console.log(statusHistory)
          // statusHistory.push({
          //   oldStatus: -1,
          //   newStatus: this.patient.status,
          //   changedBy: this.userId,
          //   changedAt: new Date(),
          //   note: this.statusNote,
          // });
          // console.log(statusHistory)
          // this.patient.statusHistory = JSON.stringify(statusHistory);

          HTTP.put("/patients/" + this.patient.id, this.patient).then(
            (result) => {
              if (result.status == 204) {
                this.isEditable = false;
                //this.$swal("", "Başarıyla güncellendi", "success");
              }
            }
          );
        } else if (
          this.patienedFileCount == 0 &&
          this.medicalFormFileCount == 0
        ) {
          this.$swal(
            "",
            // "· Lütfen Bilet veya Fee Dekont Ekleyiniz<br>· Onaylanan Teklifi Seçiniz",
            "· Lütfen Bilet veya Fee Dekont ve Medikal Form Ekleyiniz",
            "warning"
          );
        } else if (this.patienedFileCount == 0) {
          this.$swal(
            "",
            // "· Lütfen Bilet veya Fee Dekont Ekleyiniz<br>· Onaylanan Teklifi Seçiniz",
            "· Lütfen Bilet veya Fee Dekont Ekleyiniz",
            "warning"
          );
        } else if (this.medicalFormFileCount == 0) {
          this.$swal(
            "",
            // "· Lütfen Bilet veya Fee Dekont Ekleyiniz<br>· Onaylanan Teklifi Seçiniz",
            "· Lütfen Medikal Form Ekleyiniz",
            "warning"
          );
        }
      } else {
        HTTP.post("/appointments/addNotSurgeryMedicalForm/", params)
          .then(async (res) => {
            if (this.patienedFileCount != 0) {
              let updated = await this.shortUpdate();
              if (!updated) return;
              router.push({
                name: "createAppointment",
                query: {
                  id:
                    this.patient.patientCode != null
                      ? this.patient.patientCode
                      : this.patient.id,
                  convert: true,
                  temporaryConversion: this.temporaryConversion,
                  depositPaid: this.depositPaid,
                  // offerId: this.approvedOffer.id,
                },
              });
            } else {
              this.$swal(
                "",
                // "· Lütfen Bilet veya Fee Dekont Ekleyiniz<br>· Onaylanan Teklifi Seçiniz",
                "· Lütfen Bilet veya Fee Dekont Ekleyiniz",
                "warning"
              );
            }
          })
          .catch((e) => {
            this.message = "Bir şeyler ters gitti";
            console.log(e);
            console.log(e.message);
          });
      }
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    async fileCount() {
      await HTTP.get("/files/" + this.patient.id).then((result) => {
        this.patient.files = result.data;
      });
      this.medicalFormFileCount = this.patient.files.filter(
        (z) => z.filetypeId == 8
      ).length;

      this.patienedFileCount = this.patient.files.filter(
        (z) => z.filetypeId == 4
      ).length;
      if (this.medicalFormFileCount > 0 && this.patienedFileCount > 0) {
        this.patienedFileOk = true;
      } else {
        this.patienedFileOk = false;
      }
    },
    deleteFile(e) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Seçtiğiniz dosya tamamen silinecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          const deleteButton = e.target.parentElement;
          const fileId = deleteButton.dataset.fileId;
          HTTP.delete("/files?ids=" + fileId)
            .then(() => {
              this.$swal({
                icon: "success",
                text: "İşlem Başarıyla tamamlandı.",
                showConfirmButton: false,
                timer: 1500,
              });
              const index = this.patient.files.findIndex(
                (file) => file.id == fileId
              );
              const indexPatiened = this.patienedFile.findIndex(
                (file) => file.id == fileId
              );
              const indexMedical = this.medicalFormFile.findIndex(
                (file) => file.id == fileId
              );
              if (index !== -1) this.patient.files.splice(index, 1);
              if (indexPatiened !== -1) this.patienedFile.splice(index, 1);
              if (indexMedical !== -1) this.medicalFormFile.splice(index, 1);
              if (this.patient.files.length === 0) this.patienedFileOk = false;
              deleteButton.parentElement.remove();
              this.fileCount();
            })
            .catch((err) => {
              console.log(err);
              this.$swal("", "Bir Hata Oluştu!", "error");
            });
        }
      });
    },
  },
  watch: {
    patient: function () {
      this.patSource = this.sources.find((z) => z.name == this.patient.source);
      if (this.patSource) {
        this.patientSource = {
          id: this.patSource.id,
          name: this.patSource.name,
        };
      }
      this.fileCount();
    },
    patienedFile() {
      let fd = new FormData();
      fd.append("subject", "Bilet veya Fee Dekont");
      fd.append("filetypeId", 4);
      fd.append("patientId", this.patient.id);
      fd.append(
        "files",
        this.patienedFile[this.patienedFile.length - 1].file,
        this.patienedFile[this.patienedFile.length - 1].name
      );

      (this.spinnerFee = true),
        HTTP.post("/files", fd, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }).then(() => {
          this.spinnerFee = false;
          this.fileCount();
        });
    },
    medicalFormFile() {
      let fd = new FormData();
      fd.append("subject", "Medikal Form");
      fd.append("filetypeId", 8);
      fd.append("patientId", this.patient.id);
      fd.append(
        "files",
        this.medicalFormFile[this.medicalFormFile.length - 1].file,
        this.medicalFormFile[this.medicalFormFile.length - 1].name
      );
      this.spinnerMedical = true;

      HTTP.post("/files", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then(() => {
        this.spinnerMedical = false;
        this.fileCount();
      });
    },
    depositPaid: function () {
      if (this.depositPaid) this.temporaryConversion = false;
    },
    temporaryConversion: function () {
      if (this.temporaryConversion) this.depositPaid = false;
    },
  },
};
</script>

<style>
.spinner {
  margin-left: 1rem;
}

.customfileinput {
  cursor: pointer;
  border: 1px solid #636e72;
  padding: 1rem 3rem 1rem 1rem;
  width: 35rem;
  text-align-last: left;
}

.customfileinput label {
  cursor: pointer;
}

.half-hr {
  width: 50%;
  margin: 1rem 1rem 1rem 0rem;
}

.list-title {
  margin-top: -17px;
  font-size: 12px;
  color: #757575;
}
</style>
