<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId" ></Navigation>
    <AppointmentCard :appointment="appointment" :appointmentDetails="appointmentDetails" @closeAppointmentCard="closeAppointmentCard" @refreshAppointments="refreshAppointments"></AppointmentCard>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <div class="md-layout-item">
          <span class="md-title title">Randevular</span>
          <router-link
          v-if="!isDoctor && !isDietician"
            :to="{
            name:'createAppointment',
            query:{
              id: patientsId
            }
          }"
          >
            <md-button class="md-fab md-mini md-primary addButton">
              <md-icon>add</md-icon>
            </md-button>
          </router-link>
        </div>
        <md-table class="table appointments" v-model="appointments ">
          <md-table-row>
            <md-table-head>Durum</md-table-head>
            <md-table-head>Tarih</md-table-head>
            <md-table-head>Konum</md-table-head>
            <md-table-head>İşlem</md-table-head>
            <md-table-head>Katılımcılar</md-table-head>
            <md-table-head>Personel</md-table-head>
          </md-table-row>
          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            @click="showAppointmentDetails(item.id)"
          >
            <md-table-cell  md-label="Durum">
              <div
                class="status"
                v-bind:class="[
                (item.status.id === 1 ? 'waiting' : ''), 
                (item.status.id === 2 ? 'done' : ''), 
                (item.status.id === 3 ? 'cancel' : ''), 
                (item.status.id === 4 ? 'postponed' : ''), 
                (item.status.id === 5 ? 'not-come-bg' : ''), 
                (item.status.id === 6 ? 'optional' : ''), 
                (item.status.id === 7 ? 'approve' : ''), 
                (item.status.id === 8 ? 'depositPaid' : ''), 
                ]"
              ></div>
              {{ item.status.name }}
              <span v-if="getLatestAppointmentNote(item.statusHistory) != ''"><br/>({{ getLatestAppointmentNote(item.statusHistory) }})</span>
            </md-table-cell>
            <md-table-cell md-label="Tarih">
              {{ formatDate(item.date) }}
              - {{ item.time.substring(0,5) }}
            </md-table-cell>
            <md-table-cell md-label="Konum">{{ item.location.name }}</md-table-cell>
            <md-table-cell md-label="İşlem">{{ item.operation.name }}</md-table-cell>
            <md-table-cell md-label="Katılımcılar">
              <span
                class="participants"
                v-for="participants in item.AppointmentsParticipants"
                v-bind:key="participants.id"
              >{{ participants.name }}</span>
            </md-table-cell>
            <md-table-cell md-label="Personel">{{ item.user.name }}</md-table-cell>
          </md-table-row>
        </md-table>

        <div v-for="appointment in appointments" v-bind:key="appointment.id">{{ appointment.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import AppointmentCard from "@/components/patients/appointmentCard";
import moment from 'moment';

export default {
  name: "Appointments",
  data() {
    return {
      patientsId: this.$route.params.id,
      patient : null,
      appointments: {},
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: ""
        },
        statusId: "",
        location: {
          name: ""
        },
        operation: {
          name: ""
        }
      },
      appointmentDetails: false,
      created: false,
      isDietician: localStorage.getItem("roleId") == 9,
      isDoctor: localStorage.getItem("roleId") == 2
    };
  },
  components: {
    Navigation,
    Topbar,
    AppointmentCard
  },
  created() {
      HTTP.get("/patients/" + this.patientsId).then(result => {
      this.patient = result.data[0];
        
    });
    this.getAppointmentsByPatients();
  },
  methods: {
    getLatestAppointmentNote: function(statusHistory){
      if(!statusHistory)
        return "";
      let history = JSON.parse(statusHistory);
      let latestNote = history[history.length - 1];
      return latestNote.note;
    },
    formatDate(date){
      return moment(date).format("DD-MM-YYYY");
    },
    closeAppointmentCard(){
      this.appointmentDetails = false;
    },
    refreshAppointments(){
      this.getAppointmentsByPatients();
    },
    getAppointmentsByPatients: function() {
      HTTP.get("/appointments/bypatient/" +    this.patientsId   ).then(result => {
         this.appointments = result.data;
      });
    },
    showAppointmentDetails: function(id) {
      HTTP.get("/appointments/" + id).then(result => {
        this.appointment = result.data[0];
        setTimeout(() => {
          this.appointmentDetails = true;
        }, 200);
      });
    },
    viewAppointmentDetailsPage(guid){
      let routeData = this.$router.resolve(
        {
          name: 'appoinmentDetailView',
          params: { guid: guid },
        });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
.participants {
  display: block;
  span {
    display: inline-block;
  }
}
.addButton {
  float: right;
}
.table {
  width: 100%;
  margin-top: 0px;
}
.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}
.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}
.appointments {
  .md-table-row {
    cursor: pointer;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.pad {
  padding-right: 10px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;
    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}
</style>
