import vue from 'vue';
import vuex from 'vuex';

vue.use(vuex);
function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
var apiLink = process.env.VUE_APP_API_URL;
const store = new vuex.Store({
  state: {
    baseURL: apiLink,
    user: {
      id: '',
      name: '',
      userId: '',
      email: '',
      loggedIn: '',
      roleId: '',
    },
    notificationsList: [],
  }
  , countrys: [
    "Türkiye",
    "Almanya",
    "İngiltere",
    "Hollanda",
    "Bulgaristan",
    "Avusturalya",
    "Bangladeş",
    "Belçika",
    "Burkina Faso",
    "Bosna Hersek",
    "Barbados",
    "Wallis ve Futuna",
    "Saint Barthelemy",
    "Bermuda",
    "Brunei",
    "Bolivya",
    "Bahreyn",
    "Burundi",
    "Benin",
    "Butan",
    "Jamaika",
    "Bouvet Adası",
    "Botsvana",
    "Samoa",
    "Bonaire, Saint Eustatius ve Saba",
    "Brezilya",
    "Bahamalar",
    "Jersey",
    "Avusturya",
    "Beyaz Rusya",
    "Belize",
    "Rusya",
    "Ruanda",
    "Sırbistan",
    "Doğu Timor",
    "Réunion",
    "Türkmenistan",
    "Tacikistan",
    "Romanya",
    "Tokelau",
    "Gine-Bissau",
    "Guam",
    "Guatemala",
    "Güney Georgia ve Güney Sandviç Adaları",
    "Yunanistan",
    "Ekvator Ginesi",
    "Guadeloupe",
    "Japonya",
    "Guyana",
    "Guernsey",
    "Fransız Guyanası",
    "Gürcistan",
    "Grenada",
    "Birleşik Krallık",
    "Gabon",
    "El Salvador",
    "Gine",
    "Gambiya",
    "Grönland",
    "Cebelitarık",
    "Gana",
    "Umman",
    "Tunus",
    "Ürdün",
    "Hırvatistan",
    "Haiti",
    "Macaristan",
    "Hong Kong",
    "Honduras",
    "Heard Adası ve McDonald Adaları",
    "Venezüella",
    "Porto Riko",
    "Palau",
    "Portekiz",
    "Svalbard",
    "Paraguay",
    "Irak",
    "Panama",
    "Fransız Polinezyası",
    "Papua Yeni Gine",
    "Peru",
    "Pakistan",
    "Filipinler",
    "Pitcairn Adaları",
    "Polonya",
    "Saint Pierre ve Miquelon",
    "Zambiya",
    "Batı Sahra",
    "Estonya",
    "Mısır",
    "Güney Afrika",
    "Ekvador",
    "İtalya",
    "Vietnam",
    "Solomon Adaları",
    "Etiyopya",
    "Somali",
    "Zimbabwe",
    "Suudi Arabistan",
    "İspanya",
    "Eritrea",
    "Karadağ",
    "Moldova",
    "Madagaskar",
    "Saint Martin",
    "Fas",
    "Monaco",
    "Özbekistan",
    "Myanmar",
    "Mali",
    "Macao",
    "Moğolistan",
    "Marshall Adaları",
    "Makedonya",
    "Mauritius",
    "Malta",
    "Malavi",
    "Maldivler",
    "Martinik",
    "Kuzey Mariana Adaları",
    "Montserrat",
    "Moritanya",
    "Isle of Man",
    "Uganda",
    "Tanzanya",
    "Malezya",
    "Meksika",
    "İsrail",
    "Fransa",
    "İngiliz Hint Okyanusu Bölgesi",
    "Saint Helena",
    "Finlandiya",
    "Fiji",
    "Falkland Adaları",
    "Mikronezya",
    "Faroe Adaları",
    "Nikaragua",
    "Norveç",
    "Namibya",
    "Vanuatu",
    "Yeni Kaledonya",
    "Nijer",
    "Norfolk Adası",
    "Nijerya",
    "Yeni Zelanda",
    "Nepal",
    "Nauru",
    "Niue",
    "Cook Adaları",
    "Kosova",
    "Fildişi Sahilleri",
    "İsviçre",
    "Kolombiya",
    "Çin",
    "Kamerun",
    "Şili",
    "Cocos Adaları",
    "Kanada",
    "Kongo Cumhuriyeti",
    "Orta Afrika Cumhuriyeti",
    "Demokratik Kongo Cumhuriyeti",
    "Çek Cumhuriyeti",
    "Kıbrıs",
    "Noel Adası",
    "Kosta Rika",
    "Curacao",
    "Cape Verde",
    "Küba",
    "Svaziland",
    "Suriye",
    "Sint Maarten",
    "Kırgızistan",
    "Kenya",
    "Güney Sudan",
    "Surinam",
    "Kiribati",
    "Kamboçya",
    "Saint Kitts ve Nevis",
    "Komor",
    "Sao Tome ve Principe",
    "Slovakya",
    "Güney Kore",
    "Slovenya",
    "Kuzey Kore",
    "Kuveyt",
    "Senegal",
    "San Marino",
    "Sierra Leone",
    "Seyşel Adaları",
    "Kazakistan",
    "Kayman Adaları",
    "Singapur",
    "İsveç",
    "Sudan",
    "Dominik Cumhuriyeti",
    "Dominika",
    "Cibuti",
    "Danimarka",
    "İngiliz Virgin Adaları",
    "Yemen",
    "Cezayir",
    "Amerika Birleşik Devletleri",
    "Uruguay",
    "Mayotte",
    "Birleşik Devletlerin Küçük Dış Adaları",
    "Lübnan",
    "Saint Lucia",
    "Laos",
    "Tuvalu",
    "Tayvan",
    "Trinidad ve Tobago",
    "Sri Lanka",
    "Lihtenştayn",
    "Letonya"
  ],
  mutations: {
    loggedIn: (state) => {
      state.user.loggedIn = true;
    },
    signOut: (state) => {
      deleteAllCookies();
      state.user.loggedIn = false;
      state.user.id = "";
      state.user.name = "";
      state.user.email = "";
      state.user.roleId = "";
    },
    setUser: (state, data) => {
      state.user.id = data.id;
      state.user.name = data.name;
      state.user.email = data.email;
      state.user.roleId = data.roleId;
    }
  },
  actions: {
    login: ({ commit }) => {
      commit('loggedIn');
    },
    signOut: ({
      commit
    }) => {
      commit('signOut');
    },
    setUser: ({ commit }, data) => {
      commit('setUser', data);
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user.loggedIn;
    },
    getRoleId: (state) => {
      return state.user.roleId;
    }
  }
});

export default store;
