import { render, staticRenderFns } from "./whens.vue?vue&type=template&id=0832754c&scoped=true"
import script from "./whens.vue?vue&type=script&lang=js"
export * from "./whens.vue?vue&type=script&lang=js"
import style0 from "./whens.vue?vue&type=style&index=0&id=0832754c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0832754c",
  null
  
)

export default component.exports