<template>
  <div class="wrapper">
    <span class="md-title">Diller</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addLanguageModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="languages">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Dil Kodu">{{ item.code }}</md-table-cell>
        <md-table-cell md-label="Dil Adı">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateLanguage(item.id)">edit</i>
          <!-- <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteLanguage(item.id)">delete</i> -->
        </md-table-cell>
        <md-table-cell md-label="Dil Dostaları">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="viewFileTemplates(item)">text_snippet</i>
        </md-table-cell>
        <md-table-cell md-label="Tercüme">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateTranslation(item.id)">translate</i>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addLanguageModal" class="dialog">
      <md-content class="statuses">
        <h3>Yeni Dil</h3>
        <md-field>
          <label>Dil Kodu *</label>
          <md-input v-model="language.code"></md-input>
        </md-field>
        <md-field>
          <label>Dil Adı *</label>
          <md-input v-model="language.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Dil Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="addLanguage">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateLanguageModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Dil Kodu *</label>
          <md-input v-model="language.code"></md-input>
        </md-field>
        <md-field>
          <label>Dil Adı *</label>
          <md-input v-model="language.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Dil Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateLanguageDone">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="languageFileTemplatesModal" class="dialog">
      <md-content class="statuses">
      <md-button class="md-fab md-mini md-primary editButton" v-on:click="addFileTemplatesModal=true;languageFileTemplatesModal=false">
      <md-icon>add</md-icon>
    </md-button>
        <h3>Dil Dosyaları</h3>
            <md-table class="table appointments" v-model="languageFileTemplates">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Ad">
            <a :href="generateLink(item.path)" target="blank" :download="item.name">
                <md-icon>open_in_new</md-icon>
                {{ item.name }}
              </a>
        </md-table-cell>
         <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteFileTemplate(item.id)">delete</i>
        </md-table-cell>
      </md-table-row>
    </md-table>
      </md-content>
    </md-dialog>
    <md-dialog :md-active.sync="addFileTemplatesModal" class="dialog">
      <md-content class="statuses">
        <h3>Dosya Ekle</h3>
        <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <md-field>
          <label>Dosya Adı *</label>
          <md-input v-model="fileTemplateName"></md-input>
        </md-field>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
               <v-select
                v-model="fileTemplateOperation"
                placeholder="Tedavi"
                :options="operations"
                label="name"
                class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
            </md-field>
          </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
            Dosya Tipi
            <md-field>
               <v-select
                placeholder="Dosya Tipi"
                v-model="fileTemplateFileType"
                :options="filetypes"
                label="name"
                class="md-menu"
                :clearable="false"
                >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
                </v-select>
            </md-field>
          </div>
          </div>
        <file-upload ref="upload" class="file" v-model="files" accept=".doc,.docx">
          <md-icon>attach_file</md-icon>Dosya Ekleyin
        </file-upload>
        <ul>
          <li v-for="(file,index) in files" :key="index">{{file.name}}</li>
        </ul>
        <div class="md-layout-item row">
            <md-button class="md-raised md-primary" v-on:click="addFileTemplate">Yükle</md-button>
          </div>
      </md-content>
    </md-dialog>
    <md-dialog v-if="updateTranslationModal" :md-active.sync="updateTranslationModal" class="dialog">
      <md-dialog-content>
        <h3>Tercüme</h3>
        <md-field>
            <label>{{ trTranslationText.yes }}</label>
              <md-input v-model="translationText.yes"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.nope }}</label>
          <md-input v-model="translationText.nope"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.title }}</label>
          <md-input v-model="translationText.title"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.page1 }}</label>
          <md-input v-model="translationText.page1"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.height }}</label>
          <md-input v-model="translationText.height"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.weight }}</label>
          <md-input v-model="translationText.weight"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.section1 }}</label>
          <md-input v-model="translationText.section1"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.diabetes }}</label>
          <md-input v-model="translationText.diabetes"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.thyroid }}</label>
          <md-input v-model="translationText.thyroid"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.heart }}</label>
          <md-input v-model="translationText.heart"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.lungs }}</label>
          <md-input v-model="translationText.lungs"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.pressure }}</label>
          <md-input v-model="translationText.pressure"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.kidney }}</label>
          <md-input v-model="translationText.kidney"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.liver }}</label>
          <md-input v-model="translationText.liver"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.blood }}</label>
          <md-input v-model="translationText.blood"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.cancer }}</label>
          <md-input v-model="translationText.cancer"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.hiv }}</label>
          <md-input v-model="translationText.hiv"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.psyc }}</label>
          <md-input v-model="translationText.psyc"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.no }}</label>
          <md-input v-model="translationText.no"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.an }}</label>
          <md-input v-model="translationText.an"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.page2 }}</label>
          <md-input v-model="translationText.page2"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.formerly_surgery }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.formerly_surgery"></md-textarea>
            </md-field>
          </div>
        </div>
        <md-field>
          <label>{{ trTranslationText.gastric }}</label>
          <md-input v-model="translationText.gastric"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.no }}</label>
          <md-input v-model="translationText.gastric_opt.no"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.sleeve }}</label>
          <md-input v-model="translationText.gastric_opt.sleeve"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.bypass }}</label>
          <md-input v-model="translationText.gastric_opt.bypass"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.band }}</label>
          <md-input v-model="translationText.gastric_opt.band"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.plication }}</label>
          <md-input v-model="translationText.gastric_opt.plication"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.balloon }}</label>
          <md-input v-model="translationText.gastric_opt.balloon"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.gastric_opt.other }}</label>
          <md-input v-model="translationText.gastric_opt.other"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.diseases }}</label>
          <md-input v-model="translationText.diseases"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.implant }}</label>
          <md-input v-model="translationText.implant"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.wound }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.wound"></md-textarea>
            </md-field>
          </div>
        </div>
        <md-field>
          <label>{{ trTranslationText.allergy }}</label>
          <md-input v-model="translationText.allergy"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.daily_medicines }}</label>
          <md-input v-model="translationText.daily_medicines"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.vitamin_or_supplement }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.vitamin_or_supplement"></md-textarea>
            </md-field>
          </div>
        </div>
        <md-field>
          <label>{{ trTranslationText.page3 }}</label>
          <md-input v-model="translationText.page3"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.inhibitor }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.inhibitor"></md-textarea>
            </md-field>
          </div>
        </div>
        <md-field>
          <label>{{ trTranslationText.blood_thinner }}</label>
          <md-input v-model="translationText.blood_thinner"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.alcohol }}</label>
          <md-input v-model="translationText.alcohol"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.cigarette }}</label>
          <md-input v-model="translationText.cigarette"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment }}</label>
          <md-input v-model="translationText.requested_treatment"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_opt.opt1 }}</label>
          <md-input v-model="translationText.requested_treatment_opt.opt1"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_opt.opt2 }}</label>
          <md-input v-model="translationText.requested_treatment_opt.opt2"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_opt.opt3 }}</label>
          <md-input v-model="translationText.requested_treatment_opt.opt3"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_opt.opt4 }}</label>
          <md-input v-model="translationText.requested_treatment_opt.opt4"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_opt.opt5 }}</label>
          <md-input v-model="translationText.requested_treatment_opt.opt5"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_holder }}</label>
          <md-input v-model="translationText.requested_treatment_holder"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.requested_treatment_indicate }}</label>
          <md-input v-model="translationText.requested_treatment_indicate"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.addiction }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.add"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.addiction_holder }}
            </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.addiction_holder"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            {{ trTranslationText.additional_note }}
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-textarea v-model="translationText.additional_note"></md-textarea>
            </md-field>
          </div>
        </div>
        <md-field>
          <label>{{ trTranslationText.explain }}</label>
          <md-input v-model="translationText.explain"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.ok }}</label>
          <md-input v-model="translationText.editOkButton"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.not_found }}</label>
          <md-input v-model="translationText.not_found"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.name }}</label>
          <md-input v-model="translationText.name"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.sex }}</label>
          <md-input v-model="translationText.sex"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.female }}</label>
          <md-input v-model="translationText.female"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.male }}</label>
          <md-input v-model="translationText.male"></md-input>
        </md-field>
        <md-field>
          <label>{{ trTranslationText.birthday }}</label>
          <md-input v-model="translationText.birthday"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateTranslationDone()">Güncelle</md-button>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Languages",
  data() {
    return {
      title: "Diller",
      languages: [],
      languageFileTemplates: [],
      fileTemplateName: "",
      fileTemplateOperation:null,
      fileTemplateFileType: null,
      files: [],
      filetypes: [],
      operations: [],
      language: {
        name: ""
      },
      addLanguageModal: false,
      updateLanguageModal: false,
      languageFileTemplatesModal: false,
      selectedLanguageId: 0,
      addFileTemplatesModal: false,
      isValid: {
        name: null,
        duration: null
      },
      storagepath: this.$storage,
      updateTranslationModal: false,
      trTranslation: null,
      trTranslationText: null,
      translation: {},
      translationText: {
        yes:"",
        nope:"",
        title:"",
        page1:"",
        height:"",
        weight:"",
        section1:"",
        diabetes:"",
        thyroid:"",
        heart:"",
        lungs:"",
        pressure:"",
        kidney:"",
        liver:"",
        blood:"",
        cancer:"",
        hiv:"",
        psyc:"",
        no:"",
        an:"",
        page2:"",
        formerly_surgery:"", 
        gastric:"", 
        gastric_opt:{
          no:"",
          sleeve:"",
          bypass:"",
          band:"",
          plication:"",
				  balloon:"",
          other:""
        },
        diseases:"",
        implant:"",
        wound:"",
        allergy:"",
        daily_medicines:"",
        vitamin_or_supplement:"",
        page3:"",
        inhibitor:"",
        blood_thinner:"",
        alcohol:"",
        cigarette:"",
        requested_treatment:"",
        requested_treatment_opt:{
          opt1:"",
          opt2:"",
          opt3:"",
          opt4:"",
          opt5:""
        },
        requested_treatment_holder:"",
        requested_treatment_indicate:"",
        addiction:"",
        addiction_holder:"",
        additional_note:"",
        explain:"",
        ok:"",
        not_found:"",name:"",
        sex:"",
        female:"",
        male:"",
        birthday:""
      },
      newTranslation: true,
    };
  },
  created() {
       HTTP.get("/operations/").then(result => {
        this.operations = result.data;
      });
        HTTP.get("filetypes").then(result => {
        this.filetypes = result.data;
        this.fileTemplateFileType = this.filetypes[0];
      });
    this.getLanguages();
  },
  methods: {
    generateLink(path){
      return path.startsWith('http') ? path : this.storagepath + path;
    },
    addLanguage: function() {
      HTTP.post("/languages/", this.language).then(result => {
        if (result.status == 201) {
          this.getLanguages();
          this.addLanguageModal = false;

          this.isvalid = {
            name: null
          };
        }
      });
    },
    getLanguages: function() {
      HTTP.get("/languages/").then(result => {
        this.languages = result.data;
      });
    },
    updateLanguage: function(id) {
      this.language = this.languages.filter(languages => languages.id == id)[0];
      this.updateLanguageModal = true;
    },
    updateLanguageDone: function() {
      HTTP.put("/languages/" + this.language.id, this.language).then(result => {
        if (result.status == 204) {
          this.getLanguages();
          this.updateLanguageModal = false;
        }
      });
    },
    deleteLanguage: function(id) {
      HTTP.delete("/languages/?ids=" + id).then(result => {
        if (result.status == 204) {
          this.getLanguages();
        }
      });
    },
    viewFileTemplates: function(lang){
        this.selectedLanguageId = lang.id;
        this.languageFileTemplates = lang.fileTemplates;
        this.languageFileTemplatesModal = true;
    },
    addFileTemplate: function(){
        if(this.files.length <= 0 || this.fileTemplateName == ""){
            alert("Dosya ve dosya Adı Ekleyin!");
            return;
        }
        let fd = new FormData();
      this.files.forEach(file => {
        fd.append("files", file.file, file.name);
      });
      fd.append("name", this.fileTemplateName);
      fd.append("languageId", this.selectedLanguageId);
      fd.append("operationId", this.fileTemplateOperation ? this.fileTemplateOperation.id : null);
      fd.append("fileTypeId", this.fileTemplateFileType ? this.fileTemplateFileType.id : null);

      HTTP.post("/fileTemplates", fd, {
          headers: {
            "content-type": "multipart/form-data"
          }
        }).then(() => {
            this.$swal({
              icon: 'success',
              text: 'İşlem Başarıyla tamamlandı.',
              showConfirmButton: false,
              timer: 1500
            });
            this.addFileTemplatesModal = false;
          this.fileTemplateOperation = null;
          this.fileTemplateName = "";
          this.files = [];
          this.getLanguages();
        });
    },
    deleteFileTemplate: function(id){
        if(confirm("Emin misiniz?")){
            HTTP.delete("/fileTemplates?ids=" + id).then(result => {
            if (result.status == 204) {
            this.languageFileTemplatesModal = false;
        this.$swal({
              icon: 'success',
              text: 'İşlem Başarıyla tamamlandı.',
              showConfirmButton: false,
              timer: 1500
            });
            this.getLanguages();
            }
      });   
        }
    },
    updateTranslation: function(id){
      this.language = this.languages.filter(languages => languages.id == id)[0];
      HTTP.get('/translation/1').then(result => {
          console.log("TR", result);
          this.trTranslation = result.data;
          this.trTranslationText = JSON.parse(this.trTranslation.translation);
          console.log("TR Text", this.trTranslationText);
          HTTP.get('/translation/' + id).then(result => {
            if(result.data){
              console.log("new", result);
              this.translation = result.data;
              this.translationText = JSON.parse(this.translation.translation);
              this.newTranslation = false;
            }
            else{
              this.newTranslation = true;
              this.translation.languageId = this.language.id;
            }
          });
          this.updateTranslationModal = true;
      }).catch((e) => {

      });
    },
    updateTranslationDone: function(){
      this.translation.translation = JSON.stringify(this.translationText);
      if(this.newTranslation)
        HTTP.post('/translation/', this.translation).then(result => {
          if(result.status == 201){
            this.updateTranslationModal = false;
            this.$swal({
              icon: 'success',
              text: 'Tercüme başarıyla eklendi',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }).catch(e => {
          this.$swal({
            icon: 'error',
            text: e.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
        else
          HTTP.put('/translation/' + this.translation.languageId, this.translation).then(result => {
            if(result.status == 201){
            this.updateTranslationModal = false;
            this.$swal({
              icon: 'success',
              text: 'Tercüme başarıyla güncellendi',
              showConfirmButton: false,
              timer: 1500
            });
            }
          }).catch(e => {
            this.$swal({
              icon: 'error',
              text: e.message,
              showConfirmButton: false,
              timer: 1500,
            });
          });
    }
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
</style>