<template>
    <div>
        <div class="md-layout wrapper">
          <img src="../../../../../public/images/logo.png" alt="Logo" />
          <span class="md-layout-item md-size-100 md-title"
            >{{ translationText ? translationText.title : 'Medikal Bilgiler' }}</span>
          <md-tabs class="md-transparent" md-alignment="fixed" v-if="isValid">
            
            <md-tab id="tab-home" :md-label="translationText ? translationText.page1 : 'Medikal Bilgiler'">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ translationText ? translationText.height : 'Boy' }} (cm)</label>
                  <md-input v-model="medicalinfo.height" :disabled="!isEditable"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>{{ translationText ? translationText.weight : 'Kilo' }} (kg)</label>
                  <md-input v-model="medicalinfo.weight" :disabled="!isEditable"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-100">
                <b>{{ translationText ? translationText.section1 : 'Sağlık Durumu' }}</b>
              </div>
              <div class="md-layout-item md-size-100">
                <md-table class="table appointments medicalhistory">
                  <md-table-row>
                    <md-table-head class="md-size-100"></md-table-head>
                    <md-table-head>{{ translationText ? translationText.yes: 'Evet' }}</md-table-head>
                    <md-table-head>{{ translationText ? translationText.nope : 'Hayır' }}</md-table-head>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >{{ translationText ? translationText.diabetes : 'Diyabet veya kan problemi' }}</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.diabetes"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.diabetes"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >{{ translationText ? translationText.thyroid : 'Tiroid problemi' }}</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.thyroid"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.thyroid"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >{{ translationText ? translationText.heart : 'Kalp problemi/ kondisyon' }}</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.heart"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.heart"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >{{ translationText ? translationText.lungs :'Akciğer problemi / performans' }}</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.lungs"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.lungs"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.pressure : 'Tansiyon problemi' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.pressure"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.pressure"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.kidney : 'Böbrek problemi' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.kidney"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.kidney"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.liver : 'Karaciğer problemi' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.liver"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.liver"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.blood : 'Kan hastalıkları' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.blood"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.blood"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.cancer : 'Kanser geçmişi' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.cancer"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.cancer"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.hiv : 'HIV veya AIDS' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.hiv"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.hiv"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label
                      >{{ translationText ? translationText.psyc : 'Ruh ve sinir sistemi depresyon' }}</md-table-cell
                    >
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.psyc"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.psyc"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.no : 'Nörolojik problemler' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.no"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.no"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                  <md-table-row slot="md-table-row">
                    <md-table-cell md-label>{{ translationText ? translationText.an : 'Anestezi problemleri' }}</md-table-cell>
                    <md-table-cell md-label="Evet">
                      <md-radio
                        v-model="medicalinfo.an"
                        :value="true"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                    <md-table-cell md-label="Hayır">
                      <md-radio
                        v-model="medicalinfo.an"
                        :value="false"
                        :disabled="!isEditable"
                      ></md-radio>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </md-tab>
            <md-tab id="tab-pages" :md-label="translationText ? translationText.page2 : 'Ameliyat - İlaç' ">
              <div class="md-layout">
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.formerly_surgery : 'Daha önce hiç ameliyat oldunuz mu? Veya herhangi bir sebepten hastane de yatmak zorunda kaldınız mı?' }}
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.formerly_surgery"
                      :value="true"
                      :disabled="!isEditable"
                      >Evet</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.formerly_surgery"
                      :value="false"
                      :disabled="!isEditable"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.formerly_surgery">
                      <md-textarea
                        v-model="medicalinfo.formerly_surgery_desc"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.gastric : 'hiç mide ameliyatı oldun mu?' }}
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="no"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.no : 'Daha Önce Mide Ameliyatı Olmamış' }}</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="sleeve"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.sleeve : 'Mide Kol' }}</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="bypass"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.bypass : 'Mide baypası' }}</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="band"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.band : 'Mide Bandı' }}</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.gastric"
                      value="plication"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.plication : 'Gastrik Plikasyon' }}</md-radio
                    >
                  <md-radio
                      v-model="medicalinfo.gastric"
                      value="balloon"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.balloon : 'Mide Balonu' }}</md-radio
                    >
                  <md-radio
                      v-model="medicalinfo.gastric"
                      value="other"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.gastric_opt.other : 'Diğer' }}</md-radio
                    >
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.gastric=='other'">
                      <md-input
                        v-model="medicalinfo.gastric_other"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-input>
                    </md-field>
                  </div>
                </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.diseases : 'Herhangi bir hastalığın var mı?' }}
                  <div class="md-layout-item">
                    <md-radio
                      v-model="medicalinfo.diseases"
                      :value="true"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                    >
                    <md-radio
                      v-model="medicalinfo.diseases"
                      :value="false"
                      :disabled="!isEditable"
                      >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.diseases">
                      <md-textarea
                        v-model="medicalinfo.diseases_desc"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.implant : 'Vücudunuzda herhangi bir implant veya metal bir cisim var mı?' }}
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.implant" 
                      :value="true" 
                      :disabled="!isEditable"
                      >Evet</md-radio
                    >
                    <md-radio v-model="medicalinfo.implant" 
                      :value="false" 
                      :disabled="!isEditable"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.implant">
                      <md-textarea
                        v-model="medicalinfo.implant_desc"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.wound : 'Yara yeri iyileşmesi ile ilgili bir probleminiz olduğunu düşünüyor musunuz?' }}
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.wound" 
                      :value="true"
                      :disabled="!isEditable"
                      >Evet</md-radio
                    >
                    <md-radio v-model="medicalinfo.wound" 
                      :value="false"
                      :disabled="!isEditable"
                      >Hayır</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.wound">
                      <md-textarea
                        v-model="medicalinfo.wound_desc"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.allergy : 'Herhangi bir gıdaya veya ilaca alerjiniz var mı?' }}
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.allergy" :value="true" :disabled="!isEditable"
                      >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                    >
                    <md-radio v-model="medicalinfo.allergy" :value="false" :disabled="!isEditable"
                      >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.allergy">
                      <md-textarea
                        v-model="medicalinfo.allergy_desc"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  <div class="md-layout-item">
                    <label
                      >{{ translationText ? translationText.daily_medicines : 'Kullandığınız ilaçları günlük dozları ile birlikte lütfen sıralayınız.' }}</label
                    >
                    <md-field>
                      <md-textarea
                        v-model="medicalinfo.daily_medicines"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
                <!-- Row -->
                <div class="md-layout-item md-size-100">
                  <div class="md-layout-item">
                    <label
                      >{{ translationText ? translationText.vitamin_or_supplement : 'Şu anda kullanmakta olduğunuz tüm vitamin veya gıda takviyesi ürünlerini listeleyiniz.' }}</label
                    >
                    <md-field>
                      <md-textarea
                        v-model="medicalinfo.vitamin_or_supplement"
                        :placeholder="translationText ? translationText.explain : 'Lütfen açıklayınız'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
              </div>
            </md-tab>
            <md-tab id="tab-posts" :md-label="translationText ? translationText.page3 : 'Alerji - Tedavi - Ek Not'">
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.inhibitor : 'Hiç Nardil, Marplan veya Parnate gibi bir MAO inhibitörü ilaç kullandınız mı?' }}
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.inhibitor" :value="true" :disabled="!isEditable"
                    >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                  >
                  <md-radio v-model="medicalinfo.inhibitor" :value="false" :disabled="!isEditable"
                    >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                  >
                </div>
              </div>
              <!-- Row End -->
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.blood_thinner : 'Kan sulandırı herhangi bir ilaç kullanıyor musunuz?' }}
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.blood_thinner" :value="true" :disabled="!isEditable"
                    >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                  >
                  <md-radio v-model="medicalinfo.blood_thinner" :value="false" :disabled="!isEditable"
                    >{{ translationText ? translationText.nope: 'Hayır' }}</md-radio
                  >
                </div>
              </div>
              <!-- Row End -->
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.alcohol : 'Alkol kullanıyor musunuz?' }}
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.alcohol" :value="true" :disabled="!isEditable"
                    >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                  >
                  <md-radio v-model="medicalinfo.alcohol" :value="false" :disabled="!isEditable"
                    >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                  >
                </div>
              </div>
              <!-- Row End -->
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.cigarette : 'Sigara Kullanıyor musunuz?' }}
                <div class="md-layout-item">
                  <md-radio v-model="medicalinfo.cigarette" :value="true" :disabled="!isEditable"
                    >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                  >
                  <md-radio v-model="medicalinfo.cigarette" :value="false" :disabled="!isEditable"
                    >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                  >
                </div>
              </div>
              <!-- Row End -->
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.requested_treatment : 'Hangi tedavi yöntemimizi düşünüyor sunuz?' }}
                <div class="md-layout-item">
                  <md-field>
                    <v-select
                      :clearable="false"
                      :searchable="false"
                      v-model="requested_treatment"
                      :placeholder="translationText ? translationText.requested_treatment_holder : 'Lütfen Düşündüğünüz Tedaviyi Seçiniz'"
                      :options="requestedTreatments"
                      label="name"
                      class="md-menu"
                      :disabled="!isEditable"
                    >
                      <template slot="option" slot-scope="option">{{
                        option.name
                      }}</template>
                    </v-select>
                  </md-field>
                  <md-field v-if="requested_treatment.name == (translationText ? translationText.requested_treatment_opt.opt5 : 'Diğer..')">
                    <label>{{ translationText ? translationText.requested_treatment_indicate : 'Lütfen düşünüğünüz tedaviyi belirtiniz' }}</label>
                    <md-input
                      v-model="medicalinfo.requested_treatment" :disabled="!isEditable"
                    ></md-input>
                  </md-field>
                </div>
              </div>
              <!-- Row End -->
              <!-- Row -->
                <div class="md-layout-item md-size-100">
                  {{ translationText ? translationText.addiction : 'Daha önce veya şu anda herhangi bir uyuşturucu/madde bağımlılığınız var mı?' }}
                  <div class="md-layout-item">
                    <md-radio v-model="medicalinfo.addiction" :value="true" :disabled="!isEditable"
                      >{{ translationText ? translationText.yes : 'Evet' }}</md-radio
                    >
                    <md-radio v-model="medicalinfo.addiction" :value="false" :disabled="!isEditable"
                      >{{ translationText ? translationText.nope : 'Hayır' }}</md-radio
                    >
                  </div>
                  <div class="md-layout-item">
                    <md-field v-if="medicalinfo.addiction">
                      <md-textarea
                        v-model="medicalinfo.addiction_desc"
                        :placeholder="translationText ? translationText.addiction_holder : 'Lütfen kullandığınız (veya kullandığınız) maddeleri yazınız ve miktarını belirtiniz'"
                        :disabled="!isEditable"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
                <!-- Row End -->
              <!-- Row -->
              <div class="md-layout-item md-size-100">
                {{ translationText ? translationText.additional_note : 'Bizimle paylaşmanız gereken başka bir şey olduğunu düşünüyor musunuz?' }}
                <div class="md-layout-item">
                  <md-field>
                    <label>{{ translationText ? translationText.explain : 'Açıklama' }}</label>
                    <md-textarea
                      v-model="medicalinfo.additional_note" :disabled="!isEditable"
                    ></md-textarea>
                  </md-field>
                </div>
              </div>
              <!-- Row End -->
            </md-tab>
          </md-tabs>

          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="updateMedicalInfo"
            v-if="isValid"
            :disabled="!isEditable"
            >{{ translationText ? translationText.ok : 'Kaydet ve gönder' }}</md-button>
          <span class="md-layout-item md-size-100" v-else>{{ translationText ? translationText.not_found : 'Sayfa bulunamadı' }}</span>
        </div>
    </div>
</template>
<script>
  import { HTTP } from "@/main-source";
  import router from "@/router";
  export default {
      name: "MedicalInformation",
      data(){
          return { 
              medicalinfo: {
                  height: null,
                  weight: null,
                  an: null,
                  no: null,
                  psyc: null,
                  hiv: null,
                  cancer: null,
                  blood: null,
                  liver: null,
                  kidney: null,
                  pressure: null,
                  lungs: null,
                  heart: null,
                  diabetes: null,
                  thyroid: null,
                  formerly_surgery: null,
                  formerly_surgery_desc: "",
                  implant: null,
                  implant_desc: "",
                  wound: null,
                  wound_desc: "",
                  allergy: false,
                  allergy_desc: "",
                  daily_medicines: "",
                  vitamin_or_supplement: "",
                  inhibitor: false,
                  blood_thinner: false,
                  alcohol: false,
                  cigarette: false,
                  requested_treatment: null,
                  additional_note: "",
                  gastric: "no",
                  gastric_other:"",
                  diseases:false,
                  diseases_desc: "",
                  addiction: false,
                  addiction_desc:"",
                  allow_edit: true,
              },
              requested_treatment: {
                  name: "Lütfen Düşündüğünüz Tedaviyi Seçiniz"
              },
              requestedTreatments: [
                  {
                  name: "Tüp Mide"
                  },
                  {
                  name: "Şeker Ameliyatı"
                  },
                  {
                  name: "Mide Balonu"
                  },
                  {
                  name: "Mide Botoksu"
                  },
                  {
                  name: "Diğer.."
                  }
              ],
              isValid: false,
              isEditable: true,
              firstMedicalInfo: true,
              patient: null,
              translation: null,
              translationText: null,
          }
      },
      created: function(){
        HTTP.get('/patients/token/' + this.$route.params.token).then(result => {
          if(result.data[0]){
            this.patient = result.data[0];

            let langId = this.patient.language ? this.patient.language.id : 1;
            
            HTTP.get('/translation/'+ this.$route.params.token + '/' + langId)
            .then(result => {
              if(result.data)
                this.translation = result.data;
                this.translationText = JSON.parse(this.translation.translation);
                this.requestedTreatments = [{name: this.translationText.requested_treatment_opt.opt1}, 
                                            {name: this.translationText.requested_treatment_opt.opt2}, 
                                            {name: this.translationText.requested_treatment_opt.opt3}, 
                                            {name: this.translationText.requested_treatment_opt.opt4}, 
                                            {name: this.translationText.requested_treatment_opt.opt5}
                                            ];
            });
          }
        })

        HTTP.get("/medicalinfos/load/" + this.$route.params.token).then(result => {
        if (result.data[0]) {
          this.firstMedicalInfo = false;
          
          this.medicalinfo = result.data[0];
          this.isEditable = this.medicalinfo.allow_edit;
          if (this.medicalinfo.requested_treatment) {
            this.requested_treatment.name = this.medicalinfo.requested_treatment;
          }
        }
        if(result.status != 401)
          this.isValid = true;
      }).catch((e) => {
        });
      },
      methods: {
        uploadPDF: function(fileBlob, mId){
          let fd = new FormData();
          let filename = 'medicalinfo-' + this.patient.name + '-' + mId + '.pdf';
          fd.append("file", fileBlob, filename);
          fd.append("medicalInfoId", mId);
          fd.append("patientId", this.medicalinfo.patientId);
          fd.append("notifyUser", 0);//0: no, 1: email, 2: whatsapp, 3: both
          HTTP.post("/medicalinfos/upload/" + this.$route.params.token + "/", fd, {
            headers: {
              "content-type" : "applicatiom/form-data"
            }
          });
        },
          toPDF: function(){
            this.patient.medicalInfo = this.medicalinfo;
            medicalInfoForm2PDF(this.translationText, this.patient, false, false).then((result) => {
              this.uploadPDF(result, this.medicalinfo.id);
            });
          },
          updateMedicalInfo: function() {
            this.$swal({
              title: "Emin misiniz?",
              text:  "Tüm alanları doldurduğunuzdan emin misiniz?",
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#ff5252",
              confirmButtonText: "Gönder",
              cancelButtonText: "İptal"
            }).then((result) => {
              if(result.isConfirmed){
                this.medicalinfo.allow_edit = false;
                if(this.firstMedicalInfo){
                  HTTP.post("/medicalinfos/" + this.$route.params.token + "/", this.medicalinfo)
                  .then((result) => {
                      //Upload PDF file to backend.
                      this.medicalinfo.id = result.data.id;
                      this.toPDF();
                      
                      this.$swal({
                        icon: 'success',
                        text: 'Formunuz Başarıyla Gönderildi',
                        showConfirmButton: false,
                        timer: 2000
                      });
                      this.isEditable = false;
                  })
                  .catch(() => {
                      this.$swal("", "Bir hata oluştu!", "error");
                  });
                }
                else{
                  HTTP.put("/medicalinfos/token/" + this.$route.params.token + "/", this.medicalinfo)
                  .then(() => {
                      this.toPDF();
                      this.$swal({
                        icon: 'success',
                        text: 'Formunuz Başarıyla Gönderildi',
                        showConfirmButton: false,
                        timer: 1000
                      });
                      this.isEditable = false;
                  })
                  .catch((e) => {
                      this.$swal(e.message, "Bir hata oluştu!", "error");
                  });
                }
              }
            });
          },
      },
      watch: {
          requested_treatment: function(val) {
              if (val.name != "Diğer..") {
                  this.medicalinfo.requested_treatment = val.name;
              } else {
                  this.medicalinfo.requested_treatment = null;
              }
          }
      }
  }
</script>
<style lang="scss">
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
</style>
