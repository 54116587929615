<template>
  <div class="vld-parent">
  <Loading :active.sync="isUploading"
                 :can-cancel="false"
                 :is-full-page="true"
                 color="#132573" />
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <div class="md-layout-item">
          <span class="md-title title">Dosyalar</span>
        </div>
        <md-table class="table files" v-model="files">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Seçim">
              <md-radio v-model="selectedFile" :value="item.id"></md-radio>
            </md-table-cell>
            <md-table-cell md-label="Konu">
              <a :href="generateLink(item.path)" target="blank">
                <md-icon>open_in_new</md-icon>
                {{ item.subject ? item.subject : item.name }}
              </a>
            </md-table-cell>
            <md-table-cell md-label="Dosya Tipi">{{
              item.filetypeId ? item.filetype.name : ""
            }}</md-table-cell>
            <md-table-cell md-label="Eklenme Tarihi">{{
              dateString(item.createdAt)
            }}</md-table-cell>

            <md-table-cell md-label="Ekleyen">{{
              item.userId ? item.user.name : ""
            }}</md-table-cell>
          </md-table-row>
        </md-table>
        <md-button
          class="md-raised md-primary text-right"
          v-on:click="sendFile"
          v-if="files.length > 0"
          >Seçili Dosyayı Mail Olarak İlet</md-button
        >
        <md-button
          class="md-raised md-primary text-right" style="margin-left: 10px !important;"
          v-on:click="editFile"
          v-if="files.length > 0 && selectedFile"
          >Dosya Adı Güncelle</md-button
        >
        <md-button
          class="md-raised md-accent text-right" style="margin-left: 10px !important;"
          v-on:click="deleteFile"
          v-if="files.length > 0 && selectedFile"
          >DOSYAYI SİL</md-button
        >
      </div>
    </div>
    <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-50 md-xsmall-size-100 wrapper">
        <div class="md-layout md-gutter">
          <span class="md-title title">Epikriz Dosyası Ekle ({{ usedLanguage }})</span>
        </div>

        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            Dosya
            <md-field>
              <v-select
                v-model="fileTemplate"
                placeholder="Dosya"
                :options="patientLanguage.fileTemplates"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item"  v-if="fileTemplate">
            Başlık
            <md-field>
              <md-input v-model="fileTitle"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-button
                    type="button"
                    class="brand-button add-new md-raised md-primary addFileTemplateButton"
                    v-if="fileTemplate"
                    @click="addFileTemplate"
                    :disabled="isUploading"
                    >
                    Ekle
                    </md-button
                  >
          </div>
        </div>
      </div> 
      <div class="md-layout-item md-size-50 md-xsmall-size-100 wrapper">
        <div class="md-layout md-gutter">
          <span class="md-title title">Yeni Dosya Yükle</span>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label>Konu</label>
              <md-input v-model="newFile.subject"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item">
            <br />
            <br />
            <v-select
              placeholder="Dosya Tipi"
              v-model="fileType"
              :searchable="false"
              :options="filetypes"
              label="name"
              class="md-menu"
              :clearable="false"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-40">
            <ul>
              <li v-for="(file, index) in uploadedFiles" :key="index">
                {{ file.name }}
              </li>
            </ul>
            <file-upload ref="upload" class="file" v-model="uploadedFiles" :accept="acceptedFiles">
              <md-icon>attach_file</md-icon>Dosya Ekleyin
            </file-upload>
          </div>
          <div class="md-layout-item text-right">
            <div class="md-layout">
              <div class="md-layout-item row">
                <md-button class="md-raised md-primary" @click.native="addFile"
                  >Kaydet</md-button
                >
              </div>
            </div>
            <md-datepicker
              v-model="newFile.date"
              name="date"
              v-if="newFile.notify_me"
              md-immediately
            >
              <label>Ne Zaman</label>
            </md-datepicker>
          </div>
        </div>
      </div>
    </div>
    
    <md-dialog :md-active.sync="updateFileNameModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Dosya Adı *</label>
          <md-input v-model="selectedFileName"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateFileName">Güncelle</md-button>
      </md-content>
    </md-dialog>

  </div>
</template>

<script>
import Topbar from "@/components/patients/potantials/topbar";
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import router from "../../../../router";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "potantial.patient.files",
  data() {
    return {
      patientsId: this.$route.params.id,
      updateFileNameModal: false,
      selectedFileName: "",
      patient: null,
      files: [],
      isUploading: false,
      fileType: null,
      filetypes: [],
      uploadedFiles: [],
      selectedFile: null,
      time: null,
      newFile: {},
      date: null,
      tag: "",
      tags: [],
      selectedOperation: null,
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: ""
        },
        statusId: "",
        location: {
          name: ""
        },
        operation: {
          name: ""
        }
      },
      eventStatusModal: false,
      selectedEvent: null,
      appointmentDetails: false,
      statuses: [],
      created: false,
      sources: [],
      locations: [],
      operations: [],
      languages: [],
      patientLanguage: { fileTemplates : [] },
      fileTemplate: null,
      fileTitle: "",
      participants: [],
      isValid: {
        name: null,
        location: null,
        patient: null,
        operation: null,
        alocation: null,
        participants: null,
        date: null,
        time: null
      },
      storagepath: this.$storage,
      acceptedFiles: "image/*, .pdf",
      usedLanguage: "İngilizce"
    };
  },
  components: {
    Navigation,
    Topbar,
    Loading
  },
  created() {
    this.getFiles();

    this.getFileTypes();

    HTTP.get("/sources/").then(result => {
      this.sources = result.data;
    });

    HTTP.get("/locations/").then(result => {
      this.locations = result.data.filter(location => location.status == true);
      // console.log(result.data);
    });
    HTTP.get("/status/").then(result => {
      // console.log(result.data);
      this.statuses = result.data;
    });
    HTTP.get("/languages/").then(result => {
      this.languages = result.data;
    });

    HTTP.get("/operations/").then(result => {
      this.operations = result.data.filter(
        operation => operation.status == true && operation.isActivity == true
      );
      // console.log(result.data);
    });

    HTTP.get("/users/").then(result => {
      this.participants = result.data.filter(user => user.status == true);
      // console.log(result.data);
    });
  },
  mounted: function () {
    HTTP.get("/patients/" + this.patientsId).then(result => {
      this.patient = result.data[0];
      
      if(this.patient.language){

        this.patientLanguage = this.languages.find(z => z.id == this.patient.language.id);
        this.usedLanguage = this.patientLanguage.name;
        if(this.patientLanguage.fileTemplates.length == 0){
          this.patientLanguage = this.languages.find(z => z.code == "EN");
          this.usedLanguage = "İngilizce";
        }
      }else{
        this.patientLanguage = this.languages.find(z => z.code == "EN");
        this.usedLanguage = "İngilizce";
      }
    });
  },
  methods: {
    deleteFile: function(){
      this.$swal({
        title: "Emin misiniz?",
        text: "Seçtiğiniz dosya tamamen silinecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal"
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/files?ids=" + this.selectedFile)
            .then(() => {
              this.$swal({
                icon: 'success',
                text: 'İşlem Başarıyla tamamlandı.',
                showConfirmButton: false,
                timer: 1500
              });
              this.getFiles();
            })
            .catch(err => {
              console.log(err);
              this.$swal(
                "",
                "Bir Hata Oluştu!",
                "error"
              );
            });
        }
      });
    },
    editFile: function(){
      let file = this.files.filter(file => file.id == this.selectedFile)[0];
      console.log("file", file);
      this.selectedFileName = file.subject ? file.subject : file.name;
      this.updateFileNameModal=true;
    },
    updateFileName: function(){
      if(this.selectedFileName != ""){
        let data = {
          subject : this.selectedFileName
        }
        HTTP.put('/files/'+ this.selectedFile, data).then(() => {
          this.updateFileNameModal=false;
          this.getFiles();
        });
      }
    },
    addFileTemplate: function(){
      let data = {
        file: this.fileTemplate,
        title: this.fileTitle,
        patientId: this.patient.id
        };
        this.isUploading = true;
      HTTP.post("/files/fileTemplate/", data).then(() => {
        this.isUploading = false;
        this.$swal({
              icon: 'success',
              text: 'İşlem Başarıyla tamamlandı.',
              showConfirmButton: false,
              timer: 1500
            });
        this.getFiles();
      }).catch((e) => {
        this.isUploading = false;
        this.$swal({
              icon: 'error',
              text: 'İşlem Sırasında bir hata olmuş.',
              showConfirmButton: false,
              timer: 1500
            });
        this.getFiles();
      });
    },
    generateLink(path){
      return path.startsWith('http') ? path : this.storagepath + path;
    },
    getFiles: async function() {
      await HTTP.get("files/" + this.patientsId).then(result => {
        // console.log(result.data);
        this.files = [];
        this.files = result.data;
      });

    },
    getFileTypes: function() {
      HTTP.get("filetypes").then(result => {
        // console.log(result.data);
        this.filetypes = [];
        this.filetypes = result.data;
      });
    },
    eventDetails: function(id) {
      this.selectedEvent = this.files.filter(item => item.id == id)[0];
      this.eventStatusModal = true;
    },
    addFile: function() {
      let fd = new FormData();
      this.uploadedFiles.forEach(file => {
        fd.append("files", file.file, file.name);
      });
      fd.append("subject", this.newFile.subject);
      fd.append("filetypeId", this.fileType.id);
      fd.append("patientId", this.patientsId);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data"
        }
      }).then(() => {
        this.uploadedFiles = [];
        this.newFile.subject = "";
        this.filetype = null;
        if(this.fileType.id == 2)
        setTimeout(()=>{
          this.getFiles();
        }, 7000);
        else
        this.getFiles();
      });
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    sendFile: function() {
      let file = this.files.filter(file => file.id == this.selectedFile)[0];

      router.push({
        name: this.$route.path.includes("potantials")
          ? "potantial.patients.mails"
          : "patients.mails",
        params: {
          id: this.patientsId
        },
        query: {
          oid: this.selectedFile,
          name: file.name
        }
      });
    }
  },
  watch: {
    fileTemplate: function(fileTemplate){
      this.fileTitle = fileTemplate.name;
    },
    fileType: function(fileType){
        this.newFile.subject = fileType.name;

      if(fileType.id == 2)
        this.acceptedFiles = ".doc, .docx";
      else
        this.acceptedFiles =  "image/*, .pdf";
    },
    selectedOperation: function() {
      this.activity.operationId = this.selectedOperation.id;
    },
    time: function() {
      this.activity.time = this.time.HH + ":" + this.time.mm;
      // console.log(this.appointment.time);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
.participants {
  display: block;
  span {
    display: inline-block;
  }
}
.addButton {
  float: right;
}
.table {
  width: 100%;
  margin-top: 0px;
}
.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}
.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}
.appointments {
  .md-table-row {
    cursor: pointer;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.pad {
  padding-right: 10px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;
    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}
.vue-tags-input {
  max-width: none;
  margin-bottom: 20px;
}
.ti-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.files {
  list-style: none;
  font-size: 12px;
  margin-bottom: 5px;
  a {
    color: inherit !important;
    text-decoration: none !important;
  }
  a i {
    font-size: 15px !important;
  }
}
.addNewFileType {
  text-align: center;
  padding: 10px;
  button {
    display: inline-block;
    margin: auto;
  }
}
.addFileTemplateButton{
  margin-top: 40px !important;
    margin-left: 20px !important;
}
</style>
