<template>
  <div>
    <div class="md-layout potantialslist wrapper">
      <div id="desktop-top" v-if="innerWidth > 769">
        <div class="md-layout-item md-size-100 quick-action ">
          <button
            class="little-btn md-xsmall-size-50"
            @click="selectedTimeClick('all')"
            :class="{ active: time.type == 'all' }"
          >
            Tüm Zamanlar
          </button>
          <button
            class="little-btn"
            @click="selectedTimeClick('today')"
            :class="{ active: time.type == 'today' }"
          >
            Bugün
          </button>
          <button
            class="little-btn"
            @click="selectedTimeClick('thisWeek')"
            :class="{ active: time.type == 'thisWeek' }"
          >
            Bu Hafta
          </button>
          <button
            class="little-btn"
            @click="selectedTimeClick('thisMonth')"
            :class="{ active: time.type == 'thisMonth' }"
          >
            Bu Ay
          </button>
          <button
            class="little-btn"
            @click="selectedTimeClick('nextMonth')"
            :class="{ active: time.type == 'nextMonth' }"
          >
            Gelecek Ay
          </button>
          <button
            class="little-btn md-xsmall-size-50"
            @click="selectedTimeClick('prevMonth')"
            :class="{ active: time.type == 'prevMonth' }"
          >
            Önceki Ay
          </button>
          <button
            class="little-btn"
            @click="time.type = 'special'"
            :class="{ active: time.type == 'special' }"
          >
            Özel
          </button>
          <button
            class="little-btn md-xsmall-hide"
            @click="selectedTimeClick('prevDay')"
            :class="{ active: time.type == 'prevDay' }"
          >
            <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
          </button>
          <button
            class="little-btn md-xsmall-hide"
            @click="selectedTimeClick('nextDay')"
            :class="{ active: time.type == 'nextDay' }"
          >
            Sonraki Gün
            <md-icon>keyboard_arrow_right</md-icon>
          </button>
        </div>

        <div
          v-if="getDateFormat(time.startDate)"
          class="md-layout-item md-size-100 quick-action"
        >
          <span v-if="getDateFormat(time.startDate)"
            >Başlangıç Tarihi : <b>{{ getDateFormat(time.startDate, true) }}</b>
          </span>

          <span
            v-if="
              getDateFormat(time.startDate) &&
                !(
                  time.type == 'today' ||
                  time.type == 'nextDay' ||
                  time.type == 'prevDay'
                ) &&
                time.endDate
            "
          >
            <span style="margin:10px">|</span>Bitiş Tarihi :
            <b>{{ getDateFormat(time.endDate, true) }}</b></span
          >
        </div>

        <div
          style="width:100%;display: flex;flex-wrap: wrap;margin-top: 20px;"
          v-if="time.type == 'special'"
        >
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-datepicker v-model="inputStartDate" md-immediately>
              <label>Başlangıç Tarihi</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-datepicker
              :md-disabled-dates="endDateDisabledDates"
              v-model="inputEndDate"
              md-immediately
            >
              <label>Bitiş Tarihi</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-size-10 mt-3 md-xsmall-size-100">
            <md-button
              :disabled="inputStartDate && inputEndDate ? false : true"
              :class="
                inputStartDate && inputEndDate
                  ? 'md-raised md-primary btn btn-mg m-5'
                  : 'md-raised md-disabled btn btn-mg m-5'
              "
              @click="selectedTimeClick('special')"
              >Ara</md-button
            >
          </div>
        </div>

        <!-- Filter Start -->
        <div v-if="!selectingAgenciesChild" class="filters-container">
          <NewInput>
            <v-select
              @click.native="clickFilterInput('users')"
              v-model="filters.users"
              placeholder="Temsilci"
              :options="options.users"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.users, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('branches')"
              v-model="filters.branches"
              placeholder="Branş"
              :options="options.branches"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.branches, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('operations')"
              v-model="filters.operations"
              placeholder="Tedavi"
              :options="getOperationsData()"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.operations, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('countries')"
              v-model="filters.countries"
              placeholder="Ülke"
              :options="options.countries"
              label="name_tr"
              class="md-menu"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name_tr }}
                <span class="optionSelected">{{
                  checkIfSelectedCountry(filters.countries, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <!-- Agencie Field -->
          <NewInput>
            <v-select
              @click.native="clickFilterInput('agencies')"
              v-model="filters.agencies"
              placeholder="Kaynak"
              :options="childDontShow(options.agencies)"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name
                }}<span class="optionSelected">{{
                  checkIfSelected(filters.agencies, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('patientStatus')"
              v-model="filters.patientStatus"
              placeholder="Durum"
              :options="options.patientStatus"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.patientStatus, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('languages')"
              v-model="filters.languages"
              placeholder="Dil"
              :options="options.languages"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.languages, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="filters.sharing"
              placeholder="Paylaşım"
              :options="options.sharing"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.sharing, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <!-- <NewInput>
          <input
            placeholder="Advertising ID"
            style="border : 0px solid ; outline: 0px solid;padding-left : 10px;padding-right: 10px;"
            v-model="queries.adId"
          />
        </NewInput> -->
        </div>
        <!-- Filter End -->

        <!-- Filters With Child Agencies Start -->
        <div v-if="selectingAgenciesChild">
          <div class="filters-container">
            <NewInput>
              <v-select
                @click.native="clickFilterInput('users')"
                v-model="filters.users"
                placeholder="Temsilci"
                :options="options.users"
                label="name"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.users, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                @click.native="clickFilterInput('branches')"
                v-model="filters.branches"
                placeholder="Branş"
                :options="options.branches"
                label="name"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.branches, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                @click.native="clickFilterInput('operations')"
                v-model="filters.operations"
                placeholder="Tedavi"
                :options="getOperationsData()"
                label="name"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.operations, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                @click.native="clickFilterInput('countries')"
                v-model="filters.countries"
                placeholder="Ülke"
                :options="options.countries"
                label="name_tr"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name_tr }}
                  <span class="optionSelected">{{
                    checkIfSelectedCountry(filters.countries, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                @click.native="clickFilterInput('patientStatus')"
                v-model="filters.patientStatus"
                placeholder="Durum"
                :options="options.patientStatus"
                label="name"
                class="md-menu"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.patientStatus, option)
                  }}</span></template
                >
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                @click.native="clickFilterInput('languages')"
                v-model="filters.languages"
                placeholder="Dil"
                :options="options.languages"
                label="name"
                class="md-menu"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.languages, option)
                  }}</span></template
                >
              </v-select>
            </NewInput>

            <NewInput>
              <v-select
                v-model="filters.sharing"
                placeholder="Paylaşım"
                :options="options.sharing"
                label="name"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(filters.sharing, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>

            <!-- <NewInput>
          <input
            placeholder="Advertising ID"
            style="border : 0px solid ; outline: 0px solid;padding-left : 10px;padding-right: 10px;"
            v-model="queries.adId"
          />
        </NewInput> -->
          </div>

          <!-- Divider -->
          <div style="width:100%;display:flex;align-items: center;">
            <div
              style="width:100%; height: 1px; background-color: gray;margin-bottom: 20px;margin-top: 15px;opacity:0.4;color : gray"
            ></div>
          </div>

          <!-- Agencie & Sub Agencie Section -->
          <div style="display:flex;flex-wrap: wrap;">
            <!-- Agencie Field -->
            <NewInput>
              <v-select
                @click.native="clickFilterInput('agencies')"
                v-model="filters.agencies"
                placeholder="Kaynak"
                :options="childDontShow(options.agencies)"
                label="name"
                class="md-menu"
                :disabled="isDisabledWatcher"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name
                  }}<span class="optionSelected">{{
                    checkIfSelected(filters.agencies, option)
                  }}</span></template
                >
              </v-select>
            </NewInput>

            <div :key="index" v-for="(item, index) in filters.agencies">
              <NewInput width="250" v-if="item.childAgencies.length > 0">
                <v-select
                  v-model="filters.agencieChild[index]"
                  :placeholder="item.name + ' Alt Kaynak'"
                  :options="childIsDeletedControl(item.childAgencies)"
                  label="name"
                  class="md-menu"
                  multiple
                >
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </NewInput>
            </div>
          </div>
        </div>
        <!-- Filters With Child Agencies End -->

        <div
          style="width: 100%;display:flex;align-items: center;flex-wrap: wrap;justify-content: space-between;padding-right: 20px;"
        >
          <div
            style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;margin-bottom: 20px;"
          >
            <div class="md-layout-item md-size-50 md-xsmall-size-80">
              <md-field style="margin-right: 20px;">
                <label>Telefon No</label>
                <md-input
                  type="tel"
                  v-model="queries.phone"
                  @keypress="isNumber($event)"
                  @input="handlePhoneInput"
                />
                <md-icon>search</md-icon>
              </md-field>
            </div>

            <div class="md-layout-item md-size-45 md-xsmall-size-80">
              <md-checkbox
                style="margin-left: 10px !important"
                v-model="filters.type"
                >Tüm Hastalarda Ara</md-checkbox
              >
            </div>

            <md-button
              :disabled="
                sameValueFilter.length > 1 || queries.phone || queries.adId
                  ? false
                  : true
              "
              style="border: 2px solid rgb(213, 213, 213); border-radius: 5px;"
              @click="clearFilters"
            >
              Temizle
            </md-button>
          </div>

          <!-- Excel -->
          <md-speed-dial>
            <div style="display: flex;margin-bottom: 20px;">
              <md-speed-dial-target v-if="roleId == 1 || roleId == 6" style="width:45px;height: 45px">
                <md-button class="md-icon-button" @click="downloadExcel" >
                  <md-icon>download</md-icon>
                </md-button>
              </md-speed-dial-target>
              <md-speed-dial-target
                v-if="data.length == 0"
                @click="patientAddPress"
                style="width: 45px; height: 45px;background: #68B984; margin :15;"
                class="md-success"
              >
                <md-button class="md-icon-button">
                  <md-icon>add</md-icon>
                </md-button>
              </md-speed-dial-target>
            </div>
          </md-speed-dial>
        </div>
      </div>

      <div style="width: 100%;" id="mobile-top" v-if="innerWidth < 769">
        <div
          style="width: 100%; height : 110px;display: flex; justify-content: space-between;align-items:center; "
        >
          <div style="margin-top: 0px;" class="quick-action">
            <button
              class="little-btn"
              @click="selectedTimeClick('today')"
              :class="{ active: time.type == 'today' }"
            >
              Bugün
            </button>

            <button
              class="little-btn"
              @click="selectedTimeClick('thisMonth')"
              :class="{ active: time.type == 'month' }"
            >
              Bu Ay
            </button>

            <button
              class="little-btn"
              @click="time.type = 'special'"
              :class="{ active: time.type == 'special' }"
            >
              Özel
            </button>

            <div>
              <button
                class="little-btn"
                @click="selectedTimeClick('prevDay')"
                :class="{ active: time.type == 'prevDay' }"
              >
                <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
              </button>

              <button
                class="little-btn"
                @click="selectedTimeClick('nextDay')"
                :class="{ active: time.type == 'nextDay' }"
              >
                Sonraki Gün
                <md-icon>keyboard_arrow_right</md-icon>
              </button>
            </div>
          </div>

          <button class="mobile-show-button" @click="filterShow = !filterShow">
            <md-icon>filter_list</md-icon>
            <span v-if="filterShow">Hide</span>
            <span v-else>Show</span>
          </button>
        </div>
        <div
          v-if="getDateFormat(time.startDate)"
          style="padding: 0 10px 0 10px; margin-top: 10px; margin-bottom: -5px; display: flex;flex-direction: column;"
        >
          <span v-if="getDateFormat(time.startDate)"
            >Başlangıç Tarihi : <b>{{ getDateFormat(time.startDate) }}</b>
          </span>
          <span
            v-if="
              getDateFormat(time.startDate) &&
                !(
                  time.type == 'today' ||
                  time.type == 'nextDay' ||
                  time.type == 'prevDay'
                ) &&
                time.endDate
            "
          >
            Bitiş Tarihi :
            <b>{{ getDateFormat(time.endDate) }}</b></span
          >
        </div>
        <div
          v-if="time.type == 'special'"
          style="width:100%;display: flex;align-items: center;flex-direction: column;margin-top: 10px;padding:0 10px 0 10px "
        >
          <md-datepicker v-model="inputStartDate" md-immediately>
            <label>Başlangıç Tarihi</label>
          </md-datepicker>

          <md-datepicker
            :md-disabled-dates="endDateDisabledDates"
            v-model="inputEndDate"
            md-immediately
          >
            <label>Bitiş Tarihi</label>
          </md-datepicker>

          <md-button
            @click="selectedTimeClick('special')"
            :disabled="inputStartDate && inputEndDate ? false : true"
            :class="
              inputStartDate && inputEndDate
                ? 'md-raised md-primary btn btn-mg m-5'
                : 'md-raised md-disabled btn btn-mg m-5'
            "
          >
            Ara
          </md-button>
        </div>
        <div v-if="filterShow" class="filters-container">
          <NewInput>
            <v-select
              @click.native="clickFilterInput('users')"
              v-model="filters.users"
              placeholder="Temsilci"
              :options="options.users"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.users, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('branches')"
              v-model="filters.branches"
              placeholder="Branş"
              :options="options.branches"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.branches, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="filters.sharing"
              placeholder="Paylaşım"
              :options="options.sharing"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.sharing, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('operations')"
              v-model="filters.operations"
              placeholder="Tedavi"
              :options="getOperationsData()"
              label="name"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.operations, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('countries')"
              v-model="filters.countries"
              placeholder="Ülke"
              :options="options.countries"
              label="name_tr"
              class="md-menu"
              :close-on-select="false"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name_tr }}
                <span class="optionSelected">{{
                  checkIfSelectedCountry(filters.countries, option)
                }}</span>
              </template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('agencies')"
              v-model="filters.agencies"
              placeholder="Kaynak"
              :options="options.agencies"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name
                }}<span class="optionSelected">{{
                  checkIfSelected(filters.agencies, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('patientStatus')"
              v-model="filters.patientStatus"
              placeholder="Durum"
              :options="options.patientStatus"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.patientStatus, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              @click.native="clickFilterInput('languages')"
              v-model="filters.languages"
              placeholder="Dil"
              :options="options.languages"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option"
                >{{ option.name }}
                <span class="optionSelected">{{
                  checkIfSelected(filters.languages, option)
                }}</span></template
              >
            </v-select>
          </NewInput>

          <!-- <NewInput>
          <input
            placeholder="Advertising ID"
            style="border : 0px solid ; outline: 0px solid;padding-left : 10px;padding-right: 10px;"
            v-model="queries.adId"
          />
        </NewInput> -->
        </div>
        <div style="padding:0 10px 0 10px ">
          <md-field style="margin-right: 20px;">
            <label>Telefon No</label>
            <md-input
              type="tel"
              v-model="queries.phone"
              @keypress="isNumber($event)"
              @input="handlePhoneInput"
            />
            <md-icon>search</md-icon>
          </md-field>
        </div>
        <div
          style="display: flex;justify-content: space-between; padding:0 10px 0 10px;align-items: center;"
        >
          <md-checkbox style="margin-top: 4px;" v-model="filters.type"
            >Tüm Hastalarda Ara</md-checkbox
          >
          <div style="margin-top: -15px;">
            <md-button
              :disabled="
                sameValueFilter.length > 1 || queries.phone || queries.adId
                  ? false
                  : true
              "
              style="border: 2px solid rgb(213, 213, 213); border-radius: 5px;"
              @click="clearFilters"
            >
              Temizle
            </md-button>
          </div>
        </div>
        <div
          v-if="data.length == 0"
          style="display: flex;justify-content: flex-end;margin-top: 5px;margin-bottom: 5px;"
        >
          <md-speed-dial>
            <md-speed-dial-target v-if="roleId == 1 || roleId == 6" style="width:45px;height: 45px">
                <md-button class="md-icon-button" @click="downloadExcel" >
                  <md-icon>download</md-icon>
                </md-button>
              </md-speed-dial-target>
            <md-speed-dial-target
              @click="patientAddPress"
              style="width: 45px; height: 45px;background: #68B984;"
              class="md-success"
            >
              <md-button class="md-icon-button">
                <md-icon>add</md-icon>
              </md-button>
            </md-speed-dial-target>
          </md-speed-dial>
        </div>
      </div>

      <div v-if="loading" style="height:840px">
        <SkeletonTableLoading v-if="true" />
      </div>

      <div class="md-layout-item md-size-100 hidden-mini">
        <span style="float: right; margin-right: 30px; font-weight: bold"
          >Toplam hasta sayısı: {{ dataCount ? dataCount : "0" }}</span
        >
      </div>

      <div
        style="width: 100%;display: flex;justify-content: flex-end;margin-bottom: 20px;flex-wrap: wrap;"
        v-if="selectedRows.length > 0"
      >
        <!-- <div style="margin-left: 10px">
          <md-button
            style="width: auto !important"
            class="md-raised md-primary"
            @click="selectedRowsWillBeCalledAgain"
            ><md-icon>call</md-icon> Tekrar Aranacak</md-button
          >
        </div> -->
        <div v-if="roleId == 1" style="margin-left: 10px">
          <md-button
            @click="selectedRowsShared"
            style="width: auto !important"
            class="md-raised md-primary"
            ><md-icon>share</md-icon>Paylaş</md-button
          >
        </div>
        <div style="margin-left: 10px">
          <md-button
            @click="selectedRowsRedirect"
            style="width: auto !important"
            class="md-raised md-primary"
            ><md-icon>mobile_screen_share</md-icon> Yönlendir</md-button
          >
        </div>
        <div style="margin-left: 10px">
          <md-button class="md-raised md-accent" @click="selectedRowsDelete"
            ><md-icon>delete</md-icon> Sil</md-button
          >
        </div>
      </div>

      <md-table
        @md-selected="selectedRow"
        v-if="!loading"
        class="table appointments"
        v-model="data"
      >
        <md-table-row
          slot="md-table-row"
          class="trow"
          :md-selectable="roleId == 6 ? null : 'multiple'"
          slot-scope="{ item }"
          :style="{
            color: getMainTableRowColors(item).color,
            backgroundColor: getMainTableRowColors(item).background,
          }"
        >
          <md-table-cell class="cell-sm">
            <md-button
              @click="pressRepeatsDatasButton(item)"
              class="md-icon-button md-raised md-dense"
              v-if="isRepeating('phones', item.phone)"
            >
              <md-progress-spinner
                v-if="repeatsDataLoading"
                class="md-accent"
                :md-diameter="30"
                md-mode="indeterminate"
              ></md-progress-spinner>
              <md-icon v-else>groups</md-icon>
              <md-tooltip md-direction="top">Tekrarlanan kayıtlar</md-tooltip>
            </md-button>
            <md-button
              v-if="!(roleId == 6)"
              @click="navigatePotantialDetail(item)"
              class="md-icon-button md-raised md-dense"
              ><md-icon>account_circle</md-icon>
              <md-tooltip md-direction="top">Hastaya git</md-tooltip>
            </md-button>
            <md-button
              v-if="!(roleId == 6)"
              @click="
                lastClickedItem.data = item;
                modals.patientInformationModalVisible = true;
              "
              style="background: #ff4d3c;"
              class="md-icon-button md-raised md-dense"
              ><md-icon style="color : white">info</md-icon>
              <md-tooltip md-direction="top">Hastaya Dönüştür</md-tooltip>
            </md-button>

            <div v-if="isRepeating('phones', item.phone, 'type')">
              <md-icon
                :style="{
                  color: item.type ? '#79AC78 !important' : 'red !important',
                }"
                class="duplicateOfReal"
                >r_mobiledata</md-icon
              >
              <md-tooltip md-direction="top">Randevulu Hasta</md-tooltip>
            </div>
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            v-if="filters.type"
            md-label="Hasta Tipi"
          >
            {{ item.type == 1 ? "Gerçek" : "Aday Hasta" }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Telefon"
          >
            {{
              isRepeating("phoneNumberAtDifferentAgents", item.phone)
                ? `x ${getPhone(item.phone)} x`
                : getPhone(item.phone)
            }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Hasta Adı"
          >
            {{ characterLimit(item.name) }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Durum"
          >
            {{ getProperty(item.patientStatus, "name") }}

            <md-icon style="scale : 0.8" v-if="isReminderOnLastNote(item)"
              >notifications</md-icon
            >
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Temsilci"
          >
            {{ getProperty(item.user, "name") }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Tedavi"
          >
            {{ characterLimit(getProperty(item.operation, "name")) }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Ülke"
          >
            {{ getProperty(item.country, "name_tr") }}
          </md-table-cell>

          <md-table-cell @click.native="clickMainTableRow(item)" md-label="Dil">
            {{ getProperty(item.language, "name") }}
          </md-table-cell>

          <md-table-cell
            v-on:click.native="fastEditPatient(item)"
            md-label="İletişim"
          >
            {{ getProperty(item.sourceModel, "name") }}
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Kaynak"
          >
            <md-tooltip style="font-size: 11px">{{
              item.source
                ? item.source
                : item.agencieId && item.agencie
                ? item.agencie.name
                : ""
            }}</md-tooltip>
            {{ characterLimit(getPropertyForAgencie(item.agencie, "name")) }}
          </md-table-cell>

          <md-table-cell @click.native="clickMainTableRow(item)" md-label="Not">
            <div
              v-if="getLastValueOfArrayWithProperty(item.notes, 'description')"
            >
              <md-icon>library_books</md-icon>
              <md-tooltip style="font-size: 13px">
                {{
                  getLastValueOfArrayWithProperty(item.notes, "description")
                }}</md-tooltip
              >
            </div>
          </md-table-cell>

          <md-table-cell
            @click.native="clickMainTableRow(item)"
            md-label="Kayıt Tarihi"
          >
            {{ getDateWithHour(item.createdAt) }}
          </md-table-cell>

          <md-table-cell v-if="!(roleId == 6)" class="pd-0">
            <div class="hover">
              <md-button
                @click="
                  lastClickedItem.data = item;
                  modals.directPatientModalVisible = true;
                "
                class="md-icon-button md-raised md-dense"
                ><md-icon>mobile_screen_share</md-icon>
                <md-tooltip md-direction="top">Yönlendir</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div class="md-layout-item md-size-100">
        <MazPagination
          v-model="page"
          :page-count="numberOfPages"
          :dark="false"
          @page="paginationChanged"
        />
      </div>
    </div>

    <md-dialog
      :md-active.sync="modals.repeatsDataModalVisible"
      class="dialog allowOverflow"
    >
      <md-content class="statuses p-2">
        <h3>Tekrarlanan Kayıtlar</h3>
        <div style="max-height: 500px; margin-top: 25px;overflow: scroll;">
          <md-table
            v-if="!repeatsDataLoading"
            class="table appointments"
            v-model="repeatsData"
          >
            <md-table-row
              slot="md-table-row"
              class="trow"
              slot-scope="{ item }"
              :style="{
                color: getMainTableRowColors(item).color,
                backgroundColor: getMainTableRowColors(item).background,
              }"
            >
              <md-table-cell
                v-if="isRepeating('phones', item.phone, 'type')"
                class="cell-sm"
              >
                <md-icon
                  :style="{
                    color: item.type ? '#79AC78 !important' : 'red !important',
                  }"
                  class="duplicateOfReal"
                  >r_mobiledata</md-icon
                >
                <md-tooltip md-direction="top">Randevulu Hasta</md-tooltip>
              </md-table-cell>

              <md-table-cell v-if="filters.type" md-label="Hasta Tipi">
                {{ item.type == 1 ? "Gerçek" : "Aday Hasta" }}
              </md-table-cell>

              <md-table-cell md-label="Hasta Adı">
                {{ characterLimit(item.name) }}
              </md-table-cell>

              <md-table-cell md-label="Tedavi">
                {{ characterLimit(getProperty(item.operation, "name")) }}
              </md-table-cell>

              <md-table-cell md-label="Telefon">
                {{
                  isRepeating("phoneNumberAtDifferentAgents", item.phone)
                    ? `x ${getPhone(item.phone)} x`
                    : getPhone(item.phone)
                }}
              </md-table-cell>

              <md-table-cell md-label="Ülke">
                {{ getProperty(item.country, "name_tr") }}
              </md-table-cell>

              <md-table-cell md-label="Dil">
                {{ getProperty(item.language, "name") }}
              </md-table-cell>

              <md-table-cell md-label="Firma">
                {{ getProperty(item.innerFirm, "name") }}
              </md-table-cell>

              <md-table-cell md-label="İletişim">
                {{ getProperty(item.sourceModel, "name") }}
              </md-table-cell>

              <md-table-cell md-label="Temsilci">
                {{ getProperty(item.user, "name") }}
              </md-table-cell>

              <md-table-cell md-label="Durum">
                {{ getProperty(item.patientStatus, "name") }}
              </md-table-cell>

              <md-table-cell md-label="Kaynak">
                {{ characterLimit(getProperty(item.agencie, "name")) }}
              </md-table-cell>

              <md-table-cell md-label="Not">
                <div
                  v-if="
                    getLastValueOfArrayWithProperty(item.notes, 'description')
                  "
                >
                  <md-icon>library_books</md-icon>
                  <md-tooltip style="font-size: 13px">
                    {{
                      getLastValueOfArrayWithProperty(item.notes, "description")
                    }}</md-tooltip
                  >
                </div>
              </md-table-cell>

              <md-table-cell md-label="Kayıt Tarihi">
                {{ getDateWithHour(item.createdAt) }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="modals.statusWithNoteUpdateModalVisible"
      class="dialog allowOverflow"
    >
      <md-content style="width: 100%" class="md-scrollbar">
        <div style="overflow-x: hidden">
          <h1
            v-if="lastClickedItem.data.name"
            class="editedPatientModalTopPatientName"
          >
            {{ lastClickedItem.data.name }}
            <span v-if="lastClickedItem.data.country">
              {{
                lastClickedItem.data.country.name_tr
                  ? " - " + lastClickedItem.data.country.name_tr
                  : lastClickedItem.data.country.name
                  ? " - " + lastClickedItem.data.country.name
                  : ""
              }}
            </span>
          </h1>
          <h3>Durum Hareketleri</h3>
          <div class="md-layout">
            <div
              style="width: 900px"
              class="md-layout-item md-size-500 notesContainer"
            >
              <notes
                :patientId="lastClickedItem.data.id"
                @newPatientStatus="newPatientStatus"
                @newPatientNotes="newPatientNotes"
                :patientStatuses="patientStatuses"
              />
            </div>
          </div>
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="modals.directPatientModalVisible"
      class="dialog allowOverflow"
      style="overflow: none !important"
    >
      <md-content class="statuses">
        <h3>Hastayı Yönlendir</h3>
        <NewInput style="margin  : 20px 0px 0px 0px">
          <v-select
            @click.native="clickFilterInput('users')"
            v-model="directPatientModalSelectedDirectedUser"
            placeholder="Kullanıcılar"
            :options="options.directedUsers"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>
        <md-checkbox v-model="directPatientModalWillCallAgainCheck"
          >Tekrar Aranacak</md-checkbox
        >
        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="modals.directPatientModalVisible = false"
            >İptal</md-button
          >
          <md-button
            :disabled="directPatientModalSelectedDirectedUser ? false : true"
            :class="
              directPatientModalSelectedDirectedUser
                ? 'md-raised md-primary'
                : 'md-raised md-disabled'
            "
            v-on:click="directPatientPress"
            >Yönlendir</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <!-- Update Form -->
    <md-dialog
      :md-active.sync="modals.patientInformationModalVisible"
      class="dialog allowOverflow"
    >
      <md-content style="overflow-x: hidden" class="statuses">
        <h3>Hasta Bilgileri</h3>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Hasta Adı</span>
              <md-input v-model="lastClickedItem.data.name"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Tedavi</span>
              <v-select
                v-model="lastClickedItem.data.operation"
                placeholder="Tedavi"
                :options="getOperationsData()"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Ülke</span>
              <v-select
                v-model="lastClickedItem.data.country"
                placeholder="Ülke"
                :options="options.countries"
                label="name_tr"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Telefon</span>
              <md-input
                readonly
                v-model="lastClickedItem.data.phone"
              ></md-input>
            </md-field>
          </div>
          <!-- Agencie Start -->
          <!-- Main Agencie -->
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Ana Kaynak</span>
              <v-select
                v-model="lastClickedItem.updateParentAgencieField"
                placeholder="Kaynak"
                :options="childDontShow(options.agencies)"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <!-- Child Agencie -->
          <div
            v-if="lastClickedItem.updateSelectingAgenciesChild"
            class="md-layout-item md-size-50 md-xsmall-size-100"
          >
            <md-field>
              <span class="list-title">Alt Kaynak</span>
              <v-select
                v-model="lastClickedItem.updateChildAgencieField"
                placeholder="Child Agencie"
                :options="lastClickedItem.updateChildAgencies"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
              <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
            </md-field>
          </div>
          <!-- Agencie End -->
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Dil</span>
              <v-select
                v-model="lastClickedItem.data.language"
                placeholder="Dil"
                :options="options.languages"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">E-Mail</span>
              <md-input
                placeholder="E-Mail adresi"
                v-model="lastClickedItem.data.email"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">İletişim</span>
              <v-select
                :clearable="false"
                v-model="lastClickedItem.data.sourceModel"
                placeholder="İletişim"
                :options="options.sources"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div
            class="md-layout text-right"
            style="margin-top: 20px; margin-right: 15px"
          ></div>
        </div>
        <div
          style="display: flex; align-items: center;justify-content: flex-end;width: 100%;"
        >
          <md-button
            :disabled="lastClickedItem.data.sourceModel ? false : true"
            @click="patientInformationModalSaveButtonClick"
            class="md-raised button-margins md-primary"
            >KAYDET</md-button
          >
          <md-button
            :disabled="
              lastClickedItem.data.operation && lastClickedItem.data.languageId
                ? false
                : true
            "
            class="md-raised button-margins md-accent"
            v-on:click="patientInformationModalConvertPatientButtonClick()"
          >
            <md-icon>info</md-icon>
            <span v-if="!lastClickedItem.data.patientCode">
              Hastaya Dönüştür
            </span>
          </md-button>
        </div>
      </md-content>
    </md-dialog>
    <!-- Update Form End -->

    <md-dialog
      :md-active.sync="modals.directSelectedRowsPatientsModalVisible"
      class="dialog allowOverflow"
      style="overflow: none !important"
    >
      <md-content class="statuses">
        <h3>Hastaları Yönlendir</h3>
        <NewInput style="margin  : 20px 0px 0px 0px">
          <v-select
            @click.native="clickFilterInput('users')"
            v-model="directSelectedRowsPatientsModalSelectedDirectedUser"
            placeholder="Kullanıcılar"
            :options="options.directedUsers"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>
        <md-checkbox v-model="directSelectedRowsPatientsModalWillCallAgainCheck"
          >Tekrar Aranacak</md-checkbox
        >

        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="modals.directSelectedRowsPatientsModalVisible = false"
            >İptal</md-button
          >
          <md-button
            :disabled="
              directSelectedRowsPatientsModalSelectedDirectedUser ? false : true
            "
            :class="
              directSelectedRowsPatientsModalSelectedDirectedUser
                ? 'md-raised md-primary'
                : 'md-raised md-disabled'
            "
            v-on:click="directSelectedRowsPatientsPress"
            >Hepsini Yönlendir</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="modals.sharedSelectedRowsPatientsSharedModalVisible"
      class="dialog allowOverflow"
      style="overflow: none !important"
    >
      <md-content class="statuses">
        <h3>Hastaları Paylaş</h3>

        <NewInput :width="100" style="margin  : 20px 0px 0px 0px">
          <v-select
            @click.native="clickFilterInput('users')"
            v-model="sharedSelectedRowsPatientsSharedModalSelectedSharedUser"
            placeholder="Kullanıcılar"
            :options="options.directedUsers"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput :width="100" style="margin  : 20px 0px 0px 0px">
          <v-select
            @click.native="clickFilterInput('patientStatus')"
            v-model="sharedSelectedRowsPatientsSharedModalSelectedStatus"
            placeholder="Durum"
            :options="options.patientStatus"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="
              modals.sharedSelectedRowsPatientsSharedModalVisible = false
            "
            >İptal</md-button
          >
          <md-button
            :disabled="
              sharedSelectedRowsPatientsSharedModalSelectedSharedUser &&
              sharedSelectedRowsPatientsSharedModalSelectedStatus
                ? false
                : true
            "
            :class="
              sharedSelectedRowsPatientsSharedModalSelectedSharedUser &&
              sharedSelectedRowsPatientsSharedModalSelectedStatus
                ? 'md-raised md-primary'
                : 'md-raised md-disabled'
            "
            v-on:click="sharedSelectedRowsPatientsPress"
            >Hepsini Paylaş</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <ConvertModal
      :showBecomePatientModal="modals.convertPatientModalVisible"
      :patient="lastClickedItem.data"
      @closeModal="closeConvertPatientModal"
    ></ConvertModal>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { MazPagination } from "maz-ui";
import moment from "moment";

import SkeletonTableLoading from "@/components/SkeletonTableLoading.vue";
import NewInput from "@/components/newInput";
import notes from "@/components/patients/notes";
import ConvertModal from "@/components/patients/potantials/convertModal.vue";
// import JsonExcel from "vue-json-excel";
import * as XLSX from "xlsx";

export default {
  name: "Pool",
  components: {
    MazPagination,
    SkeletonTableLoading,
    NewInput,
    notes,
    ConvertModal,
    // JsonExcel,
  },
  data() {
    return {
      isDisabledWatcher: false,
      loading: false,
      limit: 30,
      page: 1,
      numberOfPages: 0,
      dataCount: null,
      data: [],
      sameValueFilter: [],
      options: {
        users: [],
        operations: [],
        countries: [],
        agencies: [],
        patientStatus: [],
        languages: [],
        sources: [],
        branches: [],
        directedUsers: [],
        sharing: [
          {
            id: 0,
            name: "Paylaşılanlar",
            value: true,
          },
          {
            id: 1,
            name: "Paylaşılmayanlar",
            value: false,
          },
        ],
      },
      filters: {
        users: null,
        operations: null,
        countries: null,
        agencies: [],
        branches: null,
        patientStatus: null,
        agencieChild: [],
        languages: null,
        startDate: null,
        endDate: null,
        type: null,
        sharing: null,
      },
      queries: {
        phone: null,
        adId: null,
      },
      time: {
        type: "today",
        startDate: moment().format("DD/MM/YYYY"),
        endDate: moment().format("DD/MM/YYYY"),
        formatted: {
          startDate: moment().format("DD/MM/YYYY"),
          endDate: moment().format("DD/MM/YYYY"),
        },
      },
      inputStartDate: null,
      inputEndDate: null,
      repeats: {
        phones: [],
        phoneNumberAtDifferentAgents: [],
      },
      modals: {
        statusWithNoteUpdateModalVisible: false,
        repeatsDataModalVisible: false,
        directPatientModalVisible: false,
        patientInformationModalVisible: false,
        convertPatientModalVisible: false,
        directSelectedRowsPatientsModalVisible: false,
        sharedSelectedRowsPatientsSharedModalVisible: false,
      },
      selectingAgenciesChild: false,
      directPatientModalSelectedDirectedUser: null,
      sharedSelectedRowsPatientsSharedModalSelectedSharedUser: null,
      directPatientModalWillCallAgainCheck: false,
      repeatsData: [],
      repeatsDataLoading: false,
      lastClickedItem: {
        data: {},
        index: null,
        updateParentAgencieField: null,
        updateParentAgencies: [],
        updateChildAgencieField: null,
        updateChildAgencies: [],
        updateSelectingAgenciesChild: false,
      },
      convertToPatientRequiredField: false,
      selectedRows: [],
      directSelectedRowsPatientsModalSelectedDirectedUser: null,
      sharedSelectedRowsPatientsSharedModalSelectedStatus: null,
      directSelectedRowsPatientsModalWillCallAgainCheck: false,
      filterShow: false,
      roleId: parseInt(localStorage.getItem("roleId")),
      // exportFields: {
      //   "Hasta Adı": "name",
      //   Tedavi: {
      //     field: "operation",
      //     callback: (value) => {
      //       return value?.name;
      //     },
      //   },
      //   Ülke: {
      //     field: "country",
      //     callback: (value) => {
      //       return value?.name_tr;
      //     },
      //   },
      //   Telefon: "phone",
      //   Kaynak: {
      //     field: "agencie",
      //     callback: (value) => {
      //       return value?.name;
      //     },
      //   },
      //   Kişi: {
      //     field: "user",
      //     callback: (value) => {
      //       return value?.name;
      //     },
      //   },
      //   Durum: {
      //     field: "patientStatus",
      //     callback: (value) => {
      //       return value?.name;
      //     },
      //   },
      //   "Kayıt Tarihi": {
      //     field: "createdAt",
      //     callback: (value) => {
      //       return value ? this.dateString(value) : "";
      //     },
      //   },
      //   "Son Güncelleme Tarihi": {
      //     field: "updatedAt",
      //     callback: (value) => {
      //       return value ? this.dateString(value) : "";
      //     },
      //   },
      // },
      debouncePhoneInputTimeout: null,
      patientStatuses : []
    };
  },
  async created() {
    this.time.startDate = moment()
      .startOf("day")
      .toDate();

    this.time.endDate = moment()
      .endOf("day")
      .toDate();

    moment.locale("tr");
    await this.getPatientStatuses()
    await this.getPool();
    await this.getRepeats();
    await this.getAgencies();
    await this.getLanguages();
    await this.getCountries();
    await this.getOperations();
    await this.getSources();
    await this.getBranches();
    await this.getDirectedUsers();
  },
  methods: {
    async getPatientStatuses() {
      HTTP.get("/patientstatus/").then((result) => {
        this.patientStatuses = result.data.filter((z) => true);
        this.patientStatuses.reverse();
        this.patientStatuses.push({ id: 0, name: "Yok" });
        this.patientStatuses.reverse();
      });
    },
    async getPool() {
      this.loading = true;

      let cleanFilter = this.sameValueFilter?.filter(
        (a) => a.field === "agencieId"
      );
      this.sameValueFilter = cleanFilter;

      const filterDatas = [
        {
          filterVariable: "users",
          backendPropertyName: "userId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "countries",
          backendPropertyName: "countryCode",
          filterVariableValueKey: "code",
        },
        {
          filterVariable: "operations",
          backendPropertyName: "treatmentId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "patientStatus",
          backendPropertyName: "status",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "languages",
          backendPropertyName: "languageId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "branches",
          backendPropertyName: "branchId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "sharing",
          backendPropertyName: "isShared",
          filterVariableValueKey: "value",
        },
      ];

      for (let i in filterDatas) {
        if (this.filters[filterDatas[i]?.filterVariable]) {
          await this.addSameValueFilter(
            this.filters[filterDatas[i]?.filterVariable],
            filterDatas[i]?.backendPropertyName,
            filterDatas[i]?.filterVariableValueKey
          );
        }
      }

      if (!this.filters.type) {
        this.sameValueFilter.push({
          field: "type",
          value: 0,
        });
      }

      let offset = this.limit * (this.page - 1);

      // console.log("sameValueFilter", this.sameValueFilter)

      const poolRequest = await HTTP.post("/pool/getPool", {
        limit: this.limit,
        page: this.page,
        offset,
        sameValueFilter: this.sameValueFilter,
        queries: this.queries,
        time: this.time,
      });

      this.data = poolRequest.data.rows;
      this.dataCount = poolRequest.data.count;
      this.numberOfPages = poolRequest.data.numberOfPages;
      this.loading = false;
      this.time = poolRequest.data.time;
    },
    async downloadExcel() {
      // Sample data (replace this with data from your backend)

      const allData = await this.getPoolExportNew()
      const data =
        allData?.length > 0
          ? this.transformDataForExcel(allData)
          : [{ Header: "Empty" }];

      // Convert the data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Save the workbook
      XLSX.writeFile(wb, "Havuz-Hastalar.xlsx");
    },
    transformDataForExcel(data) {
      return data?.map((item) => ({
        "Hasta Adı": item?.name,
        Tedavi: item?.operation?.name,
        "Ülke": item?.country?.name_tr,
        "Telefon": item?.phone,
        Kaynak: item?.agencie?.name,
        Kişi: item?.user?.name,
        Durum: item?.patientStatus?.name,
        "Kayıt Tarihi": item?.createdAt
          ? this.dateString(item?.createdAt)
          : null,
        "Son Güncelleme Tarihi": item?.updatedAt
          ? this.dateString(item?.updatedAt)
          : null,
      }));
    },
    async getRepeats() {
      const phoneRepeats = await HTTP.get("/pool/repeats/phone");
      this.repeats.phones = phoneRepeats?.data;

      const phoneNumberAtDifferentAgents = await HTTP.get(
        "/pool/repeats/phoneNumberAtDifferentAgents"
      );
      this.repeats.phoneNumberAtDifferentAgents =
        phoneNumberAtDifferentAgents?.data;
    },
    async getUsers() {
      const users = await HTTP.post("/permissions/userDatas/getUsers");
      this.options.users = users.data;
    },
    async getDirectedUsers() {
      const users = await HTTP.post("/users/roleIds", {
        ids: [3, 4],
      });
      this.options.directedUsers = users.data;
    },
    async getOperations() {
      const operations = await HTTP.get("/operations");
      this.options.operations = [{ id: null, name: "Boş" }, ...operations.data];
    },
    async getCountries() {
      const countries = await HTTP.get("/countries");
      this.options.countries = [
        { id: null, code: null, name: "Boş", name_tr: "Boş" },
        ...countries.data,
      ];
    },
    async getAgencies() {
      const agencies = await HTTP.post("/permissions/userDatas/getAgencies");
      this.options.agencies = [{ id: null, name: "Boş" }, ...agencies.data];
    },
    async getPatientStatus() {
      const patientStatus = await HTTP.get("/patientstatus");
      this.options.patientStatus = [
        { id: null, name: "Boş" },
        ...patientStatus.data,
      ];
    },
    async getSources() {
      const sources = await HTTP.get("/sources");
      this.options.sources = sources.data;
    },
    async getLanguages() {
      const languages = await HTTP.get("/languages");
      this.options.languages = [
        { id: null, code: null, name: "Boş" },
        ...languages.data,
      ];
    },
    async getBranches() {
      const branches = await HTTP.get("/permissions/userDatas/getBranches");
      this.options.branches = [{ id: null, name: "Boş" }, ...branches.data];
    },
    async paginationChanged(pageNumberb) {
      this.page = pageNumberb;
      await this.getPool();
    },
    async addSameValueFilter(data, field, valueKey) {
      await data?.map((item) => {
        this.sameValueFilter.push({
          field,
          value: item[valueKey],
        });
      });
    },
    async pressRepeatsDatasButton(item) {
      this.lastClickedItem.data = item;
      this.repeatsDataLoading = true;
      const repeatsPoolRequest = await HTTP.post("/pool/getPoolNoLimit", {
        queries: {
          phone: item?.phone,
        },
      });

      this.repeatsData = repeatsPoolRequest.data;
      this.repeatsDataLoading = false;
      this.modals.repeatsDataModalVisible = true;
    },
    async directPatientPress() {
      let data = {
        userId: this.directPatientModalSelectedDirectedUser?.id,
        directedPatientsToBeRecall: this.directPatientModalWillCallAgainCheck,
      };

      const directPatientRequest = await HTTP.patch(
        "patients/" + this.lastClickedItem?.data.id,
        data
      );
      this.modals.directPatientModalVisible = false;
      this.directPatientModalSelectedDirectedUser = null;

      directPatientRequest.status == 204
        ? this.$swal("Hasta Yönlendirme", "Yönlendirme Başarılı !", "success")
        : this.$swal(
            "Hasta Yönlendirme",
            "Yönlendirme başarısız oldu!",
            "error"
          );
    },
    async directSelectedRowsPatientsPress() {
      const dialog = await this.$swal({
        title: "Hastaları Yönlendirme?",
        text: this.selectedRows?.length + " adet hasta yönlendirilecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
      });
      if (dialog?.isConfirmed) {
        for (let index = 0; index < this.selectedRows.length; index++) {
          const pat = this.selectedRows[index];

          let data = {
            userId: this.directSelectedRowsPatientsModalSelectedDirectedUser.id,
            directedPatientsToBeRecall: this
              .directSelectedRowsPatientsModalWillCallAgainCheck,
          };
          await HTTP.patch("patients/" + pat.id, data);
        }
        this.selectedRows = [];
        this.directSelectedRowsPatientsModalSelectedDirectedUser = null;
        this.directSelectedRowsPatientsModalWillCallAgainCheck = null;
        this.modals.directSelectedRowsPatientsModalVisible = false;

        await this.getPool();

        await this.$swal({
          icon: "success",
          text: "İşlem Başarıyla tamamlandı.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async selectedRowsWillBeCalledAgain() {
      const dialog = await this.$swal({
        title: "Tekrar Aranacak",
        text:
          "Seçilen hastaları 'Tekrar Aranacak' olarak işaretlemek ister misiniz?",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Kaydet",
        cancelButtonText: "İptal",
      });
      if (dialog?.isConfirmed) {
        const updatePatientStatus = await HTTP.post(
          "/pool/updatePatientsStatus",
          {
            ids: this.selectedRows?.map((item) => item?.id),
            statusId: 30,
          }
        );

        if (updatePatientStatus?.status == 200) {
          this.selectedRows.forEach((item) => {
            item.status = 30;
            if (item.patientStatus) {
              item.patientStatus.name = "Tekrar Aranacak";
            } else {
              item.patientStatus = { name: "Tekrar Aranacak" };
            }
          });
        }

        this.$swal({
          icon: updatePatientStatus.status == 200 ? "success" : "error",
          text:
            updatePatientStatus.status == 200
              ? "İşlem Başarıyla tamamlandı."
              : "İşlem sırasında hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async selectedRowsRedirect() {
      this.modals.directSelectedRowsPatientsModalVisible = true;
    },
    async selectedRowsShared() {
      this.modals.sharedSelectedRowsPatientsSharedModalVisible = true;
    },
    async selectedRowsDelete() {
      const dialog = await this.$swal({
        title: "Emin misiniz?",
        text: this.selectedRows?.length + " hasta kaydı silinecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      });
      if (dialog?.isConfirmed) {
        var patients = this.selectedRows.map((p) => p.id);
        const deletedPatients = await HTTP.delete("/patients?ids=" + patients);

        await this.$swal({
          icon: deletedPatients.status == 204 ? "success" : "error",
          text:
            deletedPatients.status == 204
              ? "İşlem Başarıyla tamamlandı."
              : "İşlem sırasında hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getPool();
      }
    },
    async patientInformationModalSaveButtonClick() {
      const itemId = this.lastClickedItem.data.id;

      this.lastClickedItem.data.sourceId = this.lastClickedItem.data.sourceModel
        ? this.lastClickedItem.data.sourceModel.id
        : null;
      this.lastClickedItem.data.treatmentId = this.lastClickedItem.data
        .operation
        ? this.lastClickedItem.data.operation
        : null;
      this.lastClickedItem.data.status = this.lastClickedItem.data.patientStatus
        ? this.lastClickedItem.data.patientStatus.id
        : null;
      // this.lastClickedItem.data.agencieId = this.lastClickedItem.data.agencie
      //   ? this.lastClickedItem.data.agencie.id
      //   : null;
      this.lastClickedItem.data.countryCode = this.lastClickedItem.data.country
        ? this.lastClickedItem.data.country.code
        : null;
      this.lastClickedItem.data.languageId = this.lastClickedItem.data.language
        ? this.lastClickedItem.data.language.id
        : null;

      if (this.lastClickedItem.updateChildAgencieField != null) {
        this.lastClickedItem.data.agencieId = this.lastClickedItem.updateChildAgencieField?.id;
        this.lastClickedItem.data.agencie = this.lastClickedItem.updateChildAgencieField;
      } else {
        this.lastClickedItem.data.agencieId = this.lastClickedItem.updateParentAgencieField?.id;
        this.lastClickedItem.data.agencie = this.lastClickedItem.updateParentAgencieField;
      }

      const updatePatientRequest = await HTTP.put("/patients/" + itemId, {
        ...this.lastClickedItem.data,
      });

      if (updatePatientRequest.status == 204) {
        const getPatient = await HTTP.get("/patients/" + itemId);

        const findPatientArrayIndex = this.data.findIndex(
          (patient) => patient?.id == itemId
        );

        this.data[findPatientArrayIndex] == getPatient.data[0];
        this.modals.patientInformationModalVisible = false;

        this.$swal(
          "Hasta Güncelleme",
          "Hasta bilgileri güncellendi.",
          "success"
        );
      } else {
        this.$swal(
          "Hasta Güncelleme",
          "Hasta bilgileri güncellenemedi.",
          "error"
        );
      }
    },
    async getPoolExportNew() {
      // let cleanFilter = this.sameValueFilter?.filter(
      //   (a) => a.field === "agencieId"
      // );
      this.sameValueFilter = [];

      const filterDatas = [
        {
          filterVariable: "users",
          backendPropertyName: "userId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "countries",
          backendPropertyName: "countryCode",
          filterVariableValueKey: "code",
        },
        {
          filterVariable: "operations",
          backendPropertyName: "treatmentId",
          filterVariableValueKey: "id",
        },
        // {
        //   filterVariable: "agencies",
        //   backendPropertyName: "agencieId",
        //   filterVariableValueKey: "id",
        // },
        {
          filterVariable: "patientStatus",
          backendPropertyName: "status",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "languages",
          backendPropertyName: "languageId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "branches",
          backendPropertyName: "branchId",
          filterVariableValueKey: "id",
        },
      ];

      for (let i in filterDatas) {
        if (this.filters[filterDatas[i]?.filterVariable]) {
          await this.addSameValueFilter(
            this.filters[filterDatas[i]?.filterVariable],
            filterDatas[i]?.backendPropertyName,
            filterDatas[i]?.filterVariableValueKey
          );
        }
      }

      if (!this.filters.type) {
        this.sameValueFilter.push({
          field: "type",
          value: 0,
        });
      }

      let offset = 1000000 * 0;


      const poolRequest = await HTTP.post("/pool/getPool", {
        limit:1000000,
        page:1,
        offset:1000000,
        sameValueFilter: this.sameValueFilter,
        queries: this.queries,
        time: this.time,
      });

      return poolRequest.data?.rows;
    },
    async getPoolExport() {
      this.sameValueFilter = [];

      const filterDatas = [
        {
          filterVariable: "users",
          backendPropertyName: "userId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "countries",
          backendPropertyName: "countryCode",
          filterVariableValueKey: "code",
        },
        {
          filterVariable: "operations",
          backendPropertyName: "treatmentId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "agencies",
          backendPropertyName: "agencieId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "patientStatus",
          backendPropertyName: "status",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "languages",
          backendPropertyName: "languageId",
          filterVariableValueKey: "id",
        },
        {
          filterVariable: "branches",
          backendPropertyName: "branchId",
          filterVariableValueKey: "id",
        },
      ];

      for (let i in filterDatas) {
        if (this.filters[filterDatas[i]?.filterVariable]) {
          await this.addSameValueFilter(
            this.filters[filterDatas[i]?.filterVariable],
            filterDatas[i]?.backendPropertyName,
            filterDatas[i]?.filterVariableValueKey
          );
        }
      }

      if (!this.filters.type) {
        this.sameValueFilter.push({
          field: "type",
          value: 0,
        });
      }

      const poolRequest = await HTTP.post("/pool/exportPool", {
        sameValueFilter: this.sameValueFilter,
        queries: this.queries,
        time: this.time,
      });

      return poolRequest.data;
    },
    async sharedSelectedRowsPatientsPress() {
      const ids = this.selectedRows?.map((item) => item?.id);
      const userId = this
        .sharedSelectedRowsPatientsSharedModalSelectedSharedUser?.id;
      const statusId = this.sharedSelectedRowsPatientsSharedModalSelectedStatus
        ?.id;

      const dialog = await this.$swal({
        title: "Hastaları Paylaşmak?",
        text: this.selectedRows?.length + " adet hasta paylaşılacaktır.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
      });
      if (dialog?.isConfirmed) {
        try {
          await HTTP.post("/patients/sharing/multiple", {
            ids,
            userId,
            statusId,
          });

          this.selectedRows = [];
          this.sharedSelectedRowsPatientsSharedModalSelectedSharedUser = null;
          this.modals.sharedSelectedRowsPatientsSharedModalVisible = false;

          await this.getPool();

          await this.$swal({
            icon: "success",
            text: "İşlem Başarıyla tamamlandı.",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          await this.$swal({
            icon: "error",
            text: "Hata Oluştu! Lütfen tekrar deneyiniz...",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    getOperationsData() {
      if (
        Array.isArray(this.filters.branches) &&
        this.filters.branches?.length > 0
      ) {
        const selectedBranchIds = this.filters.branches.map(
          (branch) => branch?.id
        );

        const filterData = this.options.operations.filter((operation) =>
          selectedBranchIds.includes(operation.branchId)
        );
        return filterData?.filter((item) => !item?.isActivity && item?.status);
      } else {
        return this.options.operations?.filter(
          (item) => !item?.isActivity && item?.status
        );
      }
    },
    selectedTimeClick(time) {
      this.time.type = time;
      this.page = 1;
      switch (this.time.type) {
        case "all":
          break;
        case "today":
          this.time.startDate = moment()
            .startOf("day")
            .toDate();

          this.time.endDate = moment()
            .endOf("day")
            .toDate();
          break;
        case "thisWeek":
          break;
        case "thisMonth":
          break;
        case "prevMonth":
          let currDate = this.time.startDate;
          this.time.startDate = moment(currDate).subtract(1,'months').date(1).toDate();
          this.time.endDate = moment(currDate).subtract(1,'months').endOf('month').toDate();
          break;
        case "nextMonth":
          let currDate2 = this.time.startDate;
          this.time.startDate = moment(currDate2).add(1,'months').date(1).toDate();
          this.time.endDate = moment(currDate2).add(2,'months').date(0).hour(23).minute(59).second(59).toDate();
          break;
        case "nextDay":
          const date = this.time.startDate;
          this.time.startDate = moment(date)
            .add(1, "day")
            .startOf("day")
            .toDate();

          this.time.endDate = moment(date)
            .add(1, "day")
            .endOf("day")
            .toDate();

          break;
        case "prevDay":
          const date_ = this.time.startDate;
          this.time.startDate = moment(date_)
            .subtract(1, "day")
            .toDate();

          this.time.endDate = moment(date_)
            .subtract(1, "day")
            .endOf("day")
            .toDate();
          break;
        case "special":
          this.time.startDate = moment(this.inputStartDate)
            .add(1, "day")
            .startOf("day")
            .toDate();

          this.time.endDate = moment(this.inputEndDate)
            .endOf("day")
            .toDate();

          break;
      }

      this.getPool();
    },
    newPatientNotes(newNotes) {
      this.lastClickedItem.data?.notes?.push(newNotes?.data);
      this.modals.statusWithNoteUpdateModalVisible = false;
    },
    newPatientStatus: function(newStatus) {
      let findPatient = this.data.findIndex(
        (x) => x?.id === this.lastClickedItem?.data?.id
      );
      this.data[findPatient].patientStatus = newStatus;
      this.data[findPatient].status = newStatus;

      this.lastClickedItem.data.patientStatus = newStatus;
    },
    getProperty(val, key) {
      if (val) {
        if (val.hasOwnProperty(key)) {
          return val[key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getPropertyForAgencie(val, key) {
      let parent;
      if (val?.parentId) {
        parent = this.options.agencies?.find((a) => a.id === val.parentId);
      }

      if (val) {
        if (val.hasOwnProperty(key)) {
          if (parent) {
            return `${parent[key]}/${val[key]}`;
          } else {
            return val[key];
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getLastValueOfArrayWithProperty(val, key) {
      if (Array.isArray(val) && val.length > 0) {
        const lastObject = val[val.length - 1];
        if (lastObject.hasOwnProperty(key)) {
          return lastObject[key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    lengthControl(val) {
      return val?.substring(0, 20);
    },
    checkIfSelected(model, val) {
      return model && model.find((z) => z.id == val.id) ? "✓" : "";
    },
    checkIfSelectedCountry(model, val) {
      return model && model.find((z) => z.code == val.code) ? "✓" : "";
    },
    clickFilterInput(item) {
      const inputOptionsToCall = {
        users: {
          value: this.options.users,
          optionsMethodToCall: this.getUsers,
        },
        operations: {
          value: this.options.operations,
          optionsMethodToCall: this.getOperations,
        },
        countries: {
          value: this.options.countries,
          optionsMethodToCall: this.getCountries,
        },
        agencies: {
          value: this.options.agencies,
          optionsMethodToCall: this.getAgencies,
        },
        patientStatus: {
          value: this.options.patientStatus,
          optionsMethodToCall: this.getPatientStatus,
        },
        languages: {
          value: this.options.languages,
          optionsMethodToCall: this.getLanguages,
        },
      };

      if (inputOptionsToCall[item]?.value.length === 0) {
        const methodToCall = inputOptionsToCall[item]?.optionsMethodToCall;
        if (typeof methodToCall === "function") {
          methodToCall.call(this);
        }
      }
    },
    // ChildAgencieGetData
    childIsDeletedControl(val) {
      return val.filter((item) => item.isDeleted == 0 && item);
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    clearFilters() {
      this.filters = {
        users: null,
        operations: null,
        countries: null,
        agencies: null,
        patientStatus: null,
        languages: null,
        startDate: null,
        endDate: null,
        agencieChild: [],
      };
      this.isDisabledWatcher = false;
      this.lastClickedItem.updateSelectingAgenciesChild = false;

      this.queries = {
        nameOrPhone: null,
      };
      this.page = 1;
      this.getPool();
    },
    getDateFormat(date, isShowFullDay = false) {
      if (isShowFullDay) {
        return moment(date).format("DD/MM/YYYY dddd");
      }
      return moment(date).format("DD/MM/YYYY");
    },
    isRepeating(repeatName, value, valueKey) {
      if (
        value &&
        this.repeats[repeatName] &&
        this.repeats[repeatName][value]
      ) {
        if (valueKey) {
          return this.repeats[repeatName][value][valueKey] ?? false;
        } else {
          return this.repeats[repeatName][value];
        }
      } else {
        return false;
      }
    },
    navigatePotantialDetail: function(item) {
      const route = this.$router.resolve({
        name: "potantial.patients",
        params: {
          id: item.id,
        },
      });

      window.open(route.href, "_blank");
    },
    clickMainTableRow(item, index) {
      if (!(this.roleId == 6)) {
        this.lastClickedItem.data = item;
        this.lastClickedItem.index = index;
        this.modals.statusWithNoteUpdateModalVisible = true;
      }
    },
    getMainTableRowColors(item) {
      if (item.isShared && this.lastClickedItem.data.id == item?.id) {
        return {
          background: "#534BFF",
          color: "white",
        };
      } else if (item.isShared) {
        return {
          background: "white",
          color: "#534BFF",
        };
      } else if (
        this.isRepeating("phones", item.phone) &&
        this.lastClickedItem.data.id == item?.id
      ) {
        return {
          background: "#F24C3D",
          color: "white",
        };
      } else if (this.lastClickedItem.data.id == item?.id) {
        return {
          background: "#03C988",
          color: "white",
        };
      } else if (this.isRepeating("phones", item.phone)) {
        return {
          background: "white",
          color: "red",
        };
      } else {
        return {
          background: "white",
          color: "black",
        };
      }
    },
    getDateWithHour(dateString) {
      if (dateString) {
        return moment(dateString).format("DD-MM-YYYY HH:mm");
      }
    },
    endDateDisabledDates(date) {
      return moment(date).isBefore(
        moment(this.inputStartDate).format("MM/DD/YYYY")
      );
    },
    patientInformationModalConvertPatientButtonClick() {
      this.modals.convertPatientModalVisible = true;
    },
    closeConvertPatientModal() {
      this.modals.convertPatientModalVisible = false;
    },
    selectedRow(val) {
      this.selectedRows = val;
    },
    characterLimit(value) {
      if (value?.length > 25) {
        return value.substring(0, 26) + "...";
      } else {
        return value;
      }
    },
    patientAddPress() {
      const route = this.$router.resolve(
        this.queries.phone
          ? {
            name: "patient.create",
            query: { phone: this.queries.phone },
          }
          : {
            name: "patient.create",
          }
      );

      window.open(route.href, "_blank");
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    isReminderOnLastNote(item) {
      if (Array.isArray(item?.notes)) {
        const lastNote = item?.notes[item?.notes?.length - 1];
        return lastNote?.byWhatsapp || lastNote?.notify_me;
      } else {
        return true;
      }
    },
    getPhone(phone) {
      if (phone) {
        if (this.roleId == 6) {
          const maskLength = Math.floor(phone.length / 3); // Yıldız sayısını artırmak için maskLength'i değiştirdik
          const maskedPhone =
            phone.slice(0, maskLength) +
            "*".repeat(phone.length - 2 * maskLength) +
            phone.slice(-maskLength);
          return maskedPhone;
        } else {
          return phone;
        }
      } else {
        return "";
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handlePhoneInput() {
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(() => {
        this.time.type = "all";
        this.page = 1;
        this.getPool();
      }, 500); // 500 ms yazma bırakıldıktan sonra isteği tetiklemek için
    },
  },
  computed: {
    innerWidth: function() {
      return window.innerWidth;
    },
  },
  watch: {
    "lastClickedItem.data.agencie"(val) {
      if (val != null) {
        const currAgencie = this.options.agencies?.find((a) => a.id == val?.id);
        if (currAgencie?.parentId) {
          this.lastClickedItem.updateSelectingAgenciesChild = true;
          let parentAgencieStart = this.options.agencies?.filter(
            (a) => a.id === currAgencie?.parentId
          )?.[0];
          this.lastClickedItem.updateParentAgencieField = parentAgencieStart;
          this.lastClickedItem.updateChildAgencieField = currAgencie;
          this.lastClickedItem.updateChildAgencies =
            parentAgencieStart.childAgencies;
        } else if (currAgencie?.childAgencies?.length > 0) {
          this.lastClickedItem.updateSelectingAgenciesChild = true;
          this.lastClickedItem.updateParentAgencieField = currAgencie;
          this.lastClickedItem.updateChildAgencies = currAgencie.childAgencies;
          this.lastClickedItem.updateChildAgencieField = null;
        } else {
          this.lastClickedItem.updateParentAgencieField = currAgencie;
          this.lastClickedItem.updateChildAgencieField = null;
        }
      }
    },
    "lastClickedItem.updateParentAgencieField": function(val) {
      if (val?.childAgencies?.length > 0) {
        this.lastClickedItem.updateSelectingAgenciesChild = true;
        this.lastClickedItem.updateChildAgencies = val.childAgencies;
        let included = this.lastClickedItem.updateChildAgencies.find(
          (c) => c.id === this.lastClickedItem.updateChildAgencieField?.id
        );
        if (!included) {
          this.lastClickedItem.updateChildAgencieField = null;
        }
      } else {
        this.lastClickedItem.updateSelectingAgenciesChild = false;
      }
    },
    "filters.users"() {
      this.page = 1;
      this.getPool();
    },
    "filters.operations"() {
      this.page = 1;
      this.getPool();
    },
    "filters.countries"() {
      this.page = 1;
      this.getPool();
    },
    "filters.agencies"(val) {
      let sendValue = this.filters.agencieChild;

      // CHILD LAYOUT CONTROL - START
      let control = [];
      val?.map((item) => item.childAgencies.length > 0 && control.push(true));

      if (JSON.stringify(control).match(true)) {
        this.selectingAgenciesChild = true;
      } else {
        this.selectingAgenciesChild = false;
      }
      // CHILD LAYOUT CONTROL - END

      // Clean
      let cleanFilter = this.sameValueFilter?.filter(
        (a) => a.field !== "agencieId"
      );

      this.sameValueFilter = cleanFilter;

      if (sendValue?.length > 0 && val?.length > 0) {
        let ids = [];
        for (let i in sendValue) {
          for (let j in sendValue[i]) {
            ids.push(sendValue[i][j].id);
          }
        }
        if (sendValue?.length > 0) {
          this.addSameValueFilter(sendValue, "agencieId", "id");
        }
        let hasChildAgencies = [];
        let someths = val?.filter(
          (parent) => parent?.childAgencies?.length > 0
        );

        someths?.map((pC) => {
          let childs = pC?.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        const leftParentAgencies = val?.filter(
          (p) => !hasChildAgencies.includes(p.id)
        );

        let childs = [];
        leftParentAgencies.map((v) => childs.push(v.childAgencies?.map((z) => z)));
        let flattedChild = childs.flat();

        if (leftParentAgencies?.length > 0) {
          this.addSameValueFilter(leftParentAgencies, "agencieId", "id");
        }
        
        if (flattedChild?.length > 0) {
          this.addSameValueFilter(flattedChild, "agencieId", "id");
        }

        this.page = 1;
        this.getPool();
      } else if (val != null && val?.length > 0) {
        this.addSameValueFilter(val, "agencieId", "id");

        let childs = [];
        val.map((v) => childs.push(v.childAgencies?.map((z) => z)));
        let flattedChild = childs.flat();

        this.addSameValueFilter(flattedChild, "agencieId", "id");

        this.page = 1;
        this.getPool();
      } else {
        this.page = 1;
        this.getPool();
      }
    },
    "filters.agencieChild"(val) {
      // Get Selected Child Agencies' ids
      let ids = [];
      for (let i in val) {
        for (let j in val[i]) {
          ids.push(val[i][j].id);
        }
      }

      if (ids?.length > 0) {
        this.isDisabledWatcher = true;
      } else {
        this.isDisabledWatcher = false;
      }

      // Selected Parent Agencies
      let sendValue = this.filters.agencies;

      // Clean
      let cleanFilter = this.sameValueFilter?.filter(
        (a) => a.field !== "agencieId"
      );

      this.sameValueFilter = cleanFilter;

      if (sendValue?.length > 0 && ids?.length > 0) {
        if (val?.length > 0) {
          this.addSameValueFilter(val.flat(), "agencieId", "id");
        }

        let hasChildAgencies = [];
        let someths = sendValue.filter(
          (parent) => parent?.childAgencies?.length > 0
        );

        someths.map((pC) => {
          let childs = pC.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        const leftParentAgencies = sendValue.filter(
          (p) => !hasChildAgencies.includes(p.id)
        );

        let childs = [];
        leftParentAgencies.map((v) => childs.push(v.childAgencies?.map((z) => z)));
        let flattedChild = childs.flat();

        if (flattedChild?.length > 0) {
          this.addSameValueFilter(flattedChild, "agencieId", "id");
        }

        if (leftParentAgencies?.length > 0) {
          this.addSameValueFilter(leftParentAgencies, "agencieId", "id");
        }

        this.page = 1;
        this.getPool();
      } else if (sendValue?.length > 0) {
        this.addSameValueFilter(sendValue, "agencieId", "id");

        let childs = [];
        sendValue.map((v) => childs.push(v.childAgencies?.map((z) => z)));
        let flattedChild = childs.flat();

        this.addSameValueFilter(flattedChild, "agencieId", "id");

        this.page = 1;
        this.getPool();
      } else {
        this.page = 1;
        this.getPool();
      }
    },
    "filters.patientStatus"() {
      this.page = 1;
      this.getPool();
    },
    "filters.languages"() {
      this.page = 1;
      this.getPool();
    },
    "filters.type"(val) {
      this.page = 1;
      this.getPool();
    },
    "filters.branches"(val) {
      this.page = 1;
      this.getPool();
    },
    "filters.sharing"(val) {
      this.page = 1;
      this.getPool();
    },
    "queries.adId"() {
      this.getPool();
    },
  },
};
</script>

<style scoped>
.filters-container {
  width: 100%;
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.mobile-show-button {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 10px;
  padding: 5px;
  color: rgb(62, 62, 62);
  height: 35px;
  display: flex;
  align-items: center;
}
</style>
