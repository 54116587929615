<template>
  <div class="">
    <section class="content-wrapper" :style="`width: 800px`">
      <div class="" style="height: 1120px">
        <div class="position-relative">
          <div class="position-absolute pl-5 pt-5">
            <img
              :src="'/images/epicrisis/' + template.watermark"
              class="pl-5 pt-5"
              alt=""
              style="max-width: 800x; opacity: 1"
            />
            <!-- <img src="transit bipartisyon.png" class="pt-4" style="max-width: 200px;" alt=""> -->
          </div>
        </div>
        <div class="row">
          <div class="row ml-2">
            <div class="col-12" style="height: 30px"></div>
            <div
              class="col-2"
              style="max-height: 80px"
              :style="{ 'background-color': [template.color] }"
            ></div>
            <div
              class="col-4"
              style="max-height: 40px; background-color: white"
            >
              <div class="pt-3 pl-2 pb-5">
                <img :src="'/images/epicrisis/' + template.img" alt="" />
              </div>
            </div>
            <div class="col-6 pl-5" style="width: 500px">
              <div
                class="p-3"
                style="color: white; height: 80px"
                :style="{ 'background-color': [template.color] }"
              >
                <b style="font-size: medium">{{ epicrisis.title.content }} </b>
              </div>
            </div>
          </div>

          <div class="col-12 text-center mt-4 mb-2">
            <b style="font-size: large; color: black">{{
              epicrisis.summary.content
            }}</b>
          </div>
          <div class="col-8 pl-5 pt-2">
            <div class="div-12 pb-2 rounded">
              <table
                class="rounded"
                style="line-height: 17px;width: -webkit-fill-available;display"
                :style="{ border: [template.color + ' 2px solid'] }"
              >
                <tbody>
                  <tr v-if="epicrisis.name">
                    <td>{{ epicrisis.name.label }}:</td>
                    <td>{{ patient.name }}</td>
                  </tr>
                  <tr v-if="epicrisis.age">
                    <td>{{ epicrisis.age.label }}:</td>
                    <td>{{ epicrisis.age.content }}</td>
                  </tr>
                  <tr v-if="epicrisis.height">
                    <td>{{ epicrisis.height.label }}:</td>
                    <td>{{ epicrisis.height.content }} cm</td>
                  </tr>
                  <tr v-if="epicrisis.weight">
                    <td>{{ epicrisis.weight.label }}:</td>
                    <td>{{ epicrisis.weight.content }} kg</td>
                  </tr>
                  <tr v-if="epicrisis.bmi">
                    <td>{{ epicrisis.bmi.label }}:</td>
                    <td>{{ epicrisis.bmi.content }}</td>
                  </tr>
                  <tr v-if="epicrisis.complaint">
                    <td>{{ epicrisis.complaint.label }}:</td>
                    <td>{{ epicrisis.complaint.content }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="div-12"
              style="line-height: 17px"
              :style="{ border: [template.color + ' 2px solid'] }"
            >
              <table
                style="
                    width: -webkit-fill-available;display"
              >
                <tr v-if="epicrisis.admission">
                  <td>{{ epicrisis.admission.label }}:</td>
                  <td>{{ epicrisis.admission.content }}</td>
                </tr>
                <tr v-if="epicrisis.discharge">
                  <td>{{ epicrisis.discharge.label }}:</td>
                  <td>{{ epicrisis.discharge.content }}</td>
                </tr>
                <tr v-if="epicrisis.operationDate">
                  <td>{{ epicrisis.operationDate.label }}:</td>
                  <td>{{ epicrisis.operationDate.content }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-4 pr-5">
            <img
              v-if="epicrisis.img"
              :src="'/images/epicrisis/' + epicrisis.img.content"
              class="pt-4"
              style="max-height: 200px"
              alt=""
            />
          </div>
          <div class="col-12 pl-5 pr-5 pt-3">
            <p
              class="p-2"
              style="height: 645px; white-space: pre-line"
              :style="{ border: [template.color + ' 2px solid'] }"
            >
              <span class="">
                <b>{{ epicrisis.history.label }}:</b>
                {{ epicrisis.history.content }}
              </span>

              <br />
              <br />
              <span v-if="epicrisis.examination">
                <b>{{ epicrisis.examination.label }}:</b>
                <span v-html="epicrisis.examination.content"></span>
              </span>

              <br />
              <span v-if="epicrisis.pulse">
                <b>{{ epicrisis.pulse.label }}:</b>
                {{ epicrisis.pulse.content }}/dk<br />
              </span>
              <span v-if="epicrisis.spo2">
                <b>{{ epicrisis.spo2.label }}:</b> {{ epicrisis.spo2.content
                }}<br />
              </span>
              <span v-if="epicrisis.background">
                <b>{{ epicrisis.background.label }}:</b>
                {{ epicrisis.background.content }}<br />
              </span>
              <br />
              <span v-if="epicrisis.laboratory">
                <b>{{ epicrisis.laboratory.label }}:</b>
                <span v-html="epicrisis.laboratory.content"></span>
              </span>
              <br />
              <span v-if="epicrisis.abdominal">
                <b>{{ epicrisis.abdominal.label }}:</b
                >{{ epicrisis.abdominal.content }}
              </span>
              <br /><br />
              <span v-if="epicrisis.course">
                <b>{{ epicrisis.course.label }}:</b>
                {{ epicrisis.course.content }}
              </span>
            </p>
          </div>
          <!--Footer-->

          <div
            class="col-6"
            :style="{ 'background-color': [template.color] }"
          ></div>
          <div class="col-5 pt-3 row">
            <div class="col-12" style="font-size: 10px; line-height: 15px">
              {{ template.footer }}
            </div>
            <br /><br />
            <!-- <div class="col-12" style="font-size: 10px">
                    <pre style="font-size: 10px; color:black;">Üsküdar/İSTANBUL/TURKEY  www.drhasanerdem.com</pre>
                  </div> -->
          </div>
          <div
            class="col-1"
            style="width: 95px"
            :style="{ 'background-color': [template.color] }"
          ></div>
        </div>
      </div>
      <!--Sayfa 2-->
      <!-- Dynamic epicrisis pages -->
      <div class="" style="height: 1120px" v-if="epicrisis.postop.length > 0">
        <div class="position-relative">
          <div class="position-absolute pl-5 pt-5">
            <img
              :src="'/images/epicrisis/' + template.watermark"
              class="pl-5 pt-5"
              alt=""
              style="max-width: 800x; opacity: 1"
            />
          </div>
        </div>
        <div class="row">
          <div class="row ml-2">
            <div class="col-12" style="height: 30px"></div>
            <div
              class="col-2"
              style="max-height: 80px"
              :style="{ 'background-color': [template.color] }"
            ></div>
            <div
              class="col-4"
              style="max-height: 40px; background-color: white"
            >
              <div class="pt-3 pl-2 pb-5">
                <img :src="'/images/epicrisis/' + template.img" alt="" />
              </div>
            </div>
            <div class="col-6 pl-5" style="width: 500px">
              <div
                class="p-3"
                style="color: white; height: 80px"
                :style="{ 'background-color': [template.color] }"
              >
                <b style="font-size: medium">{{ epicrisis.title.content }} </b>
              </div>
            </div>
          </div>
          <div class="col-12 pl-5 pr-5 pt-3">
            <p
              class="p-2"
              style="height: 915px"
              :style="{ border: [template.color + '  2px solid'] }"
            >
              <span
                class=""
                v-for="(postop, index) in epicrisis.postop.slice(0, 6)"
                :key="index"
              >
                <b>
                  {{ epicrisis.postop[index].label }}: ({{
                    epicrisis.postop[index].content.crp.label
                  }}: {{ epicrisis.postop[index].content.crp.content }} mg/L,
                  {{ epicrisis.postop[index].content.hgb.label }}:
                  {{ epicrisis.postop[index].content.hgb.content }} g/dL)
                </b>
                {{ epicrisis.postop[index].content.desc }}
                <br />
                <br />
              </span>
            </p>
          </div>
          <!--Footer-->

          <div
            class="col-6"
            :style="{ 'background-color': [template.color] }"
          ></div>
          <div class="col-5 pt-3 row">
            <div class="col-12" style="font-size: 10px; line-height: 15px">
              {{ template.footer }}
            </div>
            <br /><br />
            <!-- <div class="col-12" style="font-size: 10px; line-height: 0px">
                    Altunizade Mah. Kısıklı Cad. No:5/B D:2 34662
                  </div>
                  <div class="col-12" style="font-size: 10px">
                    <pre style="font-size: 10px; color:black;">Üsküdar/İSTANBUL/TURKEY  www.drhasanerdem.com</pre>
                  </div> -->
          </div>
          <div
            class="col-1"
            style="width: 95px"
            :style="{ 'background-color': [template.color] }"
          ></div>
        </div>
      </div>
      <div class="" style="height: 1120px" v-if="epicrisis.postop.length > 6">
        <div class="position-relative">
          <div class="position-absolute pl-5 pt-5">
            <img
              :src="'/images/epicrisis/' + template.watermark"
              class="pl-5 pt-5"
              alt=""
              style="max-width: 800x; opacity: 1"
            />
          </div>
        </div>
        <div class="row">
          <div class="row ml-2">
            <div class="col-12" style="height: 30px"></div>
            <div
              class="col-2"
              style="max-height: 80px"
              :style="{ 'background-color': [template.color] }"
            ></div>
            <div
              class="col-4"
              style="max-height: 40px; background-color: white"
            >
              <div class="pt-3 pl-2 pb-5">
                <img :src="'/images/epicrisis/' + template.img" alt="" />
              </div>
            </div>
            <div class="col-6 pl-5" style="width: 500px">
              <div
                class="p-3"
                style="color: white; height: 80px"
                :style="{ 'background-color': [template.color] }"
              >
                <b style="font-size: medium">{{ epicrisis.title.content }} </b>
              </div>
            </div>
          </div>
          <div class="col-12 pl-5 pr-5 pt-3">
            <p
              class="p-2"
              style="height: 915px"
              :style="{ border: [template.color + '  2px solid'] }"
            >
              <span
                class=""
                v-for="(postop, index) in epicrisis.postop.slice(6, 12)"
                :key="index"
              >
                <b>
                  {{ epicrisis.postop[index + 6].label }}: ({{
                    epicrisis.postop[index + 6].content.crp.label
                  }}:
                  {{ epicrisis.postop[index + 6].content.crp.content }} mg/L,
                  {{ epicrisis.postop[index + 6].content.hgb.label }}:
                  {{ epicrisis.postop[index + 6].content.hgb.content }} g/dL)
                </b>
                {{ epicrisis.postop[index + 6].content.desc }}
                <br />
                <br />
              </span>
            </p>
          </div>
          <!--Footer-->

          <div
            class="col-6"
            :style="{ 'background-color': [template.color] }"
          ></div>
          <div class="col-5 pt-3 row">
            <div class="col-12" style="font-size: 10px; line-height: 15px">
              {{ template.footer }}
            </div>
            <br /><br />
            <!-- <div class="col-12" style="font-size: 10px; line-height: 0px">
                    Altunizade Mah. Kısıklı Cad. No:5/B D:2 34662
                  </div>
                  <div class="col-12" style="font-size: 10px">
                    <pre style="font-size: 10px; color:black;">Üsküdar/İSTANBUL/TURKEY  www.drhasanerdem.com</pre>
                  </div> -->
          </div>
          <div
            class="col-1"
            style="width: 95px"
            :style="{ 'background-color': [template.color] }"
          ></div>
        </div>
      </div>

      <!--Sayfa 3-->
      <div class="" style="height: 1120px">
        <div class="position-relative">
          <div class="position-absolute pl-5 pt-5">
            <img
              :src="'/images/epicrisis/' + template.watermark"
              class="pl-5 pt-5"
              alt=""
              style="max-width: 800x; opacity: 1"
            />
          </div>
        </div>
        <div class="row">
          <div class="row ml-2">
            <div class="col-12" style="height: 30px"></div>
            <div
              class="col-2"
              style="max-height: 80px"
              :style="{ 'background-color': [template.color] }"
            ></div>
            <div
              class="col-4"
              style="max-height: 40px; background-color: white"
            >
              <div class="pt-3 pl-2 pb-5">
                <img :src="'/images/epicrisis/' + template.img" alt="" />
              </div>
            </div>
            <div class="col-6 pl-5" style="width: 500px">
              <div
                class="p-3"
                style="color: white; height: 80px"
                :style="{ 'background-color': [template.color] }"
              >
                <b style="font-size: medium">{{ epicrisis.title.content }} </b>
              </div>
            </div>
          </div>
          <div class="col-12 pl-5 pr-5 pt-3">
            <p
              class="p-2"
              style="height: 915px"
              :style="{ border: [template.color + ' 2px solid'] }"
            >
              <span v-if="epicrisis.surgicalNote">
                <b>{{ epicrisis.surgicalNote.label }}:</b>
                {{ epicrisis.surgicalNote.content }}
              </span>
              <br />
              <br />
              <span v-if="epicrisis.condDischarge">
                <b>{{ epicrisis.condDischarge.label }}:</b>
                {{ epicrisis.condDischarge.content }} <br />
              </span>
              <br />
              <span v-if="epicrisis.diagnosis">
                <b>{{ epicrisis.diagnosis.label }}:</b>
                {{ epicrisis.diagnosis.content }}<br />
              </span>
              <br />
              <span v-if="epicrisis.surgeryNote">
                <b>{{ epicrisis.surgeryNote.label }}:</b>
                {{ epicrisis.surgeryNote.content }}
              </span>
              <br />
              <br />
              <span v-if="epicrisis.medicalPrescibed">
                <b>{{ epicrisis.medicalPrescibed.label }}:</b><br />
                <br /><span style="white-space: pre-line">{{
                  epicrisis.medicalPrescibed.content
                }}</span>
              </span>
              <br />
              <br />
              <span v-if="epicrisis.recommendations">
                <b> {{ epicrisis.recommendations.label }}:</b>
                <span style="white-space: pre-line">
                  {{ epicrisis.recommendations.content }}</span
                >
              </span>
            </p>
          </div>
          <div
            class="col-6"
            :style="{ 'background-color': [template.color] }"
          ></div>
          <div class="col-5 pt-3 row">
            <div class="col-12" style="font-size: 10px; line-height: 15px">
              {{ template.footer }}
            </div>
            <br /><br />
            <!-- <div class="col-12" style="font-size: 10px; line-height: 0px">
                    Altunizade Mah. Kısıklı Cad. No:5/B D:2 34662
                  </div>
                  <div class="col-12" style="font-size: 10px">
                    <pre style="font-size: 10px; color:black;">Üsküdar/İSTANBUL/TURKEY  www.drhasanerdem.com</pre>
                  </div> -->
          </div>
          <div
            class=""
            style="width: 95px"
            :style="{ 'background-color': [template.color] }"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { HTTP } from "../main-source";
export default {
  data() {
    return {
      apiLink: process.env.VUE_APP_API_URL,
    };
  },
  name: "epicrisis",

  props: {
    patient: {
      type: Object,
      default() {
        return { name: "hello" };
      },
    },
    epicrisis: {},
    template: {},
  },
};
</script>
<style scoped>
td {
  width: 50%;
}
</style>