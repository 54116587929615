<template>
  <div class="wrapper">
    <div class="quick-action">
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('all')"
        :class="{ active: periodName == 'all' }"
      >
        Tüm Zamanlar
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('today')"
        :class="{ active: periodName == 'today' }"
      >
        Bugün
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('this week')"
        :class="{ active: periodName == 'this week' }"
      >
        Bu Hafta
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('this month')"
        :class="{ active: periodName == 'this month' }"
      >
        Bu Ay
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('last month')"
        :class="{ active: periodName == 'last month' }"
      >
        Önceki Ay
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('next month')"
        :class="{ active: periodName == 'next month' }"
      >
        Sonraki Ay
      </button>
      <button
        class="little-btn md-xsmall-size-50"
        @click="filterByConvertingDate('special')"
        :class="{ active: periodName == 'special' }"
      >
        Özel
      </button>
      <button class="little-btn md-xsmall-hide" @click="changeDay('prev')">
        <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
      </button>
      <button class="little-btn md-xsmall-hide" @click="changeDay('next')">
        Sonraki Gün
        <md-icon>keyboard_arrow_right</md-icon>
      </button>
      <div class="md-layout-item md-size-15 md-xsmall-size-100 select-date">
        <md-datepicker
          v-model="startDate"
          md-immediately
          v-if="periodName == 'special'"
        >
          <label>Başlangıç Tarihi</label>
        </md-datepicker>
      </div>

      <div class="md-layout-item md-size-15 md-xsmall-size-100 select-date">
        <md-datepicker
          v-model="endDate"
          md-immediately
          v-if="periodName == 'special'"
        >
          <label>Bitiş Tarihi</label>
        </md-datepicker>
      </div>
    </div>

    <!-- Filters -->
    <div class="md-layout" id="filters">
      <!-- if Agencie not selected or it has no child -->
      <div v-if="!selectingAgenciesChild" class="layout-1">
        <NewInput>
          <v-select
            v-model="selectedUserFilter"
            placeholder="Personel"
            :options="users"
            label="name"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedAgencieFilter"
            placeholder="Kaynak"
            :options="childDontShow(agencies)"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedSourceFilter"
            placeholder="İletişim"
            :options="sources"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedBranchFilter"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedTreatmentFilter"
            placeholder="Tedavi"
            :options="operations"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedCountryFilter"
            placeholder="Ülke"
            :options="countries"
            label="name_tr"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name_tr
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedLanguageFilter"
            placeholder="Dil"
            :options="languages"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedAppointmentStatusFilter"
            placeholder="Randevu Durumu"
            :options="statuses"
            label="name"
            class="md-menu"
            multiple
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <v-select
            v-model="selectedDiscountFilter"
            placeholder="İndirim"
            :options="discounts"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </NewInput>

        <NewInput>
          <input
            placeholder="Advertising ID"
            style="border : 0px solid ; outline: 0px solid;padding-left : 10px;padding-right: 10px;"
            v-model="adIdFilter"
          />
        </NewInput>
      </div>

      <!-- if Agencie has selected AND it has child -->
      <div style="width:100%" v-if="selectingAgenciesChild">
        <!-- Top Filter Section -->
        <div style="display:flex;flex-wrap: wrap;">
          <NewInput>
            <v-select
              v-model="selectedUserFilter"
              placeholder="Personel"
              :options="users"
              label="name"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedSourceFilter"
              placeholder="İletişim"
              :options="sources"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedBranchFilter"
              placeholder="Branş"
              :options="branches"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedTreatmentFilter"
              placeholder="Tedavi"
              :options="operations"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedCountryFilter"
              placeholder="Ülke"
              :options="countries"
              label="name_tr"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name_tr
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedLanguageFilter"
              placeholder="Dil"
              :options="languages"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>

          <NewInput>
            <v-select
              v-model="selectedAppointmentStatusFilter"
              placeholder="Randevu Durumu"
              :options="statuses"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </NewInput>
        </div>

        <!-- Divider -->
        <div style="width:100%;display:flex;align-items: center;">
          <div
            style="width:100%; height: 1px; background-color: gray;margin-bottom: 20px;margin-top: 15px;opacity:0.4;color : gray"
          ></div>
        </div>

        <!-- Agencie & Sub Agencie Section -->
        <div style="display:flex;flex-wrap: wrap;">
          <NewInput>
            <v-select
              v-model="selectedAgencieFilter"
              placeholder="Kaynak"
              :options="childDontShow(agencies)"
              label="name"
              class="md-menu"
              multiple
              :disabled="isDisabledWatcher"
            >
              <template
                slot="option"
                slot-scope="option"
                >{{ option.name }}</template
              >
            </v-select>
          </NewInput>

          <div :key="index" v-for="(item, index) in selectedAgencieFilter">
            <NewInput width="250" v-if="item.childAgencies.length > 0">
              <v-select
                v-model="selectedAgencieChildFilter[index]"
                :placeholder="item.name + ' Alt Kaynak'"
                :options="childIsDeletedControl(item.childAgencies)"
                label="name"
                class="md-menu"
                multiple
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                </template>
              </v-select>
            </NewInput>
          </div>
        </div>
      </div>

      <!-- Clear Button -->
      <div>
        <md-button style="margin-left: 10px !important" @click="clearFilters">
          Temizle
        </md-button>
      </div>
    </div>

    <!-- Filters End -->
    <div></div>
    <SkeletonTableLoading v-if="loading" />
    <div v-if="!loading">
      <!-- <span v-if="salesReports">
                        <b>Toplam hasta sayısı: {{ patientsCount }}</b>
                      </span> -->

      <span style="font-weight : bold">
        Toplam işlem sayısı:
        {{ pagesCount == 1 ? salesReports.length : patientsCount }}
      </span>

      <div
        style="display:flex ; flex-direction:row ; gap: 12px;align-items: center; "
      >
        <md-button
          style="width:230px"
          class="md-raised md-primary  btn btn-full btn-mg"
          v-if="roleId == 6 || roleId == 1"
          @click="downloadExcelAll"
        >
          <img
            v-if="fetchAllReportsNoLimitLoading"
            style="width:25px; object-fit: contain;"
            src="../../assets/loading.gif"
          />
          <span v-else>Tüm Raporları Excel İndir</span>
        </md-button>

        <md-button
          class="md-fab md-mini md-accent  hidden-mini"
          v-if="!isSocialMediaUser"
          @click="downloadExcel"
        >
          <!-- <jsonExcel
              :data="salesReports"
              :fields="exportFields"
              :name="excelFileName()"
              :stringifyLongNum="true"
            > -->
          <md-icon>download</md-icon>
          <!-- </jsonExcel> -->
        </md-button>
      </div>

      <md-table style="margin-top: 15px;" class="table appointments">
        <md-table-row>
          <md-table-head v-if="roleId != 5">Randevu Durumu</md-table-head>
          <md-table-head v-if="roleId != 5">Hasta Adı</md-table-head>
          <md-table-head>Kaynak</md-table-head>
          <md-table-head>Ülke</md-table-head>
          <md-table-head>Dil</md-table-head>
          <md-table-head>Tedavi</md-table-head>
          <md-table-head
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 150px"
            >İletişim</md-table-head
          >
          <md-table-head
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 150px"
            >İletişim URL</md-table-head
          >
          <md-table-head
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 150px"
            >gClick ID</md-table-head
          >
          <md-table-head
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 150px"
            >Adversiting ID</md-table-head
          >
          <md-table-head>Satış Temsilcisi</md-table-head>
          <md-table-head v-if="roleId != 5">Kayıt Tarihi</md-table-head>
          <md-table-head>Satış Tarihi</md-table-head>
          <md-table-head v-if="roleId != 5">Satış Tutarı</md-table-head>
          <md-table-head v-if="roleId != 5">Para Birimi</md-table-head>
          <md-table-head v-if="roleId != 5">İndirim</md-table-head>
          <md-table-head v-if="roleId != 5">Satış Dönüşüm Süresi</md-table-head>
          <md-table-head v-if="roleId != 5">Randevu Tarihi</md-table-head>
        </md-table-row>

        <md-table-row v-for="report in salesReports" :key="report.id">
          <md-table-cell v-if="roleId != 5">
            <div
              v-if="report"
              class="status"
              v-bind:class="[
                report.statusId === 1 ? 'waiting' : '',
                report.statusId === 2 ? 'done' : '',
                report.statusId === 3 ? 'cancel' : '',
                report.statusId === 4 ? 'postponed' : '',
                report.statusId === 5 ? 'not-come-bg' : '',
                report.statusId === 6 ? 'optional' : '',
                report.statusId === 7 ? 'approve' : '',
                report.statusId === 8 ? 'depositPaid' : '',
              ]"
            ></div>
            {{ report ? getAppointmentStatusName(report.statusId) : "" }}
          </md-table-cell>
          <md-table-cell v-if="roleId != 5"
            >{{ report.patient.name }}
          </md-table-cell>
          <md-table-cell>{{
            characterLimit(tableAgencieShow(report.patient.agencie))
          }}</md-table-cell>
          <md-table-cell>{{
            report.patient.country && report.patient.country.name_tr
          }}</md-table-cell>
          <md-table-cell
            >{{
              report.patient.language.name != null
                ? report.patient.language.name
                : ""
            }}
            <md-tooltip class="custom-tooltip">{{
              report.patient.language.name != null
                ? report.patient.language.name
                : ""
            }}</md-tooltip>
          </md-table-cell>
          <md-table-cell
            >{{ widthControl(report.operation.name) }}
            <md-tooltip class="custom-tooltip">{{
              report.operation.name
            }}</md-tooltip>
          </md-table-cell>
          <md-table-cell
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 100px"
            >{{ report.patient.sourceModel && report.patient.sourceModel.name }}
            <md-tooltip class="custom-tooltip">{{
              report.patient.sourceModel && report.patient.sourceModel.name
            }}</md-tooltip>
          </md-table-cell>
          <md-table-cell
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 100px"
            >{{ report.patient.source }}
            <md-tooltip class="custom-tooltip">{{
              report.patient.source
            }}</md-tooltip>
          </md-table-cell>
          <md-table-cell
            v-if="roleId != 5"
            style="overflow: hidden; max-width: 100px"
            >{{ URLGetParam(report.patient.source, "gclid") }}
            <md-tooltip class="custom-tooltip">{{
              URLGetParam(report.patient.source, "gclid")
            }}</md-tooltip>
          </md-table-cell>
          <md-table-cell>{{ report.patient.adId }} </md-table-cell>
          <md-table-cell>{{ report.user && report.user.name }} </md-table-cell>
          <md-table-cell
            >{{ formatDate(report.patient.createdAt) }}
          </md-table-cell>
          <md-table-cell v-if="roleId != 5"
            >{{ formatDate(report.createdAt) }}
          </md-table-cell>
          <md-table-cell v-if="roleId != 5"
            >{{ patientConfirmationFormsPrice(report.fileContents) }}
          </md-table-cell>
          <md-table-cell v-if="roleId != 5"
            >{{ patientConfirmationFormsPriceCurrency(report.fileContents) }}
          </md-table-cell>
          <md-table-cell v-if="roleId != 5"
            >{{ report.isDiscounted ? "Uygulandı" : "Uygulanmadı" }}
          </md-table-cell>

          <md-table-cell v-if="roleId != 5">{{
            getConvertingPeriod(report.patient.createdAt, report.createdAt)
          }}</md-table-cell>
          <md-table-cell v-if="roleId != 5">{{ report.date }}</md-table-cell>
        </md-table-row>
      </md-table>

      <div>
        <MazPagination
          v-model="currentPage"
          :page-count="pagesCount"
          @page="paginationChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { MazPagination } from "maz-ui";
import { HTTP } from "@/main-source";
import NewInput from "../../components/newInput";
import SkeletonTableLoading from "../../components/SkeletonTableLoading.vue";
// import jsonExcel from "vue-json-excel";
// import downloadexcel from "vue-json-excel";
import * as XLSX from "xlsx";


export default {
  name: "SalesReport",
  components: {
    MazPagination,
    NewInput,
    SkeletonTableLoading,
    // jsonExcel,
    // downloadexcel,
  },
  data() {
    return {
      isDisabledWatcher: false,
      loading: true,
      patients: [],
      currentPage: 1,
      patientsCount: 0,
      pagesCount: 1,
      URLQueryParams: {},
      periodName: "this month",
      startDate: new Date(),
      endDate: new Date(),
      users: [],
      agencies: [],
      countries: [],
      lanugages: [],
      operations: [],
      statuses: [],
      sources: [],
      branches: [],
      discounts: [
        {
          name: "İndirim Uygulandı",
          value: true,
        },
        {
          name: "İndirim Uygulanmadı",
          value: false,
        },
      ],
      selectedUserFilter: [],
      selectedAgencieFilter: null,
      selectedAgencieChildFilter: [],
      selectedCountryFilter: null,
      selectedLanguageFilter: null,
      selectedAppointmentStatusFilter: null,
      selectedDiscountFilter: null,
      selectedTreatmentFilter: null,
      selectedSourceFilter: null,
      selectedBranchFilter: null,
      adIdFilter: null,
      leading: JSON.parse(localStorage.getItem("leading")),
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
      isAdmin: localStorage.getItem("roleId") == 1,
      selectingAgenciesChild: false,
      salesReports: [],
      exportFields: {
        "Hasta Adı": "patient.name",
        Kaynak: "patient.agencie.name",
        Ülke: "patient.country.name_tr",
        Dil: "patient.language.name",
        Tedavi: "operation.name",
        İletişim: "patient.sourceModel.name",
        "İletişim URL": "patient.source",
        "GClick ID": {
          field: "patient.source",
          callback: (item) => {
            return item ? this.URLGetParam(item, "gclid") : null;
          },
        },
        "Satış Temsilcisi": {
          field: "user.name",
        },
        "Kayıt Tarihi": {
          field: "patient.createdAt",
          callback: (item) => {
            return item ? this.formatDate(item) : null;
          },
        },
        "Satış Tarihi": {
          field: "createdAt",
          callback: (item) => {
            return item ? this.formatDate(item) : null;
          },
        },
        "Satış Tutarı": {
          field: "fileContents",
          callback: (item) => {
            return item ? this.patientConfirmationFormsPrice(item) : null;
          },
        },
        "Para Birimi": {
          field: "fileContents",
          callback: (item) => {
            return item
              ? this.patientConfirmationFormsPriceCurrency(item)
              : null;
          },
        },
        İndirim: {
          field: "isDiscounted",
          callback: (item) => {
            return item ? "Uygulandı" : "Uygulanmadı";
          },
        },
        "Satış Dönüşüm Süresi": {
          callback: (item) => {
            return item
              ? this.getConvertingPeriod(item.patient.createdAt, item.createdAt)
              : null;
          },
        },
        "Randevu Tarihi": {
          field: "date",
          callback: (item) => {
            return item;
          },
        },
        "Randevu Durumu": {
          field: "statusId",
          callback: (item) => {
            return item ? this.getAppointmentStatusName(item) : null;
          },
        },
      },
      fetchAllReportsNoLimitLoading: false,
    };
  },
  async created() {
    this.appendQueryParams({
      convertedDateStart: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      convertedDateEnd: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    await this.getSalesReport();
    await this.fetchAgencies();
    await this.fetchLanguages();
    await this.getBranches();
    await this.getSources();
    this.fetchUsers();
    this.fetchOperations();
    this.fetchCountries();
    this.fetchStatuses();
  },
  methods: {
    async getSalesReport() {
      this.loading = true;
      const salesReport = await HTTP.get(
        `/salesReport?limit=50&offset=${this.currentPage +
          "&"}${this.serializeURLQueryParams()}`
      );

      this.salesReports = salesReport.data.rows;
      this.pagesCount = salesReport.data.numberOfPages;
      this.patientsCount = salesReport.data.count;
      this.loading = false;
    },
    async getSalesReportForExcel() {
      const resp = await HTTP.get(
        `/salesReport?limit=50000000&offset=${this.currentPage +
          "&"}${this.serializeURLQueryParams()}`
      );
      return resp?.data?.rows;
    },
    async getSalesReportForExcelAll() {
      const salesReport = await HTTP.get(
        `/salesReport?limit=50000000&offset=${this.currentPage}`
      );

      return salesReport.data.rows;
    },
    async downloadExcel() {
      // Sample data (replace this with data from your backend)

      const allData = await this.getSalesReportForExcel();

      const data =
        allData?.length > 0
          ? this.transformDataForExcel(allData)
          : [{ Header: "Empty" }];

      // Convert the data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Save the workbook
      XLSX.writeFile(wb, this.excelFileName());
    },
    async downloadExcelAll() {
      // Sample data (replace this with data from your backend)

      this.startDownload();

      const allData = await this.getSalesReportForExcelAll();

      const data =
        allData?.length > 0
          ? this.transformDataForExcel(allData)
          : [{ Header: "Empty" }];

      // Convert the data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Save the workbook
      XLSX.writeFile(wb, this.excelFileName());

      this.finishDownload();
    },
    transformDataForExcel(data) {
      return data?.map((item) => ({
        "Hasta Adı": item?.patient?.name,
        Kaynak: item?.patient?.agencie?.name,
        Ülke: item?.patient?.country.name_tr,
        Dil: item?.patient?.language?.name,
        Tedavi: item?.operation?.name,
        İletişim: item?.patient?.sourceModel?.name,
        "İletişim URL": item?.patient?.source,
        "GClick ID": item?.patient?.source
          ? this.URLGetParam(item?.patient?.source, "gclid")
          : null,
        "Satış Temsilcisi": item?.user?.name,
        "Kayıt Tarihi": item?.patient?.createdAt
          ? this.formatDate(item?.patient?.createdAt)
          : null,
        "Satış Tarihi": item?.createdAt
          ? this.formatDate(item?.createdAt)
          : null,
        "Satış Tutarı": item?.fileContents
          ? this.patientConfirmationFormsPrice(item?.fileContents)
          : null,
        "Para Birimi": item?.fileContents
          ? this.patientConfirmationFormsPriceCurrency(item?.fileContents)
          : null,
        İndirim: item?.isDiscounted ? "Uygulandı" : "Uygulanmadı",
        "Satış Dönüşüm Süresi": item
          ? this.getConvertingPeriod(item.patient.createdAt, item.createdAt)
          : null,
        "Randevu Tarihi": item?.date,
        "Randevu Durumu": item?.statusId
          ? this.getAppointmentStatusName(item?.statusId)
          : null,
      }));
    },
    async getSources() {
      const sources = await HTTP.get("/sources");
      this.sources = sources.data;
    },
    async getBranches() {
      const branches = await HTTP.get("/permissions/userDatas/getBranches");
      this.branches = branches.data;
    },
    getConvertingPeriod(patientCreatedAt, appointmentCreatedAt) {
      let patientCreate = new Date(patientCreatedAt);
      let appointmentCreate = new Date(appointmentCreatedAt);

      var a = moment([
        appointmentCreate.getFullYear(),
        appointmentCreate.getMonth(),
        appointmentCreate.getDate(),
      ]);
      var b = moment([
        patientCreate.getFullYear(),
        patientCreate.getMonth(),
        patientCreate.getDate(),
      ]); // before
      return a.diff(b, "days") + " gün";
    },
    getAppointmentStatusName(statusId) {
      let stat = this.statuses.find((z) => z.id == statusId);
      return stat ? stat.name : "";
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    paginationChanged() {
      this.getSalesReport();
    },
    filterByConvertingDate(periodName) {
      this.periodName = periodName;
      let start = moment();
      let end = moment();
      switch (this.periodName) {
        case "this week":
          this.startDate = moment().startOf("week");
          this.endDate = moment().endOf("week");
          break;
        case "this month":
          this.startDate = moment().startOf("month");
          this.endDate = moment().endOf("month");
          break;
        case "last month":
          let curr = this.startDate;
          this.startDate = moment(curr).subtract(1, "month").startOf("month");
          this.endDate = moment(curr).subtract(1, "month").endOf("month");
          // end.subtract(1, "month").endOf("month");
          break;
        case "next month":
          let currDate = this.startDate;
          this.startDate = moment(currDate).add(1,'months').date(1).toDate();
          this.endDate = moment(currDate).add(2,'months').date(0).hour(23).minute(59).second(59).toDate();
          // end.subtract(1, "month").endOf("month");
          break;
        case "special":
          start = this.startDate ?? new Date();
          end = this.endDate ?? new Date();
      }

      this.appendQueryParams({
        convertedDateStart: start.format("YYYY-MM-DD"),
        convertedDateEnd: end.format("YYYY-MM-DD"),
      });
      if (this.periodName === "all") {
        delete this.URLQueryParams.convertedDateStart;
        delete this.URLQueryParams.convertedDateEnd;
      }
      this.resetPagination();
      this.getSalesReport();
    },
    changeDay: function(option) {
      this.periodName = "today";
      var activeDate = new Date(this.startDate);
      let val = option == "next" ? 1 : -1;
      var result = new Date(activeDate.getTime() + val * 86400000);

      this.appendQueryParams({
        convertedDateStart: result,
        convertedDateEnd: result,
      });

      this.startDate = result;
      this.endDate = result;

      this.getPool();
    },
    appendQueryParams(params) {
      this.URLQueryParams = {
        ...this.URLQueryParams,
        ...params,
      };
    },
    serializeURLQueryParams() {
      return new URLSearchParams(this.URLQueryParams).toString();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    fetchUsers() {
      HTTP.get("/users/").then((result) => {
        if (this.isAdmin) {
          this.users = result.data;
        } else {
          this.users = result.data.filter(
            (user) => user.id == this.userId || this.leading.includes(user.id)
          );
        }
      });
    },
    fetchOperations() {
      HTTP.get("/operations/").then((result) => {
        this.operations = result.data.filter(
          (item) => item.status == true && item.isSalesReport == true
        );
      });
    },
    async fetchAgencies() {
      var agenciesLimitation = "";
      if (!this.isAdmin) agenciesLimitation = "?userId=" + this.userId;

      return HTTP.get("/agencies/" + agenciesLimitation).then((result) => {
        this.agencies = result.data;
      });
    },
    fetchCountries() {
      HTTP.get("/countries").then((result) => {
        this.countries = result.data;
      });
    },
    fetchLanguages() {
      HTTP.get("/languages").then((result) => {
        this.languages = result.data;
      });
    },
    fetchStatuses() {
      HTTP.get("/status").then((result) => {
        this.statuses = result.data;
      });
    },
    // copied from List.vue //
    clearFilters: function() {
      this.selectedUserFilter = null;
      this.selectedAgencieFilter = null;
      this.selectedCountryFilter = null;
      this.selectedAppointmentStatusFilter = null;
      this.selectedTreatmentFilter = null;
      this.selectedSourceFilter = null;
      this.selectedDiscountFilter = null;
      this.selectedAgencieChildFilter = [];
      this.selectedAgencieChildFilterResult = [];
      this.selectingAgenciesChild = false;
      this.isDisabledWatcher = false;

      this.currentPage = 1;
      this.getSalesReport();
    },
    childIsDeletedControl(val) {
      return val.filter((item) => item.isDeleted == 0 && item);
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    URLGetParam(url, param) {
      let link = url;
      let linkParse = link?.replace("?", "&");
      const urlParams = new URLSearchParams(linkParse);
      if (urlParams) {
        return urlParams.get(param);
      } else {
        return "";
      }
    },
    patientConfirmationFormsPrice(val) {
      if (val?.length > 0) {
        const contentParse = JSON.parse(val[val?.length - 1]?.content);
        const incPriceContent = contentParse?.incPrice?.content;

        if (typeof incPriceContent === 'string') {
          const numericValue = incPriceContent?.match(/\d+/);
          if (numericValue) {
            return parseInt(numericValue[0], 10);
          }
        } else if (!isNaN(incPriceContent)) {
          return incPriceContent;
        }
      }
      return "";
    },
    patientConfirmationFormsPriceCurrency(val) {
      if (val?.length > 0) {
        const contentParse = JSON.parse(val[val?.length - 1]?.content);
        return contentParse?.priceCurrency?.content;
      }
      return "";
    },
    widthControl(string) {
      if (string?.length > 1) {
        return string?.substring(0, 10) + "...";
      } else {
        return "";
      }
    },
    excelFileName: function() {
      let fileName = "Raporlar " + this.formatDate(this.startDate);
      if (this.periodName != "today")
        fileName += " - " + this.formatDate(this.endDate);
      return fileName + ".xlsx";
    },
    async fetchAllReportsNoLimit() {
      const response = await HTTP.get(
        `/salesReport/convertExcel?all=true&${this.serializeURLQueryParams()}`
      );
      return response.data;
    },
    startDownload() {
      this.fetchAllReportsNoLimitLoading = true;
    },
    finishDownload() {
      this.fetchAllReportsNoLimitLoading = false;
    },
    tableAgencieShow(val) {
      let parent;
      if (val?.parentId) {
        parent = this.agencies?.find((a) => a.id === val.parentId);
      }

      if (val) {
        if (parent) {
          return `${parent.name}/${val.name}`;
        } else {
          return val.name;
        }
      } else {
        return "";
      }
    },
    characterLimit(value) {
      if (value?.length > 25) {
        return value.substring(0, 26) + "...";
      } else {
        return value;
      }
    },
  },
  watch: {
    startDate() {
      this.filterByConvertingDate("special");
    },
    endDate() {
      this.filterByConvertingDate("special");
    },
    selectedUserFilter(val) {
      if (val != null && val.length > 0) {
        let selectedUsers = val.map((z) => z.id);
        this.appendQueryParams({ user: selectedUsers });
      } else {
        delete this.URLQueryParams.user;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedAgencieFilter(val) {
      // get Ids
      let parentIds = [];
      for (let i in val) {
        parentIds.push(val[i].id);
      }

      // CHILD LAYOUT CONTROL - START
      let control = [];
      val?.filter(
        (item) => item?.childAgencies.length > 0 && control.push(true)
      );

      if (JSON.stringify(control).match(true)) {
        this.selectingAgenciesChild = true;
      } else {
        this.selectingAgenciesChild = false;
      }
      // CHILD LAYOUT CONTROL - END

      let sendValue = this.selectedAgencieChildFilter;

      this.URLQueryParams.agencie = [];
      if (sendValue?.length > 0 && val?.length > 0) {
        let ids = [];
        for (let i in sendValue) {
          for (let j in sendValue[i]) {
            ids.push(sendValue[i][j].id);
          }
        }
        for (let i in ids) {
          this.URLQueryParams.agencie.push(ids[i]);
        }
        let newData = this.URLQueryParams.agencie.filter(
          (a) => a !== undefined
        );

        let hasChildAgencies = [];
        let someths = val?.filter(
          (parent) => parent?.childAgencies?.length > 0
        );

        someths.map((pC) => {
          let childs = pC.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        let leftParents = val.filter((p) => !hasChildAgencies.includes(p.id));

        leftParents?.map((z) => z.id).forEach((y) => newData.push(y));

        let childs = [];
        leftParents.map((v) => childs.push(v.childAgencies?.map((z) => z.id)));
        let flattedChild = childs.flat();

        flattedChild.forEach((f) => newData?.push(f));

        this.URLQueryParams.agencie = newData;

        this.currentPage = 1;
        this.getSalesReport();
      } else if (val != null && val?.length > 0) {
        let childs = [];
        val.map((v) => childs.push(v.childAgencies?.map((z) => z.id)));
        let flattedChild = childs.flat();

        this.appendQueryParams({ agencie: val.map((z) => z.id) });
        flattedChild.forEach((f) => this.URLQueryParams.agencie?.push(f));

        this.currentPage = 1;
        this.getSalesReport();
      } else {
        this.selectedAgencieFilter = null;
        this.selectedAgencieChildFilter = [];
        delete this.URLQueryParams.agencie;

        this.currentPage = 1;
        this.getSalesReport();
      }
    },
    selectedAgencieChildFilter(val) {
      let ids = [];
      for (let i in val) {
        for (let j in val[i]) {
          ids.push(val[i][j].id);
        }
      }

      if (ids?.length > 0) {
        this.isDisabledWatcher = true;
      } else {
        this.isDisabledWatcher = false;
      }

      let sendValue = this.selectedAgencieFilter;

      // Parent Ids
      let parentIds = [];
      for (let i in sendValue) {
        parentIds.push(sendValue[i].id);
      }

      this.URLQueryParams.agencie = [];

      if (ids?.length > 0 && sendValue?.length > 0) {
        for (let i in ids) {
          this.URLQueryParams.agencie.push(ids[i]);
        }
        let newData = this.URLQueryParams.agencie.filter(
          (a) => a !== undefined
        );

        let hasChildAgencies = [];
        let someths = sendValue.filter(
          (parent) => parent?.childAgencies?.length > 0
        );

        someths.map((pC) => {
          let childs = pC.childAgencies.map((z) => z.id);

          childs.map((c) => {
            if (ids.includes(c)) hasChildAgencies.push(pC.id);
          });
        });

        let leftParents = sendValue.filter(
          (p) => !hasChildAgencies.includes(p.id)
        );

        let childs = [];
        leftParents.map((v) => childs.push(v.childAgencies?.map((z) => z.id)));
        let flattedChild = childs.flat();

        leftParents?.map((z) => z.id).forEach((y) => newData.push(y));
        flattedChild.forEach((f) => newData.push(f));

        this.URLQueryParams.agencie = newData;

        this.currentPage = 1;
        this.getSalesReport();
      } else if (sendValue?.length > 0) {
        let childs = [];
        sendValue.map((v) => childs.push(v.childAgencies?.map((z) => z.id)));
        let flattedChild = childs.flat();

        this.URLQueryParams.agencie = sendValue.map((z) => z.id);
        flattedChild.forEach((f) => this.URLQueryParams.agencie?.push(f));

        this.currentPage = 1;
        this.getSalesReport();
      } else {
        if (!this.isAdmin) {
          this.URLQueryParams.agencie = this.agencies.map((z) => z.id);
          this.currentPage = 1;
          this.getSalesReport();
        } else {
          this.URLQueryParams.agencie = [];
          this.currentPage = 1;
          this.getSalesReport();
        }
      }
    },
    selectedCountryFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ country: val.map((z) => z.code) });
      } else {
        delete this.URLQueryParams.country;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedLanguageFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ language: val.map((z) => z.id) });
      } else {
        delete this.URLQueryParams.language;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedAppointmentStatusFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ appointmentStatus: val.map((z) => z.id) });
      } else {
        delete this.URLQueryParams.appointmentStatus;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedDiscountFilter(val) {
      if (val?.value || val?.value == false) {
        this.appendQueryParams({ isDiscounted: val?.value });
      } else {
        delete this.URLQueryParams.isDiscounted;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedSourceFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ source: val.map((z) => z.id) });
      } else {
        delete this.URLQueryParams.source;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedBranchFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ source: val.map((z) => z.id) });
      } else {
        delete this.URLQueryParams.source;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    selectedTreatmentFilter(val) {
      if (val != null && val.length > 0) {
        this.appendQueryParams({ operation: val.map((z) => z.id) });
      } else {
        delete this.URLQueryParams.operation;
      }
      this.currentPage = 1;
      this.getSalesReport();
    },
    adIdFilter(val) {
      if (val != null && val.length > 5) {
        this.appendQueryParams({ adId: val });
        this.currentPage = 1;
        this.getSalesReport();
      } else if (val?.length == 0) {
        this.getSalesReport();
        delete this.URLQueryParams.adId;
      } else {
        delete this.URLQueryParams.adId;
      }
    },
  },
};
</script>

<style scoped>
.graph-frame {
  display: flex;
  flex-wrap: wrap;
}

.graph-container {
  width: 500px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  transform: scale(0.85);
  margin-left: -35px;
  margin-bottom: 15px;
  height: 300px;
}
</style>

<style lang="scss" scoped>
th.md-table-head {
  text-transform: capitalize;
}

.quick-action .little-btn {
  margin-right: 10px;
  margin-bottom: 32px;
}

#filters {
  margin-bottom: 32px;
}

.custom-tooltip {
  font-size: 14px;
}

.row-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.layout-1 {
  display: flex;
  flex-wrap: wrap;
}
</style>
