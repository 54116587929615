<template>
  <div>
    <h1>{{ title }}</h1>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <userRoles></userRoles>
      </div>
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <branches></branches>
      </div>
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <operations></operations>
      </div>
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <locations></locations>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <sources></sources>
        <languages></languages>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <daylimit></daylimit>
        <CustomLimits></CustomLimits>
        <custom-discount-limits></custom-discount-limits>
        <patientStatus></patientStatus>
        <fileTypes></fileTypes>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <whens></whens>
        <innerfirms></innerfirms>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <roomtypes></roomtypes>
        <appointmentStatuses></appointmentStatuses>
      </div>
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <airports></airports>
      </div>
      <div class="md-layout-item md-size-100 md-xsmall-size-100">
        <airlines></airlines>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <hotels></hotels>
      </div>
      <div class="md-layout-item md-size-50 md-xsmall-size-100">
        <activity-calendar></activity-calendar>
      </div>
    </div>
  </div>
</template>

<script>
// import { HTTP } from "@/main-source";
import operations from "@/components/settings/operations";
import locations from "@/components/settings/locations";
import sources from "@/components/settings/sources";
import roomtypes from "@/components/settings/roomtypes";
import airports from "@/components/settings/airports";
import airlines from "@/components/settings/airlines";
import daylimit from "@/components/settings/limitForDay";
import activityCalendar from "@/components/settings/activityCalendar";
import hotels from "@/components/settings/hotels";
import patientStatus from "@/components/settings/patientStatus";
import fileTypes from "@/components/settings/fileTypes";
import whens from "@/components/settings/whens";
import innerfirms from "@/components/settings/innerFirms";
import languages from "@/components/settings/languages";
import branches from "@/components/settings/branches";
import appointmentStatuses from "@/components/settings/appointmentStatuses";
import CustomLimits from "../components/settings/customLimits.vue";
import CustomDiscountLimits from "../components/settings/customDiscountLimits.vue";
import ActivityCalendar from '../components/settings/activityCalendar.vue';
import userRoles from '../components/settings/userRoles';

export default {
  name: "Settings",
  data() {
    return {
      title: "Ayarlar",
    };
  },
  components: {
    operations,
    locations,
    sources,
    patientStatus,
    fileTypes,
    whens,
    innerfirms,
    languages,
    branches,
    roomtypes,
    airports,
    hotels,
    daylimit,
    activityCalendar,
    CustomLimits,
    CustomDiscountLimits,
    ActivityCalendar,
    airlines,
    appointmentStatuses,
    userRoles
  },
};
</script>

<style lang="scss" scoped>
.test {
  color: #fff;
}
</style>