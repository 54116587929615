  <template>
      <div>
      
        <div class="md-layout details">
          <BigLoading v-if="loading"/>
            <div v-if="!loading" class="md-layout-item wrapper md-size-100">
              
              <md-button
                class="md-fab md-mini md-primary editButton"
                v-on:click="editable"
                v-if="isDisabled"
              >
                <md-icon>edit</md-icon>
              </md-button>
              <md-button 
              class="md-fab md-mini md-danger editButton"
              v-on:click="editInformation"
                v-if="!isDisabled">
                <md-icon>save</md-icon>
              </md-button>
              
              <span class="md-title">Takım</span>
              <md-field>
                <label>Arayınız...</label>
                <md-input v-model="usersWithoutDoctorsSearchInput"></md-input>
                <md-icon>search</md-icon>
              </md-field>
              <br/>
              <md-checkbox
              v-model="selectAllUsers"
              v-bind:disabled="isDisabled"
              > Tümünü Seç 
              </md-checkbox>
              
              <md-checkbox
                v-for="user in searchFilter(usersWithoutDoctors,usersWithoutDoctorsSearchInput)"
                v-bind:key="user.id"
                v-bind:value="user.id"
                v-model="checkedUsers"
                v-bind:disabled="isDisabled"
                >{{ user.name }}</md-checkbox
              >
            </div>
      </div>
    </div>
  </template>
    
  <script>
  import { HTTP } from "../../../main-source";
  import BigLoading from "../../../components/bigLoading/index.vue";
  
  export default {
    name: "Profile",
    data() {
        return {
            id: this.$route.params.id,
            selectAllUsers: false,
            profile: [],
            users: [],
            checkedUsers: [],
            userDoctors: [],
            isDisabled: true,
            usersWithoutDoctorsSearchInput: "",
            loading: true
        };
    },
    computed: {
        usersWithoutDoctors: function () {
            return this.users.filter(z => z.roleId != 2);
        },
    },
    created() {
        HTTP.get("/users/" + this.id + "/getLeadingUsers").then(result => {
            this.profile = result.data[0];
            this.checkedUsers = this.profile.leading ? JSON.parse(this.profile.leading) : [];
        });
        HTTP.get("/users/getUsersName").then(result => {
            this.users = result.data;
            this.loading = false
        });
    },
    methods: {
        editable: function () {
            this.isDisabled = !this.isDisabled;
        },
        editInformation: function () {
            // 
            this.profile.userDoctors = JSON.stringify(this.userDoctors);
            this.profile.leading = JSON.stringify(this.checkedUsers);
            HTTP.post("/users/" + this.profile.id + "/updateWithUserDoctors", {
                userDoctors: this.profile.userDoctors,
                leading: this.profile.leading
            }).then(result => {
                if (result.status == 204) {
                    this.editable();
                }
            });
        },
        searchFilter: function (data, search) {
            let searchValue = search.toLowerCase();
            return data.filter(item => item.name.toLowerCase().match(searchValue) ? item : null);
        }
    },
    watch: {
        "selectAllUsers": function () {
            if (this.selectAllUsers) {
                this.checkedUsers = [];
                this.users.forEach(user => {
                    this.checkedUsers.push(user.id);
                });
            }
            else {
                this.checkedUsers = [];
            }
        },
    },
    components: { BigLoading }
};
  </script>
  
  <style lang="scss">
  @import "../../../assets/scss/colors.scss";
  
  .md-dialog-content {
    color: #f00;
  }
  
  .error {
    display: block !important;
    color: #f00;
    transform: translate3d(0, -8px, 0);
    height: -19px;
    position: inherit;
    font-size: 1rem;
  
    border-bottom: inset;
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  
  
  .wrap {
    margin: 0px -15px;
  }
  .item {
    min-height: 200px;
    margin: 15px;
    &:last-child {
      margin-right: 5px;
    }
  }
  .profile.wrapper {
    margin-top: 75px;
  }
  .wrapper {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
  .profile {
    text-align: center;
    img {
      width: 170px !important;
      height: 170px !important;
      border-radius: 50%;
      margin-top: -90px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      margin-bottom: 20px;
    }
    span {
      display: block;
    }
    .title {
      font-weight: bold;
    }
    .md-layout {
      text-align: left;
    }
    .icon {
      padding: 0px 10px;
      margin-right: 10px;
    }
    .main {
      margin: 15px 0px;
    }
    .secondary {
      margin: 30px 0px 15px 0px;
      .md-layout {
        margin-top: 10px;
        color: $dark;
      }
    }
  }
  .md-icon-button.delete {
    background: $red !important;
    .icon {
      color: $white !important;
    }
  }
  .editButton {
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }
  .md-scrollbar {
    max-height: 80vh !important;
    overflow: auto;
  }
  .editOkButton {
    width: 100%;
    margin: 0 !important;
  }
  .vue-phone-number-input {
    width: 100%;
  }
  </style>
  
  