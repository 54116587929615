<template>
  <div>
    <Topbar @updated="refreshPatientData" :patient="patient" :patId="patientsId" :parent="this"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>

    <md-dialog :md-active.sync="eventStatusModal" class="dialog">
      <md-content class="statuses" v-if="eventStatusModal">
        <h3>Aktivite Durumu Düzenle</h3>
        Hastanın {{ selectedEvent.date }} tarihli <br />aktivite durumu
        güncelleniyor.
        <md-field>
          <md-select v-model="selectedEvent.status" placeholder="Durum">
            <md-option v-for="status in statuses" v-bind:value="status.id" v-bind:key="status.id">{{ status.name
            }}</md-option>
          </md-select>
        </md-field>
        <md-field>
          <md-input v-model="statusChangeNote" placeholder="Not" />
        </md-field>
        <p style="color: red" v-if="statusChangeNoteRequired">
          * İptal ve Erteleme dumurumlarda not girilmesi zorunludur.
        </p>
        <md-button class="md-raised md-primary updateStatus" v-on:click="setEventStatus">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <div class="md-layout details">
      <div class="md-layout-item wrapper md-size-100">
        <div class="md-layout-item">
          <span class="md-title title">Aktiviteler</span>
          <!-- <router-link
            :to="{
            name:'createAppointment',
            query:{
              id: patientsId
            }
          }"
          >
            <md-button class="md-fab md-mini md-primary addButton">
              <md-icon>add</md-icon>
            </md-button>
          </router-link> -->
        </div>
        <md-table class="table appointments" v-model="appointments">
          <md-table-row>
            <md-table-head>Durum</md-table-head>
            <md-table-head>İşlem</md-table-head>
            <md-table-head>Not</md-table-head>
            <md-table-head>Tarih</md-table-head>
            <md-table-head>Konum</md-table-head>
            <md-table-head>Katılımcılar</md-table-head>
            <md-table-head>Personel</md-table-head>
            <md-table-head>Hatırlat</md-table-head>
            <md-table-head>Düzenle / Sil</md-table-head>
          </md-table-row>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Durum" v-on:click="eventDetails(item.id)">
              <div class="status" v-bind:class="[
                item.status.id === 1 ? 'waiting' : '',
                item.status.id === 2 ? 'done' : '',
                item.status.id === 3 ? 'cancel' : '',
                item.status.id === 4 ? 'postponed' : '',
                item.status.id === 5 ? 'not-come-bg' : '',
                item.status.id === 6 ? 'optional' : '',
                item.status.id === 7 ? 'approve' : '',
                item.status.id === 8 ? 'depositPaid' : '',
              ]"></div>
              {{ item.status.name }}
            </md-table-cell>
            <md-table-cell v-on:click="eventDetails(item.id)" md-label="İşlem">{{ item.operation ? item.operation.name :
              "" }}</md-table-cell>
            <md-table-cell v-on:click="eventDetails(item.id)" md-label="Not">{{
              item.note
            }}</md-table-cell>
            <md-table-cell v-on:click="eventDetails(item.id)" md-label="Tarih">
              {{ formatDate(item.date) }}
              - {{ item.time ? item.time.substring(0, 5) : "" }}
            </md-table-cell>
            <md-table-cell v-on:click="eventDetails(item.id)" md-label="Katılımcılar">
              <span class="participants" v-for="participants in item.AppointmentsParticipants"
                v-bind:key="participants.id">{{ participants.name }}</span>
            </md-table-cell>

            <md-table-cell v-on:click="eventDetails(item.id)" md-label="Personel">{{ item.user.name }}</md-table-cell>
            <md-table-cell v-on:click="eventDetails(item.id)" md-label="Hatırlat">
              <md-checkbox v-model="item.notify_me"></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Düzenle / Sil">
              <div class="">
                <router-link :to="{
                  name: 'updateActivity',
                  params: {
                    activityId: item.id,
                  },
                }">
                  <md-button class="md-succes">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
              </div>
              <div class="">
                <md-button v-if="roleId == 1" class="md-accent" v-on:click="deleteActivities(item.id)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <h5>Seçili Güne Ait Operasyonlar</h5>
        <md-content class="timeSelect">
          <md-table class="time-table">
            <md-table-row v-for="(dayAppointment, index) in dayOperations" v-bind:key="index">
              <md-table-cell md-label="Saat" class="selectTime">{{
                dayAppointment.time.substr(0, 5)
              }}</md-table-cell>
              <md-table-cell md-label="Operasyon">{{
                dayAppointment.operation.name
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-content>

        <div v-for="appointment in appointments" v-bind:key="appointment.id">
          {{ appointment.name }}
        </div>
        <md-dialog-confirm :md-active.sync="deleteModal" md-title="Randevuyu silmek istediğinize emin misiniz?"
          md-content="Bu işlem geri alınamaz." md-confirm-text="Sil" md-cancel-text="İptal Et" @md-confirm="onConfirm" />
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100 wrapper" ref="form">
        <div class="md-layout md-gutter">
          <span class="md-title title">Yeni Aktivite</span>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <div>
              <span class="md-title">İşlem Tipi</span>

              <md-field>
                <v-select v-model="selectedOperation" placeholder="İşlem" :options="activityOperations" label="name"
                  class="md-menu">
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
              </md-field>

              <span class="md-error" v-if="this.isValid.operation === false">İşlem seçmelisiniz</span>

              <md-field>
                <label>Katılımcılar</label>
                <md-select v-model="activity.participants" name="participants" id="participants"
                  placeholder="Katılımcılar">
                  <md-option v-for="participant in participants" v-bind:value="participant.id"
                    v-bind:key="participant.id">{{ participant.name }}</md-option>
                </md-select>
              </md-field>
              <span class="md-error" v-if="this.isValid.participants === false">Katılımcı seçmelisiniz</span>

              <md-field>
                <label>Konum</label>
                <md-select v-model="activity.locationId" name="locations" id="locations" placeholder="Konum">
                  <md-option v-for="location in locations" v-bind:value="location.id" v-bind:key="location.id">{{
                    location.name }}</md-option>
                </md-select>
              </md-field>
              <span class="md-error" v-if="this.isValid.location === false">Konum seçmelisiniz</span>
            </div>
          </div>
          <div class="md-layout-item">
            <div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <span class="md-title">Tarih & Saat</span>
                  <md-datepicker v-model="date" md-immediately :md-disabled-dates="isDateDisabled">
                    <label>Aktivite Tarihi</label>
                  </md-datepicker>
                  <span class="md-error" v-if="this.isValid.date === false">Randevu tarihi seçmelisiniz</span>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <span class="md-title">Aktivite Saati</span>
                  <md-field>
                    <vue-timepicker format="HH:mm" :hour-range="activityTimes" :minute-interval="timeDuration"
                      placeholder="SS:dd" v-model="time" close-on-complete hide-disabled-hours></vue-timepicker>
                  </md-field>
                  <span class="md-error" v-if="this.isValid.time === false">Randevi saati seçmelisiniz</span>
                </div>
              </div>
              <div>
                <span class="md-title">Aktivite Notu</span>
                <md-field>
                  <md-textarea v-model="activity.note" placeholder="Aktivite notu"></md-textarea>
                </md-field>
              </div>

              <span class="md-error" v-if="isDayClosed">
                Seçili tarih/saat kapalı olarak işaretlenmiş. Lütfen başka bir
                tarih/saat seçiniz.</span><br />
            </div>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item text-right">
            <md-checkbox v-model="activity.notify_me">Hatırlat</md-checkbox>
            <input id="datetimepicker" v-model="activity.notificationDate" v-if="activity.notify_me"
              type="datetime-local" :min="activity.notify_me_date_min" placeholder="Lütfen Tarih Giriniz" />
          </div>
          <md-button class="md-raised md-primary btn-right" v-if="!isDayClosed" v-on:click="createActivity">Aktivite
            Oluştur</md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/patients/potantials/topbar";
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import { patientProfileMixin } from "@/mixins/patientProfileMixin";
import moment from "moment";
//import router from "../router";

export default {
  name: "potantial.patient.activities",
  mixins: [patientProfileMixin],
  data() {
    return {
      clickable: true,
      dayOperations: null,
      deleteModal: false,
      activityOperations: [],
      appointments: {},
      time: null,
      userId: localStorage.getItem("userId"),
      selectedEventId: null,
      activity: {
        operationId: "",
        patientId: this.$route.params.id,
        isActivity: true,
        duration: 30,
        tags: "",
        date: null,
        time: null,
        notify_me: false,
        notificationDate: moment().toDate(),
        notify_me_date_min: "",
        userId: null,

      },
      startTime: 8,
      endTime: 20,
      activityTimes: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      timeDuration: 5,
      date: null,
      tag: "",
      tags: [],
      selectedOperation: null,
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: "",
        },
        statusId: "",
        location: {
          name: "",
        },
        operation: {
          name: "",
        },
      },
      eventStatusModal: false,
      selectedEvent: null,
      appointmentDetails: false,
      created: false,
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      participants: [],
      locations: [],
      isDayClosed: false,
      isValid: {
        // name: null,
        patient: null,
        location: null,
        operation: null,
        participants: null,
        date: null,
        time: null,
      },
      statusChangeNote: "",
      roleId: localStorage.getItem("roleId"),
    };
  },
  components: {
    Navigation,
    Topbar,
    VueTimepicker,
  },
  created() {
    this.getCurrentDate()
    this.getAppointmentsByPatients();
    this.getActivityCalendarSettings();

    HTTP.get("/operations/").then((result) => {
      this.activityOperations = result.data.filter(
        (operation) => operation.status == true && operation.isActivity == true
      );
    });

    HTTP.get("/users/").then((result) => {
      this.participants = result.data.filter(
        (user) =>
          user.status == true &&
          user.role.name == "Doctor" &&
          this.userDoctors.includes(user.id)
      );
    });
    HTTP.get("/locations/").then((result) => {
      this.locations = result.data.filter(
        (location) => location.status == true
      );
    });
  },
  computed: {
    statusChangeNoteRequired: function () {
      return (
        [3, 4].includes(this.selectedEvent.status.id) &&
        this.statusChangeNote == ""
      );
    },
  },
  methods: {
    deleteActivities: function (id) {
      this.selectedEventId = id;
      this.deleteModal = true;
      //console.log(id);
    },
    onConfirm: function () {
      HTTP.delete("/appointments/?ids=" + this.selectedEventId).then(
        (result) => {
          if (result.status == 204) {
            this.getAppointmentsByPatients();
          }
        }
      );
    },
    checkHoliday: function () {
      this.isDayClosed = false;

      if (!this.date) {
        return;
      }

      let date = moment(this.date);
      let closedDays = [0];
      if (this.activity.participants.length > 0) {
        this.activity.participants.forEach((doctorId) => {
          var doctor = this.participants.find((z) => z.id == doctorId);
          if (doctor.closedDays) {
            closedDays = closedDays.concat(JSON.parse(doctor.closedDays));
          }
        });
      }

      if (closedDays.includes(date.day())) {
        this.isDayClosed = true;
        return;
      }
      let params = {
        date: moment(this.date).format("YYYY-MM-DD"),
        time: this.activity.time,
        branchId: null,
        operationId: null,
        doctorId: this.activity.participants,
        duration: "0",
        isSurgery: false,
        isNotSurgery: false,
      };
      if (params.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkHoliday?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (result.data.closedDay) {
          this.isDayClosed = true;
        }
      });
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    isDateDisabled: function (date) {
      return moment(date).isBefore(moment().format("YYYY-MM-DD"));
    },
    getAppointmentsByPatients: function () {
      HTTP.get(
        `appointments/bypatient/${this.patientsId}?type=activities`
      ).then((result) => {
        // console.log(result.data)
        this.appointments = [];
        this.appointments = result.data;
      });
    },
    getAppointmentsByDates: function () {
      let date = moment(this.date).format("YYYY-MM-DD");
      HTTP.get(`appointments?startDate=${date}&endDate=${date}`).then(
        (result) => {
          //console.log(result.data)
          this.dayOperations = result.data;
          console.log(this.dayOperations);
          //this.appointments = [];
          //this.appointments = result.data;
        }
      );
    },
    eventDetails: function (id) {
      this.selectedEvent = this.appointments.filter((item) => item.id == id)[0];
      this.eventStatusModal = true;
    },
    setEventStatus: function () {
      if (this.statusChangeNoteRequired) return;
      // console.log(this.selectedEvent.id + "-" + this.selectedEvent.status);
      // console.log(this.selectedEvent);
      HTTP.patch("/appointments/" + this.selectedEvent.id + "/status", {
        statusId: this.selectedEvent.status,
        statusNote: this.statusChangeNote,
      }).then((result) => {
        if (result.status == 204) {
          this.eventStatusModal = false;
          this.getAppointmentsByPatients();
        }
      });
    },
    is_timeString(str) {
      var regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
      if (regexp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    createActivity: async function () {
      this.clickable = false;
      if (this.selectedOperation == null) {
        this.isValid.operation = false;
        return;
      } else {
        this.isValid.operation = true;
      }

      if (this.activity.participants.length == 0) {
        this.isValid.participants = false;
        return;
      } else {
        this.isValid.participants = true;
      }

      if (this.date == null) {
        this.isValid.date = false;
        return;
      } else {
        this.isValid.date = true;
      }

      if (
        this.activity.time == undefined ||
        this.is_timeString(this.activity.time) == false
      ) {
        this.isValid.time = false;
        return;
      } else {
        this.isValid.time = true;
      }

      if (this.activity.locationId == null) {
        this.isValid.location = false;
        return;
      } else {
        this.isValid.location = true;
      }

      let start = new Date(this.date);
      let startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);
      this.activity.date = startText;
      if (this.tags) {
        let i = 0;
        this.tags.forEach((tag) => {
          if (i != 0) {
            this.activity.tags += ", ";
          }
          this.activity.tags += tag.text;
          i++;
        });
      }

      // this.activity.userId = this.patient.userId ? this.patient.userId : this.userId;

      this.activity.userId = parseInt(this.userId);

      if (this.activity.date) {
        try {
          this.activity.duration = this.selectedOperation.duration;
          await HTTP.post("/appointments/", this.activity);
          this.getAppointmentsByPatients();
          this.$swal({
            title: "",
            text: "Aktivite Başarıyla Oluşturuldu.",
            icon: "success",
          });
          this.clickable = true;
          this.activity.time = undefined;
          this.activity.locationId = undefined;
          this.selectedOperation = null;
          this.activity.note = null;
          this.tag = null;
          this.activity.notify_me = null;
          this.date = null;
          this.activity.participants = undefined;
        } catch (error) {
          const {
            response: {
              data: { message = null },
            },
          } = error;
          if (message === "notAvailable") {
            this.$swal({
              icon: "error",
              text:
                "Seçmiş olduğunuz gün ve saatte başka bir randevu bulunmaktadır!",
              showConfirmButton: true,
            });
          }
        }
      }
    },
    getActivityCalendarSettings: async function () {
      const { data: settings } = await HTTP.get(
        "/settings/default/activityCalendar"
      );
      this.startTime =
        settings?.find(({ name }) => name === "startTime")?.transaction_limit ||
        8;
      this.endTime =
        (settings?.find(({ name }) => name === "endTime")?.transaction_limit ||
          21) - 1;
      const hours = [];
      for (let hour = this.startTime; hour <= this.endTime; hour++) {
        hours.push(hour);
      }
      this.activityTimes = hours;
    },
    getOperationDuration(selectedOperationId) {
      const operation = this.activityOperations.find(
        (operation) => operation.id === selectedOperationId
      );
      this.timeDuration = operation?.duration || 5;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = this.addLeadingZero(date.getMonth() + 1);
      const day = this.addLeadingZero(date.getDate());
      const hours = this.addLeadingZero(date.getHours());
      const minutes = this.addLeadingZero(date.getMinutes());

      this.activity.notify_me_date_min = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.activity.notificationDate = `${year}-${month}-${day}T12:00`;
    },
    addLeadingZero(number) {
      return number < 10 ? `0${number}` : number;
    },
  },
  watch: {
    selectedOperation: function () {
      this.activity.operationId = this.selectedOperation.id;
      this.getOperationDuration(this.selectedOperation.id);
    },
    "activity.participants": function () {
      this.checkHoliday();
    },
    date: function () {
      this.checkHoliday();
      this.getAppointmentsByDates();
    },
    time: function () {
      this.activity.time = this.time.HH + ":" + this.time.mm;
      this.checkHoliday();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.participants {
  display: block;

  span {
    display: inline-block;
  }
}

.addButton {
  float: right;
}

.table {
  width: 100%;
  margin-top: 0px;
}

.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}

.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  max-width: 80vw;

  .md-dialog-title {
    padding: 0px;
  }
}

.appointments {
  .md-table-row {
    cursor: pointer;
  }
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.pad {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;

    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}

.vue-tags-input {
  max-width: none;
  margin-bottom: 20px;
}

.ti-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
</style>
