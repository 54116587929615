<template>
  <div>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Reports",
  data() {
    return {
      title: "Raporlar"
    };
  }
};
</script>
