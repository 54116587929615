<template>
    <div class="container" 
        :style="{
            width : width ? width+'px' : '200px' , 
            height: height ? height+'px' : '200px'
        }">
	    <div class="skeleton skeleton-box skeleton-swoosh"></div>
    </div>
</template>
<script>
export default {
    props : ["width","height"]
}
</script>
<style lang="scss" scoped>
$black: #444;
$white: #fff;

$primary-color: #dbdbdb;
$primary-color-dark: darken( $primary-color, 10% );
$primary-color-tint: lighten( $primary-color, 15% );


	
.skeleton {
	position: relative;
	border-radius: 6px;
	background-color: #f2f3f3;
	overflow: hidden;
		
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: inherit;
		width: 100%;
		transform: translateX(0) scaleX(0);
		transform-origin: left center;
		background-image: linear-gradient(to left, rgba($primary-color, 0), rgba($primary-color, 1) 40%, rgba($primary-color, 1) 60%, rgba($primary-color, 0));
	}
	
	
	&-box {
		height: 100%;
	}
	
	
	&-swoosh {
		&::before {
			animation: skelSwoosh 1.1s infinite ease-in;
		}
	}
}

@keyframes skelSwoosh {
	0%, 10% {
		transform: translateX(-30%) scaleX(0.3);
	}
	100% {
		transform: translateX(100%) scaleX(1);
	}
}


</style>