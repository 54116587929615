<template>
  <div
    :style="{
      borderColor: fileStatusColor(),
    }"
    class="main-container"
  >
    <input
      style="display : none"
      ref="file"
      type="file"
      @change="onFileChange"
      :accept="accept"
    />
    <div v-if="!loading && value.isSuccess == null">
      <div class="column-container">
        <span
          style="margin-bottom: 10px; font-weight: 100; color : rgb(47, 47, 47)"
        >
          {{ placeholder }}
        </span>
        <button class="main-button" @click="buttonClick">Dosya Yükle</button>
      </div>
    </div>
    <div v-if="!loading && value.isSuccess == false">
      hata
      <button class="main-button" @click="buttonClick">Dosya Yükle</button>
    </div>
    <div class="column-container" v-if="!loading && value.isSuccess == true">
      <!-- <div style="display: flex ; justify-content: space-between;width:250px">
        <div>
          <md-icon class="icon">visibility</md-icon>
        </div>
        <div>
          <md-icon style="color: red" class="icon">delete</md-icon>
        </div>
      </div> -->
      <img class="uploaded-image" :src="value.file.base64" />
      <span style="font-weight: 100; margin-bottom: 10px; ">{{
        value.file.name
      }}</span>
      <button class="main-button" @click="buttonClick">Dosya Yükle</button>
    </div>
    <div v-if="loading">
      <img src="../../public/images/file-loading.gif" />
    </div>
  </div>
</template>

<script>
import { HTTP } from "../main-source";

export default {
  name: "File Upload",
  data() {
    return {
      loading: false,
    };
  },
  props: ["placeholder", "filetypeId", "subject","value","accept"],
  methods: {
    async onFileChange(event) {
      this.loading = true;
      const file = event.target.files[0];
      let uploadingFile = {
        name: file.name,
        base64: "",
        files_for: "file",
        subject: this.subject,
        filetypeId: this.filetypeId,
      };

      let reader = await new FileReader();

      reader.onload = async (file) => {
        uploadingFile.base64 = await reader.result;
        await this.fileUploadRequest(uploadingFile);
      };
      await reader.readAsDataURL(file);
    },
    async fileUploadRequest(data) {
      const upladedFile = await HTTP.post("/files/base64", data);
      this.$swal(
        "",
        upladedFile?.data.message,
        upladedFile?.data?.isSuccess ? "success" : "error"
      );
      this.value = upladedFile?.data;
      this.loading = false;
      this.$emit("onChangeValue", upladedFile?.data);
    },
    buttonClick() {
      this.$refs.file.click();
    },
    fileStatusColor() {
      if (this.value.isSuccess === true) {
        return "#609966";
      } else if (this.value.isSuccess === false) {
        return "#DF2E38";
      } else {
        return "rgba(0,0,0,0.2)";
      }
    },
  },
  created(){
    
    
  },
  components: {},
};
</script>

<style scoped>
.main-container {
  width: 300px;
  border: 1px solid rgb(149, 149, 149);
  border-style: dashed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  margin-right: 40px;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-button {
  background-color: white;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  width: 125px;
  height: 40px;
  font-family: sans-serif;
  font-size: 14px;
  color: rgb(53, 53, 53);
  font-weight: 100;
}
.uploaded-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: contain;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 10px;
  background: rgb(231, 231, 231);
}
</style>
