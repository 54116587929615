<template>
  <div class="wrapper">
    <span class="md-title">Lokasyonlar</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addLocationModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="locations">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Adı">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Branş">{{ item.branch ? item.branch.name : "" }}</md-table-cell>
        <md-table-cell md-label="Durum">
          <div class="status" v-bind:class="[item.status ? 'active' : 'passive']"></div>
          {{ item.status ? 'Aktif':'Pasif' }}
        </md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i
            class="md-icon md-icon-font md-theme-default"
            v-on:click="updateLocation(item.id)"
          >edit</i>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addLocationModal" class="dialog">
      <md-content class="statuses">
        <h3>Yeni Lokasyon</h3>
        <md-field>
          <label>Lokasyon Adı *</label>
          <md-input v-model="location.name"></md-input>
        </md-field>

        <md-field>
          <v-select
            v-model="location.branch"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <span class="md-error" v-if="this.isValid.name === false">Lütfen Lokasyon Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="addLocation">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateLocationModal" class="dialog">
      <md-content class="statuses">
        <h3>Lokasyon</h3>
        <md-field>
          <label>Lokasyon Adı *</label>
          <md-input v-model="selectedLocation.name"></md-input>
        </md-field>

        <md-field>
          <v-select
            v-model="selectedLocation.branch"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <span class="md-error" v-if="this.isValid.name === false">Lütfen Lokasyon Adı Giriniz</span>
        <md-checkbox v-model="selectedLocation.status">Aktif</md-checkbox>

        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateLocationDone"
        >Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "locations",
  data() {
    return {
      title: "locations",
      locations: [],
      branches: [],
      location: {
        name: "",
        status: ""
      },
      selectedLocation: {
        name: "",
        status: ""
      },
      addLocationModal: false,
      updateLocationModal: false,
      isValid: {
        name: null,
        duration: null
      }
    };
  },
  created() {
    this.getLocations();
    this.getBranches();
  },
  methods: {
    addLocation: function() {
      if (this.location.name == "") {
        this.isValid.name = false;
      }
      if (this.location.duration == "") {
        this.isValid.duration = false;
      }
      if(this.location.branch){
        this.location.branchId = this.location.branch.id;
      }
      if (this.location.name != "" && this.location.duration != "") {
        HTTP.post("/locations/", this.location).then(result => {
          if (result.status == 201) {
            this.getLocations();
            this.addLocationModal = false;
            this.location.name = "";
            this.isvalid = {
              name: null,
              duration: null
            };
          }
        });
      }
    },
    getLocations: function() {
      HTTP.get("/locations/").then(result => {
        this.locations = result.data;
      });
    },
    updateLocation: function(id) {
      this.selectedLocation = this.locations.filter(
        location => location.id == id
      )[0];
      this.updateLocationModal = true;
    },
    updateLocationDone: function() {
      if (this.selectedLocation.name == "") {
        this.isValid.name = false;
      }

      if(this.selectedLocation.branch) {
        this.selectedLocation.branchId = this.selectedLocation.branch.id;
      }

      if (
        this.selectedLocation.name != "" &&
        this.selectedLocation.status != null
      ) {
        HTTP.put(
          "/locations/" + this.selectedLocation.id,
          this.selectedLocation
        ).then(result => {
          if (result.status == 204) {
            this.getLocations();
            this.updateLocationModal = false;
          }
        });
      }
    },
    getBranches: function() {
      HTTP.get("/branches/").then(result => {
        this.branches = result.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
</style>