<template>
      <div class="md-layout details">
         <BigLoading v-if="loading"/>
          <div class="md-layout-item wrapper md-size-100" v-if="roleId == 1 && !loading">
            <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable"
              v-if="isDisabled"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled">
              <md-icon>save</md-icon>
            </md-button>
            <span class="md-title">Branşlar</span>
            <br/>
            <md-checkbox
              v-for="branch in branches"
              v-bind:key="branch.id"
              v-bind:value="branch.id"
              v-model="checkedBranches"
              v-bind:disabled="isDisabled"
              >{{ branch.name }}</md-checkbox
            >
            
            <div  v-if="profileRoleId != 2">
              <span class="md-title">Doktorlar</span>
              <md-field>
              <label>Arayınız...</label>
              <md-input v-model="doctorsOfSelectedBranchesSearchInput"></md-input>
              <md-icon>search</md-icon>
            </md-field>
              <br/>
              <md-checkbox
                v-for="doctor in searchFilter(doctorsOfSelectedBranches,doctorsOfSelectedBranchesSearchInput)"
                v-bind:key="doctor.id"
                v-bind:value="doctor.id"
                v-model="userDoctors"
                v-bind:disabled="isDisabled"
                >{{ doctor.name }}</md-checkbox
              >
            </div>
            
            <div v-else>
            <span class="md-title">İşlem Limitleri</span>
            <br/>
            <md-table class="table appointments md-scrollbar" v-model="doctorLimits">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="">{{ item.name }}</md-table-cell>
                <md-table-cell v-for="day in weekdays" :md-label="day.name" :key="day.id">
                  <md-field style="width: 35px;">
                    <md-input type="number" v-model="operationLimits[item.id]['day' + day.id]" v-bind:disabled="isDisabled"></md-input>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>          
        </div>
      </div>
  </template>
  
  <script>
  // import userList from "../../components/user/list";
  import { HTTP } from "../../../main-source";
import BigLoading from "../../../components/bigLoading/index.vue";
  
  export default {
    name: "Profile",
    data() {
        return {
            id: this.$route.params.id,
            profile: [],
            branches: [],
            users: [],
            roleId: localStorage.getItem("roleId"),
            profileRoleId: "",
            checkedBranches: [],
            userDoctors: [],
            isDisabled: true,
            doctorLimits: [{ id: "surgery", name: "Ameliyat Limiti" }, { id: "notSurgery", name: "Ameliyatsız Limiti" }],
            operationLimits: [],
            doctorsOfSelectedBranchesSearchInput: "",
            weekdays: [
                { id: 1, name: "Pazartesi" },
                { id: 2, name: "Salı" },
                { id: 3, name: "Çarşamba" },
                { id: 4, name: "Perşembe" },
                { id: 5, name: "Cuma" },
                { id: 6, name: "Cumartesi" },
                { id: 0, name: "Pazar" },
            ],
            loading : true
        };
    },
    computed: {
        usersWithoutDoctors: function () {
            return this.users.filter(z => z.roleId != 2);
        },
        doctorsOfSelectedBranches: function () {
            return this.users.filter(z => z.roleId == 2 &&
                this.checkedBranches.filter(checkedBranch => z.branches.map(branch => branch.id).includes(checkedBranch)).length > 0);
        }
    },
    created() {
        HTTP.get("/users/" + this.id + "/getBranchesDoctorsOperationLimits").then(result => {
            this.profile = result.data[0];
            HTTP.get("/users/" + result.data[0].id + "/getUserRoleId")
                .then(result => {
                this.profileRoleId = result.data[0].roleId;
            });
            this.profile.branches.forEach(branch => {
                this.checkedBranches.push(branch.id);
            });
            this.userDoctors = this.profile.userDoctors ? JSON.parse(this.profile.userDoctors) : [];
            this.operationLimits = {
                surgery: this.profile.doctorLimits && this.profile.doctorLimits.find(z => z.type == "surgery") ? this.profile.doctorLimits.find(z => z.type == "surgery") : { "day0": 0, "day1": 0, "day2": 0, "day3": 0, "day4": 0, "day5": 0, "day6": 0 },
                notSurgery: this.profile.doctorLimits && this.profile.doctorLimits.find(z => z.type == "notSurgery") ? this.profile.doctorLimits.find(z => z.type == "notSurgery") : { "day0": 0, "day1": 0, "day2": 0, "day3": 0, "day4": 0, "day5": 0, "day6": 0 },
            };
        });
        HTTP.get("/branches/").then(result => {
            this.branches = result.data;
        });
        HTTP.get("/users/").then(result => {
            this.users = result.data;
            this.loading = false
        });
    },
    methods: {
        editable: function () {
            this.isDisabled = !this.isDisabled;
        },
        editInformation: function () {
            // 
            this.profile.branches = this.checkedBranches;
            this.profile.leading = JSON.stringify(this.checkedUsers);
            this.userDoctors = this.userDoctors.filter(z => this.doctorsOfSelectedBranches.map(d => d.id).includes(z)); // removing old saved doctors whose branch has been removed
            this.profile.userDoctors = JSON.stringify(this.userDoctors);
            this.profile.operationLimits = JSON.stringify(this.operationLimits);
            HTTP.post("/users/" + this.profile.id + "/updateBranchesAndDoctors", {
                branches: this.profile.branches,
                operationLimits: this.profile.operationLimits,
                userDoctors: this.profile.userDoctors
            }).then(result => {
                if (result.status == 204) {
                    this.editable();
                }
            });
        },
        searchFilter: function (data, search) {
            let searchValue = search.toLowerCase();
            return data.filter(item => item.name.toLowerCase().match(searchValue) ? item : null);
        }
    },
    components: { BigLoading }
};
  </script>
  
  <style lang="scss">
  @import "../../../assets/scss/colors.scss";
  
  .md-dialog-content {
    color: #f00;
  }
  
  .error {
    display: block !important;
    color: #f00;
    transform: translate3d(0, -8px, 0);
    height: -19px;
    position: inherit;
    font-size: 1rem;
  
    border-bottom: inset;
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  
  
  .wrap {
    margin: 0px -15px;
  }
  .item {
    min-height: 200px;
    margin: 15px;
    &:last-child {
      margin-right: 5px;
    }
  }
  .profile.wrapper {
    margin-top: 75px;
  }
  .wrapper {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
  .profile {
    text-align: center;
    img {
      width: 170px !important;
      height: 170px !important;
      border-radius: 50%;
      margin-top: -90px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      margin-bottom: 20px;
    }
    span {
      display: block;
    }
    .title {
      font-weight: bold;
    }
    .md-layout {
      text-align: left;
    }
    .icon {
      padding: 0px 10px;
      margin-right: 10px;
    }
    .main {
      margin: 15px 0px;
    }
    .secondary {
      margin: 30px 0px 15px 0px;
      .md-layout {
        margin-top: 10px;
        color: $dark;
      }
    }
  }
  .md-icon-button.delete {
    background: $red !important;
    .icon {
      color: $white !important;
    }
  }
  .editButton {
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }
  .md-scrollbar {
    max-height: 80vh !important;
    overflow: auto;
  }
  .editOkButton {
    width: 100%;
    margin: 0 !important;
  }
  .vue-phone-number-input {
    width: 100%;
  }
  </style>
  
  