<template>
  <div>
      <div class="md-layout details">
        <div ref="pdfContent">

        <Epic
          v-bind:epicrisis="epicrisis"
          v-bind:patient="patient"
          v-bind:template="template"
        >
        </Epic>
        </div>

        <div class="md-layout-item wrapper" >
          <span class="md-title">Bilgiler</span>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.name.label }}</label>
                <md-input v-model="patient.name"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.age.label }}</label>
                <md-input v-model="epicrisis.age.content"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.height.label }}</label>
                <md-input type="number" v-model="epicrisis.height.content"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.weight.label }}</label>
                <md-input type="number" v-model="epicrisis.weight.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.complaint.label }}</label>
                <md-input v-model="epicrisis.complaint.content"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.admission.label }}</label>
                <md-input type="date" v-model="epicrisis.admission.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.discharge.label }}</label>
                <md-input type="date" v-model="epicrisis.discharge.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.operationDate.label }}</label>
                <md-input
                  type="date"
                  :value="epicrisis.operationDate.content"
                  v-model="epicrisis.operationDate.content"
                ></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.history.label }}</label>
                <md-textarea v-model="epicrisis.history.content"></md-textarea>
              </md-field>
            </div>

            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.examination.label }}</label>
                <md-textarea v-model="epicrisis.examination.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.pulse.label }}</label>
                <md-input type="number" v-model="epicrisis.pulse.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.spo2.label }}</label>
                <md-input type="number" v-model="epicrisis.spo2.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.background.label }}</label>
                <md-textarea v-model="epicrisis.background.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.laboratory.label }}</label>
                <md-textarea v-model="epicrisis.laboratory.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.abdominal.label }}</label>
                <md-textarea v-model="epicrisis.abdominal.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.course.label }}</label>
                <md-textarea v-model="epicrisis.course.content"></md-textarea>
              </md-field>
            </div>
            <hr class="half-hr" />
            <div class="md-layout-item md-size-100 md-xsmall-size-100 md-layout">
              <md-button
              class="md-raised md-primary  md-layout-item md-size-50 md-xsmall-size-100"
              @click="addPostopDay"
            >
              Gün Ekle
              <md-icon>add</md-icon>
            </md-button>
            <md-button
              class="md-raised md-danger  md-layout-item md-size-50 md-xsmall-size-100"
              @click="deletePostopDay"
            >
              Gün Sil
              <md-icon>delete</md-icon>
            </md-button>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100 md-layout" v-for="(postop,index) in epicrisis.postop" :key="index">
             <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <md-input v-model="epicrisis.postop[index].label"></md-input>
              </md-field>
            </div>
              <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>Post-operative day {{ index + 1 }}</label>
                <md-textarea v-model="epicrisis.postop[index].content.desc"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>CRP</label>
                <md-input type="number" v-model="epicrisis.postop[index].content.crp.content"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>HGB</label>
                <md-input type="number" v-model="epicrisis.postop[index].content.hgb.content"></md-input>
              </md-field>
            </div>
            </div>
            <hr class="half-hr" />
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.surgicalNote.label }}</label>
                <md-textarea v-model="epicrisis.surgicalNote.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.condDischarge.label }}</label>
                <md-textarea v-model="epicrisis.condDischarge.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.diagnosis.label }}</label>
                <md-textarea v-model="epicrisis.diagnosis.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.surgeryNote.label }}</label>
                <md-textarea v-model="epicrisis.surgeryNote.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.medicalPrescibed.label }}</label>
                <md-textarea v-model="epicrisis.medicalPrescibed.content"></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field>
                <label>{{ epicrisis.recommendations.label }} </label>
                <md-textarea v-model="epicrisis.recommendations.content"></md-textarea>
              </md-field>
            </div>
          </div>
          <div class="btn-wrap m-3">
            <md-button
              class="md-raised md-primary btn-right"
              v-on:click="saveEpicrisis()"
            >
              Kaydet
              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>
          <div class="btn-wrap m-3">
            <md-button
              class="md-raised md-secondary btn-right"
              @click="exportToPDF"
            >
              PDF indir

              <md-icon class="btn-icon">keyboard_arrow_right</md-icon>
            </md-button>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment";
import Epic from "@/components/epicrisisComponent";


import html2pdf from "html2pdf.js";

export default {
  name: "Epikriz",

  data() {
    return {
      patient: {},

      epicrisis: {},
      template: null,
      epicrisisId: this.$route.params.id,

      roleId: localStorage.getItem("roleId"),

      users: [],
      patientId: this.$route.params.patient,
    };
  },
  components: {
    Epic,
  },
  created() {
    HTTP.get("/patientstatus/").then((result) => {
      this.patientStatuses = result.data;
    });
    HTTP.get("/whens/").then((result) => {
      this.whens = result.data;
    });
    HTTP.get("/countries/").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/languages/").then((result) => {
      this.languages = result.data;
    });
    HTTP.get("/patients/" + this.patientId).then((result) => {
      this.patient = result.data[0];
    });
    let params = {
      id: this.$route.params.id,
    };
    HTTP.get("/epicrisis/byId?" + this.serializeURLQueryParams(params)).then(
      (result) => {
        this.epicrisis = JSON.parse(
          result.data.filter((z) => z.id == this.$route.params.id)[0].content
        );
        var templateId = result.data[0].templateId != null ? result.data[0].templateId : 1;
          HTTP.get("epicrisisTemplate/byId/"+templateId).then(result => {
            this.template = result.data[0]
          });

        if(!this.epicrisis.postop){
          this.epicrisis.postop = [];
          for(var i=1;i<=3;i++){
            if(this.epicrisis["postop"+i] && this.epicrisis["postop"+i] != ""){
              this.epicrisis.postop.push({"desc": this.epicrisis["postop"+i],"crp": parseFloat(this.epicrisis["crp"+i]), "hgb": parseFloat(this.epicrisis["hgb"+i]) })
            }
          }
        }
      }
    );

    HTTP.get("/users/").then((result) => {
      this.users = result.data;
    });

    // HTTP.get("/appointments/bypatient?" + this.patientId).then((result) => {
    //   // this.epicrisis.operationDate = result.data[0].date;
    //   this.appointmentId = result.data[0].id;
    // });
  },
  methods: {
    addPostopDay : function(){
      this.epicrisis.postop.push(
        {
            "label": "Post-operative day " + (this.epicrisis.postop.length + 1),
            "content": {
                "desc": "",
                "crp": {
                    "label": "CRP",
                    "content": 0
                },
                "hgb":{
                    "label": "HGB",
                    "content": 0
                }
            }
        },
      )
    },
    deletePostopDay : function(){
      this.epicrisis.postop = this.epicrisis.postop.slice(0,(this.epicrisis.postop.length-1))
      console.log(this.epicrisis.postop.slice(4,6))
    },
    saveEpicrisis: function () {
      let params = {
        id: this.$route.params.id,
        patientId: this.patientId,
        name: this.epicrisis.title.content,
        //appointmentId: this.appointmentId,
        content: JSON.stringify(this.epicrisis),
        lastUpdateUserId: localStorage.getItem("userId"),
      };

      HTTP.post("/epicrisis/update/", params)
        .then((res) => {
          this.$swal({
            icon: "success",
            text: "İşlem Başarıyla tamamlandı.",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          this.message = "Bir şeyler ters gitti";
          console.log(e);
          console.log(e.message);
        });
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },

    exportToPDF() {
      html2pdf(this.$refs.pdfContent, {
        margin: 0,
        filename: this.patient.name+" "+this.epicrisis.title.content+".pdf",
        pdfContentWidth: 800,
        image: { type: "png", quality: 1.98 },
        html2canvas: { dpi: 300, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },


    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    dateString: function (date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear();
      return startText;
    },
    deleteSurgery: function (id) {
      HTTP.delete("surgeries/?ids=" + id).then(() => {
        this.getSurgeries();
      });
    },
  },
  computed: {},

  watch: {
    "epicrisis.height.content": function () {
      let calculate =
        this.epicrisis.weight.content /
        (this.epicrisis.height.content * 0.01 * (this.epicrisis.height.content * 0.01));
      let bmi = calculate.toFixed(2);
      this.epicrisis.bmi.content = bmi;
    },
    "epicrisis.weight.content": function () {
      let calculate =
        this.epicrisis.weight.content /
        (this.epicrisis.height.content * 0.01 * (this.epicrisis.height.content * 0.01));
      let bmi = calculate.toFixed(2);
      this.epicrisis.bmi.content = bmi;
    },
  },
};
</script>

<style lang="scss" scope>
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.editOkButton {
  width: 100%;
  margin: 0 !important;
}
.vs--disabled * {
  background: transparent !important;
}
.medicalhistory {
  height: auto;
  max-height: auto;
}
.surgery {
  margin: 10px 0px;
  padding: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  .head {
    width: 100%;
    display: block;
  }
}
.profile-img {
  overflow: visible !important;
}
.profile {
  .add {
    position: absolute;
    left: calc(50% - 85px);
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .add {
      opacity: 1;
    }
  }
}
.file.profile-img {
  label {
    top: -100px !important;
    height: 200% !important;
  }
}
.md-tabs {
  width: 100%;
}
.maz-input.is-disabled .maz-input__label,
.maz-input.is-disabled .maz-input__input,
.maz-input.is-disabled .maz-input__toggle__arrow {
  cursor: auto;
  color: #000;
}
.maz-input.is-disabled.is-valid {
  border: 0;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  /*padding: 1px;*/
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 1px; /*AA*/
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
.header {
  background-color: #6cd8d2;
  font-family: Monstserrat-Italic;
  font-size: 35px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  max-height: 70px;
}
.card1 {
  border-style: solid;
  border-width: 2px;
  border-color: #6cd8d2;
}
</style>
