<template>
  <div class="wrapper">
    <span class="md-title">Operasyonlar</span>
    <md-button class="md-fab md-mini md-warning sortButton" v-on:click="sortOperationsModal=true">
      <md-icon>sort</md-icon>
    </md-button>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addOperationModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="operations">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!-- v-on:click="showAppointmentDetails(item.id)" -->
        <!-- <md-table-cell md-label="ID">{{ item.id }}</md-table-cell> -->
        <md-table-cell md-label="Operasyon Adı">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Süresi">{{ item.duration }} dk</md-table-cell>
        <md-table-cell md-label="Kod">{{ item.slug }}</md-table-cell>
        <md-table-cell md-label="Branş">{{ item.branch ? item.branch.name : "" }}</md-table-cell>
        <md-table-cell md-label="Günlük Limit">{{ item.dailyLimit }}</md-table-cell>
        <md-table-cell md-label="Fiyat">{{ priceList(item.prices) }}</md-table-cell>
        <md-table-cell md-label="Ameliyat">
          <div class="status" v-bind:class="[item.isSurgery ? 'active' : 'passive']"></div>
          {{ item.isSurgery ? 'Evet':'Hayır' }}
        </md-table-cell>
        <md-table-cell md-label="Ameliyatsız">
          <div class="status" v-bind:class="[item.isNotSurgery ? 'active' : 'passive']"></div>
          {{ item.isNotSurgery ? 'Evet':'Hayır' }}
        </md-table-cell>
        <md-table-cell md-label="Durum">
          <div class="status" v-bind:class="[item.status ? 'active' : 'passive']"></div>
          {{ item.status ? 'Aktif':'Pasif' }}
        </md-table-cell>
        <md-table-cell md-label="Satış Raporu">
          <div class="status" v-bind:class="[item.isSalesReport ? 'active' : 'passive']"></div>
          {{ item.isSalesReport ? 'Aktif':'Pasif' }}
        </md-table-cell>

        <md-table-cell md-label="Düzenle">
          <i
            class="md-icon md-icon-font md-theme-default"
            v-on:click="updateOperation(item.id)"
          >edit</i>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="sortOperationsModal" class="dialog">
      <md-content class="statuses md-scrollbar">
        <h3>Operasyonları Sırala</h3>
        <draggable v-model="operations" group="people" @start="drag=true" @end="drag=false;saveOperationsSort();" >
          <div v-for="element in operations" :key="element.id" class="list-group-item">{{element.name}}</div>
        </draggable>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="addOperationModal" class="dialog">
      <md-content class="statuses md-scrollbar">
        <h3>Yeni Operasyon</h3>
        <md-field>
          <label>Operasyon Adı *</label>
          <md-input v-model="operation.name"></md-input>
        </md-field>

        <md-field>
          <v-select
            v-model="operation.branch"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <label>Günlük limit</label>
          <md-input v-model="operation.dailyLimit" type="number"></md-input>
        </md-field>

        <md-field>
          <label>Türkçe</label>
          <md-input v-model="operation.TR"></md-input>
        </md-field>

        <md-field>
          <label>İngilizce</label>
          <md-input v-model="operation.EN"></md-input>
        </md-field>

        <md-field>
          <label>Bulgarca</label>
          <md-input v-model="operation.BUL"></md-input>
        </md-field>

        <md-field>
          <label>Almanca</label>
          <md-input v-model="operation.GER"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Operason Adı Giriniz</span>
        <md-field>
          <label>Süresi (dk)*</label>
          <md-input v-model="operation.duration" type="number"></md-input>
        </md-field>
        <md-field>
          <label>Kod</label>
          <md-input v-model="operation.slug"></md-input>
        </md-field>
        <label for>Fiyat</label>
        <div v-for="(currency,index) in currencies" :key="index">
          <md-field v-if="prices">
            <label>{{ currency.title }}</label>
            <md-input v-model="prices[currency.slug]"></md-input>
          </md-field>
        </div>

        <span class="md-error" v-if="this.isValid.duration === false">Lütfen Operason Süresi Giriniz</span>

        <md-checkbox v-model="operation.isSurgery">Ameliyat</md-checkbox>
        <md-checkbox v-model="operation.isNotSurgery">Ameliyatsız</md-checkbox>
        <md-checkbox v-model="operation.isSalesReport">Satış Raporu</md-checkbox>

        <md-button class="md-raised md-primary updateStatus" v-on:click="addOperation">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateOperationModal" class="dialog">
      <md-content class="statuses md-scrollbar">
        <h3>Operasyon</h3>
        <md-field>
          <label>Operasyon Adı *</label>
          <md-input v-model="selectedOperation.name"></md-input>
        </md-field>

        <md-field>
          <v-select
            v-model="selectedOperation.branch"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <label>Günlük limit</label>
          <md-input v-model="selectedOperation.dailyLimit" type="number"></md-input>
        </md-field>

        <md-field>
          <label>Türkçe</label>
          <md-input v-model="selectedOperation.TR"></md-input>
        </md-field>

        <md-field>
          <label>İngilizce</label>
          <md-input v-model="selectedOperation.EN"></md-input>
        </md-field>

        <md-field>
          <label>Bulgarca</label>
          <md-input v-model="selectedOperation.BUL"></md-input>
        </md-field>

        <md-field>
          <label>Almanca</label>
          <md-input v-model="selectedOperation.GER"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Operason Adı Giriniz</span>
        <md-field>
          <label>Süresi (dk)*</label>
          <md-input v-model="selectedOperation.duration" type="number"></md-input>
        </md-field>
        <md-field>
          <label>Kod</label>
          <md-input v-model="selectedOperation.slug"></md-input>
        </md-field>

        <div v-for="(currency,index) in currencies" :key="index">
          <md-field>
            <label>{{ currency.title }}</label>
            <md-input v-model="selectedPrices[currency.slug]"></md-input>
          </md-field>
        </div>

        <span class="md-error" v-if="this.isValid.duration === false">Lütfen Operason Süresi Giriniz</span>

        <md-checkbox v-model="selectedOperation.status">Aktif</md-checkbox>
        <md-checkbox v-model="selectedOperation.isActivity">Aktivite?</md-checkbox>
        <md-checkbox v-model="selectedOperation.isSurgery">Ameliyat</md-checkbox>
        <md-checkbox v-model="selectedOperation.isNotSurgery">Ameliyatsız</md-checkbox>
        <md-checkbox v-model="selectedOperation.isSalesReport">Satış Raporu</md-checkbox>

        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateOperationDone"
        >Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import draggable from 'vuedraggable';
export default {
  name: "Operations",
  components: {
    draggable,
  },
  data() {
    return {
      title: "operations",
      operations: [],
      branches: [],
      operation: {
        name: "",
        duration: "",
        slug: "",
        isSurgery: false,
        isNotSurgery: false,
      },
      selectedOperation: {
        TR:'',
        EN:'',
        GER:'',
        BUL:'',
        name: '',
        duration: '',
        status: '',
        slug: '',
        isActivity: false,
        isSurgery: false,
        isNotSurgery: false,
        dailyLimit: 0
      },
      addOperationModal: false,
      updateOperationModal: false,
      sortOperationsModal: false,
      isValid: {
        name: null,
        duration: null
      },
      currencies: {},
      prices: {},
      selectedPrices: {}
    };
  },
  created() {
    this.getOperations();
    this.getBranches();
    HTTP.get("currency").then(currencies => {
      this.currencies = currencies.data;
      currencies.data.forEach(currency => {
        this.$set(this.prices, currency.slug, "");
        this.$set(this.selectedPrices, currency.slug, "");
      });
    });
  },
  methods: {
    addOperation: function() {
      if (this.operation.name == "") {
        this.isValid.name = false;
      }
      if (this.operation.duration == "") {
        this.isValid.duration = false;
      }
      if(this.operation.branch)
        this.operation.branchId = this.operation.branch.id;

      if (this.operation.name != "" && this.operation.duration != "") {
        this.operation.prices = JSON.stringify(this.prices);
        HTTP.post("/operations/", this.operation).then(result => {
          if (result.status == 201) {
            this.getOperations();
            this.addOperationModal = false;
            this.operation.name = "";
            this.operation.duration = "";
            this.operation.isSurgery = false;
            this.operation.isNotSurgery = false;
            this.currencies.forEach(currency => {
              this.$set(this.prices, currency.slug, "");
            });
            this.isvalid = {
              name: null,
              duration: null
            };
          }
        });
      }
    },
    getBranches: function() {
      HTTP.get("/branches/").then(result => {
        this.branches = result.data;
      });
    },
    getOperations: function() {
      HTTP.get("/operations/").then(result => {
        this.operations = result.data;
      });
    },
    updateOperation: function(id) {
      this.selectedOperation = this.operations.filter(
        operation => operation.id == id
      )[0];
      let prices = JSON.parse(this.selectedOperation.prices);
      if (prices) {
        this.selectedPrices.EUR = prices.EUR;
        this.selectedPrices.TL = prices.TL;
        this.selectedPrices.USD = prices.USD;
      }
      this.updateOperationModal = true;
    },
    updateOperationDone: function() {
      if (this.selectedOperation.name == "") {
        this.isValid.name = false;
      }
      if (this.selectedOperation.duration == "") {
        this.isValid.duration = false;
      }

      if(this.selectedOperation.branch)
        this.selectedOperation.branchId = this.selectedOperation.branch.id;

      if (
        this.selectedOperation.name != "" &&
        this.selectedOperation.duration != ""
      ) {
        this.selectedOperation.prices = JSON.stringify(this.selectedPrices);
        HTTP.put(
          "/operations/" + this.selectedOperation.id,
          this.selectedOperation
        ).then(result => {
          if (result.status == 204) {
            this.getOperations();
            this.updateOperationModal = false;
          }
        });
      }
    },
    priceList: function(prices) {
      let text = "";
      if (prices) {
        text = prices
          .split('"')
          .join("")
          .split("{")
          .join("")
          .split("}")
          .join("")
          .split(",")
          .join(" - ");
      }
      return text;
    },
    saveOperationsSort: function(){
      var sort = [];
      var loop = 1;
      this.operations.forEach(operation => {
        sort.push({id: operation.id, sort: loop});
        loop++;
      });
      HTTP.put(
          "/operations/sort",
          {sort: sort}
        ).then(result => {
          if (result.status != 204) {
            this.$swal("", "Bir hata oluştu!", "error");
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
.sortButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 50px !important;
  top: 10px !important;
}
.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    cursor: move;
}
</style>