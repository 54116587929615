<template>
  <div>
    <div class="centered-container">
      <md-content class="md-elevation-3 centerContent">
        <div class="title md-elevation-3">
          <img src="../../public/images/logo.jpg" />
        </div>

        <div class="form">
          <md-field v-if="!tokenRequired">
            <label>E-mail</label>
            <md-input v-model="email" autocomplete="off" required v-on:keyup="handleKey"></md-input>
          </md-field>

          <md-field md-has-password v-if="!tokenRequired">
            <label>Şifre</label>
            <md-input v-model="password" type="password" required v-on:keyup="handleKey"></md-input>
          </md-field>
          <md-field v-if="tokenRequired">
            <label>Token</label>
            <md-input v-model="token" type="tel" required v-on:keyup="handleKey"></md-input>
          </md-field>
        </div>

        <div class="md-layout">
          <md-button class="md-layout-item md-raised md-primary login" v-on:click="login">Giriş</md-button>
        </div>
      </md-content>
      <!-- <div class="background" /> -->
    </div>
  </div>
</template>

<script>
import router from "../router";
import { HTTP } from "../main-source";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      tokenRequired: false,
      token: "",
      error: []
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  methods: {
    ...mapActions({
      loginv: "login",
      setUser: "setUser"
    }),
    handleKey: function(e){
      if(e.keyCode == 13)
        this.login();
    },
    login: function() {
      HTTP.post("/users/signin", {
        email: this.email,
        password: this.password,
        token: this.tokenRequired ? this.token : null
      })
        .then(result => {
          if (result.status == 202) {
            localStorage.setItem("roleId", result.data.roleId);
            localStorage.setItem("userId", result.data.id);
            localStorage.setItem("name", result.data.name);
            localStorage.setItem("email", result.data.email);
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("leading", result.data.leading ?? "[]");
            localStorage.setItem("userDoctors", result.data.userDoctors ?? "[]");

            this.$store.state.user.roleId=result.data.roleId
            this.$store.state.user.userId= result.data.id
            this.$store.state.user.name=result.data.name
            this.$store.state.user.email= result.data.email

            this.setUser(result.data);
            HTTP.defaults.headers.common["Authorization"] =
              "Bearer " + result.data.token;

            router.push("/");

            /* eslint-disable */
          }else if(result.status == 203){
            this.tokenRequired = true;
          }
        })
        .catch(e => {
          if (e.response.status == 401) {
            this.error = e.response.data.message;
            alert(e.response.data.message);
          }
        });
    },
    getValue: function() {
      // login();
      // console.log(this.isLoggedIn);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/colors";

.main-panel {
  width: 100vw !important;
  height: 95vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 30px);
  margin: 0;
  .title {
    text-align: center;
    margin-top: -80px;
    margin-bottom: 40px;
    padding: 20px 40px;
    background: $white;
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    // margin-bottom: 60px;
  }
}
.md-content {
  z-index: 1;
  padding: 40px;
  width: 400px;
  position: relative;
}
.loading-overlay {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  float: right !important;
}
.centerContent{
  text-align: center;
}
</style>
