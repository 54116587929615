<template>
  <div class="wrapper">
    <span class="md-title">"Ne Zaman?" Seçenekleri</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addSourceModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="sources">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Kanal">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateSource(item.id)">edit</i>
          <!-- <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteSource(item.id)">delete</i> -->
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addSourceModal" class="dialog">
      <md-content class="statuses">
        <h3>Yeni Zaman Durumu</h3>
        <md-field>
          <label>Zaman Durumu Adı *</label>
          <md-input v-model="source.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Zaman Durumu Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="addSource">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateSourceModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Zaman Durumu Adı *</label>
          <md-input v-model="source.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false">Lütfen Zaman Durumu Adı Giriniz</span>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateSourceDone">Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Sources",
  data() {
    return {
      title: "Kaynaklar",
      sources: [],
      source: {
        name: ""
      },
      addSourceModal: false,
      updateSourceModal: false,
      isValid: {
        name: null,
        duration: null
      }
    };
  },
  created() {
    this.getSources();
  },
  methods: {
    addSource: function() {
      HTTP.post("/whens/", this.source).then(result => {
        if (result.status == 201) {
          this.getSources();
          this.addSourceModal = false;

          this.isvalid = {
            name: null
          };
        }
      });
    },
    getSources: function() {
      HTTP.get("/whens/").then(result => {
        this.sources = result.data;
      });
    },
    updateSource: function(id) {
      this.source = this.sources.filter(sources => sources.id == id)[0];
      this.updateSourceModal = true;
    },
    updateSourceDone: function() {
      HTTP.put("/whens/" + this.source.id, this.source).then(result => {
        if (result.status == 204) {
          this.getSources();
          this.updateSourceModal = false;
        }
      });
    },
    deleteSource: function(id) {
      HTTP.delete("/sources/?ids=" + id).then(result => {
        if (result.status == 204) {
          this.getSources();
        }
      });
    }
  },
  watch: {
    // addSourceModal: function() {
    //   if (this.addSourceModal == true) {
    //     this.source.name = null;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
</style>