<template>
  <!-- <div style="
    position: sticky;
    z-index: 50;
    top: -1rem;
    margin-right: 2rem;
     text-align: -webkit-right;
 ">
        <div class="md-layout-item md-xsmall-size-10">
          <md-button
            style="width: 35px; height: 35px; float: right;color:white;    right: -2rem;    top: -0.5rem;    position: absolute;"
            class="md-fab md-plain"
            @click="closeCard"
            >X</md-button
          >
        </div>
      </div> -->
  <div>
    <md-dialog :md-active.sync="isShowdayEventsCount" class="dialog">
      <md-content>
        <div
          v-for="dayOperation in dayOperations"
          :key="dayOperation"
          class="daysCount md-layout md-gutter"
        >
          <span class="operationTotalCount md-layout-item md-size-30"
            >{{
              operations.filter((operation) => operation.id == dayOperation)[0]
                .name
            }}
            :
            {{
              dayEvents.filter((e) => e.operation == dayOperation).length
            }}</span
          >
          <div class="dayEventsCountDetails md-layout-item md-size-70">
            (
            <div class="dayEventsCounter">
              <div class="status waiting-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 1
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status done-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 2
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status cancel-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 3
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status postponed-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 4
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status not-come-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 5
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status optional-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 6
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status approve-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 7
                ).length
              }}
            </div>
            |
            <div class="dayEventsCounter">
              <div class="status depositPaid-bg"></div>
              {{
                dayEvents.filter(
                  (e) => e.operation == dayOperation && e.status == 8
                ).length
              }}
            </div>
            )
          </div>
        </div>

        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="isShowdayEventsCount = false"
          >Kapat</md-button
        >
        <div class="md-layout temp-status">
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status waiting-bg"></div>
            {{ dayEvents.filter((e) => e.status == 1).length }} Bekleniyor
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status done-bg"></div>
            {{ dayEvents.filter((e) => e.status == 2).length }} Tamamlandı
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status cancel-bg"></div>
            {{ dayEvents.filter((e) => e.status == 3).length }} İptal
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status postponed-bg"></div>
            {{ dayEvents.filter((e) => e.status == 4).length }} Ertelendi
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status not-come-bg"></div>
            {{ dayEvents.filter((e) => e.status == 5).length }} Gelmedi
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status optional-bg"></div>
            {{ dayEvents.filter((e) => e.status == 6).length }} Opsiyonlandı
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status approve-bg"></div>
            {{ dayEvents.filter((e) => e.status == 7).length }} Onay Bekleniyor
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status depositPaid-bg"></div>
            {{ dayEvents.filter((e) => e.status == 8).length }} Depozit Alındı
          </div>
        </div>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="modalHolidays" class="dialog allowOverflow">
      <md-content>
        <create-holiday
          @close="modalHolidays = false"
          @refresh="refresh"
          :branch="branch"
        ></create-holiday>
      </md-content>
    </md-dialog>
    <md-dialog
      :md-active.sync="modalUpdateHoliday"
      class="dialog allowOverflow"
    >
      <md-content>
        <update-holiday
          @close="modalUpdateHoliday = false"
          @refresh="refresh"
          :branch="branch"
          :selectedHoliday="selectedHoliday"
        />
      </md-content>
    </md-dialog>
    <md-dialog :md-active.sync="modalHolidayList" class="dialog allowOverflow">
      <md-content>
        <holiday-list
          @updateHoliday="updateHoliday"
          @close="modalHolidayList = false"
          @refresh="refresh"
          :holidays="holidays"
          :users="allUsers"
        />
      </md-content>
    </md-dialog>
    <md-dialog
      :md-active.sync="appointmentNoteModal"
      :md-click-outside-to-close="true"
      class="dialog"
    >
      <md-dialog-content>
        <md-content class="statuses">
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="
                width: 35px;
                height: 35px;
                float: right;
                color: white;
                right: 0.5rem;
                top: 0.5rem;
                position: absolute;
              "
              class="md-fab md-plain"
              @click="appointmentNoteModal = false"
              >X</md-button
            >
          </div>
          <md-dialog-title>Randevu Notu Düzenle</md-dialog-title>

          <h3></h3>
          <b>{{ selectedEvent.patient.name }}</b>
          adlı hastanın {{ formatDate(selectedEvent.date) }} tarihli randevusu
          <br />
          <br />

          <md-field>
            <md-textarea v-model="selectedEvent.note"> </md-textarea>
          </md-field>
          <md-button
            class="md-raised md-primary"
            v-on:click="appointmentNoteUpdate"
            >Düzenle</md-button
          >
        </md-content>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="eventStatusModal" class="dialog">
      <md-content class="statuses">
        <h3>Randevu Durumu Düzenle</h3>
        <b>{{ selectedEvent.patient.name }}</b>
        adlı hastanın {{ selectedEvent.date }} tarihli <br />randevu durumu
        güncelleniyor.
        <md-field>
          <md-select v-model="selectedEvent.status.id" placeholder="Durum">
            <md-option
              v-for="status in statuses"
              v-bind:value="status.id"
              v-bind:key="status.id"
              >{{ status.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field>
          <md-input v-model="statusChangeNote" placeholder="Not" />
        </md-field>
        <p style="color: red" v-if="statusChangeNoteRequired">
          * İptal ve Erteleme dumurumlarda not girilmesi zorunludur.
        </p>
        <div
          v-if="fileInputShow"
          class="md-layout-item md-size-100 md-xsmall-size-100 wrapper"
        >
          <div class="md-layout">
            <file-upload
              class="customfileinput file"
              ref="upload"
              v-model="uploadedFiles"
            >
              <span v-if="uploadedFiles.length > 0">
                <span v-for="(file, index) in uploadedFiles" :key="index">
                  <md-icon>attach_file</md-icon>{{ file.name }}
                </span>
              </span>
              <span v-else>
                <md-icon>attach_file</md-icon>Dosya Ekleyin (max 4 mb)
              </span>
              <md-progress-spinner
                v-if="spinner"
                class="md-accent spinner"
                :md-diameter="20"
                :md-stroke="3"
                md-mode="indeterminate"
              ></md-progress-spinner>
            </file-upload>
          </div>
        </div>
        <br />
        <br />
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="setEventStatus"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateAppointmentUserModal" class="dialog">
      <md-content class="statuses">
        <h3>Randevu Temsilcisini Düzenle</h3>
        <md-field>
          <md-select v-model="selectedEvent.userId" placeholder="Temsilci">
            <md-option
              v-for="user in appointmentChangeUsers"
              v-bind:value="user.id"
              v-bind:key="user.id"
              >{{ user.name }}</md-option
            >
          </md-select>
        </md-field>
        <br />
        <br />
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="UpdateAppointmentUser"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>

    <md-dialog-confirm
      :md-active.sync="deleteModal"
      md-title="Randevuyu silmek istediğinize emin misiniz?"
      md-content="Bu işlem geri alınamaz."
      md-confirm-text="Sil"
      md-cancel-text="İptal Et"
      @md-confirm="onConfirm"
      style="z-index: 20 !important"
    />

    <div class="md-layout filterBtn">
      <button class="md-secondary md-raised" @click="filterToggle">
        <md-icon>filter_list</md-icon>
      </button>
    </div>
    <div class="md-layout filters">
      <div class="md-layout-item title-wrap md-xsmall-size-100 md-size-20">
        <!-- <button class="little-btn calDate" @click="toggleDatePicker()">
          <md-datepicker v-model="calDate"></md-datepicker>
        </button> -->
        <div class="md-layout-item">
          <div class="row pr-5">
            <div class="col-4 pb-2">
              <p style="font-weight: bold; margin-bottom: 0">Ameliyatlı</p>
              <vc-date-picker
                class="inline-block h-full"
                ref="testCalendar"
                v-model="calDate"
                :attributes="datePickerAttributes"
                :select-attribute="datePickerSelectAttribute"
                :first-day-of-week="2"
                :masks="{ title: getCalendarName('withSurgery') }"
                @update:to-page="changeDateWithSurgery"
                is-required
              >
                <template v-slot="{ togglePopover }">
                  <button class="little-btn" @click="togglePopover()">
                    <md-icon>event</md-icon>
                  </button>
                </template>
              </vc-date-picker>
            </div>

            <div class="col-4 float-start">
              <p style="font-weight: bold; margin-bottom: 0">Ameliyatsız</p>
              <vc-date-picker
                class="inline-block h-full isNotSurgeryCalendar"
                v-model="calDate"
                :attributes="datePickerAttributesNotSurgery"
                :select-attribute="datePickerSelectAttribute"
                :first-day-of-week="2"
                :masks="{ title: getCalendarName('nonSurgery') }"
                @update:to-page="changeDateNonSurgery"
                style="color: red"
                is-required
              >
                <template v-slot="{ togglePopover }">
                  <button
                    class="little-btn"
                    style="background: #d5f5e3"
                    @click="togglePopover()"
                  >
                    <md-icon>event</md-icon>
                  </button>
                </template>
              </vc-date-picker>
            </div>

            <div class="col-12">
              <button class="little-btn today" @click="today">BUGÜN</button>

              <md-field class="calView hidden-xs">
                <md-select v-model="calView" placeholder="Takvim Görünümü">
                  <md-option
                    v-for="view in calViews"
                    v-bind:value="view.id"
                    v-bind:key="view.name"
                    >{{ view.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
        </div>
      </div>

      <div
        class="md-layout md-xsmall-size-100 multi"
        :class="{ show: filterShow == true }"
      >
        <div
          class="md-layout-item md-xsmall-size-100"
          v-if="branches.length > 1"
        >
          <md-field class="calFilter">
            <v-select
              v-model="branch"
              placeholder="Branşlar"
              :options="branches"
              label="name"
              :clearable="false"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-field class="calFilter">
            <v-select
              v-model="location"
              placeholder="Konumlar"
              :options="locations"
              :value="location.id"
              label="name"
              :clearable="true"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-field class="calFilter">
            <v-select
              v-model="status"
              placeholder="Durumlar"
              :options="filterStatuses"
              :value="status.id"
              label="name"
              :clearable="true"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-field class="calFilter">
            <v-select
              v-model="participant"
              placeholder="Doktorlar"
              :options="participants"
              :value="participant"
              label="name"
              :clearable="false"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <md-field class="calFilter">
            <v-select
              v-model="operation"
              placeholder="Operasyonlar"
              :options="operations"
              :value="operation.id"
              label="name"
              :clearable="false"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-100" v-if="canFilterByUser()">
          <md-field class="calFilter">
            <v-select
              v-model="user"
              placeholder="Kullanıcı"
              :options="users"
              :value="user.id"
              label="name"
              class="md-menu"
              multiple
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
        </div>
      </div>

      <md-speed-dial
        class="md-top-right app-dial md-direction-bottom"
        md-event="click"
        v-if="!isDoctor && roleId != 6 && !isTransferUser && roleId != 9"
      >
        <md-speed-dial-target class="md-primary">
          <md-icon class="md-morph-initial">add</md-icon>
          <md-icon class="md-morph-final">close</md-icon>
        </md-speed-dial-target>

        <md-speed-dial-content>
          <router-link :to="{ name: 'createAppointment' }">
            <md-button class="md-icon-button md-primary">
              <md-icon class="icon">calendar_today</md-icon>
            </md-button>
          </router-link>
          <router-link :to="{ name: 'patient.create' }">
            <md-button class="md-icon-button md-primary">
              <md-icon class="icon">person_add</md-icon>
            </md-button>
          </router-link>
        </md-speed-dial-content>
      </md-speed-dial>
    </div>
    <!-- v-bind:hideTitleBar="true" -->
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100">
        <vue-cal
          v-if="innerWidth < 769"
          v-bind:locale="locale"
          class="calendar"
          ref="vuecalmobile"
          style="height: 79vh"
          :events="calendarEvents"
          :default-view="'day'"
          :disable-views="['years', 'year', 'week', 'month']"
          :time-from="7 * 60"
          :time-to="21 * 60"
          :timeCellHeight="100"
          :hideViewSelector="true"
          :showWeekNumbers="false"
          :on-event-click="eventDetails"
          :dblclickToNavigate="false"
          :selected-date="calDate"
          @cell-dblclick="createEvent"
          hide-view-selector
          @view-change="getAppointmentsByDate('mobile')"
        >
          <template v-slot:cell-content="{ cell, events }">
            <div class="row dayInfo m-0">
              <div
                class="col-6 pt-2 pl-2 align-item-center"
                style="height: 60px"
                :class="
                  getDayLimit(cell) <= operationCount(cell, true)
                    ? 'cancel-bg'
                    : ''
                "
              >
                <div class="pt-2">
                  <span class="" style="height: 50px"
                    >{{ operationCount(cell) }} <br />
                    Ameliyat</span
                  >
                </div>
              </div>
              <div
                class="col-1 pt-2 pl-2 align-item-center"
                style="
                  height: 60px;
                  position: absolute;
                  left: 40%;
                  z-index: 999;
                "
              >
                <div class="pt-2">
                  <md-icon
                    class="info"
                    @click.native="showDayEventsCount(events)"
                    >info</md-icon
                  >
                </div>
              </div>
              <div
                class="col-6 pt-2 align-item-start"
                style="height: 60px"
                :class="
                  getDayLimitNotSurgery(cell) <=
                  operationCountNotSurgery(cell, true)
                    ? 'cancel-bg'
                    : ''
                "
              >
                <div class="pt-2">
                  <span
                    >{{ operationCountNotSurgery(cell) }} <br />
                    Ameliyatsız</span
                  >
                </div>
              </div>
            </div>
          </template>

          <template v-slot:time-cell="{ hours, minutes }">
            <div
              :class="{ line: true, hours: !minutes }"
              v-if="hours == 7"
            ></div>
          </template>
        </vue-cal>
        <!-- @event-focus="eventDetails" -->
      </div>
    </div>

    <div class="md-layout-item md-size-20 little-cal hidden-xs">
      <vue-cal
        v-if="!sidebarShow"
        v-bind:locale="locale"
        hide-view-selector
        :time="false"
        default-view="month"
        :dblclickToNavigate="false"
        :disable-views="['years', 'year', 'day', 'week']"
        :selected-date="calDate"
        @cell-click="selectDate"
        xsmall
      ></vue-cal>
    </div>
    <vue-cal
      v-bind:locale="locale"
      class="calendar hidden-xs"
      ref="vuecal"
      style="height: 79vh; width: 100%"
      :events="calendarEvents"
      :disable-views="['years', 'year']"
      :time-from="7 * 60"
      :time-to="21 * 60"
      :timeCellHeight="100"
      :showWeekNumbers="false"
      @cell-dblclick="createEvent($event)"
      :dblclickToNavigate="false"
      :selected-date="calDate"
      :on-event-click="eventDetails"
      :hide-view-selector="true"
      @view-change="getAppointmentsByDate('screen')"
    >
      <!-- hide-view-selector
      hide-title-bar-->

      <template v-slot:cell-content="{ cell, events }">
        <div class="row dayInfo m-0">
          <div
            class="col-6 pt-2 pl-2 align-item-center"
            style="height: 60px"
            :class="
              getDayLimit(cell) <= operationCount(cell, true) ? 'cancel-bg' : ''
            "
          >
            <div class="pt-2">
              <span class="" style="height: 50px"
                >{{ operationCount(cell) }} <br />
                Ameliyat</span
              >
            </div>
          </div>
          <div
            class="col-1 pt-2 pl-2 align-item-center"
            style="height: 60px; position: absolute; left: 40%; z-index: 999"
          >
            <div class="pt-2">
              <md-icon class="info" @click.native="showDayEventsCount(events)"
                >info</md-icon
              >
            </div>
          </div>
          <div
            class="col-6 pt-2 align-item-start"
            style="height: 60px"
            :class="
              getDayLimitNotSurgery(cell) <=
              operationCountNotSurgery(cell, true)
                ? 'cancel-bg'
                : ''
            "
          >
            <div class="pt-2">
              <span
                >{{ operationCountNotSurgery(cell) }} <br />
                Ameliyatsız</span
              >
            </div>
          </div>
        </div>
        <div v-if="showDatesOnCalander">
          {{ formatDate(cell.formattedDate) }}
        </div>
      </template>

      <template v-slot:time-cell="{ hours, minutes }">
        <div :class="{ line: true, hours: !minutes }" v-if="hours == 7"></div>
      </template>
    </vue-cal>

    <md-snackbar
      :md-position="'center'"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showLocalHoliday"
    >
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <div class="position-relative">
            <div class="top-0 end-0">
              <md-button
                style="
                  width: 35px;
                  height: 35px;
                  float: right;
                  color: white;
                  right: 0rem;
                  top: 0rem;
                  position: absolute;
                "
                class="md-fab md-plain"
                @click="showLocalHoliday = false"
                >X</md-button
              >
            </div>
          </div>
        </div>

        <span class="md-title md-size-100 md-layout-item">Kapalı Gün</span>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          Başlık/Açıklama:
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <b>{{ selectedLocalHoliday.title }}</b>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          Başlangıç:
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <b>{{ selectedLocalHoliday.start }}</b>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">Bitiş:</div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <b>{{ selectedLocalHoliday.end }}</b>
        </div>
      </div>

      <div class="md-layout-item md-size-100 dialogBtn deleteBtn md-accent">
        <md-button
          v-if="roleId == '1'"
          class="md-accent"
          v-on:click="deleteLocalHoliday"
        >
          <md-icon>delete</md-icon>Sil
        </md-button>

        <!-- <router-link
          :to="{
            name: 'updateAppointment',
            params: {
              appointmentId: selectedEvent.id
            }
          }"
        >
          <md-button class="md-primary">
            <md-icon>edit</md-icon>DÜZENLE
          </md-button> 
        </router-link>-->
      </div>
    </md-snackbar>

    <md-snackbar
      :md-position="'left'"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showDetails"
      class="scrollbarY"
    >
      <!-- dialog event details -->
      <div class="md-layout">
        <div
          style="
            position: sticky;
            z-index: 50;
            top: -1rem;
            margin-right: 2rem;
            text-align: -webkit-right;
          "
          class="md-layout-item md-size-100"
        >
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <div class="position-relative">
                <div class="top-0 end-0">
                  <md-button
                    style="
                      width: 35px;
                      height: 35px;
                      float: right;
                      color: white;
                      right: 0rem;
                      top: 0rem;
                      position: absolute;
                    "
                    class="md-fab md-plain"
                    @click="showDetails = false"
                    >X</md-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout-item little-table md-size-50 md-xsmall-size-100">
          <h4 class="text-white">Hasta Bilgileri</h4>

          <div class="md-layout">
            <div class="md-layout-item md-size-40">Ad Soyad</div>
            <router-link
              v-if="hasAccess() || roleId == 9"
              style="text-decoration: underline; color: #07d1f1"
              :to="{
                name: 'patients_details',
                params: { id: selectedEvent.patient.id },
              }"
            >
              <div class="md-layout-item">
                : {{ selectedEvent.patient.name }}
              </div>
            </router-link>
            <div class="md-layout-item" v-else>
              :
              {{
                hasAccess() || this.roleId == 9 || isDoctor || (this.roleId == 6 && this.userProfile.canSeeComments)
                  ? selectedEvent.patient.name
                  : hiddenName(selectedEvent.patient.name)
              }}
            </div>
          </div>

          <div class="md-layout" v-if="hasAccess() || this.roleId == 9">
            <div class="md-layout-item md-size-40">Telefon</div>
            <div class="md-layout-item">
              :
              {{ hasAccessPhone(selectedEvent.patient.phone) }}
            </div>
          </div>

          <div class="md-layout" v-if="hasAccess()">
            <div class="md-layout-item md-size-40">Kaynak</div>
            <div class="md-layout-item text-truncate">
              : {{ agencieName(selectedEvent.patient) }}
            </div>
          </div>

          <div class="md-layout" v-if="hasAccess() || this.roleId == 9">
            <div class="md-layout-item md-size-40">Ülke</div>
            <div class="md-layout-item">
              : {{ countryName(selectedEvent.patient) }}
            </div>
          </div>
        </div>
        <div class="md-layout-item little-table md-size-50 md-xsmall-size-100">
          <h4 class="text-white">Randevu Bilgileri</h4>
          <div class="md-layout">
            <div class="md-layout-item md-size-40">İşlem Tipi</div>
            <div class="md-layout-item">
              : {{ selectedEvent.operation.name }}
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-40">Konum</div>
            <div class="md-layout-item text-truncate">
              : {{ selectedEvent.location ? selectedEvent.location.name : "" }}
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-40">Katılımcılar</div>
            <div class="md-layout-item participants">
              :
              <span
                v-for="participant in selectedEvent.AppointmentsParticipants"
                v-bind:key="participant.id"
                >{{ participant.name }}</span
              >
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-40">Personel</div>
            <div class="md-layout-item">: {{ selectedEvent.user.name }}</div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-40">Durum</div>
            <div class="md-layout-item">
              : {{ selectedEvent.status.name }}
              <span
                v-if="
                  getLatestAppointmentNote(selectedEvent.statusHistory) != ''
                "
                >({{
                  getLatestAppointmentNote(selectedEvent.statusHistory)
                }})</span
              >
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-40 text-truncate">
              Son Güncelleyen
            </div>
            <div class="md-layout-item">
              :
              {{
                selectedEvent.lastUpdateUser
                  ? selectedEvent.lastUpdateUser.name
                  : selectedEvent.user.name
              }}
            </div>
          </div>
          <!-- <div class="md-layout">
            <div class="md-layout-item md-size-40 text-truncate">Randevu Tarihi</div>
            <div class="md-layout-item">
              : {{ formatDate(selectedEvent.date) }}
            </div>
          </div> -->
          <div class="md-layout" v-if="isCoordinator || isAdmin">
            <div class="md-layout-item md-size-40 text-truncate">
              Son Güncelleme Tarihi
            </div>
            <div class="md-layout-item">
              : {{ formatDate(selectedEvent.updateAt) }}
            </div>
          </div>
          <div class="md-layout" v-if="isCoordinator || isAdmin">
            <div class="md-layout-item md-size-40 text-truncate">
              Oluşturulma Tarihi
            </div>
            <div class="md-layout-item">
              : {{ formatDate(selectedEvent.createdAt) }}
            </div>
          </div>
        </div>

        <div
          class="md-layout-item little-table md-size-100"
          
        >
          <div class="md-layout" v-if="hasAccessForNotes()">
            <div class="md-layout-item md-size-33">Randevu Notu</div>
            <div class="md-layout-item ml-2">: {{ selectedEvent.note }}</div>
          </div>
          <div class="md-layout" v-if="hasAccess() || hasAccessForNotes()">
            <div class="md-layout-item md-size-33">Onay Formu</div>
            <md-button v-if="selectedEvent.fileContents.length > 0" @click="navigateAppoinmentDetailView(selectedEvent.guid)"
                class="md-icon-button md-raised md-dense"><md-icon>description</md-icon>
              </md-button>
          </div>
        </div>
        

        <!-- dialog event details -->

        <div class="md-layout-item md-size-100 dialogBtn deleteBtn md-accent">
          <md-button
            v-if="roleId == 1"
            class="md-accent"
            v-on:click="deleteModal = true"
          >
            <md-icon>delete</md-icon>Sil
          </md-button>
          <md-button
            v-if="hasAccessForStatus()"
            class="md-accent"
            v-on:click="
              showDetails = false;
              eventStatusModal = true;
            "
          >
            <md-icon>sync</md-icon>Durumu Güncelle
          </md-button>
          <md-button
            v-if="isAdmin"
            class="md-accent"
            v-on:click="
              showDetails = false;
              updateAppointmentUserModal = true;
            "
          >
            <md-icon>published_with_changes</md-icon>Temsilci Güncelle
          </md-button>
          <router-link
            v-if="hasAccess() && !selectedEvent.operation.isActivity"
            :to="{
              name: 'updateAppointment',
              params: {
                appointmentId: selectedEvent.id,
              },
            }"
          >
            <md-button class="md-accent">
              <md-icon>edit</md-icon>DÜZENLE
            </md-button>
          </router-link>
          <md-button
            v-if="isAdmin || (hasAccessForUpdateNote() && hasAccess())"
            @click="
              appointmentNoteModal = true;
              showDetails = false;
            "
            class="md-accent"
          >
            <md-icon>edit_note</md-icon>RANDEVU NOTU
          </md-button>
          <router-link
            v-if="hasAccess() && selectedEvent.operation.isActivity"
            :to="{
              name: 'updateActivity',
              params: {
                activityId: selectedEvent.id,
              },
            }"
          >
            <md-button class="md-accent">
              <md-icon>edit</md-icon>DÜZENLE
            </md-button>
          </router-link>
        </div>
      </div>
    </md-snackbar>

    <div class="md-layout">
      <div class="md-layout-item md-size-60 md-xsmall-size-100">
        <div class="md-layout temp-status">
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status waiting-bg"></div>
            Bekleniyor
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status done-bg"></div>
            Tamamlandı
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status cancel-bg"></div>
            İptal
          </div>

          <div class="md-layout-item md-xsmall-size-50">
            <div class="status postponed-bg"></div>
            Ertelendi
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status not-come-bg"></div>
            Gelmedi
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status optional-bg"></div>
            Opsiyonlandı
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status approve-bg"></div>
            Onay Bekleniyor
          </div>
          <div class="md-layout-item md-xsmall-size-50">
            <div class="status depositPaid-bg"></div>
            Depozit Alındı
          </div>
        </div>
      </div>
      <div class="md-layout-item text-right">
        <!-- sağ alt -->
        <button
          class="little-btn today"
          @click="modalHolidayList = true"
          v-if="(isAdmin || isHospitalCoordinator) && holidaysButton"
        >
          KAPALI GÜNLER
        </button>
      </div>
      <div class="md-layout-item text-right">
        <!-- sağ alt -->
        <button
          class="little-btn today"
          @click="modalHolidays = true"
          v-if="isAdmin || isHospitalCoordinator"
        >
          GÜN KAPAT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../main-source";
// import axios from "axios";
import { mapGetters } from "vuex";
import VueCal from "vue-cal";
import "vue-cal/dist/i18n/tr.js";
import "vue-cal/dist/vuecal.css";
import { setTimeout } from "timers";
import router from "../router";
import createHoliday from "@/components/holiday/create";
import holidayList from "@/components/holiday/holidayList";
import updateHoliday from "@/components/holiday/updateHoliday";
import moment from "moment";
import { notificationsMixin } from "@/mixins/notificationsMixin";

export default {
  name: "home",
  mixins: [notificationsMixin],
  data() {
    return {
      updateAppointmentUserModal: false,
      modalUpdateHoliday: false,
      selectedHoliday: null,
      holidaysButton: false,
      roleId: localStorage.getItem("roleId"),
      userProfile: null,
      title: "Panel",
      locale: "tr",
      calendarEvents: [],
      currentViewTitle: "",
      activeView: "",
      selectedAppointment: "",
      selectedEvent: {
        id: "",
        guid: "",
        fileContents: [] ,
        patient: {
          id: "",
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: "",
        },
        location: {
          name: "",
        },
        status: {
          id: null,
          name: null,
        },
        operation: {
          name: "",
        },
        user: {
          name: "",
        },
      },
      selectedLocalHoliday: [],
      user: "",
      location: 0,
      branch: null,
      operation: "",
      status: [],
      participant: {
        id: null,
      },
      locations: [],
      statuses: [],
      spinner: false,
      uploadedFiles: [],
      agencies: [],
      countries: [],
      branches: [],
      filterStatuses: [],
      allUsers: [],
      showDetails: false,
      showLocalHoliday: false,
      isInfinity: true,
      duration: 12000,
      eventStatusModal: false,
      appointmentNoteModal: false,
      deleteModal: false,
      calView: "week",
      isCoordinator: localStorage.getItem("roleId") == 4,
      calViews: [
        { id: "week", name: "Hafta" },
        { id: "day", name: "Gün" },
        { id: "month", name: "Ay" },
      ],
      isShowdayEventsCount: false,
      modalHolidays: false,
      modalHolidayList: false,
      dayOperations: [],
      operations: [],
      dayEvents: [],
      appointments: null,
      calDate: null,
      userId: localStorage.getItem("userId"),
      dayLimit: {},
      customLimits: [],
      weekendDays: [0, 6],
      calendars: {
        withSurgeryCurrentMonth: Number(moment().format("M")),
        nonSurgeryCurrentMonth: Number(moment().format("M")),
      },
      discountCounts: {
        withSurgery: {
          limit: null,
          used: null,
        },
        nonSurgery: {
          limit: null,
          used: null,
        },
      },
      leading: JSON.parse(localStorage.getItem("leading")),
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      isAdmin: localStorage.getItem("roleId") == 1,
      isDoctor: localStorage.getItem("roleId") == 2,
      isHospitalCoordinator: localStorage.getItem("roleId") == 4,
      isTransferUser: localStorage.getItem("roleId") == 7,
      filterShow: false,
      operationsCounter: {
        daysExceededLimit: [],
        quarter: [],
        half: [],
        threeQuarters: [],
        closed: [],
      },
      operationsCounterNotSurgery: {
        daysExceededLimit: [],
        quarter: [],
        half: [],
        threeQuarters: [],
        closed: [],
      },
      datePickerSelectAttribute: {
        highlight: {
          class: "brand-color-background",
          contentStyle: {
            color: "#FFF",
          },
        },
      },
      operationCounts: {
        isSurgery: {},
        isNotSurgery: {},
      },
      holidays: [],
      statusChangeNote: "",
      appointmentChangeUsers: [],
    };
  },
  props: ["sidebarShow"],
  components: {
    "vue-cal": VueCal,
    createHoliday,
    holidayList,
    updateHoliday,
  },
  mounted: async function () {
    console.log("calisti");
    this.roleId = localStorage.getItem("roleId");
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("date");
    if (myParam) {
      let date = new Date(myParam);
      this.calDate = date;
    } else {
      setTimeout(() => {
        this.setCalendarTitle();
      }, 200);

      this.getAppointments();
    }

    await this.getBranches();

    // get custom limits
    this.getCustomLimits();

    this.getOperationsCounter();

    this.getOperations();

    this.getLocations();

    HTTP.get("/agencies/").then((result) => {
      this.agencies = result.data;
    });

    HTTP.get("/countries/").then((result) => {
      this.countries = result.data;
    });

    HTTP.get("/status/").then((result) => {
      // I used JSON.prase then JSON.stringify to prevent copying and linking the same object to both arrays
      this.statuses = JSON.parse(JSON.stringify(result.data));
      this.statuses.reverse();

      this.filterStatuses = result.data;
    });

    this.getUsers();

    // setTimeout(() => {
    this.getLocalHolidays();
    // }, 1000);
    this.fillNotificationsList();
  },
  methods: {
    navigateAppoinmentDetailView(guid) {
      const route = this.$router.resolve({
        name: "appoinmentDetailView",
        params: { guid, },
      });

      window.open(route.href, "_blank");
    },
    changeDateWithSurgery: async function (event) {
      if (event.month !== this.calendars.withSurgeryCurrentMonth) {
        await this.getDiscountLimits("withSurgery", event.month);
        this.calendars.withSurgeryCurrentMonth = event.month;
      }
    },
    changeDateNonSurgery: async function (event) {
      if (event.month !== this.calendars.nonSurgeryCurrentMonth) {
        await this.getDiscountLimits("nonSurgery", event.month);
        this.calendars.nonSurgeryCurrentMonth = event.month;
      }
    },
    getCalendarName(type) {
      if (
        this.discountCounts[type].limit !== null &&
        this.discountCounts[type].used !== null
      ) {
        return `${this.discountCounts[type].used}/${this.discountCounts[type].limit} - MMM YYYY`;
      }
      return `MMMM YYYY`;
    },
    getDiscountLimits: async function (type, parameterMonth) {
      this.debounce = setTimeout(async () => {
        try {
          const month = parameterMonth || Number(moment().format("M"));
          const { data: result } = await HTTP.get(
            `/branches/${this.branch.id}/discount-usage?type=${type}&month=${month}`
          );
          this.discountCounts[type] = result;
        } catch (error) {
          console.log("error", error);
        }
      }, 100);
    },
    UpdateCalendarsFromDoctors() {
      console.log("UpdateCalendarsFromDoctors");
    },
    UpdateAppointmentUser() {
      this.selectedEvent.participants = [];

      this.selectedEvent.AppointmentsParticipants.forEach((participant) => {
        this.selectedEvent?.participants.push(participant.id);
      });

      HTTP.patch("/appointments/" + this.selectedEvent.id, this.selectedEvent)
        .then((result) => {
          this.updateAppointmentUserModal = false;
          this.$swal("", "Randevu Güncellendi", "success");
        })
        .catch((error) => {
          this.updateAppointmentUserModal = false;
          this.$swal(
            "",
            "Güncelleme Sırasında Bir Hata Meydana Geldi",
            "warning"
          );
        });
    },
    appointmentNoteUpdate() {
      HTTP.patch(
        "/appointments/" + this.selectedEvent.id + "/appointmentNote",
        {
          appointmentNote: this.selectedEvent.note,
        }
      ).then((result) => {
        if (result.status == 204) {
          this.appointmentNoteModal = false;
        }
      });
    },
    getLatestAppointmentNote: function (statusHistory) {
      if (!statusHistory) return "";
      let history = JSON.parse(statusHistory);
      let latestNote = history[history.length - 1];
      return latestNote.note;
    },
    getBranches: async function () {
      await HTTP.get(
        "/users/profile/" + this.userId + "?onlyModels=branch"
      ).then((result) => {
        let user = result.data[0];
        this.userProfile = result.data[0];
        this.branches = user.branches;

        if (this.branches.length > 0) this.branch = this.branches[0];
      });
    },
    getUsers: function () {
      HTTP.get("/users/").then((result) => {
        let allUsers = result.data;

        for (let i in allUsers) {
          if (allUsers[i].id == localStorage.getItem("userId")) {
            this.appointmentChangeUsers.push(allUsers[i]);
          }
        }

        if (localStorage.getItem("roleId") == 1) {
          for (let i in allUsers) {
            if ([3, 4].includes(allUsers[i].roleId)) {
              this.appointmentChangeUsers.push(allUsers[i]);
            }
          }
        } else {
          let viewerUser = allUsers.filter(
            (user) => user.id == localStorage.getItem("userId")
          )[0];
          let userLeading = JSON.parse(viewerUser?.leading);

          if (userLeading?.length > 0) {
            for (let i in allUsers) {
              if (userLeading.includes(allUsers[i].id)) {
                this.appointmentChangeUsers.push(allUsers[i]);
              }
            }
          }
        }

        this.allUsers = result.data.filter((user) => user.status == true);
      });
    },
    getOperations: function () {
      HTTP.get("/operations/").then((result) => {
        this.operations = result.data.filter(
          (operation) =>
            operation.status == true && operation.branchId == this.branch.id
        );
      });
    },

    getCustomLimits: function () {
      // get custom limits
      HTTP.get("/settings/customLimits/day-limit").then((result) => {
        this.customLimits = result.data;
      });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    agencieName: function (patient) {
      if (!patient.agencieId) return "";
      var agencie = this.agencies.find((z) => z.id == patient.agencieId);
      return agencie ? agencie.name : "";
    },
    countryName: function (patient) {
      if (!patient.countryCode) return "";
      var country = this.countries.find((z) => z.code == patient.countryCode);
      return country ? country.name_tr : patient.countryCode;
    },
    filterToggle: function () {
      this.filterShow = !this.filterShow;
    },
    canFilterByUser: function () {
      return (
        this.isAdmin || this.isHospitalCoordinator || this.leading.length > 0
      );
    },
    hasAccess: function () {
      let roleId = localStorage.getItem("roleId");
      let userId = localStorage.getItem("userId");
      if (roleId == 1 || this.isHospitalCoordinator) {
        return true;
      } else if (
        userId == this.selectedEvent.userId ||
        this.leading.includes(this.selectedEvent.userId)
      ) {
        return true;
      }
      return false;
    },
    hasAccessForUpdateNote: function () {
      let now = new Date().getTime();
      let appointmentDate = new Date(this.selectedEvent.date).getTime();
      if (appointmentDate >= now) {
        return true;
      }
      return false;
    },
    hasAccessForNotes: function () {
      let roleId = localStorage.getItem("roleId");
      let userId = localStorage.getItem("userId");
      if (roleId == 1 || this.isHospitalCoordinator || (roleId == 6 && this.userProfile.canSeeComments)) {
        return true;
      } else if (
        userId == this.selectedEvent.userId ||
        (this.leading.includes(this.selectedEvent.userId) && roleId != 9)
      ) {
        return true;
      } else if (
        this.isDoctor &&
        this.userProfile &&
        this.userProfile.canSeeComments
      ) {
        return true;
      }
      return false;
    },
    hasAccessForStatus: function () {
      let roleId = localStorage.getItem("roleId");
      let userId = localStorage.getItem("userId");
      if (this.selectedEvent.statusId == 7) {
        return roleId == 1 ? true : false;
      } else if (this.selectedEvent.statusId == 2) {
        return roleId == 1 ? true : false;
      }
      if (roleId == 1 || this.isHospitalCoordinator) {
        return true;
      } else if (
        (userId == this.selectedEvent.userId ||
          this.leading.includes(this.selectedEvent.userId)) &&
        this.selectedEvent.statusId != 2 &&
        roleId != 9
      ) {
        return true;
      }
      return false;
    },
    hasAccessForDelete: function () {
      let roleId = localStorage.getItem("roleId");
      let userId = localStorage.getItem("userId");
      if (
        (roleId == 1 ||
          userId == this.selectedEvent.userId ||
          this.leading.includes(this.selectedEvent.userId)) &&
        roleId != 9
      ) {
        return true;
      }
      return false;
    },
    hasAccessPhone: function (phone) {
      if (!["5", "6", "8"].includes(this.roleId)) return phone;
      if (!phone) return "";
      if (phone.length == 0) return "";
      if (this.roleId == 6) {
        var hiddenPhone =
          phone.substr(0, 4) +
          "****" +
          phone.substr(phone.length - 4, phone.length);
        return hiddenPhone;
      } else {
        var hiddenPhone =
          "******" + phone.substr(phone.length - 4, phone.length);
        return hiddenPhone;
      }
    },
    hiddenName: function (name) {
      if (!name) return "";
      var names = name.split(" ");
      if (names.length == 0) return "";
      else {
        var hiddenName = names[0] + " ";
        names.splice(1, 1).forEach(function (item) {
          hiddenName += item.substr(0, 1) + "*** ";
        });
        return hiddenName;
      }
    },
    setCalendarTitle: function () {
      this.currentViewTitle = "";
      let path = /\(.*\)/;
      this.currentViewTitle += this.$refs.vuecal.viewTitle
        .match(path)[0]
        .split(" 2")[0]
        .substr(1);
      this.currentViewTitle +=
        ", 2" +
        this.$refs.vuecal.viewTitle.match(path)[0].split(" 2")[1].substr(0, 3);
      this.activeView = this.$refs.vuecal ? this.$refs.vuecal.view.id : "";
      if (this.$refs.vuecal.view.id != "week") {
        this.currentViewTitle = "";
      }
      // this.currentViewTitle = "";
      // this.currentViewTitle = this.$refs.vuecal.viewTitle;
    },
    switchView: function (viewName) {
      switch (viewName) {
        case "month":
          this.activeView = "month";
          this.$refs.vuecal.switchView("month");
          break;
        case "week":
          this.activeView = "week";
          this.$refs.vuecal.switchView("week");
          break;
        case "day":
          this.activeView = "day";
          this.$refs.vuecal.switchView("day");
          break;
      }

      //if (window.innerWidth > 769) {
      let start, end;
      start = this.$refs.vuecal._data.view.startDate;
      end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
      // }
    },
    /* eslint-disable */
    getAppointments: async function (startDate, endDate) {
      var startText = "",
        endText = "";

      var uri = "/appointments/?type=onlyOperations";

      if (this.branch) {
        uri += "&branchId=" + this.branch.id;
      } else {
        uri += "&branchId=0";
      }

      if (this.location) {
        var locations = this.location.map((z) => z.id);
        uri += "&locationId=" + locations;
      }
      if (this.status) {
        var statuses = this.status.map((z) => z.id);
        uri += "&statusId=" + statuses;
      }

      /*if (this.participant?.id) {
        var participants = this.participant?.id;
        uri += "&participantsId=" + participants;
      }*/

      if (this.user) {
        var users = this.user.map((z) => z.id);
        uri += "&userId=" + users;
      }

      if (this.operation) {
        var operations = this.operation.map((z) => z.id);
        uri += "&operationId=" + operations;
      }
      var start = startDate;
      var end = endDate;
      if ((startDate, endDate)) {
        start = startDate;
        end = endDate; // burası
      } else {
        start = this.$refs.vuecal._data.view.startDate;
        end = this.$refs.vuecal._data.view.endDate;
      }
      startText =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        ("0" + start.getDate()).slice(-2);

      endText =
        end.getFullYear() +
        "-" +
        (end.getMonth() + 1) +
        "-" +
        ("0" + end.getDate()).slice(-2);
      uri += "&startDate=" + startText + "&endDate=" + endText;
      // console.log(startText + "-" + endText);
      /* eslint-disable */

      await HTTP.get(uri).then((appointments) => {
        this.calendarEvents = [];
        this.appointments = appointments.data;

        this.operationCounts.isSurgery = {};
        this.operationCounts.isNotSurgery = {};

        //console.log(this.appointments)
        appointments.data.forEach((appointment) => {
          // let time = appointment.time.substr(0, 5);
          let participants = appointment.AppointmentsParticipants;
          let participantText = "";
          participants.forEach((participant) => {
            participantText += "<br> · " + participant.name;
          });
          let eventStatusColor = "waiting-bg";
          switch (appointment.status.id) {
            case 2:
              eventStatusColor = "done-bg";
              break;
            case 3:
              eventStatusColor = "cancel-bg";
              break;
            case 4:
              eventStatusColor = "postponed-bg";
              break;
            case 5:
              eventStatusColor = "not-come-bg";
              break;
            case 6:
              eventStatusColor = "optional-bg";
              break;
            case 7:
              eventStatusColor = "approve-bg";
              break;
            case 8:
              eventStatusColor = "depositPaid-bg";
              break;
          }

          let start = new Date(
            (appointment.date + " " + appointment.time).replace(/-/g, "/")
          );
          let end = new Date(
            (appointment.date + " " + appointment.time).replace(/-/g, "/")
          );
          end.setMinutes(start.getMinutes() + appointment.duration);

          let startText =
            start.getFullYear() +
            "-" +
            (start.getMonth() + 1) +
            "-" +
            ("0" + start.getDate()).slice(-2) +
            " " +
            ("0" + start.getHours()).slice(-2) +
            ":" +
            ("0" + start.getMinutes()).slice(-2);
          let endText =
            end.getFullYear() +
            "-" +
            (end.getMonth() + 1) +
            "-" +
            ("0" + end.getDate()).slice(-2) +
            " " +
            ("0" + end.getHours()).slice(-2) +
            ":" +
            ("0" + end.getMinutes()).slice(-2);

          let title =
            appointment.patient && appointment.patient.name
              ? appointment.patient.name.split(" ")
              : ["isimsiz", ""];
          title =
            title[0] +
            " " +
            (title[1] ? title[1].substr(0, 1) : "") +
            ". - <span class='tag'>[" +
            appointment.operation.slug +
            "]</span>";

          let userId = localStorage.getItem("userId");

          // console.log(
          //   "durum",
          //   this.hasAccess(),
          //   appointment.userId == userId
          // );
          if (
            appointment.statusId != 7 ||
            appointment.userId == userId ||
            this.isAdmin
          ) {
            const participantIds = appointment.AppointmentsParticipants.map(
              (item) => item.id
            );

            const key = appointment.operation?.isSurgery
              ? "isSurgery"
              : "isNotSurgery";
            const startDate = moment(appointment.date).format("YYYY-MM-DD");
            const endDate = moment(appointment.date).format("YYYY-MM-DD");
            this.operationCounts[key][`${startDate}_${endDate}`] =
              this.operationCounts[key][`${startDate}_${endDate}`] || [];
            this.operationCounts[key][`${startDate}_${endDate}`].push({
              id: appointment.id,
              title: title,
              start: startDate,
              end: endDate,
              class: eventStatusColor,
              operation: appointment.operationId,
              status: appointment.statusId,
              doctorId: appointment.AppointmentsParticipants[0].id,
            });

            if (participantIds.includes(this.participant.id)) {
              this.calendarEvents.push({
                id: appointment.id,
                title: title,
                start: startText,
                end: endText,
                class: eventStatusColor,
                operation: appointment.operationId,
                status: appointment.statusId,
                operationType: key,
                isOperation: true,
              });
            }
          }
        });
      });

      setTimeout(() => {
        this.getLocalHolidays();
      }, 500);
    },
    getAppointmentsByDate: function (view) {
      let start, end;
      start =
        view == "screen"
          ? this.$refs.vuecal._data.view.startDate
          : this.$refs.vuecalmobile._data.view.startDate;
      end =
        view == "screen"
          ? this.$refs.vuecal._data.view.endDate
          : this.$refs.vuecalmobile._data.view.endDate;
      this.getAppointments(start, end);
    },
    getDayOccupancyRate(date, limits, count, type) {
      const day = new Date(date).getDay();
      const limit = limits["day" + day];
      // surgery = true
      if (count == 0 && limit > 0) return;
      else if (type) {
        if (count >= limit)
          this.operationsCounter.daysExceededLimit.push(new Date(date));
        else if (count == limit / 2)
          this.operationsCounter.half.push(new Date(date));
        else if (count < limit / 2)
          this.operationsCounter.quarter.push(new Date(date));
        else if (count > limit / 2)
          this.operationsCounter.threeQuarters.push(new Date(date));
      } else {
        if (count >= limit)
          this.operationsCounterNotSurgery.daysExceededLimit.push(
            new Date(date)
          );
        else if (count == limit / 2)
          this.operationsCounterNotSurgery.half.push(new Date(date));
        else if (count < limit / 2)
          this.operationsCounterNotSurgery.quarter.push(new Date(date));
        else if (count > limit / 2)
          this.operationsCounterNotSurgery.threeQuarters.push(new Date(date));
      }
    },
    getOperationCounterBySelectedDoctors() {
      var reqObject = { userIds: this.participant?.id };

      HTTP.post("/users/operationCounterByDoctors", reqObject)
        .then((result) => {
          const surgeryLimits = result.data.userLimits.Surgery;
          const activityLimits = result.data.userLimits.Activities;
          this.operationsCounter.daysExceededLimit = [];
          this.operationsCounter.half = [];
          this.operationsCounter.quarter = [];
          this.operationsCounter.threeQuarters = [];
          this.operationsCounterNotSurgery.daysExceededLimit = [];
          this.operationsCounterNotSurgery.half = [];
          this.operationsCounterNotSurgery.quarter = [];
          this.operationsCounterNotSurgery.threeQuarters = [];
          result.data.appointmentCounts.Surgeries.forEach((surgery) => {
            this.getDayOccupancyRate(
              surgery.date,
              surgeryLimits,
              surgery.count,
              surgery.operation.isSurgery
            );
          });
          result.data.appointmentCounts.Activities.forEach((activity) => {
            this.getDayOccupancyRate(
              activity.date,
              activityLimits,
              activity.count,
              activity.operation.isSurgery
            );
          });
        })
        .catch((result) => {
          console.log("Tahvim Yuklenemedi", result);
        });
    },
    getOperationsCounter: function () {
      HTTP.get("/settings/default/daylimit").then((result) => {
        result.data.forEach(
          (z) => (this.dayLimit[z.name] = z.transaction_limit)
        );
        //console.log(this.dayLimit.weekendNotSurgery)

        // getting operationsCount for date picker counter
        let branchId = this.branch ? this.branch.id : 0;
        HTTP.get("/appointments/operationsCount?branchId=" + branchId).then(
          (result2) => {
            //console.log("surgery")
            //console.log(result2.data)
            this.operationsCounter.daysExceededLimit = [];
            this.operationsCounter.half = [];
            this.operationsCounter.quarter = [];
            this.operationsCounter.threeQuarters = [];
            result2.data.forEach((z) => {
              var thisDayLimit = 0;

              var res = this.customLimits.filter(
                (cl) =>
                  new Date(cl.date).format("YYYY-MM-DD") == z.date &&
                  cl.operationType == "surgery"
              );
              if (res.length > 0) {
                thisDayLimit = res[0].limit;
              } else {
                var day = new Date(z.date).getDay();
                thisDayLimit = this.weekendDays.includes(day)
                  ? this.dayLimit.weekend
                  : this.dayLimit.weekdays;
              }
              if (z.operationsCount >= thisDayLimit)
                this.operationsCounter.daysExceededLimit.push(new Date(z.date));
              else if (z.operationsCount == thisDayLimit / 2)
                this.operationsCounter.half.push(new Date(z.date));
              else if (z.operationsCount < thisDayLimit / 2)
                this.operationsCounter.quarter.push(new Date(z.date));
              else if (z.operationsCount > thisDayLimit / 2)
                this.operationsCounter.threeQuarters.push(new Date(z.date));
            });
          }
        );
        HTTP.get("/appointments/notSurgeryCount?branchId=" + branchId).then(
          (result2) => {
            //console.log("notSurgery");
            //console.log(result2.data)
            this.operationsCounterNotSurgery.daysExceededLimit = [];
            this.operationsCounterNotSurgery.half = [];
            this.operationsCounterNotSurgery.quarter = [];
            this.operationsCounterNotSurgery.threeQuarters = [];
            //console.log(this.dayLimit)
            result2.data.forEach((z) => {
              var thisDayLimit = 0;

              var res = this.customLimits.filter(
                (cl) =>
                  new Date(cl.date).format("YYYY-MM-DD") == z.date &&
                  cl.operationType == "nonSurgery"
              );
              if (res.length > 0) {
                thisDayLimit = res[0].limit;
              } else {
                var day = new Date(z.date).getDay();

                var thisDayLimit = this.weekendDays.includes(day)
                  ? this.dayLimit.weekendNotSurgery
                  : this.dayLimit.weekdayNotSurgery;
              }

              if (z.notSurgeryCount >= thisDayLimit)
                this.operationsCounterNotSurgery.daysExceededLimit.push(
                  new Date(z.date)
                );
              else if (z.notSurgeryCount == thisDayLimit / 2)
                this.operationsCounterNotSurgery.half.push(new Date(z.date));
              else if (z.notSurgeryCount < thisDayLimit / 2)
                this.operationsCounterNotSurgery.quarter.push(new Date(z.date));
              else if (z.notSurgeryCount > thisDayLimit / 2)
                this.operationsCounterNotSurgery.threeQuarters.push(
                  new Date(z.date)
                );
            });
          }
        );
      });
    },
    // getPublicHolidays: function () {
    // axios
    //   .get(
    //     "https://www.googleapis.com/calendar/v3/calendars/tr.turkish%23holiday%40group.v.calendar.google.com/events?key=AIzaSyDNEVNiP7XjXpSDVmflzit_ersKVgrZ3ls"
    //   )
    //   .then((result) => {
    //     let confirmedHolidays = result.data.items.filter(
    //       (holiday) => holiday.status == "confirmed"
    //     );
    //     confirmedHolidays.forEach((holiday) => {
    //       this.calendarEvents.push({
    //         start: holiday.start.date + " 08:00",
    //         end: holiday.end.date + " 00:00",
    //         title: holiday.summary,
    //         class: "background",
    //         background: true,
    //       });
    //     });
    //   })
    //   .catch((e) => {
    //     console.log(e.response);
    //   });
    // },
    updateHoliday: function (holidayId) {
      this.selectedHoliday = this.holidays.filter((z) => z.id == holidayId);
      this.modalUpdateHoliday = true;
      this.modalHolidayList = false;
    },
    getLocalHolidays: function () {
      if (!this.branch) return;
      let branchId = this.branch.id;
      let closedDays = [0];
      if (this.participant && this.participant.closedDays) {
        closedDays = closedDays.concat(JSON.parse(this.participant.closedDays));
      }
      HTTP.get("holidays").then((result) => {
        this.operationsCounter.closed = [];
        this.holidays = result.data;
        this.holidaysButton = true;
        result.data.forEach((holiday) => {
          if (
            holiday.doctorId != null &&
            this.participant?.id !== holiday.doctorId
          ) {
            return;
          }

          if (
            holiday.branchId != branchId &&
            holiday.branchId != null &&
            this.participant?.id !== holiday.doctorId
          ) {
            return;
          }

          this.calendarEvents.push({
            id: holiday.id,
            start: holiday.start + " " + holiday.startTime.substr(0, 5),
            end: holiday.end + " " + holiday.endTime.substr(0, 5),
            branchId: holiday.branchId,
            doctorId: holiday.doctorId,
            title: holiday.title,
            class: "background",
            background: true,
            isLocalHoliday: true,
          });

          // mark holidays as closed in the datepicker view
          this.operationsCounter.closed.push({
            start: new Date(holiday.start),
            end: new Date(holiday.end),
          });
        });

        // loop dates shown in the calendar
        let start, end;
        start = this.$refs.vuecal._data.view.startDate;
        end = this.$refs.vuecal._data.view.endDate;

        for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          if (closedDays.includes(d.getDay())) {
            // if day is Sunday, close it by default
            let holidayStart = new Date(d);
            holidayStart.setHours(0, 0, 0);
            let holidayEnd = new Date(d);
            holidayEnd.setHours(23, 59, 59);
            this.calendarEvents.push({
              id: 0,
              start: holidayStart.format("YYYY-MM-DD HH:mm"),
              end: holidayEnd.format("YYYY-MM-DD HH:mm"),
              title: "Hafta günü kapalı",
              class: "background",
              background: true,
              isLocalHoliday: true,
            });
          }
        }
      });
    },
    createEvent: function (area) {
      //console.log(area);
      if (this.isDoctor || this.isTransferUser || this.roleId == 9) return;
      let holidays = this.calendarEvents.filter(
        (e) => e.isLocalHoliday == true
      );
      let canRedirect = true;
      let date = moment(area);
      // check if past Date
      if (date.diff(moment(), "days") < 0) {
        return;
      }

      if (holidays.length > 0) {
        holidays.forEach((h) => {
          if (date.isBetween(h.start, h.end, null, "[]")) {
            canRedirect = false;
          }
        });
        if (canRedirect) {
          router.push({
            name: "createAppointment",
            query: {
              datetime: area,
            },
          });
        }
      } else {
        router.push({
          name: "createAppointment",
          query: {
            datetime: area,
          },
        });
      }
    },
    setEventStatus: function () {
      if (this.statusChangeNoteRequired) return;
      // this.fileCount();
      // console.log(this.statusFile[this.statusFile.length-1].id)
      if (this.fileInputShow) {
        if (this.uploadedFiles.length > 0) {
          this.spinner = true;
          let fd = new FormData();
          this.uploadedFiles.forEach((file) => {
            fd.append("files", file.file, file.name);
          });
          fd.append("subject", "Durum Değişikliği");
          fd.append("statusId", this.selectedEvent.status.id);
          fd.append("filetypeId", 9);
          fd.append("patientId", this.selectedEvent.patientId);

          HTTP.post("/files/", fd, {
            headers: {
              "content-type": "multipart/form-data",
            },
          }).then(() => {
            HTTP.patch("/appointments/" + this.selectedEvent.id + "/status", {
              statusId: this.selectedEvent.status.id,
              lastUpdateUserId: localStorage.getItem("userId"),
              statusNote: this.statusChangeNote,
            }).then((result) => {
              if (result.status == 204) {
                this.spinner = false;
                this.uploadedFiles = [];

                this.eventStatusModal = false;
                this.getAppointments();
              }
            });
          });
        } else {
          this.$swal(
            "",
            "· Durum Değişikliği İçin Lütfen Bilet / Fee Dekont Ekleyiniz",
            "warning"
          );
        }
      } else {
        //console.log(this.selectedEvent)
        HTTP.patch("/appointments/" + this.selectedEvent.id + "/status", {
          statusId: this.selectedEvent.status.id,
          lastUpdateUserId: localStorage.getItem("userId"),
          statusNote: this.statusChangeNote,
        }).then((result) => {
          if (result.status == 204) {
            this.eventStatusModal = false;
            this.getAppointments();
          }
        });
      }
    },
    /*
    next: function () {
      this.$refs.vuecal.next();
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
      this.setCalendarTitle();
    },
        */
    /*
    prev: function () {
      this.$refs.vuecal.previous();
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
      this.setCalendarTitle();
    },
        */
    today: function () {
      this.calDate = new Date();
      if (window.innerWidth > 769) {
        this.$refs.vuecal.switchView("week");
        this.activeView = "week";
      }
    },
    operationCount: function (date, isReturnGeneralLimit = false) {
      const start = moment(date.startDate).format("YYYY-MM-DD");
      const end = moment(date.endDate).format("YYYY-MM-DD");
      const buildedKey = `${start}_${end}`;
      const general = this.operationCounts.isSurgery[buildedKey]
        ? this.operationCounts.isSurgery[buildedKey].length
        : 0;

      const doctor = this.operationCounts.isSurgery[buildedKey]
        ? this.operationCounts.isSurgery[buildedKey].filter(
            (item) => item.doctorId === this.participant.id
          ).length
        : 0;
      if (isReturnGeneralLimit) {
        return general;
      }
      return `${doctor} / ${general}`;
    },
    operationCountNotSurgery: function (date, isReturnGeneralLimit = false) {
      const start = moment(date.startDate).format("YYYY-MM-DD");
      const end = moment(date.endDate).format("YYYY-MM-DD");
      const buildedKey = `${start}_${end}`;

      const general = this.operationCounts.isNotSurgery[buildedKey]
        ? this.operationCounts.isNotSurgery[buildedKey].length
        : 0;

      const doctor = this.operationCounts.isNotSurgery[buildedKey]
        ? this.operationCounts.isNotSurgery[buildedKey].filter(
            (item) => item.doctorId === this.participant.id
          ).length
        : 0;

      if (isReturnGeneralLimit) {
        return general;
      }

      return `${doctor} / ${general}`;
    },
    showDayEventsCount: function (events) {
      this.isShowdayEventsCount = true;
      this.dayEvents = events;

      let dayOperations = [];

      events.forEach((e) => {
        if (!dayOperations.includes(e.operation)) {
          dayOperations.push(e.operation);
        }
      });

      this.dayOperations = dayOperations;
    },
    eventDetails: function (event) {
      if (this.isTransferUser) return;

      if (!event.isLocalHoliday) {
        HTTP.get("/appointments/" + event.id).then((result) => {
          // console.log(result.data);
          this.selectedEvent = result.data[0];
          setTimeout(() => {
            this.showDetails = true;
          }, 200);
        });
      }
      if (event.isLocalHoliday) {
        this.showLocalHoliday = true;
        this.selectedLocalHoliday = event;
      }
    },
    goAppointmentsList: function () {
      router.push({
        name: "AppointmentsList",
      });
    },
    onConfirm: function () {
      // console.log(this.selectedEvent.id)
      HTTP.delete("/appointments/?ids=" + this.selectedEvent.id).then(
        (result) => {
          if (result.status == 204) {
            this.getAppointments();
            this.showDetails = false;
          }
        }
      );
    },
    selectDate: function (val) {
      this.calDate = val;
    },
    deleteLocalHoliday: function () {
      HTTP.delete("holidays?ids=" + this.selectedLocalHoliday.id).then(
        (result) => {
          if (result.status == 204) {
            this.showLocalHoliday = false;
            this.getAppointments();
          }
        }
      );
    },
    refresh: function () {
      this.getAppointments();
    },
    getDayLimit: function (date) {
      // check custom limits for surgery
      var res = this.customLimits.filter(
        (cl) =>
          new Date(cl.date).format("YYYY-MM-DD") == date.formattedDate &&
          cl.operationType == "surgery"
      );
      if (res.length > 0) return res[0].limit;

      var day = new Date(date.formattedDate).getDay();
      return this.weekendDays.includes(day)
        ? this.dayLimit.weekend
        : this.dayLimit.weekdays;
    },
    getDayLimitNotSurgery: function (date) {
      // check custom limits for nonSurgery
      var res = this.customLimits.filter(
        (cl) =>
          new Date(cl.date).format("YYYY-MM-DD") == date.formattedDate &&
          cl.operationType == "nonSurgery"
      );
      if (res.length > 0) return res[0].limit;

      var day = new Date(date.formattedDate).getDay();
      return this.weekendDays.includes(day)
        ? this.dayLimit.weekendNotSurgery
        : this.dayLimit.weekdayNotSurgery;
    },

    getLocations: function () {
      HTTP.get("/locations/").then((result) => {
        this.locations = result.data.filter(
          (location) =>
            location.status == true && location.branchId == this.branch.id
        );
      });
    },
  },
  computed: {
    statusChangeNoteRequired: function () {
      return (
        [3, 4].includes(this.selectedEvent.status.id) &&
        this.statusChangeNote == ""
      );
    },
    fileInputShow: function () {
      if (
        (this.selectedEvent.status.id == 1 ||
          this.selectedEvent.status.id == 6) &&
        !this.isAdmin
      ) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      auth: "isLoggedIn",
    }),
    showDatesOnCalander: function () {
      return this.$refs.vuecal ? this.$refs.vuecal.view.id == "month" : false;
    },
    innerWidth: function () {
      return window.innerWidth;
    },
    datePickerAttributes: function () {
      // https://vcalendar.io/attributes.html
      var greenExcludes = this.operationsCounter.daysExceededLimit
        .concat(this.operationsCounter.closed)
        .concat({ weekdays: [1] });

      return [
        {
          bar: "red",
          dates: this.operationsCounter.daysExceededLimit,
        },
        {
          bar: "red",
          dates: this.operationsCounter.quarter,
        },
        {
          bar: "red",
          dates: this.operationsCounter.half,
        },
        {
          bar: "red",
          dates: this.operationsCounter.threeQuarters,
        },
        {
          bar: "red",
          dates: this.operationsCounter.threeQuarters,
        },
        {
          bar: "green",
          dates: this.operationsCounter.quarter,
        },
        {
          bar: "green",
          dates: {
            start: new Date(),
          },
          excludeDates: greenExcludes,
        },
        {
          bar: "gray",
          dates: this.operationsCounter.closed,
          highlight: {
            contentClass: "closedDays",
          },
        },
        {
          highlight: {
            contentClass: "closedDays",
          },
          bar: "gray",
          dates: {
            weekdays: [1],
          },
        },
      ];
    },
    datePickerAttributesNotSurgery: function () {
      // https://vcalendar.io/attributes.html
      var greenExcludes = this.operationsCounterNotSurgery.daysExceededLimit
        .concat(this.operationsCounterNotSurgery.closed)
        .concat({ weekdays: [1] });
      //console.log(this.operationsCounterNotSurgery)
      return [
        {
          bar: "red",
          dates: this.operationsCounterNotSurgery.daysExceededLimit,
        },
        {
          bar: "red",
          dates: this.operationsCounterNotSurgery.quarter,
        },
        {
          bar: "red",
          dates: this.operationsCounterNotSurgery.half,
        },
        {
          bar: "red",
          dates: this.operationsCounterNotSurgery.threeQuarters,
        },
        {
          bar: "red",
          dates: this.operationsCounterNotSurgery.threeQuarters,
        },
        {
          bar: "green",
          dates: this.operationsCounterNotSurgery.quarter,
        },
        {
          bar: "green",
          dates: {
            start: new Date(),
          },
          excludeDates: greenExcludes,
        },
        {
          bar: "gray",
          dates: this.operationsCounter.closed,
          highlight: {
            contentClass: "closedDays",
          },
        },
        {
          highlight: {
            contentClass: "closedDays",
          },
          bar: "gray",
          dates: {
            weekdays: [1],
          },
        },
      ];
    },
    users: function () {
      return this.allUsers.filter((user) => user.role.name != "Doctor");
    },
    participants: function () {
      const participants = this.allUsers.filter(
        (user) =>
          user.role.name == "Doctor" &&
          user.branches.map((z) => z.id).includes(this.branch.id) &&
          this.userDoctors.includes(user.id)
      );
      this.participant = participants[0];
      return participants;
    },
  },
  watch: {
    selectedEvent: function () {
      this.statusChangeNote = this.getLatestAppointmentNote(
        this.selectedEvent.statusHistory
      );
    },
    calView: function () {
      // this.$refs.vuecal._data.view.id = this.calView;
      this.$refs.vuecal.switchView(this.calView);
    },
    branch: function () {
      this.operation = [];
      this.getOperations();
      this.getDiscountLimits("withSurgery");
      this.getDiscountLimits("nonSurgery");
      this.participant = [];

      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);

      this.getOperationsCounter();
      setTimeout(() => {
        this.getLocalHolidays();
      }, 500);

      this.location = [];
      this.getLocations();
    },
    location: function () {
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
    },
    status: function () {
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
    },
    participant: function () {
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);

      this.getOperationsCounter();
    },
    operation: function () {
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
    },
    user: function () {
      let start = this.$refs.vuecal._data.view.startDate;
      let end = this.$refs.vuecal._data.view.endDate;
      this.getAppointments(start, end);
    },
    calDate: function () {
      // let start, end;
      // if (window.innerWidth < 769) {
      //   start = this.$refs.vuecalmobile._data.view.startDate;
      //   end = this.$refs.vuecalmobile._data.view.endDate;
      // } else {
      //   start = this.$refs.vuecal._data.view.startDate;
      //   end = this.$refs.vuecal._data.view.endDate;
      // }
      // this.getAppointments(start, end);
      this.setCalendarTitle();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

@media (max-width: 768px) {
  .md-snackbar-content {
    line-height: normal;
  }
}

.isNotSurgeryCalendar .vc-pane-container {
  background: #d5f5e3;
}
.closedDays {
  background-color: transparent;
  user-select: none;
  pointer-events: none;
}

.closedDays::after {
  position: absolute;
  content: "\274c";
  color: #fff;
  pointer-events: none;
  cursor: default;
}

.app-dial {
  top: 0px !important;
  .md-icon-button {
    i {
      color: $white !important;
    }
  }
}
.calendar-nav {
  float: right;
}
.sub-nav {
  button {
    border-right: 0;
    cursor: pointer;
    &:active {
      transform: scale(0.95);
    }
  }
  .next-prev {
    margin-right: 40px;
    display: inline;
  }
}
.calendar {
  margin-bottom: 15px;
  .vuecal__header {
    // overflow: hidden;
    border: none;
  }
  .vuecal__weekdays-headings {
    font-size: 11px !important;
    text-transform: uppercase !important;
    // border: none !important;
    color: $table_head_color !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .vuecal__now-line {
    color: $brandcolor;
  }
  .vuecal__cell.today,
  .vuecal__cell.current {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .vuecal__event-title {
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 3px;
  }
  .event {
    background-color: rgba(0, 222, 204, 0.4);
  }
}
.md-snackbar {
  max-height: 550px !important;
}
.md-snackbar-content {
  height: 540px;
  flex-wrap: wrap !important;
  justify-content: unset !important;
  .dialogBtn {
    text-align: right;
  }
  .md-button {
    margin: 0 !important;
    margin-right: 5px !important;
    font-size: 11px;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}
.statuses {
  padding: 15px;
}
.updateStatus {
  width: 100%;
  margin: 0 !important;
  background: $brandcolor !important;
}
.vuecal__event {
  // min-width: 50%;
  min-height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  font-size: 13px;
}
.btn-listAppointments {
  margin-left: 20px;
}
.dayInfo {
  border-bottom: none !important;
  font-size: 12px !important;
  //padding: 15px;
  font-weight: bold;
  background: #fff;
  border: 1px solid #f0f0f0;
  z-index: 4;
  .md-icon {
    font-size: 14px !important;
    color: darken($white, 20%) !important;
    cursor: pointer;
    &:hover {
      color: darken($white, 70%) !important;
    }
  }
  // span {
  //   display: block;
  // }
}
.temp-status {
  font-size: 12px;
  margin-top: 10px;
}
.vuecal.calendar {
  background: $white;
  border-radius: 5px;
  border-color: darken($white, 10%);
  .vuecal__weekdays-headings {
    background: $white !important;
    border: 1px solid darken($white, 5%) !important;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    .vuecal__heading {
      height: auto;
    }
    .weekday-label {
      flex-wrap: wrap;
      text-transform: capitalize;
      color: $dark;
      span {
        width: 100%;
        &:last-child {
          display: block !important;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          line-height: 25px;
        }
      }
    }
    .today {
      .weekday-label {
        span {
          &:last-child {
            background: $brandcolor;
            color: $white;
            &:after {
              content: "\00a0";
            }
          }
        }
      }
    }
  }
}
.vuecal--week-view {
  // .vuecal__title-bar {
  //   position: absolute;
  //   background: $white;
  //   font-size: 14px;
  //   padding: 15px 0px;
  //   .vuecal__flex.vuecal__title {
  //     width: 100px;
  //     overflow: hidden;
  //     margin-top: -10px;
  //   }
  //   .vuecal__arrow {
  //     position: absolute;
  //     top: 40px;
  //     // display: none !important;
  //     // pointer-events: none !important;
  //   }
  //   .vuecal__arrow--prev {
  //     left: 20px;
  //   }
  //   .vuecal__arrow--next {
  //     left: 35px;
  //   }
  // }
}
.custom-title {
  position: absolute;
  z-index: 1;
  background: #fff;
  bottom: -80px;
  left: 10px;
  max-width: 90px;
  word-wrap: break-word;
  text-align: center;
  font-size: 12px;
  .head {
    display: block;
    font-weight: bold;
  }
}
.title-wrap {
  position: relative;
}
.little-btn {
  background: #fff;
  border: none;
  padding: 5px 7px;
  border-radius: 3px;
  color: #1a1a1a !important;
  text-decoration: none !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  margin-right: 10px;
  cursor: pointer;
  i {
    color: $brandcolor !important;
  }
}
.today {
  height: 32px;
}
.calView {
  width: 130px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
.calFilter {
  min-width: 160px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
.daysCount {
  padding: 5px;
}
.filters {
  margin-bottom: 20px;
  .md-field {
    margin: 0;
  }
  .multi {
    i {
      color: $brandcolor !important;
      margin-right: 10px;
    }
    .md-xsmall-size-100 {
      margin-right: 10px;
    }
  }
}
.text-right {
  text-align: right;
}
.md-speed-dial {
  .md-speed-dial-content {
    pointer-events: none;
  }
}
.md-speed-dial.md-active {
  .md-speed-dial-content {
    pointer-events: all;
  }
}
@media (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }
  .calView {
    width: calc(100% - 55px) !important;
  }
  .md-speed-dial {
    .md-speed-dial-content {
      pointer-events: none;
    }
  }
  .md-speed-dial.md-active {
    z-index: 200;
    .md-speed-dial-content {
      pointer-events: all;
    }
  }
  .dialogBtn {
    text-align: center !important;
  }

  .filters {
    .multi {
      height: 0px;
      overflow: hidden;
      transition: all 0.25s ease;
    }
    .show {
      height: auto !important;
      overflow: visible !important;
    }
  }
  .filterBtn {
    display: flex !important;
    flex-direction: row-reverse;
  }
}
.little-cal {
  float: left;
  background: $white;
  margin-right: 15px;
}
.vuecal__event.background {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #f0f0f0 10px,
    #f0f0f0 20px
  ); /* IE 10+ */
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.vuecal__event.background .vuecal__event-time {
  display: none;
  align-items: center;
}
.md-dialog.md-theme-default {
  overflow: visible;
}
.vuecal__event .tag {
  // border: 1px solid $white;
  // padding: 0px 1px;
  // border-radius: 5px;
  // color: $white;
  font-size: 11px !important;
  // position: absolute;
  // left: 10px;
  // bottom: 2px;
}
.disabled-event {
  pointer-events: none !important;
}

.bg-white {
  background-color: white;
}
.dayEventsCountDetails {
  display: inline-block;
  .dayEventsCounter {
    margin-right: 15px;
    display: inline-block;
    margin-left: 5px;
    font-weight: bold;
  }
  .status {
    float: initial;
    display: inline-block;
  }
}
.operationTotalCount {
  font-weight: bold;
}
.md-dialog-actions {
  padding: 0rem 0rem 1rem 1rem !important;
}
.md-snackbar {
  margin-right: 24px !important;
  max-width: 750px !important;
}
</style>
