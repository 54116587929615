<template>
  <div class="wrapper">
    <span class="md-title">Branş</span>
    <md-button
      class="md-fab md-mini md-primary editButton"
      v-on:click="addBranchModal = true"
    >
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="branches">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Branş Adı">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Haftalık Limit">{{
          item.weeklyLimit
        }}</md-table-cell>
        <md-table-cell md-label="Aylık Ameliyatlı İndirimli İşlem Limiti">{{
          item.monthlyDiscountLimitWithSurgery
        }}</md-table-cell>
        <md-table-cell md-label="Aylık Ameliyatsız İndirimli İşlem Limiti">{{
          item.monthlyDiscountLimitNonSurgery
        }}</md-table-cell>
        <md-table-cell
          md-label="Kullanıcı Aylık Ameliyatlı İndirimli İşlem Limiti"
          >{{ item.monthlyUserDiscountLimitWithSurgery }}</md-table-cell
        >
        <md-table-cell
          md-label="Kullanıcı Aylık Ameliyatsız İndirimli İşlem Limiti"
          >{{ item.monthlyUserDiscountLimitNonSurgery }}</md-table-cell
        >
        <md-table-cell md-label="Düzenle">
          <i
            class="md-icon md-icon-font md-theme-default"
            v-on:click="updateBranch(item)"
            >edit</i
          >
          <!-- <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteBranch(item.id)">delete</i> -->
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addBranchModal" class="dialog">
      <md-content class="statuses">
        <h3>Yeni Branş</h3>
        <md-field>
          <label>Branş Adı *</label>
          <md-input v-model="newBranch.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false"
          >Lütfen Branş Adı Giriniz</span
        >
        <md-field>
          <label>Haftalık Limit (Sınırsız limit için 0 yazın)</label>
          <md-input v-model="newBranch.weeklyLimit"></md-input>
        </md-field>
        <md-field>
          <label>Aylık Ameliyatlı İndirimli İşlem Limiti</label>
          <md-input
            v-model="newBranch.monthlyDiscountLimitWithSurgery"
          ></md-input>
        </md-field>
        <md-field>
          <label>Aylık Ameliyatsız İndirimli İşlem Limiti</label>
          <md-input
            v-model="newBranch.monthlyDiscountLimitNonSurgery"
          ></md-input>
        </md-field>
        <md-field>
          <label
            >Kullanıcı Aylık Ameliyatlı İndirimli İşlem Limiti (Sınırsız limit
            için 0 yazın)</label
          >
          <md-input
            v-model="newBranch.monthlyUserDiscountLimitWithSurgery"
          ></md-input>
        </md-field>
        <md-field>
          <label
            >Kullanıcı Aylık Ameliyatsız İndirimli İşlem Limiti (Sınırsız limit
            için 0 yazın)</label
          >
          <md-input
            v-model="newBranch.monthlyUserDiscountLimitNonSurgery"
          ></md-input>
        </md-field>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="addBranch"
          >Ekle</md-button
        >
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateBranchModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Branş Adı *</label>
          <md-input v-model="branch.name"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.name === false"
          >Lütfen Lokasyon Adı Giriniz</span
        >
        <md-field>
          <label>Haftalık Limit (Sınırsız limit için 0 yazın)</label>
          <md-input v-model="branch.weeklyLimit"></md-input>
        </md-field>
        <md-field>
          <label>Aylık Ameliyatlı İndirimli İşlem Limiti</label>
          <md-input v-model="branch.monthlyDiscountLimitWithSurgery"></md-input>
        </md-field>
        <md-field>
          <label>Aylık Ameliyatsız İndirimli İşlem Limiti</label>
          <md-input v-model="branch.monthlyDiscountLimitNonSurgery"></md-input>
        </md-field>
        <md-field>
          <label
            >Kullanıcı Aylık Ameliyatlı İndirimli İşlem Limiti (Sınırsız limit
            için boş bırakın!)</label
          >
          <md-input
            v-model="branch.monthlyUserDiscountLimitWithSurgery"
          ></md-input>
        </md-field>
        <md-field>
          <label
            >Kullanıcı Aylık Ameliyatsız İndirimli İşlem Limiti (Sınırsız limit
            için boş bırakın!)</label
          >
          <md-input
            v-model="branch.monthlyUserDiscountLimitNonSurgery"
          ></md-input>
        </md-field>
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateBranchDone"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Branches",
  data() {
    return {
      title: "Branches",
      branches: [],
      branch: {
        name: "",
        weeklyLimit: 0,
        monthlyDiscountLimitWithSurgery: 0,
        monthlyDiscountLimitNonSurgery: 0,
        monthlyUserDiscountLimitWithSurgery: null,
        monthlyUserDiscountLimitNonSurgery: null,
      },
      newBranch: {
        name: "",
        weeklyLimit: 0,
        monthlyDiscountLimitWithSurgery: 0,
        monthlyDiscountLimitNonSurgery: 0,
        monthlyUserDiscountLimitWithSurgery: null,
        monthlyUserDiscountLimitNonSurgery: null,
      },
      addBranchModal: false,
      updateBranchModal: false,
      isValid: {
        name: null,
      },
    };
  },
  created() {
    this.getBranches();
  },
  methods: {
    addBranch: function () {
      this.newBranch.monthlyUserDiscountLimitWithSurgery =
        this.getNullableField(
          this.newBranch.monthlyUserDiscountLimitWithSurgery
        );
      this.newBranch.monthlyUserDiscountLimitNonSurgery = this.getNullableField(
        this.newBranch.monthlyUserDiscountLimitNonSurgery
      );
      HTTP.post("/branches/", this.newBranch).then((result) => {
        if (result.status == 201) {
          this.getBranches();
          this.addBranchModal = false;
          this.newBranch.name = "";
          this.newBranch.weeklyLimit = 0;
          this.newBranch.monthlyDiscountLimitWithSurgery = 0;
          this.newBranch.monthlyDiscountLimitNonSurgery = 0;
          this.newBranch.monthlyUserDiscountLimitWithSurgery = null;
          this.newBranch.monthlyUserDiscountLimitNonSurgery = null;
          this.isvalid = {
            name: null,
          };
        }
      });
    },
    getBranches: function () {
      HTTP.get("/branches/").then((result) => {
        this.branches = result.data;
      });
    },
    updateBranch: function (item) {
      this.branch = item;
      this.updateBranchModal = true;
    },
    updateBranchDone: function () {
      this.branch.monthlyUserDiscountLimitWithSurgery = this.getNullableField(
        this.branch.monthlyUserDiscountLimitWithSurgery
      );
      this.branch.monthlyUserDiscountLimitNonSurgery = this.getNullableField(
        this.branch.monthlyUserDiscountLimitNonSurgery
      );
      HTTP.put("/branches/" + this.branch.id, this.branch).then((result) => {
        if (result.status == 204) {
          this.getBranches();
          this.updateBranchModal = false;
        }
      });
    },
    deleteBranch: function (id) {
      HTTP.delete("/branches/" + id).then((result) => {
        if (result.status == 204) {
          this.getBranches();
        }
      });
    },
    getNullableField: function (value) {
      if (value === "") {
        return null;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
</style>