<template>
  <div>
    <md-dialog-prompt
      :md-active.sync="createNew"
      v-model="epicrisisName"
      md-title="Olusutulacak Epikrizin Adini Girin"
      md-input-maxlength="30"
      md-input-placeholder="Epikriz Adini Giriniz"
      md-confirm-text="Oluştur"
      @md-confirm="createEmptyEpicrisis"
    />
    <md-dialog
      id="addTemplate"
      :md-active.sync="addTemplate"
      class="dialog"
      @md-clicked-outside="addTemplate = false"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>Yeni Epikriz Şablonu Oluşturun</md-dialog-title>
      <md-content
        class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
      >
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <label><b>Template Adı</b></label>
          <md-field>
            <md-input v-model="newTemplate.name"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <label><b>Renk Kodu</b></label>
          <md-field>
            <md-input v-model="newTemplate.color"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100">
          <label><b>Footer</b></label>
          <md-field>
            <md-textarea v-model="newTemplate.footer"></md-textarea>
          </md-field>
        </div>
        <div class="md-layout md-size-50 md-xsmall-size-100">
          <a
            target="_blank"
            v-if="updateTemplate"
            :href="apiLink + newTemplate.imgPath"
            >Mevcut Dosyayı Görmek İçin Tıklanınız</a
          >
          <file-upload
            class="customfileinput"
            input-id="IMG"
            :accept="'.png'"
            v-model="templateImg"
          >
            <md-icon>add</md-icon>IMG Yuklemek icin tiklayiniz (255 x 52)
          </file-upload>
        </div>
        <div class="md-layout md-size-50 md-xsmall-size-100">
          <a
            target="_blank"
            v-if="updateTemplate"
            :href="apiLink + newTemplate.watermarkPath"
            >Mevcut Dosyayı Görmek İçin Tıklanınız</a
          >
          <file-upload
            class="customfileinput"
            input-id="watermark"
            :accept="'.png'"
            v-model="templateWatermark"
          >
            <md-icon>add</md-icon>Watermark Yüklemek icin tiklayiniz (596 x 800)
          </file-upload>
        </div>
        <md-button
          v-if="!updateTemplate"
          class="md-raised md-primary"
          @click="createTemplate"
          >Oluştur</md-button
        >
        <md-button
          v-if="updateTemplate"
          class="md-raised md-primary"
          @click="editTemplate"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>
    <md-dialog
      id="addTemplate"
      :md-active.sync="showTemplates"
      class="dialog"
      @md-clicked-outside="showTemplates = false"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>Epikriz Şablonları</md-dialog-title>
      <md-content
        class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
      >
        <md-table
          v-model="epicrisisTemplates"
          md-sort="name"
          md-sort-order="asc"
        >
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">Epikriz Raporları</h1>
            </div>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Dosya Adı" md-sort-by="name">{{
              item.name
            }}</md-table-cell>
            <md-table-cell md-label="Footer" md-sort-by="lastUpdateUser">{{
              item.footer
            }}</md-table-cell>
            <md-table-cell md-label="Renk" md-sort-by="lastUpdateUser">{{
              item.color
            }}</md-table-cell>
            <md-table-cell
              md-label="Son Güncelleme Tarihi"
              md-sort-by="updateAt"
              >{{ item.updatedAt.slice(0, 10) }}</md-table-cell
            >
            <md-table-cell
              md-label="Son Güncelleyen"
              md-sort-by="lastUpdateUser"
              >{{ getUser(item.lastUpdateUserId) }}</md-table-cell
            >
            <md-table-cell md-label="Düzenle / Sil" md-sort-by="edit">
              <div class="">
                <md-button @click="openEditTemplate(item)" class="md-succes">
                  <md-icon>edit</md-icon>
                </md-button>
              </div>
              <div class="">
                <md-button
                  v-if="true"
                  class="md-accent"
                  v-on:click="deleteTemplate(item.id)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-content>
    </md-dialog>
    <div class="md-layout details">
      <div class="md-layout-item md-size-100 md-xsmall-size-100 wrapper">
        <div class="md-layout">
          <span class="md-title title">Yeni Epikriz Ekle</span>
        </div>
        <div
          class="md-layout-item md-size-100 md-xsmall-size-100 md-layout"
          v-if="epicrisisContent"
        >
          <md-field
            class="md-layout-item calFilter md-xsmall-size-100 md-size-85"
          >
            <v-select
              v-model="selectedContent"
              placeholder="Dosyalar"
              :options="epicrisisContent"
              :value="id"
              label="name"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-field class="md-layout-item md-xsmall-size-100 md-size-10">
            <v-select
              v-model="selectedLanguage"
              placeholder="Diller"
              :options="languages"
              :value="id"
              label="name"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-button
            @click="createEpicrisis"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>content_copy</md-icon> Kopyasini olustur</md-button
          >
          <md-button
            @click="createNew = true"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>note_add</md-icon> Yeni olustur</md-button
          >
          <md-button
            @click="showAddEpicrisisTemplate"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>note_add</md-icon> Yeni Epikriz Şablonu Oluştur</md-button
          >
          <md-button
            @click="showTemplates = true"
            class="md-raised md-primary add-button md-size-20 md-xsmall-size-100 md-layout-item"
            ><md-icon>note_add</md-icon>Şablon Listesi</md-button
          >
        </div>
      </div>
      <div class="md-layout-item wrapper md-size-100">
        <!-- sams -->
        <md-table v-model="searched" md-sort="name" md-sort-order="asc">
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">Epikriz Raporları</h1>
            </div>

            <md-field md-clearable class="md-toolbar-section-end">
              <md-input
                placeholder="Search by name..."
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Dosya Adı" md-sort-by="name">{{
              item.name
            }}</md-table-cell>
            <md-table-cell md-label="Dil" md-sort-by="lastUpdateUser">{{
              item.language
            }}</md-table-cell>
            <md-table-cell
              md-label="Son Güncelleme Tarihi"
              md-sort-by="updateAt"
              >{{ item.updatedAt }}</md-table-cell
            >
            <md-table-cell md-label="Son Güncelleyen" md-sort-by="updateAt">{{
              item.lastUpdateUser
            }}</md-table-cell>
            <md-table-cell md-label="Düzenle / Sil" md-sort-by="edit">
              <div class="">
                <router-link
                  :to="{
                    name: 'updateEpicrisisContent',
                    params: {
                      id: item.id,
                    },
                  }"
                >
                  <md-button class="md-succes">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
              </div>
              <div class="">
                <md-button
                  v-if="true"
                  class="md-accent"
                  v-on:click="deleteEpircrisis(item.id)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-dialog-confirm
          :md-active.sync="deleteModal"
          md-title="Dosyayı silmek istediğinize emin misiniz?"
          md-content="Bu işlem geri alınamaz."
          md-confirm-text="Sil"
          md-cancel-text="İptal Et"
          @md-confirm="onConfirm()"
        />
      </div>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};
import { HTTP } from "@/main-source";
import moment from "moment";

export default {
  name: "Epicrisis",
  data() {
    return {
      apiLink: process.env.VUE_APP_API_URL,
      newTemplate: {
        id: null,
        name: "",
        color: "#",
        footer: "",
        imgPath: null,
        watermarkPath: null,
        lastUpdateUserId: localStorage.getItem("userId"),
      },
      imgUpdated: false,
      watermarkUpdated: false,
      epicrisisTemplates: null,
      templateImg: [],
      templateWatermark: [],
      addTemplate: false,
      showTemplates: false,
      createNew: false,
      epicrisisName: "",
      selectedLanguage: null,
      deleteModal: false,
      languages: false,
      epicrisis: null,
      epicrisisContent: null,
      users: [],
      search: null,
      searched: [],
      lastUpdateUserName: "",
      selectedContent: null,
      selectedEventId: null,
      created: false,
      isDietician: localStorage.getItem("roleId") == 9,
      isDoctor: localStorage.getItem("roleId") == 2,
      userId: localStorage.getItem("userId"),
    };
  },
  async created() {
    await HTTP.get("/users/").then((result) => {
      this.users = result.data;
    });
    await HTTP.get("/languages/").then((result) => {
      this.languages = result.data;
      //console.log(result.data);
    });
    this.getEpicrisisTemplates();
    this.getEpicrises();

    // c
  },
  methods: {
    getEpicrisisTemplates() {
      HTTP.get("/epicrisisTemplate/").then((result) => {
        this.epicrisisTemplates = result.data.filter(
          (x) => x.isEpicrisis == true
        );
      });
    },
    showAddEpicrisisTemplate() {
      this.addTemplate = true;
      this.updateTemplate = false;
    },
    openEditTemplate(item) {
      this.newTemplate.name = item.name;
      this.newTemplate.color = item.color;
      this.newTemplate.footer = item.footer;
      this.newTemplate.imgPath = item.img;
      this.newTemplate.watermarkPath = item.watermark;
      this.newTemplate.lastUpdateUserId = this.userId;
      this.newTemplate.id = item.id;
      this.updateTemplate = true;
      this.addTemplate = true;
      this.showTemplates = false;
      this.imgUpdated = false;
      this.watermarkUpdated = false;
    },
    async editTemplate() {
      if (this.imgUpdated) {
        let imgForm = new FormData();
        imgForm.append("subject", "Epikriz Template");
        imgForm.append("filetypeId", 9);
        imgForm.append(
          "files",
          this.templateImg[0].file,
          this.newTemplate.name + "IMG"
        );
        await HTTP.post("/files", imgForm, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }).then((result) => {
          this.newTemplate.imgPath = result.data.path;
        });
      }
      if (this.watermarkUpdated) {
        let watermarkForm = new FormData();
        watermarkForm.append("subject", "Epikriz Template");
        watermarkForm.append("filetypeId", 9);
        watermarkForm.append(
          "files",
          this.templateWatermark[0].file,
          this.newTemplate.name + "WATERMARK"
        );
        await HTTP.post("/files", watermarkForm, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }).then((result) => {
          console.log(result.data);
          this.newTemplate.watermarkPath = result.data.path;
        });
      }
      HTTP.post("/epicrisisTemplate/update/", this.newTemplate)
        .then((result) => {
          this.updateTemplate = false;
          this.imgUpdated = false;
          this.watermarkUpdated = false;
          this.addTemplate = false;
          this.getEpicrisisTemplates();
        })
        .catch((err) => {});
    },
    deleteTemplate(id) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlem geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/epicrisisTemplate/" + id)
            .then((result) => {
              this.getEpicrisisTemplates();
            })
            .catch((err) => {});
        }
      });
    },
    getUser(id) {
      return id, this.users.filter((z) => z.id == id)[0].name;
    },
    async createTemplate() {
      let imgForm = new FormData();
      imgForm.append("subject", "Epikriz Template");
      imgForm.append("filetypeId", 9);
      imgForm.append(
        "files",
        this.templateImg[0].file,
        this.newTemplate.name + "IMG"
      );
      await HTTP.post("/files", imgForm, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((result) => {
        this.newTemplate.imgPath = result.data.path;
      });
      let watermarkForm = new FormData();
      watermarkForm.append("subject", "Epikriz Template");
      watermarkForm.append("filetypeId", 9);
      watermarkForm.append(
        "files",
        this.templateWatermark[0].file,
        this.newTemplate.name + "WATERMARK"
      );
      await HTTP.post("/files", watermarkForm, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((result) => {
        this.newTemplate.watermarkPath = result.data.path;
      });
      console.log(this.newTemplate);
      HTTP.post("/epicrisisTemplate/", this.newTemplate)
        .then((result) => {
          this.addTemplate = false;
          this.getEpicrisisTemplates();
        })
        .catch((err) => {});
    },
    deleteEpircrisis: function (id) {
      this.selectedEventId = id;
      this.deleteModal = true;
      console.log(this.selectedEventId);
    },
    onConfirm: function () {
      console.log(this.selectedEventId);
      HTTP.delete("/epicrisisContent/" + this.selectedEventId).then(
        (result) => {
          if (result.status == 204) {
            this.getEpicrises();
          }
        }
      );
    },
    searchOnTable() {
      this.searched = searchByName(this.epicrisis, this.search);
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },

    getEpicrises() {
      this.searched = [];
      this.epicrisis = [];
      let params = {
        patientId: this.patientsId,
      };
      HTTP.get("/epicrisisContent/").then((result) => {
        this.epicrisisContent = result.data;
        this.epicrisis = result.data;
        for (const epi of result.data) {
          var temp = new Object();
          temp.name = epi.name;
          temp.id = epi.id;
          temp.language = this.languages.filter(
            (z) => z.id == epi.languageId
          )[0].name;
          temp.lastUpdateUser = this.users.find(
            (z) => z.id == epi.lastUpdateUserId
          ).name;
          temp.updatedAt = moment(epi.updatedAt).format("DD/MM/YYYY");
          this.searched.push(temp);
        }
        this.epicrisis = this.searched;
      });
    },

    createEpicrisis: function () {
      if (this.selectedLanguage == null) {
        this.$swal({
          icon: "warning",
          text: "Lütfen Epikriz Dilini Girdiginizden Emin Olun",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      let params = {
        languageId: this.selectedLanguage.id,
        name: this.selectedContent.name,
        content: this.selectedContent.content,
        slug: this.selectedContent.slug,
        lastUpdateUserId: this.userId,
      };
      HTTP.post("/epicrisisContent/", params)
        .then((res) => {
          if (res.status == 204) {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getEpicrises();
          }
        })
        .catch((e) => {
          this.message =
            "Bir şeyler ters gitti, lütfen girdiğiniz verileri kontrol ederek tekrar deneyiniz veya sistem yöneticinize başvurunuz.";
          console.log(e);
          console.log(e.message);
        });
    },
    createEmptyEpicrisis: function () {
      if (this.selectedLanguage == null) {
        this.$swal({
          icon: "warning",
          text: "Lütfen Epikriz Dilini Girdiginizden Emin Olun",
          timer: 1500,
        });
        return;
      }
      this.createNew = false;
      let params = {
        languageId: this.selectedLanguage.id,
        name: this.epicrisisName,
        slug: this.epicrisisName.replace(" ", "-"),
        languageId: this.selectedLanguage.id,
        lastUpdateUserId: this.userId,
      };
      //console.log(params)
      HTTP.post("/epicrisisContent/new", params)
        .then((res) => {
          if (res.status == 204) {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getEpicrises();
          }
        })
        .catch((e) => {
          this.message =
            "Bir şeyler ters gitti, lütfen girdiğiniz verileri kontrol ederek tekrar deneyiniz veya sistem yöneticinize başvurunuz.";
          console.log(e);
          console.log(e.message);
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    refreshAppointments() {
      this.getEpicrises();
    },
  },
  computed: {},

  watch: {
    templateImg() {
      this.imgUpdated = true;
    },
    templateWatermark() {
      this.watermarkUpdated = true;
    },
  },
};
</script>

<style lang="scss"  >
@import "@/assets/scss/colors.scss";
.md-select-menu {
  max-width: fit-content !important;
  width: fit-content !important;
}
.add-field {
  margin-left: 15px !important;
}
.add-button {
  margin-left: 15px !important;
}
.participants {
  display: block;
  span {
    display: inline-block;
  }
}

.table {
  width: 100%;
  margin-top: 0px;
}
.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}
.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}
.appointments {
  .md-table-row {
    cursor: pointer;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.pad {
  padding-right: 10px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;
    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}
.add-button {
  margin-left: 1rem;
}
.calFilter {
  min-width: 160px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
</style>
