<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 wrapper" ref="form">
      <div class="md-layout md-gutter">
        <span class="md-title title">Aktivite Düzenle</span>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <div>
            <span class="md-title">İşlem Tipi</span>

            <md-field>
              <v-select v-model="selectedOperation" placeholder="İşlem" :options="activityOperations" label="name"
                class="md-menu">
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>

            <span class="md-error" v-if="this.isValid.operation === false">İşlem seçmelisiniz</span>

            <md-field>
              <label>Katılımcılar</label>
              <md-select v-model="activityParticipant" name="participants" id="participants" placeholder="Katılımcılar">
                <md-option v-for="participant in participants" v-bind:value="participant.id"
                  v-bind:key="participant.id">{{ participant.name }}</md-option>
              </md-select>
            </md-field>
            <span class="md-error" v-if="this.isValid.participants === false">Katılımcı seçmelisiniz</span>

            <md-field>
              <label>Konum</label>
              <md-select v-model="activity.locationId" name="locations" id="locations" placeholder="Konum">
                <md-option v-for="location in locations" v-bind:value="location.id" v-bind:key="location.id">{{
                  location.name }}</md-option>
              </md-select>
            </md-field>
            <span class="md-error" v-if="this.isValid.location === false">Konum seçmelisiniz</span>
          </div>
        </div>
        <div class="md-layout-item">
          <div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-50 md-xsmall-size-100">
                <span class="md-title">Tarih & Saat</span>
                <md-datepicker v-model="activity.date" md-immediately :md-disabled-dates="isDateDisabled">
                  <label>Aktivite Tarihi</label>
                </md-datepicker>
                <span class="md-error" v-if="this.isValid.date === false">Randevu tarihi seçmelisiniz</span>
              </div>
              <div class="md-layout-item md-size-50 md-xsmall-size-100">
                <span class="md-title">Aktivite Saati</span>
                <md-field>
                  <vue-timepicker format="HH:mm" :hour-range="activityTimes" :minute-interval="timeDuration"
                    placeholder="SS:dd" v-model="activity.time" close-on-complete hide-disabled-hours></vue-timepicker>
                </md-field>
                <span class="md-error" v-if="this.isValid.time">Randevu saati seçmelisiniz</span>
              </div>
            </div>
            <div>
              <span class="md-title">Aktivite Notu</span>
              <md-field>
                <md-textarea v-model="activity.note" placeholder="Aktivite notu"></md-textarea>
              </md-field>
            </div>

            <span class="md-error" v-if="isDayClosed">
              Seçili tarih/saat kapalı olarak işaretlenmiş. Lütfen başka bir
              tarih/saat seçiniz.</span><br />
          </div>
        </div>
      </div>

      <div class="md-layout">
        <h5>Seçili Güne Ait Operasyonlar</h5>
        <md-content class="timeSelect">
          <md-table class="time-table">
            <md-table-row v-for="(dayAppointment, index) in dayOperations" v-bind:key="index">
              <md-table-cell md-label="Saat" class="selectTime">{{
                dayAppointment.time.substr(0, 5)
              }}</md-table-cell>
              <md-table-cell md-label="Operasyon">{{
                dayAppointment.operation.name
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-content>
        <div class="md-layout-item text-right">
          <md-checkbox v-model="activity.notify_me">Hatırlat</md-checkbox>
        </div>
        <md-button class="md-raised md-primary btn-right" v-if="!isDayClosed" v-on:click="updateActivity">Aktiviteyi
          Düzenle</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import router from "@/router";
import { patientProfileMixin } from "@/mixins/patientProfileMixin";
import moment from "moment";
//import router from "../router";

export default {
  name: "updateActivity",
  mixins: [patientProfileMixin],
  data() {
    return {
      clickable: true,
      dayOperations: null,
      activityOperations: [],
      appointments: {},
      time: null,
      userId: localStorage.getItem("userId"),
      patientId: null,
      startTime: 8,
      endTime: 20,
      activityTimes: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      timeDuration: 5,
      activity: {
        operationId: null,
        activityId: this.$route.params.activityId,
        isActivity: true,
        duration: 30,
        tags: "",
        date: null,
        time: null,
        notify_me: null,
        userId: null,
        note: null,
        participants: null,
        locationId: null
      },
      date: null,
      tag: "",
      tags: [],
      selectedOperation: null,
      activityParticipant: null,
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: "",
        },
        statusId: "",
        location: {
          name: "",
        },
        operation: {
          name: "",
        },
      },
      userDoctors: JSON.parse(localStorage.getItem("userDoctors")),
      participants: [],
      locations: [],
      isDayClosed: false,
      isValid: {
        patient: null,
        location: null,
        operation: null,
        participants: null,
        date: null,
        time: null,
      },
    };
  },
  components: {
    VueTimepicker,
  },
  async created() {
    const activityId = this.$route?.params?.activityId

    this.getAppointmentsByPatients();
    this.getActivityCalendarSettings();

    const users = await HTTP.get("/users/")
    this.participants = users.data.filter(
      (user) =>
        user.status == true &&
        user.roleId == 2
    );

    console.log(this.participants)


    const locations = await HTTP.get("/locations/")
    this.locations = locations.data.filter(
      (location) => location.status == true
    );

    const appointment = await HTTP.get(`/appointments/${activityId}`)
    this.activity = appointment?.data[0]
    this.activity.date = new Date(appointment?.data[0]?.date)
    this.activityParticipant = appointment?.data[0]?.AppointmentsParticipants[0]?.id

    const operations = await HTTP.get("/operations/")
    this.activityOperations = operations.data.filter(
      (operation) => operation.status == true && operation.isActivity == true
    );

    this.getOperationDuration(appointment?.data[0].operationId);
  },
  methods: {
    checkHoliday: function () {
      this.isDayClosed = false;

      if (!this.date) {
        return;
      }

      let date = moment(this.date);
      let closedDays = [0];
      if (this.activity.participants.length > 0) {
        this.activity.participants.forEach((doctorId) => {
          var doctor = this.participants.find((z) => z.id == doctorId);
          if (doctor.closedDays) {
            closedDays = closedDays.concat(JSON.parse(doctor.closedDays));
          }
        });
      }

      if (closedDays.includes(date.day())) {
        this.isDayClosed = true;
        return;
      }
      let params = {
        date: moment(this.date).format("YYYY-MM-DD"),
        time: this.activity.time,
        branchId: null,
        operationId: null,
        doctorId: this.activity.participants,
        duration: "0",
        isSurgery: false,
        isNotSurgery: false,
      };
      if (params.date == null || params.time == null) return;
      HTTP.get(
        "holidays/checkHoliday?" + this.serializeURLQueryParams(params)
      ).then((result) => {
        if (result.data.closedDay) {
          this.isDayClosed = true;
        }
      });
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    isDateDisabled: function (date) {
      return moment(date).isBefore(moment().format("YYYY-MM-DD"));
    },
    getAppointmentsByPatients: function () {
      HTTP.get("appointments/" + this.activity.activityId).then((result) => {
        this.patientId = result.data[0].patientId;
        this.selectedOperation = result.data[0].operation;
        this.getOperationDuration(this.selectedOperation.id);
        this.activity.note = result.data[0].note;

        this.activity.locationId = result.data[0].locationId;
        this.tags = result.data[0].tags;
        this.activity.date = new Date(result.data[0].date);
        this.date = new Date(result.data[0].date);
        this.activity.time = result.data[0].time;
        this.activity.notify_me = result.data[0].notify_me;
        this.userId = result.data[0].userId;
        this.appointments = result.data;
      });
    },
    getAppointmentsByDates: function () {
      let date = moment(this.date).format("YYYY-MM-DD");
      HTTP.get(`appointments?startDate=${date}&endDate=${date}`).then(
        (result) => {
          this.dayOperations = result.data;
          console.log(this.dayOperations);
        }
      );
    },
    is_timeString(str) {
      var regexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
      if (regexp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    updateActivity: function () {
      this.clickable = false;
      if (this.selectedOperation == null) {
        this.isValid.operation = false;
        return;
      } else {
        this.isValid.operation = true;
      }

      if (this?.activity?.participants?.length == 0) {
        this.isValid.participants = false;
        return;
      } else {
        this.isValid.participants = true;
      }

      if (this.date == null) {
        this.isValid.date = false;
        return;
      } else {
        this.isValid.date = true;
      }



      if (
        this.activity.time
      ) {
        this.isValid.time = false;
      } else {
        this.isValid.time = true;
        return
      }

      if (this.activity.locationId == null) {
        this.isValid.location = false;
        return;
      } else {
        this.isValid.location = true;
      }

      if (this.tags) {
        let i = 0;
        this.tags.forEach((tag) => {
          if (i != 0) {
            this.activity.tags += ", ";
          }
          this.activity.tags += tag.text;
          i++;
        });
      }

      this.activity.participants = [this.activityParticipant]

      if (this.activity.date) {
        this.activity.duration = this.selectedOperation.duration;
        HTTP.patch("/appointments/" + this.activity.id, this.activity)
          .then((result) => {
            if (result.status == 201) {
              router.push({
                name: "potantial.patients.activities",
                params: { id: this.patientId },
              });
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message === "notAvailable") {
              this.$swal({
                icon: "error",
                text: "Seçmiş olduğunuz gün ve saatte başka bir randevu bulunmaktadır!",
                showConfirmButton: true,
              });
            }
          });
      }
    },
    getActivityCalendarSettings: async function () {
      const { data: settings } = await HTTP.get(
        "/settings/default/activityCalendar"
      );
      this.startTime =
        settings?.find(({ name }) => name === "startTime")?.transaction_limit ||
        8;
      this.endTime =
        (settings?.find(({ name }) => name === "endTime")?.transaction_limit ||
          21) - 1;
      const hours = [];
      for (let hour = this.startTime; hour <= this.endTime; hour++) {
        hours.push(hour);
      }
      this.activityTimes = hours;
    },
    getOperationDuration(selectedOperationId) {
      const operation = this.activityOperations.find(
        (operation) => operation.id === selectedOperationId
      );
      this.timeDuration = operation?.duration || 5;
    },
  },
  watch: {
    selectedOperation: function () {
      this.activity.operationId = this.selectedOperation.id;
      this.getOperationDuration(this.selectedOperation.id);
    },
    "activity.participants": function () {
      this.checkHoliday();
    },
    date: function () {
      this.checkHoliday();
      this.getAppointmentsByDates();
    },
    time: function () {
      this.activity.time = this.time.HH + ":" + this.time.mm;
      //console.log(this.activity.time)
      this.checkHoliday();
    },
    "activity.locationId"(value) {
      console.log(value)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.participants {
  display: block;

  span {
    display: inline-block;
  }
}

.addButton {
  float: right;
}

.table {
  width: 100%;
  margin-top: 0px;
}

.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}

.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  max-width: 80vw;

  .md-dialog-title {
    padding: 0px;
  }
}

.appointments {
  .md-table-row {
    cursor: pointer;
  }
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.pad {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;

    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}

.vue-tags-input {
  max-width: none;
  margin-bottom: 20px;
}

.ti-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
</style>
