<template>
  <div class="wrapper">
    <span class="md-title">Airlines</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="createModal = true">
      <md-icon>add</md-icon>
    </md-button>
    <md-input v-model="search" type="text" class="search-input" placeholder="Arayınız" />
    <md-table class="table appointments" v-model="allAirlines">

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Ad">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Kod">{{ item.icao }}</md-table-cell>
        <md-table-cell md-label="Ülke">{{ item.country }}</md-table-cell>
        <md-table-cell md-label="Call Sign">{{ item.callsign }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateAirline(item)">edit</i>
        </md-table-cell>
        <md-table-cell md-label="Sil">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteAirlineBefore(item)">delete</i>
        </md-table-cell>
        <md-table-cell md-label="Aktif">
          <md-switch @change="itemIsActiveChange(item)" style="margin-left:15px;top:4px" v-model="item.isActive"
            class="md-primary"></md-switch>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="createModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field :class="isValidControl(newAirlineItem.name)">
          <label>Airline Adı *</label>
          <md-input v-model="newAirlineItem.name"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirlineItem.icao)">
          <label>Airline Kodu *</label>
          <md-input v-model="newAirlineItem.icao"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirlineItem.country)">
          <label>Airline Ülke *</label>
          <md-input v-model="newAirlineItem.country"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirlineItem.callsign)">
          <label>Airline Call Sign *</label>
          <md-input v-model="newAirlineItem.callsign"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="createAirline">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field :class="isValidControl(updateAirlineItem.name)">
          <label>Airline Adı *</label>
          <md-input v-model="updateAirlineItem.name"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirlineItem.icao)">
          <label>Airline Kodu *</label>
          <md-input v-model="updateAirlineItem.icao"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirlineItem.country)">
          <label>Airline Ülke *</label>
          <md-input v-model="updateAirlineItem.country"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirlineItem.callsign)">
          <label>Airline Call Sign *</label>
          <md-input v-model="updateAirlineItem.callsign"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateAirlineDone">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog-confirm :md-active.sync="deleteModal" md-title="Airline silmek istediğinize emin misiniz?"
      md-content="Bu işlem geri alınamaz." md-confirm-text="Sil" md-cancel-text="İptal Et"
      @md-confirm="deleteAirlineAfter()" />
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Airlines",
  data() {
    return {
      title: "Airlines",
      sources: [],
      source: {
        name: ""
      },
      isValid: {
        name: null,
        icao: null,
        country: null,
      },
      search: "",
      allAirlines: [],
      createModal: false,
      updateModal: false,
      deleteModal: false,
      newAirlineItem: {},
      updateAirlineItem: {},
      deletedItem: {},
    };
  },
  created() {
    this.getAllAirlines();
  },
  methods: {
    validationControl(validationSchema, item) {
      let validationControl = []
      validationSchema = Object.keys(validationSchema)
      for (let i in validationSchema) {
        item[validationSchema[i]]?.length > 0 ? validationControl.push(true) : validationControl.push(false)
      }

      return validationControl.includes(false) ? false : true
    },
    isValidControl(value) {
      return value?.length > 0 ? "" : "error-input"
    },
    getAllAirlines() {
      HTTP.get("/airlines?all=true").then((response) => {
        this.allAirlines = response.data;
      });
    },
    itemIsActiveChange(item) {
      HTTP.put("/airlines/update", item).then((result) => {
        const response = result?.data
        this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
      })
    },
    createAirline() {
      if (this.validationControl(this.isValid, this.newAirlineItem)) {
        HTTP.post("/airlines", { ...this.newAirlineItem, isActive: true }).then((result) => {
          const response = result?.data
          if (response?.isSuccess) {
            let newArr = []
            newArr.push(response?.data)
            for (let i in this.allAirlines) {
              newArr.push(this.allAirlines[i])
            }
            this.allAirlines = newArr
          }
          this.createModal = false
          this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
        })
      } else {
        this.$swal("", "Lütfen zorunlu alanları doldurunuz.", "error");
      }
    },
    updateAirline(item) {
      this.updateAirlineItem = item
      this.updateModal = true
    },
    updateAirlineDone() {
      if (this.validationControl(this.isValid, this.updateAirlineItem)) {
        HTTP.put("/airlines/update", this.updateAirlineItem).then((result) => {
          const response = result?.data
          this.updateModal = false
          this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
        })
      } else {
        this.$swal("", "Lütfen zorunlu alanları doldurunuz.", "error");
      }
    },
    deleteAirlineBefore(item) {
      this.deleteModal = true
      this.deletedItem = item
    },
    deleteAirlineAfter() {
      HTTP.delete("/airlines", this.deletedItem).then((result) => {
        const response = result?.data
        this.$swal("", response?.message, response?.isSuccess ? "success" : "error");
        if (response?.isSuccess) {
          this.allAirlines = this.allAirlines.filter(x => x?.id !== this.deletedItem?.id)
        }
      })
    },
  },
  watch: {
    search(val) {
      if (val) {
        HTTP.get("/airlines/search?all=true&q=" + this.search).then(
          (response) => {
            this.allAirlines = response?.data;
          }
        );
      } else {
        this.getAllAirlines();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.active {
  background: green;
}

.passive {
  background: red;
}

.search-input {
  width: 100%;
  height: 45px;
  border: 1px solid rgb(223, 222, 222);
  outline: 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  padding: 10px
}

.search-input:focus {
  border: 1px solid orange;
}

.error-input {
  border-bottom: 2px solid red;
}
</style>