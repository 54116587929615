<template>

    <div class="md-layout details">
      <BigLoading v-if="loading" />

      <div v-if="!loading" class="md-layout-item wrapper md-size-100">
      
        <md-button
              class="md-fab md-mini md-primary editButton"
              v-on:click="editable"
              v-if="isDisabled"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button 
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
              v-if="!isDisabled">
              <md-icon>save</md-icon>
            </md-button>
        <div  v-if="roleId == 1">
          
          <span class="md-title">Kaynaklar</span>
          <md-field>
            <label>Arayınız...</label>
            <md-input v-model="agenciesSearchInput"></md-input>
            <md-icon>search</md-icon>
          </md-field>
          <br/>
          <md-checkbox
           v-model="selectAllAgencies"
           v-bind:disabled="isDisabled"
           > Tümünü Seç </md-checkbox>
           <div v-for="agencie in searchFilter(agencies,agenciesSearchInput)" v-bind:key="agencie.id">
            <div v-if="!agencie.isDeleted" style="display : flex ; align-items:center">
                <md-checkbox
                v-bind:key="agencie.id"
                v-bind:value="agencie.id" 
                v-model="checkedAgencies"  
                v-bind:disabled="isDisabled"/> 
                <h5 style="margin-top:10px">{{ agencie.name }}</h5>
            </div>
                <br>
             <div style="display:flex ; flex-wrap:wrap">
                <div
                  v-for="child in agencie.childAgencies"
                   v-bind:key="child.id" >
                    <div v-if="!child.isDeleted">   
                        <md-checkbox 
                        v-model="checkedAgencies"
                        v-bind:disabled="isDisabled"
                        v-bind:value="child.id"
                        >{{ child.name }}</md-checkbox>
                    </div>

                 </div>
             </div>
          <br>
          <hr>
           </div>
          <!-- <md-checkbox
            v-for="agencie in searchFilter(agencies,agenciesSearchInput)"
            v-bind:key="agencie.id"
            v-bind:value="agencie.id"
            v-model="checkedAgencies"
            v-bind:disabled="isDisabled"
            >{{ agencie.name }}</md-checkbox
          > -->
          </div>

        </div>
      </div>

</template>

<script>
// import userList from "../../components/user/list";
import { HTTP } from "../../../main-source";
import BigLoading from "../../../components/bigLoading/index.vue";


export default {
    name: "Profile",
    data() {
        return {
            id: this.$route.params.id,
            selectAllAgencies: false,
            profile: [],
            roles: [],
            agencies: [],
            users: [],
            checkedAgencies: [],
            isDisabled: true,
            roleId: localStorage.getItem("roleId"),
            storagepath: this.$storage,
            agenciesSearchInput: "",
            loading : true,
        };
    },
    computed: {
        usersWithoutDoctors: function () {
            return this.users.filter(z => z.roleId != 2);
        },
        doctorsOfSelectedBranches: function () {
            return this.users.filter(z => z.roleId == 2 &&
                this.checkedBranches.filter(checkedBranch => z.branches.map(branch => branch.id).includes(checkedBranch)).length > 0);
        }
    },
    created() {
        HTTP.get("/agencies/getParent").then(result => {
        this.agencies = result.data;
        this.loading = false;
      });
      
        HTTP.get("/users/" + this.id + "/getAgenciesOfUser").then(result => {
            result.data[0].agenciesOfUser.filter(agencie => {
                this.checkedAgencies.push(agencie.id);
            });
        });
        // HTTP.get("/agencies/").then(result => {
        //     this.agencies = result.data;
        //     
        // });
        
    },
    methods: {

        selectAllChildAgencies: function($event,childAgencies){
            console.log(event,childAgencies);
            childAgencies.forEach(agencies => this.checkedAgencies.push(agencies.id));
        },
        
        editable: function () {
            this.isDisabled = !this.isDisabled;
        },
        editInformation: function () {
            // 
            HTTP.post("/users/" + this.id + "/updateAgencies", {
                agencies: this.checkedAgencies
            }).then(result => {
                if (result.status == 204) {
                    this.editable();
                }
            });
        },
        mergeChildAgenciesName(data){
            let fullNames = ""
            for(let i in data){
                fullNames+=data[i]?.name
            }
            return fullNames.toLowerCase()
        },
        searchFilter: function (data, search) {
            let searchValue = search.toLowerCase();
            let newArr = []

            for(let i in data){
                if(data[i].name.toLowerCase().match(searchValue) || this.mergeChildAgenciesName(data[i]?.childAgencies).match(searchValue)){
                    newArr.push(data[i])
                }
            }
            return  newArr
        }
    },
    watch: {
        "selectAllAgencies": function () {
            if (this.selectAllAgencies) {
                this.checkedAgencies = [];
                this.agencies.forEach(agencie => {
                    agencie.childAgencies.forEach(childAgencie => {
                        this.checkedAgencies.push(childAgencie.id);
                    });
                });
            }
            else {
                this.checkedAgencies = [];
            }
        },
    },
    components: { BigLoading }
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors.scss";


.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}


</style>

