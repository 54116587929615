<template>
  <div class="wrapper md-layout">
    <!-- direct Model dialog for switching the doctor  -->
    <md-dialog :md-active.sync="showDirectModal" class="dialog allowOverflow">
      <md-content class="statuses">
        <h3>Hastayı Yönlendir</h3>
        <v-select
          v-model="directToUser"
          placeholder="Kullanıcılar"
          :options="users"
          label="name"
          class="md-menu"
        >
          <template slot="option" slot-scope="option">{{
            option.name
          }}</template>
        </v-select>
        <md-checkbox v-model="directedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        >
        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showDirectModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="directComplete"
            >Yönlendir</md-button
          >
        </div>
      </md-content>
    </md-dialog>
    <md-dialog :md-active.sync="showShareModal" class="dialog allowOverflow">
      <md-content class="statuses p-5">
        <h3>Hastayı Paylaş</h3>
        <md-field>
          <v-select
            v-model="shareToUser"
            placeholder="Kullanıcılar"
            :options="users"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
        <md-field>
          <md-textarea
            v-model="sharingNote"
            placeholder="Paylaşma Notu"
          ></md-textarea>
        </md-field>

        <!-- <md-checkbox v-model="sharedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        > -->
        <div class="md-layout text-right pb-5" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showShareModal = false"
            >İptal</md-button
          >
          <md-button
            class="md-raised md-primary"
            v-on:click="shareComplete(), (showSharedModal = false)"
            >Paylaş</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <!-- Crm üzerindeki medikal form Geçici olarak kapatıldı --->
    <!-- <md-dialog  :md-active.sync="showTokenDialogModal" class="dialog">
      <md-dialog-title>Medikal Form</md-dialog-title>
      <md-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100">
            <a :href="formLink" class="wordWrap" target="_blank">{{formLink}}</a>
          </div>
          <div class="md-layout-item md-size-100">
          <md-checkbox v-model="allowEdit" @change="updateAllowEdit">Form editlemesine izin verin.</md-checkbox>
          </div>
          
        </div>
        <div class="md-layout text-right">
          <md-button
            class="md-raised md-default button-margins"
            v-on:click="showTokenDialogModal = false"
            >Kapat</md-button>
          <md-button class="md-raised md-accent button-margins" v-on:click="copyMedicalFormLink">
            Linki Kopyala
          </md-button>
        </div>
      </md-content>
    </md-dialog> -->

    <convert-modal
      :showBecomePatientModal="showBecomePatientModal"
      :patient="patient"
      @closeModal="closeConvertModal"
    ></convert-modal>

    <div class="md-layout-item md-size-100" v-if="patient.type == 0">
      <router-link
        :to="{ name: 'pool'}"
        class="goBackButtonLink"
      >
        <div class="goBackButton">
          <md-icon>arrow_back_ios</md-icon> GERİ GİT
        </div>
      </router-link>
    </div>
    <div class="md-layout-item md-size-40 topbar-profile md-layout">
      <div>
        <img
          :src="
            patient.profileImg
              ? storagepath + patient.profileImg
              : '/images/avatar.jpg'
          "
          alt="Avatar"
        />
      </div>
      <div>
        <span class="name">{{ patient.name ? patient.name : "" }}</span>
        <span class="subract">{{ patient.phone }}</span>
        <span class="subract"
          >
          {{ patient.language.name }}  {{ patient.secondLanguage && `/ ${patient.secondLanguage.name}` }} -
          {{
            patient.country ? patient.country.name_tr : patient.countryCode
          }}
          /
          <i v-for="(owner, index) in owners" :key="owner.id"
            >{{ index != 0 ? "," : "" }}{{ owner.name }}
          </i>
        </span>
      </div>

    </div>
    <div class="md-layout-item md-size-60 text-right head-buttons">
      <!-- <md-button class="md-raised md-primary button-margins" 
        @click="generateQues">Medikal Formu
      </md-button> -->
      <md-button
        class="md-raised md-primary button-margins"
        @click="medicalInfosDialog = true"
      >
        Medikal Formu
      </md-button>
      <md-button
        v-if="!isDietician && patient.type"
        class="md-raised md-primary button-margins"
        v-on:click="sharePatient"
        >Paylaş</md-button
      >
      <md-button
        class="md-raised md-primary button-margins"
        v-on:click="showDirect"
        v-if="!isPatient && (roleId == 1 || patient.userId == userId)"
        >Yönlendir</md-button
      >

      <md-button
        class="md-raised md-primary button-margins"
        @click="navMailPage"
        >E-Posta Gönder</md-button
      >
      <!-- <md-button
        class="md-raised md-primary button-margins"
        @click="becomePatient"
        v-if="!isPatient && !saveStatus"
        >Hastaya Dönüştür</md-button
      > -->
      <md-button
        class="md-raised md-accent button-margins"
        v-on:click="deletePatient"
        v-if="roleId == 1"
        ><md-icon>delete</md-icon>Sil</md-button
      >

      <md-button
        class="md-raised md-default button-margins"
        v-if="prev"
        v-on:click="prevRecord"
      >
        <md-icon>keyboard_arrow_left</md-icon>Önceki
      </md-button>
      <md-button
        class="md-raised md-default button-margins"
        v-if="next"
        v-on:click="nextRecord"
      >
        Sonraki
        <md-icon>keyboard_arrow_right</md-icon>
      </md-button>
    </div>
    <md-dialog
      :md-active.sync="medicalInfosDialog"
      :md-click-outside-to-close="true"
      class="dialog"
    >
      <md-dialog-title>
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-90">Medikal Formlar</div>
          <div class="md-layout-item md-xsmall-size-10">
            <md-button
              style="width: 35px; height: 35px; float: right"
              class="md-fab md-plain"
              @click="medicalInfosDialog = false"
              >X</md-button
            >
          </div>
        </div>
      </md-dialog-title>
      <md-content>
        <div class="wrapper">
          <div class="">
            <span class="title">Dosyalar</span>
          </div>
          <div class="">
            <span v-if="files.length == 0">Medikal form mevcut degil</span>
          </div>
          <md-table class="table files" v-model="files">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Konu">
                <a :href="generateLink(item.path)" target="blank">
                  <md-icon>open_in_new</md-icon>
                  {{ item.subject ? item.subject : item.name }}
                </a>
              </md-table-cell>
              <md-table-cell md-label="Eklenme Tarihi">{{
                dateString(item.createdAt)
              }}</md-table-cell>

              <md-table-cell md-label="Ekleyen">{{
                item.userId ? item.user.name : ""
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-content>
    </md-dialog>
  </div>
</template>


<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import { required } from "vuelidate/lib/validators";
import convertModal from "./convertModal.vue";

export default {
  components: { convertModal },
  name: "potantials.patient.topbar",
  data: function () {
    return {
      next: null,
      prev: null,
      //patient: [],
      patientTreatmentList: "",
      showBecomePatientModal: false,
      patientAgencieName: "",
      storagepath: this.$storage,
      offers: [],
      patienedFile: [],
      patienedFileOk: false,
      patientsId: this.$route.params.id,
      approvedOffer: null,
      isPatient: true,
      isEditable: true,
      medicalInfosDialog: false,
      showDirectModal: false,
      showShareModal: false,
      owners: null,
      directToUser: null,
      shareToUser: null,
      users: [],
      roleId: localStorage.getItem("roleId"),
      isDietician: localStorage.getItem("roleId") == 9,
      userId: localStorage.getItem("userId"),
      patSource: null,
      patientSource: null,
      userAction: "direct",
      showInvalidErrors: false,
      temporaryConversion: false,
      allowEdit: true,
      showTokenDialogModal: false,
      formLink: "",
      sharingNote: null,
      files: [],
      directedPatientsToBeRecall : ""
    };
  },
  validations: {
    patient: {
      name: { required },
      status: { required },
      treatmentId: { required },
      countryCode: { required },
      estimated_amount: {},
      agencieId: { required },
      source: {},
      languageId: { required },
    },
  },
  props: ["renew", "patId", "parent", "patient", "saveStatus"],
  created: function () {
    this.getUsers()
    this.setNextAndPrev();
    this.getOffers();
    this.getFiles();
    this.getAllUsers()


    HTTP.get("/medicalinfos/" + this.$route.params.id).then((result) => {
      if (result.data[0]) this.allowEdit = result.data[0].allow_edit;
    });
 
  },
  methods: {
    async getUsers(){
      const users = await HTTP.post("/users/roleIds",{
        ids : [3,4]
      });

      this.users = users.data;
    },
    async getAllUsers(){
      const users = await HTTP.get("/users");

      var ownersId = [];
      ownersId.push(this.patient.userId);

      if (this.patient.secondUserId) {
        ownersId.push(this.patient.secondUserId);
      }
      if (this.patient.sharingHistory) {
        JSON.parse(this.patient.sharingHistory).forEach((z) => {
          ownersId.push(z.sharedTo);
        });
      }
      this.owners = users?.data?.filter((a) => ownersId.includes(a.id));
    },
    dateString: function (date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    generateLink(path) {
      return path.startsWith("http") ? path : this.storagepath + path;
    },
    getFiles: async function () {
      await HTTP.get("files/" + this.patientsId).then((result) => {
        this.files = [];
        this.files = result.data.filter((z) => z.filetype.id == 8);
      });
    },
    setNextAndPrev: function () {
      let uri = "/patients/potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "/patients/";
      }

      // if (this.roleId != 1 && this.roleId != 4) {
      //         uri += "&user=" + localStorage.getItem("userId");
      //       }
      var userLimits = this.roleId != 1 ? "?userId=" + this.userId : "";

      HTTP.get(uri + this.$route.params.id + "/nextprev" + userLimits)
        .then((result) => {
          // this.patient = result.data[0];
          this.isPatient = result.data[0].type;
          this.patientAgencieName = this.patient.agencie
            ? this.patient.agencie.name
            : "";

          result.data[1] ? (this.next = result.data[1].id) : (this.next = null);
          result.data[2] ? (this.prev = result.data[2].id) : (this.prev = null);

          this.patientTreatments();
        })
        .catch(() => {
          HTTP.get("/patients/" + this.$route.params.id).then((result) => {
            // this.patient = result.data[0];
            this.isPatient = result.data[0].type;
          });
        });
    },
    getOffers: function () {
      HTTP.get("/offers/" + this.$route.params.id).then((result) => {
        result.data.forEach((offer) => {
          let nameString =
            this.totalPriceString(offer.totalPrice) + " " + offer.currencySlug;
          this.offers.push({
            name: nameString,
            id: offer.id,
          });
        });
      });
    },
    prevRecord: function () {
      let uri = "potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "patients/";
      }
      window.location = this.$appDomain + uri + this.prev + "/general";
    },
    nextRecord: function () {
      let uri = "potantials/";
      if (this.$route.path.includes("patients")) {
        uri = "patients/";
      }
      window.location = this.$appDomain + uri + this.next + "/general";
    },
    navMailPage: function () {
      router.push({
        name: "potantial.patients.mails",
        id: this.$route.params.id,
      });
    },
    becomePatient: function () {
      this.showBecomePatientModal = true;
    },
    patientTreatments: function () {
      if (this.patient.treatments.length > 0) {
        HTTP.get("/operations").then((result) => {
          this.patient.treatments.forEach((element) => {
            this.patientTreatmentList +=
              " & " +
              result.data.filter((r) => r.id == element.operationId)[0].name;
          });
          this.patientTreatmentList = this.patientTreatmentList.substring(3);
        });
      }
    },
    totalPriceString: function (total) {
      var totalString = new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      })
        .format(total)
        .substr(1);
      return totalString;
    },
    goToOffer: function () {
      router.push({
        name: "potantial.patients.offers",
        id: this.patientsId,
      });
    },
    showDirect() {
      this.showDirectModal = true;
      this.userAction = "direct";
    },
    sharePatient() {
      this.showShareModal = true;
      this.userAction = "share";
    },
    directComplete: function () {
      let data = {};
      if (this.userAction == "direct"){
      data.userId = this.directToUser.id;
      data.directedPatientsToBeRecall = this.directedPatientsToBeRecall
      } 
        
      else if (this.userAction == "share")
        data.secondUserId = this.directToUser.id;

      HTTP.patch("patients/" + this.patientsId, data).then(() => {
        this.directToUser = null;
        this.$swal("", "Başarıyla yönlendirildi", "success");
        this.$emit("updated");
        this.showDirectModal = false;
      });
    },

    shareComplete: function () {
      this.showSharedModal = false;
      let data = {};
      data.sharedUserId = this.shareToUser.id;
      data.sharingNote = this.sharingNote;
      // data.selectedOperation = this.selectedOperation.id;
      // data.sharingType = '0';

      HTTP.patch("patients/shareFromTopbar/" + this.patientsId, data).then(
        () => {
          this.sharedToUser = null;
          this.selectedPatientId = null;
          this.$swal("", "Başarıyla Paylaşıldı", "success");
          this.$emit("updated");
        }
      );
    },

    deletePatient: function () {
      this.$swal({
        title: "Emin misiniz?",
        text: "Seçilen hastanın tüm bilgileri, randevuları işlemleri ve dosyaları SİLENECEKTİR.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.delete("/patients?ids=" + this.patient.id).then(() => {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            if (this.isPatient) router.push({ name: "patients" });
            else router.push({ name: "potantials" });
          });
        }
      });
    },
    closeConvertModal() {
      this.showBecomePatientModal = false;
    },
    openMedicalFormModal(token) {
      var siteLink = process.env.VUE_APP_APP_URL;
      this.formLink = siteLink + "potantials/ques/" + token;
      this.showTokenDialogModal = true;
    },
    sendEmail(token) {
      var siteLink = process.env.VUE_APP_APP_URL;
      this.formLink = siteLink + "potantials/ques/" + token;
      if (this.patient.email) {
        let emailLink = {
          to: this.patient.email,
          from: localStorage.getItem("email"),
          cc: "",
          subject: "HE bağlantı formu",
          patientId: this.patient.id,
          files: [],
          message: 'Lütfen <a href="' + this.formLink + '">formu</> doldurun',
          oid: "null",
        };
        HTTP.post("/mails", emailLink);
      }
      this.showTokenDialogModal = true;
    },
    generateQues() {
      let pToken = this.patient.PatientTokens.find(
        (z) => z.form_type == "medicalinfos"
      );
      if (pToken) this.openMedicalFormModal(pToken.token);
      else {
        HTTP.get("/medicalinfos/token/" + this.$route.params.id, {
          email: this.patient.email,
        })
          .then((result) => {
            if (result.status == 202) {
              this.patient.PatientTokens.push({
                form_type: "medicalinfos",
                token: result.data.token,
              });
              this.openMedicalFormModal(result.data.token);
            } else if (result.status == 404) alert(result.data.message);
          })
          .catch((e) => {
            alert(e.response.data.message);
          });
      }
    },
    copyMedicalFormLink() {
      try {
        navigator.clipboard.writeText(this.formLink);
        this.$swal({
          icon: "success",
          text: "Kopyalandı",
          showConfirmButton: false,
          timer: 1000,
        });
      } catch ($e) {
        this.$swal({
          icon: "error",
          text: "Kopyalanamadı!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    updateAllowEdit() {
      HTTP.put("/medicalinfos/allow/" + this.$route.params.id, {
        allow_edit: this.allowEdit,
      })
        .then((result) => {
          if (result.status != 200)
            this.$swal({
              icon: "error",
              text: result.data.message,
              showConfirmButton: true,
            });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            text: e.message,
            showConfirmButton: true,
          });
        });
    },
  },
  watch: {
    renew: function () {
      this.getOffers();
    },
  },
};
</script>

<style lang="scss"  >
.topbar-profile {
  img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 10px;
  }
  span {
    display: block;
    margin-top: 5px;
  }
  .name {
    font-size: 24px;
    font-weight: bold;
  }
}
.talert {
  margin-bottom: 10px !important;
  button {
    margin-left: 15px !important;
  }
}
.md-layout.link {
  margin-bottom: 10px;
  a,
  i {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.smallSelect ul {
  max-height: 250px;
}
.scrollbarY {
  overflow-y: auto;
  overflow-x: hidden;
}
button.button-margins {
  margin: 3px !important;
}
.goBackButton {
  background-color: #666;
  color: #fff;
  font-weight: bold;
  margin-top: -15px;
  margin-left: -15px;
  margin-bottom: 15px;
  padding: 10px;
  width: 110px;

  .md-icon {
    color: #fff !important;
  }
}
.goBackButtonLink {
  &:hover,
  &:active {
    text-decoration: none !important;
  }
}
.wordWrap {
  word-wrap: break-word;
}
</style>
