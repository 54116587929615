<template>
  <div id="app" v-bind:class="{ ohid: sidebarShow }">
    <div class="sidebar" v-if="auth" v-bind:class="{ noView: !sidebarShow }">
      <sidebar v-if="auth"></sidebar>
    </div>
    <div
      class="main-panel"
      v-bind:class="{ clear: !auth, full: !sidebarShow, moved: sidebarShow }"
    >
      <md-toolbar md-elevation="1" v-if="auth">
        <h3 class="md-title">
          <md-icon @click.native="sidebarToggle">menu</md-icon>Panel
        </h3>
        <div class="md-toolbar-section-end">
          <div v-if="!isLoading">
            <div v-if="isFastConnection">
              <ion-icon name="wifi" style="color: #8EAC50;" />
            </div>
            <div v-else-if="isSlowConnection">
              <ion-icon name="wifi" style="color: red;" />
            </div>
            <div v-else>
              <ion-icon name="wifi" style="color: grey;" />
            </div>
          </div>
          <md-menu md-size="medium" md-align-trigger>
            <md-button md-menu-trigger>
              {{ userName }}
              <md-icon>keyboard_arrow_down</md-icon>
            </md-button>

            <md-menu-content>
              <md-button class="md-secondary" v-on:click="goToProfile">
                Profilim
              </md-button>
              <md-button class="md-secondary" v-on:click="signOut">
                Çıkış
                <md-icon>exit_to_app</md-icon>
              </md-button>
            </md-menu-content>
          </md-menu>
          <md-avatar class="avatar">
            <img src="../public/images/avatar.jpg" alt="Avatar" />
          </md-avatar>
        </div>
      </md-toolbar>

      <div class="content" v-bind:class="{ loginpage: !auth }">
        <transition name="fade" mode="out-in">
          <router-view :sidebarShow="sidebarShow" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar";
import router from "./router";
import { mapGetters, mapActions } from "vuex";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export default {
  name: "App",

  data() {
    return {
      userName: "",
      sidebarShow: true,

      isLoading: true,
      connectionType: "",
      bandwidth: "",
    };
  },
  created: async function() {
    if (window.innerWidth < 769) {
      this.sidebarShow = false;
    }

    let name = "";
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      if (cookie.split("=")[0].trim() == "name") {
        name = cookie.split("=")[1];
      }
    });
    if (name) {
      this.userName = name;
    }
  },
  async mounted() {
    if (navigator.connection) {
      await navigator.connection.ready;

      this.connectionType = navigator.connection.effectiveType;
      this.bandwidth = navigator.connection.downlink.toFixed(2);

      this.isLoading = false;
    } else {
      this.connectionType = "Unknown";
      this.bandwidth = "N/A";

      this.isLoading = false;
    }
  },
  computed: {
    ...mapGetters({
      auth: "isLoggedIn",
    }),
    isFastConnection() {
      return (
        this.connectionType === "4g" ||
        this.connectionType === "5g" ||
        this.bandwidth >= 10
      );
    },
    isSlowConnection() {
      return (
        this.connectionType === "2g" ||
        this.connectionType === "3g" ||
        this.bandwidth < 2
      );
    },
  },
  components: {
    sidebar,
  },
  methods: {
    ...mapActions({
      logOut: "signOut",
    }),
    setName: function() {
      let name = "";
      name = localStorage.getItem("name");
      if (name) {
        this.userName = name;
      }
    },
    signOut: function() {
      deleteAllCookies();
      localStorage.removeItem("token");
      localStorage.removeItem("roleId");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      this.logOut();
      router.push({
        name: "login",
      });
    },
    goToProfile: function() {
      router.push({
        name: "users.profile.general",
        params: { id: localStorage.getItem("userId") },
      });
    },
    sidebarToggle: function() {
      this.sidebarShow = !this.sidebarShow;
    },
  },
  watch: {
    auth: function() {
      this.setName();
    },
    $route: function() {
      if (screen.width < 769) {
        this.sidebarShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clear {
  width: 100%;
  float: none;
}
.loginpage {
  width: 100%;
  height: 100vh;
  float: none;
  margin: 0;
  background-image: url("../public/images/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.sidebar {
  transition: all 0.3s ease;
}
.noView {
  transform: translate3d(-480px, 0, 0);
  padding: 0;
}
.full {
  width: 100%;
  float: left;
}
.wraptext {
  white-space: break-spaces;
}
.markAsReadButton {
  margin-right: auto !important;
  color: green !important;
}
@media (max-width: 767.98px) {
  .moved {
    transform: translate3d(85vw, 0, 0);
  }
  .ohid {
    overflow: hidden;
  }
  .content {
    padding: 15px;
  }
}
</style>

<style>
ion-icon {
  font-size: 25px;
}
</style>
