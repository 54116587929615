<template>
  <login />
</template>

<script>
// @ is an alias to /src
import login from "@/components/login.vue";

export default {
  name: "Login",
  components: {
    login
  }
};
</script>
