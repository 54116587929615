<template>
  <div>
    <div class="md-layout">
      <h1>{{ title }}</h1>
      <md-speed-dial class="md-top-right" md-direction="bottom" md-event="click">
        <router-link to="/users/create">
          <md-speed-dial-target class="md-primary">
            <md-button class="md-icon-button">
              <md-icon>add</md-icon>
            </md-button>
          </md-speed-dial-target>
        </router-link>
      </md-speed-dial>
    </div>
    <div class="md-layout mb-2">
      <md-field>
              <label>Kullanıcı Adı</label>
              <md-input
                v-model="search_username"
              ></md-input>
            </md-field>
    </div>
    <div class="md-layout md-gutter">
      <userWrap
        v-for="user in users"
        :name="user.name"
        :role="user.role"
        :imgUrl="user.profileImg ? storagepath + user.profileImg : null"
        :id="user.id"
        :key="user.id"
      ></userWrap>
    </div>
  </div>
</template>

<script>
import userList from "../../components/user/list";
// import router from "../router";
import { HTTP } from "../../main-source";

export default {
  name: "Users",
  data() {
    return {
      title: "Kullanıcılar",
      users: [],
      allUsers: [],
      search_username: "",
      storagepath: this.$storage,
    };
  },
  components: {
    userWrap: userList
  },
  created() {
    HTTP.get("/users").then(result => {
      this.users = result?.data;
      this.allUsers = result?.data;
      console.log(result.data)
    });
  },
  watch: {
    search_username: function(username){
      this.users = this.allUsers.filter(z => z.name?.toLowerCase().includes(username.toLowerCase()));
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";
.md-top-right {
  top: unset !important;
  margin-top: 10px;
  button {
    // background: $brandcolor !important;
    width: 40px;
    height: 40px;
  }
}
.mb-2{
  margin-bottom: 2rem;
}
</style>
