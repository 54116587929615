<template>
  <div class="patient">
    <md-card>
      <md-avatar class="avatar">
        <img src="../../../public/images/avatar.jpg" alt="Avatar" />
      </md-avatar>
      <md-card-content class="desc">
        <!-- <span class="title">{{ name }}</span>
        {{ role }}-->
        <span class="title">{{ name }}</span>
        <span>{{ country ? country.name_tr : "" }}</span>
        <span>{{ phone }}</span>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "patientList",
  data() {
    return {};
  },
  props: ["id", "name", "country", "phone", "imgUrl"]
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";
.patient {
  width: 100%;
  transition: all 0.3s ease !important;
  background: $white;
  .avatar {
    width: 72px;
    height: 72px;
    margin: 15px 0px 15px 15px !important;
    float: left;
  }
  .desc {
    float: left;
    margin: 10px 10px 10px 5px;
    &:after {
      clear: both;
    }
  }
  span {
    display: block;
  }
  .title {
    font-weight: bold;
    margin-left: 0px;
  }
  .md-card-content {
    padding: 10px;
    color: $dark;
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none !important;
  }
}
.md-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04) !important;
}
</style>