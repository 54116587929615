<template>
  <div>
    <BigLoading v-if="loading" />
    <div v-if="!loading" class="md-layout wrap">
      <div
        class="md-layout-item md-xsmall-size-100 md-small-size-100 item profile-wrap"
        style="overflow-x: scroll"
      >
        <div class="md-layout-item profile wrapper">
          <file-upload
            ref="upload"
            class="file profile-img"
            v-model="image"
            accept="image/png, image/gif, image/jpeg"
            extensions="gif,jpg,jpeg,png"
          >
            <img
              :src="
                profile.profileImg
                  ? storagepath + profile.profileImg
                  : '/images/avatar.jpg'
              "
              alt="Avatar"
            />
            <img src="/images/add.jpg" class="add" alt />
          </file-upload>

          <div class="main">
            <span class="title">{{ profile.name }}</span>
            {{ profile.role ? profile.role.name : profile.roleId }}
          </div>
          <div class="secondary">
            <div class="md-layout">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">phone</i>
              </div>
              <div class="md-layout-item">{{ phoneHolder }}</div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">mail</i>
              </div>
              <div class="md-layout-item">{{ profile.email }}</div>
            </div>
          </div>
          <div class="md-layout">
            <md-switch v-model="profile.status" class="md-primary">{{
              profile.status ? "Aktif Kullanıcı" : "Pasif Kullanıcı"
            }}</md-switch>
          </div>
          <div class="md-layout">
            <md-switch
              v-model="profile.getAppointmentsNotification"
              class="md-primary"
              >{{
                profile.getAppointmentsNotification
                  ? "Hafta içi randevuların bildirimi alacak"
                  : "Hafta içi randevuların bildirimi almaz"
              }}</md-switch
            >
          </div>
          <div class="md-layout">
            <md-switch
              v-model="profile.getWhatsappNotifications"
              class="md-primary"
              >{{
                profile.getWhatsappNotifications
                  ? "Whatsapp bildirimleri açık"
                  : "Whatsapp bildirimleri kapalı"
              }}</md-switch
            >
          </div>
          <div class="md-layout" v-if="roleId == 1 && profile.roleId == 2">
            <md-switch v-model="profile.canSeeComments" class="md-primary">{{
              profile.canSeeComments
                ? "Randevu Yorumları Görebilir"
                : "Randevu Yorumları Göremez"
            }}</md-switch>
          </div>
          <div class="md-layout" v-if="roleId == 1">
            <md-switch v-model="appointmentNotification" class="md-primary"
              >Hastane Randevu Bildirimleri</md-switch
            >
          </div>
          <div class="md-layout" v-if="roleId == 1 && appointmentNotification">
            <md-switch
              v-model="profile.appointmentNotification.notification.whatsapp"
              v-on:change="updateNotification"
              class="md-primary"
            >
              Whatsapp</md-switch
            >
            <md-switch
              v-model="profile.appointmentNotification.notification.email"
              v-on:change="updateNotification"
              class="md-primary"
              >E-Mail
            </md-switch>
          </div>
          <div class="md-layout" v-if="roleId == 1">
            <md-switch v-model="clinicAppointmentNotification"  class="md-primary"
              >Klinik Randevu Bildirimleri</md-switch
            >
          </div>
          <div class="md-layout" v-if="roleId == 1 && clinicAppointmentNotification">
            <md-switch
              v-model="profile.isActivityAppointmentWhatsappNotification"
              class="md-primary"
              v-on:change="updateClinicNotificationWhatsapp"

            >
              Whatsapp</md-switch
            >
            <md-switch
              v-model="profile.isActivityAppointmentEmailNotification"
              class="md-primary"
              v-on:change="updateClinicNotificationEmail"
              >E-Mail
            </md-switch>
          </div>
        </div>

     


        <div class="wrapper" v-if="roleId == 1">
          <md-button
            class="md-fab md-mini md-primary editButton"
            v-on:click="editable('roles')"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <span class="md-title">Kullanıcı Yetkileri</span>
          <md-field>
            <md-select
              v-model="profile.roleId"
              v-bind:disabled="isDisabled.roles"
            >
              <md-option
                v-for="role in roles"
                v-bind:value="role.id"
                v-bind:key="role.id"
                >{{ role.name }}</md-option
              >
            </md-select>
          </md-field>

          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="editRoles"
            v-if="!isDisabled.roles"
            >Değiştir</md-button
          >
        </div>

        <div class="wrapper">
          <md-button
            class="md-fab md-mini md-primary editButton"
            v-on:click="editable('password')"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <span class="md-title">Şifre Değiştir</span>
          <md-field>
            <label>Yeni Şifre</label>
            <md-input
              v-model="password"
              type="password"
              v-bind:disabled="isDisabled.password"
            ></md-input>
          </md-field>
          <md-field>
            <label>Yeni Şifre Onay</label>
            <md-input
              v-model="password1"
              type="password"
              v-bind:disabled="isDisabled.password"
            ></md-input>
          </md-field>
          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="editPassword"
            v-if="!isDisabled.password"
            >Değiştir</md-button
          >
        </div>
      </div>
      <!-- İlk sutun -->

      <div class="md-layout-item item md-xsmall-size-100 md-small-size-100">
        <div class="wrapper">
          <md-button
            class="md-fab md-mini md-primary editButton"
            v-on:click="editable('information')"
            v-if="isDisabled.information"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <md-button
            class="md-fab md-mini md-danger editButton"
            v-on:click="editInformation"
            v-if="!isDisabled.information"
          >
            <md-icon>save</md-icon>
          </md-button>
          <span class="md-title">Bilgiler</span>
          <md-field>
            <label>Ad Soyad</label>
            <md-input
              v-model="profile.name"
              v-bind:disabled="isDisabled.information"
            ></md-input>
          </md-field>
          <md-field class="tel-input">
            <vue-phone-number-input
              v-model="profile.phone"
              :show-code-on-list="true"
              :translations="{
                countrySelectorLabel: 'Ülke',
                countrySelectorError: 'Hata',
                phoneNumberLabel: 'Telefon Numarası',
                example: 'Örnek :',
              }"
              @update="telInput"
              :error="!phoneValidate"
              :clearable="!isDisabled.information"
              v-bind:disabled="isDisabled.information"
            ></vue-phone-number-input>
          </md-field>
          <div v-if="!phoneValidate" class="error">
            <p>Bu numara başka bir kullanıcıya tanımlanmıştır !</p>
          </div>
          <md-field>
            <label>E-Mail</label>
            <md-input
              v-model="profile.email"
              :disabled="isDisabled.information"
            ></md-input>
          </md-field>
          <md-field>
            <label>Cinsiyet</label>
            <md-select v-model="profile.sex" :disabled="isDisabled.information">
              <md-option value="false">Kadın</md-option>
              <md-option value="true">Erkek</md-option>
            </md-select>
          </md-field>

          <md-datepicker
            :style="{ 'pointer-events': isDisabled.information ? 'none' : '' }"
            :md-open-on-focus="!isDisabled.information"
            v-model="profile.birthday"
            :disabled="isDisabled.information"
            md-immediately
          >
            <label>Doğum Tarihi</label>
          </md-datepicker>
        </div>

        <div class="wrapper">
          <md-button
            :style="{
              'background-color': roleId == 1 ? '#122573' : 'grey',
              'pointer-events': roleId == 1 ? 'auto' : 'none',
            }"
            class="md-fab md-mini editButton"
            v-on:click="editable('editInnerFirms')"
          >
            <md-icon>edit</md-icon>
          </md-button>
          <span class="md-title">Kullanıcı Firması</span>
          <md-field
            :style="{
              'pointer-events': isDisabled.editInnerFirms ? 'none' : '',
            }"
          >
            <md-select :disabled="roleId != 1" v-model="userInnerFirmId">
              <md-option
                v-for="firm in innerFirms"
                v-bind:value="firm.id"
                v-bind:key="firm.id"
                >{{ firm.name }}</md-option
              >
            </md-select>
          </md-field>

          <md-button
            class="md-raised md-primary editOkButton"
            v-on:click="editInnerFirms"
            v-if="!isDisabled.editInnerFirms"
            >Değiştir</md-button
          >
        </div>
      </div>
      <!-- İkinci sutun -->
    </div>
    <md-dialog-alert
      :md-active.sync="validateDialog"
      md-content="Bu numara başka bir kullanıcıya tanımlanmıştır !"
      md-cancel-text="Kapat"
    />
  </div>
</template>

<script>
// import userList from "../../components/user/list";
import { HTTP } from "../../../main-source";
import VuePhoneNumberInput from "vue-phone-number-input";
import BigLoading from "../../../components/bigLoading/index.vue";
var phone = null;

export default {
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      profile: {},
      password: "placeholder",
      password1: "placeholder",
      userInnerFirmId: null,
      roles: [],
      users: [],
      innerFirms: [],
      isDisabled: {
        roles: true,
        password: true,
        information: true,
        editInnerFirms: true,
      },
      appointments: [],
      phoneHolder: "",
      roleId: localStorage.getItem("roleId"),
      image: [],
      storagepath: this.$storage,
      phoneList: [],
      phoneValidate: true,
      validateDialog: false,
      loading: true,
      appointmentNotification: {
        notification: { email: true, whatsapp: true },
      },
      clinicAppointmentNotification: {
        email: true, whatsapp: true
      },
    };
  },
  components: {
    "vue-phone-number-input": VuePhoneNumberInput,
    BigLoading,
  },
  async created() {
    await this.getUser();
    await this.getRoles();
    await this.getInnerFirms();
    this.loading = false;
  },
  methods: {
    async getUser() {
      const user = await HTTP.get("/users/" + this.id + "/getGeneralInfos");
      this.profile = user.data;
      this.profile.birthday = new Date(user.data.birthday);
      this.profile.appointmentNotification = JSON.parse(
        this.profile.appointmentNotification
      );
      this.appointmentNotification =
        this.profile.appointmentNotification.notification.whatsapp ||
        this.profile.appointmentNotification.notification.email;

      this.clinicAppointmentNotification = this.profile.isActivityAppointmentWhatsappNotification ||
        this.profile.isActivityAppointmentEmailNotification;

      this.userInnerFirmId = user.data.userInnerFirms[0]?.innerFirmId;
    },
    async getRoles() {
      const roles = await HTTP.get("/roles/");
      this.roles = roles.data;
    },
    async getInnerFirms() {
      const innerFirms = await HTTP.get("/firms/");
      this.innerFirms = innerFirms.data;
    },
    async editInnerFirms() {
      const userInnerFirmId = this.userInnerFirmId;
      const updateUserInnerFirm = await HTTP.post(
        "/users/updateUserInnerFirm",
        {
          userId: this.profile.id,
          innerFirms: [userInnerFirmId],
        }
      );
      this.isDisabled.editInnerFirms = true;

      const { message, isSuccess } = updateUserInnerFirm?.data;
      this.$swal("", message, isSuccess ? "success" : "error");
    },
    updateNotification() {
      HTTP.put("/users/updateAppointmentNotification/" + this.profile.id, {
        data: JSON.stringify(this.profile.appointmentNotification),
      }).then((result) => {});
    },
    async updateClinicNotificationWhatsapp(value) {
      await HTTP.post("/users/updateActivityAppointmentNotification", {
        userId: this.profile.id,
        isActivityAppointmentWhatsappNotification: value
      });
    },
    async updateClinicNotificationEmail(value) {
      await HTTP.post("/users/updateActivityAppointmentNotification", {
        userId: this.profile.id,
        isActivityAppointmentEmailNotification: value
      });
    },
    telInput(tel) {
      this.phoneHolder = tel.formattedNumber;

      if (
        this.phoneList.includes(this.phoneHolder) &&
        this.phoneHolder != phone
      ) {
        this.phoneValidate = false;
        this.validateDialog = true;
      } else {
        this.phoneValidate = true;
        this.validateDialog = false;
      }
    },
    editable(options) {
      switch (options) {
        case "roles":
          this.isDisabled.roles = !this.isDisabled.roles;
          break;
        case "password":
          this.password = "";
          this.password1 = "";
          this.isDisabled.password = !this.isDisabled.password;
          break;
        case "information":
          this.isDisabled.information = !this.isDisabled.information;
          break;

        case "editInnerFirms":
          this.isDisabled.editInnerFirms = !this.isDisabled.editInnerFirms;

        default:
          break;
      }
    },
    editRoles() {
      HTTP.patch("/users/" + this.profile.id + "/role", {
        roleId: this.profile.roleId,
      }).then((result) => {
        if (result.status == 204) {
          this.editable("roles");
        }
      });
    },
    editInformation() {
      this.profile.phone = this.phoneHolder;

      HTTP.post("/users/" + this.id + "/updateGeneralInfos", {
        name: this.profile.name,
        phone: this.profile.phone,
        email: this.profile.email,
        sex: this.profile.sex,
        birthday: this.profile.birthday,
      }).then((result) => {
        if (result.status == 204) {
          this.editable("information");
        }
      });
    },
    editPassword: function() {
      let isValid = false;

      if (this.password == this.password1) {
        isValid = true;
      }

      if (isValid) {
        HTTP.patch("/users/" + this.profile.id + "/password", {
          password: this.password,
          password1: this.password1,
        }).then((result) => {
          if (result.status == 204) {
            this.editable("password");
          }
        });
      }
    },
    searchFilter: function(data, search) {
      let searchValue = search.toLowerCase();
      return data.filter((item) =>
        item.name.toLowerCase().match(searchValue) ? item : null
      );
    },
  },
  watch: {
    "profile.status": function() {
      HTTP.patch("/users/" + this.profile.id + "/status", {
        status: this.profile.status,
      });
    },
    "profile.getAppointmentsNotification": function() {
      HTTP.patch("/users/" + this.profile.id + "/getAppointmentsNotification", {
        getAppointmentsNotification: this.profile.getAppointmentsNotification,
      });
    },
    "profile.getWhatsappNotifications": function() {
      HTTP.patch("/users/" + this.profile.id + "/getWhatsappNotifications", {
        getWhatsappNotifications: this.profile.getWhatsappNotifications,
      });
    },
    "profile.canSeeComments": function() {
      HTTP.patch("/users/" + this.profile.id + "/comments", {
        canSeeComments: this.profile.canSeeComments,
      });
    },
    image: function() {
      let fd = new FormData();
      fd.append("image", this.image[0].file, this.image[0].name);

      HTTP.post("/users/" + this.profile.id + "/profileimage", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then(() => {
        HTTP.get("/users/profile/" + this.profile.id).then((result) => {
          this.profile = result.data[0];
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors.scss";

.md-dialog-content {
  color: #f00;
}

.error {
  display: block !important;
  color: #f00;
  transform: translate3d(0, -8px, 0);
  height: -19px;
  position: inherit;
  font-size: 1rem;

  border-bottom: inset;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.wrap {
  margin: 0px -15px;
}
.item {
  min-height: 200px;
  margin: 15px;
  &:last-child {
    margin-right: 5px;
  }
}
.profile.wrapper {
  margin-top: 75px;
}
.wrapper {
  position: relative;
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}
.profile {
  text-align: center;
  img {
    width: 170px !important;
    height: 170px !important;
    border-radius: 50%;
    margin-top: -90px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }
  span {
    display: block;
  }
  .title {
    font-weight: bold;
  }
  .md-layout {
    text-align: left;
  }
  .icon {
    padding: 0px 10px;
    margin-right: 10px;
  }
  .main {
    margin: 15px 0px;
  }
  .secondary {
    margin: 30px 0px 15px 0px;
    .md-layout {
      margin-top: 10px;
      color: $dark;
    }
  }
}
.md-icon-button.delete {
  background: $red !important;
  .icon {
    color: $white !important;
  }
}
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.md-scrollbar {
  max-height: 80vh !important;
  overflow: auto;
}
.editOkButton {
  width: 100%;
  margin: 0 !important;
}
.vue-phone-number-input {
  width: 100%;
}
</style>
