<template>
  <div class="wrapper">
    <span class="md-title">Klinik Randevuları Takvim Ayarları</span>
    <md-table class="table appointments" v-model="sources">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell>{{
          item.text
        }}</md-table-cell>
        <md-table-cell md-label="Saat">{{
          item.transaction_limit
        }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateSource(item.id)">edit</i>
          
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="updateSourceModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label>Günlük İşlem Limiti *</label>
          <md-input v-model="source.transaction_limit"></md-input>
        </md-field>
        <span class="md-error" v-if="this.isValid.transaction_limit === false"
          >Lütfen Lokasyon Adı Giriniz</span
        >
        <md-button
          class="md-raised md-primary updateStatus"
          v-on:click="updateSourceDone"
          >Güncelle</md-button
        >
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "activityCalendar",
  data() {
    return {
      title: "Klinik Randevulari Takvim Ayarlari",
      sources: [],
      source: {
        transaction_limit: ""
      },
      addSourceModal: false,
      updateSourceModal: false,
      isValid: {
        transaction_limit: null,
        duration: null
      }
    };
  },
  created() {
    this.getSources();
  },
  methods: {
    getSources: function() {
      HTTP.get("/settings/default/activityCalendar").then(result => {
        this.sources = result.data;
      });
    },
    updateSource: function(id) {
      this.source = this.sources.filter(sources => sources.id == id)[0];
      this.updateSourceModal = true;
    },
    updateSourceDone: function() {
      HTTP.put("/settings/default/activityCalendar/"+this.source.id, this.source).then(result => {
        if (result.status == 204) {
          this.getSources();
          this.updateSourceModal = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
</style>