<template>
  <div id="form" v-if="confirmation && template">
    <!-- DESKTOP SCREEN -->
    <div v-if="deviceControl == 'desktop'">
      <div class="main-container">
        <div class="watermark-container">
          <img style=" margin-top: 200px;" :src="template.watermark" alt="" />
        </div>
        <div
          class="header-container"
          :style="{ 'background-color': [template.color] }"
        >
          <h2>{{ confirmation.headerTitle.label }}</h2>
          <img :src="template.img" alt="" />
        </div>

        <div class="content-container">
          <div class="id-date-container">
            <label v-if="!input"
              ><b>{{ confirmation.confirmationId.label }}</b> :
              {{ $route.params.guid }}
            </label>
            <label
              ><b>{{ confirmation.date.label }}</b> :
              {{ formatDateWithHour(confirmation.date.content) }}
            </label>
          </div>

          <div
            style="width:100%;display:flex;flex-direction: column;padding-right: 40px;padding-left: 40px;"
          >
            <div style="display:flex;align-items: center;">
              <div style="margin-right:15px">
                <CheckCircleFillIcon :color="template.color" size="60" />
              </div>
              <div style="display:flex;flex-direction:column">
                <label style="font-weight:700;font-size:22px;margin-top: 8px;"
                  >{{ confirmation.title.label }}
                  <span
                    v-if="appointmentStatus"
                    :style="{
                      color: getAppointmentStatusColor(appointmentStatus.id),
                      'font-size': '16px',
                    }"
                  >
                    -
                    {{ appointmentStatus ? appointmentStatus.name : "" }}</span
                  >
                </label>
              </div>
            </div>

            <div style="margin-top:15px">
              <label>{{ confirmation.description.content }} </label>
            </div>
          </div>

          <div
            style="margin-top: 11px;flex-wrap: wrap;width:800px;height:65px;position: absolute;padding-left: 220px;display: flex;align-items: center;"
          >
            <ConsultationWithDoctorsIcon
              :color="template.color"
              size="45"
              :title="confirmation.consultationWithDoctors.label"
              :value="confirmation.consultationWithDoctors.content"
            />
            <PreOperativeTestIcon
              :color="template.color"
              size="45"
              :title="confirmation.preOperativeTest.label"
              :value="confirmation.preOperativeTest.content"
            />

            <div style="display:flex">
              <AllMedicationsIcon
                :color="template.color"
                size="45"
                :title="confirmation.allMedications.label"
                :value="confirmation.allMedications.content"
              />
              <HelplineWithExpertTeamIcon
                :color="template.color"
                size="45"
                :title="confirmation.helplineWithExpertTeam.label"
                :value="confirmation.helplineWithExpertTeam.content"
              />
              <!-- <VipCarIcon
                :color="template.color"
                size="40"
                :title="confirmation.postSurgeryGarment.label"
                :value="confirmation.postSurgeryGarment.content"
              /> -->
            </div>
          </div>

          <div>
            <img
              v-if="avatar"
              :src="avatar"
              style="position:absolute ; margin-top: -65px;width: 110px;height: 110px;margin-left:50px;background: white;border-radius: 50%;"
            />

            <div
              :style="{ backgroundColor: template.color }"
              style="width:100%;height:90px;margin-top: 125px;padding-left: 190px;display:flex;flex-direction: column;"
            >
              <div
                style="width:100%;display:flex;justify-content:space-between;padding-right:20px"
              >
                <label
                  v-if="confirmation.userNameSurname.label"
                  style="color:white;font-size: 21px;font-weight: 700;margin-bottom: 8px;margin-top:18px;"
                  >{{ confirmation.userNameSurname.label }}</label
                >
                <label
                  v-if="confirmation.userNameSurname.phone"
                  style="color:white;font-size: 21px;font-weight: 700;margin-bottom: 8px;margin-top:18px;"
                  >{{ confirmation.userNameSurname.phone }}</label
                >
              </div>
              <label
                v-if="confirmation.userNameSurname.content"
                style="color:white;font-size: 24px;font-weight: 600"
                >{{ confirmation.userNameSurname.content }}</label
              >
            </div>
          </div>

          <div style="margin-top:10px" class="row-label-value">
            <label class="row-label-big">{{
              confirmation.treatmentPackage.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.treatmentPackage.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.incPrice.label
            }}</label>
            <div v-if="input" style="display:flex">
              <md-field
                :style="{
                  'border-color': confirmation.incPrice.content
                    ? 'green'
                    : 'red',
                }"
                class="input-container"
              >
                <!-- <md-input
                  type="tel"
                  class="text-input"
                  v-model="confirmation.incPrice.content"
                  @keypress="isNumber($event)"
                  @change.native="(event) => console.log(event)"
                ></md-input> -->
                <input
                  class="default-input"
                  type="tel"
                  :value="confirmation.incPrice.content"
                  @input="(e) => changeInputValue(e, 'incPrice')"
                  @keypress="isNumber($event)"
                />
              </md-field>

              <md-field
                :style="{
                  'border-color': confirmation.priceCurrency.content
                    ? 'green'
                    : 'red',
                }"
                style="width:70px;margin-left:10px"
                class="input-container"
              >
                <md-select
                  class="text-input"
                  v-model="confirmation.priceCurrency.content"
                  placeholder="Para Birimi"
                >
                  <md-option
                    v-for="currency in allCurrencies"
                    v-bind:value="currency.slug"
                    v-bind:key="currency.slug"
                  >
                    {{ currency.slug }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <label v-else class="row-label-value-text"
              >{{ confirmation.incPrice.content }}
              {{ confirmation.priceCurrency.content }}
            </label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.depositPrice.label
            }}</label>
            <div v-if="input" style="display:flex">
              <md-field
                :style="{
                  'border-color': confirmation.depositPrice.content
                    ? 'green'
                    : 'red',
                }"
                class="input-container"
              >
                <!-- <md-input
                  type="tel"
                  class="text-input"
                  v-model="confirmation.depositPrice.content"
                  @keypress="isNumber($event)"
                ></md-input> -->
                <input
                  class="default-input"
                  type="tel"
                  :value="confirmation.depositPrice.content"
                  @input="(e) => changeInputValue(e, 'depositPrice')"
                  @keypress="isNumber($event)"
                />
              </md-field>

              <md-field
                :style="{
                  'border-color': confirmation.priceCurrency.content
                    ? 'green'
                    : 'red',
                }"
                style="width:70px;margin-left:10px"
                class="input-container"
              >
                <md-select
                  class="text-input"
                  v-model="confirmation.priceCurrency.content"
                  placeholder="Para Birimi"
                >
                  <md-option
                    v-for="currency in allCurrencies"
                    v-bind:value="currency.slug"
                    v-bind:key="currency.slug"
                  >
                    {{ currency.slug }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <label v-else class="row-label-value-text"
              >{{ confirmation.depositPrice.content }}
              {{ confirmation.priceCurrency.content }}
            </label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.balancedPrice.label
            }}</label>
            <div v-if="input" style="display:flex">
              <md-field
                :style="{
                  'border-color': confirmation.balancedPrice.content
                    ? 'green'
                    : 'red',
                }"
                class="input-container"
              >
                <md-input
                  disabled
                  type="tel"
                  class="text-input"
                  v-model="confirmation.balancedPrice.content"
                  @keypress="isNumber($event)"
                ></md-input>
              </md-field>

              <md-field
                :style="{
                  'border-color': confirmation.priceCurrency.content
                    ? 'green'
                    : 'red',
                }"
                style="width:70px;margin-left:10px"
                class="input-container"
              >
                <md-select
                  class="text-input"
                  v-model="confirmation.priceCurrency.content"
                  placeholder="Para Birimi"
                >
                  <md-option
                    v-for="currency in allCurrencies"
                    v-bind:value="currency.slug"
                    v-bind:key="currency.slug"
                  >
                    {{ currency.slug }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <label v-else class="row-label-value-text"
              >{{ confirmation.balancedPrice.content }}
              {{ confirmation.priceCurrency.content }}
            </label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <label class="row-label-main-text">{{
            confirmation.personalInformations.label
          }}</label>
          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.nameSurname.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.nameSurname.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>
          <!-- <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.country.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.country.content
            }}</label>
          </div> -->

          <!-- <div class="line">
            --------------------------------------------------------------------------------------------
          </div> -->
          <div class="row-label-value">
            <label class="row-label-big">{{ confirmation.phone.label }}</label>
            <label class="row-label-value-text">{{
              confirmation.phone.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{ confirmation.email.label }}</label>
            <label class="row-label-value-text">{{
              confirmation.email.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <label class="row-label-main-text">{{
            confirmation.treatmentInformations.label
          }}</label>
          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.treatmentDateTime.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.treatmentDateTime.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.operationLocation.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.operationLocation.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>

          <div class="row-label-value">
            <label class="row-label-big">{{
              confirmation.staffDoctor.label
            }}</label>
            <label class="row-label-value-text">{{
              confirmation.staffDoctor.content
            }}</label>
          </div>

          <div class="line">
            --------------------------------------------------------------------------------------------
          </div>
        </div>

        <!--Footer-->

        <div class="footer">
          <div class="footer-top">
            <p :key="footer" v-for="footer in template.footer.split(',')">
              {{ footer }}
            </p>
          </div>
          <div
            :style="{ backgroundColor: template.color }"
            class="footer-bottom"
          ></div>
        </div>
      </div>

      <div class="main-container">
        <div v-if="template.watermark" class="watermark-container">
          <img :src="template.watermark" alt="" />
        </div>

        <div style="margin-top:40px" class="content-container">
          <!--! TRANSFER INFORMATIONS - START -->

          <div>
            <div>
              <label
                :style="{
                  opacity: confirmation.visibleAreas.transferInfos ? 1 : 0.4,
                  'pointer-events': confirmation.visibleAreas.transferInfos
                    ? ''
                    : 'none',
                }"
                class="row-label-main-text"
                >{{ confirmation.transferInformations.label }}</label
              >

              <md-switch
                v-if="input"
                style="margin-left:15px;top:4px"
                v-model="confirmation.visibleAreas.transferInfos"
                class="md-primary"
              ></md-switch>
            </div>

            <div
              :style="{
                opacity: confirmation.visibleAreas.transferInfos ? 1 : 0.4,
                'pointer-events': confirmation.visibleAreas.transferInfos
                  ? ''
                  : 'none',
              }"
            >
              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.vipTransfer.label
                }}</label>

                <md-checkbox
                  style="margin-top:-10px;margin-right:0px"
                  v-if="input"
                  value="✓"
                  v-model="confirmation.vipTransfer.content"
                ></md-checkbox>
                <label v-else class="row-label-value-text">{{
                  !confirmation.visibleAreas.transferInfos
                    ? nullDataText
                    : confirmation.vipTransfer.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>

              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.transferManagingNameSurname.label
                }}</label>
                <label class="row-label-value-text">{{
                  confirmation.transferManagingNameSurname.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>

              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.airportToHotelHospital.label
                }}</label>
                <md-field
                  :style="{
                    'border-color': confirmation.airportToHotelHospital.content
                      ? 'green'
                      : 'red',
                  }"
                  v-if="input"
                  class="input-container"
                >
                  <md-select
                    class="text-input"
                    v-model="confirmation.airportToHotelHospital.content"
                    :placeholder="confirmation.airportToHotelHospital.label"
                  >
                    <md-option
                      v-for="item in transferInformationsSelection(
                        confirmation.airportToHotelHospital.label
                      )"
                      v-bind:value="item.name"
                      v-bind:key="item.name"
                    >
                      {{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
                <label v-else class="row-label-value-text">{{
                  !confirmation.visibleAreas.transferInfos
                    ? nullDataText
                    : confirmation.airportToHotelHospital.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>

              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.hospitalToHotelAddress.label
                }}</label>
                <md-field
                  :style="{
                    'border-color': confirmation.hospitalToHotelAddress.content
                      ? 'green'
                      : 'red',
                  }"
                  v-if="input"
                  class="input-container"
                >
                  <md-select
                    class="text-input"
                    v-model="confirmation.hospitalToHotelAddress.content"
                    :placeholder="confirmation.hospitalToHotelAddress.label"
                  >
                    <md-option
                      v-for="item in transferInformationsSelection(
                        confirmation.hospitalToHotelAddress.label
                      )"
                      v-bind:value="item.name"
                      v-bind:key="item.name"
                    >
                      {{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
                <label v-else class="row-label-value-text">{{
                  !confirmation.visibleAreas.transferInfos
                    ? nullDataText
                    : confirmation.hospitalToHotelAddress.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>

              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.transferFromToHotelToAirport.label
                }}</label>
                <md-field
                  :style="{
                    'border-color': confirmation.transferFromToHotelToAirport
                      .content
                      ? 'green'
                      : 'red',
                  }"
                  v-if="input"
                  class="input-container"
                >
                  <md-select
                    class="text-input"
                    v-model="confirmation.transferFromToHotelToAirport.content"
                    :placeholder="
                      confirmation.transferFromToHotelToAirport.label
                    "
                  >
                    <md-option
                      v-for="item in transferInformationsSelection(
                        confirmation.transferFromToHotelToAirport.label
                      )"
                      v-bind:value="item.name"
                      v-bind:key="item.name"
                    >
                      {{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
                <label v-else class="row-label-value-text">{{
                  !confirmation.visibleAreas.transferInfos
                    ? nullDataText
                    : confirmation.transferFromToHotelToAirport.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>
            </div>
          </div>

          <!--! TRANSFER INFORMATIONS - END -->

          <!--! ACCOMMODATION - START -->

          <div>
            <div>
              <label
                v-if="confirmation.accommodation"
                class="row-label-main-text"
                :style="{
                  opacity: confirmation.visibleAreas.hotelInfos ? 1 : 0.4,
                  'pointer-events': confirmation.visibleAreas.hotelInfos
                    ? ''
                    : 'none',
                }"
                >{{ confirmation.accommodation.label }}</label
              >

              <md-switch
                v-if="input"
                style="margin-left:15px;top:4px"
                v-model="confirmation.visibleAreas.hotelInfos"
                class="md-primary"
              ></md-switch>
            </div>

            <div
              :style="{
                opacity: confirmation.visibleAreas.hotelInfos ? 1 : 0.4,
                'pointer-events': confirmation.visibleAreas.hotelInfos
                  ? ''
                  : 'none',
              }"
            >
              <div>
                <div class="row-label-value">
                  <label v-if="confirmation.hotelName" class="row-label-big">{{
                    confirmation.hotelName.label
                  }}</label>

                  <md-field
                    :style="{
                      'border-color': confirmation.hotelName.content
                        ? 'green'
                        : 'red',
                    }"
                    v-if="input"
                    class="input-container"
                  >
                    <md-select
                      class="text-input"
                      v-model="confirmation.hotelName.content"
                      :placeholder="confirmation.hotelName.label"
                      style="text-align:right"
                    >
                      <md-option
                        v-for="hotel in allHotels"
                        v-bind:value="hotel.name"
                        v-bind:key="hotel.name"
                      >
                        {{ hotel.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <label v-else class="row-label-value-text">{{
                    !confirmation.visibleAreas.hotelInfos
                      ? nullDataText
                      : confirmation.hotelName.content
                  }}</label>
                </div>
                <div class="line">
                  --------------------------------------------------------------------------------------------
                </div>
              </div>

              <div>
                <div class="row-label-value">
                  <label class="row-label-big">{{
                    confirmation.accommodationDay.label
                  }}</label>
                  <md-field
                    :style="{
                      'border-color': confirmation.accommodationDay.content
                        ? 'green'
                        : 'red',
                    }"
                    v-if="input"
                    class="input-container"
                  >
                    <md-input
                      type="tel"
                      class="text-input"
                      v-model="confirmation.accommodationDay.content"
                    ></md-input>
                  </md-field>
                  <label v-else class="row-label-value-text">{{
                    !confirmation.visibleAreas.hotelInfos
                      ? nullDataText
                      : confirmation.accommodationDay.content
                  }}</label>
                </div>

                <div class="line">
                  --------------------------------------------------------------------------------------------
                </div>
              </div>

              <div>
                <div class="row-label-value">
                  <label class="row-label-big">{{
                    confirmation.roomTypeAdultsChildren.label
                  }}</label>
                  <md-field
                    :style="{
                      'border-color': confirmation.roomTypeAdultsChildren
                        .content
                        ? 'green'
                        : 'red',
                    }"
                    v-if="input"
                    class="input-container"
                  >
                    <md-select
                      class="text-input"
                      v-model="confirmation.roomTypeAdultsChildren.content"
                      :placeholder="confirmation.roomTypeAdultsChildren.label"
                    >
                      <md-option
                        v-for="roomType in allRoomTypes"
                        v-bind:value="roomType.name"
                        v-bind:key="roomType.name"
                      >
                        {{ roomType.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <label v-else class="row-label-value-text">{{
                    !confirmation.visibleAreas.hotelInfos
                      ? nullDataText
                      : confirmation.roomTypeAdultsChildren.content
                  }}</label>
                </div>
                <div class="line">
                  --------------------------------------------------------------------------------------------
                </div>
              </div>

              <div class="row-label-value">
                <label class="row-label-big">{{
                  confirmation.checkInDate.label
                }}</label>
                <div v-if="input" style="margin-top:-4px">
                  <md-field
                    :style="{
                      'border-color': confirmation.checkInDate.content
                        ? 'green'
                        : 'red',
                    }"
                    style="padding-top:0px"
                    class="input-container"
                  >
                    <md-datepicker
                      md-immediately
                      style="margin-bottom:0px"
                      v-model="confirmation.checkInDate.content"
                    />
                  </md-field>
                </div>
                <label v-else class="row-label-value-text">{{
                  !confirmation.visibleAreas.hotelInfos
                    ? nullDataText
                    : confirmation.checkInDate.content
                }}</label>
              </div>

              <div class="line">
                --------------------------------------------------------------------------------------------
              </div>

              <div>
                <div class="row-label-value">
                  <label class="row-label-big">{{
                    confirmation.checkOutDate.label
                  }}</label>
                  <label class="row-label-value-text">{{
                    !confirmation.visibleAreas.hotelInfos
                      ? nullDataText
                      : formatDate(confirmation.checkOutDate.content)
                  }}</label>
                </div>

                <div class="line">
                  --------------------------------------------------------------------------------------------
                </div>
              </div>
            </div>

            <!--! ACCOMMODATION - END -->
          </div>

          <div>
            <div style="display:flex">
              <label
                :style="{
                  opacity: confirmation.visibleAreas.flightInfos ? 1 : 0.4,
                  'pointer-events': confirmation.visibleAreas.flightInfos
                    ? ''
                    : 'none',
                }"
                class="row-label-main-text"
                >{{ confirmation.flightDetails.label }}</label
              >

              <md-switch
                v-if="input"
                style="margin-left:15px;"
                v-model="confirmation.visibleAreas.flightInfos"
                class="md-primary"
              ></md-switch>
            </div>

            <div
              :style="{
                'pointer-events': confirmation.visibleAreas.flightInfos
                  ? ''
                  : 'none',
              }"
            >
              <div
                :style="{
                  opacity: confirmation.visibleAreas.flightArrivalInfos
                    ? 1
                    : 0.4,
                }"
              >
                <div style="display:flex">
                  <label
                    :style="{
                      opacity: confirmation.visibleAreas.flightInfos ? 1 : 0.4,
                      'pointer-events': confirmation.visibleAreas.flightInfos
                        ? ''
                        : 'none',
                      fontSize: '17px',
                    }"
                    class="row-label-main-text"
                    >{{ confirmation.flightDetails.arrivalLabel }}</label
                  >

                  <md-switch
                    v-if="input"
                    style="margin-left:15px;"
                    v-model="confirmation.visibleAreas.flightArrivalInfos"
                    class="md-primary"
                  ></md-switch>
                </div>

                <div
                  :style="{
                    'pointer-events': confirmation.visibleAreas
                      .flightArrivalInfos
                      ? ''
                      : 'none',
                  }"
                >
                  <div>
                    <div class="row-label-value">
                      <label class="row-label-big">{{
                        confirmation.arrivalAirport.label
                      }}</label>

                      <md-field
                        :style="{
                          'border-color': confirmation.arrivalAirport.content
                            ? 'green'
                            : 'red',
                        }"
                        v-if="input"
                        class="input-container"
                      >
                        <md-select
                          class="text-input"
                          v-model="confirmation.arrivalAirport.content"
                          :placeholder="confirmation.arrivalAirport.label"
                        >
                          <md-input
                            v-model="returnAirportSearch"
                            type="text"
                            style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                            placeholder="Arayınız"
                          />
                          <md-option
                            v-for="airport in allAirports"
                            v-bind:value="airport.name"
                            v-bind:key="airport.id"
                          >
                            {{ airport.name }}</md-option
                          >
                        </md-select>
                      </md-field>

                      <label v-else class="row-label-value-text">{{
                        !confirmation.visibleAreas.flightInfos
                          ? nullDataText
                          : confirmation.arrivalAirport.content
                      }}</label>
                    </div>
                  </div>

                  <div class="line">
                    --------------------------------------------------------------------------------------------
                  </div>
                  <div class="row-label-value">
                    <label class="row-label-big">{{
                      confirmation.dateAndTime.label
                    }}</label>

                    <div v-if="input" style="display:flex">
                      <div style="margin-top:-4px;margin-right: 5px;">
                        <md-field
                          :style="{
                            'border-color': confirmation.dateAndTime.content
                              ? 'green'
                              : 'red',
                          }"
                          style="padding-top:0px"
                          class="input-container"
                        >
                          <md-datepicker
                            :md-disabled-dates="isDateDisabledFlightDate"
                            md-immediately
                            style="margin-bottom:0px"
                            v-model="confirmation.dateAndTime.content"
                          />
                        </md-field>
                      </div>

                      <md-field
                        :style="{
                          'border-color': confirmation.dateAndTime.time
                            ? 'green'
                            : 'red',
                        }"
                        style="width:90px"
                        class="input-container"
                      >
                        <md-input
                          type="time"
                          class="text-input"
                          v-model="confirmation.dateAndTime.time"
                        ></md-input>
                      </md-field>
                    </div>

                    <label v-else class="row-label-value-text">{{
                      !confirmation.visibleAreas.flightInfos
                        ? nullDataText
                        : confirmation.dateAndTime.content +
                          " - " +
                          confirmation.dateAndTime.time
                    }}</label>
                  </div>

                  <div class="line">
                    --------------------------------------------------------------------------------------------
                  </div>

                  <div class="row-label-value">
                    <label class="row-label-big">{{
                      confirmation.flightCode
                        ? confirmation.flightCode.label
                        : ""
                    }}</label>
                    <div v-if="input" style="display:flex">
                      <md-field
                        v-if="confirmation.flightAirline"
                        :style="{
                          'border-color': confirmation.flightAirline.id
                            ? 'green'
                            : 'red',
                        }"
                        style="width:120px;margin-left:10px"
                        class="input-container"
                      >
                        <md-select
                          class="text-input"
                          v-model="confirmation.flightAirline.id"
                          placeholder="Uçuş Kodu"
                        >
                          <md-input
                            v-model="returnAirlineSearch"
                            type="text"
                            style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                            placeholder="Arayınız"
                          />
                          <md-option
                            v-for="airlines in allAirlines"
                            v-bind:value="airlines.id"
                            v-bind:key="airlines.id"
                          >
                            {{ airlines.iata }} - {{ airlines.name }}</md-option
                          >
                        </md-select>
                      </md-field>
                      <md-field
                        :style="{
                          'border-color': confirmation.flightCode.content
                            ? 'green'
                            : 'red',
                        }"
                        class="input-container"
                      >
                        <md-input
                          style="text-align: left; padding-left: 10px;"
                          class="text-input"
                          v-model="confirmation.flightCode.content"
                        ></md-input>
                      </md-field>
                    </div>
                    <label v-else class="row-label-value-text">
                      <label
                        v-if="confirmation.flightAirline"
                        class="row-label-value-text"
                      >
                        {{
                          !confirmation.visibleAreas.flightInfos
                            ? nullDataText
                            : confirmation.flightAirline.iata
                        }}
                      </label>
                      {{
                        !confirmation.visibleAreas.flightInfos
                          ? nullDataText
                          : confirmation.flightCode.content
                      }}</label
                    >
                  </div>

                  <div class="line">
                    --------------------------------------------------------------------------------------------
                  </div>
                </div>
              </div>

              <div
                :style="{
                  opacity: confirmation.visibleAreas.flightReturnInfos
                    ? 1
                    : 0.4,
                }"
              >
                <div style="display:flex">
                  <label
                    :style="{
                      opacity: confirmation.visibleAreas.flightReturnInfos
                        ? 1
                        : 0.4,
                      fontSize: '17px',
                    }"
                    class="row-label-main-text"
                    >{{ confirmation.flightDetails.returnLabel }}</label
                  >

                  <md-switch
                    v-if="input"
                    style="margin-left:15px;"
                    v-model="confirmation.visibleAreas.flightReturnInfos"
                    class="md-primary"
                  ></md-switch>
                </div>
                <div
                  :style="{
                    'pointer-events': confirmation.visibleAreas
                      .flightReturnInfos
                      ? ''
                      : 'none',
                  }"
                >
                  <div class="row-label-value">
                    <label class="row-label-big">{{
                      confirmation.returnAirport.label
                    }}</label>
                    <md-field
                      :style="{
                        'border-color': confirmation.returnAirport.content
                          ? 'green'
                          : 'red',
                      }"
                      v-if="input"
                      class="input-container"
                    >
                      <md-select
                        class="text-input"
                        v-model="confirmation.returnAirport.content"
                        :placeholder="confirmation.returnAirport.label"
                      >
                        <md-input
                          v-model="returnAirportSearch"
                          type="text"
                          style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                          placeholder="Arayınız"
                        />
                        <md-option
                          v-for="airport in allAirports"
                          v-bind:value="airport.name"
                          v-bind:key="airport.id"
                        >
                          {{ airport.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                    <label v-else class="row-label-value-text">{{
                      !confirmation.visibleAreas.flightInfos
                        ? nullDataText
                        : confirmation.returnAirport.content
                    }}</label>
                  </div>

                  <div class="line">
                    --------------------------------------------------------------------------------------------
                  </div>
                  <div class="row-label-value">
                    <label class="row-label-big">{{
                      confirmation.returnDateAndTime.label
                    }}</label>

                    <div v-if="input" style="display:flex">
                      <div style="margin-top:-4px;margin-right: 5px;">
                        <md-field
                          :style="{
                            'border-color': confirmation.returnDateAndTime
                              .content
                              ? 'green'
                              : 'red',
                          }"
                          style="padding-top:0px"
                          class="input-container"
                        >
                          <md-datepicker
                            :md-disabled-dates="isDateDisabledFlightReturnDate"
                            md-override-native
                            md-immediately
                            style="margin-bottom:0px"
                            v-model="confirmation.returnDateAndTime.content"
                          />
                        </md-field>
                      </div>

                      <md-field
                        :style="{
                          'border-color': confirmation.returnDateAndTime.time
                            ? 'green'
                            : 'red',
                        }"
                        style="width:90px"
                        class="input-container"
                      >
                        <md-input
                          type="time"
                          class="text-input"
                          v-model="confirmation.returnDateAndTime.time"
                        ></md-input>
                      </md-field>
                    </div>

                    <label v-else class="row-label-value-text">{{
                      !confirmation.visibleAreas.flightInfos
                        ? nullDataText
                        : confirmation.returnDateAndTime.content +
                          " - " +
                          confirmation.returnDateAndTime.time
                    }}</label>
                  </div>

                  <div class="line">
                    --------------------------------------------------------------------------------------------
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="
                (input
                  ? confirmation.notes
                  : confirmation.notes.content.length > 0) || notesLabelShow
              "
            >
              <label class="row-label-main-text">{{
                confirmation.notes ? confirmation.notes.label : ""
              }}</label>
              <div style="width :100%; padding: 0 40px 0 40px;">
                <md-field
                  :style="{
                    'border-color': 'green',
                  }"
                  style="margin-top: 0px;"
                  v-if="input"
                  class="input-full-container"
                >
                  <md-textarea
                    class="text-area-input"
                    v-model="confirmation.notes.content"
                    :maxlength="300"
                  ></md-textarea>
                </md-field>
                <label v-else class="row-label-value-text">{{
                  confirmation.notes.content
                }}</label>
              </div>
              <span
                v-if="!notesHide && !notesLabelShow"
                style="margin-left:40px;font-size:12px;color:grey"
                >Bu alanı hasta göreceğinden dolayı dikkat edilerek doldurulması
                gerekmektedir.</span
              >
            </div>
          </div>
        </div>

        <!--Footer-->

        <div class="footer">
          <div class="footer-top">
            <p :key="footer" v-for="footer in template.footer.split(',')">
              {{ footer }}
            </p>
          </div>
          <div
            :style="{ backgroundColor: template.color }"
            class="footer-bottom"
          ></div>
        </div>
      </div>
    </div>

    <!-- MOBILE SCREEN -->

    <div v-if="deviceControl == 'mobile'">
      <div style="width:85vw" v-if="input">
        <div class="row">
          <span class="mobile-title-label">Header Title :</span>
          <h6 v-if="confirmation.headerTitle">
            {{ confirmation.headerTitle.label }}
          </h6>
        </div>

        <!-- <div class="row">
          <span class="mobile-title-label">Description :</span>
          <md-field :style="{
            'border-color': 'green',
          }">
            <md-textarea disabled v-model="confirmation.description.content"></md-textarea>
          </md-field>
        </div>

        <h5 style="margin-left:-15px;margin-top:20px;margin-bottom: 20px;">
          Temsilci Bilgileri
        </h5>

        <div class="row">
          <span class="mobile-title-label">Ad Soyad :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.userNameSurname.label"></md-input>
          </md-field>
        </div>

        <div class="row">
          <span class="mobile-title-label">Rolü :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.userNameSurname.content"></md-input>
          </md-field>
        </div>

        <div class="row">
          <span class="mobile-title-label">Telefon :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled type="tel" class="text-input"
              v-model="confirmation.userNameSurname.phone"></md-input>
          </md-field>
        </div> -->

        <h5 style="margin-left:-15px;margin-top:20px;margin-bottom: 20px;">
          Randevu Bilgileri
        </h5>

        <div v-if="confirmation.treatmentPackage" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.treatmentPackage.label }} :</span
          >
          <md-field
            :style="{
              'border-color': 'green',
              width: '100%',
            }"
            class="input-container"
          >
            <md-input
              style="text-align: left;"
              disabled
              class="text-input"
              v-model="confirmation.treatmentPackage.content"
            ></md-input>
          </md-field>
        </div>

        <div v-if="confirmation.priceCurrency" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.incPrice.label }} :</span
          >
          <div style="display:flex">
            <md-field
              :style="{
                'border-color': confirmation.incPrice.content ? 'green' : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <input
                style="text-align: left;"
                class="default-input"
                type="tel"
                :value="confirmation.incPrice.content"
                @input="(e) => changeInputValue(e, 'incPrice')"
                @keypress="isNumber($event)"
              />
              <!-- <md-input
                style="text-align: left;"
                type="tel"
                class="text-input"
                v-model="confirmation.incPrice.content"
                @keypress="isNumber($event)"
              ></md-input> -->
            </md-field>

            <md-field
              :style="{
                'border-color': confirmation.priceCurrency.content
                  ? 'green'
                  : 'red',
              }"
              style="width:70px;margin-left:10px"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.priceCurrency.content"
                placeholder="Para Birimi"
              >
                <md-option
                  v-for="currency in allCurrencies"
                  v-bind:value="currency.slug"
                  v-bind:key="currency.slug"
                >
                  {{ currency.slug }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="row">
          <span v-if="confirmation.depositPrice" class="mobile-title-label"
            >{{ confirmation.depositPrice.label }} :</span
          >
          <div v-if="confirmation.depositPrice" style="display:flex">
            <md-field
              :style="{
                'border-color': confirmation.depositPrice.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <input
                style="text-align: left;"
                class="default-input"
                type="tel"
                :value="confirmation.depositPrice.content"
                @input="(e) => changeInputValue(e, 'depositPrice')"
                @keypress="isNumber($event)"
              />
              <!-- <md-input
                style="text-align: left;"
                type="tel"
                class="text-input"
                v-model="confirmation.depositPrice.content"
                @keypress="isNumber($event)"
              ></md-input> -->
            </md-field>

            <md-field
              v-if="confirmation.priceCurrency"
              :style="{
                'border-color': confirmation.priceCurrency.content
                  ? 'green'
                  : 'red',
              }"
              style="width:70px;margin-left:10px"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.priceCurrency.content"
                placeholder="Para Birimi"
              >
                <md-option
                  v-for="currency in allCurrencies"
                  v-bind:value="currency.slug"
                  v-bind:key="currency.slug"
                >
                  {{ currency.slug }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <div v-if="confirmation.balancedPrice" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.balancedPrice.label }} :</span
          >
          <md-field
            :style="{
              'border-color': confirmation.balancedPrice.content
                ? 'green'
                : 'red',
              width: '100%',
            }"
            class="input-container"
          >
            <md-input
              style="text-align: left;"
              disabled
              class="text-input"
              v-model="confirmation.balancedPrice.content"
            ></md-input>
          </md-field>
        </div>

        <!-- <h5 style="margin-left:-15px;margin-top:20px;margin-bottom: 20px;">
          {{ confirmation.personalInformations.label }}
        </h5>

        <div class="row">
          <span class="mobile-title-label">{{ confirmation.nameSurname.label }} :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.nameSurname.content"></md-input>
          </md-field>
        </div>

        <div class="row">
          <span class="mobile-title-label">{{ confirmation.country.label }} :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.country.content"></md-input>
          </md-field>
        </div>

        <div class="row">
          <span class="mobile-title-label">{{ confirmation.phone.label }} :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.phone.content"></md-input>
          </md-field>
        </div>

        <div class="row">
          <span class="mobile-title-label">{{ confirmation.email.label }} :</span>
          <md-field :style="{
            'border-color': 'green',
            width: '100%',
          }" class="input-container">
            <md-input style="text-align: left;" disabled class="text-input"
              v-model="confirmation.email.content"></md-input>
          </md-field>
        </div> -->

        <h5
          v-if="confirmation.treatmentInformations"
          style="margin-left:-15px;margin-top:20px;margin-bottom: 20px;"
        >
          {{ confirmation.treatmentInformations.label }}
        </h5>

        <div v-if="confirmation.treatmentDateTime" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.treatmentDateTime.label }} :</span
          >
          <md-field
            :style="{
              'border-color': 'green',
              width: '100%',
            }"
            class="input-container"
          >
            <md-input
              style="text-align: left;"
              disabled
              class="text-input"
              v-model="confirmation.treatmentDateTime.content"
            ></md-input>
          </md-field>
        </div>

        <div v-if="confirmation.operationLocation" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.operationLocation.label }} :</span
          >
          <md-field
            :style="{
              'border-color': 'green',
              width: '100%',
            }"
            class="input-container"
          >
            <md-input
              style="text-align: left;"
              disabled
              class="text-input"
              v-model="confirmation.operationLocation.content"
            ></md-input>
          </md-field>
        </div>

        <div v-if="confirmation.staffDoctor" class="row">
          <span class="mobile-title-label"
            >{{ confirmation.staffDoctor.label }} :</span
          >
          <md-field
            :style="{
              'border-color': 'green',
              width: '100%',
            }"
            class="input-container"
          >
            <md-input
              style="text-align: left;"
              disabled
              class="text-input"
              v-model="confirmation.staffDoctor.content"
            ></md-input>
          </md-field>
        </div>

        <div
          v-if="confirmation.transferInformations"
          style="display: flex;align-items: center;margin-top:20px;margin-bottom: 20px;margin-left:-15px"
        >
          <h5
            :style="{
              opacity: confirmation.visibleAreas.transferInfos ? 1 : 0.4,
              'pointer-events': confirmation.visibleAreas.transferInfos
                ? ''
                : 'none',
            }"
          >
            {{ confirmation.transferInformations.label }}
          </h5>

          <md-switch
            v-if="input"
            style="margin-left:15px;margin-top: 7px;"
            v-model="confirmation.visibleAreas.transferInfos"
            class="md-primary"
          ></md-switch>
        </div>

        <div
          :style="{
            opacity: confirmation.visibleAreas.transferInfos ? 1 : 0.4,
            marginLeft: '5px',
            'pointer-events': confirmation.visibleAreas.transferInfos
              ? ''
              : 'none',
          }"
        >
          <div>
            <span
              v-if="confirmation.vipTransfer"
              style="margin-left:-15px"
              class="mobile-title-label"
              >{{ confirmation.vipTransfer.label }} :</span
            >

            <md-checkbox
              style="margin-top:-10px;margin-right:0px;height:40px"
              value="✓"
              v-model="confirmation.vipTransfer.content"
            ></md-checkbox>
          </div>

          <div v-if="confirmation.transferManagingNameSurname" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.transferManagingNameSurname.label }} :</span
            >

            <md-field
              :style="{
                'border-color': 'green',
                width: '100%',
              }"
              class="input-container"
            >
              <md-input
                style="text-align: left;"
                disabled
                class="text-input"
                v-model="confirmation.transferManagingNameSurname.content"
              ></md-input>
            </md-field>
          </div>

          <div v-if="confirmation.airportToHotelHospital" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.airportToHotelHospital.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.airportToHotelHospital.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.airportToHotelHospital.content"
                :placeholder="confirmation.airportToHotelHospital.label"
              >
                <md-option
                  v-for="item in transferInformationsSelection(
                    confirmation.airportToHotelHospital.label
                  )"
                  v-bind:value="item.name"
                  v-bind:key="item.name"
                >
                  {{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div v-if="confirmation.hospitalToHotelAddress" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.hospitalToHotelAddress.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.hospitalToHotelAddress.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.hospitalToHotelAddress.content"
                :placeholder="confirmation.hospitalToHotelAddress.label"
              >
                <md-option
                  v-for="item in transferInformationsSelection(
                    confirmation.hospitalToHotelAddress.label
                  )"
                  v-bind:value="item.name"
                  v-bind:key="item.name"
                >
                  {{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div v-if="confirmation.transferFromToHotelToAirport" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.transferFromToHotelToAirport.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.transferFromToHotelToAirport
                  .content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.transferFromToHotelToAirport.content"
                :placeholder="confirmation.transferFromToHotelToAirport.label"
              >
                <md-option
                  v-for="item in transferInformationsSelection(
                    confirmation.transferFromToHotelToAirport.label
                  )"
                  v-bind:value="item.name"
                  v-bind:key="item.name"
                >
                  {{ item.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <div
          v-if="confirmation.accommodation"
          style="display: flex;align-items: center;margin-top:20px;margin-bottom: 20px;margin-left:-15px"
        >
          <h5
            :style="{
              opacity: confirmation.visibleAreas.hotelInfos ? 1 : 0.4,
              'pointer-events': confirmation.visibleAreas.hotelInfos
                ? ''
                : 'none',
            }"
          >
            {{ confirmation.accommodation.label }}
          </h5>

          <md-switch
            style="margin-left:15px;margin-top: 7px;"
            v-model="confirmation.visibleAreas.hotelInfos"
            class="md-primary"
          ></md-switch>
        </div>

        <div
          :style="{
            opacity: confirmation.visibleAreas.hotelInfos ? 1 : 0.4,
            marginLeft: '5px',
            'pointer-events': confirmation.visibleAreas.hotelInfos
              ? ''
              : 'none',
          }"
        >
          <div v-if="confirmation.hotelName" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.hotelName.label }} :
            </span>

            <md-field
              :style="{
                'border-color': confirmation.hotelName.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.hotelName.content"
                :placeholder="confirmation.hotelName.label"
                style="text-align:right"
              >
                <md-option
                  v-for="hotel in allHotels"
                  v-bind:value="hotel.name"
                  v-bind:key="hotel.name"
                >
                  {{ hotel.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div v-if="confirmation.accommodationDay" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.accommodationDay.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.accommodationDay.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-input
                style="text-align: left;"
                type="tel"
                class="text-input"
                v-model="confirmation.accommodationDay.content"
              ></md-input>
            </md-field>
          </div>

          <div v-if="confirmation.roomTypeAdultsChildren" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.roomTypeAdultsChildren.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.roomTypeAdultsChildren.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              class="input-container"
            >
              <md-select
                class="text-input"
                v-model="confirmation.roomTypeAdultsChildren.content"
                :placeholder="confirmation.roomTypeAdultsChildren.label"
              >
                <md-option
                  v-for="roomType in allRoomTypes"
                  v-bind:value="roomType.name"
                  v-bind:key="roomType.name"
                >
                  {{ roomType.name }}</md-option
                >
              </md-select>
            </md-field>
          </div>

          <div v-if="confirmation.checkInDate" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.checkInDate.label }} :</span
            >

            <md-field
              :style="{
                'border-color': confirmation.checkInDate.content
                  ? 'green'
                  : 'red',
                width: '100%',
              }"
              style="padding-top:0px"
              class="input-container"
            >
              <md-datepicker
                md-immediately
                style="margin-bottom:0px"
                v-model="confirmation.checkInDate.content"
              >
              </md-datepicker>
            </md-field>
          </div>

          <div v-if="confirmation.checkOutDate" class="row">
            <span class="mobile-title-label"
              >{{ confirmation.checkOutDate.label }} :</span
            >
            <span class="mobile-title-label">{{
              !confirmation.visibleAreas.hotelInfos
                ? nullDataText
                : formatDate(confirmation.checkOutDate.content)
            }}</span>
          </div>
        </div>

        <div
          style="display: flex;align-items: center;margin-top:20px;margin-bottom: 20px;margin-left:-15px"
        >
          <h5
            :style="{
              opacity: confirmation.visibleAreas.flightInfos ? 1 : 0.4,
              'pointer-events': confirmation.visibleAreas.flightInfos
                ? ''
                : 'none',
            }"
          >
            {{ confirmation.flightDetails.label }}
          </h5>

          <md-switch
            style="margin-left:15px;margin-top: 7px;"
            v-model="confirmation.visibleAreas.flightInfos"
            class="md-primary"
          ></md-switch>
        </div>

        <div
          :style="{
            opacity: confirmation.visibleAreas.flightInfos ? 1 : 0.4,
            marginLeft: '5px',
            'pointer-events': confirmation.visibleAreas.flightInfos
              ? ''
              : 'none',
          }"
        >
          <div
            style="display: flex;align-items: center;margin-top:20px;margin-bottom: 10px;margin-left:-15px"
          >
            <h5
              :style="{
                opacity: confirmation.visibleAreas.flightArrivalInfos ? 1 : 0.4,
                'font-size': '18px',
              }"
            >
              {{ confirmation.flightDetails.arrivalLabel }}
            </h5>

            <md-switch
              style="margin-left:15px;margin-top: 7px;"
              v-model="confirmation.visibleAreas.flightArrivalInfos"
              class="md-primary"
            ></md-switch>
          </div>

          <div
            :style="{
              opacity: confirmation.visibleAreas.flightArrivalInfos ? 1 : 0.4,
              'pointer-events': confirmation.visibleAreas.flightArrivalInfos
                ? ''
                : 'none',
            }"
          >
            <div v-if="confirmation.arrivalAirport" class="row">
              <span class="mobile-title-label"
                >{{ confirmation.arrivalAirport.label }} :
              </span>

              <md-field
                :style="{
                  'border-color': confirmation.arrivalAirport.content
                    ? 'green'
                    : 'red',
                  width: '100%',
                }"
                class="input-container"
              >
                <md-select
                  class="text-input"
                  v-model="confirmation.arrivalAirport.content"
                  :placeholder="confirmation.arrivalAirport.label"
                >
                  <md-input
                    v-model="returnAirportSearch"
                    type="text"
                    style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                    placeholder="Arayınız"
                  />
                  <md-option
                    v-for="airport in allAirports"
                    v-bind:value="airport.name"
                    v-bind:key="airport.id"
                  >
                    {{ airport.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div v-if="confirmation.dateAndTime" class="row">
              <span class="mobile-title-label"
                >{{ confirmation.dateAndTime.label }} :</span
              >

              <div style="display:flex">
                <div style="margin-top:-4px;margin-right: 5px;">
                  <md-field
                    :style="{
                      'border-color': confirmation.dateAndTime.content
                        ? 'green'
                        : 'red',
                      width: '100%',
                    }"
                    style="padding-top:0px"
                    class="input-container"
                  >
                    <md-datepicker
                      :md-disabled-dates="isDateDisabledFlightDate"
                      md-immediately
                      style="margin-bottom:0px"
                      v-model="confirmation.dateAndTime.content"
                    />
                  </md-field>
                </div>

                <md-field
                  :style="{
                    'border-color': confirmation.dateAndTime.time
                      ? 'green'
                      : 'red',
                  }"
                  style="width:90px"
                  class="input-container"
                >
                  <md-input
                    type="time"
                    class="text-input"
                    v-model="confirmation.dateAndTime.time"
                  ></md-input>
                </md-field>
              </div>
            </div>

            <div v-if="confirmation.flightCode" class="row">
              <span v-if="confirmation.flightCode" class="mobile-title-label"
                >{{ confirmation.flightCode.label }} :
              </span>

              <div style="display:flex">
                <md-field
                  v-if="confirmation.flightAirline"
                  :style="{
                    'border-color': confirmation.flightAirline.id
                      ? 'green'
                      : 'red',
                  }"
                  style="width:30%"
                  class="input-container"
                >
                  <md-select
                    class="text-input"
                    v-model="confirmation.flightAirline.id"
                    placeholder="Uçuş Kodu"
                  >
                    <md-input
                      v-model="returnAirlineSearch"
                      type="text"
                      style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                      placeholder="Arayınız"
                    />
                    <md-option
                      v-for="airlines in allAirlines"
                      v-bind:value="airlines.id"
                      v-bind:key="airlines.id"
                    >
                      {{ airlines.iata }} - {{ airlines.name }}</md-option
                    >
                  </md-select>
                </md-field>
                <md-field
                  :style="{
                    'border-color': confirmation.flightCode.content
                      ? 'green'
                      : 'red',
                  }"
                  style="width:70%"
                  class="input-container"
                >
                  <md-input
                    style="text-align: left; padding-left: 10px;"
                    class="text-input"
                    v-model="confirmation.flightCode.content"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </div>

          <div
            style="display: flex;align-items: center;margin-top:20px;margin-bottom: 10px;margin-left:-15px"
          >
            <h5
              :style="{
                opacity: confirmation.visibleAreas.flightReturnInfos ? 1 : 0.4,
                'font-size': '18px',
              }"
            >
              {{ confirmation.flightDetails.returnLabel }}
            </h5>

            <md-switch
              style="margin-left:15px;margin-top: 7px;"
              v-model="confirmation.visibleAreas.flightReturnInfos"
              class="md-primary"
            ></md-switch>
          </div>

          <div
            :style="{
              opacity: confirmation.visibleAreas.flightReturnInfos ? 1 : 0.4,
              'pointer-events': confirmation.visibleAreas.flightReturnInfos
                ? ''
                : 'none',
            }"
          >
            <div v-if="confirmation.returnAirport" class="row">
              <span class="mobile-title-label"
                >{{ confirmation.returnAirport.label }} :</span
              >

              <md-field
                :style="{
                  'border-color': true ? 'green' : 'red',
                  width: '100%',
                }"
                class="input-container"
              >
                <md-select
                  class="text-input"
                  v-model="confirmation.returnAirport.content"
                  :placeholder="confirmation.returnAirport.label"
                >
                  <md-input
                    v-model="returnAirportSearch"
                    type="text"
                    style="width: 100%; height: 45px;margin-top: -8px;padding: 15px;"
                    placeholder="Arayınız"
                  />
                  <md-option
                    v-for="airport in allAirports"
                    v-bind:value="airport.name"
                    v-bind:key="airport.id"
                  >
                    {{ airport.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div v-if="confirmation.returnDateAndTime" class="row">
              <span class="mobile-title-label"
                >{{ confirmation.returnDateAndTime.label }} :</span
              >

              <div style="display:flex">
                <div style="margin-top:-4px;margin-right: 5px;">
                  <md-field
                    :style="{
                      'border-color': true ? 'green' : 'red',
                      width: '100%',
                    }"
                    style="padding-top:0px"
                    class="input-container"
                  >
                    <md-datepicker
                      :md-disabled-dates="isDateDisabledFlightReturnDate"
                      md-override-native
                      md-immediately
                      style="margin-bottom:0px"
                      v-model="confirmation.returnDateAndTime.content"
                    />
                  </md-field>
                </div>

                <md-field
                  :style="{
                    'border-color': true ? 'green' : 'red',
                  }"
                  style="width:90px"
                  class="input-container"
                >
                  <md-input
                    type="time"
                    class="text-input"
                    v-model="confirmation.returnDateAndTime.time"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top:20px;margin-left:-10px">
          <h5>{{ confirmation.notes.label }}</h5>
          <md-field
            :style="{
              'border-color': 'green',
            }"
          >
            <md-textarea
              v-model="confirmation.notes.content"
              :maxlength="300"
            ></md-textarea>
          </md-field>
          <span v-if="!notesHide" style="font-size:12px;color:grey"
            >Bu alanı hasta göreceğinden dolayı dikkat edilerek doldurulması
            gerekmektedir.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckCircleFillIcon from "./icons/CheckCircleFillIcon.vue";
import PreOperativeTestIcon from "./icons/PreOperativeTestIcon.vue";
import AllMedicationsIcon from "./icons/AllMedicationsIcon.vue";
import HelplineWithExpertTeamIcon from "./icons/HelplineWithExpertTeamIcon.vue";
import ConsultationWithDoctorsIcon from "./icons/ConsultationWithDoctorsIcon.vue";
// import VipCarIcon from "./icons/VipCarIcon.vue";
// import HotelIcon from "./icons/HotelIcon.vue";
import moment from "moment";
import { HTTP } from "../../main-source";

export default {
  data() {
    return {
      allCurrencies: [],
      allHotels: [],
      allTransferManagingUser: [],
      allRoomTypes: [],
      allAirports: [],
      allAirlines: [],
      changeDatas: {},
      errorStyle: "2px solid red",
      successStyle: "2px solid green",
      nullDataText: "Not",
      returnAirportSearch: "",
      returnAirlineSearch: "",
      screenWidth: 0,
      deviceControl: false,
    };
  },
  props: {
    patient: {
      type: Object,
      default() {
        return { name: "hello" };
      },
    },
    confirmation: {},
    template: {},
    appointment: {},
    avatar: {
      type: String,
    },
    visibleAreas: {},
    input: {
      type: Boolean,
    },
    appointmentStatus: {
      type: Boolean,
    },
    create: {
      type: Boolean,
    },
    notesHide: {
      type: Boolean,
    },
    notesLabelShow: {
      type: Boolean,
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  components: {
    CheckCircleFillIcon,
    PreOperativeTestIcon,
    AllMedicationsIcon,
    HelplineWithExpertTeamIcon,
    ConsultationWithDoctorsIcon,
    // HotelIcon,
    // VipCarIcon
  },
  async created() {
    if (!this.input) {
      this.deviceControl = "desktop";
    } else {
      if (this.screenWidth >= 769) {
        this.deviceControl = "desktop";
      } else {
        this.deviceControl = "mobile";
      }
    }

    await this.getAllCurrencies();
    await this.getAllHotels();
    await this.getAllUsers();
    await this.getAllRoomTypes();
    await this.getAllAirports();
    await this.getAllAirlines();
    await this.requiredControl();
  },
  methods: {
    getAllCurrencies() {
      HTTP.get("currency").then((response) => {
        this.allCurrencies = response.data;
      });
    },
    getAllHotels() {
      HTTP.get("/hotels").then((response) => {
        this.allHotels = response.data;

        if (this.create) {
          this.confirmation.hotelName.content = response?.data[0]?.name;
          this.confirmation.hotelName.hotelId = response?.data[0]?.id;
        }
      });
    },
    getAllUsers() {
      HTTP.get("/users").then((response) => {
        this.allTransferManagingUser = response.data.filter(
          (x) => x.roleId == 7
        );

        if (this.create) {
          this.confirmation.transferManagingNameSurname.content = response.data.filter(
            (x) => x.roleId == 7
          )[0]?.name;

          this.confirmation.transferManagingNameSurname.userId = response.data.filter(
            (x) => x.roleId == 7
          )[0]?.id;
        }
      });
    },
    getAllRoomTypes() {
      HTTP.get("/roomtypes").then((response) => {
        this.allRoomTypes = response.data;

        if (this.create) {
          this.confirmation.roomTypeAdultsChildren.content =
            response?.data[0]?.name;
          this.confirmation.roomTypeAdultsChildren.roomTypeId =
            response?.data[0]?.id;
        }
      });
    },
    getAllAirports() {
      HTTP.get("/airports").then((response) => {
        this.allAirports = response.data;
      });
    },
    getAllAirlines() {
      HTTP.get("/airlines").then((response) => {
        this.allAirlines = response.data;
      });
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    transferInformationsSelection(val) {
      let index = val.lastIndexOf(" ");
      let selections = val.substring(index + 1, val.length);

      let array = selections?.split("/");
      let result = [];

      for (let i in array) {
        result.push({
          id: i + 1,
          name: array[i],
        });
      }

      return result;
    },
    formatDateWithHour(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY HH:MM");
      } else {
        return "";
      }
    },
    requiredControl() {
      let control = [];

      const requiredFields = [
        {
          fieldName: "incPrice",
          value: "content",
          message: this.confirmation.incPrice.label + " alanınızı giriniz.",
          _value: this.confirmation.incPrice.content,
        },
        {
          fieldName: "depositPrice",
          value: "content",
          message: this.confirmation.depositPrice.label + " alanınızı giriniz.",
          _value: this.confirmation.depositPrice.content,
        },
      ];

      if (this.confirmation.visibleAreas.transferInfos) {
        requiredFields.push(
          {
            fieldName: "airportToHotelHospital",
            value: "content",
            message:
              this.confirmation.airportToHotelHospital.label + " seçiniz.",
          },
          {
            fieldName: "hospitalToHotelAddress",
            value: "content",
            message:
              this.confirmation.hospitalToHotelAddress.label + " seçiniz.",
          },
          {
            fieldName: "transferFromToHotelToAirport",
            value: "content",
            message:
              this.confirmation.transferFromToHotelToAirport.label +
              " seçiniz.",
          }
        );
      }

      if (this.confirmation.visibleAreas.hotelInfos) {
        requiredFields.push(
          {
            fieldName: "hotelName",
            value: "content",
            message: this.confirmation.hotelName.label + " seçiniz.",
          },
          {
            fieldName: "accommodationDay",
            value: "content",
            message: this.confirmation.accommodationDay.label + " giriniz.",
          },
          {
            fieldName: "roomTypeAdultsChildren",
            value: "content",
            message:
              this.confirmation.roomTypeAdultsChildren.label + " seçiniz.",
          },
          {
            fieldName: "checkInDate",
            value: "content",
            message: this.confirmation.checkInDate.label + " seçiniz.",
          }
        );
      }

      if (this.confirmation.visibleAreas.flightInfos) {
        if (this.confirmation.visibleAreas.flightArrivalInfos) {
          requiredFields.push(
            {
              fieldName: "arrivalAirport",
              value: "content",
              message: this.confirmation.arrivalAirport.label + " seçiniz.",
            },
            {
              fieldName: "dateAndTime",
              value: "content",
              message: this.confirmation.dateAndTime.label + " giriniz.",
            },
            {
              fieldName: "dateAndTime",
              value: "time",
              message: this.confirmation.dateAndTime.label + " giriniz.",
            },
            {
              fieldName: "flightCode",
              value: "content",
              message: this.confirmation.flightCode.label + " giriniz.",
            },
            {
              fieldName: "flightAirline",
              value: "id",
              message: this.confirmation.flightAirline.label + " seçiniz.",
            }
          );
        }

        if (this.confirmation.visibleAreas.flightReturnInfos) {
          requiredFields.push(
            {
              fieldName: "returnAirport",
              value: "content",
              message: this.confirmation.returnAirport.label + " seçiniz.",
            },
            {
              fieldName: "returnDateAndTime",
              value: "content",
              message: this.confirmation.returnDateAndTime.label + " giriniz.",
            },
            {
              fieldName: "returnDateAndTime",
              value: "time",
              message: this.confirmation.returnDateAndTime.label + " giriniz.",
            }
          );
        }
      }

      for (let i in requiredFields) {
        if (this.confirmation[requiredFields[i].fieldName]) {
          String(
            this.confirmation[requiredFields[i].fieldName][
              requiredFields[i].value
            ]
          )?.length > 0
            ? null
            : control.push(requiredFields[i]);
        }
      }

      control?.length > 0
        ? this.$emit("validation", control)
        : this.$emit("validation", false);
    },
    isDateDisabledFlightDate: function(date) {
      const appointmentDateAndTime = this.confirmation.treatmentDateTime
        .content;
      const findIndex = appointmentDateAndTime.indexOf(" - ");
      const appointmentDate = appointmentDateAndTime.substring(0, findIndex);

      const day = appointmentDate.substring(0, 2);
      const month = appointmentDate.substring(3, 5);
      const year = appointmentDate.substring(6, 10);

      const formatDate = new Date(month + "/" + day + "/" + year);

      formatDate.setDate(formatDate.getDate() - 30);

      return moment(date).isBefore(moment(formatDate).format("MM/DD/YYYY"));
    },
    isDateDisabledFlightReturnDate: function(date) {
      const appointmentDateAndTime = this.confirmation.treatmentDateTime
        .content;
      const findIndex = appointmentDateAndTime.indexOf(" - ");
      const appointmentDate = appointmentDateAndTime.substring(0, findIndex);

      const day = appointmentDate.substring(0, 2);
      const month = appointmentDate.substring(3, 5);
      const year = appointmentDate.substring(6, 10);

      const formatDate = new Date(month + "/" + day + "/" + year);

      formatDate.setDate(formatDate.getDate() - 30);

      return moment(date).isBefore(moment(formatDate).format("MM/DD/YYYY"));
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;

      if (!this.input) {
        this.deviceControl = "desktop";
      } else {
        if (this.screenWidth >= 769) {
          this.deviceControl = "desktop";
        } else {
          this.deviceControl = "mobile";
        }
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getAppointmentStatusColor(id) {
      const colors = [
        "#FFCF00",
        "#67DAD5",
        "#FA1B1A",
        "#A367FF",
        "#FF8813",
        "#11FB41",
        "#D0D0D0",
        "#D0D0D0",
      ];
      return colors[id - 1];
    },
    changeInputValue(event, valueName) {
      this.confirmation[valueName].content = event?.target?.value;
      this.requiredControl();
    },
  },
  watch: {
    confirmation() {
      this.requiredControl();
    },
    "confirmation.incPrice.content"(val) {
      this.requiredControl();
    },
    "confirmation.depositPrice.content"(val) {
      this.requiredControl();
    },
    "confirmation.transferManagingNameSurname.content"(val) {
      this.confirmation.transferManagingNameSurname.userId = this.allTransferManagingUser?.find(
        (x) => x.name == val
      )?.id;
      this.requiredControl();
    },
    "confirmation.airportToHotelHospital.content"(val) {
      this.requiredControl();
    },
    "confirmation.hospitalToHotelAddress.content"(val) {
      this.requiredControl();
    },
    "confirmation.transferFromToHotelToAirport.content"(val) {
      this.requiredControl();
    },
    "confirmation.hotelName.content"(val) {
      this.confirmation.hotelName.hotelId = this.allHotels?.find(
        (x) => x.name == val
      )?.id;
      this.requiredControl();
    },
    "confirmation.accommodationDay.content"(val, oldVal) {
      if (!parseInt(this.confirmation.accommodationDay.content)) {
        this.confirmation.accommodationDay.content = 1;
      }

      const date = this.confirmation.checkInDate.content;
      const day = date.substring(0, 2);
      const month = date.substring(3, 5);
      const year = date.substring(6, 10);

      var checkInDate = new Date(month + "/" + day + "/" + year);
      checkInDate.setDate(
        checkInDate.getDate() +
          parseInt(this.confirmation.accommodationDay.content)
      );
      this.confirmation.checkOutDate.content = checkInDate;
      this.requiredControl();
    },
    "confirmation.roomTypeAdultsChildren.content"(val) {
      this.confirmation.roomTypeAdultsChildren.roomTypeId = this.allRoomTypes?.find(
        (x) => x.name == val
      )?.id;
      this.requiredControl();
    },
    "confirmation.airportToHotelHospital.content"(val) {
      this.requiredControl();
    },
    "confirmation.checkInDate.content"(val) {
      const date = this.confirmation.checkInDate.content;
      const day = date.substring(0, 2);
      const month = date.substring(3, 5);
      const year = date.substring(6, 10);

      var checkInDate = new Date(month + "/" + day + "/" + year);

      checkInDate.setDate(
        checkInDate.getDate() +
          parseInt(this.confirmation.accommodationDay.content)
      );

      this.confirmation.checkOutDate.content = moment(checkInDate);
      this.requiredControl();
    },
    "confirmation.arrivalAirport.content"(val) {
      this.confirmation.arrivalAirport.airportId = this.allAirports?.find(
        (x) => x.name == val
      )?.id;
      this.requiredControl();
    },
    "confirmation.dateAndTime.content"(val) {
      this.requiredControl();
    },
    "confirmation.dateAndTime.time"(val) {
      this.requiredControl();
    },
    "confirmation.returnAirport.content"(val) {
      this.confirmation.returnAirport.airportId = this.allAirports?.find(
        (x) => x.name == val
      )?.id;
      this.requiredControl();
    },
    "confirmation.returnDateAndTime.content"(val) {
      if (!val) {
        this.confirmation.returnDateAndTime.content = "";
      }
      this.requiredControl();
    },
    "confirmation.returnDateAndTime.time"(val) {
      this.requiredControl();
    },
    "confirmation.flightCode.content"(val) {
      this.requiredControl();
    },
    "confirmation.flightAirline.id"(val) {
      this.requiredControl();
    },
    "confirmation.notes.content"(val) {
      this.requiredControl();
    },
    "confirmation.visibleAreas.transferInfos"() {
      this.requiredControl();
    },
    "confirmation.visibleAreas.hotelInfos"() {
      this.requiredControl();
    },
    "confirmation.visibleAreas.flightInfos"(val) {
      this.requiredControl();
      this.confirmation.visibleAreas.flightArrivalInfos = val;
      this.confirmation.visibleAreas.flightReturnInfos = val;
    },
    "confirmation.visibleAreas.flightArrivalInfos"(val) {
      this.requiredControl();
      if (
        !this.confirmation.visibleAreas.flightArrivalInfos &&
        !this.confirmation.visibleAreas.flightReturnInfos
      ) {
        this.confirmation.visibleAreas.flightInfos = false;
      }
    },
    "confirmation.visibleAreas.flightReturnInfos"(val) {
      this.requiredControl();
      if (
        !this.confirmation.visibleAreas.flightArrivalInfos &&
        !this.confirmation.visibleAreas.flightReturnInfos
      ) {
        this.confirmation.visibleAreas.flightInfos = false;
      }
    },
    "confirmation.incPrice.content"(val) {
      if (this.confirmation?.depositPrice?.content?.length > 0) {
        this.confirmation.balancedPrice.content =
          parseFloat(this.confirmation?.incPrice?.content) -
          parseFloat(this.confirmation?.depositPrice?.content);
      }
    },
    "confirmation.depositPrice.content"(val) {
      if (
        this.confirmation?.incPrice?.content?.length > 0 &&
        this.confirmation?.incPrice?.content !== ""
      ) {
        const balancedPrice =
          parseFloat(this.confirmation?.incPrice?.content) -
          parseFloat(this.confirmation?.depositPrice?.content);

        balancedPrice
          ? (this.confirmation.balancedPrice.content = balancedPrice)
          : (this.confirmation.balancedPrice.content = "0");
      }
    },

    confirmation(newVal, oldVal) {
      this.confirmation.incPrice.content = oldVal.incPrice.content;
      this.confirmation.priceCurrency.content = oldVal.priceCurrency.content;
      this.confirmation.depositPrice.content = oldVal.depositPrice.content;
      this.confirmation.priceCurrency.content = oldVal.priceCurrency.content;

      this.confirmation.vipTransfer.content = oldVal.vipTransfer.content;
      this.confirmation.transferManagingNameSurname.content =
        oldVal.transferManagingNameSurname.content;
      this.confirmation.airportToHotelHospital.content =
        oldVal.airportToHotelHospital.content;
      this.confirmation.hospitalToHotelAddress.content =
        oldVal.hospitalToHotelAddress.content;
      this.confirmation.transferFromToHotelToAirport.content =
        oldVal.transferFromToHotelToAirport.content;

      this.confirmation.hotelName.content = oldVal.hotelName.content;
      this.confirmation.accommodationDay.content =
        oldVal.accommodationDay.content;
      this.confirmation.roomTypeAdultsChildren.content =
        oldVal.roomTypeAdultsChildren.content;
      this.confirmation.checkInDate.content = oldVal.checkInDate.content;
      this.confirmation.checkOutDate.content = oldVal.checkOutDate.content;
      this.confirmation.checkOutDate.content = oldVal.checkOutDate.content;

      this.confirmation.arrivalAirport.content = oldVal.arrivalAirport.content;
      this.confirmation.dateAndTime.content = oldVal.dateAndTime.content;
      this.confirmation.dateAndTime.time = oldVal.dateAndTime.time;
      this.confirmation.returnAirport.content = oldVal.returnAirport.content;
      this.confirmation.returnDateAndTime.content =
        oldVal.returnDateAndTime.content;
      this.confirmation.returnDateAndTime.time = oldVal.returnDateAndTime.time;
      this.confirmation.flightCode.content = oldVal.flightCode.content;

      this.confirmation.visibleAreas.transferInfos =
        oldVal.visibleAreas.transferInfos;
      this.confirmation.visibleAreas.hotelInfos =
        oldVal.visibleAreas.hotelInfos;
      this.confirmation.visibleAreas.flightInfos =
        oldVal.visibleAreas.flightInfos;
    },
    returnAirportSearch(val) {
      if (val) {
        HTTP.get("/airports/search?q=" + this.returnAirportSearch).then(
          (response) => {
            this.allAirports = response?.data;
          }
        );
      } else {
        this.getAllAirports();
      }
    },
    returnAirlineSearch(val) {
      if (val) {
        HTTP.get("/airlines/search?q=" + this.returnAirlineSearch).then(
          (response) => {
            this.allAirlines = response?.data;
          }
        );
      } else {
        this.getAllAirlines();
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 800px;
  height: 1120px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Montserrat";
}

.watermark-container {
  width: 800px;
  height: 1122px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.watermark-container img {
  width: 75%;
}

.header-container {
  width: 100%;
  height: 90px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container img {
  height: 67%;
}

.header-container h2 {
  font-size: 27px;
  font-weight: bold;
  font-weight: 600;
  color: white;
  margin-top: 5px;
}

.content-container {
  width: 100%;
  height: 970px;
}

.id-date-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-left: 40px;
  font-size: 13px;
}

.id-date-container label:first-child {
  margin-right: 20px;
}

.top-content {
  width: 100%;
  height: 300px;
  display: flex;
}

.row-label-value {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  height: 65px;
}

.line {
  font-size: 20px;
  color: #565656;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: -45px;
}

.row-label-main-text {
  margin-top: 15px;
  padding-left: 40px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #4c4c4c;
  text-shadow: -1px 1px 0px #6cd8d2;
}

.row-label-big {
  font-size: 13px;
  font-weight: bold;
  color: #27aae1;
  margin-top: -20px;
}

.row-label-value-text {
  font-size: 13px;
  font-weight: bold;
  margin-top: -20px;
}

.footer {
}

.footer p {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 3px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.footer-bottom {
  width: 800px;
  height: 15px;
  background: #6cd8d2;
}

.input-container {
  margin-top: -26px;
  border-bottom: 2px solid red;
  width: 200px;
}

.input-full-container {
  margin-top: -26px;
  border-bottom: 2px solid red;
  width: 100%;
}

.default-input {
  border: 0px solid;
  text-align: right;
  width: 100%;
}

.text-input {
  text-align: right;
  background: #efefef;
  border-radius: 5px;
}

.text-area-input {
  text-align: left;
  background: #efefef;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mobile-title-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

#form {
  transition: 0.5s;
}

@media (max-width: 767.98px) {
}
</style>
