<template>
  <div>
    <div class="md-title">Hasta Kayıt</div>
    <div class="md-layout">
      <div
        class="md-layout-item item profile-wrap md-size-30 md-xsmall-size-100"
      >
        <div
          :style="{
            'border-radius': '20px',
            padding: '30px',
            border: form.isShared
              ? '2px solid blue'
              : existingData
              ? '2px solid #FF4A4A'
              : '2px solid #82CD47',
            'box-shadow': '0px 0px 20px rgba(0,0,0,0.1)',
          }"
          class="md-layout-item profile wrapper"
        >
          <img src="../../../public/images/avatar.jpg" alt="Avatar" />
          <div class="main">
            <span v-if="form.name" class="title">{{ form.name }}</span>
            <span style="margin-top: 5px "> {{ getFormCountryName() }}</span>

            <div style="margin-top: 20px;" class="md-layout" v-if="form.phone">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">phone</i>
              </div>
              <div class="md-layout-item">
                {{ form.phone }}
              </div>
            </div>

            <div
              style="margin-top: 10px;"
              class="md-layout"
              v-if="form.firstCreatedAt"
            >
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">date_range</i>
              </div>
              <div class="md-layout-item">
                {{ formatDate(form.firstCreatedAt) }}
                <small>(İlk Kayıt Tarihi)</small>
              </div>
            </div>

            <div style="margin-top: 10px;" class="md-layout" v-if="form.email">
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">mail</i>
              </div>
              <div class="md-layout-item">{{ form.email }}</div>
            </div>

            <div
              style="margin-top: 10px;"
              class="md-layout"
              v-if="form.firstOwnerUser"
            >
              <div class="md-layout-item md-size-10 icon">
                <i class="md-icon">account_circle</i>
              </div>

              <div class="md-layout-item">
                {{ form.firstOwnerUser.name }}
                <small>(İlk Kayıt Sahibi)</small>
              </div>
            </div>

            <div class="md-layout story" v-if="duplicatedPatients.length > 0">
              <span class="md-title">Hasta Geçmişi</span>
              <ul class="md-layout-item">
                <li v-for="(pat, index) in duplicatedPatients" :key="index">
                  <ul class="md-layout-item" v-if="pat.directionHistory">
                    <div
                      v-for="(item, index2) in pat.directionHistory"
                      :key="index2"
                    >
                      <span class="md-title"
                        ><span
                          :class="pat.isShared ? 'blue-text' : 'red-text'"
                          >{{ pat.type == 1 ? "Gerçek" : "Aday" }}</span
                        >
                        <b> {{ getUserName(pat.userId) }}</b> -
                        <b>{{ formatDate(pat.createdAt) }}</b></span
                      >
                      <li v-html="renderPatientHistory(item)"></li>
                    </div>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="md-layout story" v-if="form.sharingHistory">
              <span class="md-title">Paylaşım Hareketleri</span>
              <ul class="md-layout-item">
                <li v-for="(item, index) in form.sharingHistory" :key="index">
                  <span v-if="item.sharedBy != 0"
                    ><b>{{ getUserName(item.sharedBy) }}</b> tarafından </span
                  ><b>{{ formatDate(item.sharedAt) }}</b> tarihinde
                  <b>{{ getUserName(item.sharedTo) }}</b> adlı kullanıcıya
                  paylaşılmıştır.
                </li>
              </ul>
            </div>

            <div
              class="md-layout"
              v-if="
                existingData && (existingData.userId == userId || roleId == 1)
              "
            >
              <md-button
                class="md-layout-item md-raised md-primary"
                :to="{
                  name: 'potantial.patients',
                  params: { id: existingData.id },
                }"
                >Hastaya git</md-button
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="md-layout-item item profile-wrap md-xsmall-size-100 patients-details"
      >
        <!-- YENİ HASTA ADAYI OLUŞTUR - START -->
        <div class="wrapper md-layout">
          <div class="md-layout-item md-size-40 md-xsmall-size-100">
            <span class="md-title">Yeni Hasta Adayı Oluştur</span>
          </div>
          <div
            class="md-layout-item md-size-60 text-right head-buttons hidden-xs"
          >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              @click="submitForm(true)"
              >Kaydet ve Yeni</md-button
            >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              @click="submitForm"
              >Kaydet</md-button
            >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-default'
              "
              :disabled="loading"
              v-on:click="cancel"
              >İptal</md-button
            >
          </div>
        </div>
        <!-- YENİ HASTA ADAYI OLUŞTUR - END -->

        <form @submit.prevent="submitForm" class="wrapper">
          <span class="md-title">Bilgiler</span>

          <div style="margin-top: 15px;" class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <MazPhoneNumberInput
                v-model="telInput"
                :translations="{
                  countrySelectorLabel: 'Ülke',
                  countrySelectorError: 'Hata',
                  phoneNumberLabel: 'Telefon Numarası',
                  example: 'Örnek :',
                }"
                type="tel"
                :style="{
                  'border-radius': '14px',
                  border: $v.form.phone.$error
                    ? '1px solid red'
                    : `  2px solid ${existingData ? 'red' : 'transparent'} `,
                }"
                default-country-code="TR"
                :preferred-countries="['TR', 'DE', 'GB', 'NL', 'BG', 'AU']"
                :showCodeOnList="true"
                @update="onChangeTelInput"
                clearable
                startFocus="true"
              />

              <div style="margin-top: 5px;" v-if="existingData">
                <span
                  style="color : red ;font-weight: 100;font-family: sans-serif;"
                  >Telefon daha önce alınmış!</span
                >
              </div>
              <div style="margin-top: 25px;">
                <span class="md-error">{{
                  $v.form.phone.$error ? "Lütfen telefon numarası giriniz" : ""
                }}</span>
              </div>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="{ 'md-invalid': $v.form.userId.$error }">
                <label v-if="roleId != 1">
                  <small>(Hasta Sahibi)</small>
                </label>

                <md-select v-model="form.userId" placeholder="Hasta Sahibi">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.users"
                      class="searchInput"
                      type="text"
                      placeholder="Kullanıcı ara..."
                    />
                  </div>
                  <md-option
                    v-for="user in searchInOptionsData('users')"
                    v-bind:value="user.id"
                    v-bind:key="user.id"
                    >{{ user.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.userId.$error ? "Lütfen hasta sahibi seçiniz." : ""
              }}</span>
            </div>

            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="{ 'md-invalid': $v.form.innerFirmId.$error }">
                <label v-if="roleId != 1">
                  <small>Dr. HE</small>
                </label>

                <md-select
                  v-model="form.innerFirmId"
                  :disabled="roleId != 1"
                  placeholder="Firma"
                >
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.firms"
                      class="searchInput"
                      type="text"
                      placeholder="Firma ara..."
                    />
                  </div>
                  <md-option
                    v-for="firm in searchInOptionsData('firms')"
                    v-bind:value="firm.id"
                    v-bind:key="firm.id"
                    >{{ firm.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.innerFirmId.$error ? "Lütfen firma seçiniz." : ""
              }}</span>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-90">
              <md-field :class="{ 'md-invalid': $v.form.name.$error }">
                <label>Ad Soyad</label>
                <md-input v-model="form.name" ref="name"></md-input>
              </md-field>
              <span class="md-error">{{
                $v.form.name.$error ? "Lütfen ad ve soyad giriniz" : ""
              }}</span>
            </div>
            <div class="noNameBtn">
              <md-button
                class="md-icon-button md-dense md-primary"
                @click="form.name = 'İsimsiz'"
              >
                <md-icon>no_accounts</md-icon>
                <md-tooltip md-direction="top">İsimsiz</md-tooltip>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <!-- Agencie -->
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="{ 'md-invalid': $v.form.agencieId.$error }">
                <md-select v-model="form.agencieId" placeholder="Ana Kaynak">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.agencies"
                      class="searchInput"
                      type="text"
                      placeholder="Firma ara..."
                    />
                  </div>
                  <md-option
                    v-for="agencie in childDontShow(
                      searchInOptionsData('agencies')
                    )"
                    v-bind:value="agencie.id"
                    v-bind:key="agencie.id"
                    >{{ agencie.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.agencieId.$error ? "Lütfen kaynak seçiniz." : ""
              }}</span>
            </div>
            <!-- Agencie End -->
            <!-- Child Agency -->
            <div
              v-if="selectingAgenciesChild"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <md-field>
                <v-select
                  v-model="form.childAgencieId"
                  placeholder="Alt Kaynak"
                  :options="options.childAgencies"
                  label="name"
                  class="md-menu"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                </v-select>
                <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
              </md-field>
            </div>
            <!-- Child Agency End -->

            
          </div>
          <!-- Agencie End -->

          <!-- İletişim -->
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <md-field :class="{ 'md-invalid': $v.form.sourceId.$error }">
                <md-select v-model="form.sourceId" placeholder="İletişim">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.sources"
                      class="searchInput"
                      type="text"
                      placeholder="İletişim ara..."
                    />
                  </div>
                  <md-option
                    v-for="source in searchInOptionsData('sources')"
                    v-bind:value="source.id"
                    v-bind:key="source.id"
                    >{{ source.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.sourceId.$error ? "Lütfen iletişim seçiniz." : ""
              }}</span>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100 md-xsmall-size-100">
              <md-field
                :class="{ 'md-invalid': $v.form.patientStatusId.$error }"
              >
                <md-select v-model="form.patientStatusId" placeholder="Durum">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.patientStatuses"
                      class="searchInput"
                      type="text"
                      placeholder="Durum ara..."
                    />
                  </div>
                  <md-option
                    v-for="patientStatus in searchInOptionsData(
                      'patientStatuses'
                    )"
                    v-bind:value="patientStatus.id"
                    v-bind:key="patientStatus.id"
                    >{{ patientStatus.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.patientStatusId.$error ? "Lütfen durum seçiniz." : ""
              }}</span>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="{ 'md-invalid': $v.form.languageId.$error }">
                <md-select v-model="form.languageId" placeholder="Dil">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.languages"
                      class="searchInput"
                      type="text"
                      placeholder="Dil ara..."
                    />
                  </div>
                  <md-option
                    v-for="language in searchInOptionsData('languages')"
                    v-bind:value="language.id"
                    v-bind:key="language.id"
                    >{{ language.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.languageId.$error ? "Lütfen dil seçiniz." : ""
              }}</span>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-select v-model="form.secondLanguageId" placeholder="İkinci Dil">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.languages"
                      class="searchInput"
                      type="text"
                      placeholder="Dil ara..."
                    />
                  </div>
                  <md-option
                    v-for="language in searchInOptionsData('languages')"
                    v-bind:value="language.id"
                    v-bind:key="language.id"
                    >{{ language.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="{ 'md-invalid': $v.form.branchId.$error }">
                <md-select v-model="form.branchId" placeholder="Branş">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.branches"
                      class="searchInput"
                      type="text"
                      placeholder="Branş ara..."
                    />
                  </div>
                  <md-option
                    v-for="branch in searchInOptionsData('branches')"
                    v-bind:value="branch.id"
                    v-bind:key="branch.id"
                    >{{ branch.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.branchId.$error ? "Lütfen tedavi seçiniz." : ""
              }}</span>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field
                :style="{ 'pointer-events': !form.branchId ? 'none' : 'auto' }"
                :class="{
                  'md-disabled': !form.branchId,
                  'md-invalid': $v.form.treatmentId.$error,
                }"
              >
                <md-select v-model="form.treatmentId" placeholder="Tedavi">
                  <div style="padding:5px">
                    <md-input
                      v-model="searchOptions.operations"
                      class="searchInput"
                      type="text"
                      placeholder="Tedavi ara..."
                    />
                  </div>
                  <md-option
                    v-for="operation in filterData(
                      searchInOptionsData('operations'),
                      'branchId',
                      'branchId'
                    )"
                    v-bind:value="operation.id"
                    v-bind:key="operation.id"
                    >{{ operation.name }}</md-option
                  >
                </md-select>
              </md-field>
              <span class="md-error">{{
                $v.form.treatmentId.$error ? "Lütfen tedavi seçiniz." : ""
              }}</span>
            </div>
          </div>

          <div
            style="margin-top: 20px;"
            class="md-layout-item text-right head-buttons"
          >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              @click="submitForm(true)"
              >Kaydet ve Yeni</md-button
            >
            <md-button
              :class="
                loading ? 'md-raised md-disabled' : 'md-raised md-primary'
              "
              :disabled="loading"
              @click="submitForm"
              >Kaydet</md-button
            >
            <md-button class="md-raised md-default" v-on:click="cancel"
              >İptal</md-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { MazPhoneNumberInput } from "maz-ui";
import { required } from "vuelidate/lib/validators";
import { HTTP } from "../../main-source";
import moment from "moment";

export default {
  name: "CreatePatient",
  components: { MazPhoneNumberInput },
  data() {
    return {
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
      loading: false,
      telInput: null,
      existingData: null,
      duplicatedPatients: [],
      nameInputIsFocus: false,
      selectingAgenciesChild: false,
      allUsers : [],
      form: {
        //? form variables - start
        countryCode: null,
        phone: null,
        name: null,
        userId: null,
        innerFirmId: null,
        agencieId: null,
        childAgencieId: null,
        sourceId: null,
        languageId: null,
        secondLanguageId: null,
        patientStatusId: null,
        branchId: null,
        treatmentId: null,
        //? form variables - end
        source: null,
        innerFirm: null,
        isShared: null,
        firstCreatedAt: null,
        firstOwnerUser: null,
        email: null,
        sharingHistory: null,
      },
      options: {
        users: [],
        firms: [],
        agencies: [],
        sources: [],
        languages: [],
        patientStatuses: [],
        childAgencies: [],
        operations: [],
        countries: [],
        branches: [],
      },
      searchOptions: {
        users: null,
      },
    };
  },
  validations: {
    form: {
      phone: { required },
      userId: { required },
      innerFirmId: { required },
      name: { required: false },
      agencieId: { required },
      sourceId: { required },
      languageId: { required },
      patientStatusId: { required },
      branchId: { required },
      treatmentId: { required: false },
    },
  },
  created() {
    this.getAllUsers()
    this.getUsers();
    this.getCountries();
    this.getFirms();
    this.getAgencies();
    this.getSources();
    this.getLanguages();
    this.getPatientStatuses();
    this.getOperations();
    this.getBranches();
  },
  methods: {
    async checkDuplicatedPatient() {
      const phone = this.form.phone;
      this.loading = true;

      const duplicateDatasRequest = await HTTP.post(
        "/duplicateDatas/patients/phone",
        {
          phone,
        }
      );

      const { data } = duplicateDatasRequest.data;

      this.existingData = data ? data[0] : null;
      this.duplicatedPatients = data;
      if (!data[0]) {
        this.form.name = null;
        this.form.userId = null;
        this.form.innerFirmId = null;
        this.form.userId = null;
        this.form.agencieId = null;
        this.form.sourceId = null;
        this.form.languageId = null;
        this.form.patientStatusId = null;
        this.form.treatmentId = null;
        this.form.firstOwnerUser = null;
        this.form.firstCreatedAt = null;
      }
      this.loading = false;
    },
    async getAllUsers() {
      const users = await HTTP.get("/users");
      this.allUsers = users.data;
    },
    async getUsers() {
      const users = await HTTP.post("/permissions/userDatas/getUsers", {
        roleId: [3, 4],
      });
      this.options.users = users.data;
    },
    async getFirms() {
      const firms = await HTTP.get("/firms/");
      this.options.firms = firms.data;
    },
    async getAgencies() {
      const agencies = await HTTP.get(
        "/permissions/userDatas/getPatientAdditionAgencies"
      );
      this.options.agencies = agencies.data;
    },
    async getBranches() {
      const branches = await HTTP.get("/permissions/userDatas/getBranches");
      this.options.branches = branches.data;
    },
    async getSources() {
      const sources = await HTTP.get("/sources/");
      this.options.sources = sources.data;
    },
    async getLanguages() {
      const languages = await HTTP.get("/languages/");
      this.options.languages = languages.data;
    },
    async getPatientStatuses() {
      const patientStatuses = await HTTP.get("/patientstatus/");
      this.options.patientStatuses = patientStatuses.data;
    },
    async getOperations() {
      const operations = await HTTP.get("/operations/");
      this.options.operations = operations.data.filter(
        (item) => item.status == true
      );
    },
    async getCountries() {
      const countries = await HTTP.get("/countries");
      this.options.countries = countries.data;
    },
    async submitForm(continueAdd) {
      this.$v.$touch();

      if (!this.$v.$error) {
        if (this.existingData) {
          const confirmDialog = await this.$swal({
            title: "Eklemek istediğinize emin misiniz?",
            text: "Numara zaten kayıtlı!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff5252",
            confirmButtonText: "Ekle",
            cancelButtonText: "İptal",
          });

          if (confirmDialog.isConfirmed) {
            this.createPatient(continueAdd);
          }
        } else {
          this.createPatient(continueAdd);
        }
      }
    },
    async createPatient(continueAdd) {
      this.loading = true;
      const resAgencieId = this.form.childAgencieId
        ? this.form.childAgencieId.id
        : this.form.agencieId;

      const { data, isSuccess, message } = (
        await HTTP.post("/patients/create", {
          countryCode: this.form.countryCode,
          phone: this.form.phone,
          name: this.form.name,
          userId: this.form.userId,
          innerFirmId: this.form.innerFirmId,
          branchId: this.form.branchId,
          agencieId: resAgencieId,
          sourceId: this.form.sourceId,
          languageId: this.form.languageId,
          secondLanguageId: this.form.secondLanguageId,
          patientStatusId: this.form.patientStatusId,
          treatmentId: this.form.treatmentId,
        })
      )?.data;
      this.loading = false;
      if (isSuccess) {
        if (continueAdd == true) {
          location.reload();
        } else {
          this.$router.push({ name: "pool" });
        }

        this.$swal({
          icon: "success",
          text: message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal({
          icon: "error",
          text: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    getUserName(userId) {
      var user = this.allUsers?.find((z) => z.id == userId);
      return user ? user.name : "";
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    renderPatientHistory(item) {
      if (item?.isShared) {
        return `<b>${this.getUserName(
          item.directedBy
        )}</b> tarafından <b>${this.formatDate(
          item.directedAt
        )}</b> tarihinde <b> tarihinde <b>${this.getUserName(
          item.directedTo
        )}</b> adlı kullanıcıya paylaşılmıştır.`;
      } else {
        let text = "";
        if (item.directedBy == item.directedTo)
          text = `<b>${this.formatDate(
            item.directedAt
          )}</b> tarihinde <b>${this.getUserName(
            item.directedBy
          )}</b> tarafından havuza eklenmiştir.`;
        else {
          if (item.directedBy == 0) text = `Kaynaklardan gelen formlardan `;
          text +=
            `<b>${this.formatDate(
              item.directedAt
            )}</b> tarihinde <b>${this.getUserName(item.directedTo)}</b> ` +
            (item.directedBy == 0
              ? `kullanıcıya gelmiştir.`
              : `kullanıcıya yönlendirilmiştir.`);
        }

        return text;
      }
    },
    searchInOptionsData(fieldName) {
      if (this.searchOptions?.[fieldName]) {
        return this.options[fieldName]?.filter((item) =>
          String(item?.name)
            ?.toLowerCase()
            .match(String(this.searchOptions?.[fieldName]).toLowerCase())
        );
      } else {
        return this.options[fieldName];
      }
    },
    filterData(data, itemField, formField) {
      return data?.filter(
        (item) => item?.[itemField] == this?.form?.[formField]
      );
    },
    cancel: function() {
      this.$router.push({ path: "/patients" });
    },
    onChangeTelInput: function(tel) {
      this.form.phone = tel.e164;
      this.form.countryCode = tel.countryCode;
    },
    getFormCountryName: function() {
      if (!this.form.countryCode) return "";

      const country = this.options.countries?.find(
        (z) => z.code == this.form.countryCode
      );
      return country ? country.name_tr : this.form.countryCode;
    },
  },
  computed: {},
  watch: {
    telInput(value) {
      if (value?.length > 7) {
        this.checkDuplicatedPatient();
      }
    },
    existingData(value) {
      if (value) {
        this.form.name = value?.name;
        this.form.userId = value?.firstOwnerUserId;
        this.form.innerFirmId = value?.innerFirm?.id;
        this.form.userId = value?.firstOwnerUserId;
        this.form.countryCode = value?.countryCode;
        this.form.agencieId = value?.agencieId;
        this.form.sourceId = value?.sourceId;
        this.form.languageId = value?.languageId;
        this.form.patientStatusId = value?.patientStatus?.id;
        this.form.treatmentId = value?.treatmentId;
        this.form.firstOwnerUser = value?.firstOwnerUser;
        this.form.firstCreatedAt = value?.firstCreatedAt;
      }
    },
    "form.userId"(value) {
      const selectedUser = this.options.users?.find(
        (item) => item?.id == value
      );

      if (selectedUser?.userInnerFirms?.length > 0) {
        this.form.innerFirmId = selectedUser?.userInnerFirms[0]?.innerFirmId;
      } else {
        this.form.innerFirmId = null;
      }
    },
    "form.branchId"(value) {
      this.form.treatmentId = null;
    },
    "form.agencieId": function(val) {
      const currAgencie = this.options.agencies?.find((a) => a.id === val);

      if (currAgencie?.childAgencies?.length > 0) {
        this.selectingAgenciesChild = true;
        this.options.childAgencies = currAgencie.childAgencies;
        this.form.childAgencieId = null;
      } else {
        this.selectingAgenciesChild = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.wrap {
  margin: 0px -15px;
}

.profile-wrap {
  margin-top: 75px;
}

.md-icon {
  color: rgb(47, 47, 47) !important;
}

.red-text {
  color: red;
  display: inline-block !important;
}

.blue-text {
  color: blue;
  display: inline-block !important;
}

.searchInput {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(208, 208, 208);
  padding: 10px;
}

.searchInput input:focus {
  outline: 1px solid rgb(61, 61, 244);
}
</style>
