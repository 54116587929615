<template>
  <div class="md-layout">
    <div class="md-layout-item item profile-wrap md-size-30">
      <div class="md-layout-item profile wrapper">
        <img src="../../../public/images/avatar.jpg" alt="Avatar" />
        <div class="main">
          <span class="title">{{ form.name }}</span>
          {{ getRoleName(form.roleId) }}
        </div>
        <div class="secondary">
          <div class="md-layout" v-if="form.phone">
            <div class="md-layout-item md-size-10 icon">
              <i class="md-icon">phone</i>
            </div>
            <div class="md-layout-item">{{ form.phone }}</div>
          </div>

          <div class="md-layout" v-if="form.email">
            <div class="md-layout-item md-size-10 icon">
              <i class="md-icon">mail</i>
            </div>
            <div class="md-layout-item">{{ form.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout-item item profile-wrap">
      <form @submit.prevent="submitForm" class="wrapper">
        <span class="md-title">Bilgiler</span>

        <md-field :class="{ 'md-invalid': $v.form.name.$error }">
          <label>Ad Soyad</label>
          <md-input v-model="form.name" />
        </md-field>
        <span class="md-error">{{
          $v.form.name.$error ? "Lütfen ad ve soyad giriniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field
          class="tel-input"
          :class="{ 'md-invalid': $v.form.phone.$error }"
        >
          <vue-phone-number-input
            v-model="form.phone"
            :show-code-on-list="true"
            :translations="{
              countrySelectorLabel: 'Ülke',
              countrySelectorError: 'Hata',
              phoneNumberLabel: 'Telefon Numarası',
              example: 'Örnek :',
            }"
            :only-countries="['TR', 'DE', 'GB', 'NL', 'BG', 'AU']"
            @update="telInput"
            clearable
          />
        </md-field>
        <span class="md-error">{{
          $v.form.phone.$error ? "Lütfen telefon numarası giriniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.email.$error }">
          <label>E-Mail</label>
          <md-input v-model="form.email" />
        </md-field>
        <span class="md-error">{{
          $v.form.email.$error ? "Lütfen geçerli bir email adresi giriniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.password.$error }">
          <label>Şifre</label>
          <md-input v-model="form.password" type="password" />
        </md-field>
        <span class="md-error">{{
          $v.form.password.$error ? "Lütfen şifre giriniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.rePassword.$error }">
          <label>Şifre Onay</label>
          <md-input v-model="form.rePassword" type="password" />
        </md-field>
        <span class="md-error">{{
          $v.form.rePassword.$error ? "Lütfen şifreyi yeniden giriniz" : ""
        }}</span>
        <span style="color : grey"
          >Şifre en az 1 büyük harf, 1 küçük harf ve 1 rakam içermelidir.</span
        >

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.innerFirms.$error }">
          <label>Firma</label>
          <md-select v-model="form.innerFirms[0]" placeholder="Firma">
            <md-option
              v-for="firm in options.innerFirms"
              v-bind:value="firm.id"
              v-bind:key="firm.id"
              >{{ firm.name }}</md-option
            >
          </md-select>
        </md-field>
        <span class="md-error">{{
          $v.form.innerFirms.$error ? "Lütfen firma seçiniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.roleId.$error }">
          <label>Kullanıcı Rolü</label>
          <md-select v-model="form.roleId" placeholder="Kullanıcı Rolü">
            <md-option
              v-for="role in options.roles"
              v-bind:value="role.id"
              v-bind:key="role.id"
              >{{ role.name }}</md-option
            >
          </md-select>
        </md-field>
        <span class="md-error">{{
          $v.form.roleId.$error ? "Lütfen rol seçiniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.sex.$error }">
          <label>Cinsiyet</label>
          <md-select v-model="form.sex">
            <md-option value="false">Kadın</md-option>
            <md-option value="true">Erkek</md-option>
          </md-select>
        </md-field>
        <span class="md-error">{{
          $v.form.sex.$error ? "Lütfen cinsiyet seçiniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-datepicker
          :class="{ 'md-invalid': $v.form.birthday.$error }"
          v-model="form.birthday"
          md-immediately
        >
          <label>Doğum Tarihi</label>
        </md-datepicker>
        <span class="md-error">{{
          $v.form.birthday.$error ? "Lütfen doğum tarihi seçiniz" : ""
        }}</span>

        <div style="height: 10px;" />

        <md-field :class="{ 'md-invalid': $v.form.locations.$error }">
          <label>Kurum</label>
          <md-select
            v-model="form.locations"
            name="locations"
            id="locations"
            multiple
          >
            <md-option
              v-for="location in options.locations"
              v-bind:value="location.id"
              v-bind:key="location.id"
              >{{ location.name }}</md-option
            >
          </md-select>
        </md-field>
        <span class="md-error">{{
          $v.form.locations.$error ? "Lütfen kurum seçiniz" : ""
        }}</span>

        <div style="height: 40px;" />

        <md-button type="submit" class="md-raised md-primary full-btn"
          >Kullanıcı Ekle</md-button
        >
      </form>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { HTTP } from "@/main-source";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        phone: "",
        formattedPhone: "",
        email: "",
        password: "",
        rePassword: "",
        innerFirms: [],
        roleId: "",
        sex: "",
        birthday: "",
        locations: [],
      },
      options: {
        innerFirms: [],
        roles: [],
        locations: [],
      },
    };
  },
  components: {
    "vue-phone-number-input": VuePhoneNumberInput,
  },
  validations: {
    form: {
      name: { required },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        lowercase: (value) => /[a-z]/.test(value),
        uppercase: (value) => /[A-Z]/.test(value),
        digit: (value) => /[0-9]/.test(value),
      },
      rePassword: {
        required,
        minLength: minLength(8),
        lowercase: (value) => /[a-z]/.test(value),
        uppercase: (value) => /[A-Z]/.test(value),
        digit: (value) => /[0-9]/.test(value),
        sameAsPassword: sameAs("password"),
      },
      phone: {
        required,
      },
      innerFirms: {
        required,
      },
      roleId: {
        required,
      },
      sex: {
        required,
      },
      birthday: {
        required,
      },
      locations: {
        required,
      },
    },
  },
  created() {
    this.getInnerFirms();
    this.getLocations();
    this.getRoles();
  },
  methods: {
    async getInnerFirms() {
      const innerFirms = await HTTP.get("/firms/");
      this.options.innerFirms = innerFirms.data;
    },
    async getLocations() {
      const locations = await HTTP.get("/locations");
      this.options.locations = locations.data;
    },
    async getRoles() {
      const roles = await HTTP.get("/roles");
      this.options.roles = roles.data;
    },
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$error) {
        const createUser = await HTTP.post("/users/create", this.form);
        const { message, isSuccess } = createUser?.data;
        this.$swal("", message, isSuccess ? "success" : "error");
      } else {
        this.$swal("", "Lütfen gerekli alanları doldurunuz!", "error");
      }
    },
    telInput(tel) {
      if (tel.isValid) {
        this.form.formattedPhone = tel.formattedNumber;
      }
    },
    getRoleName(roleId) {
      if (roleId) {
        return this.options.roles.find((item) => item?.id == roleId)?.name;
      } else {
        return null;
      }
    },
  },
};
</script>
