<template>
  <div class="wrapper">
    <span class="md-title">Günlük Özel İşlem Limitleri</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="addCustomLimitModal=true">
      <md-icon>add</md-icon>
    </md-button>

    <md-table class="table appointments" v-model="customLimits">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Tip">{{ _operationTypeName(item.operationType) }}</md-table-cell>
        <md-table-cell md-label="Limit">{{ item.limit }}</md-table-cell>
        <md-table-cell md-label="Tarih">{{_formatDate(item.date) }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateCustomLimit(item.id)">edit</i>
          <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteCustomLimit(item.id)">delete</i>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="addCustomLimitModal" class="dialog">
      <md-content class="statuses">
        <h3>Ekle</h3>
        <md-field>
          <label for="operationType">Operasyon Tipi</label>
          <md-select v-model="customLimit.operationType">
            <md-option value="surgery">Ameliyat</md-option>
            <md-option value="nonSurgery">Ameliyatsız</md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Limit</label>
          <md-input v-model="customLimit.limit" type="number"></md-input>
        </md-field>

        <md-field>
          <label for="selected_date">Tarih</label>
          <md-datepicker v-model="customLimit.date" md-immediately>
            <label>Tarih Seçiniz</label>
          </md-datepicker>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="addCustomLimit">Ekle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateCustomLimitModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field>
          <label for="operationType">Operasyon Tipi</label>
          <md-select v-model="customLimit.operationType">
            <md-option value="surgery">Ameliyat</md-option>
            <md-option value="nonSurgery">Ameliyatsız</md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Limit</label>
          <md-input v-model="customLimit.limit" type="number"></md-input>
        </md-field>

        <md-field>
          <label for="selected_date">Tarih</label>
          <md-datepicker v-model="customLimit.date" md-immediately>
            <label>Tarih Seçiniz</label>
          </md-datepicker>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateCustomLimitDone">Güncelle</md-button>
      </md-content>
    </md-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import moment from "moment";
export default {
  name: "CustomLimit",
  data() {
    return {
      operationTypes : {'surgery': 'Ameliyat', 'nonSurgery': 'Ameliyatsız'},
      customLimits: [],
      customLimit: {
        date: new Date(),
        limit: 0,
        operationType: "surgery",
      },
      addCustomLimitModal: false,
      updateCustomLimitModal: false
    };
  },
  created() {
    this.getCustomLimits();
  },
  methods: {

    _formatDate: function(value){
      if(value) return moment(String(value)).format("YYYY-MM-DD"); 
    },

    _operationTypeName: function(value){
      if(value) return this.operationTypes[value];
    },

    addCustomLimit: function() {
      HTTP.post("/settings/customLimits/day-limit", this.customLimit).then(result => {
        if (result.status == 201) {
          this.getCustomLimits();
          this.addCustomLimitModal = false;
        }
      });
    },
    getCustomLimits: function() {
      HTTP.get("/settings/customLimits/day-limit").then(result => {
        this.customLimits = result.data;
      });
    },
    updateCustomLimit: function(id) {
      this.customLimit = this.customLimits.filter(customLimits => customLimits.id == id)[0];
      this.customLimit.date = new Date(this.customLimit.date);
      this.updateCustomLimitModal = true;
    },
    updateCustomLimitDone: function() {
      HTTP.put("/settings/customLimits/day-limit/" + this.customLimit.id, this.customLimit).then(result => {
        if (result.status == 204) {
          this.getCustomLimits();
          this.updateCustomLimitModal = false;
        }
      });
    },
    deleteCustomLimit: function(id) {
        if(confirm("Emin misiniz?")){
            HTTP.delete("/settings/customLimits/day-limit/" + id).then(result => {
              if (result.status == 204) {
                this.getCustomLimits();
              }
            });
        }
    },
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.active {
  background: green;
}
.passive {
  background: red;
}
.sortButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 50px !important;
  top: 10px !important;
}
.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    cursor: move;
}
</style>