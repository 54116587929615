<template>
  <div>
    <Topbar :patId="patientsId" :parent="this" :patient="patient"></Topbar>
    <Navigation class="navigation" :id="patientsId"></Navigation>
    <!-- <AppointmentCard
      :appointment="appointment"
      :appointmentDetails="appointmentDetails"
      @closeAppointmentCard="closeAppointmentCard"
      @refreshAppointments="refreshAppointments"
    ></AppointmentCard> -->

    <div class="md-layout details">
      <div class="md-layout-item md-size-100 md-xsmall-size-100 wrapper">
        <div class="md-layout">
          <span class="md-title title">Yeni Epikriz Ekle</span>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-layout"
          v-if="epicrisisContent"
        >
          <md-field class="calFilter md-layout-item md-size-10">
            <v-select
              v-model="selectedLanguageId"
              placeholder="Diller"
              :options="languages"
              :value="id"
              label="name"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-field class="calFilter md-layout-item md-size-55">
            <v-select
              v-model="selectedContent"
              placeholder="Dosyalar"
              :options="FiltredEpicrisisContent"
              :value="id"
              label="name"
              :disabled="!selectedLanguageId"
              class="md-menu"
            >
              <template slot="option" slot-scope="option">
                {{ languages[option.languageId - 1].code + " " + option.name }}
              </template>
            </v-select>
          </md-field>
          <!-- Templates -->
          <md-field class="calFilter md-layout-item md-size-10">
            <v-select
              v-model="selectedTemplate"
              placeholder="Şablonlar"
              :options="templateOptions"
              :value="id"
              label="name"
              class="md-menu"
              :disabled="!selectedContent"
            >
              <template slot="option" slot-scope="option">{{
                option.name
              }}</template>
            </v-select>
          </md-field>
          <md-button
            @click="createEpicrisis"
            class="md-raised md-primary add-button md-layout-item md-size-10"
            >Ekle</md-button
          >
        </div>
        <!--
        <div>
          <md-field
            class="md-layout-item md-size-50 md-xsmall-size-100 add-field"
          >
            <label for="slug">Dosyalar</label>
            <md-select v-model="selectedContent" name="slug">
              <md-option
                v-for="content in epicrisisContent"
                :key="content"
                :value="content.id"
                >{{ content.name }}</md-option
              >
            </md-select>
          </md-field>
          <md-button
            @click="createEpicrisis"
            class="md-raised md-primary add-button"
            >Ekle</md-button
          >
        </div> -->
      </div>
      <div class="md-layout-item wrapper md-size-100">
        <!-- sams -->

        <md-table v-model="searched" md-sort="name" md-sort-order="asc">
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">Epikriz Raporları</h1>
            </div>

            <md-field md-clearable class="md-toolbar-section-end">
              <md-input
                placeholder="Search by name..."
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Dosya Adı" md-sort-by="name">{{
              item.name
            }}</md-table-cell>
            <md-table-cell md-label="Template">{{
              item.epicrisisTemplate.name
            }}</md-table-cell>
            <md-table-cell
              md-label="Son Guncelleyen"
              md-sort-by="lastUpdateUser"
              >{{ item.lastUpdateUser }}</md-table-cell
            >
            <md-table-cell
              md-label="Son Güncelleme Tarihi"
              md-sort-by="updateAt"
              >{{ item.updatedAt }}</md-table-cell
            >
            <md-table-cell md-label="Düzenle / Sil" md-sort-by="edit">
              <div class="">
                <router-link
                  :to="{
                    name: 'patients.epicrisisUpdate',
                    params: {
                      patient: item.patientId,
                      id: item.id,
                    },
                  }"
                >
                  <md-button class="md-succes">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
              </div>
              <div class="">
                <md-button
                  v-if="true"
                  class="md-accent"
                  v-on:click="deleteEpircrisis(item.id)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-dialog-confirm
          :md-active.sync="deleteModal"
          md-title="Dosyayı silmek istediğinize emin misiniz?"
          md-content="Bu işlem geri alınamaz."
          md-confirm-text="Sil"
          md-cancel-text="İptal Et"
          @md-confirm="onConfirm()"
        />
      </div>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};
import { HTTP } from "@/main-source";
import Navigation from "@/components/patients/potantials/nav";
import Topbar from "@/components/patients/potantials/topbar";
import moment from "moment";

export default {
  name: "Epicrisis",
  data() {
    return {
      deleteModal: false,
      epicrisis: null,
      epicrisisContent: null,
      FiltredEpicrisisContent: null,
      users: [],
      search: null,
      searched: [],
      patientsId: this.$route.params.id,
      appointments: {},
      lastUpdateUserName: "",
      selectedContent: null,
      selectedEventId: null,
      selectedLanguageId: null,
      templateOptions: [],
      selectedTemplate: null,
      // patientId:
      appointment: {
        patient: {
          name: "",
          phone: "",
          email: "",
          sex: "",
          birthday: "",
          source: "",
          country: "",
        },
        statusId: "",
        location: {
          name: "",
        },
        operation: {
          name: "",
        },
      },
      // appointmentDetails: false,
      created: false,
      isDietician: localStorage.getItem("roleId") == 9,
      isDoctor: localStorage.getItem("roleId") == 2,
      languages: null,
    };
  },
  components: {
    Navigation,
    Topbar,
    // AppointmentCard,
  },
  async created() {
    HTTP.get("/patients/" + this.patientsId).then((result) => {
      this.patient = result.data[0];
    });
    await HTTP.get("/epicrisisTemplate/").then((result) => {
      let data = result.data.filter((x) => x.isEpicrisis == true);
      data.forEach((element) => {
        this.templateOptions.push({
          id: element.id,
          name: element.name,
        });
      });
    });

    await HTTP.get("/languages").then((result) => {
      this.languages = result.data;
    });

    await HTTP.get("/epicrisis/content").then((result) => {
      this.epicrisisContent = result.data;
      this.FiltredEpicrisisContent = result.data;
    });

    await HTTP.get("/users/").then((result) => {
      this.users = result.data;
      this.getEpicrises();
    });
  },
  methods: {
    deleteEpircrisis: function (id) {
      this.selectedEventId = id;
      this.deleteModal = true;
      //
    },
    onConfirm: function () {
      HTTP.delete("epicrisis/?id=" + this.selectedEventId).then((result) => {
        if (result.status == 204) {
          this.getEpicrises();
        }
      });
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = searchByName(this.epicrisis, this.search);
    },
    serializeURLQueryParams(params) {
      return new URLSearchParams(params).toString();
    },

    getEpicrises() {
      this.searched = [];
      this.epicrisis = [];
      let params = {
        patientId: this.patientsId,
      };
      HTTP.get("/epicrisis?" + this.serializeURLQueryParams(params)).then(
        (result) => {
          this.epicrisis = result.data;
          for (const epi of result.data) {
            var temp = new Object();
            temp.name = epi.name;
            temp.epicrisisTemplate = epi.epicrisisTemplate;
            temp.id = epi.id;
            temp.lastUpdateUser = this.users.find(
              (z) => z.id == epi.lastUpdateUserId
            ).name;
            temp.updatedAt = moment(epi.updatedAt).format("DD/MM/YYYY");
            temp.patientId = epi.patientId;
            //
            this.searched.push(temp);
          }
          //this.searched = result.data
          this.epicrisis = this.searched;
          //
        }
      );
    },

    createEpicrisis() {
      if (
        this.selectedTemplate == null ||
        this.selectedContent == null ||
        !this.selectedLanguageId
      ) {
        this.$swal({
          icon: "warning",
          text: "Lütfen İstenilen Verileri Seçiniz.",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        let params = {
          patientId: this.patientsId,
          name: this.selectedContent.name,
          appointmentId: this.appointmentId,
          id: this.selectedContent.id,
          // content: JSON.stringify(this.epicrisis),
          lastUpdateUserId: localStorage.getItem("userId"),
          templateId: this.selectedTemplate.id,
        };

        HTTP.post("/epicrisis", params)
          .then((res) => {
            if (res.status == 201) {
              this.$swal({
                icon: "success",
                text: "İşlem Başarıyla tamamlandı.",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getEpicrises();
            }
          })
          .catch((e) => {
            this.message =
              "Bir şeyler ters gitti, lütfen girdiğiniz verileri kontrol ederek tekrar deneyiniz veya sistem yöneticinize başvurunuz.";
            this.$swal("", this.message, "error");
          });
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    refreshAppointments() {
      this.getEpicrises();
    },
  },
  computed: {},

  watch: {
    selectedLanguageId: function () {
      this.selectedContent = null;
      if (!this.selectedLanguageId) {
        this.FiltredEpicrisisContent = this.epicrisisContent;
      } else {
        this.FiltredEpicrisisContent = this.epicrisisContent.filter(
          (z) => z.languageId == this.selectedLanguageId.id
        );
      }
    },
  },
};
</script>

<style lang="scss"  >
@import "@/assets/scss/colors.scss";
.md-select-menu {
  max-width: fit-content !important;
  width: fit-content !important;
}
.add-field {
  margin-left: 15px !important;
}
.add-button {
  margin-left: 15px !important;
}
.participants {
  display: block;
  span {
    display: inline-block;
  }
}

.table {
  width: 100%;
  margin-top: 0px;
}
.title {
  display: inline-block;
  margin: 15px 0px 0px 15px;
}
.dialog.md-dialog {
  padding: 20px 25px;
  // margin: 0px 0px 15px 0px;
  min-width: 40vw;
  // max-width: 80vw;
  .md-dialog-title {
    padding: 0px;
  }
}
.appointments {
  .md-table-row {
    cursor: pointer;
  }
}
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.pad {
  padding-right: 10px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .md-dialog-actions {
    flex-wrap: wrap !important;
    padding: 0 !important;
    justify-content: flex-start !important;
    a,
    button {
      margin: 10px 5px 0px 0px !important;
    }
  }
}
.add-button {
  margin-left: 1rem;
}
.calFilter {
  min-width: 160px !important;
  display: inline-block !important;
  //margin-right: 20px !important;
}
</style>
