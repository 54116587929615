<template>
  <div class="main-container">
    <div
      style="
        position: fixed;
        z-index: 0;
        height: 100vh;
        top: 0;
        -webkit-backface-visibility: hidden;
      "
    >
      <img
        style="width: 100vw; height: 100vh; object-fit: cover"
        src="/images/appointment-detail-bg.png"
      />
    </div>
    <div
      style="justify-content: center; display: flex; flex-wrap: wrap"
      class="btn-wrap m-3"
    >
      <md-button
        style="margin-bottom: 15px !important"
        v-if="![3, 4].includes(statusId)"
        @click="exportToPDF"
        class="md-raised md-secondary btn-right"
      >
        PDF indir
        <md-icon class="btn-icon">download</md-icon>
      </md-button>
      <!-- <md-menu md-size="big" md-direction="top-top" :md-active.sync="shareCard">
        <md-button class="md-raised md-secondary btn-right" md-menu-trigger>
          <md-icon>share</md-icon>
          Paylaş
        </md-button>

        <md-menu-content>
          <div class="md-menu-item-content">
            <ion-icon name="logo-whatsapp"></ion-icon>
            Whatsapp
          </div>
          <div class="md-menu-item-content">
            <ion-icon name="mail-outline"></ion-icon>
            Mail
          </div>
        </md-menu-content>
      </md-menu> -->

      <md-button
        style="margin-bottom: 15px !important"
        v-if="session"
        @click="goToPatient"
        class="md-raised md-secondary btn-right"
      >
        Hastaya Dön
        <md-icon class="btn-icon">person</md-icon>
      </md-button>
      <md-button
        style="margin-bottom: 15px !important"
        v-if="session"
        @click="goToHomePage"
        class="md-raised md-secondary btn-right"
      >
        Randevuya Dön
        <md-icon class="btn-icon">home</md-icon>
      </md-button>
    </div>
    <div v-if="![3, 4].includes(statusId)" class="content-container">
      <div v-if="screenWidth < 769">
        <div style="width: 100%; display: flex; justify-content: center">
          <h3 style="text-align: center; width: 80%">
            Treatment Confirmation Form
          </h3>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
          "
        >
          <div
            :style="{
              width: '80%',
              background: getAppointmentStatusColor(appoinment.statusId),
              padding: '9px',
              borderRadius: '15px',
              textAlign: 'center',
              'justify-content': 'center',
              'align-items': 'center',
            }"
          >
            <span style="color: white; font-size: 17px">{{
              appoinment.status ? appoinment.status.name : ""
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="screenWidth >= 769" style="display: flex; align-items: center">
        <h3 style="text-align: center">Treatment Confirmation Form -</h3>
        <div
          :style="{
            background: getAppointmentStatusColor(appoinment.statusId),
            padding: '9px',
            borderRadius: '15px',
            textAlign: 'center',
            'justify-content': 'center',
            'align-items': 'center',
          }"
        >
          <span style="color: white; font-size: 17px">{{
            appoinment.status ? appoinment.status.name : ""
          }}</span>
        </div>
      </div>
      <iframe
        frameborder="0"
        v-if="flightCode && session"
        scrolling="yes"
        class="flightIframe"
        :src="`https://www.google.com/search?q=${flightCode}&oq=AFR570&igu=1`"
      ></iframe>

      <div v-if="screenWidth >= 769" ref="pdfContentDesktop">
        <ConfirmationTemplate
          notesHide
          :appointmentStatus="appoinment.status"
          class="confirmation-template"
          :confirmation="content"
          :template="template"
          :avatar="currentUserAvatar"
        />
      </div>

      <div v-if="screenWidth < 769" style="height: 1100px; overflow: hidden">
        <div style="transform: scale(0.45) !important; margin-top: -75%">
          <ConfirmationTemplate
            notesHide
            :appointmentStatus="appoinment.status"
            class="confirmation-template"
            :confirmation="content"
            :template="template"
            :avatar="currentUserAvatar"
          />
        </div>
      </div>

      <div
        :style="{ display: !mobileDocumentView ? 'none' : '' }"
        ref="pdfContent"
      >
        <ConfirmationTemplate
          notesHide
          :appointmentStatus="appoinment.status"
          class="confirmation-template"
          :confirmation="content"
          :template="template"
          :avatar="currentUserAvatar"
        />
      </div>
    </div>
    <div class="content-container" v-if="statusId == 3">
      <h3>Randevu İptal Edilmiştir</h3>
    </div>
    <div class="content-container" v-if="statusId == 4">
      <h3>Randevu Ertelenmiştir</h3>
    </div>
  </div>
</template>

<script>
import ConfirmationTemplate from "../components/confirmationTemplate/confirmationTemplate.vue";
import { HTTP } from "../main-source";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      content: {},
      template: {},
      user: {},
      currentUserAvatar: "/images/avatar.jpg",
      storagepath: this.$storage,
      flightCode: false,
      appoinment: {},
      shareCard: false,
      session: localStorage.getItem("userId"),
      statusId: 1,
      mobileDocumentView: false,
      screenWidth: 0,
    };
  },
  components: {
    ConfirmationTemplate,
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  async created() {
    await this.getAppointmentConfirmationForm();
  },
  methods: {
    getAppointmentConfirmationForm() {
      HTTP.get(
        "/appointments/confirmationForm/" + this.$route.params.guid
      ).then((response) => {
        let lastAppointmentConfirmationForm =
          response.data?.fileContents[response.data?.fileContents?.length - 1];
        let content = lastAppointmentConfirmationForm.content;
        this.user = response.data.user;

        if (this.user.profileImg !== null) {
          this.currentUserAvatar = `data:image/png;base64, ${this.user.base64ProfileImg}`;
        }

        content.confirmationId.content = lastAppointmentConfirmationForm.guid;
        this.content = content;
        this.template = lastAppointmentConfirmationForm.template;

        if (content.visibleAreas?.flightInfos) {
          this.flightCode = content?.flightAirline?.iata
            ? content?.flightAirline?.iata + content?.flightCode?.content
            : null;
        }

        this.appoinment = response?.data;
      });
    },
    async exportToPDF() {
      const config = {
        margin: 0,
        filename:
          `${this?.appoinment?.patient?.name} - ${this?.appoinment?.operation?.name} - ${this?.appoinment?.date} - ${this?.appoinment?.id} ` +
          ".pdf",
        image: { type: "jpg", quality: 1 },
        html2canvas: { scale: 4, dpi: 300, letterRendering: false },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          compressPdf: true,
        },
      };

      if (this.screenWidth > 769) {
        await html2pdf(this.$refs.pdfContentDesktop, config);
      } else {
        await this.updateState("mobileDocumentView", true);
        await html2pdf(this.$refs.pdfContent, config);
        await this.updateState("mobileDocumentView", false);
      }
    },
    goToHomePage() {
      const route = this.$router.resolve({
        name: "AppointmentsList",
        query: { date: this.appoinment.date },
      });

      window.open(route.href, "_blank");
    },
    goToPatient() {
      const route = this.$router.resolve({
        name: "potantial.patients",
        params: {
          id: this.appoinment.patientId,
        },
      });

      window.open(route.href, "_blank");
    },
    shareBtnClick() {
      this.shareCard = !this.shareCard;
    },
    getAppointmentStatusColor(id) {
      const colors = [
        "#FFCF00",
        "#67DAD5",
        "#FA1B1A",
        "#A367FF",
        "#FF8813",
        "#11FB41",
        "#D0D0D0",
        "#D0D0D0",
      ];
      return colors[id - 1];
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    updateState(name, value) {
      this[name] = value;
    },
  },
};
</script>

<style scoped>
h3 {
  color: rgb(46, 46, 46);
  font-family: sans-serif;
  margin: 20px;
  font-weight: 100;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-template {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.content-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  align-items: center;
}

.flightIframe {
  width: 700px;
  height: 345px;
  overflow: scroll;
  border: 0px;
  border-radius: 20px;
  margin-bottom: 30px;
  overflow-x: hidden;
  transition: 0.5s;
}

.md-menu-item-content {
  width: 100%;
  height: 50px;
  display: flex;
  transition: 0.5s;
  align-items: center;
  border-bottom: 1px solid rgb(189, 189, 189);
  padding: 10px;
}

.md-menu-item-content:hover {
  background: rgb(228, 228, 228);
  transition: 0.5s;
  cursor: pointer;
}

ion-icon {
  font-size: 25px;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .flightIframe {
    width: 90vw;
    transition: 0.5s;
  }
}
</style>
