<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#132573"
    />
    <!-- <div class="md-layout">
      <h1>{{ title }}</h1>
    </div>-->

    <!-- <md-dialog :md-active.sync="customFilterModal" class="dialog" v-if="false">
      <md-dialog-title>Yeni Filtre</md-dialog-title>
      <md-content>
        <md-field>
          <v-select
            v-model="nf_user"
            placeholder="Kullanıcılar"
            :options="users"
            label="name"
            class="md-menu"
            v-if="roleId == 1 || roleId == 4"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_firms"
            placeholder="İç Firma"
            :options="firms"
            label="name"
            class="md-menu"
            v-if="roleId == 1 || roleId == 4"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_country"
            placeholder="Ülke"
            :options="countrys"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_agencie"
            placeholder="Acente"
            :options="agencies"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_patientStatus"
            placeholder="Durum"
            :options="patientStatus"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_when"
            placeholder="Ne Zaman?"
            :options="whens"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            v-model="nf_operation"
            placeholder="Operasyon"
            :options="operations"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <label>Başlık</label>
          <md-input v-model="nf_name" ref="nf_name"></md-input>
          <span class="md-error">Bu alanı boş bırakmamalısınız!</span>
        </md-field>

        <div class="md-layout text-right">
          <md-button
            class="md-raised md-default"
            v-on:click="customFilterModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="addFilter"
            >Kaydet</md-button
          >
        </div>
      </md-content>
    </md-dialog> -->
    <!-- Yönlendir -->
    <!-- rediract dialog for the single patient -->
    <md-dialog :md-active.sync="showDirectModal" class="dialog allowOverflow">
      <md-content class="statuses">
        <h3>Hastayı Yönlendir</h3>
        <v-select
          v-model="directToUser"
          placeholder="Kullanıcılar"
          :options="users"
          label="name"
          class="md-menu"
        >
          <template slot="option" slot-scope="option">{{
            option.name
          }}</template>
        </v-select>
        <md-checkbox v-model="directedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        >
        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showDirectModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="directComplete"
            >Yönlendir</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="patientInformationModal"
      class="dialog allowOverflow"
    >
      <md-content style="overflow-x: hidden" class="statuses">
        <h3>Hasta Bilgileri</h3>
        <div class="md-layout md-gutter" v-if="editedPatient">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Hasta Adı</span>
              <md-input v-model="editedPatient.name"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Tedavi</span>
              <v-select
                v-model="editedPatient.operation"
                placeholder="Tedavi"
                :options="operations2"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
            <span v-if="error" class="md-error"
              >Bu alanı boş bırakmamalısınız!</span
            >
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Ülke</span>
              <v-select
                v-model="editedPatient.country"
                placeholder="Ülke"
                :options="countries"
                label="name_tr"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Telefon</span>
              <md-input readonly v-model="editedPatient.phone"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Kaynak</span>
              <v-select
                v-model="editedPatient.agencie"
                placeholder="Kaynak"
                :options="agencies"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Dil</span>
              <v-select
                v-model="editedPatient.language"
                placeholder="Dil"
                :options="languages"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">E-Mail</span>
              <md-input
                :placeholder="false"
                v-model="editedPatient.email"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">İletişim</span>
              <!-- <md-textarea
                :disabled="!isEditable"
                v-model="editedPatient.source"
                v-if="editedPatient.source && !patSource"
                md-autogrow
              ></md-textarea> -->
              <v-select
                :clearable="false"
                v-model="editedPatient.sourceModel"
                placeholder="İletişim"
                :options="sources"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
            <span v-if="sourceError" class="md-error"
              >Bu alanı boş bırakmamalısınız!</span
            >
          </div>
          <div
            class="md-layout text-right"
            style="margin-top: 20px; margin-right: 15px"
          ></div>
          <!-- <div class="md-layout-item wrapper md-size-100" v-if="statusHistory.length > 0">
            <span class="md-title">Durum Hareketleri</span>
            <ul>
              <li  v-for="(item,index) in statusHistory" :key="index">
              <span v-if="item.oldStatus != null">
                <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.oldStatus)}}</b>'den <b>{{getStatusName(item.newStatus)}}</b>'ye değiştirildi.
                </span> 
                <span v-else> 
                  <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.newStatus)}}</b> olarak atandı.
                </span>
            <br>
              <span v-if="item.note != null"><b>Durum Notu:</b> {{item.note}}</span></li>
            </ul>
          </div> -->
        </div>
        <div class="buttons-container">
          <div class="button-white-border">
            <md-button
              @click="patientInformationSaveButtonClick"
              class="md-raised md-primary"
              >KAYDET</md-button
            >
          </div>
          <md-button
            class="md-raised button-margins md-accent"
            v-on:click="convertPatient"
          >
            <md-icon>info</md-icon>
            <span v-if="!editedPatient.patientCode"> Hastaya Dönüştür </span>
            <span v-if="editedPatient.patientCode">Randevu Oluştur </span>
          </md-button>
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="patientInformationModal"
      class="dialog allowOverflow"
    >
      <md-content style="overflow-x: hidden" class="statuses">
        <h3>Hasta Bilgileri</h3>
        <div class="md-layout md-gutter" v-if="editedPatient">
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Hasta Adı</span>
              <md-input v-model="editedPatient.name"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Tedavi</span>
              <v-select
                v-model="editedPatient.operation"
                placeholder="Tedavi"
                :options="operations2"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
            <span v-if="error" class="md-error"
              >Bu alanı boş bırakmamalısınız!</span
            >
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Ülke</span>
              <v-select
                v-model="editedPatient.country"
                placeholder="Ülke"
                :options="countries"
                label="name_tr"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name_tr
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Telefon</span>
              <md-input readonly v-model="editedPatient.phone"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Kaynak</span>
              <v-select
                v-model="editedPatient.agencie"
                placeholder="Kaynak"
                :options="agencies"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">Dil</span>
              <v-select
                v-model="editedPatient.language"
                placeholder="Dil"
                :options="languages"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">E-Mail</span>
              <md-input
                :placeholder="false"
                v-model="editedPatient.email"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field>
              <span class="list-title">İletişim</span>
              <!-- <md-textarea
                :disabled="!isEditable"
                v-model="editedPatient.source"
                v-if="editedPatient.source && !patSource"
                md-autogrow
              ></md-textarea> -->
              <v-select
                :clearable="false"
                v-model="editedPatient.sourceModel"
                placeholder="İletişim"
                :options="sources"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
              </v-select>
            </md-field>
            <span v-if="sourceError" class="md-error"
              >Bu alanı boş bırakmamalısınız!</span
            >
          </div>
          <div
            class="md-layout text-right"
            style="margin-top: 20px; margin-right: 15px"
          ></div>
          <!-- <div class="md-layout-item wrapper md-size-100" v-if="statusHistory.length > 0">
            <span class="md-title">Durum Hareketleri</span>
            <ul>
              <li  v-for="(item,index) in statusHistory" :key="index">
              <span v-if="item.oldStatus != null">
                <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.oldStatus)}}</b>'den <b>{{getStatusName(item.newStatus)}}</b>'ye değiştirildi.
                </span> 
                <span v-else> 
                  <span v-if="item.changedBy != 0"><b>{{getUserName(item.changedBy)}}</b> tarafından </span><b>{{formatDate(item.changedAt)}}</b> tarihinde durum <b>{{getStatusName(item.newStatus)}}</b> olarak atandı.
                </span>
            <br>
              <span v-if="item.note != null"><b>Durum Notu:</b> {{item.note}}</span></li>
            </ul>
          </div> -->
        </div>
        <div class="buttons-container">
          <div class="button-white-border">
            <md-button
              @click="patientInformationSaveButtonClick"
              class="md-raised md-primary"
              >KAYDET</md-button
            >
          </div>
          <md-button
            class="md-raised button-margins md-accent"
            v-on:click="convertPatient"
          >
            <md-icon>info</md-icon>
            <span v-if="!editedPatient.patientCode"> Hastaya Dönüştür </span>
            <span v-if="editedPatient.patientCode">Randevu Oluştur </span>
          </md-button>
        </div>
      </md-content>
    </md-dialog>

    <!-- rediract dialog for the single patient -->
    <md-dialog :md-active.sync="showShareModal" class="dialog allowOverflow">
      <md-content class="statuses p-5">
        <h3>Hastayı Paylaş</h3>
        <md-field>
          <v-select
            v-model="shareToUser"
            placeholder="Kullanıcılar"
            :options="users"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <v-select
            :clearable="false"
            v-model="selectedBranch"
            placeholder="Branş"
            :options="branches"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>
        <md-field>
          <v-select
            :clearable="false"
            v-model="selectedOperation"
            placeholder="İşlem"
            :options="operations"
            label="name"
            class="md-menu"
          >
            <template slot="option" slot-scope="option">{{
              option.name
            }}</template>
          </v-select>
        </md-field>

        <md-field>
          <md-textarea
            v-model="sharingNote"
            placeholder="Paylaşma Notu"
          ></md-textarea>
        </md-field>

        <!-- <md-checkbox v-model="sharedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        > -->
        <div class="md-layout text-right pb-5" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showShareModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="shareComplete"
            >Paylaş</md-button
          >
        </div>
      </md-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="searchDuplicatesModal"
      class="dialog allowOverflow"
    >
      <md-content class="statuses p-5">
        <h3>Tekrarlanan Kayıtlar</h3>

        <md-table
          class="table appointments"
          v-model="searchDuplaticesValues"
          @md-selected="patientSelected"
          style="max-height: 500px; margin-top: 25px"
        >
          <md-table-row
            slot="md-table-row"
            class="trow"
            slot-scope="{ item }"
            md-selectable="multiple"
            :style="{
              background: editedPatient.id == item.id ? '#1C90FF' : '',
              color: editedPatient.id == item.id ? 'white' : '',
            }"
            :md-disabled="!hasAccess() && !canSee(item)"
            :class="
              item.sharingType == 2 ? 'shared' : '' || isDuplicated(item.phone)
            "
          >
            <md-table-cell
              class="cell-sm"
              :class="{
                activePatient: editedPatient && editedPatient.id == item.id,
              }"
            >
              <md-button
                v-if="(hasAccess() || canSee(item)) && roleId != 5"
                class="md-icon-button md-raised md-dense"
                v-on:click="goPotantialDetail(item)"
                ><md-icon>account_circle</md-icon>
                <md-tooltip md-direction="top">Hastaya git</md-tooltip>
              </md-button>

              <div>
                <md-icon
                  style="color: #4834d4; margin-top: 5px"
                  v-if="item.sharingType == 2"
                  >share</md-icon
                >
                <md-tooltip md-direction="top">Paylaşılmış Hasta</md-tooltip>
              </div>
              <div @click="searchDuplicates(item.phone)">
                <md-icon
                  class="duplicateOfReal"
                  v-if="isDuplicated(item.phone) == 'duplicatedOfRealPatient'"
                  >r_mobiledata</md-icon
                >
                <md-tooltip md-direction="top">Randevulu Hasta</md-tooltip>
              </div>
            </md-table-cell>

            <md-table-cell md-label="Hasta Tipi" v-if="searchAllPatients">
              {{ item.type == 1 ? "Gerçek" : "Aday Hasta" }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Telefon"
            >
              <span
                :title="
                  isConflicted(item.phone)
                    ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                    : ''
                "
              >
                <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                  >✗</b
                >
                {{ hasAccessPhone(item.phone) }}
                <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                  >✗</b
                >
              </span>
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              v-if="innerWidth < 769"
              md-label="Dil"
            >
              {{ item.language ? item.language.name : "" }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Ülke"
            >
              {{ item.country ? item.country.name_tr : item.countryCode }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth < 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Kaynak"
            >
              <span>
                <div
                  style="
                    white-space: nowrap;
                    width: 30ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :title="item.source"
                >
                  {{ item.agencieId && item.agencie ? item.agencie.name : "" }}
                </div>
                <md-tooltip style="font-size: 13px">{{
                  item.source
                    ? item.source
                    : item.agencieId && item.agencie
                    ? item.agencie.name
                    : ""
                }}</md-tooltip>
              </span>
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Hasta Adı"
            >
              {{ widthBlock(hasAccessName(item)) }}
              <md-tooltip style="font-size: 13px">
                {{ hasAccessName(item) }}
              </md-tooltip>
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Tedavi"
            >
              {{ item.operation ? widthBlock(item.operation.name) : "" }}
            </md-table-cell>

            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Ülke"
            >
              {{
                item.country
                  ? widthBlock(item.country.name_tr)
                  : item.countryCode
              }}
            </md-table-cell>
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              v-if="innerWidth >= 769"
              md-label="Dil"
            >
              {{ item.language ? widthBlock(item.language.name) : "" }}
            </md-table-cell>
            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Telefon"
            >
              <span
                :title="
                  isConflicted(item.phone)
                    ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                    : ''
                "
              >
                <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                  >✗</b
                >
                {{ widthBlock(hasAccessPhone(item.phone)) }}
                <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                  >✗</b
                >
              </span>
            </md-table-cell>
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Durum"
            >
              {{
                item.status && item.patientStatus
                  ? widthBlock(item.patientStatus.name)
                  : ""
              }}
            </md-table-cell>
            <md-table-cell
              v-if="innerWidth >= 769"
              v-on:click.native="fastEditPatient(item)"
              md-label="Kaynak"
            >
              <span>
                <div
                  style="
                    white-space: nowrap;
                    width: 30ch;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :title="item.source"
                >
                  {{
                    item.agencieId && item.agencie
                      ? widthBlock(item.agencie.name)
                      : ""
                  }}
                </div>
                <md-tooltip style="font-size: 13px">{{
                  item.source
                    ? item.source
                    : item.agencieId && item.agencie
                    ? widthBlock(item.agencie.name)
                    : ""
                }}</md-tooltip>
              </span>
            </md-table-cell>
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="İletişim"
            >
              {{ item.sourceModel ? item.sourceModel.name : "" }}
            </md-table-cell>
            <!-- <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="İletişim"
              >
                <div>
                  {{ item.sourceModel ? item.sourceModel.name : "" }}
                </div>

              </md-table-cell> -->
            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Kayıt Tarihi"
              >{{ dateString(item.createdAt) }}</md-table-cell
            >
            <md-table-cell
              v-if="roleId != 5"
              v-on:click.native="fastEditPatient(item)"
              md-label="Not"
            >
              <span v-if="item.notes.length > 0">
                <div v-if="item.notes[item.notes.length - 1].description != ''">
                  <md-icon>library_books</md-icon>
                  <md-tooltip style="font-size: 13px">{{
                    item.notes[item.notes.length - 1].description
                  }}</md-tooltip>
                </div>
              </span>
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Kişi"
            >
              {{ item.user ? item.user.name : "" }}
            </md-table-cell>

            <md-table-cell
              v-on:click.native="fastEditPatient(item)"
              md-label="Son Güncelleme Tarihi"
              >{{ dateString(item.updatedAt) }}</md-table-cell
            >
            <md-table-cell class="pd-0">
              <div
                class="hover"
                v-if="
                  (hasAccess() || canSee(item) || roleId == 6) && roleId != 5
                "
              >
                <!-- <md-button
                   v-if="innerWidth >= 769"
                    class="md-icon-button md-raised md-dense"
                    v-on:click="goPotantialDetail(item)"
                    ><md-icon>account_circle</md-icon>
                    <md-tooltip md-direction="top">Hastaya git</md-tooltip>
                    </md-button> -->
                <!-- <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="shareTo(item.id)"
                    ><md-icon>share</md-icon>
                    <md-tooltip md-direction="top">Paylaş</md-tooltip>
                  </md-button> -->
                <md-button
                  class="md-icon-button md-raised md-dense"
                  v-on:click="directTo(item.id)"
                  ><md-icon>mobile_screen_share</md-icon>
                  <md-tooltip md-direction="top">Yönlendir</md-tooltip>
                </md-button>

                <!-- <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="sendEmail(item.id)"
                    ><md-icon>mail_outline</md-icon>
                    <md-tooltip md-direction="top">E-Mail At</md-tooltip>
                    </md-button> -->
                <md-button
                  class="md-icon-button md-raised md-dense"
                  v-if="isDuplicated(item.phone)"
                  v-on:click="searchDuplicates(item.phone)"
                  ><md-icon>groups</md-icon>
                  <md-tooltip md-direction="top"
                    >Tekrarlanan kayıtlar</md-tooltip
                  >
                </md-button>
                <!-- <md-button
                    class="md-icon-button md-raised md-dense md-accent"
                    v-if="roleId == 1 || canSee(item)"
                    v-on:click="deletePatient(item)"
                    ><md-icon>delete</md-icon>
                    <md-tooltip md-direction="top">Sil</md-tooltip>
                  </md-button> -->
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-content>
    </md-dialog>

    <!-- redirect model for multiple patients -->
    <md-dialog
      :md-active.sync="showAllDirectModal"
      class="dialog allowOverflow"
      style="overflow: none !important"
    >
      <md-content class="statuses">
        <h3>Hastayı Yönlendir</h3>
        <v-select
          v-model="directToUser"
          placeholder="Kullanıcılar"
          :options="users"
          label="name"
          class="md-menu"
        >
          <template slot="option" slot-scope="option">{{
            option.name
          }}</template>
        </v-select>
        <md-checkbox v-model="directedPatientsToBeRecall"
          >Tekrar Aranacak</md-checkbox
        >
        <div class="md-layout text-right" style="margin-top: 20px">
          <md-button
            class="md-raised md-default"
            v-on:click="showAllDirectModal = false"
            >İptal</md-button
          >
          <md-button class="md-raised md-primary" v-on:click="directCompleteAll"
            >Hepsini Yönlendir
          </md-button>
        </div>
      </md-content>
    </md-dialog>

    <!-- fast edit patient model -->
    <md-dialog
      :md-active.sync="fastEditPatientModal"
      class="dialog allowOverflow"
    >
      <md-content style="width: 100%" class="md-scrollbar">
        <div style="overflow-x: hidden">
          <h1
            v-if="editedPatient.name"
            class="editedPatientModalTopPatientName"
          >
            {{ editedPatient.name }}
            <span v-if="editedPatient.country">
              {{
                editedPatient.country.name_tr
                  ? " - " + editedPatient.country.name_tr
                  : editedPatient.country.name
                  ? " - " + editedPatient.country.name
                  : ""
              }}
            </span>
          </h1>
          <h3>Durum Hareketleri</h3>
          <div class="md-layout" v-if="editedPatient">
            <div
              style="width: 900px"
              class="md-layout-item md-size-500 notesContainer"
            >
              <notes
                :patientId="editedPatient.id"
                :patientStatuses="patientStatusFilterOptions"
                @newPatientStatus="newPatientStatus"
                @newPatientNotes="newPatientNotes"
              />
            </div>
          </div>
        </div>
      </md-content>
    </md-dialog>

    <convert-modal
      :showBecomePatientModal="showBecomePatientModal"
      :patient="editedPatient"
      @closeModal="closeConvertModal"
    ></convert-modal>
    <!-- Yönlendir -->
    <!-- Filtreler -->
    <!-- <md-dialog :md-active.sync="showDeleteFilter" class="dialog" v-if="false">
      <md-dialog-title>Filtre Sil</md-dialog-title>
      <md-content>
        <div
          class="md-layout bg-l"
          v-for="(f, index) in userCustomFilters"
          :key="index"
        >
          <div class="md-layout-item">{{ f.name }}</div>
          <div class="md-layout-item text-right">
            <md-button class="md-raised md-default" @click="deleteCF(f.id)"
              >SİL</md-button
            >
          </div>
        </div>
        <br />

        <div class="md-layout text-right">
          <md-button
            class="md-raised md-default"
            v-on:click="showDeleteFilter = false"
            >Kapat</md-button
          >
        </div>
      </md-content>
    </md-dialog> -->
    <!-- Filtreler -->
    <div class="md-layout md-gutter graph hidden-xs" v-if="false">
      <div class="md-layout-item md-size-20 md-xsmall-size-100">
        <div class="wrapper text-center b1" v-if="currenciesLoaded">
          AÇIK SATISLAR
          <span class="big">{{ openSales() }} TL</span>
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-50">
        <div class="wrapper text-center b2">
          BU AY KAZANILAN
          <span class="big">{{ monthEarned }} TL</span>
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-50">
        <div class="wrapper text-center b3">
          BU AY BEKLENEN
          <span class="big">{{ monthExpect }} TL</span>
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-50">
        <div class="wrapper text-center b4">
          SONRAKI AY
          <span class="big">{{ nextMonthExpect }} TL</span>
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-xsmall-size-50">
        <div class="wrapper text-center b5">
          KAYBEDİLEN
          <span class="big">{{ loseds }} TL</span>
        </div>
      </div>
    </div>
    <div class="md-layout potantialslist wrapper">
      <div class="md-layout" style="overflow-x: auto">
        <div class="md-layout-item md-size-50 quick-action md-xsmall-size-100">
          <button
            class="little-btn md-xsmall-size-50"
            @click="getQuick('all')"
            :class="{ active: quickSelect == 'all' }"
          >
            Tüm Zamanlar
          </button>
          <button
            class="little-btn"
            @click="getQuick('today')"
            :class="{ active: quickSelect == 'today' }"
          >
            Bugün
          </button>
          <button
            class="little-btn"
            @click="getQuick('week')"
            :class="{ active: quickSelect == 'week' }"
          >
            Bu Hafta
          </button>
          <button
            class="little-btn"
            @click="getQuick('month')"
            :class="{ active: quickSelect == 'month' }"
          >
            Bu Ay
          </button>
          <button
            class="little-btn"
            @click="getQuick('nextMonth')"
            :class="{ active: quickSelect == 'nextMonth' }"
          >
            Gelecek Ay
          </button>
          <button
            class="little-btn md-xsmall-size-50"
            @click="getQuick('last month')"
            :class="{ active: quickSelect == 'last month' }"
          >
            Önceki Ay
          </button>
          <button
            class="little-btn"
            @click="getQuick('special')"
            :class="{ active: quickSelect == 'special' }"
          >
            Özel
          </button>
          <button class="little-btn md-xsmall-hide" @click="changeDay('prev')">
            <md-icon>keyboard_arrow_left</md-icon>Önceki Gün
          </button>
          <button class="little-btn md-xsmall-hide" @click="changeDay('next')">
            Sonraki Gün
            <md-icon>keyboard_arrow_right</md-icon>
          </button>
        </div>
        <div class="md-layout-item md-size-20 md-xsmall-size-100">
          <md-datepicker
            v-model="startDate"
            md-immediately
            v-if="quickSelect == 'special'"
          >
            <label>Başlangıç Tarihi</label>
          </md-datepicker>
        </div>

        <div class="md-layout-item md-size-20 md-xsmall-size-100">
          <md-datepicker
            v-model="endDate"
            md-immediately
            v-if="quickSelect == 'special'"
          >
            <label>Bitiş Tarihi</label>
          </md-datepicker>
        </div>

        <div class="md-layout-item md-size-10 mt-3 md-xsmall-size-100">
          <md-button
            v-if="quickSelect == 'special'"
            class="md-raised md-primary btn btn-mg m-5"
            @click="getPool()"
            >Ara</md-button
          >
        </div>

        <div class="md-layout filterBtn">
          <button class="md-secondary md-raised" @click="filterToggle">
            <md-icon>filter_list</md-icon>
            <span class="filter-btn-text" v-if="filterShow">Hide</span>
            <span class="filter-btn-text" v-else>Show</span>
          </button>
        </div>
        <!-- <div class="md-layout-item  "> -->

        <!-- </div> -->
        <div class="md-layout-item md-size-100 list-top">
          <!-- the add and the direct button -->
          <md-speed-dial
            v-if="roleId != 5 && roleId != 6"
            class="md-top-right listFloatButtons"
            md-direction="bottom"
            md-event="click"
          >
            <div class="top-choices">
              <!--jsonExcel :data="patients" :fields="exportFields" name="Havuz Hastaları.xls" v-if="roleId == 1 || roleId == 6"-->
              <div class="top-choices-download-and-addpatient">
                <jsonExcel
                  :fetch="getPool2Export"
                  :fields="exportFields"
                  name="Havuz Hastaları.xls"
                  v-if="roleId == 1 || roleId == 6"
                >
                  <md-speed-dial-target class="hidden-mini">
                    <md-button class="md-icon-button">
                      <md-icon>download</md-icon>
                    </md-button>
                  </md-speed-dial-target>
                </jsonExcel>

                <router-link
                  :to="{
                    name: 'patients.create',
                  }"
                >
                  <md-speed-dial-target class="md-primary">
                    <md-button class="md-icon-button">
                      <md-icon>add</md-icon>
                    </md-button>
                  </md-speed-dial-target>
                </router-link>
              </div>

              <div
                style="margin-left: 10px"
                v-if="this.selectedPatients.length > 0"
              >
                <md-button
                  style="width: auto !important"
                  class="md-raised md-primary"
                  v-on:click="willBeCalled()"
                  ><md-icon>call</md-icon> Tekrar Aranacak</md-button
                >
              </div>
              <div
                style="margin-left: 10px"
                v-if="this.selectedPatients.length > 0"
              >
                <md-button
                  style="width: auto !important"
                  class="md-raised md-primary"
                  v-on:click="directAllTo()"
                  ><md-icon>mobile_screen_share</md-icon> Yönlendir</md-button
                >
              </div>
              <div
                style="margin-left: 10px"
                v-if="this.selectedPatients.length > 0"
              >
                <md-button
                  class="md-raised md-accent"
                  v-on:click="deleteSelectedPatients()"
                  ><md-icon>delete</md-icon> Sil</md-button
                >
              </div>
            </div>
          </md-speed-dial>

          <div class="md-layout-item md-size-100">
            <span class="md-subheading" v-if="quickSelect != 'all'"
              >{{ startDateText }}
              {{ quickSelect != "today" ? " - " + endDateText : "" }}</span
            >
          </div>

          <div
            id="filters"
            v-if="
              !selectingAgenciesChild && (innerWidth > 769 ? true : filterShow)
            "
            class="layout-1"
          >
            <NewInput
              v-if="
                roleId == 1 || roleId == 5 || roleId == 6 || leading.length > 0
              "
            >
              <v-select
                v-model="selectedUserFilter"
                placeholder="Personel"
                :options="filterUsers"
                label="name"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(selectedUserFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedTreatmentFilter"
                placeholder="Tedavi"
                :options="OperationFilterOptions"
                label="name"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(selectedTreatmentFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedCountryFilter"
                placeholder="Ülke"
                :options="countries"
                label="name_tr"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name_tr }}
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedAgencieFilter"
                placeholder="Kaynak"
                :options="childDontShow(agencies)"
                label="name"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(selectedAgencieFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedSourceFilter"
                placeholder="İletişim"
                :options="sources"
                label="name"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(selectedSourceFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedStatusFilter"
                placeholder="Durum"
                :options="patientStatusFilterOptions"
                label="name"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    numberIfSelected(selectedStatusFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <v-select
                v-model="selectedLanguageFilter"
                placeholder="Dil"
                :options="languages"
                label="name"
                class="md-menu"
                :close-on-select="false"
                multiple
              >
                <template slot="option" slot-scope="option"
                  >{{ option.name }}
                  <span class="optionSelected">{{
                    checkIfSelected(selectedLanguageFilter, option)
                  }}</span>
                </template>
              </v-select>
            </NewInput>
            <NewInput>
              <input
                placeholder="Advertising ID"
                style="border : 0px solid ; outline: 0px solid;padding-left : 10px;padding-right: 10px;"
                v-model="adIdFilter"
              />
            </NewInput>
            <!-- <div
                class="md-layout-item md-size-80 md-xsmall-size-80"
                v-if="roleId == 1"
                >
                <md-checkbox
                  style="margin-left: 10px !important"
                  v-model="historyChecked"
                  >Durum Hareketlerne Göre Filtrele</md-checkbox
                >
                <md-checkbox
                  style="margin-left: 10px !important"
                  v-if="historyChecked"
                  v-model="currentStatusChecked"
                  >Seçilen Son Durum Güncel Durum ile Aynı</md-checkbox
                >
                </div> -->
          </div>
          <div
            style="width: 100%"
            v-if="
              selectingAgenciesChild && (innerWidth >= 769 ? true : filterShow)
            "
          >
            <div style="display: flex; flex-wrap: wrap">
              <NewInput
                v-if="
                  roleId == 1 ||
                    roleId == 5 ||
                    roleId == 6 ||
                    leading.length > 0
                "
              >
                <v-select
                  v-model="selectedUserFilter"
                  placeholder="Personel"
                  :options="filterUsers"
                  label="name"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                    <span class="optionSelected">{{
                      checkIfSelected(selectedUserFilter, option)
                    }}</span>
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedTreatmentFilter"
                  placeholder="Tedavi"
                  :options="OperationFilterOptions"
                  label="name"
                  class="md-menu"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                    <span class="optionSelected">{{
                      checkIfSelected(selectedTreatmentFilter, option)
                    }}</span>
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedSourceFilter"
                  placeholder="İletişim"
                  :options="sources"
                  label="name"
                  class="md-menu"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedCountryFilter"
                  placeholder="Ülke"
                  :options="countries"
                  label="name_tr"
                  class="md-menu"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name_tr }}
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedSourceFilter"
                  placeholder="İletişim"
                  :options="sources"
                  label="name"
                  class="md-menu"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                    <span class="optionSelected">{{
                      checkIfSelected(selectedSourceFilter, option)
                    }}</span>
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedStatusFilter"
                  placeholder="Durum"
                  :options="patientStatusFilterOptions"
                  label="name"
                  class="md-menu"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                    <span class="optionSelected">{{
                      numberIfSelected(selectedStatusFilter, option)
                    }}</span>
                  </template>
                </v-select>
              </NewInput>
              <NewInput>
                <v-select
                  v-model="selectedLanguageFilter"
                  placeholder="Dil"
                  :options="languages"
                  label="name"
                  class="md-menu"
                  :close-on-select="false"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                    <span class="optionSelected">{{
                      checkIfSelected(selectedLanguageFilter, option)
                    }}</span>
                  </template>
                </v-select>
              </NewInput>
            </div>
            <div style="width: 100%; display: flex; align-items: center">
              <div
                style="
                  width: 100%;
                  height: 1px;
                  background-color: gray;
                  margin-bottom: 20px;
                  margin-top: 15px;
                  opacity: 0.4;
                  color: gray;
                "
              ></div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
              <NewInput>
                <v-select
                  v-model="selectedAgencieFilter"
                  placeholder="Kaynak"
                  :options="childDontShow(agencies)"
                  label="name"
                  class="md-menu"
                  multiple
                >
                  <template slot="option" slot-scope="option"
                    >{{ option.name }}
                  </template>
                </v-select>
              </NewInput>
              <div :key="index" v-for="(item, index) in selectedAgencieFilter">
                <NewInput width="250" v-if="item.childAgencies.length > 0">
                  <v-select
                    v-model="selectedAgencieChildFilter[index]"
                    :placeholder="item.name + ' Alt Kaynak'"
                    :options="childIsDeletedControl(item.childAgencies)"
                    label="name"
                    class="md-menu"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </NewInput>
              </div>
            </div>
          </div>
          <!-- <div class="md-layout" v-if="false">
            <div class="md-layout-item md-size-20 md-xsmall-size-80">
              <v-select
                v-model="selectedFilter"
                placeholder="Tüm Satışlarım"
                :options="userCustomFilters"
                label="name"
                class="md-menu"
              >
                <template slot="option" slot-scope="option">{{
                  option.name
                }}</template>
                <template #list-footer>
                  <div class="addNewFileType" style="text-align: center">
                    <md-button type="button" @click="customFilterModal = true"
                      >Yeni Filtre</md-button
                    >
                    <md-button type="button" @click="showDeleteFilter = true"
                      >Filtre Sil</md-button
                    >
                  </div>
                </template>
              </v-select>
            </div>
          </div> -->
          <div class="md-layout" v-if="roleId != 5">
            <div class="md-layout-item md-size-30 md-xsmall-size-80">
              <md-field>
                <label>Ad / Telefon No</label>
                <md-input v-model="search_query"></md-input>
                <md-icon>search</md-icon>
              </md-field>
            </div>
            <div
              style="display: flex; align-items: flex-end"
              class="md-layout-item md-size-20 md-xsmall-size-80"
            >
              <md-checkbox
                style="margin-left: 10px !important"
                v-model="searchAllPatients"
                >Tüm Hastalarda Ara</md-checkbox
              >
            </div>
            <div
              style="display: flex; align-items: center"
              class="md-layout-item md-size-20 md-xsmall-size-80"
            >
              <md-button
                style="border: 2px solid grey; border-radius: 5px"
                @click="clearFilters"
              >
                Temizle
              </md-button>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-100 hidden-mini">
          <span
            v-if="patients.length > 0"
            style="float: right; margin-right: 30px; font-weight: bold"
            >Toplam hasta sayısı: {{ patientsCount }}</span
          >
        </div>

        <div class="md-layout-item md-size-100" v-if="recallCount > 0">
          <md-tabs>
            <template slot="md-tab" slot-scope="{ tab }">
              {{ tab.label }}
              <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i>
            </template>
            <md-tab
              id="tab-all"
              md-label="Hepsi"
              @click="tabType = 'all'"
            ></md-tab>
            <md-tab
              id="tab-recall"
              md-label="Tekrar Aranacak"
              @click="tabType = 'recall'"
              :md-template-data="{ badge: recallCount }"
            ></md-tab>
          </md-tabs>
        </div>

        <SkeletonTableLoading v-if="tableLoading" />

        <div class="md-layout-item md-size-100">
          <md-table
            class="table appointments"
            v-model="patients"
            @md-selected="patientSelected"
          >
            <md-table-row
              slot="md-table-row"
              class="trow"
              slot-scope="{ item }"
              md-selectable="multiple"
              :style="{
                background: editedPatient.id == item.id ? '#1C90FF' : '',
                color: editedPatient.id == item.id ? 'white' : '',
              }"
              :md-disabled="!hasAccess() && !canSee(item)"
              :class="
                item.sharingType == 2
                  ? 'shared'
                  : '' || isDuplicated(item.phone)
              "
            >
              <md-table-cell
                class="cell-sm"
                :class="{
                  activePatient: editedPatient && editedPatient.id == item.id,
                }"
              >
                <md-button
                  style="margin-right: 20px"
                  v-if="(hasAccess() || canSee(item)) && roleId != 5"
                  class="md-icon-button md-raised md-dense"
                  v-on:click="goPotantialDetail(item)"
                  ><md-icon>account_circle</md-icon>
                  <md-tooltip md-direction="top">Hastaya git</md-tooltip>
                </md-button>
                <md-button
                  v-if="(hasAccess() || canSee(item)) && roleId != 5"
                  class="md-icon-button md-raised md-dense"
                  v-on:click="patientInformationPress(item)"
                  ><md-icon>info</md-icon>
                  <md-tooltip md-direction="top">Hasta Bilgileri</md-tooltip>
                </md-button>

                <div>
                  <md-icon
                    style="color: #4834d4; margin-top: 5px"
                    v-if="item.sharingType == 2"
                    >share</md-icon
                  >
                  <md-tooltip md-direction="top">Paylaşılmış Hasta</md-tooltip>
                </div>
                <div @click="searchDuplicates(item.phone)">
                  <md-icon
                    class="duplicateOfReal"
                    v-if="isDuplicated(item.phone) == 'duplicatedOfRealPatient'"
                    >r_mobiledata</md-icon
                  >
                  <md-tooltip md-direction="top">Randevulu Hasta</md-tooltip>
                </div>
              </md-table-cell>

              <md-table-cell md-label="Hasta Tipi" v-if="searchAllPatients">
                {{ item.type == 1 ? "Gerçek" : "Aday Hasta" }}
              </md-table-cell>

              <md-table-cell
                v-if="innerWidth <= 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Telefon"
              >
                <span
                  v-if="item.phone"
                  :title="
                    isConflicted(item.phone)
                      ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                      : ''
                  "
                >
                  <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                    >✗</b
                  >
                  {{ hasAccessPhone(item.phone) }}
                  <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                    >✗</b
                  >
                </span>
              </md-table-cell>

              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                v-if="innerWidth < 769"
                md-label="Dil"
              >
                {{ item.language ? item.language.name : "" }}
              </md-table-cell>

              <md-table-cell
                v-if="innerWidth < 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Ülke"
              >
                {{ item.country ? item.country.name_tr : item.countryCode }}
              </md-table-cell>

              <md-table-cell
                v-if="innerWidth < 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Kaynak"
              >
                <span>
                  <div
                    v-if="item.agencieId"
                    style="
                      white-space: nowrap;
                      width: 30ch;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :title="item.source"
                  >
                    {{
                      item.agencieId && item.agencie ? item.agencie.name : ""
                    }}
                  </div>
                  <md-tooltip style="font-size: 13px">{{
                    item.source
                      ? item.source
                      : item.agencieId && item.agencie
                      ? item.agencie.name
                      : ""
                  }}</md-tooltip>
                </span>
              </md-table-cell>

              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Hasta Adı"
              >
                {{ item.name ? widthBlock(hasAccessName(item)) : "" }}
                <md-tooltip style="font-size: 13px">
                  {{ hasAccessName(item) }}
                </md-tooltip>
              </md-table-cell>

              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Tedavi"
              >
                {{ item.operation ? widthBlock(item.operation.name) : "" }}
              </md-table-cell>

              <md-table-cell
                v-if="innerWidth >= 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Ülke"
              >
                {{
                  item.country
                    ? widthBlock(item.country.name_tr)
                    : item.countryCode
                }}
              </md-table-cell>
              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                v-if="innerWidth >= 769"
                md-label="Dil"
              >
                {{ item.language ? widthBlock(item.language.name) : "" }}
              </md-table-cell>
              <md-table-cell
                v-if="innerWidth >= 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Telefon"
              >
                <span
                  v-if="item.phone"
                  :title="
                    isConflicted(item.phone)
                      ? 'Farklı Satış Temsilcilerde tekrarlanmış!'
                      : ''
                  "
                >
                  <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                    >✗</b
                  >
                  {{ widthBlock(hasAccessPhone(item.phone)) }}
                  <b v-if="isConflicted(item.phone) && item.sharingType != 2"
                    >✗</b
                  >
                </span>
              </md-table-cell>
              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Durum"
              >
                {{
                  item.status && item.patientStatus
                    ? widthBlock(item.patientStatus.name)
                    : ""
                }}
              </md-table-cell>
              <md-table-cell
                v-if="innerWidth >= 769"
                v-on:click.native="fastEditPatient(item)"
                md-label="Kaynak"
              >
                <span v-if="item.agencieId">
                  <div
                    style="
                      white-space: nowrap;
                      width: 30ch;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :title="item.source"
                  >
                    {{
                      item.agencieId && item.agencie
                        ? widthBlock(item.agencie.name)
                        : ""
                    }}
                  </div>
                  <md-tooltip style="font-size: 13px">{{
                    item.source
                      ? item.source
                      : item.agencieId && item.agencie
                      ? widthBlock(item.agencie.name)
                      : ""
                  }}</md-tooltip>
                </span>
              </md-table-cell>

              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="İletişim"
              >
                {{ item.sourceModel ? item.sourceModel.name : "" }}
              </md-table-cell>
              <!-- <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="İletişim"
              >
                <div>
                  {{ item.sourceModel ? item.sourceModel.name : "" }}
                </div>

              </md-table-cell> -->
              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Kayıt Tarihi"
                >{{ dateString(item.createdAt) }}</md-table-cell
              >
              <md-table-cell
                v-if="roleId != 5"
                v-on:click.native="fastEditPatient(item)"
                md-label="Not"
              >
                <span v-if="item.notes.length > 0">
                  <div
                    v-if="item.notes[item.notes.length - 1].description != ''"
                  >
                    <md-icon>library_books</md-icon>
                    <md-tooltip style="font-size: 13px">{{
                      item.notes[item.notes.length - 1].description
                    }}</md-tooltip>
                  </div>
                </span>
              </md-table-cell>

              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Kişi"
              >
                {{ item.user ? item.user.name : "" }}
              </md-table-cell>

              <md-table-cell md-label="Adversiting Id">
                {{ item.adId }}
              </md-table-cell>
              <md-table-cell
                v-on:click.native="fastEditPatient(item)"
                md-label="Son Güncelleme Tarihi"
                >{{ dateString(item.updatedAt) }}</md-table-cell
              >
              <md-table-cell class="pd-0">
                <div
                  class="hover"
                  v-if="
                    (hasAccess() || canSee(item || roleId == 6)) && roleId != 5
                  "
                >
                  <!-- <md-button
                   v-if="innerWidth >= 769"
                    class="md-icon-button md-raised md-dense"
                    v-on:click="goPotantialDetail(item)"
                    ><md-icon>account_circle</md-icon>
                    <md-tooltip md-direction="top">Hastaya git</md-tooltip>
                    </md-button> -->
                  <!-- <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="shareTo(item.id)"
                    ><md-icon>share</md-icon>
                    <md-tooltip md-direction="top">Paylaş</md-tooltip>
                  </md-button> -->
                  <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="directTo(item.id)"
                    ><md-icon>mobile_screen_share</md-icon>
                    <md-tooltip md-direction="top">Yönlendir</md-tooltip>
                  </md-button>

                  <!-- <md-button
                    class="md-icon-button md-raised md-dense"
                    v-on:click="sendEmail(item.id)"
                    ><md-icon>mail_outline</md-icon>
                    <md-tooltip md-direction="top">E-Mail At</md-tooltip>
                    </md-button> -->
                  <md-button
                    class="md-icon-button md-raised md-dense"
                    v-if="isDuplicated(item.phone)"
                    v-on:click="searchDuplicates(item.phone)"
                    ><md-icon>groups</md-icon>
                    <md-tooltip md-direction="top"
                      >Tekrarlanan kayıtlar</md-tooltip
                    >
                  </md-button>
                  <!-- <md-button
                    class="md-icon-button md-raised md-dense md-accent"
                    v-if="roleId == 1 || canSee(item)"
                    v-on:click="deletePatient(item)"
                    ><md-icon>delete</md-icon>
                    <md-tooltip md-direction="top">Sil</md-tooltip>
                  </md-button> -->
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div class="md-layout-item md-size-100">
          <MazPagination
            v-model="paginationCurrentPage"
            v-if="paginationPageCount > 1"
            :page-count="paginationPageCount"
            :dark="false"
            @page="paginationChanged"
          />
          <span v-if="patients.length > 0"
            >Toplam hasta sayısı: {{ patientsCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import axios from "axios";
import { MazPagination } from "maz-ui";
import notes from "@/components/patients/notes";
import moment from "moment";
import jsonExcel from "vue-json-excel";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ConvertModal from "../../../../components/patients/potantials/convertModal.vue";
import NewInput from "../../../../components/newInput";
import SkeletonTableLoading from "../../../../components/SkeletonTableLoading.vue";

export default {
  name: "PotantialList",
  data() {
    return {
      sourceError: false,
      isEditable: false,
      noteStatus: false,
      title: "Hastalar",
      profile: [],
      patients: [],
      search_query: "",
      filters: [],
      selectedFilter: null,
      selectedPatientId: null,
      showDirectModal: false,
      showShareModal: false,
      directedPatientsToBeRecall: false,
      users: [],
      filterUsers: [],
      directToUser: null,
      shareToUser: null,
      currencies: {
        EUR: null,
        GBP: null,
        USD: null,
      },
      monthEarned: 0,
      monthExpect: 0,
      nextMonthExpect: 0,
      loseds: 0,
      customFilterModal: false,
      currenciesLoaded: false,
      isAdmin: localStorage.getItem("roleId") == 1,
      isDietician: localStorage.getItem("roleId") == 9,
      roleId: localStorage.getItem("roleId"),
      userId: localStorage.getItem("userId"),
      leading: JSON.parse(localStorage.getItem("leading")),
      userCustomFilters: [],
      quickSelect: "all",
      startDate: new Date(),
      endDate: new Date(),
      showDeleteFilter: false,
      operations: [],
      operations2: [],
      firms: [],
      agencies: [],
      patientStatus: [],
      countries: [],
      languages: [],
      sources: [],
      whens: [],
      patientOwners: [],
      nf_country: null,
      nf_firms: null,
      nf_agencie: null,
      nf_patientStatus: null,
      nf_user: null,
      nf_when: null,
      nf_operation: null,
      nf_name: null,
      patientStatusFilterOptions: [],
      OperationFilterOptions: [],
      selectedUserFilter: null,
      selectedAgencieFilter: null,
      selectedCountryFilter: null,
      selectedLanguageFilter: null,
      selectedTreatmentFilter: null,
      selectedStatusFilter: null,
      selectedPatients: [],
      selectedSourceFilter: null,
      adIdFilter: null,
      showAllDirectModal: false,
      showAllShareModal: false,
      searching: false,
      lastSearchField: "",
      paginationCurrentPage: 1,
      paginationPageCount: 1,
      paginationPageSize: 100,
      patientsCount: 0,
      duplicatedPhones: [],
      conflictedPhones: [],

      sharedPatientCode: [],
      shareBranches: [],
      branches: [],
      shareOperations: [],
      selectedOperation: [],
      selectedOperations: [],
      selectedBranch: [],
      sharingNote: [],
      // possibleConflicedPhones: [],
      isGettingDuplicates: false,
      editedPatient: {
        sharingHistory: null,
        patientCode: null,
        operation: null,
      },
      fastEditPatientModal: false,
      patientSource: null,
      patSource: null,
      patientCountries: [],
      filterShow: false,
      isLoading: false,
      exportFields: {
        "Hasta Adı": "name",
        Tedavi: {
          field: "operation",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Ülke: {
          field: "country",
          callback: (value) => {
            return value ? value.name_tr : "countryCode";
          },
        },
        Telefon: "phone",
        Kaynak: {
          filed: "agencie",
          callback: (value) => {
            return "agencieId" && value ? value.name : "";
          },
        },
        Kişi: {
          field: "user",
          callback: (value) => {
            return value ? value.name : "";
          },
        },
        Durum: {
          field: "patientStatus",
          callback: (value) => {
            return "status" && value ? value.name : "";
          },
        },
        "Kayıt Tarihi": {
          field: "createdAt",
          callback: (value) => {
            return value ? this.dateString(value) : "";
          },
        },
        "Son Güncelleme Tarihi": {
          field: "updatedAt",
          callback: (value) => {
            return value ? this.dateString(value) : "";
          },
        },
      },
      statusChanged: false,
      statusNote: "",
      error: false,
      statusHistory: [],
      statusCurrent: null,
      historyChecked: false,
      currentStatusChecked: false,
      showBecomePatientModal: false,
      searchAllPatients: false,
      tabType: "all",
      recallCount: 0,
      searchDuplicatesModal: false,
      searchDuplaticesValues: null,
      tableScrollPosition: 0,
      selectingAgenciesChild: false,
      selectedAgencieChildFilter: [[]],
      patientInformationModal: false,
      tableLoading: true,
    };
  },
  components: {
    MazPagination,
    notes,
    jsonExcel,
    Loading,
    ConvertModal,
    NewInput,
    SkeletonTableLoading,
  },
  async created() {
    axios.get("https://intesasoft.com/api/currency/").then((res) => {
      this.currencies.EUR = res.data.EUR;
      this.currencies.USD = res.data.USD;
      this.currencies.GBP = res.data.GBP;
      this.currenciesLoaded = true;
    });

    var agenciesLimitation = "";
    if (this.roleId != 1) agenciesLimitation = "?userId=" + this.userId;
    await HTTP.get("/agencies/" + agenciesLimitation).then((result) => {
      this.agencies = result.data;
      this.sharingAgencies = result.data;
    });
    var filtredUserAgencie = [];
    this.agencies.forEach(function(item) {
      filtredUserAgencie.push(item.id);
    });
    // var vm = this.filterUsers;
    // await HTTP.get("/agencies?agenciesUsers="+filtredUserAgencie).then((result) => {
    //   result.data.map((z) => z.agencieUsers.map(function(item){
    //     if(!vm.includes(item.id) )vm.push(item.id)
    //   }))
    //   this.filterUsersId = vm;
    // });
    this.getPool();
    HTTP.get("/users/").then((result) => {
      this.users = result.data;
      if (["1", "6"].includes(this.roleId))
        this.filterUsers = this.users.filter((z) => true);
      else
        this.filterUsers = this.users.filter(
          (z) => this.leading.includes(z.id) || z.id == this.userId
        );
    });

    this.getCustomFilters();
    HTTP.get("/operations/").then((result) => {
      this.operations = result.data.filter((item) => item.status == true);
      this.OperationFilterOptions = result.data.filter(
        (item) => item.status == true
      );
      this.OperationFilterOptions.reverse();
      this.OperationFilterOptions.push({ id: 0, name: "Yok" });
      this.OperationFilterOptions.reverse();
      this.operations2 = result.data.filter((item) => item.status == true);
    });
    HTTP.get("/firms/").then((result) => {
      this.firms = result.data;
    });

    HTTP.get("/patientstatus/").then((result) => {
      this.patientStatus = result.data;
      this.patientStatusFilterOptions = result.data.filter((z) => true);
      this.patientStatusFilterOptions.reverse();
      this.patientStatusFilterOptions.push({ id: 0, name: "Yok" });
      this.patientStatusFilterOptions.reverse();
    });
    HTTP.get("/whens/").then((result) => {
      this.whens = result.data;
    });
    HTTP.get("/countries").then((result) => {
      this.countries = result.data;
    });
    HTTP.get("/languages").then((result) => {
      this.languages = result.data;
    });
    HTTP.get("/sources").then((result) => {
      this.sources = result.data;
    });
    HTTP.get("/users/").then((result) => {
      this.patientOwners = result.data.filter(
        (user) => user.roleId != 2 && user.status == true
      );
    });

    this.getDuplicates();
    await this.getBranches();
  },
  methods: {
    hasAccessSourceLink: function() {
      if (isAdmin) {
        return true;
      }
    },
    checkIfSelected: function(model, val) {
      return model && model.find((z) => z.id == val.id) ? "✓" : "";
    },
    numberIfSelected: function(model, val) {
      if (!this.historyChecked)
        return model && model.find((z) => z.id == val.id) ? "✓" : "";
      else {
        let idx = model ? model.findIndex((z) => z.id == val.id) : -1;
        return idx == -1 ? "" : idx + 1;
      }
    },
    filterToggle: function() {
      this.filterShow = !this.filterShow;
    },
    isDuplicated: function(phone) {
      if (phone == "") return false;
      let duplicatedRecord = this.duplicatedPhones.filter(
        (z) => z.phone == phone
      );
      if (duplicatedRecord.length > 0) {
        return duplicatedRecord[0].isRealPatient == 1
          ? "duplicatedOfRealPatient"
          : "duplicated";
      } else return false;
    },
    isConflicted: function(phone) {
      if (phone == "") return false;
      let conflictedRecord = this.conflictedPhones.filter(
        (z) => z.phone == phone
      );
      if (conflictedRecord.length > 0) return true;
      else return false;
    },
    isShared: function(patientCode) {
      if (patientCode == "") return false;
      let sharedRecord = this.sharedPatientCode.filter(
        (z) => z.patientCode == patientCode
      );
      if (sharedRecord.length > 0) {
        return sharedRecord.sharingType == 2 ? "shared" : "";
      } else return false;
    },
    searchDuplicates: function(phone) {
      //this.isGettingDuplicates = true;
      //this.searchAllPatients = true;
      //this.search_query = phone.replace("+", "");

      HTTP.get(
        "/patients/?&includeUser=true&potantials=all&q=" +
          phone.replace("+", "")
      ).then((result) => {
        this.searchDuplicatesModal = true;
        this.searchDuplaticesValues = result.data;
      });
    },
    getDuplicates: function() {
      HTTP.get("/patients/getDuplicates").then((result) => {
        this.duplicatedPhones = result.data;
      });
      HTTP.get("/patients/getConflicts").then((result) => {
        this.conflictedPhones = result.data;
      });
      HTTP.get("/patients/getShared").then((result) => {
        this.sharedPatientCode = result.data;
      });
    },
    hasAccessName: function(patient) {
      var name = patient.name ? patient.name : "";
      if (["5", "6", "8"].includes(this.roleId)) {
        return this.hiddenName(name);
      } else if (this.hasAccess() || this.canSee(patient)) {
        return name;
      } else {
        return "*********";
      }
    },
    hasAccess: function() {
      let roleId = localStorage.getItem("roleId");
      if (roleId == 1) {
        return true;
      }
      return false;
    },
    canSee: function(patient) {
      return (
        patient.userId == this.userId ||
        patient.secondUserId == this.userId ||
        this.leading.includes(patient.userId) ||
        this.leading.includes(patient.secondUserId)
      );
    },
    hiddenName: function(name) {
      if (!name) return "";
      var names = name.split(" ");
      if (names.length == 0) return "";
      else {
        var hiddenName = names[0] + " ";
        names.splice(1, 1).forEach(function(item) {
          hiddenName += item.substr(0, 1) + "*** ";
        });
        return hiddenName;
      }
    },
    hasAccessPhone: function(phone) {
      if (!["5", "6", "8"].includes(this.roleId)) return phone;
      if (!phone) return "";
      if (phone.length == 0) return "";
      if (this.roleId == 6) {
        var hiddenPhone =
          phone.substr(0, 4) +
          "****" +
          phone.substr(phone.length - 4, phone.length);
        return hiddenPhone;
      } else {
        var hiddenPhone =
          "******" + phone.substr(phone.length - 4, phone.length);
        return hiddenPhone;
      }
    },
    getPool: async function(resetPagination = true) {
      this.tableLoading = true;

      this.patients = [];

      this.paginationPageCount = 1;
      if (resetPagination) this.paginationCurrentPage = 1;
      let offset = this.paginationPageSize * (this.paginationCurrentPage - 1);
      let uri =
        "/patients/?limit=" +
        this.paginationPageSize +
        "&offset=" +
        offset +
        "&includeUser=true&potantials=true";

      if (this.quickSelect != "all") {
        let startText =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1) +
          "-" +
          ("0" + this.startDate.getDate()).slice(-2);

        let endText =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1) +
          "-" +
          ("0" + this.endDate.getDate()).slice(-2);
        uri += "&startDate=" + startText + "&endDate=" + endText;
      }

      if (this.roleId != 1 && this.roleId != 6 && !this.selectedUserFilter) {
        uri += "&user=" + this.userId + "," + this.leading.join();
      }

      if (this.selectedFilter) {
        uri =
          "/patients/?potantials=true&includeUser=true&" +
          this.selectedFilter.query;
      }

      if (this.selectedUserFilter) {
        var users = this.selectedUserFilter.map((z) => z.id);
        uri += "&user=" + users;
      }

      if (this.adIdFilter) {
        uri += "&adId=" + this.adIdFilter;
      }

      if (this.selectedAgencieChildFilter[0][0]?.id) {
        let ids = [];
        for (let i in this.selectedAgencieChildFilter) {
          for (let j in this.selectedAgencieChildFilter[i]) {
            ids.push(this.selectedAgencieChildFilter[i][j].id);
          }
        }
        uri += "&agencie=" + ids;
      } else {
        if (
          this.selectedAgencieFilter &&
          this.selectedAgencieFilter.length > 0
        ) {
          var agencies = this.selectedAgencieFilter.map((z) => z.id);
          uri += "&agencie=" + agencies;
        } else if (this.roleId == 5 || this.roleId == 6) {
          var agencies = this.agencies.map((z) => z.id);
          uri += "&agencie=0," + agencies;
        }
      }

      if (this.selectedCountryFilter) {
        var countries = this.selectedCountryFilter.map((z) => z.code);
        uri += "&country=" + countries;
      }

      if (this.selectedLanguageFilter) {
        var languages = this.selectedLanguageFilter.map((z) => z.id);
        uri += "&language=" + languages;
      }

      if (this.selectedSourceFilter) {
        var sources = this.selectedSourceFilter.map((z) => z.id);
        uri += "&source=" + sources;
      }

      if (this.selectedTreatmentFilter) {
        var operations = this.selectedTreatmentFilter.map((z) => z.id);
        uri += "&operation=" + operations;
      }

      if (this.tabType == "recall") {
        uri += "&patientstatus=30";
      } else if (this.selectedStatusFilter) {
        var patientstatuses = this.selectedStatusFilter.map((z) => z.id);
        uri += "&patientstatus=" + patientstatuses;
        if (this.historyChecked) uri += "&statushistory=true";
        if (this.currentStatusChecked) uri += "&currentchecked=true";
      }

      await HTTP.get(uri).then((result) => {
        this.patients = result.data.rows;
        this.patientsCount = result.data.count;
        this.paginationPageCount = Math.ceil(
          result.data.count / this.paginationPageSize
        );
      });

      this.tableLoading = false;
    },
    async getPool2Export() {
      this.isLoading = true;
      let patients2export = [];
      let uri = "/patients/?includeUser=true&potantials=true";

      if (this.quickSelect != "all") {
        let startText =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1) +
          "-" +
          ("0" + this.startDate.getDate()).slice(-2);

        let endText =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1) +
          "-" +
          ("0" + this.endDate.getDate()).slice(-2);
        uri += "&startDate=" + startText + "&endDate=" + endText;
      }

      if (this.roleId != 1 && this.roleId != 5 && this.roleId != 6) {
        uri += "&user=" + this.userId + "," + this.leading.join();
      }

      if (this.selectedFilter) {
        uri =
          "/patients/?potantials=true&includeUser=true&" +
          this.selectedFilter.query;
      }

      if (this.selectedUserFilter) {
        var users = this.selectedUserFilter.map((z) => z.id);
        uri += "&user=" + users;
      }

      if (this.selectedAgencieFilter && this.selectedAgencieFilter.length > 0) {
        var agencies = this.selectedAgencieFilter.map((z) => z.id);
        uri += "&agencie=" + agencies;
      } else if (this.roleId == 5 || this.roleId == 6) {
        var agencies = this.agencies.map((z) => z.id);
        uri += "&agencie=0," + agencies;
      }

      if (this.selectedCountryFilter) {
        var countries = this.selectedCountryFilter.map((z) => z.code);
        uri += "&country=" + countries;
      }

      if (this.selectedLanguageFilter) {
        var languages = this.selectedLanguageFilter.map((z) => z.id);
        uri += "&language=" + languages;
      }

      if (this.selectedSourceFilter) {
        var sources = this.selectedSourceFilter.map((z) => z.id);
        uri += "&source=" + sources;
      }

      if (this.selectedTreatmentFilter) {
        var operations = this.selectedTreatmentFilter.map((z) => z.id);
        uri += "&operation=" + operations;
      }

      if (this.selectedStatusFilter) {
        var patientstatuses = this.selectedStatusFilter.map((z) => z.id);
        uri += "&patientstatus=" + patientstatuses;
        if (this.historyChecked) uri += "&statushistory=true";
        if (this.currentStatusChecked) uri += "&currentchecked=true";
      }

      await HTTP.get(uri).then((result) => {
        patients2export = result.data;
      });
      this.isLoading = false;
      return patients2export;
    },
    searchPatient: async function(q) {
      let userId = this.userId;
      this.paginationPageCount = 1;
      let uri = "/patients/?limit=100&includeUser=true";
      uri += "&potantials=" + (this.searchAllPatients ? "all" : "true");
      if (!this.hasAccess() && !this.isGettingDuplicates) {
        uri += "&user=" + userId + "," + this.leading.join();
      }
      this.isGettingDuplicates = false;

      if (q != this.lastSearchField) return;
      if (q == "") {
        this.getPool();
      }
      this.searching = true;
      HTTP.get(uri + "&q=" + q).then((patients) => {
        this.patients = patients.data;
        this.searching = false;
        if (q != this.lastSearchField) this.searchPatient(this.lastSearchField);
      });
    },
    addFilter: function() {
      //

      let uri = "";

      if (this.nf_country) {
        uri += "&country=" + this.nf_country;
      }

      if (this.nf_firms) {
        uri += "&firm=" + this.nf_firms.id;
      }

      if (this.nf_agencie) {
        uri += "&agencie=" + this.nf_agencie.id;
      }
      if (this.nf_patientStatus) {
        uri += "&patientstatus=" + this.nf_patientStatus.id;
      }

      if (this.nf_user) {
        uri += "&user=" + this.nf_user.id;
      }

      if (this.nf_when) {
        uri += "&when=" + this.nf_when.name;
      }

      if (this.nf_operation) {
        uri += "&operation=" + this.nf_operation.id;
      }
      uri = uri.substr(1);

      this.customFilterModal = false;

      let data = {
        query: uri,
        name: this.nf_name,
      };

      HTTP.post("/customfilters/", data).then((result) => {
        this.getCustomFilters();
      });
    },
    getCustomFilters: function() {
      HTTP.get("/customfilters/").then((result) => {
        this.userCustomFilters = result.data;
        // this.users = result.data.filter(user => user.role.name != "Doctor");
      });
    },
    dateString: function(date) {
      let start = new Date(date);
      let startText =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getFullYear() +
        " - " +
        start.getHours() +
        ":" +
        ("0" + start.getMinutes()).slice(-2);

      return startText;
    },
    sendEmail: function(id) {
      router.push({
        name: "potantial.patients.mails",
        params: { id: id },
      });
    },
    directTo: function(id) {
      this.selectedPatientId = id;
      this.showDirectModal = true;
    },
    directAllTo: function() {
      this.showAllDirectModal = true;
    },
    directCompleteAll: function() {
      for (let index = 0; index < this.selectedPatients.length; index++) {
        const pat = this.selectedPatients[index];

        let data = {
          userId: this.directToUser.id,
          directedPatientsToBeRecall: this.directedPatientsToBeRecall,
        };
        HTTP.patch("patients/" + pat.id, data).then(() => {
          this.directToUser = null;
          // this.selectedPatientId = null;
          this.showAllDirectModal = false;
        });
      }
      this.selectedPatients = [];
      this.getPool();
      this.getDuplicates();
      //
    },
    directComplete: function() {
      let data = {
        userId: this.directToUser.id,
        directedPatientsToBeRecall: this.directedPatientsToBeRecall,
      };
      HTTP.patch("patients/" + this.selectedPatientId, data).then(() => {
        //
        this.directToUser = null;
        this.selectedPatientId = null;
        this.showDirectModal = false;
        this.getPool();
        this.getDuplicates();
      });
    },
    shareTo: function(id) {
      this.selectedPatientId = id;
      this.showShareModal = true;
    },
    shareComplete: function() {
      let data = {};
      data.sharedUserId = this.shareToUser.id;
      data.sharingNote = this.sharingNote;
      data.selectedOperation = this.selectedOperation.id;

      HTTP.patch("patients/share/" + this.selectedPatientId, data).then(() => {
        this.sharedToUser = null;
        this.selectedPatientId = null;
        this.$swal("", "Başarıyla Paylaşıldı", "success");
        this.$emit("updated");
        this.showSharedModal = false;
        this.getPool();
        this.getDuplicates();
      });
    },
    getBranches: function() {
      let userId = localStorage.getItem("userId");
      let branches = [];
      HTTP.get("/branches/").then((result) => {
        result.data.forEach(function(branch) {
          if (branch.branchUsers.map((x) => x.id).includes(parseInt(userId)))
            branches.push(branch);
        });

        this.branches = branches;

        this.selectedBranch = this.branches ? this.branches[0] : null;
      });
    },
    getOperations: function() {
      HTTP.get("/operations/").then((result) => {
        this.operations = result.data.filter(
          (operation) =>
            operation.status == true &&
            operation.branchId == this.selectedBranch.id
        );
        this.operations2 = result.data.filter((item) => item.status == true);
      });
    },
    willBeCalled: function() {
      this.$swal({
        title: "Tekrar Aranacak",
        text:
          "Seçilen hastaları 'Tekrar Aranacak' olarak işaretlemek ister misiniz?",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Kaydet",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          for (let index = 0; index < this.selectedPatients.length; index++) {
            var pat = this.selectedPatients[index];

            pat.status = 30; // will be called again status id = 30

            HTTP.put("patients/" + pat.id, pat).then(() => {
              this.patients.find((z) => z.id == pat.id).status = 30;
              this.patients.find(
                (z) => z.id == pat.id
              ).patientStatus = this.patientStatus.find((z) => z.id == 30);
            });
          }
          this.selectedPatients = [];
          this.getRecallCount();
        }
      });
    },
    goPotantialDetail: function(item) {
      if (!this.hasAccess() && !this.canSee(item)) return;
      let savedFilters = {
        search_query: this.search_query,
        selectedUserFilter: this.selectedUserFilter,
        selectedAgencieFilter: this.selectedAgencieFilter,
        selectedCountryFilter: this.selectedCountryFilter,
        selectedLanguageFilter: this.selectedLanguageFilter,
        selectedTreatmentFilter: this.selectedTreatmentFilter,
        selectedStatusFilter: this.selectedStatusFilter,
        quickSelect: this.quickSelect,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      localStorage.setItem("savedFilters", JSON.stringify(savedFilters));

      router.push({
        name: "potantial.patients",
        params: {
          id: item.id,
        },
      });
    },
    openSales: function() {
      let total = 0;
      this.patients.forEach((patient) => {
        if (patient.offers && patient.offers.length > 0) {
          if (patient.offers[patient.offers.length - 1].status != 4) {
            switch (patient.offers[patient.offers.length - 1].currencySlug) {
              case "USD":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.USD
                );

                break;
              case "GBP":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.GBP
                );
                break;
              case "EUR":
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice *
                    this.currencies.EUR
                );
                break;

              default:
                total += parseFloat(
                  patient.offers[patient.offers.length - 1].totalPrice
                );
                break;
            }
          }
        }
      });

      if (total) {
        return total.toFixed(2);
      } else {
        return 0;
      }
    },
    thisMonthTotal: function() {
      var total = 0;
      HTTP.get("/appointments/totals/monthearned").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.monthEarned = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    thisMonthExpect: function() {
      var total = 0;
      HTTP.get("/appointments/totals/monthexpect").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.monthExpect = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    nextMonthExpectFunc: function() {
      var total = 0;
      HTTP.get("/appointments/totals/nextmonthexpect").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.currencySlug) {
              case "USD":
                total += parseFloat(o.totalPrice) * this.currencies.USD;
                break;
              case "GBP":
                total += parseFloat(o.totalPrice) * this.currencies.GBP;
                break;
              case "EUR":
                total += parseFloat(o.totalPrice) * this.currencies.EUR;
                break;
              default:
                total += parseFloat(o.totalPrice);
                break;
            }
          });
          this.nextMonthExpect = total.toFixed(2);
        }
      });
      return total.toFixed(2);
    },
    getLoseds: function() {
      var total = 0;
      HTTP.get("/appointments/totals/loseds").then((result) => {
        if (result.data.length > 0) {
          result.data.forEach((o) => {
            switch (o.offers[o.offers.length - 1].currencySlug) {
              case "USD":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.USD
                );
                break;
              case "GBP":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.GBP
                );
                break;
              case "EUR":
                total += parseFloat(
                  o.offers[o.offers.length - 1].totalPrice * this.currencies.EUR
                );
                break;
              default:
                total += parseFloat(o.offers[o.offers.length - 1].totalPrice);
                break;
            }
          });
          this.loseds = total.toFixed(2);
        }
      });
      // return total;
    },
    deleteCF: function(id) {
      HTTP.delete("/customfilters?ids=" + id).then(() => {
        this.getCustomFilters();
      });
    },
    getQuick: function(val) {
      this.quickSelect = val;
      // this.getPool();
      var current = new Date(); // get current date

      switch (this.quickSelect) {
        case "all":
          this.startDate = null;
          this.endDate = null;
          break;
        case "today":
          this.startDate = current;
          this.endDate = current;
          break;
        case "week":
          const [weekStart, weekEnd] = this.getThisWeekDates();

          this.startDate = weekStart;
          this.endDate = weekEnd;
          break;
        case "month":
          var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
          var lastDay = new Date(
            current.getFullYear(),
            current.getMonth() + 1,
            0
          );
          this.startDate = firstDay;
          this.endDate = lastDay;
          break;
        case "last month":
          var firstDay = new Date(
            current.getFullYear(),
            current.getMonth() - 1,
            1
          );
          var lastDay = new Date(current.getFullYear(), current.getMonth(), 0);
          this.startDate = firstDay;
          this.endDate = lastDay;
          break;
      }
      if (this.quickSelect != "special") {
        this.getPool();
      }
    },
    changeDay: function(option) {
      this.quickSelect = "today";
      var activeDate = new Date(this.startDate);
      let val = option == "next" ? 1 : -1;
      var result = new Date(activeDate.getTime() + val * 86400000);

      this.startDate = result;
      this.endDate = result;

      this.getPool();
    },
    paginationChanged: function() {
      this.getPool(false);
    },
    patientSelected: function(items) {
      this.selectedPatients = items;
    },
    deletePatient: function(patient) {
      this.$swal({
        title: "Emin misiniz?",
        text:
          patient.name +
          " adlı ve " +
          patient.phone +
          " telefon nolu hastanın kaydı silinecektir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff5252",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed)
          HTTP.delete("/patients?ids=" + patient.id).then(() => {
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getDuplicates();
            this.selectedPatients = this.selectedPatients.filter(
              (p) => p.id !== patient.id
            );
            if (this.search_query != "")
              this.searchPatient(this.lastSearchField);
            else this.getPool();
          });
      });
    },
    deleteSelectedPatients: function() {
      if (this.selectedPatients.length > 0) {
        this.$swal({
          title: "Emin misiniz?",
          text: this.selectedPatients.length + " hasta kaydı silinecektir.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff5252",
          confirmButtonText: "Sil",
          cancelButtonText: "İptal",
        }).then((result) => {
          if (result.isConfirmed) {
            var patients = this.selectedPatients.map((p) => p.id);
            HTTP.delete("/patients?ids=" + patients).then(() => {
              this.$swal({
                icon: "success",
                text: "İşlem Başarıyla tamamlandı.",
                showConfirmButton: false,
                timer: 1500,
              });
              this.selectedPatients = [];
              this.getDuplicates();
              if (this.search_query != "")
                this.searchPatient(this.lastSearchField);
              else this.getPool();
            });
          }
        });
      }
    },
    fastEditPatient: function(item) {
      if (this.roleId == 5) return;
      if (!this.hasAccess() && !this.canSee(item)) return;
      this.editedPatient = item;
      this.statusCurrent = this.editedPatient.status;
      this.patSource = this.sources.find((z) => z.name == item.source);
      if (this.patSource) {
        this.patientSource = {
          id: this.patSource.id,
          name: this.patSource.name,
        };
      }
      this.statusHistory = this.editedPatient.statusHistory
        ? JSON.parse(this.editedPatient.statusHistory)
        : [];
      this.fastEditPatientModal = true;
    },
    newPatientStatus: function(newStatus) {
      //

      let findPatient = this.patients.findIndex(
        (x) => x.id === this.editedPatient.id
      );
      this.patients[findPatient].patientStatus = newStatus;
      this.patients[findPatient].status = newStatus;

      this.editedPatient.patientStatus = newStatus;
    },
    newPatientNotes: function(newNotes) {
      //

      this.editedPatient?.notes?.push(newNotes?.data);
      this.fastEditPatientModal = false;
    },
    saveEditedPatient: async function() {
      // if (this.$refs.patientNotes.newNote.status != null || this.$refs.patientNotes.newNote.description) {
      //   await this.$refs.patientNotes.createNote();
      //
      //   if (!this.$refs.patientNotes.noteStatus) {
      //
      //     return;
      //   }
      // }
      //
      // İletişim zorunlulugu
      // if (!this.editedPatient.sourceModel) {
      //   this.sourceError = true;
      //   //
      //   return;
      // }
      this.editedPatient.sourceId = this.editedPatient.sourceModel
        ? this.editedPatient.sourceModel.id
        : null;
      this.editedPatient.treatmentId = this.editedPatient.operation
        ? this.editedPatient.operation
        : null;
      this.editedPatient.status = this.editedPatient.patientStatus
        ? this.editedPatient.patientStatus.id
        : null;
      this.editedPatient.agencieId = this.editedPatient.agencie
        ? this.editedPatient.agencie.id
        : null;
      this.editedPatient.countryCode = this.editedPatient.country
        ? this.editedPatient.country.code
        : null;
      this.editedPatient.languageId = this.editedPatient.language
        ? this.editedPatient.language.id
        : null;

      // if (this.statusCurrent != this.editedPatient.status) {
      //   let statusHistory = this.editedPatient.statusHistory;
      //   if (statusHistory == "" || statusHistory == null) statusHistory = [];
      //   else statusHistory = JSON.parse(statusHistory);
      //   statusHistory.push({
      //     oldStatus: this.statusCurrent,
      //     newStatus: this.editedPatient.status,
      //     changedBy: this.userId,
      //     changedAt: new Date(),
      //     note: this.statusNote,
      //   });
      //   this.editedPatient.statusHistory = JSON.stringify(statusHistory);
      // }

      HTTP.put("/patients/" + this.editedPatient.id, this.editedPatient).then(
        (result) => {
          if (result.status == 204) {
            var patientIndex = this.patients.findIndex(
              (item) => item.id == this.editedPatient.id
            );
            this.editedPatient.treatmentId = this.editedPatient.operation
              ? this.editedPatient.operation.id
              : null;
            this.patients[patientIndex] = this.editedPatient;
            // this.editedPatient = null;
            // this.fastEditPatientModal = false;
            this.$swal({
              icon: "success",
              text: "İşlem Başarıyla tamamlandı.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.sourceError = false;
            this.statusChanged = false;
            this.statusNote = "";
            this.getRecallCount();
            this.isEditable = false;
          }
        }
      );
    },
    cancelEditPatient: function() {
      // this.editedPatient = null;
      this.fastEditPatientModal = false;
      this.isEditable = false;
      this.statusNote = "";
      this.statusChanged = false;
    },
    convertPatient: function() {
      // if(!this.editedPatient.sourceModel){
      //   this.sourceError = true;
      // }
      if (!this.editedPatient.operation) {
        this.error = true;
      }
      if (!this.sourceError && !this.error) {
        this.error = false;
        this.sourceError = false;
        this.cancelEditPatient();
        this.showBecomePatientModal = true;
      }
    },
    clearFilters: function() {
      this.selectedUserFilter = null;
      this.selectedAgencieFilter = null;
      this.selectedCountryFilter = null;
      this.selectedLanguageFilter = null;
      this.selectedTreatmentFilter = null;
      this.selectedStatusFilter = null;
      this.historyChecked = false;
      this.currentStatusChecked = false;
      this.search_query = null;
      this.searchAllPatients = false;
      this.selectedSourceFilter = null;
      this.getPool();
    },
    changeStatus: function(selVal) {
      this.statusChanged = this.statusCurrent != selVal.id ? true : false;
    },
    closeConvertModal() {
      this.showBecomePatientModal = false;
    },
    searchQuery: function() {
      let cQuery = this.search_query.trim().replace("+", "");

      this.lastSearchField = cQuery;
      if (!this.searching) this.searchPatient(cQuery);
    },
    getRecallCount() {
      let uri =
        "/patients/?limit=1&offset=0&includeUser=true&potantials=true&&patientstatus=30";
      if (this.roleId != 1) {
        uri += "&user=" + this.userId + "," + this.leading.join();
      }
      HTTP.get(uri).then((result) => {
        this.recallCount = result.data.count;
        if (this.recallCount == 0) this.tabType = "all";
      });
    },
    getUserName(userId) {
      var user = this.users.find((z) => z.id == userId);
      return user ? user.name : "";
    },
    getStatusName(statusID) {
      var status = this.patientStatus.find((z) => z.id == statusID);
      return status ? status.name : "";
    },
    formatDate(date, showTime = false) {
      return showTime
        ? moment(date).format("DD-MM-YYYY HH:mm")
        : moment(date).format("DD-MM-YYYY");
    },
    widthBlock(value) {
      if (value.length > 17) {
        return value.substring(0, 18) + "...";
      } else {
        return value;
      }
    },
    patientInfosSave() {
      this.editedPatient.sourceId = this.editedPatient.sourceModel
        ? this.editedPatient.sourceModel.id
        : null;
      this.editedPatient.treatmentId = this.editedPatient.operation
        ? this.editedPatient.operation
        : null;
      this.editedPatient.status = this.editedPatient.patientStatus
        ? this.editedPatient.patientStatus.id
        : null;
      this.editedPatient.agencieId = this.editedPatient.agencie
        ? this.editedPatient.agencie.id
        : null;
      this.editedPatient.countryCode = this.editedPatient.country
        ? this.editedPatient.country.code
        : null;
      this.editedPatient.languageId = this.editedPatient.language
        ? this.editedPatient.language.id
        : null;

      HTTP.put("/patients/" + this.editedPatient.id, this.editedPatient).then(
        (result) => {
          if (result.status == 204) {
            var patientIndex = this.patients.findIndex(
              (item) => item.id == this.editedPatient.id
            );
            this.editedPatient.treatmentId = this.editedPatient.operation
              ? this.editedPatient.operation.id
              : null;
            this.patients[patientIndex] = this.editedPatient;
            // this.editedPatient = null;
            // this.fastEditPatientModal = false;

            this.sourceError = false;
            this.statusChanged = false;
            this.statusNote = "";
            this.getRecallCount();
            this.isEditable = false;
            this.patientInformationModal = false;
            this.$swal("", "Hasta bilgileri güncellendi.", "success");
          }
        }
      );
    },
    patientInformationPress(item) {
      if (this.roleId == 5) return;
      if (!this.hasAccess() && !this.canSee(item)) return;
      this.editedPatient = item;
      this.statusCurrent = this.editedPatient.status;
      this.patSource = this.sources.find((z) => z.name == item.source);
      if (this.patSource) {
        this.patientSource = {
          id: this.patSource.id,
          name: this.patSource.name,
        };
      }
      this.statusHistory = this.editedPatient.statusHistory
        ? JSON.parse(this.editedPatient.statusHistory)
        : [];

      this.patientInformationModal = true;
    },
    patientInformationSaveButtonClick() {
      this.patientInfosSave();
    },
    childIsDeletedControl(val) {
      return val.filter((item) => item.isDeleted == 0 && item);
    },
    childDontShow(val) {
      return val.filter(
        (item) => item.parentId == null && item.isDeleted == false && item
      );
    },
    getThisWeekDates() {
      const today = new Date();
      const currentDay = today.getDay(); // 0: Pazar, 1: Pazartesi, ... 6: Cumartesi
      const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // bu haftanın başlangıç gününün tarihteki indeksi
      const weekStart = new Date(today.setDate(diff)); // bu haftanın başlangıç tarihi
      const weekEnd = new Date(today.setDate(weekStart.getDate() + 6)); // bu haftanın bitiş tarihi

      return [weekStart, weekEnd];
    },
  },
  mounted: function() {
    // setTimeout(() => {
    //   this.thisMonthTotal();
    //   this.thisMonthExpect();
    //   this.nextMonthExpectFunc();
    //   this.getLoseds();
    // }, 500);

    if (this.$route.params.savedFilters == "savedFilters") {
      let savedFilters = localStorage.getItem("savedFilters");
      if (savedFilters != "") {
        savedFilters = JSON.parse(savedFilters);
        this.quickSelect = savedFilters.quickSelect;
        this.startDate = new Date(savedFilters.startDate);
        this.endDate = new Date(savedFilters.endDate);
        this.search_query = savedFilters.search_query;
        this.selectedUserFilter = savedFilters.selectedUserFilter;
        this.selectedAgencieFilter = savedFilters.selectedAgencieFilter;
        this.selectedCountryFilter = savedFilters.selectedCountryFilter;
        this.selectedLanguageFilter = savedFilters.selectedLanguageFilter;
        this.selectedTreatmentFilter = savedFilters.selectedTreatmentFilter;
        this.selectedStatusFilter = savedFilters.selectedStatusFilter;
      }
    } else {
      localStorage.setItem("savedFilters", "");
    }
    this.getRecallCount();
  },
  computed: {
    innerWidth: function() {
      return window.innerWidth;
    },
    startDateText: function() {
      let startText =
        ("0" + this.startDate.getDate()).slice(-2) +
        "-" +
        (this.startDate.getMonth() + 1) +
        "-" +
        this.startDate.getFullYear();

      var weekday = new Array(7);
      weekday[0] = "Pazar";
      weekday[1] = "Pazartesi";
      weekday[2] = "Salı";
      weekday[3] = "Çarşamba";
      weekday[4] = "Perşembe";
      weekday[5] = "Cuma";
      weekday[6] = "Cumartesi";

      var n = weekday[this.startDate.getDay()];
      if (this.quickSelect == "today") {
        startText += " " + n;
      }
      return startText;
    },
    endDateText: function() {
      let endText =
        ("0" + this.endDate.getDate()).slice(-2) +
        "-" +
        (this.endDate.getMonth() + 1) +
        "-" +
        this.endDate.getFullYear();
      return endText;
    },
  },
  watch: {
    fastEditPatientModal: function() {
      if (!this.fastEditPatientModal) {
        this.isEditable = false;
        this.error = false;
        this.sourceError = false;
        document.title = "Klinik Yönetim Sistemi";
      } else {
        document.title = this.editedPatient.name + " düzenleniyor...";
      }
    },
    searchAllPatients: function() {
      this.searchQuery();
    },
    selectedBranch: function() {
      this.getOperations();
      this.selectedOperation = null;
      this.participants = this.doctors.filter(
        (z) =>
          z.branches.map((z) => z.id).includes(this.selectedBranch.id) &&
          this.userDoctors.includes(z.id)
      );
      this.appointment.participants = [];
      this.appointment.locationId = null;
      this.appointmentDateChanged();
      this.getLocations();
    },
    search_query: function() {
      this.searchQuery();
    },
    selectedFilter: function() {
      this.getPool();
    },
    selectedUserFilter: function() {
      this.getPool();
    },
    selectedAgencieFilter: function(val) {
      let control = [];
      val.filter((item) => item.childAgencies.length > 0 && control.push(true));

      if (JSON.stringify(control).match(true)) {
        this.selectingAgenciesChild = true;
      } else {
        this.selectingAgenciesChild = false;
      }

      this.getPool();
    },
    selectedAgencieChildFilter(val) {
      this.getPool();
    },
    selectedCountryFilter: function() {
      this.getPool();
    },
    selectedLanguageFilter: function() {
      this.getPool();
    },
    selectedTreatmentFilter: function() {
      this.getPool();
    },
    selectedStatusFilter: function() {
      this.getPool();
    },
    selectedSourceFilter: function() {
      this.getPool();
    },
    adIdFilter: async function(val) {
      this.tableLoading = true;
      if (val?.length > 5) {
        await this.getPool();
      } else if (val?.length == 0) {
        await this.getPool();
      }
      this.tableLoading = false;
    },
    historyChecked: function() {
      this.selectedStatusFilter = null;
      this.getPool();
    },
    currentStatusChecked: function() {
      this.getPool();
    },
    tabType: function() {
      this.clearFilters();
      this.getRecallCount();
      this.getPool();
    },
  },
};
</script>

<style scoped>
.editButton {
  width: 35px !important;
  height: 35px !important;
  position: absolute !important;
  right: 50px !important;
  top: 35px !important;
}
.top-choices-download-and-addpatient {
  flex-direction: row;
  display: flex;
  margin-top: 40px;
  width: 90px;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-white-border {
  border: 5px solid transparent;
}

.editedPatientModalTopPatientName {
  font-weight: 100;
  font-family: sans-serif;
  font-size: 25px;
}
</style>

<style lang="scss">
@import "@/assets/scss/colors.scss";
.patientlist {
  margin-top: 7px !important;
  margin: -15px;
  .md-layout-item {
    min-width: 31.1%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    background: $white;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.03);
    }
  }
}

@media (max-width: 767.98px) {
  .patientlist {
    padding: 15px;
  }
  .potantialslist {
    > .md-layout {
      overflow: hidden;
    }
    .quick-action {
      display: block !important;
      .little-btn {
        margin: 10px;
      }
    }
  }
  .little-btn {
    margin: 10px;
  }
  .hover {
    button {
      opacity: 1 !important;
    }
  }
}
.potantialslist .wrapper {
  width: 100%;
}
.graph {
  .wrapper {
    border-bottom: 4px solid;
  }
}
.text-center {
  text-align: center;
  span {
    display: block;
  }
  .big {
    font-size: 19px;
    font-weight: bold;
    margin: 5px auto;
  }
}
.b1 {
  border-color: #fecf2f !important;
}
.b2 {
  border-color: #6cdad4 !important;
}
.b3 {
  border-color: #fd8824 !important;
}
.b4 {
  border-color: #a467fc !important;
}
.b5 {
  border-color: #fa1a1b !important;
}
.list-top {
  padding: 15px;
}
.trow {
  position: relative;
  &:hover {
    .hover {
      button {
        opacity: 1;
      }
    }
  }
}
.hover {
  display: flex;
  // position: absolute;
  button {
    opacity: 0;
    // &:first-child {
    margin-right: 3px !important;
    // }
  }
}
.text-right {
  text-align: right;
  justify-content: flex-end;
}
.select-date {
  margin-top: -15px;
}
.md-menu {
  li {
    position: relative;
    &:hover {
      .nf-delete {
        opacity: 1 !important;
      }
    }
  }
}
.nf-delete {
  position: absolute;
  top: 2px;
  right: 10px;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 99999999 !important;
  .md-icon {
    font-size: 18px !important;
    color: #fff !important;
  }
}
.bg-l {
  background: rgba(0, 0, 0, 0.05);
  margin: 5px 0px;
  padding: 10px;
}
.pd-0 {
  .md-table-cell-container {
    padding: 0 !important;
  }
}
.allowOverflow .md-dialog-container {
  overflow: visible !important;
}
.top-choices {
  display: flex;
  flex-direction: row;
}
.width-200 {
  width: 200px;
}
.duplicated {
  // background-color: #fdd;
  color: red;
}
.shared {
  // background-color: #fdd;
  color: #4834d4;
}
.duplicatedOfRealPatient {
  color: red;
}
.pagination-number-btn {
  background-color: var(--maz-bg-color);
  margin-right: 10px;
  color: var(--maz-text-color);
}
.pagination-arrow-btn {
  background-color: var(--maz-bg-color);
  margin-right: 10px;
  color: var(--maz-text-color);
}
#filters.show {
  display: block !important;
}
.show-xs {
  display: none;
}
@media (max-width: 768px) {
  .show-xs {
    display: inline-block;
  }
  .hidden-mini {
    display: none !important;
  }
}
.notesContainer {
  background-color: #eee;
  padding: 10px 20px;
  margin-top: 10px;
}
.cell-sm {
  .md-table-cell-container {
    padding: 0 10px !important;
    display: flex;
    .duplicateOfReal {
      font-size: 46px !important;
      display: block;
      color: red !important;
      margin-left: 5px;
    }
  }
}
.activePatient {
  button {
    background-color: green !important;
    i {
      color: #fff !important;
    }
  }
}
.listFloatButtons {
  margin-top: 50px !important;
}
.badge {
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 2px;
  background: red;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.05em;
  font-family: "Roboto Mono", monospace;
}
// .md-field:after, .md-field:before {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: 1;
//   transition: border .3s cubic-bezier(.4,0,.2,1),opacity .3s cubic-bezier(.4,0,.2,1),transform 0s cubic-bezier(.4,0,.2,1) .3s;
//   will-change: border,opacity,transform;
//   content: " ";
//   }
.md-layout-item {
  padding: 0.3rem;
}

.md-table-cell-container {
  padding: 0px !important;
}
.md-table-head-label {
  padding-left: 0px !important;
}

.layout-1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>
