<template>
    <div>
      <div class="sub-nav hidden-xs" >
        <router-link
          :to="{
            name: 'users.profile.general',
            params: { id: this.$route.params.id },
          }"
        >Genel Bakış</router-link>
        <router-link
        v-if="roleId == 1"
          :to="{
            name: 'users.profile.locations',
            params: { id: this.$route.params.id },
          }"
        >Konumlar</router-link>
        <router-link
        v-if="roleId == 1"
          :to="{
            name: 'users.profile.agencies',
            params: { id: this.$route.params.id },
          }"
        >Kaynaklar</router-link>
        <router-link
        v-if="roleId == 1 && profile.roleId != 2"
          :to="{
            name: 'users.profile.team',
            params: { id: this.$route.params.id },
          }"
        >Takım</router-link>
        <router-link
          v-if="roleId == 1"
          :to="{
            name: 'users.profile.branchesDoctors',
            params: { id: this.$route.params.id },
          }"
        >Branşlar - Doktorlar</router-link>
        <router-link
        v-if="(roleId == 1 || roleId == 2) && profile.roleId == 2"
          :to="{
            name: 'users.profile.closedDays',
            params: { id: this.$route.params.id },
          }"
        >Kapalı Günler</router-link>
        <!-- <router-link
        v-if="roleId == 1"
          :to="{
            name: 'users.profile.allSources',
            params: { id: this.$route.params.id },
          }"
        >İletişim Kaynakları</router-link> -->
      </div>
      <md-menu md-direction="bottom-start" v-if="innerWidth < 769" >
      <md-button md-menu-trigger>
        <md-icon>menu</md-icon>
        {{ $route.meta.name }}
      </md-button>

      <md-menu-content>
        <!-- <md-menu-item>My Item 1</md-menu-item> -->
        <md-menu-item>
          <router-link
          :to="{
            name: 'users.profile.general',
            params: { id: this.$route.params.id },
          }"
        >Genel Bakış</router-link>
        </md-menu-item>
        <md-menu-item   v-if="roleId == 1">
          <router-link
          :to="{
            name: 'users.profile.locations',
            params: { id: this.$route.params.id },
          }"
        >Konumlar</router-link>
        </md-menu-item>

        <md-menu-item   v-if="roleId == 1">
          <router-link
            :to="{
              name: 'users.profile.agencies',
              params: { id: this.$route.params.id },
            }"
          >Kaynaklar</router-link>
        </md-menu-item>

        <md-menu-item v-if="roleId == 1 && profile.roleId != 2">
          <router-link
            :to="{
              name: 'users.profile.team',
              params: { id: this.$route.params.id },
            }"
          >Takım</router-link>
        </md-menu-item>

        <md-menu-item  v-if="roleId == 1">
          <router-link
          :to="{
            name: 'users.profile.branchesDoctors',
            params: { id: this.$route.params.id },
          }"
        >Branşlar - Doktorlar</router-link>
        </md-menu-item>

        <md-menu-item    v-if="(roleId == 1 || roleId == 2) && profile.roleId == 2">
          <router-link
          :to="{
            name: 'users.profile.closedDays',
            params: { id: this.$route.params.id },
          }"
        >Kapalı Günler</router-link>
        </md-menu-item>


      </md-menu-content>
    </md-menu>

    </div>
  </template>
  <script>
    
    import { HTTP } from "../../main-source";
  
  
  export default {
    name: "Navigation",
    data() {
      return {
        id : this.$route.params.id,
        roleId : localStorage.getItem('roleId'),
        profile : []
      }
    },
    computed : {
      innerWidth: function() {
      return window.innerWidth;
      }
    },
    created() {
      
      HTTP.get("/users/" + this.id + "/getUserRoleId")
      .then(result => {
          this.profile = result.data[0]
        });
        
      
    },
  };
  </script>
  <style lang="scss">
  @import "@/assets/scss/colors.scss";
  .sub-nav {
    a,
    button {
      background: $white;
      border-left: 1px solid lighten($dark, 80%);
      border-top: 1px solid lighten($dark, 80%);
      border-bottom: 1px solid lighten($dark, 80%);
      padding: 8px 30px;
      color: $dark !important;
      text-decoration: none !important;
      &:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-right: 1px solid lighten($dark, 80%);
      }
    }
    .router-link-exact-active,
    .active {
      color: $brandcolor !important;
      font-weight: bold;
    }
  }
  .setLocation.md-top-right {
    top: unset !important;
    margin-top: -30px !important;
    button.md-fab {
      background: #ccc !important;
    }
  }
  </style>