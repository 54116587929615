<template>
  <div class="wrapper">
    <span class="md-title">Havaalanı</span>
    <md-button class="md-fab md-mini md-primary editButton" v-on:click="createModal = true">
      <md-icon>add</md-icon>
    </md-button>
    <md-input v-model="search" type="text" class="search-input" placeholder="Arayınız" />
    <md-table class="table appointments" v-model="allAirports">

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Ad">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Kod">{{ item.code }}</md-table-cell>
        <md-table-cell md-label="Ülke">{{ item.country }}</md-table-cell>
        <md-table-cell md-label="Şehir">{{ item.city }}</md-table-cell>
        <md-table-cell md-label="Eyalet">{{ item.state }}</md-table-cell>
        <md-table-cell md-label="Düzenle">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="updateAirport(item)">edit</i>
        </md-table-cell>
        <md-table-cell md-label="Sil">
          <i class="md-icon md-icon-font md-theme-default" v-on:click="deleteAirportBefore(item)">delete</i>
        </md-table-cell>
        <md-table-cell md-label="Aktif">
          <md-switch @change="itemIsActiveChange(item)" style="margin-left:15px;top:4px" v-model="item.isActive"
            class="md-primary"></md-switch>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="createModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field :class="isValidControl(newAirportItem.name)">
          <label>Havaalanı Adı *</label>
          <md-input v-model="newAirportItem.name"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirportItem.code)">
          <label>Havaalanı Kodu *</label>
          <md-input v-model="newAirportItem.code"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı Lat</label>
          <md-input v-model="newAirportItem.lat"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı Long</label>
          <md-input v-model="newAirportItem.long"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirportItem.country)">
          <label>Havaalanı Ülke *</label>
          <md-input v-model="newAirportItem.country"></md-input>
        </md-field>
        <md-field :class="isValidControl(newAirportItem.city)">
          <label>Havaalanı Şehir *</label>
          <md-input v-model="newAirportItem.city"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı İlçe</label>
          <md-input v-model="newAirportItem.state"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="createAirport">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog :md-active.sync="updateModal" class="dialog">
      <md-content class="statuses">
        <h3>Güncelle</h3>
        <md-field :class="isValidControl(updateAirportItem.name)">
          <label>Havaalanı Adı *</label>
          <md-input v-model="updateAirportItem.name"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirportItem.code)">
          <label>Havaalanı Kodu *</label>
          <md-input v-model="updateAirportItem.code"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı Lat</label>
          <md-input v-model="updateAirportItem.lat"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı Long</label>
          <md-input v-model="updateAirportItem.long"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirportItem.country)">
          <label>Havaalanı Ülke *</label>
          <md-input v-model="updateAirportItem.country"></md-input>
        </md-field>
        <md-field :class="isValidControl(updateAirportItem.city)">
          <label>Havaalanı Şehir *</label>
          <md-input v-model="updateAirportItem.city"></md-input>
        </md-field>
        <md-field>
          <label>Havaalanı İlçe</label>
          <md-input v-model="updateAirportItem.state"></md-input>
        </md-field>
        <md-button class="md-raised md-primary updateStatus" v-on:click="updateAirportDone">Güncelle</md-button>
      </md-content>
    </md-dialog>

    <md-dialog-confirm :md-active.sync="deleteModal" md-title="Airportu silmek istediğinize emin misiniz?"
      md-content="Bu işlem geri alınamaz." md-confirm-text="Sil" md-cancel-text="İptal Et"
      @md-confirm="deleteAirportAfter()" />
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Airports",
  data() {
    return {
      title: "Airport",
      isValid: {
        name: null,
        code: null,
        country: null,
        city: null
      },
      search: "",
      allAirports: [],
      createModal: false,
      updateModal: false,
      deleteModal: false,
      newAirportItem: {},
      updateAirportItem: {},
      deletedItem: {},
    };
  },
  created() {
    this.getAllAirports();
  },
  methods: {
    validationControl(validationSchema, item) {
      let validationControl = []
      validationSchema = Object.keys(validationSchema)
      for (let i in validationSchema) {
        item[validationSchema[i]]?.length > 0 ? validationControl.push(true) : validationControl.push(false)
      }

      return validationControl.includes(false) ? false : true
    },
    isValidControl(value) {
      return value?.length > 0 ? "" : "error-input"
    },
    getAllAirports() {
      HTTP.get("/airports?all=true&").then((response) => {
        this.allAirports = response.data;
      });
    },
    itemIsActiveChange(item) {
      HTTP.put("/airports/update", item).then((result) => {
        const response = result?.data
        this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
      })
    },
    createAirport() {
      if (this.validationControl(this.isValid, this.newAirportItem)) {
        HTTP.post("/airports", { ...this.newAirportItem, isActive: true }).then((result) => {
          const response = result?.data
          if (response?.isSuccess) {
            let newArr = []
            newArr.push(response?.data)
            for (let i in this.allAirports) {
              newArr.push(this.allAirports[i])
            }
            this.allAirports = newArr
          }
          this.createModal = false
          this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
        })
      } else {
        this.$swal("", "Lütfen zorunlu alanları doldurunuz.", "error");
      }
    },
    updateAirport(item) {
      this.updateAirportItem = item
      this.updateModal = true
    },
    updateAirportDone() {
      if (this.validationControl(this.isValid, this.updateAirportItem)) {
        HTTP.put("/airports/update", this.updateAirportItem).then((result) => {
          const response = result?.data
          this.updateModal = false
          this.$swal("", result?.data?.message, response?.isSuccess ? "success" : "error");
        })
      } else {
        this.$swal("", "Lütfen zorunlu alanları doldurunuz.", "error");
      }
    },
    deleteAirportBefore(item) {
      this.deleteModal = true
      this.deletedItem = item
    },
    deleteAirportAfter() {
      HTTP.delete("/airports", this.deletedItem).then((result) => {
        const response = result?.data
        this.$swal("", response?.message, response?.isSuccess ? "success" : "error");
        if (response?.isSuccess) {
          this.allAirports = this.allAirports.filter(x => x?.id !== this.deletedItem?.id)
        }
      })
    },
  },
  watch: {
    search(val) {
      if (val) {
        HTTP.get("/airports/search?all=true&q=" + this.search).then(
          (response) => {
            this.allAirports = response?.data;
          }
        );
      } else {
        this.getAllAirports();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.active {
  background: green;
}

.passive {
  background: red;
}

.search-input {
  width: 100%;
  height: 45px;
  border: 1px solid rgb(223, 222, 222);
  outline: 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  padding: 10px
}

.search-input:focus {
  border: 1px solid orange;
}

.error-input {
  border-bottom: 2px solid red;
}
</style>